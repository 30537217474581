import React from "react";
const oil2data = {
  Alabama: {
    "2001": 71,
    "2002": 78,
    "2003": 94,
    "2004": 110,
    "2005": 89,
    "2006": 111,
    "2007": 172,
    "2008": 204,
    "2009": 201,
    "2010": 188,
    "2011": 193,
    "2012": 163,
    "2013": 138,
    "2014": 147,
    "2015": 135,
    "2016": 115,
    "2017": 109,
    "2018": 83,
    "2019": 67,
    "2020": 58,
    "2021": 38
  },
  Alaska: {
    "2001": 170,
    "2002": 229,
    "2003": 231,
    "2004": 340,
    "2005": 338,
    "2006": 338,
    "2007": 378,
    "2008": 371,
    "2009": 352,
    "2010": 280,
    "2011": 204,
    "2012": 214,
    "2013": 222,
    "2014": 242,
    "2015": 242,
    "2016": 174,
    "2017": 229,
    "2018": 238,
    "2019": 244,
    "2020": 336,
    "2021": 333
  },
  Arizona: {
    "2001": 67,
    "2002": 67,
    "2003": 62,
    "2004": 64,
    "2005": 54,
    "2006": 39,
    "2007": 98,
    "2008": 86,
    "2009": 86,
    "2010": 85,
    "2011": 23,
    "2012": 22,
    "2013": 27,
    "2014": 27,
    "2015": 11,
    "2016": 6,
    "2017": 6,
    "2018": 4,
    "2019": 3,
    "2020": 3,
    "2021": 3
  },
  Arkansas: {
    "2001": 788,
    "2002": 898,
    "2003": 924,
    "2004": 901,
    "2005": 1127,
    "2006": 1238,
    "2007": 1439,
    "2008": 1521,
    "2009": 1486,
    "2010": 1234,
    "2011": 1158,
    "2012": 960,
    "2013": 742,
    "2014": 726,
    "2015": 714,
    "2016": 615,
    "2017": 583,
    "2018": 584,
    "2019": 537,
    "2020": 465,
    "2021": 452
  },
  California: {
    "2001": 604,
    "2002": 658,
    "2003": 690,
    "2004": 672,
    "2005": 672,
    "2006": 737,
    "2007": 687,
    "2008": 778,
    "2009": 756,
    "2010": 648,
    "2011": 630,
    "2012": 610,
    "2013": 600,
    "2014": 574,
    "2015": 545,
    "2016": 530,
    "2017": 524,
    "2018": 516,
    "2019": 493,
    "2020": 487,
    "2021": 430
  },
  Colorado: {
    "2001": 5036,
    "2002": 5462,
    "2003": 5558,
    "2004": 4378,
    "2005": 4134,
    "2006": 5747,
    "2007": 5397,
    "2008": 6179,
    "2009": 5910,
    "2010": 5627,
    "2011": 5343,
    "2012": 5160,
    "2013": 4963,
    "2014": 4712,
    "2015": 4333,
    "2016": 4034,
    "2017": 3859,
    "2018": 3703,
    "2019": 3512,
    "2020": 3339,
    "2021": 3245
  },
  Connecticut: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Delaware: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Florida: {
    "2001": 12,
    "2002": 13,
    "2003": 13,
    "2004": 13,
    "2005": 8,
    "2006": 3,
    "2007": 3,
    "2008": 3,
    "2009": 2,
    "2010": 2,
    "2011": 2,
    "2012": 2,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 2,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Georgia: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Hawaii: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Idaho: {
    "2001": 5,
    "2002": 8,
    "2003": 8,
    "2004": 3,
    "2005": 3,
    "2006": 2,
    "2007": 6,
    "2008": 16,
    "2009": 13,
    "2010": 12,
    "2011": 5,
    "2012": 4,
    "2013": 4,
    "2014": 2,
    "2015": 7,
    "2016": 15,
    "2017": 14,
    "2018": 14,
    "2019": 13,
    "2020": 13,
    "2021": 13
  },
  Illinois: {
    "2001": 11,
    "2002": 11,
    "2003": 11,
    "2004": 11,
    "2005": 11,
    "2006": 11,
    "2007": 11,
    "2008": 11,
    "2009": 11,
    "2010": 11,
    "2011": 11,
    "2012": 11,
    "2013": 9,
    "2014": 8,
    "2015": 8,
    "2016": 8,
    "2017": 8,
    "2018": 8,
    "2019": 7,
    "2020": 7,
    "2021": 7
  },
  Indiana: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 2,
    "2009": 15,
    "2010": 13,
    "2011": 7,
    "2012": 9,
    "2013": 9,
    "2014": 9,
    "2015": 8,
    "2016": 9,
    "2017": 9,
    "2018": 9,
    "2019": 2,
    "2020": 2,
    "2021": 2
  },
  Iowa: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Kansas: {
    "2001": 486,
    "2002": 482,
    "2003": 487,
    "2004": 471,
    "2005": 465,
    "2006": 474,
    "2007": 458,
    "2008": 485,
    "2009": 494,
    "2010": 488,
    "2011": 483,
    "2012": 485,
    "2013": 482,
    "2014": 467,
    "2015": 464,
    "2016": 462,
    "2017": 457,
    "2018": 459,
    "2019": 448,
    "2020": 437,
    "2021": 434
  },
  Kentucky: {
    "2001": 83,
    "2002": 83,
    "2003": 77,
    "2004": 84,
    "2005": 81,
    "2006": 81,
    "2007": 76,
    "2008": 83,
    "2009": 76,
    "2010": 62,
    "2011": 63,
    "2012": 69,
    "2013": 69,
    "2014": 69,
    "2015": 68,
    "2016": 69,
    "2017": 70,
    "2018": 71,
    "2019": 70,
    "2020": 69,
    "2021": 64
  },
  Louisiana: {
    "2001": 586,
    "2002": 518,
    "2003": 505,
    "2004": 501,
    "2005": 435,
    "2006": 436,
    "2007": 407,
    "2008": 531,
    "2009": 441,
    "2010": 387,
    "2011": 462,
    "2012": 539,
    "2013": 525,
    "2014": 524,
    "2015": 566,
    "2016": 572,
    "2017": 558,
    "2018": 558,
    "2019": 508,
    "2020": 549,
    "2021": 430
  },
  Maine: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Maryland: {
    "2001": 3,
    "2002": 3,
    "2003": 3,
    "2004": 4,
    "2005": 4,
    "2006": 4,
    "2007": 3,
    "2008": 4,
    "2009": 4,
    "2010": 4,
    "2011": 3,
    "2012": 4,
    "2013": 4,
    "2014": 4,
    "2015": 4,
    "2016": 4,
    "2017": 4,
    "2018": 4,
    "2019": 4,
    "2020": 4,
    "2021": 4
  },
  Massachusetts: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Michigan: {
    "2001": 134,
    "2002": 136,
    "2003": 145,
    "2004": 139,
    "2005": 128,
    "2006": 120,
    "2007": 113,
    "2008": 147,
    "2009": 172,
    "2010": 174,
    "2011": 163,
    "2012": 168,
    "2013": 170,
    "2014": 184,
    "2015": 216,
    "2016": 235,
    "2017": 217,
    "2018": 208,
    "2019": 163,
    "2020": 146,
    "2021": 139
  },
  Minnesota: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Mississippi: {
    "2001": 1030,
    "2002": 1157,
    "2003": 1160,
    "2004": 1158,
    "2005": 1035,
    "2006": 992,
    "2007": 1092,
    "2008": 1154,
    "2009": 916,
    "2010": 788,
    "2011": 769,
    "2012": 882,
    "2013": 849,
    "2014": 841,
    "2015": 778,
    "2016": 596,
    "2017": 476,
    "2018": 470,
    "2019": 456,
    "2020": 444,
    "2021": 385
  },
  Missouri: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Montana: {
    "2001": 3922,
    "2002": 4222,
    "2003": 4376,
    "2004": 4223,
    "2005": 4202,
    "2006": 4220,
    "2007": 3750,
    "2008": 4185,
    "2009": 4093,
    "2010": 3607,
    "2011": 3499,
    "2012": 3566,
    "2013": 3488,
    "2014": 3364,
    "2015": 3075,
    "2016": 2675,
    "2017": 2561,
    "2018": 2821,
    "2019": 2759,
    "2020": 2327,
    "2021": 2153
  },
  Nebraska: {
    "2001": 34,
    "2002": 34,
    "2003": 39,
    "2004": 35,
    "2005": 35,
    "2006": 31,
    "2007": 28,
    "2008": 29,
    "2009": 28,
    "2010": 27,
    "2011": 24,
    "2012": 25,
    "2013": 30,
    "2014": 34,
    "2015": 34,
    "2016": 27,
    "2017": 27,
    "2018": 27,
    "2019": 27,
    "2020": 28,
    "2021": 28
  },
  Nevada: {
    "2001": 1007,
    "2002": 1138,
    "2003": 1029,
    "2004": 1180,
    "2005": 1444,
    "2006": 1996,
    "2007": 2126,
    "2008": 2176,
    "2009": 2157,
    "2010": 1984,
    "2011": 1845,
    "2012": 1927,
    "2013": 1881,
    "2014": 1696,
    "2015": 1214,
    "2016": 627,
    "2017": 426,
    "2018": 498,
    "2019": 518,
    "2020": 482,
    "2021": 413
  },
  "New Hampshire": {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "New Jersey": {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "New Mexico": {
    "2001": 9026,
    "2002": 9276,
    "2003": 9229,
    "2004": 8299,
    "2005": 8135,
    "2006": 9133,
    "2007": 7647,
    "2008": 8951,
    "2009": 8954,
    "2010": 8915,
    "2011": 8682,
    "2012": 8549,
    "2013": 8348,
    "2014": 8282,
    "2015": 8170,
    "2016": 7962,
    "2017": 7855,
    "2018": 7808,
    "2019": 7742,
    "2020": 7772,
    "2021": 7913
  },
  "New York": {
    "2001": 4,
    "2002": 4,
    "2003": 5,
    "2004": 5,
    "2005": 5,
    "2006": 5,
    "2007": 5,
    "2008": 5,
    "2009": 5,
    "2010": 5,
    "2011": 5,
    "2012": 5,
    "2013": 5,
    "2014": 5,
    "2015": 5,
    "2016": 5,
    "2017": 5,
    "2018": 5,
    "2019": 5,
    "2020": 5,
    "2021": 4
  },
  "North Carolina": {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "North Dakota": {
    "2001": 1571,
    "2002": 1653,
    "2003": 1595,
    "2004": 1494,
    "2005": 1669,
    "2006": 1753,
    "2007": 1510,
    "2008": 1565,
    "2009": 1772,
    "2010": 1779,
    "2011": 1969,
    "2012": 2024,
    "2013": 2061,
    "2014": 2119,
    "2015": 2071,
    "2016": 1837,
    "2017": 1769,
    "2018": 1741,
    "2019": 1740,
    "2020": 1709,
    "2021": 1689
  },
  Ohio: {
    "2001": 193,
    "2002": 197,
    "2003": 199,
    "2004": 232,
    "2005": 238,
    "2006": 233,
    "2007": 215,
    "2008": 238,
    "2009": 237,
    "2010": 251,
    "2011": 249,
    "2012": 265,
    "2013": 267,
    "2014": 260,
    "2015": 261,
    "2016": 259,
    "2017": 288,
    "2018": 284,
    "2019": 299,
    "2020": 299,
    "2021": 291
  },
  Oklahoma: {
    "2001": 1216,
    "2002": 1235,
    "2003": 1261,
    "2004": 1159,
    "2005": 1134,
    "2006": 1196,
    "2007": 1193,
    "2008": 1268,
    "2009": 1298,
    "2010": 1291,
    "2011": 1284,
    "2012": 1294,
    "2013": 1284,
    "2014": 1275,
    "2015": 1250,
    "2016": 1209,
    "2017": 1122,
    "2018": 1120,
    "2019": 1075,
    "2020": 1073,
    "2021": 1079
  },
  Oregon: {
    "2001": 20,
    "2002": 23,
    "2003": 23,
    "2004": 20,
    "2005": 18,
    "2006": 170,
    "2007": 200,
    "2008": 191,
    "2009": 188,
    "2010": 172,
    "2011": 165,
    "2012": 150,
    "2013": 112,
    "2014": 111,
    "2015": 111,
    "2016": 111,
    "2017": 110,
    "2018": 105,
    "2019": 105,
    "2020": 105,
    "2021": 105
  },
  Pennsylvania: {
    "2001": 71,
    "2002": 72,
    "2003": 72,
    "2004": 72,
    "2005": 71,
    "2006": 72,
    "2007": 70,
    "2008": 69,
    "2009": 68,
    "2010": 67,
    "2011": 67,
    "2012": 68,
    "2013": 69,
    "2014": 69,
    "2015": 75,
    "2016": 75,
    "2017": 75,
    "2018": 75,
    "2019": 73,
    "2020": 70,
    "2021": 70
  },
  "Rhode Island": {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "South Carolina": {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "South Dakota": {
    "2001": 250,
    "2002": 250,
    "2003": 243,
    "2004": 149,
    "2005": 158,
    "2006": 165,
    "2007": 155,
    "2008": 167,
    "2009": 181,
    "2010": 160,
    "2011": 170,
    "2012": 255,
    "2013": 303,
    "2014": 428,
    "2015": 420,
    "2016": 382,
    "2017": 372,
    "2018": 367,
    "2019": 359,
    "2020": 357,
    "2021": 336
  },
  Tennessee: {
    "2001": 20,
    "2002": 3,
    "2003": 3,
    "2004": 3,
    "2005": 3,
    "2006": 3,
    "2007": 3,
    "2008": 2,
    "2009": 2,
    "2010": 2,
    "2011": 2,
    "2012": 2,
    "2013": 2,
    "2014": 2,
    "2015": 2,
    "2016": 2,
    "2017": 2,
    "2018": 2,
    "2019": 2,
    "2020": 2,
    "2021": 2
  },
  Texas: {
    "2001": 566,
    "2002": 664,
    "2003": 690,
    "2004": 661,
    "2005": 646,
    "2006": 651,
    "2007": 658,
    "2008": 658,
    "2009": 731,
    "2010": 724,
    "2011": 735,
    "2012": 648,
    "2013": 683,
    "2014": 727,
    "2015": 712,
    "2016": 667,
    "2017": 599,
    "2018": 582,
    "2019": 539,
    "2020": 527,
    "2021": 519
  },
  Utah: {
    "2001": 3494,
    "2002": 3711,
    "2003": 3719,
    "2004": 3383,
    "2005": 3607,
    "2006": 4108,
    "2007": 3818,
    "2008": 4300,
    "2009": 4271,
    "2010": 4192,
    "2011": 3947,
    "2012": 3789,
    "2013": 3574,
    "2014": 3445,
    "2015": 3367,
    "2016": 2985,
    "2017": 2873,
    "2018": 2752,
    "2019": 2983,
    "2020": 2902,
    "2021": 2587
  },
  Vermont: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Virginia: {
    "2001": 25,
    "2002": 27,
    "2003": 26,
    "2004": 25,
    "2005": 25,
    "2006": 24,
    "2007": 22,
    "2008": 46,
    "2009": 42,
    "2010": 40,
    "2011": 38,
    "2012": 39,
    "2013": 39,
    "2014": 38,
    "2015": 38,
    "2016": 38,
    "2017": 19,
    "2018": 20,
    "2019": 18,
    "2020": 18,
    "2021": 18
  },
  Washington: {
    "2001": 84,
    "2002": 94,
    "2003": 213,
    "2004": 334,
    "2005": 354,
    "2006": 434,
    "2007": 446,
    "2008": 416,
    "2009": 342,
    "2010": 260,
    "2011": 101,
    "2012": 43,
    "2013": 2,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  "West Virginia": {
    "2001": 267,
    "2002": 269,
    "2003": 273,
    "2004": 281,
    "2005": 274,
    "2006": 285,
    "2007": 268,
    "2008": 283,
    "2009": 273,
    "2010": 267,
    "2011": 251,
    "2012": 259,
    "2013": 257,
    "2014": 257,
    "2015": 250,
    "2016": 238,
    "2017": 235,
    "2018": 231,
    "2019": 227,
    "2020": 223,
    "2021": 221
  },
  Wisconsin: {
    "2001": 0,
    "2002": 0,
    "2003": 0,
    "2004": 0,
    "2005": 0,
    "2006": 0,
    "2007": 0,
    "2008": 0,
    "2009": 0,
    "2010": 0,
    "2011": 0,
    "2012": 0,
    "2013": 0,
    "2014": 0,
    "2015": 0,
    "2016": 0,
    "2017": 0,
    "2018": 0,
    "2019": 0,
    "2020": 0,
    "2021": 0
  },
  Wyoming: {
    "2001": 21014,
    "2002": 21525,
    "2003": 21472,
    "2004": 15432,
    "2005": 14877,
    "2006": 19547,
    "2007": 16479,
    "2008": 18961,
    "2009": 17854,
    "2010": 16798,
    "2011": 16622,
    "2012": 16489,
    "2013": 16209,
    "2014": 15535,
    "2015": 15059,
    "2016": 13598,
    "2017": 13095,
    "2018": 12780,
    "2019": 13296,
    "2020": 13238,
    "2021": 12464
  },
  Total: {
    "2001": 51870,
    "2002": 54200,
    "2003": 54435,
    "2004": 45836,
    "2005": 45479,
    "2006": 54359,
    "2007": 48933,
    "2008": 55085,
    "2009": 53431,
    "2010": 50544,
    "2011": 49174,
    "2012": 48699,
    "2013": 47427,
    "2014": 46183,
    "2015": 44213,
    "2016": 40143,
    "2017": 38556,
    "2018": 38147,
    "2019": 38294,
    "2020": 37496,
    "2021": 35871
  }
};

class OIL2 extends React.Component {
  constructor(props) {
    super(props);

    //let testing = [];
    let noData = [];
    let date = [];
    let oildrilling = {};
    let drilling = [];
    let states = [];
    const oildata = Object.keys(oil2data).sort((a, b) => {
      //console.log(oil2data[a]["2021"]);
      return oil2data[b]["2021"] - oil2data[a]["2021"];
    });
    oildata.forEach((state, i) => {
      if (["Wyoming", "New Mexico"].includes(state)) return null;
      states.push(state);
      const statedata = oil2data[state];
      Object.keys(statedata).forEach((year) => {
        i === 0 && date.push(year);
        state !== "Total" && drilling.push(statedata[year]);
        if (!oildrilling[state]) oildrilling[state] = [];
        oildrilling[state].push([Number(year), statedata[year]]);
        i === 0 && noData.push([year, 0]);
      });
    });
    /*let dataDataData = {};
    Object.keys(dataData).forEach((label, i) => {
      const yearData = Object.values(label)[i];
      if (!dataDataData[yearData[0]]) dataDataData[yearData[0]] = 0;
      dataDataData[yearData[0]] = dataDataData[yearData[0]] + yearData[1];
    });*/
    var lowDrilling = Math.min(...drilling);
    var lowDate = Math.min(...date);
    var highDrilling = Math.max(...drilling);
    var highDate = Math.max(...date);
    //console.log(dataData);
    var state = {
      withbig: true,
      states,
      chosenState: "Total",
      oildrilling,
      noData,
      yAxisDrilling: highDrilling - lowDrilling,
      xAxisDrilling: highDate - lowDate,
      xAxisPrice: highDate - lowDate,
      lowDrilling,
      highDate,
      lowDate
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (this.state.withbig !== this.state.lastwithoutbig)
      this.setState({ lastwithoutbig: this.state.withbig }, () => {
        //let testing = [];
        let noData = [];
        let date = [];
        let oildrilling = {};
        let drilling = [];
        let states = [];
        const oildata = Object.keys(oil2data).sort((a, b) => {
          //console.log(oil2data[a]["2021"]);
          return oil2data[b]["2021"] - oil2data[a]["2021"];
        });
        oildata.forEach((state, i) => {
          if (this.state.withbig && ["Wyoming", "New Mexico"].includes(state))
            return null;
          states.push(state);
          const statedata = oil2data[state];
          Object.keys(statedata).forEach((year) => {
            i === 0 && date.push(year);
            state !== "Total" && drilling.push(statedata[year]);
            if (!oildrilling[state]) oildrilling[state] = [];
            oildrilling[state].push([Number(year), statedata[year]]);
            i === 0 && noData.push([year, 0]);
          });
        });
        /*let dataDataData = {};
    Object.keys(dataData).forEach((label, i) => {
      const yearData = Object.values(label)[i];
      if (!dataDataData[yearData[0]]) dataDataData[yearData[0]] = 0;
      dataDataData[yearData[0]] = dataDataData[yearData[0]] + yearData[1];
    });*/
        var lowDrilling = Math.min(...drilling);
        var lowDate = Math.min(...date);
        var highDrilling = Math.max(...drilling);
        var highDate = Math.max(...date);
        //console.log(dataData);
        var state = {
          states,
          chosenState: "Total",
          oildrilling,
          noData,
          yAxisDrilling: highDrilling - lowDrilling,
          xAxisDrilling: highDate - lowDate,
          xAxisPrice: highDate - lowDate,
          lowDrilling,
          highDate,
          lowDate
        };
        this.setState(state);
      });
  };
  render() {
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };

    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(0, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    //console.log(this.state.oilprice);
    const coefficience = (this.props.lastWidth - 60) / this.props.lastWidth;
    const noData = this.state.noData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        coefficience *
        this.props.lastWidth,
      0
    ]);
    //console.log(this.state.oilprice);

    const lineheight =
      (this.props.lineheight ? this.props.lineheight : 200) - 20;
    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: lineheight + 10,
      transform: "translate(0%,0px) scale(1,-1)"
    };
    const states = this.state.states.filter((state) => {
      if (this.state.withbig && ["Wyoming", "New Mexico"].includes(state))
        return null;
      return state;
    });
    var oildrilling = {};
    states.forEach((state) => {
      oildrilling[state] = this.state.oildrilling[state].map(([x, y]) => [
        ((x - this.state.lowDate) / this.state.xAxisDrilling) *
          coefficience *
          this.props.lastWidth,
        ((y - this.state.lowDrilling) / this.state.yAxisDrilling) * lineheight
      ]);
    });
    return (
      <div
        style={{
          fontSize: "12px",
          width: "100%",
          height: lineheight + 46,
          position: "relative",
          backgroundColor: "white"
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          <a href="https://www.blm.gov/programs-energy-and-minerals-oil-and-gas-oil-and-gas-statistics">
            {this.state.lowDate}
            &nbsp;-&nbsp;
            {this.state.highDate}
          </a>
        </div>
        <div style={{ display: "flex" }}>
          <select
            value={this.state.chosenState}
            onChange={(e) => this.setState({ chosenState: e.target.value })}
          >
            {this.state.states.map((state, i) => {
              return <option key={state + i}>{state}</option>;
            })}
          </select>
          <div
            style={{
              backgroundColor: "rgba(255,255,255,.3)",
              padding: "4px 8px"
            }}
          >
            <a href="https://www.eia.gov/dnav/ng/hist/e_ertrr0_xr0_nus_cm.htm">
              oil rigs
            </a>
            : Bureau of Land Management
          </div>
        </div>
        {this.state.highDrilling}
        <div
          style={{
            top: "50px",
            position: "absolute",
            right: "0px",
            zIndex: "9"
          }}
        >
          WY, NM{" "}
          <input
            type="checkbox"
            value={this.state.withbig}
            onChange={(e) => {
              this.setState({ withbig: e.target.checked });
            }}
          />
        </div>
        <div
          style={{
            height: lineheight + 20,
            position: "relative",
            border: "1px solid blue"
          }}
        >
          <svg
            className="all"
            style={linecss}
            xmlns="http://www.w3.org/2000/svg"
          >
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {states
              .sort((a, b) => b - a)
              .map((state) => {
                return oildrilling[state].map(
                  ([x, y], i) =>
                    !isNaN(x) &&
                    !isNaN(y) && (
                      <rect
                        x={x}
                        y={y}
                        width={2}
                        height={2}
                        stroke={
                          this.state.chosenState === state
                            ? "blueviolet"
                            : "lightgrey"
                        }
                        fill={
                          this.state.chosenState === state
                            ? "blue"
                            : "lightgrey"
                        }
                        strokeWidth={1}
                        key={i}
                      />
                    )
                );
              })}
          </svg>
        </div>
        {/*<div
          style={{
            backgroundColor: "rgba(250,250,250,0.6)",
            top: "10px",
            height: "40px",
            display: "flex",
            position: "relative",
            width: "100%",
            left: "2px",
            zIndex: "0",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              position: "absolute",
              width: "max-content"
            }}
          ></div>
          </div>*/}
      </div>
    );
  }
}

export default OIL2;
