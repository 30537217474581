export const laborwagedata = {
  //https://www.census.gov/data/tables/time-series/demo/income-poverty/cps-hinc/hinc-03.html#par_textimage_8
  "2005":{
    "17":{
      "houseLoitererm": {
         "2.5k": 2,
         "5k": 12,
         "7.5k": 4,
         "10k": 4,
         "12.5k": 6,
         "15k": 10,
         "17.5k": 6,
         "20k": 19,
         "22.5k": 10,
         "25k": 18,
         "27.5k": 13,
         "30k": 24,
         "32.5k": 13,
         "35k": 8,
         "37.5k": 12,
         "40k": 13,
         "42.5k": 20,
         "45k": 12,
         "47.5k": 23,
         "50k": 7,
         "52.5k": 13,
         "55k": 8,
         "57.5k": 7,
         "60k": 4,
         "62.5k": 17,
         "65k": 7,
         "67.5k": 4,
         "70k": 4,
         "72.5k": 4,
         "75k": 1,
         "77.5k": 12,
         "80k": 0,
         "82.5k": 0,
         "85k": 10,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 1,
         "100k": 0,
         "200k": 7
      },
      "spousem": {
         "2.5k": 13,
         "5k": 9,
         "7.5k": 5,
         "10k": 8,
         "12.5k": 12,
         "15k": 7,
         "17.5k": 20,
         "20k": 13,
         "22.5k": 10,
         "25k": 9,
         "27.5k": 11,
         "30k": 8,
         "32.5k": 21,
         "35k": 7,
         "37.5k": 7,
         "40k": 20,
         "42.5k": 22,
         "45k": 21,
         "47.5k": 16,
         "50k": 9,
         "52.5k": 13,
         "55k": 14,
         "57.5k": 11,
         "60k": 11,
         "62.5k": 18,
         "65k": 12,
         "67.5k": 9,
         "70k": 4,
         "72.5k": 2,
         "75k": 19,
         "77.5k": 7,
         "80k": 9,
         "82.5k": 5,
         "85k": 8,
         "87.5k": 12,
         "90k": 6,
         "92.5k": 10,
         "95k": 7,
         "97.5k": 8,
         "100k": 11,
         "200k": 83
      },
      "childm": {
         "2.5k": 16,
         "5k": 11,
         "7.5k": 8,
         "10k": 23,
         "12.5k": 7,
         "15k": 17,
         "17.5k": 19,
         "20k": 21,
         "22.5k": 35,
         "25k": 31,
         "27.5k": 31,
         "30k": 37,
         "32.5k": 41,
         "35k": 34,
         "37.5k": 31,
         "40k": 23,
         "42.5k": 64,
         "45k": 17,
         "47.5k": 44,
         "50k": 40,
         "52.5k": 57,
         "55k": 38,
         "57.5k": 51,
         "60k": 32,
         "62.5k": 49,
         "65k": 35,
         "67.5k": 68,
         "70k": 24,
         "72.5k": 37,
         "75k": 21,
         "77.5k": 29,
         "80k": 40,
         "82.5k": 44,
         "85k": 36,
         "87.5k": 15,
         "90k": 42,
         "92.5k": 54,
         "95k": 38,
         "97.5k": 30,
         "100k": 38,
         "200k": 950
      },
      "otherRelativem": {
         "2.5k": 39,
         "5k": 10,
         "7.5k": 14,
         "10k": 15,
         "12.5k": 12,
         "15k": 9,
         "17.5k": 19,
         "20k": 19,
         "22.5k": 18,
         "25k": 27,
         "27.5k": 35,
         "30k": 16,
         "32.5k": 42,
         "35k": 31,
         "37.5k": 64,
         "40k": 18,
         "42.5k": 31,
         "45k": 33,
         "47.5k": 16,
         "50k": 32,
         "52.5k": 23,
         "55k": 25,
         "57.5k": 37,
         "60k": 19,
         "62.5k": 20,
         "65k": 9,
         "67.5k": 21,
         "70k": 25,
         "72.5k": 13,
         "75k": 13,
         "77.5k": 18,
         "80k": 21,
         "82.5k": 27,
         "85k": 7,
         "87.5k": 9,
         "90k": 2,
         "92.5k": 6,
         "95k": 14,
         "97.5k": 6,
         "100k": 14,
         "200k": 121
      },
      "nonRelativem": {
         "2.5k": 89,
         "5k": 19,
         "7.5k": 31,
         "10k": 49,
         "12.5k": 95,
         "15k": 71,
         "17.5k": 74,
         "20k": 64,
         "22.5k": 124,
         "25k": 63,
         "27.5k": 87,
         "30k": 63,
         "32.5k": 98,
         "35k": 67,
         "37.5k": 95,
         "40k": 59,
         "42.5k": 90,
         "45k": 52,
         "47.5k": 53,
         "50k": 48,
         "52.5k": 87,
         "55k": 38,
         "57.5k": 30,
         "60k": 20,
         "62.5k": 38,
         "65k": 25,
         "67.5k": 23,
         "70k": 33,
         "72.5k": 30,
         "75k": 24,
         "77.5k": 20,
         "80k": 14,
         "82.5k": 32,
         "85k": 13,
         "87.5k": 10,
         "90k": 5,
         "92.5k": 7,
         "95k": 3,
         "97.5k": 9,
         "100k": 12,
         "200k": 111
      },
      "houseLoitererfm": {
         "2.5k": 11,
         "5k": 8,
         "7.5k": 6,
         "10k": 2,
         "12.5k": 19,
         "15k": 23,
         "17.5k": 22,
         "20k": 20,
         "22.5k": 41,
         "25k": 13,
         "27.5k": 36,
         "30k": 37,
         "32.5k": 44,
         "35k": 26,
         "37.5k": 41,
         "40k": 31,
         "42.5k": 44,
         "45k": 32,
         "47.5k": 43,
         "50k": 36,
         "52.5k": 52,
         "55k": 28,
         "57.5k": 47,
         "60k": 10,
         "62.5k": 40,
         "65k": 17,
         "67.5k": 19,
         "70k": 16,
         "72.5k": 16,
         "75k": 20,
         "77.5k": 13,
         "80k": 7,
         "82.5k": 9,
         "85k": 5,
         "87.5k": 13,
         "90k": 10,
         "92.5k": 9,
         "95k": 0,
         "97.5k": 8,
         "100k": 0,
         "200k": 53
      },
      "spousefm": {
         "2.5k": 2,
         "5k": 7,
         "7.5k": 3,
         "10k": 7,
         "12.5k": 14,
         "15k": 8,
         "17.5k": 7,
         "20k": 13,
         "22.5k": 9,
         "25k": 5,
         "27.5k": 10,
         "30k": 3,
         "32.5k": 8,
         "35k": 13,
         "37.5k": 15,
         "40k": 3,
         "42.5k": 7,
         "45k": 6,
         "47.5k": 19,
         "50k": 16,
         "52.5k": 15,
         "55k": 13,
         "57.5k": 13,
         "60k": 9,
         "62.5k": 12,
         "65k": 3,
         "67.5k": 17,
         "70k": 3,
         "72.5k": 6,
         "75k": 2,
         "77.5k": 14,
         "80k": 8,
         "82.5k": 14,
         "85k": 5,
         "87.5k": 2,
         "90k": 3,
         "92.5k": 9,
         "95k": 8,
         "97.5k": 6,
         "100k": 5,
         "200k": 72
      },
      "childfm": {
         "2.5k": 18,
         "5k": 1,
         "7.5k": 12,
         "10k": 11,
         "12.5k": 10,
         "15k": 14,
         "17.5k": 11,
         "20k": 40,
         "22.5k": 30,
         "25k": 16,
         "27.5k": 17,
         "30k": 28,
         "32.5k": 22,
         "35k": 26,
         "37.5k": 25,
         "40k": 15,
         "42.5k": 40,
         "45k": 25,
         "47.5k": 20,
         "50k": 39,
         "52.5k": 37,
         "55k": 17,
         "57.5k": 27,
         "60k": 25,
         "62.5k": 29,
         "65k": 39,
         "67.5k": 34,
         "70k": 34,
         "72.5k": 37,
         "75k": 38,
         "77.5k": 32,
         "80k": 27,
         "82.5k": 31,
         "85k": 36,
         "87.5k": 21,
         "90k": 37,
         "92.5k": 35,
         "95k": 36,
         "97.5k": 16,
         "100k": 31,
         "200k": 727
      },
      "otherRelativefm": {
         "2.5k": 12,
         "5k": 7,
         "7.5k": 7,
         "10k": 8,
         "12.5k": 7,
         "15k": 21,
         "17.5k": 32,
         "20k": 25,
         "22.5k": 30,
         "25k": 11,
         "27.5k": 28,
         "30k": 18,
         "32.5k": 30,
         "35k": 22,
         "37.5k": 46,
         "40k": 35,
         "42.5k": 33,
         "45k": 20,
         "47.5k": 30,
         "50k": 41,
         "52.5k": 23,
         "55k": 15,
         "57.5k": 23,
         "60k": 29,
         "62.5k": 17,
         "65k": 7,
         "67.5k": 7,
         "70k": 18,
         "72.5k": 9,
         "75k": 8,
         "77.5k": 19,
         "80k": 7,
         "82.5k": 15,
         "85k": 7,
         "87.5k": 4,
         "90k": 8,
         "92.5k": 5,
         "95k": 4,
         "97.5k": 3,
         "100k": 5,
         "200k": 104
      },
      "nonRelativefm": {
         "2.5k": 119,
         "5k": 86,
         "7.5k": 106,
         "10k": 101,
         "12.5k": 92,
         "15k": 105,
         "17.5k": 142,
         "20k": 109,
         "22.5k": 118,
         "25k": 56,
         "27.5k": 127,
         "30k": 80,
         "32.5k": 90,
         "35k": 74,
         "37.5k": 96,
         "40k": 48,
         "42.5k": 59,
         "45k": 45,
         "47.5k": 75,
         "50k": 45,
         "52.5k": 69,
         "55k": 34,
         "57.5k": 50,
         "60k": 39,
         "62.5k": 26,
         "65k": 26,
         "67.5k": 19,
         "70k": 30,
         "72.5k": 24,
         "75k": 17,
         "77.5k": 52,
         "80k": 14,
         "82.5k": 4,
         "85k": 19,
         "87.5k": 12,
         "90k": 4,
         "92.5k": 4,
         "95k": 4,
         "97.5k": 5,
         "100k": 2,
         "200k": 90
      }
   },
    "21":{
      "houseLoitererm": {
         "2.5k": 1,
         "5k": 0,
         "7.5k": 12,
         "10k": 13,
         "12.5k": 19,
         "15k": 32,
         "17.5k": 19,
         "20k": 17,
         "22.5k": 25,
         "25k": 24,
         "27.5k": 29,
         "30k": 16,
         "32.5k": 25,
         "35k": 18,
         "37.5k": 27,
         "40k": 20,
         "42.5k": 20,
         "45k": 26,
         "47.5k": 26,
         "50k": 20,
         "52.5k": 22,
         "55k": 16,
         "57.5k": 12,
         "60k": 19,
         "62.5k": 20,
         "65k": 32,
         "67.5k": 16,
         "70k": 21,
         "72.5k": 25,
         "75k": 14,
         "77.5k": 22,
         "80k": 7,
         "82.5k": 11,
         "85k": 13,
         "87.5k": 5,
         "90k": 13,
         "92.5k": 6,
         "95k": 14,
         "97.5k": 9,
         "100k": 9,
         "200k": "112"
      },
      "spousem": {
         "2.5k": 4,
         "5k": 1,
         "7.5k": 0,
         "10k": 4,
         "12.5k": 1,
         "15k": 0,
         "17.5k": 11,
         "20k": 0,
         "22.5k": 5,
         "25k": 3,
         "27.5k": 9,
         "30k": 0,
         "32.5k": 10,
         "35k": 4,
         "37.5k": 2,
         "40k": 3,
         "42.5k": 3,
         "45k": 2,
         "47.5k": 2,
         "50k": 7,
         "52.5k": 6,
         "55k": 0,
         "57.5k": 3,
         "60k": 0,
         "62.5k": 2,
         "65k": 0,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 1,
         "75k": 4,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 1,
         "85k": 1,
         "87.5k": 2,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": "3"
      },
      "childm": {
         "2.5k": 32,
         "5k": 10,
         "7.5k": 5,
         "10k": 7,
         "12.5k": 23,
         "15k": 18,
         "17.5k": 17,
         "20k": 25,
         "22.5k": 25,
         "25k": 22,
         "27.5k": 39,
         "30k": 30,
         "32.5k": 15,
         "35k": 29,
         "37.5k": 19,
         "40k": 30,
         "42.5k": 18,
         "45k": 16,
         "47.5k": 21,
         "50k": 39,
         "52.5k": 41,
         "55k": 24,
         "57.5k": 14,
         "60k": 7,
         "62.5k": 14,
         "65k": 23,
         "67.5k": 14,
         "70k": 21,
         "72.5k": 4,
         "75k": 18,
         "77.5k": 13,
         "80k": 12,
         "82.5k": 17,
         "85k": 9,
         "87.5k": 13,
         "90k": 17,
         "92.5k": 6,
         "95k": 2,
         "97.5k": 9,
         "100k": 16,
         "200k": "97"
      },
      "otherRelativem": {
         "2.5k": 43,
         "5k": 20,
         "7.5k": 39,
         "10k": 30,
         "12.5k": 58,
         "15k": 53,
         "17.5k": 45,
         "20k": 68,
         "22.5k": 65,
         "25k": 35,
         "27.5k": 60,
         "30k": 29,
         "32.5k": 46,
         "35k": 23,
         "37.5k": 32,
         "40k": 8,
         "42.5k": 27,
         "45k": 18,
         "47.5k": 17,
         "50k": 27,
         "52.5k": 26,
         "55k": 18,
         "57.5k": 19,
         "60k": 10,
         "62.5k": 29,
         "65k": 9,
         "67.5k": 3,
         "70k": 19,
         "72.5k": 3,
         "75k": 8,
         "77.5k": 16,
         "80k": 2,
         "82.5k": 10,
         "85k": 4,
         "87.5k": 8,
         "90k": 5,
         "92.5k": 3,
         "95k": 4,
         "97.5k": 6,
         "100k": 10,
         "200k": "60"
      },
      "nonRelativem": {
         "2.5k": 73,
         "5k": 22,
         "7.5k": 43,
         "10k": 54,
         "12.5k": 56,
         "15k": 47,
         "17.5k": 55,
         "20k": 88,
         "22.5k": 77,
         "25k": 62,
         "27.5k": 121,
         "30k": 78,
         "32.5k": 126,
         "35k": 83,
         "37.5k": 100,
         "40k": 87,
         "42.5k": 108,
         "45k": 91,
         "47.5k": 121,
         "50k": 98,
         "52.5k": 132,
         "55k": 83,
         "57.5k": 110,
         "60k": 78,
         "62.5k": 112,
         "65k": 105,
         "67.5k": 132,
         "70k": 99,
         "72.5k": 122,
         "75k": 98,
         "77.5k": 94,
         "80k": 84,
         "82.5k": 118,
         "85k": 87,
         "87.5k": 88,
         "90k": 88,
         "92.5k": 114,
         "95k": 76,
         "97.5k": 87,
         "100k": 94,
         "200k": "1,704"
      },
      "houseLoitererfm": {
         "2.5k": 16,
         "5k": 3,
         "7.5k": 8,
         "10k": 3,
         "12.5k": 18,
         "15k": 12,
         "17.5k": 18,
         "20k": 9,
         "22.5k": 19,
         "25k": 22,
         "27.5k": 25,
         "30k": 17,
         "32.5k": 24,
         "35k": 7,
         "37.5k": 13,
         "40k": 8,
         "42.5k": 31,
         "45k": 8,
         "47.5k": 13,
         "50k": 15,
         "52.5k": 13,
         "55k": 13,
         "57.5k": 20,
         "60k": 19,
         "62.5k": 12,
         "65k": 8,
         "67.5k": 24,
         "70k": 4,
         "72.5k": 11,
         "75k": 6,
         "77.5k": 15,
         "80k": 9,
         "82.5k": 9,
         "85k": 5,
         "87.5k": 3,
         "90k": 6,
         "92.5k": 3,
         "95k": 6,
         "97.5k": 7,
         "100k": 3,
         "200k": "70"
      },
      "spousefm": {
         "2.5k": 11,
         "5k": 3,
         "7.5k": 8,
         "10k": 2,
         "12.5k": 12,
         "15k": 9,
         "17.5k": 13,
         "20k": 21,
         "22.5k": 17,
         "25k": 15,
         "27.5k": 15,
         "30k": 7,
         "32.5k": 13,
         "35k": 16,
         "37.5k": 14,
         "40k": 9,
         "42.5k": 9,
         "45k": 4,
         "47.5k": 10,
         "50k": 12,
         "52.5k": 11,
         "55k": 4,
         "57.5k": 9,
         "60k": 3,
         "62.5k": 11,
         "65k": 2,
         "67.5k": 8,
         "70k": 13,
         "72.5k": 5,
         "75k": 4,
         "77.5k": 2,
         "80k": 0,
         "82.5k": 2,
         "85k": 0,
         "87.5k": 3,
         "90k": 0,
         "92.5k": 2,
         "95k": 1,
         "97.5k": 2,
         "100k": 1,
         "200k": "9"
      },
      "childfm": {
         "2.5k": 44,
         "5k": 4,
         "7.5k": 13,
         "10k": 32,
         "12.5k": 22,
         "15k": 25,
         "17.5k": 20,
         "20k": 24,
         "22.5k": 48,
         "25k": 25,
         "27.5k": 27,
         "30k": 16,
         "32.5k": 21,
         "35k": 13,
         "37.5k": 35,
         "40k": 15,
         "42.5k": 31,
         "45k": 13,
         "47.5k": 19,
         "50k": 16,
         "52.5k": 33,
         "55k": 23,
         "57.5k": 14,
         "60k": 9,
         "62.5k": 39,
         "65k": 8,
         "67.5k": 15,
         "70k": 14,
         "72.5k": 4,
         "75k": 3,
         "77.5k": 11,
         "80k": 10,
         "82.5k": 9,
         "85k": 13,
         "87.5k": 4,
         "90k": 9,
         "92.5k": 3,
         "95k": 5,
         "97.5k": 11,
         "100k": 7,
         "200k": "88"
      },
      "otherRelativefm": {
         "2.5k": 126,
         "5k": 47,
         "7.5k": 87,
         "10k": 71,
         "12.5k": 63,
         "15k": 65,
         "17.5k": 60,
         "20k": 56,
         "22.5k": 66,
         "25k": 48,
         "27.5k": 73,
         "30k": 51,
         "32.5k": 38,
         "35k": 33,
         "37.5k": 35,
         "40k": 26,
         "42.5k": 33,
         "45k": 36,
         "47.5k": 27,
         "50k": 20,
         "52.5k": 24,
         "55k": 21,
         "57.5k": 7,
         "60k": 8,
         "62.5k": 24,
         "65k": 2,
         "67.5k": 6,
         "70k": 15,
         "72.5k": 8,
         "75k": 5,
         "77.5k": 7,
         "80k": 3,
         "82.5k": 13,
         "85k": 8,
         "87.5k": 0,
         "90k": 1,
         "92.5k": 3,
         "95k": 2,
         "97.5k": 2,
         "100k": 2,
         "200k": "46"
      },
      "nonRelativefm": {
         "2.5k": 44,
         "5k": 20,
         "7.5k": 52,
         "10k": 56,
         "12.5k": 50,
         "15k": 62,
         "17.5k": 71,
         "20k": 72,
         "22.5k": 77,
         "25k": 75,
         "27.5k": 73,
         "30k": 79,
         "32.5k": 90,
         "35k": 66,
         "37.5k": 107,
         "40k": 72,
         "42.5k": 111,
         "45k": 88,
         "47.5k": 90,
         "50k": 94,
         "52.5k": 112,
         "55k": 63,
         "57.5k": 92,
         "60k": 76,
         "62.5k": 107,
         "65k": 92,
         "67.5k": 86,
         "70k": 73,
         "72.5k": 112,
         "75k": 87,
         "77.5k": 85,
         "80k": 97,
         "82.5k": 77,
         "85k": 96,
         "87.5k": 70,
         "90k": 78,
         "92.5k": 81,
         "95k": 57,
         "97.5k": 81,
         "100k": 83,
         "200k": "1,547"
      }
   },
    "24":{
      "houseLoitererm": {
         "2.5k": 2,
         "5k": 12,
         "7.5k": 4,
         "10k": 4,
         "12.5k": 6,
         "15k": 10,
         "17.5k": 6,
         "20k": 19,
         "22.5k": 10,
         "25k": 18,
         "27.5k": 13,
         "30k": 24,
         "32.5k": 13,
         "35k": 8,
         "37.5k": 12,
         "40k": 13,
         "42.5k": 20,
         "45k": 12,
         "47.5k": 23,
         "50k": 7,
         "52.5k": 13,
         "55k": 8,
         "57.5k": 7,
         "60k": 4,
         "62.5k": 17,
         "65k": 7,
         "67.5k": 4,
         "70k": 4,
         "72.5k": 4,
         "75k": 1,
         "77.5k": 12,
         "80k": 0,
         "82.5k": 0,
         "85k": 10,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 1,
         "100k": 0,
         "200k": 7
      },
      "spousem": {
         "2.5k": 13,
         "5k": 9,
         "7.5k": 5,
         "10k": 8,
         "12.5k": 12,
         "15k": 7,
         "17.5k": 20,
         "20k": 13,
         "22.5k": 10,
         "25k": 9,
         "27.5k": 11,
         "30k": 8,
         "32.5k": 21,
         "35k": 7,
         "37.5k": 7,
         "40k": 20,
         "42.5k": 22,
         "45k": 21,
         "47.5k": 16,
         "50k": 9,
         "52.5k": 13,
         "55k": 14,
         "57.5k": 11,
         "60k": 11,
         "62.5k": 18,
         "65k": 12,
         "67.5k": 9,
         "70k": 4,
         "72.5k": 2,
         "75k": 19,
         "77.5k": 7,
         "80k": 9,
         "82.5k": 5,
         "85k": 8,
         "87.5k": 12,
         "90k": 6,
         "92.5k": 10,
         "95k": 7,
         "97.5k": 8,
         "100k": 11,
         "200k": 83
      },
      "childm": {
         "2.5k": 16,
         "5k": 11,
         "7.5k": 8,
         "10k": 23,
         "12.5k": 7,
         "15k": 17,
         "17.5k": 19,
         "20k": 21,
         "22.5k": 35,
         "25k": 31,
         "27.5k": 31,
         "30k": 37,
         "32.5k": 41,
         "35k": 34,
         "37.5k": 31,
         "40k": 23,
         "42.5k": 64,
         "45k": 17,
         "47.5k": 44,
         "50k": 40,
         "52.5k": 57,
         "55k": 38,
         "57.5k": 51,
         "60k": 32,
         "62.5k": 49,
         "65k": 35,
         "67.5k": 68,
         "70k": 24,
         "72.5k": 37,
         "75k": 21,
         "77.5k": 29,
         "80k": 40,
         "82.5k": 44,
         "85k": 36,
         "87.5k": 15,
         "90k": 42,
         "92.5k": 54,
         "95k": 38,
         "97.5k": 30,
         "100k": 38,
         "200k": 950
      },
      "otherRelativem": {
         "2.5k": 39,
         "5k": 10,
         "7.5k": 14,
         "10k": 15,
         "12.5k": 12,
         "15k": 9,
         "17.5k": 19,
         "20k": 19,
         "22.5k": 18,
         "25k": 27,
         "27.5k": 35,
         "30k": 16,
         "32.5k": 42,
         "35k": 31,
         "37.5k": 64,
         "40k": 18,
         "42.5k": 31,
         "45k": 33,
         "47.5k": 16,
         "50k": 32,
         "52.5k": 23,
         "55k": 25,
         "57.5k": 37,
         "60k": 19,
         "62.5k": 20,
         "65k": 9,
         "67.5k": 21,
         "70k": 25,
         "72.5k": 13,
         "75k": 13,
         "77.5k": 18,
         "80k": 21,
         "82.5k": 27,
         "85k": 7,
         "87.5k": 9,
         "90k": 2,
         "92.5k": 6,
         "95k": 14,
         "97.5k": 6,
         "100k": 14,
         "200k": 121
      },
      "nonRelativem": {
         "2.5k": 89,
         "5k": 19,
         "7.5k": 31,
         "10k": 49,
         "12.5k": 95,
         "15k": 71,
         "17.5k": 74,
         "20k": 64,
         "22.5k": 124,
         "25k": 63,
         "27.5k": 87,
         "30k": 63,
         "32.5k": 98,
         "35k": 67,
         "37.5k": 95,
         "40k": 59,
         "42.5k": 90,
         "45k": 52,
         "47.5k": 53,
         "50k": 48,
         "52.5k": 87,
         "55k": 38,
         "57.5k": 30,
         "60k": 20,
         "62.5k": 38,
         "65k": 25,
         "67.5k": 23,
         "70k": 33,
         "72.5k": 30,
         "75k": 24,
         "77.5k": 20,
         "80k": 14,
         "82.5k": 32,
         "85k": 13,
         "87.5k": 10,
         "90k": 5,
         "92.5k": 7,
         "95k": 3,
         "97.5k": 9,
         "100k": 12,
         "200k": 111
      },
      "houseLoitererfm": {
         "2.5k": 11,
         "5k": 8,
         "7.5k": 6,
         "10k": 2,
         "12.5k": 19,
         "15k": 23,
         "17.5k": 22,
         "20k": 20,
         "22.5k": 41,
         "25k": 13,
         "27.5k": 36,
         "30k": 37,
         "32.5k": 44,
         "35k": 26,
         "37.5k": 41,
         "40k": 31,
         "42.5k": 44,
         "45k": 32,
         "47.5k": 43,
         "50k": 36,
         "52.5k": 52,
         "55k": 28,
         "57.5k": 47,
         "60k": 10,
         "62.5k": 40,
         "65k": 17,
         "67.5k": 19,
         "70k": 16,
         "72.5k": 16,
         "75k": 20,
         "77.5k": 13,
         "80k": 7,
         "82.5k": 9,
         "85k": 5,
         "87.5k": 13,
         "90k": 10,
         "92.5k": 9,
         "95k": 0,
         "97.5k": 8,
         "100k": 0,
         "200k": 53
      },
      "spousefm": {
         "2.5k": 2,
         "5k": 7,
         "7.5k": 3,
         "10k": 7,
         "12.5k": 14,
         "15k": 8,
         "17.5k": 7,
         "20k": 13,
         "22.5k": 9,
         "25k": 5,
         "27.5k": 10,
         "30k": 3,
         "32.5k": 8,
         "35k": 13,
         "37.5k": 15,
         "40k": 3,
         "42.5k": 7,
         "45k": 6,
         "47.5k": 19,
         "50k": 16,
         "52.5k": 15,
         "55k": 13,
         "57.5k": 13,
         "60k": 9,
         "62.5k": 12,
         "65k": 3,
         "67.5k": 17,
         "70k": 3,
         "72.5k": 6,
         "75k": 2,
         "77.5k": 14,
         "80k": 8,
         "82.5k": 14,
         "85k": 5,
         "87.5k": 2,
         "90k": 3,
         "92.5k": 9,
         "95k": 8,
         "97.5k": 6,
         "100k": 5,
         "200k": 72
      },
      "childfm": {
         "2.5k": 18,
         "5k": 1,
         "7.5k": 12,
         "10k": 11,
         "12.5k": 10,
         "15k": 14,
         "17.5k": 11,
         "20k": 40,
         "22.5k": 30,
         "25k": 16,
         "27.5k": 17,
         "30k": 28,
         "32.5k": 22,
         "35k": 26,
         "37.5k": 25,
         "40k": 15,
         "42.5k": 40,
         "45k": 25,
         "47.5k": 20,
         "50k": 39,
         "52.5k": 37,
         "55k": 17,
         "57.5k": 27,
         "60k": 25,
         "62.5k": 29,
         "65k": 39,
         "67.5k": 34,
         "70k": 34,
         "72.5k": 37,
         "75k": 38,
         "77.5k": 32,
         "80k": 27,
         "82.5k": 31,
         "85k": 36,
         "87.5k": 21,
         "90k": 37,
         "92.5k": 35,
         "95k": 36,
         "97.5k": 16,
         "100k": 31,
         "200k": 727
      },
      "otherRelativefm": {
         "2.5k": 12,
         "5k": 7,
         "7.5k": 7,
         "10k": 8,
         "12.5k": 7,
         "15k": 21,
         "17.5k": 32,
         "20k": 25,
         "22.5k": 30,
         "25k": 11,
         "27.5k": 28,
         "30k": 18,
         "32.5k": 30,
         "35k": 22,
         "37.5k": 46,
         "40k": 35,
         "42.5k": 33,
         "45k": 20,
         "47.5k": 30,
         "50k": 41,
         "52.5k": 23,
         "55k": 15,
         "57.5k": 23,
         "60k": 29,
         "62.5k": 17,
         "65k": 7,
         "67.5k": 7,
         "70k": 18,
         "72.5k": 9,
         "75k": 8,
         "77.5k": 19,
         "80k": 7,
         "82.5k": 15,
         "85k": 7,
         "87.5k": 4,
         "90k": 8,
         "92.5k": 5,
         "95k": 4,
         "97.5k": 3,
         "100k": 5,
         "200k": 104
      },
      "nonRelativefm": {
         "2.5k": 119,
         "5k": 86,
         "7.5k": 106,
         "10k": 101,
         "12.5k": 92,
         "15k": 105,
         "17.5k": 142,
         "20k": 109,
         "22.5k": 118,
         "25k": 56,
         "27.5k": 127,
         "30k": 80,
         "32.5k": 90,
         "35k": 74,
         "37.5k": 96,
         "40k": 48,
         "42.5k": 59,
         "45k": 45,
         "47.5k": 75,
         "50k": 45,
         "52.5k": 69,
         "55k": 34,
         "57.5k": 50,
         "60k": 39,
         "62.5k": 26,
         "65k": 26,
         "67.5k": 19,
         "70k": 30,
         "72.5k": 24,
         "75k": 17,
         "77.5k": 52,
         "80k": 14,
         "82.5k": 4,
         "85k": 19,
         "87.5k": 12,
         "90k": 4,
         "92.5k": 4,
         "95k": 4,
         "97.5k": 5,
         "100k": 2,
         "200k": 90
      }
   },
    "29":{
      "houseLoitererm": {
         "2.5k": 6,
         "5k": 0,
         "7.5k": 9,
         "10k": 5,
         "12.5k": 16,
         "15k": 12,
         "17.5k": 11,
         "20k": 11,
         "22.5k": 22,
         "25k": 26,
         "27.5k": 32,
         "30k": 22,
         "32.5k": 32,
         "35k": 22,
         "37.5k": 27,
         "40k": 19,
         "42.5k": 37,
         "45k": 20,
         "47.5k": 24,
         "50k": 25,
         "52.5k": 22,
         "55k": 14,
         "57.5k": 18,
         "60k": 29,
         "62.5k": 20,
         "65k": 23,
         "67.5k": 23,
         "70k": 25,
         "72.5k": 15,
         "75k": 9,
         "77.5k": 31,
         "80k": 15,
         "82.5k": 1,
         "85k": 13,
         "87.5k": 6,
         "90k": 18,
         "92.5k": 11,
         "95k": 4,
         "97.5k": 8,
         "100k": 9,
         "200k": 150
      },
      "spousem": {
         "2.5k": 16,
         "5k": 5,
         "7.5k": 7,
         "10k": 8,
         "12.5k": 24,
         "15k": 25,
         "17.5k": 26,
         "20k": 35,
         "22.5k": 37,
         "25k": 27,
         "27.5k": 45,
         "30k": 46,
         "32.5k": 61,
         "35k": 56,
         "37.5k": 40,
         "40k": 35,
         "42.5k": 65,
         "45k": 29,
         "47.5k": 39,
         "50k": 30,
         "52.5k": 57,
         "55k": 29,
         "57.5k": 39,
         "60k": 53,
         "62.5k": 53,
         "65k": 27,
         "67.5k": 41,
         "70k": 11,
         "72.5k": 47,
         "75k": 25,
         "77.5k": 36,
         "80k": 19,
         "82.5k": 33,
         "85k": 13,
         "87.5k": 17,
         "90k": 10,
         "92.5k": 16,
         "95k": 21,
         "97.5k": 24,
         "100k": 15,
         "200k": 130
      },
      "childm": {
         "2.5k": 19,
         "5k": 0,
         "7.5k": 7,
         "10k": 22,
         "12.5k": 14,
         "15k": 12,
         "17.5k": 28,
         "20k": 32,
         "22.5k": 43,
         "25k": 17,
         "27.5k": 43,
         "30k": 50,
         "32.5k": 52,
         "35k": 30,
         "37.5k": 30,
         "40k": 40,
         "42.5k": 34,
         "45k": 47,
         "47.5k": 37,
         "50k": 50,
         "52.5k": 42,
         "55k": 10,
         "57.5k": 32,
         "60k": 35,
         "62.5k": 77,
         "65k": 18,
         "67.5k": 18,
         "70k": 18,
         "72.5k": 44,
         "75k": 33,
         "77.5k": 25,
         "80k": 31,
         "82.5k": 38,
         "85k": 32,
         "87.5k": 16,
         "90k": 13,
         "92.5k": 12,
         "95k": 11,
         "97.5k": 10,
         "100k": 12,
         "200k": 310
      },
      "otherRelativem": {
         "2.5k": 26,
         "5k": 8,
         "7.5k": 19,
         "10k": 27,
         "12.5k": 24,
         "15k": 22,
         "17.5k": 36,
         "20k": 19,
         "22.5k": 35,
         "25k": 18,
         "27.5k": 23,
         "30k": 37,
         "32.5k": 43,
         "35k": 23,
         "37.5k": 39,
         "40k": 39,
         "42.5k": 47,
         "45k": 30,
         "47.5k": 28,
         "50k": 18,
         "52.5k": 30,
         "55k": 37,
         "57.5k": 46,
         "60k": 27,
         "62.5k": 15,
         "65k": 14,
         "67.5k": 36,
         "70k": 63,
         "72.5k": 22,
         "75k": 27,
         "77.5k": 42,
         "80k": 48,
         "82.5k": 55,
         "85k": 31,
         "87.5k": 30,
         "90k": 24,
         "92.5k": 33,
         "95k": 35,
         "97.5k": 31,
         "100k": 31,
         "200k": 614
      },
      "nonRelativem": {
         "2.5k": 78,
         "5k": 41,
         "7.5k": 49,
         "10k": 59,
         "12.5k": 109,
         "15k": 78,
         "17.5k": 105,
         "20k": 85,
         "22.5k": 197,
         "25k": 112,
         "27.5k": 155,
         "30k": 90,
         "32.5k": 251,
         "35k": 120,
         "37.5k": 218,
         "40k": 119,
         "42.5k": 177,
         "45k": 95,
         "47.5k": 159,
         "50k": 162,
         "52.5k": 174,
         "55k": 137,
         "57.5k": 177,
         "60k": 115,
         "62.5k": 139,
         "65k": 77,
         "67.5k": 114,
         "70k": 80,
         "72.5k": 100,
         "75k": 60,
         "77.5k": 89,
         "80k": 70,
         "82.5k": 51,
         "85k": 53,
         "87.5k": 54,
         "90k": 38,
         "92.5k": 71,
         "95k": 28,
         "97.5k": 34,
         "100k": 11,
         "200k": 550
      },
      "houseLoitererfm": {
         "2.5k": 17,
         "5k": 2,
         "7.5k": 4,
         "10k": 7,
         "12.5k": 4,
         "15k": 4,
         "17.5k": 4,
         "20k": 0,
         "22.5k": 9,
         "25k": 11,
         "27.5k": 10,
         "30k": 9,
         "32.5k": 18,
         "35k": 6,
         "37.5k": 21,
         "40k": 7,
         "42.5k": 12,
         "45k": 8,
         "47.5k": 9,
         "50k": 13,
         "52.5k": 9,
         "55k": 8,
         "57.5k": 12,
         "60k": 13,
         "62.5k": 12,
         "65k": 10,
         "67.5k": 10,
         "70k": 10,
         "72.5k": 12,
         "75k": 12,
         "77.5k": 4,
         "80k": 4,
         "82.5k": 11,
         "85k": 13,
         "87.5k": 5,
         "90k": 5,
         "92.5k": 3,
         "95k": 2,
         "97.5k": 7,
         "100k": 2,
         "200k": 98
      },
      "spousefm": {
         "2.5k": 20,
         "5k": 2,
         "7.5k": 14,
         "10k": 15,
         "12.5k": 38,
         "15k": 18,
         "17.5k": 55,
         "20k": 30,
         "22.5k": 58,
         "25k": 53,
         "27.5k": 74,
         "30k": 44,
         "32.5k": 108,
         "35k": 59,
         "37.5k": 84,
         "40k": 69,
         "42.5k": 87,
         "45k": 74,
         "47.5k": 105,
         "50k": 80,
         "52.5k": 90,
         "55k": 60,
         "57.5k": 117,
         "60k": 82,
         "62.5k": 107,
         "65k": 53,
         "67.5k": 71,
         "70k": 51,
         "72.5k": 85,
         "75k": 59,
         "77.5k": 67,
         "80k": 49,
         "82.5k": 36,
         "85k": 56,
         "87.5k": 38,
         "90k": 32,
         "92.5k": 43,
         "95k": 34,
         "97.5k": 31,
         "100k": 23,
         "200k": 427
      },
      "childfm": {
         "2.5k": 23,
         "5k": 5,
         "7.5k": 8,
         "10k": 14,
         "12.5k": 15,
         "15k": 18,
         "17.5k": 25,
         "20k": 22,
         "22.5k": 27,
         "25k": 18,
         "27.5k": 19,
         "30k": 13,
         "32.5k": 23,
         "35k": 21,
         "37.5k": 32,
         "40k": 37,
         "42.5k": 57,
         "45k": 23,
         "47.5k": 18,
         "50k": 16,
         "52.5k": 44,
         "55k": 20,
         "57.5k": 33,
         "60k": 24,
         "62.5k": 28,
         "65k": 16,
         "67.5k": 43,
         "70k": 21,
         "72.5k": 34,
         "75k": 14,
         "77.5k": 23,
         "80k": 24,
         "82.5k": 20,
         "85k": 4,
         "87.5k": 22,
         "90k": 16,
         "92.5k": 15,
         "95k": 6,
         "97.5k": 9,
         "100k": 1,
         "200k": 192
      },
      "otherRelativefm": {
         "2.5k": 2,
         "5k": 5,
         "7.5k": 5,
         "10k": 13,
         "12.5k": 21,
         "15k": 17,
         "17.5k": 20,
         "20k": 20,
         "22.5k": 15,
         "25k": 8,
         "27.5k": 17,
         "30k": 18,
         "32.5k": 26,
         "35k": 16,
         "37.5k": 24,
         "40k": 19,
         "42.5k": 26,
         "45k": 29,
         "47.5k": 15,
         "50k": 23,
         "52.5k": 37,
         "55k": 20,
         "57.5k": 31,
         "60k": 18,
         "62.5k": 18,
         "65k": 21,
         "67.5k": 19,
         "70k": 26,
         "72.5k": 26,
         "75k": 21,
         "77.5k": 22,
         "80k": 15,
         "82.5k": 21,
         "85k": 20,
         "87.5k": 19,
         "90k": 22,
         "92.5k": 44,
         "95k": 7,
         "97.5k": 29,
         "100k": 19,
         "200k": 441
      },
      "nonRelativefm": {
         "2.5k": 178,
         "5k": 96,
         "7.5k": 124,
         "10k": 121,
         "12.5k": 155,
         "15k": 147,
         "17.5k": 165,
         "20k": 188,
         "22.5k": 160,
         "25k": 149,
         "27.5k": 170,
         "30k": 103,
         "32.5k": 219,
         "35k": 119,
         "37.5k": 159,
         "40k": 103,
         "42.5k": 194,
         "45k": 87,
         "47.5k": 143,
         "50k": 81,
         "52.5k": 162,
         "55k": 65,
         "57.5k": 111,
         "60k": 71,
         "62.5k": 137,
         "65k": 74,
         "67.5k": 76,
         "70k": 43,
         "72.5k": 87,
         "75k": 45,
         "77.5k": 79,
         "80k": 35,
         "82.5k": 69,
         "85k": 29,
         "87.5k": 44,
         "90k": 32,
         "92.5k": 47,
         "95k": 30,
         "97.5k": 39,
         "100k": 29,
         "200k": 379
      }
   },
    "34":{
      "houseLoitererm": {
         "2.5k": 0,
         "5k": 1,
         "7.5k": 7,
         "10k": 2,
         "12.5k": 0,
         "15k": 12,
         "17.5k": 21,
         "20k": 24,
         "22.5k": 15,
         "25k": 11,
         "27.5k": 16,
         "30k": 10,
         "32.5k": 24,
         "35k": 16,
         "37.5k": 36,
         "40k": 28,
         "42.5k": 38,
         "45k": 14,
         "47.5k": 26,
         "50k": 40,
         "52.5k": 41,
         "55k": 31,
         "57.5k": 38,
         "60k": 18,
         "62.5k": 34,
         "65k": 10,
         "67.5k": 27,
         "70k": 10,
         "72.5k": 24,
         "75k": 17,
         "77.5k": 33,
         "80k": 30,
         "82.5k": 25,
         "85k": 23,
         "87.5k": 11,
         "90k": 10,
         "92.5k": 11,
         "95k": 11,
         "97.5k": 4,
         "100k": 6,
         "200k": 236
      },
      "spousem": {
         "2.5k": 13,
         "5k": 4,
         "7.5k": 5,
         "10k": 1,
         "12.5k": 0,
         "15k": 1,
         "17.5k": 8,
         "20k": 13,
         "22.5k": 15,
         "25k": 10,
         "27.5k": 11,
         "30k": 1,
         "32.5k": 27,
         "35k": 12,
         "37.5k": 8,
         "40k": 11,
         "42.5k": 17,
         "45k": 20,
         "47.5k": 21,
         "50k": 8,
         "52.5k": 10,
         "55k": 26,
         "57.5k": 12,
         "60k": 17,
         "62.5k": 12,
         "65k": 7,
         "67.5k": 16,
         "70k": 4,
         "72.5k": 13,
         "75k": 5,
         "77.5k": 20,
         "80k": 8,
         "82.5k": 4,
         "85k": 5,
         "87.5k": 4,
         "90k": 9,
         "92.5k": 8,
         "95k": 3,
         "97.5k": 3,
         "100k": 1,
         "200k": 97
      },
      "childm": {
         "2.5k": 19,
         "5k": 4,
         "7.5k": 5,
         "10k": 3,
         "12.5k": 22,
         "15k": 7,
         "17.5k": 3,
         "20k": 12,
         "22.5k": 23,
         "25k": 24,
         "27.5k": 26,
         "30k": 18,
         "32.5k": 16,
         "35k": 23,
         "37.5k": 26,
         "40k": 6,
         "42.5k": 38,
         "45k": 37,
         "47.5k": 26,
         "50k": 18,
         "52.5k": 28,
         "55k": 21,
         "57.5k": 22,
         "60k": 22,
         "62.5k": 44,
         "65k": 19,
         "67.5k": 7,
         "70k": 20,
         "72.5k": 23,
         "75k": 13,
         "77.5k": 21,
         "80k": 4,
         "82.5k": 12,
         "85k": 14,
         "87.5k": 26,
         "90k": 17,
         "92.5k": 12,
         "95k": 12,
         "97.5k": 11,
         "100k": 4,
         "200k": 175
      },
      "otherRelativem": {
         "2.5k": 24,
         "5k": 10,
         "7.5k": 8,
         "10k": 12,
         "12.5k": 34,
         "15k": 16,
         "17.5k": 44,
         "20k": 19,
         "22.5k": 35,
         "25k": 38,
         "27.5k": 50,
         "30k": 35,
         "32.5k": 70,
         "35k": 53,
         "37.5k": 59,
         "40k": 36,
         "42.5k": 73,
         "45k": 57,
         "47.5k": 57,
         "50k": 68,
         "52.5k": 89,
         "55k": 37,
         "57.5k": 62,
         "60k": 47,
         "62.5k": 77,
         "65k": 54,
         "67.5k": 73,
         "70k": 30,
         "72.5k": 54,
         "75k": 29,
         "77.5k": 80,
         "80k": 34,
         "82.5k": 67,
         "85k": 19,
         "87.5k": 36,
         "90k": 25,
         "92.5k": 44,
         "95k": 19,
         "97.5k": 41,
         "100k": 28,
         "200k": 423
      },
      "nonRelativem": {
         "2.5k": 58,
         "5k": 20,
         "7.5k": 43,
         "10k": 31,
         "12.5k": 75,
         "15k": 63,
         "17.5k": 96,
         "20k": 69,
         "22.5k": 162,
         "25k": 87,
         "27.5k": 176,
         "30k": 151,
         "32.5k": 162,
         "35k": 86,
         "37.5k": 184,
         "40k": 120,
         "42.5k": 196,
         "45k": 94,
         "47.5k": 160,
         "50k": 110,
         "52.5k": 169,
         "55k": 95,
         "57.5k": 148,
         "60k": 104,
         "62.5k": 214,
         "65k": 100,
         "67.5k": 114,
         "70k": 81,
         "72.5k": 169,
         "75k": 99,
         "77.5k": 145,
         "80k": 63,
         "82.5k": 105,
         "85k": 75,
         "87.5k": 72,
         "90k": 50,
         "92.5k": 115,
         "95k": 57,
         "97.5k": 79,
         "100k": 49,
         "200k": 859
      },
      "houseLoitererfm": {
         "2.5k": 3,
         "5k": 1,
         "7.5k": 3,
         "10k": 7,
         "12.5k": 9,
         "15k": 10,
         "17.5k": 6,
         "20k": 8,
         "22.5k": 15,
         "25k": 13,
         "27.5k": 15,
         "30k": 4,
         "32.5k": 18,
         "35k": 11,
         "37.5k": 10,
         "40k": 21,
         "42.5k": 7,
         "45k": 10,
         "47.5k": 13,
         "50k": 9,
         "52.5k": 10,
         "55k": 16,
         "57.5k": 19,
         "60k": 7,
         "62.5k": 6,
         "65k": 7,
         "67.5k": 22,
         "70k": 15,
         "72.5k": 19,
         "75k": 15,
         "77.5k": 2,
         "80k": 11,
         "82.5k": 4,
         "85k": 4,
         "87.5k": 5,
         "90k": 5,
         "92.5k": 9,
         "95k": 4,
         "97.5k": 7,
         "100k": 4,
         "200k": 109
      },
      "spousefm": {
         "2.5k": 2,
         "5k": 1,
         "7.5k": 7,
         "10k": 1,
         "12.5k": 3,
         "15k": 0,
         "17.5k": 11,
         "20k": 5,
         "22.5k": 4,
         "25k": 0,
         "27.5k": 7,
         "30k": 5,
         "32.5k": 5,
         "35k": 6,
         "37.5k": 12,
         "40k": 4,
         "42.5k": 8,
         "45k": 11,
         "47.5k": 12,
         "50k": 5,
         "52.5k": 3,
         "55k": 9,
         "57.5k": 10,
         "60k": 10,
         "62.5k": 11,
         "65k": 2,
         "67.5k": 8,
         "70k": 7,
         "72.5k": 4,
         "75k": 4,
         "77.5k": 11,
         "80k": 4,
         "82.5k": 7,
         "85k": 6,
         "87.5k": 3,
         "90k": 0,
         "92.5k": 2,
         "95k": 1,
         "97.5k": 2,
         "100k": 3,
         "200k": 64
      },
      "childfm": {
         "2.5k": 2,
         "5k": 3,
         "7.5k": 8,
         "10k": 4,
         "12.5k": 6,
         "15k": 9,
         "17.5k": 3,
         "20k": 18,
         "22.5k": 3,
         "25k": 14,
         "27.5k": 17,
         "30k": 17,
         "32.5k": 11,
         "35k": 7,
         "37.5k": 20,
         "40k": 15,
         "42.5k": 6,
         "45k": 7,
         "47.5k": 17,
         "50k": 9,
         "52.5k": 9,
         "55k": 19,
         "57.5k": 26,
         "60k": 10,
         "62.5k": 25,
         "65k": 16,
         "67.5k": 15,
         "70k": 9,
         "72.5k": 10,
         "75k": 7,
         "77.5k": 11,
         "80k": 22,
         "82.5k": 14,
         "85k": 6,
         "87.5k": 6,
         "90k": 6,
         "92.5k": 15,
         "95k": 7,
         "97.5k": 2,
         "100k": 6,
         "200k": 95
      },
      "otherRelativefm": {
         "2.5k": 8,
         "5k": 7,
         "7.5k": 8,
         "10k": 9,
         "12.5k": 29,
         "15k": 29,
         "17.5k": 33,
         "20k": 48,
         "22.5k": 77,
         "25k": 36,
         "27.5k": 83,
         "30k": 63,
         "32.5k": 84,
         "35k": 46,
         "37.5k": 85,
         "40k": 61,
         "42.5k": 120,
         "45k": 68,
         "47.5k": 90,
         "50k": 64,
         "52.5k": 131,
         "55k": 87,
         "57.5k": 115,
         "60k": 76,
         "62.5k": 131,
         "65k": 86,
         "67.5k": 101,
         "70k": 65,
         "72.5k": 121,
         "75k": 81,
         "77.5k": 98,
         "80k": 63,
         "82.5k": 100,
         "85k": 43,
         "87.5k": 86,
         "90k": 49,
         "92.5k": 99,
         "95k": 43,
         "97.5k": 64,
         "100k": 33,
         "200k": 880
      },
      "nonRelativefm": {
         "2.5k": 140,
         "5k": 80,
         "7.5k": 102,
         "10k": 114,
         "12.5k": 124,
         "15k": 104,
         "17.5k": 157,
         "20k": 140,
         "22.5k": 170,
         "25k": 132,
         "27.5k": 157,
         "30k": 115,
         "32.5k": 179,
         "35k": 123,
         "37.5k": 181,
         "40k": 97,
         "42.5k": 169,
         "45k": 92,
         "47.5k": 97,
         "50k": 128,
         "52.5k": 147,
         "55k": 77,
         "57.5k": 142,
         "60k": 92,
         "62.5k": 137,
         "65k": 55,
         "67.5k": 119,
         "70k": 57,
         "72.5k": 97,
         "75k": 45,
         "77.5k": 79,
         "80k": 62,
         "82.5k": 90,
         "85k": 46,
         "87.5k": 66,
         "90k": 44,
         "92.5k": 73,
         "95k": 37,
         "97.5k": 36,
         "100k": 30,
         "200k": 657
      }
   },
    "44":{
      "houseLoitererm": {
         "2.5k": 10,
         "5k": 10,
         "7.5k": 15,
         "10k": 25,
         "12.5k": 17,
         "15k": 13,
         "17.5k": 9,
         "20k": 15,
         "22.5k": 28,
         "25k": 10,
         "27.5k": 33,
         "30k": 30,
         "32.5k": 45,
         "35k": 26,
         "37.5k": 51,
         "40k": 30,
         "42.5k": 43,
         "45k": 21,
         "47.5k": 53,
         "50k": 31,
         "52.5k": 51,
         "55k": 58,
         "57.5k": 23,
         "60k": 29,
         "62.5k": 29,
         "65k": 22,
         "67.5k": 52,
         "70k": 34,
         "72.5k": 41,
         "75k": 14,
         "77.5k": 28,
         "80k": 18,
         "82.5k": 32,
         "85k": 14,
         "87.5k": 23,
         "90k": 25,
         "92.5k": 17,
         "95k": 19,
         "97.5k": 29,
         "100k": 9,
         "200k": "332"
      },
      "spousem": {
         "2.5k": 11,
         "5k": 6,
         "7.5k": 35,
         "10k": 28,
         "12.5k": 39,
         "15k": 39,
         "17.5k": 54,
         "20k": 27,
         "22.5k": 35,
         "25k": 38,
         "27.5k": 38,
         "30k": 34,
         "32.5k": 39,
         "35k": 34,
         "37.5k": 54,
         "40k": 41,
         "42.5k": 31,
         "45k": 33,
         "47.5k": 47,
         "50k": 46,
         "52.5k": 47,
         "55k": 49,
         "57.5k": 47,
         "60k": 38,
         "62.5k": 44,
         "65k": 32,
         "67.5k": 53,
         "70k": 48,
         "72.5k": 32,
         "75k": 14,
         "77.5k": 41,
         "80k": 47,
         "82.5k": 26,
         "85k": 29,
         "87.5k": 14,
         "90k": 16,
         "92.5k": 39,
         "95k": 13,
         "97.5k": 24,
         "100k": 20,
         "200k": "259"
      },
      "childm": {
         "2.5k": 23,
         "5k": 11,
         "7.5k": 7,
         "10k": 7,
         "12.5k": 18,
         "15k": 4,
         "17.5k": 8,
         "20k": 20,
         "22.5k": 15,
         "25k": 27,
         "27.5k": 32,
         "30k": 30,
         "32.5k": 26,
         "35k": 28,
         "37.5k": 25,
         "40k": 23,
         "42.5k": 31,
         "45k": 16,
         "47.5k": 15,
         "50k": 20,
         "52.5k": 37,
         "55k": 28,
         "57.5k": 5,
         "60k": 17,
         "62.5k": 48,
         "65k": 36,
         "67.5k": 22,
         "70k": 6,
         "72.5k": 38,
         "75k": 12,
         "77.5k": 16,
         "80k": 9,
         "82.5k": 21,
         "85k": 1,
         "87.5k": 22,
         "90k": 16,
         "92.5k": 13,
         "95k": 16,
         "97.5k": 14,
         "100k": 10,
         "200k": "204"
      },
      "otherRelativem": {
         "2.5k": 31,
         "5k": 6,
         "7.5k": 14,
         "10k": 23,
         "12.5k": 32,
         "15k": 41,
         "17.5k": 70,
         "20k": 73,
         "22.5k": 64,
         "25k": 67,
         "27.5k": 93,
         "30k": 60,
         "32.5k": 117,
         "35k": 74,
         "37.5k": 127,
         "40k": 81,
         "42.5k": 126,
         "45k": 94,
         "47.5k": 136,
         "50k": 113,
         "52.5k": 150,
         "55k": 84,
         "57.5k": 140,
         "60k": 109,
         "62.5k": 167,
         "65k": 109,
         "67.5k": 129,
         "70k": 89,
         "72.5k": 134,
         "75k": 99,
         "77.5k": 127,
         "80k": 89,
         "82.5k": 118,
         "85k": 81,
         "87.5k": 104,
         "90k": 80,
         "92.5k": 118,
         "95k": 65,
         "97.5k": 97,
         "100k": 63,
         "200k": "1,599"
      },
      "nonRelativem": {
         "2.5k": 177,
         "5k": 45,
         "7.5k": 115,
         "10k": 108,
         "12.5k": 165,
         "15k": 128,
         "17.5k": 204,
         "20k": 190,
         "22.5k": 243,
         "25k": 210,
         "27.5k": 279,
         "30k": 195,
         "32.5k": 376,
         "35k": 206,
         "37.5k": 359,
         "40k": 198,
         "42.5k": 377,
         "45k": 240,
         "47.5k": 331,
         "50k": 256,
         "52.5k": 478,
         "55k": 225,
         "57.5k": 339,
         "60k": 191,
         "62.5k": 353,
         "65k": 233,
         "67.5k": 276,
         "70k": 196,
         "72.5k": 299,
         "75k": 198,
         "77.5k": 237,
         "80k": 180,
         "82.5k": 270,
         "85k": 159,
         "87.5k": 255,
         "90k": 161,
         "92.5k": 217,
         "95k": 163,
         "97.5k": 164,
         "100k": 147,
         "200k": "2,972"
      },
      "houseLoitererfm": {
         "2.5k": 2,
         "5k": 3,
         "7.5k": 7,
         "10k": 5,
         "12.5k": 8,
         "15k": 18,
         "17.5k": 21,
         "20k": 10,
         "22.5k": 15,
         "25k": 22,
         "27.5k": 14,
         "30k": 27,
         "32.5k": 26,
         "35k": 23,
         "37.5k": 25,
         "40k": 29,
         "42.5k": 31,
         "45k": 24,
         "47.5k": 30,
         "50k": 20,
         "52.5k": 29,
         "55k": 21,
         "57.5k": 21,
         "60k": 18,
         "62.5k": 17,
         "65k": 12,
         "67.5k": 34,
         "70k": 22,
         "72.5k": 37,
         "75k": 14,
         "77.5k": 24,
         "80k": 13,
         "82.5k": 28,
         "85k": 8,
         "87.5k": 14,
         "90k": 12,
         "92.5k": 38,
         "95k": 8,
         "97.5k": 10,
         "100k": 11,
         "200k": "205"
      },
      "spousefm": {
         "2.5k": 6,
         "5k": 8,
         "7.5k": 7,
         "10k": 22,
         "12.5k": 8,
         "15k": 25,
         "17.5k": 13,
         "20k": 23,
         "22.5k": 29,
         "25k": 19,
         "27.5k": 16,
         "30k": 14,
         "32.5k": 14,
         "35k": 14,
         "37.5k": 22,
         "40k": 24,
         "42.5k": 14,
         "45k": 17,
         "47.5k": 17,
         "50k": 16,
         "52.5k": 16,
         "55k": 18,
         "57.5k": 19,
         "60k": 19,
         "62.5k": 15,
         "65k": 18,
         "67.5k": 19,
         "70k": 13,
         "72.5k": 18,
         "75k": 10,
         "77.5k": 16,
         "80k": 12,
         "82.5k": 6,
         "85k": 9,
         "87.5k": 7,
         "90k": 11,
         "92.5k": 5,
         "95k": 6,
         "97.5k": 10,
         "100k": 2,
         "200k": "142"
      },
      "childfm": {
         "2.5k": 4,
         "5k": 7,
         "7.5k": 5,
         "10k": 13,
         "12.5k": 15,
         "15k": 19,
         "17.5k": 18,
         "20k": 13,
         "22.5k": 15,
         "25k": 22,
         "27.5k": 30,
         "30k": 14,
         "32.5k": 27,
         "35k": 19,
         "37.5k": 22,
         "40k": 19,
         "42.5k": 20,
         "45k": 13,
         "47.5k": 11,
         "50k": 15,
         "52.5k": 17,
         "55k": 20,
         "57.5k": 12,
         "60k": 16,
         "62.5k": 23,
         "65k": 10,
         "67.5k": 13,
         "70k": 18,
         "72.5k": 14,
         "75k": 10,
         "77.5k": 18,
         "80k": 2,
         "82.5k": 18,
         "85k": 10,
         "87.5k": 13,
         "90k": 8,
         "92.5k": 6,
         "95k": 7,
         "97.5k": 3,
         "100k": 10,
         "200k": "121"
      },
      "otherRelativefm": {
         "2.5k": 51,
         "5k": 13,
         "7.5k": 31,
         "10k": 33,
         "12.5k": 48,
         "15k": 57,
         "17.5k": 69,
         "20k": 73,
         "22.5k": 117,
         "25k": 79,
         "27.5k": 132,
         "30k": 114,
         "32.5k": 174,
         "35k": 106,
         "37.5k": 155,
         "40k": 88,
         "42.5k": 187,
         "45k": 150,
         "47.5k": 181,
         "50k": 155,
         "52.5k": 255,
         "55k": 150,
         "57.5k": 225,
         "60k": 152,
         "62.5k": 212,
         "65k": 151,
         "67.5k": 187,
         "70k": 153,
         "72.5k": 231,
         "75k": 169,
         "77.5k": 183,
         "80k": 139,
         "82.5k": 237,
         "85k": 161,
         "87.5k": 194,
         "90k": 138,
         "92.5k": 180,
         "95k": 149,
         "97.5k": 152,
         "100k": 141,
         "200k": "2,907"
      },
      "nonRelativefm": {
         "2.5k": 290,
         "5k": 89,
         "7.5k": 244,
         "10k": 221,
         "12.5k": 207,
         "15k": 255,
         "17.5k": 295,
         "20k": 251,
         "22.5k": 342,
         "25k": 265,
         "27.5k": 310,
         "30k": 248,
         "32.5k": 355,
         "35k": 248,
         "37.5k": 340,
         "40k": 231,
         "42.5k": 348,
         "45k": 205,
         "47.5k": 306,
         "50k": 235,
         "52.5k": 290,
         "55k": 169,
         "57.5k": 235,
         "60k": 186,
         "62.5k": 291,
         "65k": 195,
         "67.5k": 225,
         "70k": 168,
         "72.5k": 192,
         "75k": 130,
         "77.5k": 194,
         "80k": 135,
         "82.5k": 211,
         "85k": 120,
         "87.5k": 132,
         "90k": 105,
         "92.5k": 172,
         "95k": 79,
         "97.5k": 129,
         "100k": 96,
         "200k": "2,167"
      }
   },
    "54":{
      "houseLoitererm": {
         "2.5k": 5,
         "5k": 0,
         "7.5k": 28,
         "10k": 5,
         "12.5k": 24,
         "15k": 9,
         "17.5k": 23,
         "20k": 11,
         "22.5k": 18,
         "25k": 6,
         "27.5k": 27,
         "30k": 24,
         "32.5k": 20,
         "35k": 17,
         "37.5k": 33,
         "40k": 29,
         "42.5k": 39,
         "45k": 10,
         "47.5k": 28,
         "50k": 9,
         "52.5k": 29,
         "55k": 22,
         "57.5k": 11,
         "60k": 24,
         "62.5k": 36,
         "65k": 30,
         "67.5k": 27,
         "70k": 24,
         "72.5k": 15,
         "75k": 13,
         "77.5k": 37,
         "80k": 12,
         "82.5k": 14,
         "85k": 17,
         "87.5k": 24,
         "90k": 14,
         "92.5k": 16,
         "95k": 8,
         "97.5k": 13,
         "100k": 13,
         "200k": "216"
      },
      "spousem": {
         "2.5k": 11,
         "5k": 8,
         "7.5k": 18,
         "10k": 21,
         "12.5k": 31,
         "15k": 36,
         "17.5k": 28,
         "20k": 41,
         "22.5k": 20,
         "25k": 34,
         "27.5k": 32,
         "30k": 28,
         "32.5k": 39,
         "35k": 26,
         "37.5k": 41,
         "40k": 52,
         "42.5k": 18,
         "45k": 20,
         "47.5k": 26,
         "50k": 29,
         "52.5k": 22,
         "55k": 19,
         "57.5k": 14,
         "60k": 8,
         "62.5k": 11,
         "65k": 13,
         "67.5k": 19,
         "70k": 12,
         "72.5k": 14,
         "75k": 8,
         "77.5k": 14,
         "80k": 5,
         "82.5k": 3,
         "85k": 16,
         "87.5k": 6,
         "90k": 2,
         "92.5k": 21,
         "95k": 0,
         "97.5k": 18,
         "100k": 14,
         "200k": "129"
      },
      "childm": {
         "2.5k": 20,
         "5k": 0,
         "7.5k": 5,
         "10k": 13,
         "12.5k": 15,
         "15k": 14,
         "17.5k": 19,
         "20k": 17,
         "22.5k": 24,
         "25k": 16,
         "27.5k": 39,
         "30k": 37,
         "32.5k": 22,
         "35k": 27,
         "37.5k": 24,
         "40k": 20,
         "42.5k": 32,
         "45k": 26,
         "47.5k": 34,
         "50k": 27,
         "52.5k": 24,
         "55k": 30,
         "57.5k": 19,
         "60k": 26,
         "62.5k": 27,
         "65k": 17,
         "67.5k": 24,
         "70k": 14,
         "72.5k": 27,
         "75k": 19,
         "77.5k": 25,
         "80k": 12,
         "82.5k": 23,
         "85k": 16,
         "87.5k": 9,
         "90k": 21,
         "92.5k": 6,
         "95k": 11,
         "97.5k": 10,
         "100k": 2,
         "200k": "302"
      },
      "otherRelativem": {
         "2.5k": 41,
         "5k": 9,
         "7.5k": 21,
         "10k": 22,
         "12.5k": 39,
         "15k": 29,
         "17.5k": 29,
         "20k": 61,
         "22.5k": 47,
         "25k": 48,
         "27.5k": 54,
         "30k": 59,
         "32.5k": 129,
         "35k": 64,
         "37.5k": 110,
         "40k": 84,
         "42.5k": 100,
         "45k": 80,
         "47.5k": 107,
         "50k": 101,
         "52.5k": 113,
         "55k": 96,
         "57.5k": 126,
         "60k": 69,
         "62.5k": 145,
         "65k": 94,
         "67.5k": 122,
         "70k": 98,
         "72.5k": 109,
         "75k": 83,
         "77.5k": 126,
         "80k": 99,
         "82.5k": 134,
         "85k": 92,
         "87.5k": 86,
         "90k": 93,
         "92.5k": 127,
         "95k": 64,
         "97.5k": 79,
         "100k": 73,
         "200k": "1,934"
      },
      "nonRelativem": {
         "2.5k": 189,
         "5k": 75,
         "7.5k": 142,
         "10k": 177,
         "12.5k": 199,
         "15k": 176,
         "17.5k": 199,
         "20k": 203,
         "22.5k": 215,
         "25k": 172,
         "27.5k": 243,
         "30k": 191,
         "32.5k": 303,
         "35k": 204,
         "37.5k": 305,
         "40k": 222,
         "42.5k": 365,
         "45k": 218,
         "47.5k": 317,
         "50k": 229,
         "52.5k": 374,
         "55k": 206,
         "57.5k": 254,
         "60k": 195,
         "62.5k": 334,
         "65k": 179,
         "67.5k": 314,
         "70k": 206,
         "72.5k": 304,
         "75k": 214,
         "77.5k": 318,
         "80k": 184,
         "82.5k": 287,
         "85k": 188,
         "87.5k": 198,
         "90k": 178,
         "92.5k": 248,
         "95k": 164,
         "97.5k": 168,
         "100k": 143,
         "200k": "3,758"
      },
      "houseLoitererfm": {
         "2.5k": 13,
         "5k": 3,
         "7.5k": 21,
         "10k": 10,
         "12.5k": 6,
         "15k": 9,
         "17.5k": 10,
         "20k": 21,
         "22.5k": 34,
         "25k": 18,
         "27.5k": 34,
         "30k": 26,
         "32.5k": 35,
         "35k": 23,
         "37.5k": 36,
         "40k": 26,
         "42.5k": 39,
         "45k": 16,
         "47.5k": 32,
         "50k": 18,
         "52.5k": 38,
         "55k": 12,
         "57.5k": 17,
         "60k": 23,
         "62.5k": 22,
         "65k": 14,
         "67.5k": 20,
         "70k": 11,
         "72.5k": 21,
         "75k": 18,
         "77.5k": 28,
         "80k": 9,
         "82.5k": 24,
         "85k": 23,
         "87.5k": 20,
         "90k": 8,
         "92.5k": 23,
         "95k": 11,
         "97.5k": 22,
         "100k": 6,
         "200k": "240"
      },
      "spousefm": {
         "2.5k": 5,
         "5k": 0,
         "7.5k": 4,
         "10k": 8,
         "12.5k": 12,
         "15k": 8,
         "17.5k": 13,
         "20k": 26,
         "22.5k": 8,
         "25k": 13,
         "27.5k": 20,
         "30k": 13,
         "32.5k": 26,
         "35k": 27,
         "37.5k": 9,
         "40k": 16,
         "42.5k": 5,
         "45k": 19,
         "47.5k": 17,
         "50k": 18,
         "52.5k": 9,
         "55k": 13,
         "57.5k": 17,
         "60k": 4,
         "62.5k": 16,
         "65k": 15,
         "67.5k": 8,
         "70k": 1,
         "72.5k": 8,
         "75k": 6,
         "77.5k": 10,
         "80k": 3,
         "82.5k": 1,
         "85k": 5,
         "87.5k": 5,
         "90k": 6,
         "92.5k": 4,
         "95k": 4,
         "97.5k": 1,
         "100k": 3,
         "200k": "55"
      },
      "childfm": {
         "2.5k": 12,
         "5k": 1,
         "7.5k": 9,
         "10k": 9,
         "12.5k": 16,
         "15k": 16,
         "17.5k": 10,
         "20k": 12,
         "22.5k": 17,
         "25k": 11,
         "27.5k": 22,
         "30k": 15,
         "32.5k": 18,
         "35k": 21,
         "37.5k": 20,
         "40k": 8,
         "42.5k": 13,
         "45k": 16,
         "47.5k": 20,
         "50k": 23,
         "52.5k": 9,
         "55k": 14,
         "57.5k": 15,
         "60k": 14,
         "62.5k": 13,
         "65k": 9,
         "67.5k": 25,
         "70k": 11,
         "72.5k": 18,
         "75k": 7,
         "77.5k": 11,
         "80k": 8,
         "82.5k": 13,
         "85k": 11,
         "87.5k": 21,
         "90k": 15,
         "92.5k": 12,
         "95k": 5,
         "97.5k": 5,
         "100k": 1,
         "200k": "194"
      },
      "otherRelativefm": {
         "2.5k": 59,
         "5k": 27,
         "7.5k": 31,
         "10k": 36,
         "12.5k": 49,
         "15k": 63,
         "17.5k": 78,
         "20k": 78,
         "22.5k": 96,
         "25k": 71,
         "27.5k": 101,
         "30k": 82,
         "32.5k": 137,
         "35k": 103,
         "37.5k": 114,
         "40k": 141,
         "42.5k": 210,
         "45k": 139,
         "47.5k": 180,
         "50k": 131,
         "52.5k": 190,
         "55k": 148,
         "57.5k": 159,
         "60k": 163,
         "62.5k": 233,
         "65k": 166,
         "67.5k": 211,
         "70k": 190,
         "72.5k": 211,
         "75k": 171,
         "77.5k": 217,
         "80k": 159,
         "82.5k": 162,
         "85k": 155,
         "87.5k": 159,
         "90k": 149,
         "92.5k": 194,
         "95k": 128,
         "97.5k": 130,
         "100k": 134,
         "200k": "3,264"
      },
      "nonRelativefm": {
         "2.5k": 295,
         "5k": 92,
         "7.5k": 256,
         "10k": 233,
         "12.5k": 216,
         "15k": 222,
         "17.5k": 248,
         "20k": 282,
         "22.5k": 316,
         "25k": 190,
         "27.5k": 268,
         "30k": 210,
         "32.5k": 383,
         "35k": 221,
         "37.5k": 336,
         "40k": 223,
         "42.5k": 317,
         "45k": 243,
         "47.5k": 263,
         "50k": 238,
         "52.5k": 271,
         "55k": 173,
         "57.5k": 267,
         "60k": 164,
         "62.5k": 249,
         "65k": 179,
         "67.5k": 252,
         "70k": 152,
         "72.5k": 223,
         "75k": 165,
         "77.5k": 189,
         "80k": 130,
         "82.5k": 163,
         "85k": 159,
         "87.5k": 156,
         "90k": 140,
         "92.5k": 156,
         "95k": 102,
         "97.5k": 118,
         "100k": 89,
         "200k": "2,423"
      }
   },
    "64":{
      "houseLoitererm": {
         "2.5k": 4,
         "5k": 0,
         "7.5k": 3,
         "10k": 7,
         "12.5k": 11,
         "15k": 9,
         "17.5k": 13,
         "20k": 17,
         "22.5k": 6,
         "25k": 5,
         "27.5k": 1,
         "30k": 3,
         "32.5k": 9,
         "35k": 8,
         "37.5k": 9,
         "40k": 3,
         "42.5k": 4,
         "45k": 0,
         "47.5k": 4,
         "50k": 1,
         "52.5k": 4,
         "55k": 31,
         "57.5k": 2,
         "60k": 6,
         "62.5k": 4,
         "65k": 0,
         "67.5k": 7,
         "70k": 2,
         "72.5k": 0,
         "75k": 1,
         "77.5k": 3,
         "80k": 2,
         "82.5k": 2,
         "85k": 1,
         "87.5k": 0,
         "90k": 7,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 2,
         "100k": 5,
         "200k": "12"
      },
      "spousem": {
         "2.5k": 6,
         "5k": 10,
         "7.5k": 5,
         "10k": 12,
         "12.5k": 12,
         "15k": 11,
         "17.5k": 6,
         "20k": 14,
         "22.5k": 9,
         "25k": 1,
         "27.5k": 20,
         "30k": 5,
         "32.5k": 10,
         "35k": 14,
         "37.5k": 26,
         "40k": 3,
         "42.5k": 15,
         "45k": 12,
         "47.5k": 8,
         "50k": 5,
         "52.5k": 7,
         "55k": 6,
         "57.5k": 5,
         "60k": 4,
         "62.5k": 22,
         "65k": 9,
         "67.5k": 4,
         "70k": 12,
         "72.5k": 5,
         "75k": 3,
         "77.5k": 11,
         "80k": 0,
         "82.5k": 11,
         "85k": 8,
         "87.5k": 8,
         "90k": 1,
         "92.5k": 2,
         "95k": 9,
         "97.5k": 1,
         "100k": 5,
         "200k": "139"
      },
      "childm": {
         "2.5k": 16,
         "5k": 0,
         "7.5k": 0,
         "10k": 3,
         "12.5k": 5,
         "15k": 12,
         "17.5k": 10,
         "20k": 7,
         "22.5k": 18,
         "25k": 9,
         "27.5k": 16,
         "30k": 15,
         "32.5k": 13,
         "35k": 4,
         "37.5k": 21,
         "40k": 14,
         "42.5k": 14,
         "45k": 9,
         "47.5k": 22,
         "50k": 11,
         "52.5k": 26,
         "55k": 6,
         "57.5k": 11,
         "60k": 12,
         "62.5k": 3,
         "65k": 4,
         "67.5k": 13,
         "70k": 5,
         "72.5k": 4,
         "75k": 8,
         "77.5k": 7,
         "80k": 7,
         "82.5k": 13,
         "85k": 9,
         "87.5k": 19,
         "90k": 6,
         "92.5k": 13,
         "95k": 10,
         "97.5k": 7,
         "100k": 11,
         "200k": "139"
      },
      "otherRelativem": {
         "2.5k": 37,
         "5k": 8,
         "7.5k": 23,
         "10k": 16,
         "12.5k": 38,
         "15k": 44,
         "17.5k": 26,
         "20k": 50,
         "22.5k": 52,
         "25k": 43,
         "27.5k": 80,
         "30k": 34,
         "32.5k": 88,
         "35k": 56,
         "37.5k": 83,
         "40k": 93,
         "42.5k": 136,
         "45k": 49,
         "47.5k": 66,
         "50k": 74,
         "52.5k": 82,
         "55k": 72,
         "57.5k": 78,
         "60k": 67,
         "62.5k": 90,
         "65k": 73,
         "67.5k": 82,
         "70k": 65,
         "72.5k": 110,
         "75k": 45,
         "77.5k": 73,
         "80k": 66,
         "82.5k": 73,
         "85k": 47,
         "87.5k": 65,
         "90k": 68,
         "92.5k": 73,
         "95k": 41,
         "97.5k": 53,
         "100k": 57,
         "200k": "1,202"
      },
      "nonRelativem": {
         "2.5k": 172,
         "5k": 86,
         "7.5k": 115,
         "10k": 142,
         "12.5k": 192,
         "15k": 158,
         "17.5k": 196,
         "20k": 187,
         "22.5k": 256,
         "25k": 203,
         "27.5k": 242,
         "30k": 178,
         "32.5k": 289,
         "35k": 174,
         "37.5k": 216,
         "40k": 220,
         "42.5k": 266,
         "45k": 219,
         "47.5k": 240,
         "50k": 217,
         "52.5k": 263,
         "55k": 165,
         "57.5k": 212,
         "60k": 186,
         "62.5k": 265,
         "65k": 177,
         "67.5k": 182,
         "70k": 221,
         "72.5k": 201,
         "75k": 146,
         "77.5k": 181,
         "80k": 144,
         "82.5k": 187,
         "85k": 141,
         "87.5k": 117,
         "90k": 115,
         "92.5k": 127,
         "95k": 106,
         "97.5k": 93,
         "100k": 84,
         "200k": "2,660"
      },
      "houseLoitererfm": {
         "2.5k": 2,
         "5k": 2,
         "7.5k": 6,
         "10k": 3,
         "12.5k": 3,
         "15k": 7,
         "17.5k": 5,
         "20k": 4,
         "22.5k": 5,
         "25k": 6,
         "27.5k": 11,
         "30k": 18,
         "32.5k": 5,
         "35k": 1,
         "37.5k": 5,
         "40k": 8,
         "42.5k": 10,
         "45k": 9,
         "47.5k": 8,
         "50k": 1,
         "52.5k": 10,
         "55k": 6,
         "57.5k": 12,
         "60k": 4,
         "62.5k": 7,
         "65k": 4,
         "67.5k": 4,
         "70k": 0,
         "72.5k": 1,
         "75k": 2,
         "77.5k": 0,
         "80k": 2,
         "82.5k": 2,
         "85k": 0,
         "87.5k": 0,
         "90k": 1,
         "92.5k": 3,
         "95k": 0,
         "97.5k": 2,
         "100k": 3,
         "200k": "21"
      },
      "spousefm": {
         "2.5k": 9,
         "5k": 0,
         "7.5k": 4,
         "10k": 5,
         "12.5k": 0,
         "15k": 4,
         "17.5k": 4,
         "20k": 5,
         "22.5k": 5,
         "25k": 8,
         "27.5k": 9,
         "30k": 6,
         "32.5k": 21,
         "35k": 5,
         "37.5k": 10,
         "40k": 4,
         "42.5k": 3,
         "45k": 7,
         "47.5k": 10,
         "50k": 3,
         "52.5k": 13,
         "55k": 6,
         "57.5k": 10,
         "60k": 7,
         "62.5k": 4,
         "65k": 6,
         "67.5k": 6,
         "70k": 2,
         "72.5k": 3,
         "75k": 8,
         "77.5k": 5,
         "80k": 9,
         "82.5k": 7,
         "85k": 2,
         "87.5k": 4,
         "90k": 7,
         "92.5k": 3,
         "95k": 5,
         "97.5k": 7,
         "100k": 6,
         "200k": "88"
      },
      "childfm": {
         "2.5k": 8,
         "5k": 1,
         "7.5k": 12,
         "10k": 9,
         "12.5k": 8,
         "15k": 17,
         "17.5k": 25,
         "20k": 15,
         "22.5k": 22,
         "25k": 16,
         "27.5k": 23,
         "30k": 19,
         "32.5k": 22,
         "35k": 13,
         "37.5k": 25,
         "40k": 19,
         "42.5k": 20,
         "45k": 15,
         "47.5k": 10,
         "50k": 17,
         "52.5k": 33,
         "55k": 20,
         "57.5k": 15,
         "60k": 19,
         "62.5k": 9,
         "65k": 13,
         "67.5k": 16,
         "70k": 7,
         "72.5k": 8,
         "75k": 18,
         "77.5k": 13,
         "80k": 13,
         "82.5k": 7,
         "85k": 18,
         "87.5k": 15,
         "90k": 6,
         "92.5k": 16,
         "95k": 14,
         "97.5k": 9,
         "100k": 15,
         "200k": "173"
      },
      "otherRelativefm": {
         "2.5k": 75,
         "5k": 31,
         "7.5k": 19,
         "10k": 40,
         "12.5k": 66,
         "15k": 97,
         "17.5k": 85,
         "20k": 99,
         "22.5k": 120,
         "25k": 154,
         "27.5k": 140,
         "30k": 119,
         "32.5k": 206,
         "35k": 118,
         "37.5k": 114,
         "40k": 136,
         "42.5k": 171,
         "45k": 143,
         "47.5k": 184,
         "50k": 153,
         "52.5k": 189,
         "55k": 110,
         "57.5k": 167,
         "60k": 122,
         "62.5k": 196,
         "65k": 144,
         "67.5k": 133,
         "70k": 147,
         "72.5k": 143,
         "75k": 109,
         "77.5k": 111,
         "80k": 119,
         "82.5k": 128,
         "85k": 108,
         "87.5k": 95,
         "90k": 81,
         "92.5k": 91,
         "95k": 81,
         "97.5k": 74,
         "100k": 59,
         "200k": "1,796"
      },
      "nonRelativefm": {
         "2.5k": 254,
         "5k": 91,
         "7.5k": 287,
         "10k": 275,
         "12.5k": 288,
         "15k": 270,
         "17.5k": 273,
         "20k": 243,
         "22.5k": 339,
         "25k": 176,
         "27.5k": 294,
         "30k": 204,
         "32.5k": 282,
         "35k": 176,
         "37.5k": 270,
         "40k": 196,
         "42.5k": 282,
         "45k": 154,
         "47.5k": 218,
         "50k": 174,
         "52.5k": 202,
         "55k": 175,
         "57.5k": 189,
         "60k": 110,
         "62.5k": 186,
         "65k": 118,
         "67.5k": 130,
         "70k": 122,
         "72.5k": 137,
         "75k": 93,
         "77.5k": 104,
         "80k": 101,
         "82.5k": 83,
         "85k": 75,
         "87.5k": 76,
         "90k": 96,
         "92.5k": 82,
         "95k": 43,
         "97.5k": 59,
         "100k": 77,
         "200k": "1,320"
      }
   },
    "74":{
      "nonRelativem": {
         "2.5k": 0,
         "5k": 0,
         "7.5k": 0,
         "10k": 2,
         "12.5k": 1,
         "15k": 0,
         "17.5k": 0,
         "20k": 0,
         "22.5k": 1,
         "25k": 0,
         "27.5k": 3,
         "30k": 0,
         "32.5k": 0,
         "35k": 0,
         "37.5k": 0,
         "40k": 0,
         "42.5k": 0,
         "45k": 0,
         "47.5k": 0,
         "50k": 1,
         "52.5k": 0,
         "55k": 0,
         "57.5k": 0,
         "60k": 0,
         "62.5k": 0,
         "65k": 0,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 2,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": 2
      },
      "otherRelativem": {
         "2.5k": 2,
         "5k": 0,
         "7.5k": 2,
         "10k": 6,
         "12.5k": 2,
         "15k": 3,
         "17.5k": 7,
         "20k": 6,
         "22.5k": 7,
         "25k": 1,
         "27.5k": 15,
         "30k": 1,
         "32.5k": 4,
         "35k": 4,
         "37.5k": 5,
         "40k": 6,
         "42.5k": 1,
         "45k": 4,
         "47.5k": 5,
         "50k": 1,
         "52.5k": 6,
         "55k": 3,
         "57.5k": 10,
         "60k": 4,
         "62.5k": 0,
         "65k": 1,
         "67.5k": 1,
         "70k": 2,
         "72.5k": 1,
         "75k": 0,
         "77.5k": 5,
         "80k": 2,
         "82.5k": 0,
         "85k": 3,
         "87.5k": 2,
         "90k": 0,
         "92.5k": 1,
         "95k": 1,
         "97.5k": 0,
         "100k": 0,
         "200k": 25
      },
      "childm": {
         "2.5k": 3,
         "5k": 0,
         "7.5k": 3,
         "10k": 0,
         "12.5k": 7,
         "15k": 7,
         "17.5k": 6,
         "20k": 12,
         "22.5k": 5,
         "25k": 5,
         "27.5k": 12,
         "30k": 4,
         "32.5k": 8,
         "35k": 2,
         "37.5k": 9,
         "40k": 3,
         "42.5k": 3,
         "45k": 5,
         "47.5k": 13,
         "50k": 10,
         "52.5k": 16,
         "55k": 6,
         "57.5k": 7,
         "60k": 6,
         "62.5k": 10,
         "65k": 8,
         "67.5k": 9,
         "70k": 4,
         "72.5k": 4,
         "75k": 14,
         "77.5k": 4,
         "80k": 6,
         "82.5k": 4,
         "85k": 10,
         "87.5k": 1,
         "90k": 1,
         "92.5k": 4,
         "95k": 8,
         "97.5k": 5,
         "100k": 2,
         "200k": 55
      },
      "spousem": {
         "2.5k": 25,
         "5k": 2,
         "7.5k": 4,
         "10k": 13,
         "12.5k": 29,
         "15k": 51,
         "17.5k": 77,
         "20k": 88,
         "22.5k": 87,
         "25k": 77,
         "27.5k": 60,
         "30k": 88,
         "32.5k": 71,
         "35k": 69,
         "37.5k": 69,
         "40k": 66,
         "42.5k": 70,
         "45k": 55,
         "47.5k": 65,
         "50k": 42,
         "52.5k": 54,
         "55k": 57,
         "57.5k": 49,
         "60k": 22,
         "62.5k": 40,
         "65k": 32,
         "67.5k": 29,
         "70k": 49,
         "72.5k": 32,
         "75k": 38,
         "77.5k": 25,
         "80k": 15,
         "82.5k": 30,
         "85k": 26,
         "87.5k": 20,
         "90k": 18,
         "92.5k": 19,
         "95k": 7,
         "97.5k": 17,
         "100k": 9,
         "200k": 303
      },
      "houseLoitererm": {
         "2.5k": 72,
         "5k": 25,
         "7.5k": 95,
         "10k": 171,
         "12.5k": 252,
         "15k": 226,
         "17.5k": 218,
         "20k": 240,
         "22.5k": 236,
         "25k": 277,
         "27.5k": 291,
         "30k": 210,
         "32.5k": 250,
         "35k": 186,
         "37.5k": 182,
         "40k": 178,
         "42.5k": 174,
         "45k": 148,
         "47.5k": 153,
         "50k": 114,
         "52.5k": 142,
         "55k": 134,
         "57.5k": 115,
         "60k": 93,
         "62.5k": 109,
         "65k": 103,
         "67.5k": 87,
         "70k": 84,
         "72.5k": 82,
         "75k": 87,
         "77.5k": 68,
         "80k": 53,
         "82.5k": 53,
         "85k": 66,
         "87.5k": 47,
         "90k": 64,
         "92.5k": 50,
         "95k": 30,
         "97.5k": 36,
         "100k": 30,
         "200k": 822
      },
      "nonRelativefm": {
         "2.5k": 0,
         "5k": 1,
         "7.5k": 0,
         "10k": 2,
         "12.5k": 0,
         "15k": 0,
         "17.5k": 5,
         "20k": 0,
         "22.5k": 2,
         "25k": 2,
         "27.5k": 1,
         "30k": 1,
         "32.5k": 0,
         "35k": 0,
         "37.5k": 3,
         "40k": 0,
         "42.5k": 1,
         "45k": 0,
         "47.5k": 0,
         "50k": 0,
         "52.5k": 0,
         "55k": 0,
         "57.5k": 3,
         "60k": 0,
         "62.5k": 0,
         "65k": 0,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 0,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 2,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 2,
         "200k": 0
      },
      "otherRelativefm": {
         "2.5k": 0,
         "5k": 0,
         "7.5k": 7,
         "10k": 0,
         "12.5k": 0,
         "15k": 2,
         "17.5k": 9,
         "20k": 3,
         "22.5k": 5,
         "25k": 2,
         "27.5k": 9,
         "30k": 0,
         "32.5k": 3,
         "35k": 4,
         "37.5k": 5,
         "40k": 4,
         "42.5k": 6,
         "45k": 8,
         "47.5k": 4,
         "50k": 2,
         "52.5k": 6,
         "55k": 7,
         "57.5k": 0,
         "60k": 2,
         "62.5k": 0,
         "65k": 4,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 3,
         "77.5k": 3,
         "80k": 0,
         "82.5k": 0,
         "85k": 2,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": 21
      },
      "childfm": {
         "2.5k": 1,
         "5k": 0,
         "7.5k": 3,
         "10k": 10,
         "12.5k": 12,
         "15k": 9,
         "17.5k": 13,
         "20k": 18,
         "22.5k": 23,
         "25k": 27,
         "27.5k": 17,
         "30k": 17,
         "32.5k": 24,
         "35k": 13,
         "37.5k": 25,
         "40k": 11,
         "42.5k": 23,
         "45k": 10,
         "47.5k": 18,
         "50k": 13,
         "52.5k": 23,
         "55k": 13,
         "57.5k": 12,
         "60k": 14,
         "62.5k": 9,
         "65k": 10,
         "67.5k": 19,
         "70k": 11,
         "72.5k": 16,
         "75k": 16,
         "77.5k": 11,
         "80k": 4,
         "82.5k": 10,
         "85k": 4,
         "87.5k": 5,
         "90k": 3,
         "92.5k": 6,
         "95k": 7,
         "97.5k": 8,
         "100k": 4,
         "200k": 173
      },
      "spousefm": {
         "2.5k": 35,
         "5k": 9,
         "7.5k": 17,
         "10k": 19,
         "12.5k": 74,
         "15k": 95,
         "17.5k": 100,
         "20k": 128,
         "22.5k": 184,
         "25k": 166,
         "27.5k": 201,
         "30k": 166,
         "32.5k": 157,
         "35k": 148,
         "37.5k": 118,
         "40k": 117,
         "42.5k": 106,
         "45k": 113,
         "47.5k": 123,
         "50k": 91,
         "52.5k": 88,
         "55k": 85,
         "57.5k": 73,
         "60k": 70,
         "62.5k": 75,
         "65k": 59,
         "67.5k": 69,
         "70k": 59,
         "72.5k": 58,
         "75k": 43,
         "77.5k": 64,
         "80k": 25,
         "82.5k": 30,
         "85k": 39,
         "87.5k": 30,
         "90k": 32,
         "92.5k": 31,
         "95k": 17,
         "97.5k": 22,
         "100k": 13,
         "200k": 428
      },
      "houseLoitererfm": {
         "2.5k": 104,
         "5k": 52,
         "7.5k": 233,
         "10k": 384,
         "12.5k": 408,
         "15k": 386,
         "17.5k": 353,
         "20k": 284,
         "22.5k": 262,
         "25k": 291,
         "27.5k": 246,
         "30k": 244,
         "32.5k": 177,
         "35k": 169,
         "37.5k": 155,
         "40k": 137,
         "42.5k": 118,
         "45k": 123,
         "47.5k": 118,
         "50k": 89,
         "52.5k": 105,
         "55k": 100,
         "57.5k": 71,
         "60k": 66,
         "62.5k": 71,
         "65k": 61,
         "67.5k": 42,
         "70k": 67,
         "72.5k": 57,
         "75k": 38,
         "77.5k": 44,
         "80k": 24,
         "82.5k": 40,
         "85k": 26,
         "87.5k": 30,
         "90k": 12,
         "92.5k": 32,
         "95k": 11,
         "97.5k": 38,
         "100k": 16,
         "200k": 348
      }
   }
  },
  "2006": {
    "17":{
      "nonRelativem": {
         "2.5k": 1,
         "5k": 0,
         "7.5k": 0,
         "10k": 0,
         "12.5k": 0,
         "15k": 0,
         "17.5k": 0,
         "20k": 0,
         "22.5k": 0,
         "25k": 0,
         "27.5k": 1,
         "30k": 0,
         "32.5k": 0,
         "35k": 0,
         "37.5k": 0,
         "40k": 0,
         "42.5k": 0,
         "45k": 0,
         "47.5k": 0,
         "50k": 0,
         "52.5k": 0,
         "55k": 0,
         "57.5k": 0,
         "60k": 0,
         "62.5k": 0,
         "65k": 0,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 0,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": "0"
      },
      "otherRelativem": {
         "2.5k": 4,
         "5k": 3,
         "7.5k": 12,
         "10k": 3,
         "12.5k": 6,
         "15k": 1,
         "17.5k": 3,
         "20k": 4,
         "22.5k": 0,
         "25k": 1,
         "27.5k": 8,
         "30k": 6,
         "32.5k": 4,
         "35k": 4,
         "37.5k": 5,
         "40k": 9,
         "42.5k": 5,
         "45k": 3,
         "47.5k": 7,
         "50k": 1,
         "52.5k": 10,
         "55k": 3,
         "57.5k": 8,
         "60k": 8,
         "62.5k": 7,
         "65k": 5,
         "67.5k": 6,
         "70k": 2,
         "72.5k": 6,
         "75k": 6,
         "77.5k": 0,
         "80k": 1,
         "82.5k": 3,
         "85k": 12,
         "87.5k": 2,
         "90k": 3,
         "92.5k": 2,
         "95k": 2,
         "97.5k": 1,
         "100k": 0,
         "200k": "62"
      },
      "childm": {
         "2.5k": 7,
         "5k": 0,
         "7.5k": 5,
         "10k": 5,
         "12.5k": 3,
         "15k": 3,
         "17.5k": 12,
         "20k": 3,
         "22.5k": 5,
         "25k": 1,
         "27.5k": 1,
         "30k": 6,
         "32.5k": 1,
         "35k": 5,
         "37.5k": 5,
         "40k": 2,
         "42.5k": 7,
         "45k": 0,
         "47.5k": 2,
         "50k": 5,
         "52.5k": 6,
         "55k": 3,
         "57.5k": 1,
         "60k": 0,
         "62.5k": 2,
         "65k": 1,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 3,
         "75k": 2,
         "77.5k": 1,
         "80k": 2,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 3,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": "16"
      },
      "spousem": {
         "2.5k": 16,
         "5k": 13,
         "7.5k": 23,
         "10k": 23,
         "12.5k": 23,
         "15k": 18,
         "17.5k": 32,
         "20k": 31,
         "22.5k": 28,
         "25k": 26,
         "27.5k": 31,
         "30k": 23,
         "32.5k": 33,
         "35k": 26,
         "37.5k": 36,
         "40k": 21,
         "42.5k": 33,
         "45k": 14,
         "47.5k": 25,
         "50k": 18,
         "52.5k": 28,
         "55k": 17,
         "57.5k": 15,
         "60k": 28,
         "62.5k": 30,
         "65k": 17,
         "67.5k": 19,
         "70k": 15,
         "72.5k": 14,
         "75k": 6,
         "77.5k": 20,
         "80k": 16,
         "82.5k": 14,
         "85k": 11,
         "87.5k": 8,
         "90k": 7,
         "92.5k": 4,
         "95k": 11,
         "97.5k": 14,
         "100k": 5,
         "200k": "136"
      },
      "houseLoitererm": {
         "2.5k": 149,
         "5k": 94,
         "7.5k": 119,
         "10k": 132,
         "12.5k": 238,
         "15k": 209,
         "17.5k": 261,
         "20k": 213,
         "22.5k": 283,
         "25k": 218,
         "27.5k": 312,
         "30k": 183,
         "32.5k": 348,
         "35k": 200,
         "37.5k": 287,
         "40k": 187,
         "42.5k": 361,
         "45k": 193,
         "47.5k": 294,
         "50k": 228,
         "52.5k": 334,
         "55k": 206,
         "57.5k": 271,
         "60k": 203,
         "62.5k": 323,
         "65k": 180,
         "67.5k": 244,
         "70k": 142,
         "72.5k": 260,
         "75k": 209,
         "77.5k": 267,
         "80k": 172,
         "82.5k": 234,
         "85k": 183,
         "87.5k": 210,
         "90k": 154,
         "92.5k": 172,
         "95k": 118,
         "97.5k": 147,
         "100k": 123,
         "200k": "3,168"
      },
      "nonRelativefm": {
         "2.5k": 0,
         "5k": 1,
         "7.5k": 0,
         "10k": 0,
         "12.5k": 0,
         "15k": 2,
         "17.5k": 0,
         "20k": 1,
         "22.5k": 1,
         "25k": 0,
         "27.5k": 0,
         "30k": 0,
         "32.5k": 0,
         "35k": 0,
         "37.5k": 0,
         "40k": 0,
         "42.5k": 0,
         "45k": 0,
         "47.5k": 0,
         "50k": 0,
         "52.5k": 0,
         "55k": 0,
         "57.5k": 0,
         "60k": 0,
         "62.5k": 0,
         "65k": 0,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 0,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": "0"
      },
      "otherRelativefm": {
         "2.5k": 3,
         "5k": 2,
         "7.5k": 6,
         "10k": 2,
         "12.5k": 4,
         "15k": 6,
         "17.5k": 9,
         "20k": 8,
         "22.5k": 8,
         "25k": 5,
         "27.5k": 6,
         "30k": 11,
         "32.5k": 2,
         "35k": 2,
         "37.5k": 7,
         "40k": 3,
         "42.5k": 18,
         "45k": 3,
         "47.5k": 7,
         "50k": 5,
         "52.5k": 7,
         "55k": 4,
         "57.5k": 6,
         "60k": 4,
         "62.5k": 5,
         "65k": 6,
         "67.5k": 10,
         "70k": 2,
         "72.5k": 10,
         "75k": 6,
         "77.5k": 8,
         "80k": 4,
         "82.5k": 4,
         "85k": 8,
         "87.5k": 1,
         "90k": 2,
         "92.5k": 2,
         "95k": 6,
         "97.5k": 0,
         "100k": 4,
         "200k": "46"
      },
      "childfm": {
         "2.5k": 8,
         "5k": 2,
         "7.5k": 1,
         "10k": 0,
         "12.5k": 0,
         "15k": 1,
         "17.5k": 0,
         "20k": 8,
         "22.5k": 1,
         "25k": 5,
         "27.5k": 3,
         "30k": 3,
         "32.5k": 4,
         "35k": 4,
         "37.5k": 1,
         "40k": 2,
         "42.5k": 9,
         "45k": 2,
         "47.5k": 6,
         "50k": 2,
         "52.5k": 3,
         "55k": 1,
         "57.5k": 4,
         "60k": 0,
         "62.5k": 4,
         "65k": 2,
         "67.5k": 4,
         "70k": 4,
         "72.5k": 0,
         "75k": 2,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 3,
         "90k": 2,
         "92.5k": 0,
         "95k": 1,
         "97.5k": 3,
         "100k": 0,
         "200k": "18"
      },
      "spousefm": {
         "2.5k": 29,
         "5k": 4,
         "7.5k": 16,
         "10k": 29,
         "12.5k": 22,
         "15k": 32,
         "17.5k": 24,
         "20k": 22,
         "22.5k": 17,
         "25k": 28,
         "27.5k": 39,
         "30k": 21,
         "32.5k": 30,
         "35k": 22,
         "37.5k": 21,
         "40k": 43,
         "42.5k": 33,
         "45k": 29,
         "47.5k": 31,
         "50k": 35,
         "52.5k": 23,
         "55k": 25,
         "57.5k": 19,
         "60k": 23,
         "62.5k": 23,
         "65k": 20,
         "67.5k": 26,
         "70k": 12,
         "72.5k": 14,
         "75k": 5,
         "77.5k": 13,
         "80k": 16,
         "82.5k": 11,
         "85k": 12,
         "87.5k": 8,
         "90k": 7,
         "92.5k": 12,
         "95k": 11,
         "97.5k": 9,
         "100k": 3,
         "200k": "132"
      },
      "houseLoitererfm": {
         "2.5k": 159,
         "5k": 78,
         "7.5k": 127,
         "10k": 107,
         "12.5k": 189,
         "15k": 163,
         "17.5k": 239,
         "20k": 238,
         "22.5k": 228,
         "25k": 203,
         "27.5k": 274,
         "30k": 211,
         "32.5k": 352,
         "35k": 220,
         "37.5k": 320,
         "40k": 215,
         "42.5k": 314,
         "45k": 212,
         "47.5k": 275,
         "50k": 224,
         "52.5k": 310,
         "55k": 211,
         "57.5k": 264,
         "60k": 200,
         "62.5k": 271,
         "65k": 175,
         "67.5k": 224,
         "70k": 167,
         "72.5k": 270,
         "75k": 157,
         "77.5k": 196,
         "80k": 156,
         "82.5k": 204,
         "85k": 133,
         "87.5k": 187,
         "90k": 184,
         "92.5k": 166,
         "95k": 157,
         "97.5k": 146,
         "100k": 111,
         "200k": "3,067"
      }
   },
    "21":{
      "nonRelativem": {
         "2.5k": 0,
         "5k": 3,
         "7.5k": 3,
         "10k": 0,
         "12.5k": 0,
         "15k": 1,
         "17.5k": 3,
         "20k": 5,
         "22.5k": 14,
         "25k": 4,
         "27.5k": 0,
         "30k": 6,
         "32.5k": 10,
         "35k": 3,
         "37.5k": 7,
         "40k": 3,
         "42.5k": 8,
         "45k": 3,
         "47.5k": 2,
         "50k": 0,
         "52.5k": 5,
         "55k": 1,
         "57.5k": 0,
         "60k": 4,
         "62.5k": 1,
         "65k": 1,
         "67.5k": 0,
         "70k": 0,
         "72.5k": 0,
         "75k": 0,
         "77.5k": 0,
         "80k": 0,
         "82.5k": 0,
         "85k": 0,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 0,
         "95k": 0,
         "97.5k": 0,
         "100k": 0,
         "200k": "0"
      },
      "otherRelativem": {
         "2.5k": 9,
         "5k": 24,
         "7.5k": 6,
         "10k": 10,
         "12.5k": 19,
         "15k": 22,
         "17.5k": 35,
         "20k": 23,
         "22.5k": 22,
         "25k": 17,
         "27.5k": 41,
         "30k": 18,
         "32.5k": 38,
         "35k": 38,
         "37.5k": 14,
         "40k": 30,
         "42.5k": 29,
         "45k": 23,
         "47.5k": 38,
         "50k": 4,
         "52.5k": 19,
         "55k": 5,
         "57.5k": 15,
         "60k": 20,
         "62.5k": 12,
         "65k": 16,
         "67.5k": 14,
         "70k": 5,
         "72.5k": 11,
         "75k": 13,
         "77.5k": 4,
         "80k": 8,
         "82.5k": 13,
         "85k": 9,
         "87.5k": 9,
         "90k": 9,
         "92.5k": 4,
         "95k": 6,
         "97.5k": 8,
         "100k": 1,
         "200k": "154"
      },
      "childm": {
         "2.5k": 16,
         "5k": 4,
         "7.5k": 2,
         "10k": 20,
         "12.5k": 21,
         "15k": 14,
         "17.5k": 27,
         "20k": 16,
         "22.5k": 7,
         "25k": 25,
         "27.5k": 20,
         "30k": 21,
         "32.5k": 26,
         "35k": 14,
         "37.5k": 35,
         "40k": 26,
         "42.5k": 21,
         "45k": 22,
         "47.5k": 26,
         "50k": 14,
         "52.5k": 45,
         "55k": 12,
         "57.5k": 24,
         "60k": 11,
         "62.5k": 23,
         "65k": 12,
         "67.5k": 21,
         "70k": 10,
         "72.5k": 16,
         "75k": 13,
         "77.5k": 6,
         "80k": 9,
         "82.5k": 8,
         "85k": 13,
         "87.5k": 14,
         "90k": 11,
         "92.5k": 10,
         "95k": 2,
         "97.5k": 8,
         "100k": 8,
         "200k": "131"
      },
      "spousem": {
         "2.5k": 46,
         "5k": 39,
         "7.5k": 25,
         "10k": 36,
         "12.5k": 55,
         "15k": 53,
         "17.5k": 46,
         "20k": 34,
         "22.5k": 71,
         "25k": 32,
         "27.5k": 36,
         "30k": 35,
         "32.5k": 51,
         "35k": 37,
         "37.5k": 34,
         "40k": 22,
         "42.5k": 41,
         "45k": 22,
         "47.5k": 31,
         "50k": 17,
         "52.5k": 33,
         "55k": 9,
         "57.5k": 20,
         "60k": 17,
         "62.5k": 19,
         "65k": 7,
         "67.5k": 13,
         "70k": 9,
         "72.5k": 5,
         "75k": 5,
         "77.5k": 12,
         "80k": 6,
         "82.5k": 8,
         "85k": 9,
         "87.5k": 4,
         "90k": 5,
         "92.5k": 8,
         "95k": 4,
         "97.5k": 7,
         "100k": 2,
         "200k": "72"
      },
      "houseLoitererm": {
         "2.5k": 64,
         "5k": 16,
         "7.5k": 32,
         "10k": 58,
         "12.5k": 94,
         "15k": 57,
         "17.5k": 70,
         "20k": 71,
         "22.5k": 96,
         "25k": 54,
         "27.5k": 96,
         "30k": 83,
         "32.5k": 120,
         "35k": 75,
         "37.5k": 140,
         "40k": 92,
         "42.5k": 100,
         "45k": 115,
         "47.5k": 95,
         "50k": 77,
         "52.5k": 152,
         "55k": 83,
         "57.5k": 104,
         "60k": 115,
         "62.5k": 125,
         "65k": 87,
         "67.5k": 127,
         "70k": 67,
         "72.5k": 109,
         "75k": 74,
         "77.5k": 109,
         "80k": 82,
         "82.5k": 99,
         "85k": 88,
         "87.5k": 98,
         "90k": 81,
         "92.5k": 98,
         "95k": 84,
         "97.5k": 82,
         "100k": 66,
         "200k": "2,010"
      },
      "nonRelativefm": {
         "2.5k": 7,
         "5k": 4,
         "7.5k": 3,
         "10k": 5,
         "12.5k": 13,
         "15k": 14,
         "17.5k": 8,
         "20k": 11,
         "22.5k": 16,
         "25k": 16,
         "27.5k": 20,
         "30k": 3,
         "32.5k": 9,
         "35k": 12,
         "37.5k": 24,
         "40k": 11,
         "42.5k": 23,
         "45k": 10,
         "47.5k": 19,
         "50k": 4,
         "52.5k": 17,
         "55k": 3,
         "57.5k": 6,
         "60k": 0,
         "62.5k": 7,
         "65k": 0,
         "67.5k": 3,
         "70k": 0,
         "72.5k": 5,
         "75k": 0,
         "77.5k": 1,
         "80k": 4,
         "82.5k": 1,
         "85k": 2,
         "87.5k": 0,
         "90k": 2,
         "92.5k": 6,
         "95k": 0,
         "97.5k": 2,
         "100k": 0,
         "200k": "11"
      },
      "otherRelativefm": {
         "2.5k": 21,
         "5k": 5,
         "7.5k": 22,
         "10k": 20,
         "12.5k": 20,
         "15k": 21,
         "17.5k": 16,
         "20k": 25,
         "22.5k": 36,
         "25k": 25,
         "27.5k": 38,
         "30k": 38,
         "32.5k": 24,
         "35k": 41,
         "37.5k": 35,
         "40k": 15,
         "42.5k": 27,
         "45k": 9,
         "47.5k": 31,
         "50k": 10,
         "52.5k": 28,
         "55k": 24,
         "57.5k": 32,
         "60k": 10,
         "62.5k": 17,
         "65k": 16,
         "67.5k": 8,
         "70k": 9,
         "72.5k": 10,
         "75k": 8,
         "77.5k": 1,
         "80k": 7,
         "82.5k": 19,
         "85k": 10,
         "87.5k": 12,
         "90k": 5,
         "92.5k": 7,
         "95k": 3,
         "97.5k": 7,
         "100k": 1,
         "200k": "87"
      },
      "childfm": {
         "2.5k": 19,
         "5k": 6,
         "7.5k": 9,
         "10k": 8,
         "12.5k": 15,
         "15k": 7,
         "17.5k": 16,
         "20k": 12,
         "22.5k": 28,
         "25k": 8,
         "27.5k": 28,
         "30k": 17,
         "32.5k": 19,
         "35k": 14,
         "37.5k": 29,
         "40k": 12,
         "42.5k": 25,
         "45k": 13,
         "47.5k": 21,
         "50k": 7,
         "52.5k": 21,
         "55k": 12,
         "57.5k": 9,
         "60k": 13,
         "62.5k": 10,
         "65k": 5,
         "67.5k": 8,
         "70k": 9,
         "72.5k": 7,
         "75k": 12,
         "77.5k": 9,
         "80k": 10,
         "82.5k": 2,
         "85k": 14,
         "87.5k": 5,
         "90k": 4,
         "92.5k": 10,
         "95k": 1,
         "97.5k": 4,
         "100k": 13,
         "200k": "94"
      },
      "spousefm": {
         "2.5k": 125,
         "5k": 74,
         "7.5k": 87,
         "10k": 76,
         "12.5k": 68,
         "15k": 67,
         "17.5k": 64,
         "20k": 35,
         "22.5k": 73,
         "25k": 43,
         "27.5k": 68,
         "30k": 52,
         "32.5k": 40,
         "35k": 45,
         "37.5k": 47,
         "40k": 11,
         "42.5k": 52,
         "45k": 22,
         "47.5k": 18,
         "50k": 17,
         "52.5k": 35,
         "55k": 12,
         "57.5k": 21,
         "60k": 14,
         "62.5k": 18,
         "65k": 5,
         "67.5k": 10,
         "70k": 8,
         "72.5k": 10,
         "75k": 8,
         "77.5k": 15,
         "80k": 5,
         "82.5k": 6,
         "85k": 3,
         "87.5k": 7,
         "90k": 0,
         "92.5k": 5,
         "95k": 0,
         "97.5k": 4,
         "100k": 2,
         "200k": "57"
      },
      "houseLoitererfm": {
         "2.5k": 54,
         "5k": 29,
         "7.5k": 24,
         "10k": 49,
         "12.5k": 55,
         "15k": 41,
         "17.5k": 71,
         "20k": 61,
         "22.5k": 79,
         "25k": 70,
         "27.5k": 88,
         "30k": 56,
         "32.5k": 93,
         "35k": 86,
         "37.5k": 106,
         "40k": 71,
         "42.5k": 105,
         "45k": 80,
         "47.5k": 111,
         "50k": 89,
         "52.5k": 125,
         "55k": 75,
         "57.5k": 111,
         "60k": 70,
         "62.5k": 114,
         "65k": 65,
         "67.5k": 103,
         "70k": 80,
         "72.5k": 123,
         "75k": 93,
         "77.5k": 105,
         "80k": 82,
         "82.5k": 90,
         "85k": 68,
         "87.5k": 90,
         "90k": 62,
         "92.5k": 91,
         "95k": 70,
         "97.5k": 57,
         "100k": 64,
         "200k": "1,714"
      }
   },
    "24":{
      "nonRelativem": {
         "2.5k": 2,
         "5k": 14,
         "7.5k": 6,
         "10k": 7,
         "12.5k": 7,
         "15k": 4,
         "17.5k": 17,
         "20k": 8,
         "22.5k": 14,
         "25k": 14,
         "27.5k": 8,
         "30k": 16,
         "32.5k": 21,
         "35k": 19,
         "37.5k": 10,
         "40k": 12,
         "42.5k": 15,
         "45k": 21,
         "47.5k": 5,
         "50k": 25,
         "52.5k": 11,
         "55k": 14,
         "57.5k": 26,
         "60k": 6,
         "62.5k": 10,
         "65k": 13,
         "67.5k": 19,
         "70k": 1,
         "72.5k": 11,
         "75k": 11,
         "77.5k": 13,
         "80k": 2,
         "82.5k": 11,
         "85k": 14,
         "87.5k": 3,
         "90k": 7,
         "92.5k": 6,
         "95k": 7,
         "97.5k": 9,
         "100k": 7,
         "200k": 121
      },
      "otherRelativem": {
         "2.5k": 11,
         "5k": 4,
         "7.5k": 7,
         "10k": 13,
         "12.5k": 13,
         "15k": 4,
         "17.5k": 24,
         "20k": 22,
         "22.5k": 29,
         "25k": 42,
         "27.5k": 33,
         "30k": 20,
         "32.5k": 36,
         "35k": 43,
         "37.5k": 34,
         "40k": 42,
         "42.5k": 50,
         "45k": 41,
         "47.5k": 61,
         "50k": 35,
         "52.5k": 54,
         "55k": 35,
         "57.5k": 51,
         "60k": 44,
         "62.5k": 50,
         "65k": 29,
         "67.5k": 55,
         "70k": 31,
         "72.5k": 54,
         "75k": 34,
         "77.5k": 33,
         "80k": 29,
         "82.5k": 25,
         "85k": 38,
         "87.5k": 49,
         "90k": 27,
         "92.5k": 57,
         "95k": 21,
         "97.5k": 39,
         "100k": 22,
         "200k": 994
      },
      "childm": {
         "2.5k": 18,
         "5k": 0,
         "7.5k": 4,
         "10k": 5,
         "12.5k": 14,
         "15k": 6,
         "17.5k": 6,
         "20k": 15,
         "22.5k": 4,
         "25k": 7,
         "27.5k": 12,
         "30k": 6,
         "32.5k": 16,
         "35k": 11,
         "37.5k": 22,
         "40k": 7,
         "42.5k": 22,
         "45k": 17,
         "47.5k": 15,
         "50k": 7,
         "52.5k": 20,
         "55k": 10,
         "57.5k": 9,
         "60k": 6,
         "62.5k": 7,
         "65k": 3,
         "67.5k": 10,
         "70k": 1,
         "72.5k": 3,
         "75k": 4,
         "77.5k": 0,
         "80k": 4,
         "82.5k": 4,
         "85k": 5,
         "87.5k": 0,
         "90k": 0,
         "92.5k": 3,
         "95k": 1,
         "97.5k": 2,
         "100k": 2,
         "200k": 16
      },
      "spousem": {
         "2.5k": 20,
         "5k": 5,
         "7.5k": 2,
         "10k": 9,
         "12.5k": 4,
         "15k": 6,
         "17.5k": 21,
         "20k": 24,
         "22.5k": 44,
         "25k": 20,
         "27.5k": 44,
         "30k": 18,
         "32.5k": 39,
         "35k": 30,
         "37.5k": 33,
         "40k": 16,
         "42.5k": 69,
         "45k": 15,
         "47.5k": 21,
         "50k": 16,
         "52.5k": 35,
         "55k": 7,
         "57.5k": 32,
         "60k": 19,
         "62.5k": 31,
         "65k": 23,
         "67.5k": 29,
         "70k": 11,
         "72.5k": 40,
         "75k": 12,
         "77.5k": 22,
         "80k": 10,
         "82.5k": 12,
         "85k": 7,
         "87.5k": 19,
         "90k": 8,
         "92.5k": 15,
         "95k": 20,
         "97.5k": 6,
         "100k": 7,
         "200k": 191
      },
      "houseLoitererm": {
         "2.5k": 65,
         "5k": 23,
         "7.5k": 45,
         "10k": 37,
         "12.5k": 63,
         "15k": 50,
         "17.5k": 58,
         "20k": 87,
         "22.5k": 91,
         "25k": 64,
         "27.5k": 89,
         "30k": 64,
         "32.5k": 99,
         "35k": 65,
         "37.5k": 96,
         "40k": 62,
         "42.5k": 107,
         "45k": 49,
         "47.5k": 65,
         "50k": 42,
         "52.5k": 64,
         "55k": 54,
         "57.5k": 36,
         "60k": 23,
         "62.5k": 42,
         "65k": 30,
         "67.5k": 36,
         "70k": 21,
         "72.5k": 28,
         "75k": 11,
         "77.5k": 26,
         "80k": 22,
         "82.5k": 13,
         "85k": 8,
         "87.5k": 3,
         "90k": 12,
         "92.5k": 16,
         "95k": 9,
         "97.5k": 8,
         "100k": 0,
         "200k": 127
      },
      "nonRelativefm": {
         "2.5k": 3,
         "5k": 4,
         "7.5k": 0,
         "10k": 3,
         "12.5k": 9,
         "15k": 7,
         "17.5k": 10,
         "20k": 4,
         "22.5k": 16,
         "25k": 5,
         "27.5k": 8,
         "30k": 4,
         "32.5k": 9,
         "35k": 16,
         "37.5k": 10,
         "40k": 11,
         "42.5k": 6,
         "45k": 7,
         "47.5k": 20,
         "50k": 12,
         "52.5k": 15,
         "55k": 9,
         "57.5k": 5,
         "60k": 3,
         "62.5k": 7,
         "65k": 9,
         "67.5k": 13,
         "70k": 10,
         "72.5k": 7,
         "75k": 3,
         "77.5k": 7,
         "80k": 10,
         "82.5k": 5,
         "85k": 2,
         "87.5k": 7,
         "90k": 1,
         "92.5k": 10,
         "95k": 7,
         "97.5k": 6,
         "100k": 7,
         "200k": 78
      },
      "otherRelativefm": {
         "2.5k": 6,
         "5k": 12,
         "7.5k": 3,
         "10k": 18,
         "12.5k": 11,
         "15k": 7,
         "17.5k": 29,
         "20k": 11,
         "22.5k": 14,
         "25k": 17,
         "27.5k": 20,
         "30k": 19,
         "32.5k": 32,
         "35k": 22,
         "37.5k": 39,
         "40k": 34,
         "42.5k": 25,
         "45k": 25,
         "47.5k": 37,
         "50k": 25,
         "52.5k": 54,
         "55k": 22,
         "57.5k": 30,
         "60k": 35,
         "62.5k": 32,
         "65k": 23,
         "67.5k": 48,
         "70k": 29,
         "72.5k": 41,
         "75k": 36,
         "77.5k": 26,
         "80k": 28,
         "82.5k": 39,
         "85k": 27,
         "87.5k": 31,
         "90k": 27,
         "92.5k": 28,
         "95k": 26,
         "97.5k": 21,
         "100k": 14,
         "200k": 756
      },
      "childfm": {
         "2.5k": 4,
         "5k": 4,
         "7.5k": 4,
         "10k": 9,
         "12.5k": 12,
         "15k": 5,
         "17.5k": 22,
         "20k": 22,
         "22.5k": 42,
         "25k": 25,
         "27.5k": 41,
         "30k": 25,
         "32.5k": 47,
         "35k": 30,
         "37.5k": 40,
         "40k": 24,
         "42.5k": 53,
         "45k": 15,
         "47.5k": 31,
         "50k": 38,
         "52.5k": 33,
         "55k": 30,
         "57.5k": 26,
         "60k": 19,
         "62.5k": 19,
         "65k": 21,
         "67.5k": 19,
         "70k": 13,
         "72.5k": 20,
         "75k": 0,
         "77.5k": 11,
         "80k": 6,
         "82.5k": 9,
         "85k": 2,
         "87.5k": 11,
         "90k": 2,
         "92.5k": 7,
         "95k": 12,
         "97.5k": 4,
         "100k": 0,
         "200k": 50
      },
      "spousefm": {
         "2.5k": 31,
         "5k": 6,
         "7.5k": 16,
         "10k": 13,
         "12.5k": 16,
         "15k": 35,
         "17.5k": 23,
         "20k": 18,
         "22.5k": 25,
         "25k": 23,
         "27.5k": 31,
         "30k": 26,
         "32.5k": 41,
         "35k": 24,
         "37.5k": 36,
         "40k": 29,
         "42.5k": 21,
         "45k": 19,
         "47.5k": 16,
         "50k": 30,
         "52.5k": 31,
         "55k": 14,
         "57.5k": 22,
         "60k": 18,
         "62.5k": 34,
         "65k": 19,
         "67.5k": 20,
         "70k": 10,
         "72.5k": 21,
         "75k": 16,
         "77.5k": 27,
         "80k": 13,
         "82.5k": 23,
         "85k": 3,
         "87.5k": 9,
         "90k": 11,
         "92.5k": 5,
         "95k": 6,
         "97.5k": 22,
         "100k": 6,
         "200k": 150
      },
      "houseLoitererfm": {
         "2.5k": 129,
         "5k": 69,
         "7.5k": 84,
         "10k": 67,
         "12.5k": 71,
         "15k": 79,
         "17.5k": 116,
         "20k": 119,
         "22.5k": 106,
         "25k": 64,
         "27.5k": 92,
         "30k": 69,
         "32.5k": 93,
         "35k": 63,
         "37.5k": 90,
         "40k": 57,
         "42.5k": 95,
         "45k": 59,
         "47.5k": 50,
         "50k": 36,
         "52.5k": 40,
         "55k": 19,
         "57.5k": 56,
         "60k": 48,
         "62.5k": 58,
         "65k": 29,
         "67.5k": 22,
         "70k": 16,
         "72.5k": 27,
         "75k": 26,
         "77.5k": 27,
         "80k": 6,
         "82.5k": 25,
         "85k": 1,
         "87.5k": 7,
         "90k": 6,
         "92.5k": 11,
         "95k": 4,
         "97.5k": 19,
         "100k": 9,
         "200k": 94
      }
   },
    "29":{
      "nonRelativem": {
         "2.5k": 5,
         "5k": 1,
         "7.5k": 2,
         "10k": 8,
         "12.5k": 17,
         "15k": 8,
         "17.5k": 9,
         "20k": 3,
         "22.5k": 18,
         "25k": 5,
         "27.5k": 27,
         "30k": 9,
         "32.5k": 25,
         "35k": 18,
         "37.5k": 37,
         "40k": 19,
         "42.5k": 24,
         "45k": 15,
         "47.5k": 19,
         "50k": 18,
         "52.5k": 18,
         "55k": 15,
         "57.5k": 27,
         "60k": 7,
         "62.5k": 33,
         "65k": 8,
         "67.5k": 11,
         "70k": 9,
         "72.5k": 35,
         "75k": 11,
         "77.5k": 16,
         "80k": 16,
         "82.5k": 17,
         "85k": 10,
         "87.5k": 11,
         "90k": 11,
         "92.5k": 16,
         "95k": 9,
         "97.5k": 13,
         "100k": 4,
         "200k": 195
      },
      "otherRelativem": {
         "2.5k": 12,
         "5k": 2,
         "7.5k": 15,
         "10k": 9,
         "12.5k": 15,
         "15k": 13,
         "17.5k": 21,
         "20k": 26,
         "22.5k": 39,
         "25k": 28,
         "27.5k": 44,
         "30k": 46,
         "32.5k": 64,
         "35k": 25,
         "37.5k": 68,
         "40k": 39,
         "42.5k": 64,
         "45k": 45,
         "47.5k": 39,
         "50k": 25,
         "52.5k": 62,
         "55k": 28,
         "57.5k": 57,
         "60k": 29,
         "62.5k": 62,
         "65k": 40,
         "67.5k": 36,
         "70k": 45,
         "72.5k": 43,
         "75k": 24,
         "77.5k": 30,
         "80k": 19,
         "82.5k": 27,
         "85k": 11,
         "87.5k": 22,
         "90k": 23,
         "92.5k": 38,
         "95k": 28,
         "97.5k": 19,
         "100k": 24,
         "200k": 167
      },
      "childm": {
         "2.5k": 19,
         "5k": 4,
         "7.5k": 23,
         "10k": 10,
         "12.5k": 12,
         "15k": 22,
         "17.5k": 21,
         "20k": 29,
         "22.5k": 38,
         "25k": 13,
         "27.5k": 29,
         "30k": 22,
         "32.5k": 27,
         "35k": 19,
         "37.5k": 32,
         "40k": 39,
         "42.5k": 32,
         "45k": 43,
         "47.5k": 64,
         "50k": 35,
         "52.5k": 47,
         "55k": 39,
         "57.5k": 41,
         "60k": 32,
         "62.5k": 27,
         "65k": 22,
         "67.5k": 57,
         "70k": 29,
         "72.5k": 42,
         "75k": 18,
         "77.5k": 54,
         "80k": 48,
         "82.5k": 33,
         "85k": 27,
         "87.5k": 14,
         "90k": 35,
         "92.5k": 24,
         "95k": 36,
         "97.5k": 44,
         "100k": 22,
         "200k": 677
      },
      "spousem": {
         "2.5k": 20,
         "5k": 5,
         "7.5k": 6,
         "10k": 16,
         "12.5k": 13,
         "15k": 12,
         "17.5k": 28,
         "20k": 24,
         "22.5k": 34,
         "25k": 20,
         "27.5k": 25,
         "30k": 31,
         "32.5k": 46,
         "35k": 28,
         "37.5k": 36,
         "40k": 38,
         "42.5k": 63,
         "45k": 36,
         "47.5k": 36,
         "50k": 37,
         "52.5k": 44,
         "55k": 32,
         "57.5k": 42,
         "60k": 22,
         "62.5k": 46,
         "65k": 42,
         "67.5k": 19,
         "70k": 34,
         "72.5k": 26,
         "75k": 15,
         "77.5k": 20,
         "80k": 15,
         "82.5k": 23,
         "85k": 16,
         "87.5k": 32,
         "90k": 30,
         "92.5k": 15,
         "95k": 18,
         "97.5k": 19,
         "100k": 4,
         "200k": 321
      },
      "houseLoitererm": {
         "2.5k": 94,
         "5k": 15,
         "7.5k": 62,
         "10k": 52,
         "12.5k": 91,
         "15k": 81,
         "17.5k": 99,
         "20k": 75,
         "22.5k": 169,
         "25k": 136,
         "27.5k": 172,
         "30k": 104,
         "32.5k": 227,
         "35k": 140,
         "37.5k": 217,
         "40k": 91,
         "42.5k": 202,
         "45k": 112,
         "47.5k": 177,
         "50k": 134,
         "52.5k": 193,
         "55k": 105,
         "57.5k": 147,
         "60k": 102,
         "62.5k": 160,
         "65k": 117,
         "67.5k": 132,
         "70k": 88,
         "72.5k": 126,
         "75k": 58,
         "77.5k": 93,
         "80k": 60,
         "82.5k": 59,
         "85k": 70,
         "87.5k": 45,
         "90k": 54,
         "92.5k": 69,
         "95k": 46,
         "97.5k": 48,
         "100k": 30,
         "200k": 636
      },
      "nonRelativefm": {
         "2.5k": 4,
         "5k": 0,
         "7.5k": 5,
         "10k": 3,
         "12.5k": 3,
         "15k": 11,
         "17.5k": 7,
         "20k": 12,
         "22.5k": 10,
         "25k": 4,
         "27.5k": 12,
         "30k": 12,
         "32.5k": 12,
         "35k": 11,
         "37.5k": 6,
         "40k": 15,
         "42.5k": 20,
         "45k": 11,
         "47.5k": 6,
         "50k": 12,
         "52.5k": 10,
         "55k": 7,
         "57.5k": 7,
         "60k": 2,
         "62.5k": 8,
         "65k": 7,
         "67.5k": 11,
         "70k": 9,
         "72.5k": 11,
         "75k": 11,
         "77.5k": 6,
         "80k": 6,
         "82.5k": 3,
         "85k": 5,
         "87.5k": 13,
         "90k": 4,
         "92.5k": 7,
         "95k": 11,
         "97.5k": 6,
         "100k": 2,
         "200k": 112
      },
      "otherRelativefm": {
         "2.5k": 10,
         "5k": 2,
         "7.5k": 11,
         "10k": 18,
         "12.5k": 31,
         "15k": 26,
         "17.5k": 39,
         "20k": 35,
         "22.5k": 53,
         "25k": 48,
         "27.5k": 77,
         "30k": 50,
         "32.5k": 98,
         "35k": 56,
         "37.5k": 102,
         "40k": 55,
         "42.5k": 94,
         "45k": 65,
         "47.5k": 74,
         "50k": 64,
         "52.5k": 100,
         "55k": 63,
         "57.5k": 103,
         "60k": 71,
         "62.5k": 120,
         "65k": 52,
         "67.5k": 106,
         "70k": 73,
         "72.5k": 82,
         "75k": 50,
         "77.5k": 76,
         "80k": 51,
         "82.5k": 50,
         "85k": 64,
         "87.5k": 52,
         "90k": 28,
         "92.5k": 54,
         "95k": 26,
         "97.5k": 32,
         "100k": 24,
         "200k": 434
      },
      "childfm": {
         "2.5k": 14,
         "5k": 7,
         "7.5k": 4,
         "10k": 12,
         "12.5k": 12,
         "15k": 12,
         "17.5k": 15,
         "20k": 11,
         "22.5k": 18,
         "25k": 18,
         "27.5k": 28,
         "30k": 19,
         "32.5k": 24,
         "35k": 36,
         "37.5k": 13,
         "40k": 18,
         "42.5k": 25,
         "45k": 21,
         "47.5k": 28,
         "50k": 32,
         "52.5k": 32,
         "55k": 18,
         "57.5k": 23,
         "60k": 25,
         "62.5k": 24,
         "65k": 19,
         "67.5k": 22,
         "70k": 29,
         "72.5k": 22,
         "75k": 16,
         "77.5k": 41,
         "80k": 32,
         "82.5k": 18,
         "85k": 22,
         "87.5k": 23,
         "90k": 7,
         "92.5k": 20,
         "95k": 10,
         "97.5k": 13,
         "100k": 10,
         "200k": 524
      },
      "spousefm": {
         "2.5k": 13,
         "5k": 5,
         "7.5k": 5,
         "10k": 6,
         "12.5k": 9,
         "15k": 4,
         "17.5k": 35,
         "20k": 9,
         "22.5k": 35,
         "25k": 18,
         "27.5k": 13,
         "30k": 15,
         "32.5k": 24,
         "35k": 19,
         "37.5k": 37,
         "40k": 18,
         "42.5k": 32,
         "45k": 20,
         "47.5k": 26,
         "50k": 22,
         "52.5k": 32,
         "55k": 13,
         "57.5k": 20,
         "60k": 30,
         "62.5k": 40,
         "65k": 22,
         "67.5k": 18,
         "70k": 35,
         "72.5k": 36,
         "75k": 12,
         "77.5k": 33,
         "80k": 5,
         "82.5k": 22,
         "85k": 10,
         "87.5k": 12,
         "90k": 26,
         "92.5k": 8,
         "95k": 16,
         "97.5k": 9,
         "100k": 7,
         "200k": 197
      },
      "houseLoitererfm": {
         "2.5k": 129,
         "5k": 98,
         "7.5k": 118,
         "10k": 132,
         "12.5k": 159,
         "15k": 149,
         "17.5k": 157,
         "20k": 137,
         "22.5k": 202,
         "25k": 157,
         "27.5k": 204,
         "30k": 163,
         "32.5k": 224,
         "35k": 100,
         "37.5k": 189,
         "40k": 106,
         "42.5k": 168,
         "45k": 119,
         "47.5k": 136,
         "50k": 76,
         "52.5k": 171,
         "55k": 85,
         "57.5k": 118,
         "60k": 58,
         "62.5k": 146,
         "65k": 99,
         "67.5k": 83,
         "70k": 66,
         "72.5k": 86,
         "75k": 40,
         "77.5k": 57,
         "80k": 39,
         "82.5k": 89,
         "85k": 50,
         "87.5k": 60,
         "90k": 49,
         "92.5k": 46,
         "95k": 34,
         "97.5k": 31,
         "100k": 22,
         "200k": 431
      }
   },
    "34":{
      "nonRelativem": {
         "2.5k": 3,
         "5k": 1,
         "7.5k": 6,
         "10k": 6,
         "12.5k": 5,
         "15k": 8,
         "17.5k": 12,
         "20k": 18,
         "22.5k": 9,
         "25k": 39,
         "27.5k": 27,
         "30k": 13,
         "32.5k": 22,
         "35k": 10,
         "37.5k": 28,
         "40k": 13,
         "42.5k": 34,
         "45k": 19,
         "47.5k": 24,
         "50k": 21,
         "52.5k": 46,
         "55k": 16,
         "57.5k": 29,
         "60k": 28,
         "62.5k": 41,
         "65k": 18,
         "67.5k": 23,
         "70k": 11,
         "72.5k": 25,
         "75k": 8,
         "77.5k": 15,
         "80k": 13,
         "82.5k": 21,
         "85k": 17,
         "87.5k": 24,
         "90k": 9,
         "92.5k": 20,
         "95k": 13,
         "97.5k": 8,
         "100k": 2,
         "200k": 214
      },
      "otherRelativem": {
         "2.5k": 5,
         "5k": 0,
         "7.5k": 0,
         "10k": 4,
         "12.5k": 13,
         "15k": 7,
         "17.5k": 4,
         "20k": 5,
         "22.5k": 13,
         "25k": 0,
         "27.5k": 11,
         "30k": 22,
         "32.5k": 17,
         "35k": 13,
         "37.5k": 29,
         "40k": 11,
         "42.5k": 21,
         "45k": 8,
         "47.5k": 22,
         "50k": 14,
         "52.5k": 23,
         "55k": 13,
         "57.5k": 17,
         "60k": 7,
         "62.5k": 11,
         "65k": 12,
         "67.5k": 21,
         "70k": 14,
         "72.5k": 14,
         "75k": 9,
         "77.5k": 3,
         "80k": 6,
         "82.5k": 8,
         "85k": 13,
         "87.5k": 11,
         "90k": 15,
         "92.5k": 2,
         "95k": 4,
         "97.5k": 9,
         "100k": 5,
         "200k": 108
      },
      "childm": {
         "2.5k": 19,
         "5k": 5,
         "7.5k": 8,
         "10k": 15,
         "12.5k": 23,
         "15k": 14,
         "17.5k": 36,
         "20k": 31,
         "22.5k": 50,
         "25k": 37,
         "27.5k": 56,
         "30k": 18,
         "32.5k": 68,
         "35k": 29,
         "37.5k": 56,
         "40k": 52,
         "42.5k": 97,
         "45k": 74,
         "47.5k": 53,
         "50k": 53,
         "52.5k": 74,
         "55k": 48,
         "57.5k": 60,
         "60k": 40,
         "62.5k": 71,
         "65k": 51,
         "67.5k": 58,
         "70k": 39,
         "72.5k": 70,
         "75k": 39,
         "77.5k": 52,
         "80k": 27,
         "82.5k": 55,
         "85k": 31,
         "87.5k": 46,
         "90k": 34,
         "92.5k": 40,
         "95k": 23,
         "97.5k": 44,
         "100k": 21,
         "200k": 475
      },
      "spousem": {
         "2.5k": 21,
         "5k": 14,
         "7.5k": 0,
         "10k": 12,
         "12.5k": 5,
         "15k": 24,
         "17.5k": 21,
         "20k": 31,
         "22.5k": 12,
         "25k": 12,
         "27.5k": 20,
         "30k": 1,
         "32.5k": 24,
         "35k": 10,
         "37.5k": 28,
         "40k": 26,
         "42.5k": 25,
         "45k": 25,
         "47.5k": 26,
         "50k": 25,
         "52.5k": 27,
         "55k": 35,
         "57.5k": 20,
         "60k": 18,
         "62.5k": 10,
         "65k": 19,
         "67.5k": 18,
         "70k": 17,
         "72.5k": 18,
         "75k": 9,
         "77.5k": 10,
         "80k": 8,
         "82.5k": 9,
         "85k": 10,
         "87.5k": 22,
         "90k": 8,
         "92.5k": 18,
         "95k": 11,
         "97.5k": 13,
         "100k": 7,
         "200k": 278
      },
      "houseLoitererm": {
         "2.5k": 62,
         "5k": 21,
         "7.5k": 26,
         "10k": 48,
         "12.5k": 50,
         "15k": 50,
         "17.5k": 68,
         "20k": 67,
         "22.5k": 129,
         "25k": 85,
         "27.5k": 138,
         "30k": 97,
         "32.5k": 193,
         "35k": 84,
         "37.5k": 196,
         "40k": 99,
         "42.5k": 191,
         "45k": 105,
         "47.5k": 164,
         "50k": 109,
         "52.5k": 190,
         "55k": 99,
         "57.5k": 145,
         "60k": 88,
         "62.5k": 171,
         "65k": 72,
         "67.5k": 126,
         "70k": 78,
         "72.5k": 130,
         "75k": 84,
         "77.5k": 115,
         "80k": 78,
         "82.5k": 124,
         "85k": 75,
         "87.5k": 90,
         "90k": 49,
         "92.5k": 120,
         "95k": 70,
         "97.5k": 61,
         "100k": 54,
         "200k": 965
      },
      "nonRelativefm": {
         "2.5k": 5,
         "5k": 1,
         "7.5k": 0,
         "10k": 7,
         "12.5k": 1,
         "15k": 6,
         "17.5k": 14,
         "20k": 13,
         "22.5k": 9,
         "25k": 10,
         "27.5k": 17,
         "30k": 8,
         "32.5k": 16,
         "35k": 3,
         "37.5k": 15,
         "40k": 11,
         "42.5k": 26,
         "45k": 3,
         "47.5k": 8,
         "50k": 6,
         "52.5k": 18,
         "55k": 8,
         "57.5k": 16,
         "60k": 16,
         "62.5k": 11,
         "65k": 13,
         "67.5k": 10,
         "70k": 3,
         "72.5k": 24,
         "75k": 12,
         "77.5k": 11,
         "80k": 10,
         "82.5k": 16,
         "85k": 7,
         "87.5k": 11,
         "90k": 4,
         "92.5k": 24,
         "95k": 1,
         "97.5k": 12,
         "100k": 4,
         "200k": 126
      },
      "otherRelativefm": {
         "2.5k": 0,
         "5k": 4,
         "7.5k": 5,
         "10k": 4,
         "12.5k": 5,
         "15k": 6,
         "17.5k": 5,
         "20k": 1,
         "22.5k": 5,
         "25k": 3,
         "27.5k": 11,
         "30k": 5,
         "32.5k": 6,
         "35k": 6,
         "37.5k": 3,
         "40k": 12,
         "42.5k": 15,
         "45k": 11,
         "47.5k": 11,
         "50k": 2,
         "52.5k": 3,
         "55k": 16,
         "57.5k": 10,
         "60k": 9,
         "62.5k": 11,
         "65k": 4,
         "67.5k": 4,
         "70k": 11,
         "72.5k": 10,
         "75k": 7,
         "77.5k": 8,
         "80k": 1,
         "82.5k": 1,
         "85k": 8,
         "87.5k": 7,
         "90k": 1,
         "92.5k": 1,
         "95k": 2,
         "97.5k": 6,
         "100k": 3,
         "200k": 82
      },
      "childfm": {
         "2.5k": 21,
         "5k": 3,
         "7.5k": 9,
         "10k": 16,
         "12.5k": 20,
         "15k": 19,
         "17.5k": 37,
         "20k": 37,
         "22.5k": 51,
         "25k": 42,
         "27.5k": 49,
         "30k": 47,
         "32.5k": 76,
         "35k": 38,
         "37.5k": 97,
         "40k": 67,
         "42.5k": 84,
         "45k": 54,
         "47.5k": 89,
         "50k": 83,
         "52.5k": 121,
         "55k": 65,
         "57.5k": 64,
         "60k": 73,
         "62.5k": 117,
         "65k": 78,
         "67.5k": 88,
         "70k": 54,
         "72.5k": 109,
         "75k": 60,
         "77.5k": 108,
         "80k": 65,
         "82.5k": 87,
         "85k": 57,
         "87.5k": 83,
         "90k": 43,
         "92.5k": 85,
         "95k": 65,
         "97.5k": 42,
         "100k": 41,
         "200k": 967
      },
      "spousefm": {
         "2.5k": 5,
         "5k": 2,
         "7.5k": 6,
         "10k": 9,
         "12.5k": 12,
         "15k": 3,
         "17.5k": 3,
         "20k": 15,
         "22.5k": 14,
         "25k": 5,
         "27.5k": 10,
         "30k": 9,
         "32.5k": 12,
         "35k": 7,
         "37.5k": 8,
         "40k": 4,
         "42.5k": 9,
         "45k": 3,
         "47.5k": 10,
         "50k": 9,
         "52.5k": 24,
         "55k": 13,
         "57.5k": 10,
         "60k": 14,
         "62.5k": 12,
         "65k": 10,
         "67.5k": 19,
         "70k": 9,
         "72.5k": 10,
         "75k": 5,
         "77.5k": 9,
         "80k": 11,
         "82.5k": 19,
         "85k": 7,
         "87.5k": 6,
         "90k": 2,
         "92.5k": 10,
         "95k": 9,
         "97.5k": 8,
         "100k": 6,
         "200k": 167
      },
      "houseLoitererfm": {
         "2.5k": 122,
         "5k": 53,
         "7.5k": 92,
         "10k": 85,
         "12.5k": 129,
         "15k": 124,
         "17.5k": 175,
         "20k": 108,
         "22.5k": 161,
         "25k": 136,
         "27.5k": 168,
         "30k": 90,
         "32.5k": 176,
         "35k": 110,
         "37.5k": 157,
         "40k": 105,
         "42.5k": 199,
         "45k": 95,
         "47.5k": 122,
         "50k": 97,
         "52.5k": 149,
         "55k": 75,
         "57.5k": 107,
         "60k": 83,
         "62.5k": 138,
         "65k": 48,
         "67.5k": 103,
         "70k": 52,
         "72.5k": 107,
         "75k": 66,
         "77.5k": 101,
         "80k": 57,
         "82.5k": 72,
         "85k": 46,
         "87.5k": 72,
         "90k": 43,
         "92.5k": 80,
         "95k": 39,
         "97.5k": 49,
         "100k": 28,
         "200k": 782
      },
   },
    "44":{
      "nonRelativem": {
         "2.5k": 9,
         "5k": 2,
         "7.5k": 6,
         "10k": 6,
         "12.5k": 16,
         "15k": 17,
         "17.5k": 3,
         "20k": 19,
         "22.5k": 24,
         "25k": 16,
         "27.5k": 18,
         "30k": 23,
         "32.5k": 14,
         "35k": 22,
         "37.5k": 27,
         "40k": 9,
         "42.5k": 24,
         "45k": 24,
         "47.5k": 38,
         "50k": 16,
         "52.5k": 34,
         "55k": 10,
         "57.5k": 15,
         "60k": 36,
         "62.5k": 19,
         "65k": 11,
         "67.5k": 21,
         "70k": 12,
         "72.5k": 40,
         "75k": 26,
         "77.5k": 18,
         "80k": 12,
         "82.5k": 17,
         "85k": 10,
         "87.5k": 11,
         "90k": 20,
         "92.5k": 22,
         "95k": 13,
         "97.5k": 13,
         "100k": 11,
         "200k": "216"
      },
      "otherRelativem": {
         "2.5k": 17,
         "5k": 10,
         "7.5k": 13,
         "10k": 13,
         "12.5k": 9,
         "15k": 8,
         "17.5k": 18,
         "20k": 33,
         "22.5k": 28,
         "25k": 22,
         "27.5k": 21,
         "30k": 30,
         "32.5k": 20,
         "35k": 32,
         "37.5k": 43,
         "40k": 21,
         "42.5k": 34,
         "45k": 32,
         "47.5k": 29,
         "50k": 40,
         "52.5k": 41,
         "55k": 38,
         "57.5k": 29,
         "60k": 27,
         "62.5k": 59,
         "65k": 42,
         "67.5k": 38,
         "70k": 30,
         "72.5k": 63,
         "75k": 27,
         "77.5k": 28,
         "80k": 28,
         "82.5k": 43,
         "85k": 36,
         "87.5k": 14,
         "90k": 23,
         "92.5k": 29,
         "95k": 22,
         "97.5k": 22,
         "100k": 25,
         "200k": "357"
      },
      "childm": {
         "2.5k": 22,
         "5k": 3,
         "7.5k": 7,
         "10k": 22,
         "12.5k": 21,
         "15k": 22,
         "17.5k": 34,
         "20k": 31,
         "22.5k": 35,
         "25k": 48,
         "27.5k": 29,
         "30k": 37,
         "32.5k": 43,
         "35k": 27,
         "37.5k": 56,
         "40k": 64,
         "42.5k": 41,
         "45k": 45,
         "47.5k": 32,
         "50k": 43,
         "52.5k": 18,
         "55k": 39,
         "57.5k": 30,
         "60k": 33,
         "62.5k": 21,
         "65k": 30,
         "67.5k": 31,
         "70k": 32,
         "72.5k": 31,
         "75k": 20,
         "77.5k": 24,
         "80k": 23,
         "82.5k": 27,
         "85k": 21,
         "87.5k": 7,
         "90k": 17,
         "92.5k": 10,
         "95k": 23,
         "97.5k": 25,
         "100k": 11,
         "200k": "269"
      },
      "spousem": {
         "2.5k": 35,
         "5k": 14,
         "7.5k": 20,
         "10k": 12,
         "12.5k": 37,
         "15k": 34,
         "17.5k": 53,
         "20k": 31,
         "22.5k": 77,
         "25k": 44,
         "27.5k": 77,
         "30k": 65,
         "32.5k": 118,
         "35k": 62,
         "37.5k": 115,
         "40k": 68,
         "42.5k": 160,
         "45k": 88,
         "47.5k": 120,
         "50k": 113,
         "52.5k": 183,
         "55k": 74,
         "57.5k": 143,
         "60k": 76,
         "62.5k": 151,
         "65k": 51,
         "67.5k": 112,
         "70k": 67,
         "72.5k": 156,
         "75k": 94,
         "77.5k": 148,
         "80k": 94,
         "82.5k": 154,
         "85k": 78,
         "87.5k": 100,
         "90k": 76,
         "92.5k": 121,
         "95k": 73,
         "97.5k": 86,
         "100k": 73,
         "200k": "1,905"
      },
      "houseLoitererm": {
         "2.5k": 148,
         "5k": 32,
         "7.5k": 89,
         "10k": 110,
         "12.5k": 184,
         "15k": 101,
         "17.5k": 187,
         "20k": 167,
         "22.5k": 249,
         "25k": 186,
         "27.5k": 274,
         "30k": 203,
         "32.5k": 389,
         "35k": 221,
         "37.5k": 345,
         "40k": 193,
         "42.5k": 387,
         "45k": 164,
         "47.5k": 357,
         "50k": 235,
         "52.5k": 441,
         "55k": 181,
         "57.5k": 289,
         "60k": 247,
         "62.5k": 358,
         "65k": 217,
         "67.5k": 291,
         "70k": 166,
         "72.5k": 345,
         "75k": 177,
         "77.5k": 252,
         "80k": 162,
         "82.5k": 280,
         "85k": 169,
         "87.5k": 230,
         "90k": 128,
         "92.5k": 218,
         "95k": 149,
         "97.5k": 158,
         "100k": 126,
         "200k": "3,189"
      },
      "nonRelativefm": {
         "2.5k": 11,
         "5k": 5,
         "7.5k": 8,
         "10k": 8,
         "12.5k": 12,
         "15k": 12,
         "17.5k": 20,
         "20k": 13,
         "22.5k": 18,
         "25k": 18,
         "27.5k": 18,
         "30k": 20,
         "32.5k": 18,
         "35k": 13,
         "37.5k": 20,
         "40k": 13,
         "42.5k": 26,
         "45k": 13,
         "47.5k": 15,
         "50k": 10,
         "52.5k": 22,
         "55k": 6,
         "57.5k": 15,
         "60k": 16,
         "62.5k": 26,
         "65k": 6,
         "67.5k": 11,
         "70k": 9,
         "72.5k": 10,
         "75k": 14,
         "77.5k": 8,
         "80k": 11,
         "82.5k": 11,
         "85k": 13,
         "87.5k": 12,
         "90k": 9,
         "92.5k": 10,
         "95k": 7,
         "97.5k": 19,
         "100k": 7,
         "200k": "149"
      },
      "otherRelativefm": {
         "2.5k": 16,
         "5k": 0,
         "7.5k": 12,
         "10k": 7,
         "12.5k": 8,
         "15k": 6,
         "17.5k": 10,
         "20k": 16,
         "22.5k": 8,
         "25k": 18,
         "27.5k": 28,
         "30k": 13,
         "32.5k": 15,
         "35k": 18,
         "37.5k": 24,
         "40k": 15,
         "42.5k": 39,
         "45k": 19,
         "47.5k": 32,
         "50k": 20,
         "52.5k": 29,
         "55k": 23,
         "57.5k": 24,
         "60k": 14,
         "62.5k": 35,
         "65k": 15,
         "67.5k": 20,
         "70k": 12,
         "72.5k": 14,
         "75k": 20,
         "77.5k": 11,
         "80k": 14,
         "82.5k": 18,
         "85k": 21,
         "87.5k": 12,
         "90k": 10,
         "92.5k": 13,
         "95k": 22,
         "97.5k": 12,
         "100k": 18,
         "200k": "194"
      },
      "childfm": {
         "2.5k": 6,
         "5k": 2,
         "7.5k": 4,
         "10k": 13,
         "12.5k": 9,
         "15k": 6,
         "17.5k": 11,
         "20k": 14,
         "22.5k": 15,
         "25k": 17,
         "27.5k": 21,
         "30k": 39,
         "32.5k": 29,
         "35k": 22,
         "37.5k": 12,
         "40k": 18,
         "42.5k": 24,
         "45k": 13,
         "47.5k": 10,
         "50k": 10,
         "52.5k": 17,
         "55k": 19,
         "57.5k": 12,
         "60k": 11,
         "62.5k": 8,
         "65k": 11,
         "67.5k": 11,
         "70k": 16,
         "72.5k": 21,
         "75k": 4,
         "77.5k": 20,
         "80k": 14,
         "82.5k": 17,
         "85k": 12,
         "87.5k": 11,
         "90k": 9,
         "92.5k": 7,
         "95k": 5,
         "97.5k": 9,
         "100k": 16,
         "200k": "141"
      },
      "spousefm": {
         "2.5k": 50,
         "5k": 12,
         "7.5k": 24,
         "10k": 21,
         "12.5k": 45,
         "15k": 45,
         "17.5k": 68,
         "20k": 53,
         "22.5k": 86,
         "25k": 73,
         "27.5k": 96,
         "30k": 93,
         "32.5k": 162,
         "35k": 123,
         "37.5k": 145,
         "40k": 106,
         "42.5k": 235,
         "45k": 107,
         "47.5k": 215,
         "50k": 136,
         "52.5k": 260,
         "55k": 118,
         "57.5k": 189,
         "60k": 153,
         "62.5k": 241,
         "65k": 157,
         "67.5k": 226,
         "70k": 124,
         "72.5k": 261,
         "75k": 144,
         "77.5k": 190,
         "80k": 152,
         "82.5k": 228,
         "85k": 147,
         "87.5k": 183,
         "90k": 121,
         "92.5k": 203,
         "95k": 135,
         "97.5k": 141,
         "100k": 129,
         "200k": "3,158"
      },
      "houseLoitererfm": {
         "2.5k": 236,
         "5k": 108,
         "7.5k": 197,
         "10k": 205,
         "12.5k": 274,
         "15k": 213,
         "17.5k": 282,
         "20k": 234,
         "22.5k": 293,
         "25k": 226,
         "27.5k": 340,
         "30k": 253,
         "32.5k": 353,
         "35k": 205,
         "37.5k": 316,
         "40k": 220,
         "42.5k": 360,
         "45k": 247,
         "47.5k": 272,
         "50k": 185,
         "52.5k": 329,
         "55k": 144,
         "57.5k": 248,
         "60k": 170,
         "62.5k": 271,
         "65k": 125,
         "67.5k": 176,
         "70k": 121,
         "72.5k": 269,
         "75k": 134,
         "77.5k": 213,
         "80k": 132,
         "82.5k": 209,
         "85k": 116,
         "87.5k": 126,
         "90k": 120,
         "92.5k": 167,
         "95k": 82,
         "97.5k": 118,
         "100k": 97,
         "200k": "2,399"
      }
   },
    "54":{
      "nonRelativem": {
         "2.5k": 3,
         "5k": 1,
         "7.5k": 6,
         "10k": 20,
         "12.5k": 4,
         "15k": 21,
         "17.5k": 28,
         "20k": 13,
         "22.5k": 46,
         "25k": 15,
         "27.5k": 26,
         "30k": 28,
         "32.5k": 28,
         "35k": 11,
         "37.5k": 52,
         "40k": 16,
         "42.5k": 21,
         "45k": 28,
         "47.5k": 31,
         "50k": 23,
         "52.5k": 12,
         "55k": 28,
         "57.5k": 42,
         "60k": 24,
         "62.5k": 23,
         "65k": 18,
         "67.5k": 28,
         "70k": 14,
         "72.5k": 53,
         "75k": 19,
         "77.5k": 44,
         "80k": 27,
         "82.5k": 30,
         "85k": 7,
         "87.5k": 5,
         "90k": 13,
         "92.5k": 8,
         "95k": 14,
         "97.5k": 16,
         "100k": 9,
         "200k": "221"
      },
      "otherRelativem": {
         "2.5k": 8,
         "5k": 0,
         "7.5k": 7,
         "10k": 13,
         "12.5k": 40,
         "15k": 25,
         "17.5k": 42,
         "20k": 21,
         "22.5k": 45,
         "25k": 57,
         "27.5k": 37,
         "30k": 43,
         "32.5k": 44,
         "35k": 24,
         "37.5k": 58,
         "40k": 27,
         "42.5k": 35,
         "45k": 19,
         "47.5k": 40,
         "50k": 29,
         "52.5k": 38,
         "55k": 8,
         "57.5k": 20,
         "60k": 11,
         "62.5k": 24,
         "65k": 34,
         "67.5k": 16,
         "70k": 17,
         "72.5k": 17,
         "75k": 13,
         "77.5k": 4,
         "80k": 13,
         "82.5k": 25,
         "85k": 14,
         "87.5k": 1,
         "90k": 15,
         "92.5k": 12,
         "95k": 14,
         "97.5k": 0,
         "100k": 19,
         "200k": "170"
      },
      "childm": {
         "2.5k": 10,
         "5k": 3,
         "7.5k": 6,
         "10k": 8,
         "12.5k": 17,
         "15k": 10,
         "17.5k": 7,
         "20k": 23,
         "22.5k": 16,
         "25k": 25,
         "27.5k": 32,
         "30k": 13,
         "32.5k": 39,
         "35k": 14,
         "37.5k": 20,
         "40k": 13,
         "42.5k": 32,
         "45k": 21,
         "47.5k": 20,
         "50k": 14,
         "52.5k": 46,
         "55k": 25,
         "57.5k": 33,
         "60k": 25,
         "62.5k": 38,
         "65k": 22,
         "67.5k": 20,
         "70k": 19,
         "72.5k": 21,
         "75k": 13,
         "77.5k": 14,
         "80k": 22,
         "82.5k": 19,
         "85k": 13,
         "87.5k": 14,
         "90k": 14,
         "92.5k": 11,
         "95k": 5,
         "97.5k": 20,
         "100k": 7,
         "200k": "245"
      },
      "spousem": {
         "2.5k": 25,
         "5k": 12,
         "7.5k": 17,
         "10k": 10,
         "12.5k": 42,
         "15k": 26,
         "17.5k": 55,
         "20k": 39,
         "22.5k": 63,
         "25k": 40,
         "27.5k": 69,
         "30k": 47,
         "32.5k": 82,
         "35k": 57,
         "37.5k": 85,
         "40k": 89,
         "42.5k": 95,
         "45k": 86,
         "47.5k": 98,
         "50k": 104,
         "52.5k": 136,
         "55k": 75,
         "57.5k": 124,
         "60k": 95,
         "62.5k": 139,
         "65k": 80,
         "67.5k": 114,
         "70k": 69,
         "72.5k": 140,
         "75k": 86,
         "77.5k": 132,
         "80k": 79,
         "82.5k": 132,
         "85k": 84,
         "87.5k": 115,
         "90k": 91,
         "92.5k": 109,
         "95k": 59,
         "97.5k": 87,
         "100k": 75,
         "200k": "2,148"
      },
      "houseLoitererm": {
         "2.5k": 234,
         "5k": 74,
         "7.5k": 111,
         "10k": 169,
         "12.5k": 171,
         "15k": 140,
         "17.5k": 182,
         "20k": 139,
         "22.5k": 230,
         "25k": 174,
         "27.5k": 224,
         "30k": 180,
         "32.5k": 363,
         "35k": 186,
         "37.5k": 258,
         "40k": 195,
         "42.5k": 381,
         "45k": 171,
         "47.5k": 311,
         "50k": 212,
         "52.5k": 379,
         "55k": 219,
         "57.5k": 255,
         "60k": 184,
         "62.5k": 343,
         "65k": 187,
         "67.5k": 284,
         "70k": 164,
         "72.5k": 349,
         "75k": 188,
         "77.5k": 260,
         "80k": 192,
         "82.5k": 239,
         "85k": 191,
         "87.5k": 238,
         "90k": 139,
         "92.5k": 236,
         "95k": 187,
         "97.5k": 196,
         "100k": 142,
         "200k": "4,124"
      },
      "nonRelativefm": {
         "2.5k": 4,
         "5k": 5,
         "7.5k": 3,
         "10k": 20,
         "12.5k": 9,
         "15k": 6,
         "17.5k": 13,
         "20k": 7,
         "22.5k": 15,
         "25k": 12,
         "27.5k": 15,
         "30k": 7,
         "32.5k": 19,
         "35k": 16,
         "37.5k": 19,
         "40k": 19,
         "42.5k": 12,
         "45k": 14,
         "47.5k": 22,
         "50k": 9,
         "52.5k": 17,
         "55k": 21,
         "57.5k": 29,
         "60k": 20,
         "62.5k": 16,
         "65k": 5,
         "67.5k": 28,
         "70k": 15,
         "72.5k": 14,
         "75k": 11,
         "77.5k": 4,
         "80k": 9,
         "82.5k": 8,
         "85k": 10,
         "87.5k": 17,
         "90k": 5,
         "92.5k": 11,
         "95k": 12,
         "97.5k": 2,
         "100k": 8,
         "200k": "189"
      },
      "otherRelativefm": {
         "2.5k": 5,
         "5k": 3,
         "7.5k": 2,
         "10k": 11,
         "12.5k": 7,
         "15k": 11,
         "17.5k": 12,
         "20k": 8,
         "22.5k": 12,
         "25k": 25,
         "27.5k": 18,
         "30k": 15,
         "32.5k": 26,
         "35k": 6,
         "37.5k": 28,
         "40k": 8,
         "42.5k": 13,
         "45k": 10,
         "47.5k": 14,
         "50k": 7,
         "52.5k": 16,
         "55k": 13,
         "57.5k": 11,
         "60k": 3,
         "62.5k": 4,
         "65k": 8,
         "67.5k": 14,
         "70k": 11,
         "72.5k": 15,
         "75k": 0,
         "77.5k": 7,
         "80k": 8,
         "82.5k": 4,
         "85k": 10,
         "87.5k": 2,
         "90k": 3,
         "92.5k": 3,
         "95k": 7,
         "97.5k": 6,
         "100k": 3,
         "200k": "72"
      },
      "childfm": {
         "2.5k": 16,
         "5k": 0,
         "7.5k": 9,
         "10k": 14,
         "12.5k": 8,
         "15k": 12,
         "17.5k": 20,
         "20k": 24,
         "22.5k": 23,
         "25k": 13,
         "27.5k": 30,
         "30k": 19,
         "32.5k": 26,
         "35k": 24,
         "37.5k": 39,
         "40k": 26,
         "42.5k": 25,
         "45k": 14,
         "47.5k": 18,
         "50k": 31,
         "52.5k": 25,
         "55k": 19,
         "57.5k": 31,
         "60k": 16,
         "62.5k": 19,
         "65k": 14,
         "67.5k": 25,
         "70k": 19,
         "72.5k": 32,
         "75k": 17,
         "77.5k": 15,
         "80k": 13,
         "82.5k": 17,
         "85k": 10,
         "87.5k": 12,
         "90k": 9,
         "92.5k": 14,
         "95k": 8,
         "97.5k": 18,
         "100k": 12,
         "200k": "244"
      },
      "spousefm": {
         "2.5k": 52,
         "5k": 18,
         "7.5k": 28,
         "10k": 25,
         "12.5k": 36,
         "15k": 34,
         "17.5k": 61,
         "20k": 88,
         "22.5k": 66,
         "25k": 69,
         "27.5k": 117,
         "30k": 53,
         "32.5k": 151,
         "35k": 98,
         "37.5k": 128,
         "40k": 104,
         "42.5k": 164,
         "45k": 118,
         "47.5k": 199,
         "50k": 168,
         "52.5k": 221,
         "55k": 138,
         "57.5k": 166,
         "60k": 158,
         "62.5k": 217,
         "65k": 126,
         "67.5k": 197,
         "70k": 143,
         "72.5k": 241,
         "75k": 136,
         "77.5k": 170,
         "80k": 121,
         "82.5k": 172,
         "85k": 152,
         "87.5k": 181,
         "90k": 111,
         "92.5k": 172,
         "95k": 146,
         "97.5k": 161,
         "100k": 108,
         "200k": "3,658"
      },
      "houseLoitererfm": {
         "2.5k": 291,
         "5k": 62,
         "7.5k": 197,
         "10k": 251,
         "12.5k": 289,
         "15k": 205,
         "17.5k": 285,
         "20k": 220,
         "22.5k": 317,
         "25k": 199,
         "27.5k": 320,
         "30k": 208,
         "32.5k": 341,
         "35k": 210,
         "37.5k": 319,
         "40k": 232,
         "42.5k": 305,
         "45k": 201,
         "47.5k": 254,
         "50k": 246,
         "52.5k": 317,
         "55k": 194,
         "57.5k": 234,
         "60k": 202,
         "62.5k": 310,
         "65k": 209,
         "67.5k": 241,
         "70k": 117,
         "72.5k": 237,
         "75k": 165,
         "77.5k": 182,
         "80k": 124,
         "82.5k": 206,
         "85k": 113,
         "87.5k": 171,
         "90k": 124,
         "92.5k": 146,
         "95k": 101,
         "97.5k": 148,
         "100k": 119,
         "200k": "2,728"
      }
   },
    "64": {
      nonRelativem: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 1,
        "15k": 0,
        "17.5k": 0,
        "20k": 2,
        "22.5k": 5,
        "25k": 0,
        "27.5k": 0,
        "30k": 1,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 1,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 1,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 2,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      otherRelativem: {
        "2.5k": 1,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 3,
        "15k": 4,
        "17.5k": 4,
        "20k": 12,
        "22.5k": 10,
        "25k": 3,
        "27.5k": 9,
        "30k": 10,
        "32.5k": 11,
        "35k": 0,
        "37.5k": 5,
        "40k": 1,
        "42.5k": 7,
        "45k": 6,
        "47.5k": 0,
        "50k": 6,
        "52.5k": 5,
        "55k": 9,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 2,
        "65k": 1,
        "67.5k": 2,
        "70k": 0,
        "72.5k": 2,
        "75k": 4,
        "77.5k": 0,
        "80k": 2,
        "82.5k": 1,
        "85k": 0,
        "87.5k": 0,
        "90k": 2,
        "92.5k": 0,
        "95k": 1,
        "97.5k": 0,
        "100k": 0,
        "200k": "38"
      },
      childm: {
        "2.5k": 21,
        "5k": 3,
        "7.5k": 22,
        "10k": 26,
        "12.5k": 34,
        "15k": 28,
        "17.5k": 61,
        "20k": 57,
        "22.5k": 72,
        "25k": 75,
        "27.5k": 67,
        "30k": 98,
        "32.5k": 91,
        "35k": 104,
        "37.5k": 108,
        "40k": 67,
        "42.5k": 83,
        "45k": 80,
        "47.5k": 59,
        "50k": 65,
        "52.5k": 61,
        "55k": 43,
        "57.5k": 42,
        "60k": 60,
        "62.5k": 29,
        "65k": 28,
        "67.5k": 25,
        "70k": 22,
        "72.5k": 37,
        "75k": 34,
        "77.5k": 34,
        "80k": 21,
        "82.5k": 18,
        "85k": 21,
        "87.5k": 31,
        "90k": 17,
        "92.5k": 21,
        "95k": 10,
        "97.5k": 18,
        "100k": 9,
        "200k": "396"
      },
      spousem: {
        "2.5k": 89,
        "5k": 28,
        "7.5k": 79,
        "10k": 158,
        "12.5k": 190,
        "15k": 159,
        "17.5k": 211,
        "20k": 256,
        "22.5k": 195,
        "25k": 209,
        "27.5k": 266,
        "30k": 256,
        "32.5k": 263,
        "35k": 205,
        "37.5k": 183,
        "40k": 184,
        "42.5k": 164,
        "45k": 158,
        "47.5k": 147,
        "50k": 170,
        "52.5k": 150,
        "55k": 90,
        "57.5k": 116,
        "60k": 93,
        "62.5k": 123,
        "65k": 89,
        "67.5k": 109,
        "70k": 91,
        "72.5k": 91,
        "75k": 71,
        "77.5k": 103,
        "80k": 62,
        "82.5k": 48,
        "85k": 48,
        "87.5k": 38,
        "90k": 58,
        "92.5k": 42,
        "95k": 49,
        "97.5k": 53,
        "100k": 42,
        "200k": "910"
      },
      houseLoitererm: {
        "2.5k": 112,
        "5k": 31,
        "7.5k": 104,
        "10k": 185,
        "12.5k": 230,
        "15k": 198,
        "17.5k": 286,
        "20k": 332,
        "22.5k": 287,
        "25k": 293,
        "27.5k": 347,
        "30k": 369,
        "32.5k": 373,
        "35k": 330,
        "37.5k": 300,
        "40k": 261,
        "42.5k": 262,
        "45k": 256,
        "47.5k": 216,
        "50k": 249,
        "52.5k": 223,
        "55k": 146,
        "57.5k": 167,
        "60k": 158,
        "62.5k": 162,
        "65k": 117,
        "67.5k": 141,
        "70k": 126,
        "72.5k": 139,
        "75k": 124,
        "77.5k": 147,
        "80k": 93,
        "82.5k": 67,
        "85k": 71,
        "87.5k": 73,
        "90k": 80,
        "92.5k": 65,
        "95k": 63,
        "97.5k": 75,
        "100k": 55,
        "200k": "1,417"
      },
      nonRelativefm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 1,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 3,
        "25k": 3,
        "27.5k": 0,
        "30k": 1,
        "32.5k": 2,
        "35k": 0,
        "37.5k": 4,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "4"
      },
      otherRelativefm: {
        "2.5k": 2,
        "5k": 0,
        "7.5k": 2,
        "10k": 1,
        "12.5k": 0,
        "15k": 2,
        "17.5k": 3,
        "20k": 4,
        "22.5k": 5,
        "25k": 5,
        "27.5k": 10,
        "30k": 12,
        "32.5k": 9,
        "35k": 3,
        "37.5k": 2,
        "40k": 10,
        "42.5k": 4,
        "45k": 4,
        "47.5k": 5,
        "50k": 2,
        "52.5k": 0,
        "55k": 5,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 3,
        "65k": 0,
        "67.5k": 0,
        "70k": 5,
        "72.5k": 2,
        "75k": 1,
        "77.5k": 0,
        "80k": 1,
        "82.5k": 2,
        "85k": 4,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 2,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "34"
      },
      childfm: {
        "2.5k": 45,
        "5k": 8,
        "7.5k": 22,
        "10k": 34,
        "12.5k": 41,
        "15k": 73,
        "17.5k": 95,
        "20k": 146,
        "22.5k": 123,
        "25k": 151,
        "27.5k": 162,
        "30k": 161,
        "32.5k": 157,
        "35k": 129,
        "37.5k": 107,
        "40k": 125,
        "42.5k": 95,
        "45k": 115,
        "47.5k": 90,
        "50k": 102,
        "52.5k": 94,
        "55k": 79,
        "57.5k": 81,
        "60k": 68,
        "62.5k": 57,
        "65k": 72,
        "67.5k": 58,
        "70k": 63,
        "72.5k": 61,
        "75k": 44,
        "77.5k": 58,
        "80k": 34,
        "82.5k": 36,
        "85k": 32,
        "87.5k": 22,
        "90k": 28,
        "92.5k": 28,
        "95k": 27,
        "97.5k": 27,
        "100k": 28,
        "200k": "491"
      },
      spousefm: {
        "2.5k": 76,
        "5k": 51,
        "7.5k": 206,
        "10k": 363,
        "12.5k": 373,
        "15k": 334,
        "17.5k": 332,
        "20k": 302,
        "22.5k": 324,
        "25k": 272,
        "27.5k": 245,
        "30k": 257,
        "32.5k": 218,
        "35k": 226,
        "37.5k": 211,
        "40k": 188,
        "42.5k": 162,
        "45k": 153,
        "47.5k": 101,
        "50k": 103,
        "52.5k": 102,
        "55k": 100,
        "57.5k": 82,
        "60k": 71,
        "62.5k": 53,
        "65k": 39,
        "67.5k": 65,
        "70k": 46,
        "72.5k": 71,
        "75k": 42,
        "77.5k": 39,
        "80k": 36,
        "82.5k": 28,
        "85k": 32,
        "87.5k": 16,
        "90k": 26,
        "92.5k": 27,
        "95k": 11,
        "97.5k": 23,
        "100k": 13,
        "200k": "459"
      },
      houseLoitererfm: {
        "2.5k": 125,
        "5k": 59,
        "7.5k": 234,
        "10k": 402,
        "12.5k": 423,
        "15k": 416,
        "17.5k": 455,
        "20k": 474,
        "22.5k": 475,
        "25k": 440,
        "27.5k": 436,
        "30k": 451,
        "32.5k": 404,
        "35k": 374,
        "37.5k": 351,
        "40k": 336,
        "42.5k": 284,
        "45k": 300,
        "47.5k": 210,
        "50k": 216,
        "52.5k": 212,
        "55k": 197,
        "57.5k": 184,
        "60k": 151,
        "62.5k": 129,
        "65k": 122,
        "67.5k": 144,
        "70k": 125,
        "72.5k": 163,
        "75k": 106,
        "77.5k": 108,
        "80k": 75,
        "82.5k": 76,
        "85k": 72,
        "87.5k": 48,
        "90k": 65,
        "92.5k": 68,
        "95k": 45,
        "97.5k": 61,
        "100k": 54,
        "200k": "1,181"
      }
    },
    "74": {
      nonRelativem: {
        "2.5k": 3,
        "5k": 1,
        "7.5k": 6,
        "10k": 20,
        "12.5k": 4,
        "15k": 21,
        "17.5k": 28,
        "20k": 13,
        "22.5k": 46,
        "25k": 15,
        "27.5k": 26,
        "30k": 28,
        "32.5k": 28,
        "35k": 11,
        "37.5k": 52,
        "40k": 16,
        "42.5k": 21,
        "45k": 28,
        "47.5k": 31,
        "50k": 23,
        "52.5k": 12,
        "55k": 28,
        "57.5k": 42,
        "60k": 24,
        "62.5k": 23,
        "65k": 18,
        "67.5k": 28,
        "70k": 14,
        "72.5k": 53,
        "75k": 19,
        "77.5k": 44,
        "80k": 27,
        "82.5k": 30,
        "85k": 7,
        "87.5k": 5,
        "90k": 13,
        "92.5k": 8,
        "95k": 14,
        "97.5k": 16,
        "100k": 9,
        "200k": "221"
      },
      otherRelativem: {
        "2.5k": 8,
        "5k": 0,
        "7.5k": 7,
        "10k": 13,
        "12.5k": 40,
        "15k": 25,
        "17.5k": 42,
        "20k": 21,
        "22.5k": 45,
        "25k": 57,
        "27.5k": 37,
        "30k": 43,
        "32.5k": 44,
        "35k": 24,
        "37.5k": 58,
        "40k": 27,
        "42.5k": 35,
        "45k": 19,
        "47.5k": 40,
        "50k": 29,
        "52.5k": 38,
        "55k": 8,
        "57.5k": 20,
        "60k": 11,
        "62.5k": 24,
        "65k": 34,
        "67.5k": 16,
        "70k": 17,
        "72.5k": 17,
        "75k": 13,
        "77.5k": 4,
        "80k": 13,
        "82.5k": 25,
        "85k": 14,
        "87.5k": 1,
        "90k": 15,
        "92.5k": 12,
        "95k": 14,
        "97.5k": 0,
        "100k": 19,
        "200k": "170"
      },
      childm: {
        "2.5k": 10,
        "5k": 3,
        "7.5k": 6,
        "10k": 8,
        "12.5k": 17,
        "15k": 10,
        "17.5k": 7,
        "20k": 23,
        "22.5k": 16,
        "25k": 25,
        "27.5k": 32,
        "30k": 13,
        "32.5k": 39,
        "35k": 14,
        "37.5k": 20,
        "40k": 13,
        "42.5k": 32,
        "45k": 21,
        "47.5k": 20,
        "50k": 14,
        "52.5k": 46,
        "55k": 25,
        "57.5k": 33,
        "60k": 25,
        "62.5k": 38,
        "65k": 22,
        "67.5k": 20,
        "70k": 19,
        "72.5k": 21,
        "75k": 13,
        "77.5k": 14,
        "80k": 22,
        "82.5k": 19,
        "85k": 13,
        "87.5k": 14,
        "90k": 14,
        "92.5k": 11,
        "95k": 5,
        "97.5k": 20,
        "100k": 7,
        "200k": "245"
      },
      spousem: {
        "2.5k": 25,
        "5k": 12,
        "7.5k": 17,
        "10k": 10,
        "12.5k": 42,
        "15k": 26,
        "17.5k": 55,
        "20k": 39,
        "22.5k": 63,
        "25k": 40,
        "27.5k": 69,
        "30k": 47,
        "32.5k": 82,
        "35k": 57,
        "37.5k": 85,
        "40k": 89,
        "42.5k": 95,
        "45k": 86,
        "47.5k": 98,
        "50k": 104,
        "52.5k": 136,
        "55k": 75,
        "57.5k": 124,
        "60k": 95,
        "62.5k": 139,
        "65k": 80,
        "67.5k": 114,
        "70k": 69,
        "72.5k": 140,
        "75k": 86,
        "77.5k": 132,
        "80k": 79,
        "82.5k": 132,
        "85k": 84,
        "87.5k": 115,
        "90k": 91,
        "92.5k": 109,
        "95k": 59,
        "97.5k": 87,
        "100k": 75,
        "200k": "2,148"
      },
      houseLoitererm: {
        "2.5k": 234,
        "5k": 74,
        "7.5k": 111,
        "10k": 169,
        "12.5k": 171,
        "15k": 140,
        "17.5k": 182,
        "20k": 139,
        "22.5k": 230,
        "25k": 174,
        "27.5k": 224,
        "30k": 180,
        "32.5k": 363,
        "35k": 186,
        "37.5k": 258,
        "40k": 195,
        "42.5k": 381,
        "45k": 171,
        "47.5k": 311,
        "50k": 212,
        "52.5k": 379,
        "55k": 219,
        "57.5k": 255,
        "60k": 184,
        "62.5k": 343,
        "65k": 187,
        "67.5k": 284,
        "70k": 164,
        "72.5k": 349,
        "75k": 188,
        "77.5k": 260,
        "80k": 192,
        "82.5k": 239,
        "85k": 191,
        "87.5k": 238,
        "90k": 139,
        "92.5k": 236,
        "95k": 187,
        "97.5k": 196,
        "100k": 142,
        "200k": "4,124"
      },
      nonRelativefm: {
        "2.5k": 4,
        "5k": 5,
        "7.5k": 3,
        "10k": 20,
        "12.5k": 9,
        "15k": 6,
        "17.5k": 13,
        "20k": 7,
        "22.5k": 15,
        "25k": 12,
        "27.5k": 15,
        "30k": 7,
        "32.5k": 19,
        "35k": 16,
        "37.5k": 19,
        "40k": 19,
        "42.5k": 12,
        "45k": 14,
        "47.5k": 22,
        "50k": 9,
        "52.5k": 17,
        "55k": 21,
        "57.5k": 29,
        "60k": 20,
        "62.5k": 16,
        "65k": 5,
        "67.5k": 28,
        "70k": 15,
        "72.5k": 14,
        "75k": 11,
        "77.5k": 4,
        "80k": 9,
        "82.5k": 8,
        "85k": 10,
        "87.5k": 17,
        "90k": 5,
        "92.5k": 11,
        "95k": 12,
        "97.5k": 2,
        "100k": 8,
        "200k": "189"
      },
      otherRelativefm: {
        "2.5k": 5,
        "5k": 3,
        "7.5k": 2,
        "10k": 11,
        "12.5k": 7,
        "15k": 11,
        "17.5k": 12,
        "20k": 8,
        "22.5k": 12,
        "25k": 25,
        "27.5k": 18,
        "30k": 15,
        "32.5k": 26,
        "35k": 6,
        "37.5k": 28,
        "40k": 8,
        "42.5k": 13,
        "45k": 10,
        "47.5k": 14,
        "50k": 7,
        "52.5k": 16,
        "55k": 13,
        "57.5k": 11,
        "60k": 3,
        "62.5k": 4,
        "65k": 8,
        "67.5k": 14,
        "70k": 11,
        "72.5k": 15,
        "75k": 0,
        "77.5k": 7,
        "80k": 8,
        "82.5k": 4,
        "85k": 10,
        "87.5k": 2,
        "90k": 3,
        "92.5k": 3,
        "95k": 7,
        "97.5k": 6,
        "100k": 3,
        "200k": "72"
      },
      childfm: {
        "2.5k": 16,
        "5k": 0,
        "7.5k": 9,
        "10k": 14,
        "12.5k": 8,
        "15k": 12,
        "17.5k": 20,
        "20k": 24,
        "22.5k": 23,
        "25k": 13,
        "27.5k": 30,
        "30k": 19,
        "32.5k": 26,
        "35k": 24,
        "37.5k": 39,
        "40k": 26,
        "42.5k": 25,
        "45k": 14,
        "47.5k": 18,
        "50k": 31,
        "52.5k": 25,
        "55k": 19,
        "57.5k": 31,
        "60k": 16,
        "62.5k": 19,
        "65k": 14,
        "67.5k": 25,
        "70k": 19,
        "72.5k": 32,
        "75k": 17,
        "77.5k": 15,
        "80k": 13,
        "82.5k": 17,
        "85k": 10,
        "87.5k": 12,
        "90k": 9,
        "92.5k": 14,
        "95k": 8,
        "97.5k": 18,
        "100k": 12,
        "200k": "244"
      },
      spousefm: {
        "2.5k": 52,
        "5k": 18,
        "7.5k": 28,
        "10k": 25,
        "12.5k": 36,
        "15k": 34,
        "17.5k": 61,
        "20k": 88,
        "22.5k": 66,
        "25k": 69,
        "27.5k": 117,
        "30k": 53,
        "32.5k": 151,
        "35k": 98,
        "37.5k": 128,
        "40k": 104,
        "42.5k": 164,
        "45k": 118,
        "47.5k": 199,
        "50k": 168,
        "52.5k": 221,
        "55k": 138,
        "57.5k": 166,
        "60k": 158,
        "62.5k": 217,
        "65k": 126,
        "67.5k": 197,
        "70k": 143,
        "72.5k": 241,
        "75k": 136,
        "77.5k": 170,
        "80k": 121,
        "82.5k": 172,
        "85k": 152,
        "87.5k": 181,
        "90k": 111,
        "92.5k": 172,
        "95k": 146,
        "97.5k": 161,
        "100k": 108,
        "200k": "3,658"
      },
      houseLoitererfm: {
        "2.5k": 291,
        "5k": 62,
        "7.5k": 197,
        "10k": 251,
        "12.5k": 289,
        "15k": 205,
        "17.5k": 285,
        "20k": 220,
        "22.5k": 317,
        "25k": 199,
        "27.5k": 320,
        "30k": 208,
        "32.5k": 341,
        "35k": 210,
        "37.5k": 319,
        "40k": 232,
        "42.5k": 305,
        "45k": 201,
        "47.5k": 254,
        "50k": 246,
        "52.5k": 317,
        "55k": 194,
        "57.5k": 234,
        "60k": 202,
        "62.5k": 310,
        "65k": 209,
        "67.5k": 241,
        "70k": 117,
        "72.5k": 237,
        "75k": 165,
        "77.5k": 182,
        "80k": 124,
        "82.5k": 206,
        "85k": 113,
        "87.5k": 171,
        "90k": 124,
        "92.5k": 146,
        "95k": 101,
        "97.5k": 148,
        "100k": 119,
        "200k": "2,728"
      }
    }
  },
  "2007": {
    "17": {
      houseLoitererm: {
        "2.5k": 2,
        "5k": 1,
        "7.5k": 0,
        "10k": 2,
        "12.5k": 4,
        "15k": 1,
        "17.5k": 4,
        "20k": 5,
        "22.5k": 7,
        "25k": 4,
        "27.5k": 3,
        "30k": 2,
        "32.5k": 8,
        "35k": 3,
        "37.5k": 5,
        "40k": 3,
        "42.5k": 1,
        "45k": 8,
        "47.5k": 3,
        "50k": 3,
        "52.5k": 2,
        "55k": 0,
        "57.5k": 7,
        "60k": 0,
        "62.5k": 8,
        "65k": 3,
        "67.5k": 2,
        "70k": 2,
        "72.5k": 1,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 1,
        "97.5k": 0,
        "100k": 0,
        "200k": "5"
      },
      spousem: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 0,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 0,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 1,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      childm: {
        "2.5k": 152,
        "5k": 92,
        "7.5k": 111,
        "10k": 160,
        "12.5k": 203,
        "15k": 175,
        "17.5k": 225,
        "20k": 210,
        "22.5k": 234,
        "25k": 229,
        "27.5k": 283,
        "30k": 180,
        "32.5k": 299,
        "35k": 212,
        "37.5k": 309,
        "40k": 247,
        "42.5k": 327,
        "45k": 188,
        "47.5k": 278,
        "50k": 202,
        "52.5k": 343,
        "55k": 213,
        "57.5k": 281,
        "60k": 198,
        "62.5k": 327,
        "65k": 176,
        "67.5k": 235,
        "70k": 179,
        "72.5k": 263,
        "75k": 181,
        "77.5k": 270,
        "80k": 166,
        "82.5k": 230,
        "85k": 121,
        "87.5k": 242,
        "90k": 142,
        "92.5k": 185,
        "95k": 105,
        "97.5k": 163,
        "100k": 148,
        "200k": "3,249"
      },
      otherRelativem: {
        "2.5k": 25,
        "5k": 16,
        "7.5k": 28,
        "10k": 17,
        "12.5k": 22,
        "15k": 26,
        "17.5k": 25,
        "20k": 26,
        "22.5k": 32,
        "25k": 40,
        "27.5k": 28,
        "30k": 17,
        "32.5k": 43,
        "35k": 18,
        "37.5k": 22,
        "40k": 26,
        "42.5k": 25,
        "45k": 26,
        "47.5k": 21,
        "50k": 23,
        "52.5k": 35,
        "55k": 28,
        "57.5k": 28,
        "60k": 9,
        "62.5k": 21,
        "65k": 10,
        "67.5k": 12,
        "70k": 18,
        "72.5k": 14,
        "75k": 20,
        "77.5k": 8,
        "80k": 5,
        "82.5k": 18,
        "85k": 15,
        "87.5k": 8,
        "90k": 7,
        "92.5k": 6,
        "95k": 8,
        "97.5k": 5,
        "100k": 3,
        "200k": "155"
      },
      nonRelativem: {
        "2.5k": 0,
        "5k": 2,
        "7.5k": 3,
        "10k": 0,
        "12.5k": 7,
        "15k": 2,
        "17.5k": 8,
        "20k": 4,
        "22.5k": 8,
        "25k": 6,
        "27.5k": 5,
        "30k": 5,
        "32.5k": 6,
        "35k": 3,
        "37.5k": 0,
        "40k": 6,
        "42.5k": 13,
        "45k": 2,
        "47.5k": 14,
        "50k": 2,
        "52.5k": 7,
        "55k": 1,
        "57.5k": 13,
        "60k": 5,
        "62.5k": 16,
        "65k": 10,
        "67.5k": 14,
        "70k": 8,
        "72.5k": 5,
        "75k": 10,
        "77.5k": 6,
        "80k": 3,
        "82.5k": 3,
        "85k": 9,
        "87.5k": 4,
        "90k": 4,
        "92.5k": 4,
        "95k": 2,
        "97.5k": 2,
        "100k": 0,
        "200k": "57"
      },
      houseLoitererfm: {
        "2.5k": 5,
        "5k": 2,
        "7.5k": 3,
        "10k": 0,
        "12.5k": 5,
        "15k": 6,
        "17.5k": 4,
        "20k": 1,
        "22.5k": 6,
        "25k": 1,
        "27.5k": 6,
        "30k": 1,
        "32.5k": 7,
        "35k": 2,
        "37.5k": 3,
        "40k": 1,
        "42.5k": 5,
        "45k": 0,
        "47.5k": 3,
        "50k": 6,
        "52.5k": 1,
        "55k": 1,
        "57.5k": 0,
        "60k": 1,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 2,
        "70k": 2,
        "72.5k": 3,
        "75k": 3,
        "77.5k": 1,
        "80k": 4,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 1,
        "90k": 2,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 5,
        "100k": 0,
        "200k": "15"
      },
      spousefm: {
        "2.5k": 0,
        "5k": 1,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 0,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 2,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 2,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      childfm: {
        "2.5k": 181,
        "5k": 89,
        "7.5k": 119,
        "10k": 155,
        "12.5k": 177,
        "15k": 165,
        "17.5k": 240,
        "20k": 226,
        "22.5k": 266,
        "25k": 234,
        "27.5k": 257,
        "30k": 189,
        "32.5k": 284,
        "35k": 194,
        "37.5k": 241,
        "40k": 248,
        "42.5k": 304,
        "45k": 204,
        "47.5k": 262,
        "50k": 185,
        "52.5k": 297,
        "55k": 195,
        "57.5k": 231,
        "60k": 154,
        "62.5k": 302,
        "65k": 163,
        "67.5k": 195,
        "70k": 162,
        "72.5k": 271,
        "75k": 166,
        "77.5k": 233,
        "80k": 148,
        "82.5k": 219,
        "85k": 187,
        "87.5k": 225,
        "90k": 119,
        "92.5k": 154,
        "95k": 118,
        "97.5k": 149,
        "100k": 124,
        "200k": "3,146"
      },
      otherRelativefm: {
        "2.5k": 25,
        "5k": 12,
        "7.5k": 19,
        "10k": 16,
        "12.5k": 40,
        "15k": 31,
        "17.5k": 42,
        "20k": 32,
        "22.5k": 24,
        "25k": 34,
        "27.5k": 41,
        "30k": 20,
        "32.5k": 32,
        "35k": 23,
        "37.5k": 28,
        "40k": 22,
        "42.5k": 45,
        "45k": 25,
        "47.5k": 25,
        "50k": 18,
        "52.5k": 17,
        "55k": 22,
        "57.5k": 24,
        "60k": 14,
        "62.5k": 17,
        "65k": 27,
        "67.5k": 17,
        "70k": 11,
        "72.5k": 17,
        "75k": 6,
        "77.5k": 17,
        "80k": 18,
        "82.5k": 23,
        "85k": 14,
        "87.5k": 12,
        "90k": 6,
        "92.5k": 11,
        "95k": 12,
        "97.5k": 7,
        "100k": 4,
        "200k": "170"
      },
      nonRelativefm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 4,
        "10k": 4,
        "12.5k": 0,
        "15k": 9,
        "17.5k": 4,
        "20k": 3,
        "22.5k": 5,
        "25k": 12,
        "27.5k": 21,
        "30k": 9,
        "32.5k": 3,
        "35k": 4,
        "37.5k": 3,
        "40k": 5,
        "42.5k": 10,
        "45k": 6,
        "47.5k": 7,
        "50k": 2,
        "52.5k": 5,
        "55k": 15,
        "57.5k": 6,
        "60k": 2,
        "62.5k": 4,
        "65k": 8,
        "67.5k": 9,
        "70k": 1,
        "72.5k": 6,
        "75k": 11,
        "77.5k": 5,
        "80k": 5,
        "82.5k": 9,
        "85k": 2,
        "87.5k": 7,
        "90k": 2,
        "92.5k": 3,
        "95k": 7,
        "97.5k": 4,
        "100k": 1,
        "200k": "52"
      }
    },
    "21": {
      houseLoitererm: {
        "2.5k": 54,
        "5k": 20,
        "7.5k": 22,
        "10k": 18,
        "12.5k": 39,
        "15k": 33,
        "17.5k": 56,
        "20k": 36,
        "22.5k": 51,
        "25k": 36,
        "27.5k": 35,
        "30k": 30,
        "32.5k": 40,
        "35k": 20,
        "37.5k": 48,
        "40k": 27,
        "42.5k": 45,
        "45k": 20,
        "47.5k": 25,
        "50k": 11,
        "52.5k": 20,
        "55k": 11,
        "57.5k": 23,
        "60k": 14,
        "62.5k": 18,
        "65k": 11,
        "67.5k": 12,
        "70k": 8,
        "72.5k": 12,
        "75k": 8,
        "77.5k": 13,
        "80k": 10,
        "82.5k": 6,
        "85k": 5,
        "87.5k": 3,
        "90k": 0,
        "92.5k": 5,
        "95k": 2,
        "97.5k": 4,
        "100k": 6,
        "200k": "64"
      },
      spousem: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 1,
        "10k": 0,
        "12.5k": 4,
        "15k": 2,
        "17.5k": 3,
        "20k": 1,
        "22.5k": 2,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 11,
        "35k": 3,
        "37.5k": 4,
        "40k": 1,
        "42.5k": 6,
        "45k": 4,
        "47.5k": 0,
        "50k": 2,
        "52.5k": 2,
        "55k": 1,
        "57.5k": 0,
        "60k": 1,
        "62.5k": 5,
        "65k": 1,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 3,
        "75k": 0,
        "77.5k": 1,
        "80k": 0,
        "82.5k": 2,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      childm: {
        "2.5k": 44,
        "5k": 17,
        "7.5k": 21,
        "10k": 58,
        "12.5k": 47,
        "15k": 55,
        "17.5k": 45,
        "20k": 91,
        "22.5k": 95,
        "25k": 60,
        "27.5k": 104,
        "30k": 59,
        "32.5k": 88,
        "35k": 80,
        "37.5k": 105,
        "40k": 82,
        "42.5k": 145,
        "45k": 132,
        "47.5k": 101,
        "50k": 61,
        "52.5k": 158,
        "55k": 93,
        "57.5k": 117,
        "60k": 78,
        "62.5k": 147,
        "65k": 102,
        "67.5k": 112,
        "70k": 94,
        "72.5k": 101,
        "75k": 108,
        "77.5k": 135,
        "80k": 106,
        "82.5k": 121,
        "85k": 81,
        "87.5k": 91,
        "90k": 68,
        "92.5k": 94,
        "95k": 77,
        "97.5k": 81,
        "100k": 83,
        "200k": "2,124"
      },
      otherRelativem: {
        "2.5k": 26,
        "5k": 4,
        "7.5k": 5,
        "10k": 16,
        "12.5k": 13,
        "15k": 20,
        "17.5k": 12,
        "20k": 25,
        "22.5k": 14,
        "25k": 19,
        "27.5k": 13,
        "30k": 12,
        "32.5k": 19,
        "35k": 29,
        "37.5k": 22,
        "40k": 16,
        "42.5k": 24,
        "45k": 17,
        "47.5k": 19,
        "50k": 11,
        "52.5k": 17,
        "55k": 21,
        "57.5k": 14,
        "60k": 17,
        "62.5k": 27,
        "65k": 13,
        "67.5k": 21,
        "70k": 10,
        "72.5k": 23,
        "75k": 14,
        "77.5k": 14,
        "80k": 11,
        "82.5k": 6,
        "85k": 5,
        "87.5k": 2,
        "90k": 9,
        "92.5k": 7,
        "95k": 5,
        "97.5k": 4,
        "100k": 2,
        "200k": "108"
      },
      nonRelativem: {
        "2.5k": 48,
        "5k": 5,
        "7.5k": 10,
        "10k": 11,
        "12.5k": 10,
        "15k": 19,
        "17.5k": 16,
        "20k": 17,
        "22.5k": 23,
        "25k": 16,
        "27.5k": 39,
        "30k": 28,
        "32.5k": 34,
        "35k": 12,
        "37.5k": 36,
        "40k": 20,
        "42.5k": 32,
        "45k": 23,
        "47.5k": 23,
        "50k": 8,
        "52.5k": 28,
        "55k": 12,
        "57.5k": 21,
        "60k": 9,
        "62.5k": 26,
        "65k": 5,
        "67.5k": 14,
        "70k": 8,
        "72.5k": 11,
        "75k": 8,
        "77.5k": 14,
        "80k": 12,
        "82.5k": 8,
        "85k": 3,
        "87.5k": 7,
        "90k": 8,
        "92.5k": 11,
        "95k": 4,
        "97.5k": 5,
        "100k": 5,
        "200k": "114"
      },
      houseLoitererfm: {
        "2.5k": 106,
        "5k": 42,
        "7.5k": 65,
        "10k": 43,
        "12.5k": 73,
        "15k": 54,
        "17.5k": 59,
        "20k": 54,
        "22.5k": 57,
        "25k": 55,
        "27.5k": 72,
        "30k": 50,
        "32.5k": 59,
        "35k": 25,
        "37.5k": 43,
        "40k": 23,
        "42.5k": 40,
        "45k": 39,
        "47.5k": 11,
        "50k": 17,
        "52.5k": 42,
        "55k": 16,
        "57.5k": 18,
        "60k": 15,
        "62.5k": 21,
        "65k": 3,
        "67.5k": 9,
        "70k": 12,
        "72.5k": 6,
        "75k": 5,
        "77.5k": 8,
        "80k": 4,
        "82.5k": 7,
        "85k": 7,
        "87.5k": 3,
        "90k": 0,
        "92.5k": 4,
        "95k": 2,
        "97.5k": 4,
        "100k": 3,
        "200k": "38"
      },
      spousefm: {
        "2.5k": 2,
        "5k": 4,
        "7.5k": 3,
        "10k": 5,
        "12.5k": 12,
        "15k": 6,
        "17.5k": 11,
        "20k": 5,
        "22.5k": 15,
        "25k": 6,
        "27.5k": 17,
        "30k": 12,
        "32.5k": 18,
        "35k": 9,
        "37.5k": 19,
        "40k": 11,
        "42.5k": 17,
        "45k": 2,
        "47.5k": 8,
        "50k": 4,
        "52.5k": 13,
        "55k": 10,
        "57.5k": 4,
        "60k": 4,
        "62.5k": 12,
        "65k": 4,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 14,
        "75k": 0,
        "77.5k": 3,
        "80k": 2,
        "82.5k": 2,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 2,
        "95k": 1,
        "97.5k": 5,
        "100k": 0,
        "200k": "5"
      },
      childfm: {
        "2.5k": 31,
        "5k": 22,
        "7.5k": 30,
        "10k": 40,
        "12.5k": 52,
        "15k": 56,
        "17.5k": 74,
        "20k": 74,
        "22.5k": 76,
        "25k": 67,
        "27.5k": 79,
        "30k": 60,
        "32.5k": 103,
        "35k": 58,
        "37.5k": 95,
        "40k": 69,
        "42.5k": 121,
        "45k": 95,
        "47.5k": 96,
        "50k": 69,
        "52.5k": 109,
        "55k": 68,
        "57.5k": 112,
        "60k": 97,
        "62.5k": 122,
        "65k": 83,
        "67.5k": 109,
        "70k": 70,
        "72.5k": 109,
        "75k": 81,
        "77.5k": 114,
        "80k": 76,
        "82.5k": 114,
        "85k": 83,
        "87.5k": 88,
        "90k": 65,
        "92.5k": 93,
        "95k": 56,
        "97.5k": 83,
        "100k": 59,
        "200k": "1,788"
      },
      otherRelativefm: {
        "2.5k": 12,
        "5k": 6,
        "7.5k": 4,
        "10k": 8,
        "12.5k": 9,
        "15k": 13,
        "17.5k": 24,
        "20k": 18,
        "22.5k": 20,
        "25k": 22,
        "27.5k": 18,
        "30k": 17,
        "32.5k": 26,
        "35k": 23,
        "37.5k": 18,
        "40k": 15,
        "42.5k": 18,
        "45k": 12,
        "47.5k": 9,
        "50k": 21,
        "52.5k": 18,
        "55k": 1,
        "57.5k": 20,
        "60k": 18,
        "62.5k": 10,
        "65k": 8,
        "67.5k": 15,
        "70k": 14,
        "72.5k": 15,
        "75k": 5,
        "77.5k": 12,
        "80k": 4,
        "82.5k": 22,
        "85k": 5,
        "87.5k": 5,
        "90k": 3,
        "92.5k": 5,
        "95k": 7,
        "97.5k": 8,
        "100k": 0,
        "200k": "104"
      },
      nonRelativefm: {
        "2.5k": 13,
        "5k": 11,
        "7.5k": 10,
        "10k": 12,
        "12.5k": 22,
        "15k": 29,
        "17.5k": 25,
        "20k": 37,
        "22.5k": 18,
        "25k": 32,
        "27.5k": 38,
        "30k": 24,
        "32.5k": 33,
        "35k": 26,
        "37.5k": 25,
        "40k": 20,
        "42.5k": 24,
        "45k": 26,
        "47.5k": 19,
        "50k": 22,
        "52.5k": 23,
        "55k": 33,
        "57.5k": 33,
        "60k": 19,
        "62.5k": 20,
        "65k": 11,
        "67.5k": 9,
        "70k": 11,
        "72.5k": 12,
        "75k": 10,
        "77.5k": 13,
        "80k": 3,
        "82.5k": 9,
        "85k": 9,
        "87.5k": 6,
        "90k": 2,
        "92.5k": 8,
        "95k": 8,
        "97.5k": 16,
        "100k": 1,
        "200k": "90"
      }
    },
    "24": {
      houseLoitererm: {
        "2.5k": 69,
        "5k": 18,
        "7.5k": 24,
        "10k": 53,
        "12.5k": 69,
        "15k": 48,
        "17.5k": 70,
        "20k": 50,
        "22.5k": 99,
        "25k": 72,
        "27.5k": 129,
        "30k": 57,
        "32.5k": 110,
        "35k": 61,
        "37.5k": 90,
        "40k": 53,
        "42.5k": 84,
        "45k": 55,
        "47.5k": 66,
        "50k": 59,
        "52.5k": 79,
        "55k": 54,
        "57.5k": 55,
        "60k": 37,
        "62.5k": 62,
        "65k": 15,
        "67.5k": 33,
        "70k": 16,
        "72.5k": 28,
        "75k": 28,
        "77.5k": 15,
        "80k": 17,
        "82.5k": 31,
        "85k": 11,
        "87.5k": 19,
        "90k": 19,
        "92.5k": 6,
        "95k": 19,
        "97.5k": 16,
        "100k": 6,
        "200k": "126"
      },
      spousem: {
        "2.5k": 12,
        "5k": 0,
        "7.5k": 4,
        "10k": 2,
        "12.5k": 5,
        "15k": 13,
        "17.5k": 9,
        "20k": 8,
        "22.5k": 13,
        "25k": 12,
        "27.5k": 14,
        "30k": 9,
        "32.5k": 15,
        "35k": 7,
        "37.5k": 15,
        "40k": 17,
        "42.5k": 12,
        "45k": 16,
        "47.5k": 3,
        "50k": 12,
        "52.5k": 11,
        "55k": 11,
        "57.5k": 11,
        "60k": 10,
        "62.5k": 12,
        "65k": 2,
        "67.5k": 6,
        "70k": 6,
        "72.5k": 8,
        "75k": 3,
        "77.5k": 4,
        "80k": 2,
        "82.5k": 2,
        "85k": 1,
        "87.5k": 0,
        "90k": 2,
        "92.5k": 3,
        "95k": 2,
        "97.5k": 0,
        "100k": 1,
        "200k": "11"
      },
      childm: {
        "2.5k": 11,
        "5k": 12,
        "7.5k": 10,
        "10k": 9,
        "12.5k": 11,
        "15k": 13,
        "17.5k": 33,
        "20k": 20,
        "22.5k": 37,
        "25k": 29,
        "27.5k": 34,
        "30k": 37,
        "32.5k": 33,
        "35k": 34,
        "37.5k": 33,
        "40k": 32,
        "42.5k": 50,
        "45k": 33,
        "47.5k": 25,
        "50k": 20,
        "52.5k": 46,
        "55k": 46,
        "57.5k": 34,
        "60k": 46,
        "62.5k": 74,
        "65k": 64,
        "67.5k": 40,
        "70k": 31,
        "72.5k": 43,
        "75k": 45,
        "77.5k": 34,
        "80k": 41,
        "82.5k": 59,
        "85k": 26,
        "87.5k": 32,
        "90k": 31,
        "92.5k": 38,
        "95k": 25,
        "97.5k": 37,
        "100k": 22,
        "200k": "1,069"
      },
      otherRelativem: {
        "2.5k": 2,
        "5k": 4,
        "7.5k": 5,
        "10k": 5,
        "12.5k": 13,
        "15k": 8,
        "17.5k": 5,
        "20k": 7,
        "22.5k": 5,
        "25k": 13,
        "27.5k": 17,
        "30k": 12,
        "32.5k": 16,
        "35k": 17,
        "37.5k": 20,
        "40k": 13,
        "42.5k": 17,
        "45k": 15,
        "47.5k": 11,
        "50k": 10,
        "52.5k": 26,
        "55k": 4,
        "57.5k": 20,
        "60k": 6,
        "62.5k": 28,
        "65k": 4,
        "67.5k": 11,
        "70k": 4,
        "72.5k": 10,
        "75k": 3,
        "77.5k": 20,
        "80k": 14,
        "82.5k": 9,
        "85k": 8,
        "87.5k": 8,
        "90k": 23,
        "92.5k": 7,
        "95k": 3,
        "97.5k": 15,
        "100k": 10,
        "200k": "128"
      },
      nonRelativem: {
        "2.5k": 10,
        "5k": 3,
        "7.5k": 15,
        "10k": 12,
        "12.5k": 9,
        "15k": 9,
        "17.5k": 11,
        "20k": 27,
        "22.5k": 18,
        "25k": 40,
        "27.5k": 25,
        "30k": 21,
        "32.5k": 37,
        "35k": 16,
        "37.5k": 39,
        "40k": 17,
        "42.5k": 51,
        "45k": 18,
        "47.5k": 22,
        "50k": 29,
        "52.5k": 37,
        "55k": 37,
        "57.5k": 17,
        "60k": 27,
        "62.5k": 34,
        "65k": 10,
        "67.5k": 22,
        "70k": 28,
        "72.5k": 23,
        "75k": 7,
        "77.5k": 12,
        "80k": 18,
        "82.5k": 20,
        "85k": 15,
        "87.5k": 4,
        "90k": 21,
        "92.5k": 4,
        "95k": 18,
        "97.5k": 20,
        "100k": 15,
        "200k": "162"
      },
      houseLoitererfm: {
        "2.5k": 131,
        "5k": 60,
        "7.5k": 89,
        "10k": 70,
        "12.5k": 91,
        "15k": 118,
        "17.5k": 110,
        "20k": 62,
        "22.5k": 80,
        "25k": 86,
        "27.5k": 95,
        "30k": 58,
        "32.5k": 105,
        "35k": 48,
        "37.5k": 70,
        "40k": 68,
        "42.5k": 105,
        "45k": 43,
        "47.5k": 49,
        "50k": 45,
        "52.5k": 74,
        "55k": 46,
        "57.5k": 53,
        "60k": 24,
        "62.5k": 55,
        "65k": 18,
        "67.5k": 16,
        "70k": 14,
        "72.5k": 23,
        "75k": 25,
        "77.5k": 40,
        "80k": 19,
        "82.5k": 22,
        "85k": 4,
        "87.5k": 15,
        "90k": 7,
        "92.5k": 10,
        "95k": 11,
        "97.5k": 11,
        "100k": 5,
        "200k": "104"
      },
      spousefm: {
        "2.5k": 3,
        "5k": 0,
        "7.5k": 1,
        "10k": 17,
        "12.5k": 15,
        "15k": 6,
        "17.5k": 18,
        "20k": 15,
        "22.5k": 29,
        "25k": 18,
        "27.5k": 39,
        "30k": 21,
        "32.5k": 44,
        "35k": 19,
        "37.5k": 44,
        "40k": 20,
        "42.5k": 33,
        "45k": 23,
        "47.5k": 49,
        "50k": 17,
        "52.5k": 23,
        "55k": 20,
        "57.5k": 13,
        "60k": 8,
        "62.5k": 30,
        "65k": 10,
        "67.5k": 23,
        "70k": 4,
        "72.5k": 17,
        "75k": 11,
        "77.5k": 17,
        "80k": 13,
        "82.5k": 16,
        "85k": 1,
        "87.5k": 7,
        "90k": 4,
        "92.5k": 5,
        "95k": 4,
        "97.5k": 8,
        "100k": 11,
        "200k": "69"
      },
      childfm: {
        "2.5k": 15,
        "5k": 4,
        "7.5k": 7,
        "10k": 25,
        "12.5k": 12,
        "15k": 20,
        "17.5k": 10,
        "20k": 21,
        "22.5k": 24,
        "25k": 24,
        "27.5k": 25,
        "30k": 19,
        "32.5k": 36,
        "35k": 31,
        "37.5k": 29,
        "40k": 23,
        "42.5k": 33,
        "45k": 27,
        "47.5k": 34,
        "50k": 32,
        "52.5k": 23,
        "55k": 13,
        "57.5k": 32,
        "60k": 24,
        "62.5k": 53,
        "65k": 36,
        "67.5k": 41,
        "70k": 20,
        "72.5k": 41,
        "75k": 21,
        "77.5k": 33,
        "80k": 27,
        "82.5k": 41,
        "85k": 24,
        "87.5k": 25,
        "90k": 28,
        "92.5k": 34,
        "95k": 18,
        "97.5k": 22,
        "100k": 26,
        "200k": "758"
      },
      otherRelativefm: {
        "2.5k": 5,
        "5k": 0,
        "7.5k": 2,
        "10k": 0,
        "12.5k": 10,
        "15k": 5,
        "17.5k": 9,
        "20k": 5,
        "22.5k": 7,
        "25k": 8,
        "27.5k": 11,
        "30k": 10,
        "32.5k": 7,
        "35k": 2,
        "37.5k": 4,
        "40k": 10,
        "42.5k": 13,
        "45k": 2,
        "47.5k": 11,
        "50k": 16,
        "52.5k": 15,
        "55k": 8,
        "57.5k": 15,
        "60k": 12,
        "62.5k": 9,
        "65k": 3,
        "67.5k": 8,
        "70k": 5,
        "72.5k": 7,
        "75k": 6,
        "77.5k": 10,
        "80k": 5,
        "82.5k": 6,
        "85k": 4,
        "87.5k": 8,
        "90k": 7,
        "92.5k": 7,
        "95k": 8,
        "97.5k": 12,
        "100k": 8,
        "200k": "125"
      },
      nonRelativefm: {
        "2.5k": 27,
        "5k": 5,
        "7.5k": 11,
        "10k": 5,
        "12.5k": 12,
        "15k": 16,
        "17.5k": 13,
        "20k": 23,
        "22.5k": 14,
        "25k": 17,
        "27.5k": 50,
        "30k": 19,
        "32.5k": 31,
        "35k": 20,
        "37.5k": 54,
        "40k": 39,
        "42.5k": 39,
        "45k": 16,
        "47.5k": 21,
        "50k": 39,
        "52.5k": 33,
        "55k": 21,
        "57.5k": 27,
        "60k": 25,
        "62.5k": 19,
        "65k": 14,
        "67.5k": 22,
        "70k": 27,
        "72.5k": 15,
        "75k": 18,
        "77.5k": 20,
        "80k": 10,
        "82.5k": 25,
        "85k": 6,
        "87.5k": 30,
        "90k": 13,
        "92.5k": 6,
        "95k": 5,
        "97.5k": 21,
        "100k": 12,
        "200k": "141"
      }
    },
    "29": {
      houseLoitererm: {
        "2.5k": 53,
        "5k": 51,
        "7.5k": 42,
        "10k": 57,
        "12.5k": 79,
        "15k": 72,
        "17.5k": 91,
        "20k": 92,
        "22.5k": 181,
        "25k": 113,
        "27.5k": 184,
        "30k": 87,
        "32.5k": 235,
        "35k": 104,
        "37.5k": 150,
        "40k": 111,
        "42.5k": 201,
        "45k": 102,
        "47.5k": 176,
        "50k": 109,
        "52.5k": 174,
        "55k": 123,
        "57.5k": 143,
        "60k": 85,
        "62.5k": 159,
        "65k": 79,
        "67.5k": 98,
        "70k": 95,
        "72.5k": 108,
        "75k": 76,
        "77.5k": 96,
        "80k": 69,
        "82.5k": 100,
        "85k": 38,
        "87.5k": 78,
        "90k": 34,
        "92.5k": 66,
        "95k": 29,
        "97.5k": 50,
        "100k": 47,
        "200k": 679
      },
      spousem: {
        "2.5k": 9,
        "5k": 4,
        "7.5k": 5,
        "10k": 4,
        "12.5k": 23,
        "15k": 20,
        "17.5k": 16,
        "20k": 26,
        "22.5k": 34,
        "25k": 17,
        "27.5k": 53,
        "30k": 28,
        "32.5k": 64,
        "35k": 35,
        "37.5k": 48,
        "40k": 29,
        "42.5k": 43,
        "45k": 31,
        "47.5k": 58,
        "50k": 40,
        "52.5k": 81,
        "55k": 30,
        "57.5k": 47,
        "60k": 27,
        "62.5k": 58,
        "65k": 41,
        "67.5k": 37,
        "70k": 21,
        "72.5k": 47,
        "75k": 34,
        "77.5k": 52,
        "80k": 27,
        "82.5k": 41,
        "85k": 14,
        "87.5k": 27,
        "90k": 12,
        "92.5k": 26,
        "95k": 10,
        "97.5k": 28,
        "100k": 8,
        "200k": 225
      },
      childm: {
        "2.5k": 21,
        "5k": 4,
        "7.5k": 23,
        "10k": 13,
        "12.5k": 31,
        "15k": 14,
        "17.5k": 13,
        "20k": 34,
        "22.5k": 39,
        "25k": 23,
        "27.5k": 24,
        "30k": 16,
        "32.5k": 42,
        "35k": 28,
        "37.5k": 40,
        "40k": 25,
        "42.5k": 54,
        "45k": 36,
        "47.5k": 26,
        "50k": 44,
        "52.5k": 57,
        "55k": 45,
        "57.5k": 50,
        "60k": 53,
        "62.5k": 61,
        "65k": 44,
        "67.5k": 31,
        "70k": 35,
        "72.5k": 63,
        "75k": 53,
        "77.5k": 49,
        "80k": 26,
        "82.5k": 52,
        "85k": 33,
        "87.5k": 39,
        "90k": 25,
        "92.5k": 46,
        "95k": 34,
        "97.5k": 33,
        "100k": 23,
        "200k": 729
      },
      otherRelativem: {
        "2.5k": 5,
        "5k": 2,
        "7.5k": 5,
        "10k": 11,
        "12.5k": 7,
        "15k": 6,
        "17.5k": 21,
        "20k": 12,
        "22.5k": 3,
        "25k": 31,
        "27.5k": 13,
        "30k": 6,
        "32.5k": 14,
        "35k": 24,
        "37.5k": 19,
        "40k": 11,
        "42.5k": 15,
        "45k": 21,
        "47.5k": 43,
        "50k": 25,
        "52.5k": 32,
        "55k": 8,
        "57.5k": 30,
        "60k": 30,
        "62.5k": 23,
        "65k": 18,
        "67.5k": 17,
        "70k": 7,
        "72.5k": 18,
        "75k": 6,
        "77.5k": 14,
        "80k": 25,
        "82.5k": 28,
        "85k": 10,
        "87.5k": 27,
        "90k": 3,
        "92.5k": 16,
        "95k": 7,
        "97.5k": 16,
        "100k": 16,
        "200k": 170
      },
      nonRelativem: {
        "2.5k": 12,
        "5k": 7,
        "7.5k": 4,
        "10k": 9,
        "12.5k": 8,
        "15k": 25,
        "17.5k": 34,
        "20k": 28,
        "22.5k": 27,
        "25k": 21,
        "27.5k": 42,
        "30k": 22,
        "32.5k": 44,
        "35k": 33,
        "37.5k": 51,
        "40k": 36,
        "42.5k": 66,
        "45k": 18,
        "47.5k": 41,
        "50k": 30,
        "52.5k": 62,
        "55k": 25,
        "57.5k": 49,
        "60k": 28,
        "62.5k": 43,
        "65k": 14,
        "67.5k": 25,
        "70k": 50,
        "72.5k": 19,
        "75k": 38,
        "77.5k": 43,
        "80k": 28,
        "82.5k": 47,
        "85k": 35,
        "87.5k": 24,
        "90k": 33,
        "92.5k": 35,
        "95k": 25,
        "97.5k": 15,
        "100k": 16,
        "200k": 359
      },
      houseLoitererfm: {
        "2.5k": 162,
        "5k": 78,
        "7.5k": 71,
        "10k": 98,
        "12.5k": 152,
        "15k": 131,
        "17.5k": 159,
        "20k": 109,
        "22.5k": 158,
        "25k": 128,
        "27.5k": 180,
        "30k": 137,
        "32.5k": 173,
        "35k": 118,
        "37.5k": 171,
        "40k": 96,
        "42.5k": 164,
        "45k": 120,
        "47.5k": 132,
        "50k": 116,
        "52.5k": 152,
        "55k": 90,
        "57.5k": 109,
        "60k": 66,
        "62.5k": 116,
        "65k": 62,
        "67.5k": 112,
        "70k": 64,
        "72.5k": 86,
        "75k": 70,
        "77.5k": 90,
        "80k": 58,
        "82.5k": 81,
        "85k": 59,
        "87.5k": 66,
        "90k": 43,
        "92.5k": 78,
        "95k": 28,
        "97.5k": 39,
        "100k": 26,
        "200k": 538
      },
      spousefm: {
        "2.5k": 22,
        "5k": 14,
        "7.5k": 14,
        "10k": 10,
        "12.5k": 26,
        "15k": 21,
        "17.5k": 29,
        "20k": 41,
        "22.5k": 57,
        "25k": 40,
        "27.5k": 61,
        "30k": 43,
        "32.5k": 74,
        "35k": 41,
        "37.5k": 49,
        "40k": 56,
        "42.5k": 86,
        "45k": 58,
        "47.5k": 83,
        "50k": 53,
        "52.5k": 88,
        "55k": 78,
        "57.5k": 116,
        "60k": 30,
        "62.5k": 107,
        "65k": 67,
        "67.5k": 65,
        "70k": 65,
        "72.5k": 94,
        "75k": 56,
        "77.5k": 75,
        "80k": 69,
        "82.5k": 69,
        "85k": 47,
        "87.5k": 59,
        "90k": 23,
        "92.5k": 48,
        "95k": 35,
        "97.5k": 31,
        "100k": 24,
        "200k": 557
      },
      childfm: {
        "2.5k": 11,
        "5k": 5,
        "7.5k": 8,
        "10k": 4,
        "12.5k": 8,
        "15k": 20,
        "17.5k": 18,
        "20k": 11,
        "22.5k": 27,
        "25k": 24,
        "27.5k": 25,
        "30k": 20,
        "32.5k": 20,
        "35k": 10,
        "37.5k": 11,
        "40k": 15,
        "42.5k": 31,
        "45k": 34,
        "47.5k": 31,
        "50k": 33,
        "52.5k": 25,
        "55k": 14,
        "57.5k": 28,
        "60k": 24,
        "62.5k": 35,
        "65k": 23,
        "67.5k": 18,
        "70k": 32,
        "72.5k": 42,
        "75k": 23,
        "77.5k": 35,
        "80k": 46,
        "82.5k": 28,
        "85k": 29,
        "87.5k": 26,
        "90k": 16,
        "92.5k": 30,
        "95k": 4,
        "97.5k": 24,
        "100k": 23,
        "200k": 541
      },
      otherRelativefm: {
        "2.5k": 6,
        "5k": 0,
        "7.5k": 0,
        "10k": 3,
        "12.5k": 5,
        "15k": 6,
        "17.5k": 8,
        "20k": 4,
        "22.5k": 6,
        "25k": 8,
        "27.5k": 14,
        "30k": 11,
        "32.5k": 13,
        "35k": 13,
        "37.5k": 7,
        "40k": 18,
        "42.5k": 15,
        "45k": 7,
        "47.5k": 15,
        "50k": 15,
        "52.5k": 7,
        "55k": 8,
        "57.5k": 8,
        "60k": 8,
        "62.5k": 8,
        "65k": 1,
        "67.5k": 10,
        "70k": 13,
        "72.5k": 8,
        "75k": 4,
        "77.5k": 12,
        "80k": 6,
        "82.5k": 4,
        "85k": 3,
        "87.5k": 6,
        "90k": 5,
        "92.5k": 10,
        "95k": 4,
        "97.5k": 3,
        "100k": 1,
        "200k": 121
      },
      nonRelativefm: {
        "2.5k": 5,
        "5k": 4,
        "7.5k": 2,
        "10k": 6,
        "12.5k": 4,
        "15k": 4,
        "17.5k": 10,
        "20k": 20,
        "22.5k": 24,
        "25k": 10,
        "27.5k": 25,
        "30k": 16,
        "32.5k": 22,
        "35k": 15,
        "37.5k": 26,
        "40k": 18,
        "42.5k": 46,
        "45k": 32,
        "47.5k": 30,
        "50k": 37,
        "52.5k": 24,
        "55k": 34,
        "57.5k": 30,
        "60k": 24,
        "62.5k": 37,
        "65k": 26,
        "67.5k": 36,
        "70k": 25,
        "72.5k": 35,
        "75k": 29,
        "77.5k": 18,
        "80k": 17,
        "82.5k": 11,
        "85k": 17,
        "87.5k": 13,
        "90k": 24,
        "92.5k": 27,
        "95k": 13,
        "97.5k": 18,
        "100k": 12,
        "200k": 282
      }
    },
    "34": {
      houseLoitererm: {
        "2.5k": 60,
        "5k": 19,
        "7.5k": 50,
        "10k": 43,
        "12.5k": 88,
        "15k": 49,
        "17.5k": 80,
        "20k": 86,
        "22.5k": 118,
        "25k": 70,
        "27.5k": 149,
        "30k": 93,
        "32.5k": 181,
        "35k": 102,
        "37.5k": 157,
        "40k": 121,
        "42.5k": 164,
        "45k": 86,
        "47.5k": 166,
        "50k": 98,
        "52.5k": 176,
        "55k": 99,
        "57.5k": 155,
        "60k": 74,
        "62.5k": 187,
        "65k": 93,
        "67.5k": 134,
        "70k": 76,
        "72.5k": 153,
        "75k": 69,
        "77.5k": 112,
        "80k": 74,
        "82.5k": 113,
        "85k": 88,
        "87.5k": 89,
        "90k": 46,
        "92.5k": 106,
        "95k": 53,
        "97.5k": 49,
        "100k": 39,
        "200k": "1,136"
      },
      spousem: {
        "2.5k": 5,
        "5k": 7,
        "7.5k": 8,
        "10k": 7,
        "12.5k": 18,
        "15k": 17,
        "17.5k": 27,
        "20k": 22,
        "22.5k": 45,
        "25k": 24,
        "27.5k": 36,
        "30k": 25,
        "32.5k": 53,
        "35k": 27,
        "37.5k": 47,
        "40k": 42,
        "42.5k": 56,
        "45k": 46,
        "47.5k": 70,
        "50k": 38,
        "52.5k": 70,
        "55k": 33,
        "57.5k": 53,
        "60k": 36,
        "62.5k": 66,
        "65k": 37,
        "67.5k": 52,
        "70k": 39,
        "72.5k": 54,
        "75k": 46,
        "77.5k": 65,
        "80k": 38,
        "82.5k": 51,
        "85k": 35,
        "87.5k": 44,
        "90k": 24,
        "92.5k": 47,
        "95k": 20,
        "97.5k": 33,
        "100k": 38,
        "200k": "479"
      },
      childm: {
        "2.5k": 4,
        "5k": 2,
        "7.5k": 16,
        "10k": 21,
        "12.5k": 14,
        "15k": 3,
        "17.5k": 5,
        "20k": 10,
        "22.5k": 30,
        "25k": 26,
        "27.5k": 14,
        "30k": 16,
        "32.5k": 25,
        "35k": 19,
        "37.5k": 7,
        "40k": 18,
        "42.5k": 33,
        "45k": 10,
        "47.5k": 23,
        "50k": 20,
        "52.5k": 18,
        "55k": 18,
        "57.5k": 33,
        "60k": 12,
        "62.5k": 32,
        "65k": 14,
        "67.5k": 19,
        "70k": 5,
        "72.5k": 22,
        "75k": 10,
        "77.5k": 26,
        "80k": 11,
        "82.5k": 15,
        "85k": 7,
        "87.5k": 24,
        "90k": 14,
        "92.5k": 15,
        "95k": 16,
        "97.5k": 9,
        "100k": 13,
        "200k": "265"
      },
      otherRelativem: {
        "2.5k": 2,
        "5k": 0,
        "7.5k": 5,
        "10k": 4,
        "12.5k": 8,
        "15k": 4,
        "17.5k": 4,
        "20k": 15,
        "22.5k": 0,
        "25k": 7,
        "27.5k": 6,
        "30k": 8,
        "32.5k": 21,
        "35k": 7,
        "37.5k": 9,
        "40k": 8,
        "42.5k": 17,
        "45k": 3,
        "47.5k": 25,
        "50k": 5,
        "52.5k": 9,
        "55k": 11,
        "57.5k": 14,
        "60k": 9,
        "62.5k": 18,
        "65k": 11,
        "67.5k": 16,
        "70k": 20,
        "72.5k": 10,
        "75k": 7,
        "77.5k": 15,
        "80k": 5,
        "82.5k": 11,
        "85k": 4,
        "87.5k": 10,
        "90k": 6,
        "92.5k": 6,
        "95k": 7,
        "97.5k": 0,
        "100k": 5,
        "200k": "142"
      },
      nonRelativem: {
        "2.5k": 18,
        "5k": 2,
        "7.5k": 7,
        "10k": 7,
        "12.5k": 13,
        "15k": 4,
        "17.5k": 20,
        "20k": 9,
        "22.5k": 20,
        "25k": 22,
        "27.5k": 20,
        "30k": 6,
        "32.5k": 25,
        "35k": 11,
        "37.5k": 40,
        "40k": 15,
        "42.5k": 51,
        "45k": 26,
        "47.5k": 17,
        "50k": 20,
        "52.5k": 39,
        "55k": 19,
        "57.5k": 38,
        "60k": 13,
        "62.5k": 44,
        "65k": 11,
        "67.5k": 30,
        "70k": 17,
        "72.5k": 19,
        "75k": 25,
        "77.5k": 18,
        "80k": 8,
        "82.5k": 12,
        "85k": 19,
        "87.5k": 5,
        "90k": 15,
        "92.5k": 14,
        "95k": 20,
        "97.5k": 16,
        "100k": 9,
        "200k": "244"
      },
      houseLoitererfm: {
        "2.5k": 107,
        "5k": 69,
        "7.5k": 97,
        "10k": 66,
        "12.5k": 134,
        "15k": 113,
        "17.5k": 159,
        "20k": 126,
        "22.5k": 195,
        "25k": 142,
        "27.5k": 154,
        "30k": 95,
        "32.5k": 173,
        "35k": 90,
        "37.5k": 144,
        "40k": 119,
        "42.5k": 151,
        "45k": 92,
        "47.5k": 127,
        "50k": 71,
        "52.5k": 147,
        "55k": 75,
        "57.5k": 128,
        "60k": 65,
        "62.5k": 109,
        "65k": 46,
        "67.5k": 98,
        "70k": 54,
        "72.5k": 97,
        "75k": 60,
        "77.5k": 111,
        "80k": 56,
        "82.5k": 85,
        "85k": 37,
        "87.5k": 66,
        "90k": 46,
        "92.5k": 67,
        "95k": 39,
        "97.5k": 55,
        "100k": 38,
        "200k": "819"
      },
      spousefm: {
        "2.5k": 9,
        "5k": 11,
        "7.5k": 15,
        "10k": 15,
        "12.5k": 35,
        "15k": 18,
        "17.5k": 34,
        "20k": 36,
        "22.5k": 57,
        "25k": 28,
        "27.5k": 61,
        "30k": 37,
        "32.5k": 74,
        "35k": 37,
        "37.5k": 76,
        "40k": 49,
        "42.5k": 104,
        "45k": 53,
        "47.5k": 85,
        "50k": 47,
        "52.5k": 111,
        "55k": 53,
        "57.5k": 81,
        "60k": 48,
        "62.5k": 126,
        "65k": 48,
        "67.5k": 85,
        "70k": 58,
        "72.5k": 109,
        "75k": 56,
        "77.5k": 72,
        "80k": 58,
        "82.5k": 90,
        "85k": 67,
        "87.5k": 70,
        "90k": 49,
        "92.5k": 79,
        "95k": 30,
        "97.5k": 62,
        "100k": 41,
        "200k": "1,055"
      },
      childfm: {
        "2.5k": 6,
        "5k": 4,
        "7.5k": 7,
        "10k": 7,
        "12.5k": 7,
        "15k": 14,
        "17.5k": 7,
        "20k": 12,
        "22.5k": 18,
        "25k": 7,
        "27.5k": 15,
        "30k": 8,
        "32.5k": 16,
        "35k": 6,
        "37.5k": 13,
        "40k": 7,
        "42.5k": 11,
        "45k": 6,
        "47.5k": 9,
        "50k": 6,
        "52.5k": 18,
        "55k": 6,
        "57.5k": 14,
        "60k": 18,
        "62.5k": 18,
        "65k": 8,
        "67.5k": 14,
        "70k": 16,
        "72.5k": 16,
        "75k": 15,
        "77.5k": 8,
        "80k": 8,
        "82.5k": 12,
        "85k": 4,
        "87.5k": 15,
        "90k": 10,
        "92.5k": 11,
        "95k": 17,
        "97.5k": 3,
        "100k": 11,
        "200k": "188"
      },
      otherRelativefm: {
        "2.5k": 3,
        "5k": 0,
        "7.5k": 1,
        "10k": 2,
        "12.5k": 5,
        "15k": 1,
        "17.5k": 12,
        "20k": 3,
        "22.5k": 14,
        "25k": 12,
        "27.5k": 2,
        "30k": 3,
        "32.5k": 16,
        "35k": 4,
        "37.5k": 15,
        "40k": 7,
        "42.5k": 2,
        "45k": 5,
        "47.5k": 5,
        "50k": 7,
        "52.5k": 5,
        "55k": 7,
        "57.5k": 5,
        "60k": 1,
        "62.5k": 5,
        "65k": 6,
        "67.5k": 3,
        "70k": 3,
        "72.5k": 0,
        "75k": 3,
        "77.5k": 8,
        "80k": 6,
        "82.5k": 9,
        "85k": 3,
        "87.5k": 2,
        "90k": 3,
        "92.5k": 7,
        "95k": 3,
        "97.5k": 11,
        "100k": 2,
        "200k": "95"
      },
      nonRelativefm: {
        "2.5k": 9,
        "5k": 6,
        "7.5k": 6,
        "10k": 0,
        "12.5k": 7,
        "15k": 13,
        "17.5k": 12,
        "20k": 15,
        "22.5k": 8,
        "25k": 12,
        "27.5k": 9,
        "30k": 12,
        "32.5k": 4,
        "35k": 8,
        "37.5k": 18,
        "40k": 11,
        "42.5k": 24,
        "45k": 7,
        "47.5k": 7,
        "50k": 9,
        "52.5k": 14,
        "55k": 3,
        "57.5k": 23,
        "60k": 11,
        "62.5k": 18,
        "65k": 9,
        "67.5k": 26,
        "70k": 11,
        "72.5k": 18,
        "75k": 12,
        "77.5k": 21,
        "80k": 9,
        "82.5k": 12,
        "85k": 4,
        "87.5k": 14,
        "90k": 9,
        "92.5k": 17,
        "95k": 2,
        "97.5k": 11,
        "100k": 10,
        "200k": "163"
      }
    },
    "44": {
      houseLoitererm: {
        "2.5k": 131,
        "5k": 37,
        "7.5k": 95,
        "10k": 123,
        "12.5k": 157,
        "15k": 159,
        "17.5k": 173,
        "20k": 141,
        "22.5k": 252,
        "25k": 165,
        "27.5k": 266,
        "30k": 130,
        "32.5k": 316,
        "35k": 155,
        "37.5k": 289,
        "40k": 194,
        "42.5k": 317,
        "45k": 172,
        "47.5k": 315,
        "50k": 199,
        "52.5k": 439,
        "55k": 172,
        "57.5k": 336,
        "60k": 175,
        "62.5k": 360,
        "65k": 160,
        "67.5k": 284,
        "70k": 173,
        "72.5k": 313,
        "75k": 136,
        "77.5k": 283,
        "80k": 178,
        "82.5k": 227,
        "85k": 169,
        "87.5k": 217,
        "90k": 173,
        "92.5k": 240,
        "95k": 122,
        "97.5k": 163,
        "100k": 113,
        "200k": "3,418"
      },
      spousem: {
        "2.5k": 25,
        "5k": 10,
        "7.5k": 21,
        "10k": 32,
        "12.5k": 24,
        "15k": 22,
        "17.5k": 53,
        "20k": 43,
        "22.5k": 69,
        "25k": 63,
        "27.5k": 86,
        "30k": 40,
        "32.5k": 95,
        "35k": 63,
        "37.5k": 108,
        "40k": 73,
        "42.5k": 129,
        "45k": 58,
        "47.5k": 101,
        "50k": 84,
        "52.5k": 144,
        "55k": 93,
        "57.5k": 149,
        "60k": 75,
        "62.5k": 164,
        "65k": 66,
        "67.5k": 114,
        "70k": 88,
        "72.5k": 149,
        "75k": 78,
        "77.5k": 122,
        "80k": 87,
        "82.5k": 149,
        "85k": 69,
        "87.5k": 129,
        "90k": 99,
        "92.5k": 95,
        "95k": 81,
        "97.5k": 92,
        "100k": 63,
        "200k": "1,924"
      },
      childm: {
        "2.5k": 13,
        "5k": 5,
        "7.5k": 8,
        "10k": 26,
        "12.5k": 34,
        "15k": 36,
        "17.5k": 24,
        "20k": 20,
        "22.5k": 24,
        "25k": 26,
        "27.5k": 46,
        "30k": 16,
        "32.5k": 55,
        "35k": 32,
        "37.5k": 47,
        "40k": 38,
        "42.5k": 56,
        "45k": 31,
        "47.5k": 25,
        "50k": 45,
        "52.5k": 61,
        "55k": 28,
        "57.5k": 68,
        "60k": 34,
        "62.5k": 33,
        "65k": 44,
        "67.5k": 23,
        "70k": 30,
        "72.5k": 22,
        "75k": 18,
        "77.5k": 20,
        "80k": 19,
        "82.5k": 29,
        "85k": 40,
        "87.5k": 14,
        "90k": 25,
        "92.5k": 15,
        "95k": 14,
        "97.5k": 19,
        "100k": 30,
        "200k": "338"
      },
      otherRelativem: {
        "2.5k": 4,
        "5k": 3,
        "7.5k": 4,
        "10k": 8,
        "12.5k": 11,
        "15k": 13,
        "17.5k": 8,
        "20k": 8,
        "22.5k": 15,
        "25k": 24,
        "27.5k": 24,
        "30k": 22,
        "32.5k": 31,
        "35k": 14,
        "37.5k": 37,
        "40k": 19,
        "42.5k": 29,
        "45k": 20,
        "47.5k": 21,
        "50k": 14,
        "52.5k": 18,
        "55k": 28,
        "57.5k": 32,
        "60k": 27,
        "62.5k": 16,
        "65k": 18,
        "67.5k": 20,
        "70k": 6,
        "72.5k": 23,
        "75k": 18,
        "77.5k": 22,
        "80k": 15,
        "82.5k": 24,
        "85k": 27,
        "87.5k": 15,
        "90k": 16,
        "92.5k": 20,
        "95k": 10,
        "97.5k": 17,
        "100k": 4,
        "200k": "246"
      },
      nonRelativem: {
        "2.5k": 14,
        "5k": 5,
        "7.5k": 18,
        "10k": 10,
        "12.5k": 13,
        "15k": 12,
        "17.5k": 23,
        "20k": 31,
        "22.5k": 14,
        "25k": 36,
        "27.5k": 23,
        "30k": 22,
        "32.5k": 53,
        "35k": 39,
        "37.5k": 54,
        "40k": 39,
        "42.5k": 44,
        "45k": 36,
        "47.5k": 40,
        "50k": 33,
        "52.5k": 44,
        "55k": 33,
        "57.5k": 50,
        "60k": 21,
        "62.5k": 29,
        "65k": 21,
        "67.5k": 44,
        "70k": 21,
        "72.5k": 47,
        "75k": 25,
        "77.5k": 34,
        "80k": 73,
        "82.5k": 24,
        "85k": 26,
        "87.5k": 20,
        "90k": 17,
        "92.5k": 38,
        "95k": 16,
        "97.5k": 30,
        "100k": 14,
        "200k": "338"
      },
      houseLoitererfm: {
        "2.5k": 227,
        "5k": 107,
        "7.5k": 120,
        "10k": 239,
        "12.5k": 241,
        "15k": 202,
        "17.5k": 275,
        "20k": 255,
        "22.5k": 281,
        "25k": 231,
        "27.5k": 305,
        "30k": 227,
        "32.5k": 358,
        "35k": 216,
        "37.5k": 292,
        "40k": 239,
        "42.5k": 323,
        "45k": 188,
        "47.5k": 288,
        "50k": 217,
        "52.5k": 332,
        "55k": 185,
        "57.5k": 220,
        "60k": 182,
        "62.5k": 307,
        "65k": 156,
        "67.5k": 224,
        "70k": 126,
        "72.5k": 238,
        "75k": 162,
        "77.5k": 191,
        "80k": 142,
        "82.5k": 198,
        "85k": 124,
        "87.5k": 154,
        "90k": 117,
        "92.5k": 144,
        "95k": 92,
        "97.5k": 149,
        "100k": 99,
        "200k": "2,436"
      },
      spousefm: {
        "2.5k": 39,
        "5k": 10,
        "7.5k": 24,
        "10k": 26,
        "12.5k": 37,
        "15k": 47,
        "17.5k": 59,
        "20k": 51,
        "22.5k": 114,
        "25k": 70,
        "27.5k": 101,
        "30k": 71,
        "32.5k": 119,
        "35k": 85,
        "37.5k": 146,
        "40k": 109,
        "42.5k": 186,
        "45k": 127,
        "47.5k": 159,
        "50k": 135,
        "52.5k": 241,
        "55k": 113,
        "57.5k": 174,
        "60k": 119,
        "62.5k": 228,
        "65k": 125,
        "67.5k": 199,
        "70k": 131,
        "72.5k": 249,
        "75k": 124,
        "77.5k": 230,
        "80k": 114,
        "82.5k": 189,
        "85k": 147,
        "87.5k": 216,
        "90k": 111,
        "92.5k": 168,
        "95k": 122,
        "97.5k": 132,
        "100k": 100,
        "200k": "3,244"
      },
      childfm: {
        "2.5k": 8,
        "5k": 1,
        "7.5k": 7,
        "10k": 9,
        "12.5k": 14,
        "15k": 13,
        "17.5k": 13,
        "20k": 6,
        "22.5k": 11,
        "25k": 16,
        "27.5k": 23,
        "30k": 6,
        "32.5k": 17,
        "35k": 20,
        "37.5k": 18,
        "40k": 18,
        "42.5k": 11,
        "45k": 13,
        "47.5k": 28,
        "50k": 21,
        "52.5k": 8,
        "55k": 9,
        "57.5k": 19,
        "60k": 14,
        "62.5k": 13,
        "65k": 28,
        "67.5k": 16,
        "70k": 13,
        "72.5k": 18,
        "75k": 21,
        "77.5k": 18,
        "80k": 12,
        "82.5k": 11,
        "85k": 6,
        "87.5k": 10,
        "90k": 10,
        "92.5k": 7,
        "95k": 5,
        "97.5k": 9,
        "100k": 5,
        "200k": "187"
      },
      otherRelativefm: {
        "2.5k": 10,
        "5k": 8,
        "7.5k": 12,
        "10k": 5,
        "12.5k": 15,
        "15k": 18,
        "17.5k": 30,
        "20k": 4,
        "22.5k": 17,
        "25k": 12,
        "27.5k": 25,
        "30k": 8,
        "32.5k": 27,
        "35k": 11,
        "37.5k": 22,
        "40k": 15,
        "42.5k": 21,
        "45k": 16,
        "47.5k": 8,
        "50k": 15,
        "52.5k": 11,
        "55k": 11,
        "57.5k": 9,
        "60k": 21,
        "62.5k": 16,
        "65k": 17,
        "67.5k": 9,
        "70k": 7,
        "72.5k": 18,
        "75k": 10,
        "77.5k": 16,
        "80k": 5,
        "82.5k": 20,
        "85k": 14,
        "87.5k": 8,
        "90k": 8,
        "92.5k": 7,
        "95k": 8,
        "97.5k": 8,
        "100k": 4,
        "200k": "149"
      },
      nonRelativefm: {
        "2.5k": 6,
        "5k": 1,
        "7.5k": 3,
        "10k": 7,
        "12.5k": 7,
        "15k": 10,
        "17.5k": 13,
        "20k": 6,
        "22.5k": 21,
        "25k": 25,
        "27.5k": 10,
        "30k": 12,
        "32.5k": 15,
        "35k": 10,
        "37.5k": 19,
        "40k": 11,
        "42.5k": 22,
        "45k": 4,
        "47.5k": 24,
        "50k": 23,
        "52.5k": 19,
        "55k": 15,
        "57.5k": 23,
        "60k": 20,
        "62.5k": 23,
        "65k": 21,
        "67.5k": 19,
        "70k": 10,
        "72.5k": 20,
        "75k": 21,
        "77.5k": 22,
        "80k": 17,
        "82.5k": 13,
        "85k": 12,
        "87.5k": 18,
        "90k": 17,
        "92.5k": 13,
        "95k": 9,
        "97.5k": 3,
        "100k": 10,
        "200k": "281"
      }
    },
    "54": {
      houseLoitererm: {
        "2.5k": 194,
        "5k": 65,
        "7.5k": 144,
        "10k": 194,
        "12.5k": 179,
        "15k": 133,
        "17.5k": 206,
        "20k": 127,
        "22.5k": 248,
        "25k": 202,
        "27.5k": 224,
        "30k": 161,
        "32.5k": 299,
        "35k": 165,
        "37.5k": 349,
        "40k": 183,
        "42.5k": 384,
        "45k": 197,
        "47.5k": 293,
        "50k": 177,
        "52.5k": 351,
        "55k": 197,
        "57.5k": 285,
        "60k": 204,
        "62.5k": 315,
        "65k": 224,
        "67.5k": 249,
        "70k": 163,
        "72.5k": 305,
        "75k": 209,
        "77.5k": 268,
        "80k": 182,
        "82.5k": 297,
        "85k": 182,
        "87.5k": 287,
        "90k": 163,
        "92.5k": 215,
        "95k": 145,
        "97.5k": 241,
        "100k": 148,
        "200k": "4,285"
      },
      spousem: {
        "2.5k": 28,
        "5k": 7,
        "7.5k": 17,
        "10k": 14,
        "12.5k": 30,
        "15k": 29,
        "17.5k": 44,
        "20k": 41,
        "22.5k": 42,
        "25k": 74,
        "27.5k": 60,
        "30k": 28,
        "32.5k": 97,
        "35k": 57,
        "37.5k": 76,
        "40k": 51,
        "42.5k": 120,
        "45k": 83,
        "47.5k": 94,
        "50k": 84,
        "52.5k": 151,
        "55k": 93,
        "57.5k": 102,
        "60k": 71,
        "62.5k": 146,
        "65k": 97,
        "67.5k": 132,
        "70k": 81,
        "72.5k": 137,
        "75k": 124,
        "77.5k": 128,
        "80k": 70,
        "82.5k": 117,
        "85k": 109,
        "87.5k": 120,
        "90k": 106,
        "92.5k": 104,
        "95k": 87,
        "97.5k": 99,
        "100k": 94,
        "200k": "2,120"
      },
      childm: {
        "2.5k": 9,
        "5k": 1,
        "7.5k": 8,
        "10k": 26,
        "12.5k": 49,
        "15k": 17,
        "17.5k": 53,
        "20k": 30,
        "22.5k": 45,
        "25k": 50,
        "27.5k": 65,
        "30k": 38,
        "32.5k": 23,
        "35k": 40,
        "37.5k": 39,
        "40k": 34,
        "42.5k": 21,
        "45k": 57,
        "47.5k": 32,
        "50k": 46,
        "52.5k": 16,
        "55k": 32,
        "57.5k": 19,
        "60k": 16,
        "62.5k": 22,
        "65k": 31,
        "67.5k": 21,
        "70k": 4,
        "72.5k": 14,
        "75k": 16,
        "77.5k": 5,
        "80k": 11,
        "82.5k": 15,
        "85k": 6,
        "87.5k": 13,
        "90k": 3,
        "92.5k": 17,
        "95k": 11,
        "97.5k": 0,
        "100k": 8,
        "200k": "124"
      },
      otherRelativem: {
        "2.5k": 4,
        "5k": 7,
        "7.5k": 4,
        "10k": 4,
        "12.5k": 23,
        "15k": 12,
        "17.5k": 23,
        "20k": 14,
        "22.5k": 10,
        "25k": 15,
        "27.5k": 12,
        "30k": 2,
        "32.5k": 18,
        "35k": 40,
        "37.5k": 36,
        "40k": 13,
        "42.5k": 27,
        "45k": 26,
        "47.5k": 29,
        "50k": 17,
        "52.5k": 42,
        "55k": 11,
        "57.5k": 21,
        "60k": 23,
        "62.5k": 35,
        "65k": 20,
        "67.5k": 17,
        "70k": 18,
        "72.5k": 25,
        "75k": 11,
        "77.5k": 14,
        "80k": 16,
        "82.5k": 18,
        "85k": 14,
        "87.5k": 10,
        "90k": 5,
        "92.5k": 17,
        "95k": 15,
        "97.5k": 10,
        "100k": 20,
        "200k": "216"
      },
      nonRelativem: {
        "2.5k": 16,
        "5k": 9,
        "7.5k": 8,
        "10k": 16,
        "12.5k": 16,
        "15k": 16,
        "17.5k": 23,
        "20k": 25,
        "22.5k": 36,
        "25k": 37,
        "27.5k": 27,
        "30k": 9,
        "32.5k": 34,
        "35k": 16,
        "37.5k": 13,
        "40k": 20,
        "42.5k": 32,
        "45k": 31,
        "47.5k": 20,
        "50k": 18,
        "52.5k": 14,
        "55k": 6,
        "57.5k": 37,
        "60k": 30,
        "62.5k": 63,
        "65k": 23,
        "67.5k": 37,
        "70k": 30,
        "72.5k": 21,
        "75k": 26,
        "77.5k": 34,
        "80k": 14,
        "82.5k": 15,
        "85k": 11,
        "87.5k": 12,
        "90k": 42,
        "92.5k": 15,
        "95k": 14,
        "97.5k": 16,
        "100k": 15,
        "200k": "226"
      },
      houseLoitererfm: {
        "2.5k": 236,
        "5k": 91,
        "7.5k": 201,
        "10k": 307,
        "12.5k": 238,
        "15k": 219,
        "17.5k": 280,
        "20k": 220,
        "22.5k": 295,
        "25k": 272,
        "27.5k": 331,
        "30k": 176,
        "32.5k": 328,
        "35k": 232,
        "37.5k": 302,
        "40k": 211,
        "42.5k": 334,
        "45k": 233,
        "47.5k": 310,
        "50k": 218,
        "52.5k": 299,
        "55k": 204,
        "57.5k": 287,
        "60k": 166,
        "62.5k": 293,
        "65k": 194,
        "67.5k": 229,
        "70k": 178,
        "72.5k": 219,
        "75k": 158,
        "77.5k": 207,
        "80k": 132,
        "82.5k": 205,
        "85k": 152,
        "87.5k": 194,
        "90k": 136,
        "92.5k": 162,
        "95k": 117,
        "97.5k": 138,
        "100k": 116,
        "200k": "2,674"
      },
      spousefm: {
        "2.5k": 61,
        "5k": 17,
        "7.5k": 18,
        "10k": 31,
        "12.5k": 33,
        "15k": 52,
        "17.5k": 51,
        "20k": 78,
        "22.5k": 105,
        "25k": 70,
        "27.5k": 80,
        "30k": 77,
        "32.5k": 117,
        "35k": 80,
        "37.5k": 138,
        "40k": 81,
        "42.5k": 170,
        "45k": 111,
        "47.5k": 165,
        "50k": 121,
        "52.5k": 190,
        "55k": 135,
        "57.5k": 187,
        "60k": 144,
        "62.5k": 202,
        "65k": 144,
        "67.5k": 177,
        "70k": 137,
        "72.5k": 178,
        "75k": 136,
        "77.5k": 197,
        "80k": 149,
        "82.5k": 202,
        "85k": 142,
        "87.5k": 196,
        "90k": 122,
        "92.5k": 199,
        "95k": 140,
        "97.5k": 177,
        "100k": 139,
        "200k": "3,728"
      },
      childfm: {
        "2.5k": 0,
        "5k": 2,
        "7.5k": 3,
        "10k": 2,
        "12.5k": 3,
        "15k": 14,
        "17.5k": 19,
        "20k": 18,
        "22.5k": 16,
        "25k": 12,
        "27.5k": 15,
        "30k": 15,
        "32.5k": 29,
        "35k": 38,
        "37.5k": 16,
        "40k": 7,
        "42.5k": 9,
        "45k": 18,
        "47.5k": 15,
        "50k": 18,
        "52.5k": 12,
        "55k": 9,
        "57.5k": 13,
        "60k": 7,
        "62.5k": 6,
        "65k": 4,
        "67.5k": 2,
        "70k": 5,
        "72.5k": 0,
        "75k": 3,
        "77.5k": 8,
        "80k": 10,
        "82.5k": 6,
        "85k": 10,
        "87.5k": 16,
        "90k": 8,
        "92.5k": 4,
        "95k": 4,
        "97.5k": 2,
        "100k": 8,
        "200k": "56"
      },
      otherRelativefm: {
        "2.5k": 12,
        "5k": 8,
        "7.5k": 8,
        "10k": 7,
        "12.5k": 20,
        "15k": 17,
        "17.5k": 16,
        "20k": 18,
        "22.5k": 26,
        "25k": 12,
        "27.5k": 19,
        "30k": 10,
        "32.5k": 29,
        "35k": 27,
        "37.5k": 36,
        "40k": 17,
        "42.5k": 23,
        "45k": 22,
        "47.5k": 21,
        "50k": 24,
        "52.5k": 34,
        "55k": 23,
        "57.5k": 19,
        "60k": 20,
        "62.5k": 24,
        "65k": 9,
        "67.5k": 32,
        "70k": 21,
        "72.5k": 11,
        "75k": 19,
        "77.5k": 17,
        "80k": 24,
        "82.5k": 31,
        "85k": 6,
        "87.5k": 34,
        "90k": 4,
        "92.5k": 9,
        "95k": 9,
        "97.5k": 15,
        "100k": 9,
        "200k": "242"
      },
      nonRelativefm: {
        "2.5k": 8,
        "5k": 4,
        "7.5k": 10,
        "10k": 5,
        "12.5k": 8,
        "15k": 11,
        "17.5k": 18,
        "20k": 9,
        "22.5k": 16,
        "25k": 9,
        "27.5k": 21,
        "30k": 8,
        "32.5k": 24,
        "35k": 13,
        "37.5k": 27,
        "40k": 5,
        "42.5k": 26,
        "45k": 19,
        "47.5k": 11,
        "50k": 18,
        "52.5k": 23,
        "55k": 11,
        "57.5k": 22,
        "60k": 28,
        "62.5k": 27,
        "65k": 10,
        "67.5k": 9,
        "70k": 17,
        "72.5k": 11,
        "75k": 13,
        "77.5k": 12,
        "80k": 7,
        "82.5k": 23,
        "85k": 5,
        "87.5k": 10,
        "90k": 19,
        "92.5k": 12,
        "95k": 8,
        "97.5k": 19,
        "100k": 6,
        "200k": "209"
      }
    },
    "64": {
      houseLoitererm: {
        "2.5k": 248,
        "5k": 62,
        "7.5k": 104,
        "10k": 193,
        "12.5k": 215,
        "15k": 198,
        "17.5k": 244,
        "20k": 147,
        "22.5k": 222,
        "25k": 184,
        "27.5k": 232,
        "30k": 151,
        "32.5k": 272,
        "35k": 142,
        "37.5k": 279,
        "40k": 208,
        "42.5k": 280,
        "45k": 167,
        "47.5k": 238,
        "50k": 161,
        "52.5k": 276,
        "55k": 161,
        "57.5k": 258,
        "60k": 182,
        "62.5k": 275,
        "65k": 191,
        "67.5k": 199,
        "70k": 159,
        "72.5k": 227,
        "75k": 165,
        "77.5k": 203,
        "80k": 129,
        "82.5k": 202,
        "85k": 115,
        "87.5k": 124,
        "90k": 136,
        "92.5k": 206,
        "95k": 116,
        "97.5k": 126,
        "100k": 141,
        "200k": "3,370"
      },
      spousem: {
        "2.5k": 28,
        "5k": 7,
        "7.5k": 21,
        "10k": 26,
        "12.5k": 35,
        "15k": 30,
        "17.5k": 36,
        "20k": 42,
        "22.5k": 73,
        "25k": 60,
        "27.5k": 59,
        "30k": 59,
        "32.5k": 92,
        "35k": 55,
        "37.5k": 97,
        "40k": 52,
        "42.5k": 104,
        "45k": 63,
        "47.5k": 82,
        "50k": 62,
        "52.5k": 110,
        "55k": 60,
        "57.5k": 77,
        "60k": 72,
        "62.5k": 87,
        "65k": 84,
        "67.5k": 68,
        "70k": 72,
        "72.5k": 84,
        "75k": 71,
        "77.5k": 106,
        "80k": 61,
        "82.5k": 97,
        "85k": 58,
        "87.5k": 94,
        "90k": 49,
        "92.5k": 73,
        "95k": 65,
        "97.5k": 53,
        "100k": 40,
        "200k": "1,360"
      },
      childm: {
        "2.5k": 2,
        "5k": 0,
        "7.5k": 3,
        "10k": 5,
        "12.5k": 2,
        "15k": 9,
        "17.5k": 18,
        "20k": 9,
        "22.5k": 8,
        "25k": 13,
        "27.5k": 14,
        "30k": 8,
        "32.5k": 9,
        "35k": 9,
        "37.5k": 8,
        "40k": 5,
        "42.5k": 4,
        "45k": 6,
        "47.5k": 19,
        "50k": 8,
        "52.5k": 1,
        "55k": 1,
        "57.5k": 3,
        "60k": 3,
        "62.5k": 4,
        "65k": 2,
        "67.5k": 5,
        "70k": 1,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 8,
        "80k": 0,
        "82.5k": 6,
        "85k": 2,
        "87.5k": 3,
        "90k": 2,
        "92.5k": 2,
        "95k": 0,
        "97.5k": 1,
        "100k": 0,
        "200k": "15"
      },
      otherRelativem: {
        "2.5k": 7,
        "5k": 0,
        "7.5k": 2,
        "10k": 10,
        "12.5k": 15,
        "15k": 3,
        "17.5k": 11,
        "20k": 16,
        "22.5k": 24,
        "25k": 4,
        "27.5k": 13,
        "30k": 7,
        "32.5k": 17,
        "35k": 12,
        "37.5k": 26,
        "40k": 4,
        "42.5k": 13,
        "45k": 6,
        "47.5k": 4,
        "50k": 11,
        "52.5k": 16,
        "55k": 12,
        "57.5k": 14,
        "60k": 13,
        "62.5k": 16,
        "65k": 15,
        "67.5k": 10,
        "70k": 3,
        "72.5k": 5,
        "75k": 9,
        "77.5k": 9,
        "80k": 11,
        "82.5k": 18,
        "85k": 5,
        "87.5k": 16,
        "90k": 10,
        "92.5k": 16,
        "95k": 3,
        "97.5k": 6,
        "100k": 4,
        "200k": "179"
      },
      nonRelativem: {
        "2.5k": 3,
        "5k": 2,
        "7.5k": 3,
        "10k": 7,
        "12.5k": 2,
        "15k": 6,
        "17.5k": 13,
        "20k": 5,
        "22.5k": 10,
        "25k": 9,
        "27.5k": 6,
        "30k": 10,
        "32.5k": 18,
        "35k": 11,
        "37.5k": 13,
        "40k": 11,
        "42.5k": 12,
        "45k": 8,
        "47.5k": 12,
        "50k": 5,
        "52.5k": 3,
        "55k": 7,
        "57.5k": 9,
        "60k": 21,
        "62.5k": 14,
        "65k": 2,
        "67.5k": 16,
        "70k": 13,
        "72.5k": 4,
        "75k": 1,
        "77.5k": 5,
        "80k": 12,
        "82.5k": 6,
        "85k": 0,
        "87.5k": 0,
        "90k": 3,
        "92.5k": 22,
        "95k": 5,
        "97.5k": 6,
        "100k": 3,
        "200k": "107"
      },
      houseLoitererfm: {
        "2.5k": 245,
        "5k": 80,
        "7.5k": 178,
        "10k": 373,
        "12.5k": 269,
        "15k": 268,
        "17.5k": 284,
        "20k": 223,
        "22.5k": 320,
        "25k": 236,
        "27.5k": 227,
        "30k": 220,
        "32.5k": 305,
        "35k": 187,
        "37.5k": 258,
        "40k": 198,
        "42.5k": 300,
        "45k": 186,
        "47.5k": 208,
        "50k": 195,
        "52.5k": 281,
        "55k": 143,
        "57.5k": 148,
        "60k": 182,
        "62.5k": 219,
        "65k": 117,
        "67.5k": 164,
        "70k": 105,
        "72.5k": 154,
        "75k": 136,
        "77.5k": 134,
        "80k": 86,
        "82.5k": 146,
        "85k": 74,
        "87.5k": 95,
        "90k": 85,
        "92.5k": 104,
        "95k": 91,
        "97.5k": 65,
        "100k": 69,
        "200k": "1,647"
      },
      spousefm: {
        "2.5k": 67,
        "5k": 15,
        "7.5k": 28,
        "10k": 37,
        "12.5k": 42,
        "15k": 70,
        "17.5k": 105,
        "20k": 81,
        "22.5k": 119,
        "25k": 113,
        "27.5k": 141,
        "30k": 118,
        "32.5k": 132,
        "35k": 88,
        "37.5k": 147,
        "40k": 146,
        "42.5k": 145,
        "45k": 139,
        "47.5k": 131,
        "50k": 103,
        "52.5k": 172,
        "55k": 101,
        "57.5k": 164,
        "60k": 117,
        "62.5k": 166,
        "65k": 125,
        "67.5k": 140,
        "70k": 119,
        "72.5k": 172,
        "75k": 119,
        "77.5k": 130,
        "80k": 113,
        "82.5k": 131,
        "85k": 100,
        "87.5k": 118,
        "90k": 112,
        "92.5k": 136,
        "95k": 88,
        "97.5k": 89,
        "100k": 99,
        "200k": "2,376"
      },
      childfm: {
        "2.5k": 0,
        "5k": 3,
        "7.5k": 5,
        "10k": 0,
        "12.5k": 1,
        "15k": 7,
        "17.5k": 10,
        "20k": 2,
        "22.5k": 3,
        "25k": 12,
        "27.5k": 11,
        "30k": 4,
        "32.5k": 8,
        "35k": 11,
        "37.5k": 4,
        "40k": 3,
        "42.5k": 10,
        "45k": 1,
        "47.5k": 9,
        "50k": 3,
        "52.5k": 2,
        "55k": 0,
        "57.5k": 5,
        "60k": 0,
        "62.5k": 0,
        "65k": 3,
        "67.5k": 4,
        "70k": 9,
        "72.5k": 1,
        "75k": 0,
        "77.5k": 1,
        "80k": 3,
        "82.5k": 2,
        "85k": 3,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 4,
        "95k": 1,
        "97.5k": 8,
        "100k": 0,
        "200k": "27"
      },
      otherRelativefm: {
        "2.5k": 4,
        "5k": 2,
        "7.5k": 9,
        "10k": 12,
        "12.5k": 15,
        "15k": 3,
        "17.5k": 15,
        "20k": 10,
        "22.5k": 23,
        "25k": 12,
        "27.5k": 18,
        "30k": 12,
        "32.5k": 17,
        "35k": 13,
        "37.5k": 41,
        "40k": 22,
        "42.5k": 24,
        "45k": 9,
        "47.5k": 19,
        "50k": 8,
        "52.5k": 34,
        "55k": 20,
        "57.5k": 27,
        "60k": 27,
        "62.5k": 23,
        "65k": 16,
        "67.5k": 23,
        "70k": 15,
        "72.5k": 26,
        "75k": 7,
        "77.5k": 17,
        "80k": 14,
        "82.5k": 22,
        "85k": 9,
        "87.5k": 17,
        "90k": 9,
        "92.5k": 16,
        "95k": 10,
        "97.5k": 12,
        "100k": 14,
        "200k": "233"
      },
      nonRelativefm: {
        "2.5k": 6,
        "5k": 2,
        "7.5k": 1,
        "10k": 0,
        "12.5k": 2,
        "15k": 4,
        "17.5k": 11,
        "20k": 3,
        "22.5k": 9,
        "25k": 7,
        "27.5k": 0,
        "30k": 2,
        "32.5k": 8,
        "35k": 9,
        "37.5k": 5,
        "40k": 8,
        "42.5k": 6,
        "45k": 2,
        "47.5k": 11,
        "50k": 3,
        "52.5k": 7,
        "55k": 10,
        "57.5k": 2,
        "60k": 7,
        "62.5k": 4,
        "65k": 12,
        "67.5k": 6,
        "70k": 5,
        "72.5k": 8,
        "75k": 2,
        "77.5k": 5,
        "80k": 2,
        "82.5k": 8,
        "85k": 4,
        "87.5k": 5,
        "90k": 2,
        "92.5k": 4,
        "95k": 4,
        "97.5k": 12,
        "100k": 3,
        "200k": "89"
      }
    },
    "74": {
      houseLoitererm: {
        "2.5k": 85,
        "5k": 25,
        "7.5k": 67,
        "10k": 121,
        "12.5k": 162,
        "15k": 233,
        "17.5k": 228,
        "20k": 228,
        "22.5k": 200,
        "25k": 200,
        "27.5k": 225,
        "30k": 245,
        "32.5k": 218,
        "35k": 180,
        "37.5k": 214,
        "40k": 155,
        "42.5k": 213,
        "45k": 182,
        "47.5k": 134,
        "50k": 127,
        "52.5k": 148,
        "55k": 116,
        "57.5k": 135,
        "60k": 112,
        "62.5k": 104,
        "65k": 100,
        "67.5k": 88,
        "70k": 80,
        "72.5k": 88,
        "75k": 88,
        "77.5k": 83,
        "80k": 52,
        "82.5k": 49,
        "85k": 70,
        "87.5k": 71,
        "90k": 60,
        "92.5k": 70,
        "95k": 71,
        "97.5k": 32,
        "100k": 49,
        "200k": "1,078"
      },
      spousem: {
        "2.5k": 20,
        "5k": 6,
        "7.5k": 8,
        "10k": 31,
        "12.5k": 20,
        "15k": 52,
        "17.5k": 50,
        "20k": 68,
        "22.5k": 55,
        "25k": 76,
        "27.5k": 114,
        "30k": 72,
        "32.5k": 59,
        "35k": 61,
        "37.5k": 66,
        "40k": 79,
        "42.5k": 62,
        "45k": 59,
        "47.5k": 60,
        "50k": 54,
        "52.5k": 46,
        "55k": 51,
        "57.5k": 55,
        "60k": 61,
        "62.5k": 48,
        "65k": 25,
        "67.5k": 38,
        "70k": 26,
        "72.5k": 37,
        "75k": 39,
        "77.5k": 30,
        "80k": 20,
        "82.5k": 30,
        "85k": 14,
        "87.5k": 28,
        "90k": 21,
        "92.5k": 21,
        "95k": 40,
        "97.5k": 34,
        "100k": 25,
        "200k": "467"
      },
      childm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 2,
        "12.5k": 0,
        "15k": 3,
        "17.5k": 4,
        "20k": 0,
        "22.5k": 0,
        "25k": 2,
        "27.5k": 0,
        "30k": 1,
        "32.5k": 0,
        "35k": 3,
        "37.5k": 1,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 2,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 2,
        "200k": "0"
      },
      otherRelativem: {
        "2.5k": 8,
        "5k": 2,
        "7.5k": 4,
        "10k": 6,
        "12.5k": 2,
        "15k": 4,
        "17.5k": 0,
        "20k": 3,
        "22.5k": 5,
        "25k": 5,
        "27.5k": 9,
        "30k": 13,
        "32.5k": 19,
        "35k": 13,
        "37.5k": 9,
        "40k": 1,
        "42.5k": 8,
        "45k": 3,
        "47.5k": 14,
        "50k": 2,
        "52.5k": 13,
        "55k": 7,
        "57.5k": 9,
        "60k": 5,
        "62.5k": 8,
        "65k": 6,
        "67.5k": 6,
        "70k": 4,
        "72.5k": 7,
        "75k": 3,
        "77.5k": 11,
        "80k": 5,
        "82.5k": 10,
        "85k": 2,
        "87.5k": 6,
        "90k": 0,
        "92.5k": 6,
        "95k": 10,
        "97.5k": 3,
        "100k": 3,
        "200k": "123"
      },
      nonRelativem: {
        "2.5k": 3,
        "5k": 1,
        "7.5k": 0,
        "10k": 2,
        "12.5k": 10,
        "15k": 0,
        "17.5k": 3,
        "20k": 4,
        "22.5k": 3,
        "25k": 10,
        "27.5k": 2,
        "30k": 6,
        "32.5k": 3,
        "35k": 1,
        "37.5k": 3,
        "40k": 10,
        "42.5k": 2,
        "45k": 4,
        "47.5k": 6,
        "50k": 2,
        "52.5k": 6,
        "55k": 3,
        "57.5k": 3,
        "60k": 5,
        "62.5k": 8,
        "65k": 2,
        "67.5k": 14,
        "70k": 4,
        "72.5k": 2,
        "75k": 1,
        "77.5k": 4,
        "80k": 1,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 5,
        "95k": 1,
        "97.5k": 1,
        "100k": 7,
        "200k": "19"
      },
      houseLoitererfm: {
        "2.5k": 117,
        "5k": 54,
        "7.5k": 173,
        "10k": 436,
        "12.5k": 383,
        "15k": 430,
        "17.5k": 346,
        "20k": 227,
        "22.5k": 289,
        "25k": 300,
        "27.5k": 253,
        "30k": 202,
        "32.5k": 184,
        "35k": 173,
        "37.5k": 167,
        "40k": 157,
        "42.5k": 181,
        "45k": 135,
        "47.5k": 153,
        "50k": 94,
        "52.5k": 109,
        "55k": 103,
        "57.5k": 90,
        "60k": 90,
        "62.5k": 78,
        "65k": 72,
        "67.5k": 75,
        "70k": 40,
        "72.5k": 65,
        "75k": 38,
        "77.5k": 68,
        "80k": 55,
        "82.5k": 42,
        "85k": 39,
        "87.5k": 36,
        "90k": 25,
        "92.5k": 29,
        "95k": 26,
        "97.5k": 39,
        "100k": 36,
        "200k": "492"
      },
      spousefm: {
        "2.5k": 34,
        "5k": 4,
        "7.5k": 13,
        "10k": 29,
        "12.5k": 47,
        "15k": 63,
        "17.5k": 95,
        "20k": 129,
        "22.5k": 137,
        "25k": 138,
        "27.5k": 146,
        "30k": 152,
        "32.5k": 148,
        "35k": 126,
        "37.5k": 160,
        "40k": 108,
        "42.5k": 138,
        "45k": 109,
        "47.5k": 97,
        "50k": 78,
        "52.5k": 94,
        "55k": 78,
        "57.5k": 89,
        "60k": 63,
        "62.5k": 71,
        "65k": 77,
        "67.5k": 39,
        "70k": 51,
        "72.5k": 52,
        "75k": 53,
        "77.5k": 52,
        "80k": 30,
        "82.5k": 27,
        "85k": 38,
        "87.5k": 34,
        "90k": 35,
        "92.5k": 42,
        "95k": 39,
        "97.5k": 21,
        "100k": 29,
        "200k": "645"
      },
      childfm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 0,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 0,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 3,
        "35k": 4,
        "37.5k": 0,
        "40k": 2,
        "42.5k": 2,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 1,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 1,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "3"
      },
      otherRelativefm: {
        "2.5k": 7,
        "5k": 0,
        "7.5k": 3,
        "10k": 9,
        "12.5k": 18,
        "15k": 27,
        "17.5k": 18,
        "20k": 19,
        "22.5k": 13,
        "25k": 15,
        "27.5k": 7,
        "30k": 30,
        "32.5k": 11,
        "35k": 13,
        "37.5k": 9,
        "40k": 14,
        "42.5k": 23,
        "45k": 6,
        "47.5k": 17,
        "50k": 8,
        "52.5k": 14,
        "55k": 14,
        "57.5k": 16,
        "60k": 22,
        "62.5k": 21,
        "65k": 18,
        "67.5k": 12,
        "70k": 11,
        "72.5k": 7,
        "75k": 14,
        "77.5k": 13,
        "80k": 10,
        "82.5k": 14,
        "85k": 8,
        "87.5k": 8,
        "90k": 9,
        "92.5k": 21,
        "95k": 9,
        "97.5k": 10,
        "100k": 6,
        "200k": "233"
      },
      nonRelativefm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 2,
        "12.5k": 0,
        "15k": 3,
        "17.5k": 0,
        "20k": 2,
        "22.5k": 1,
        "25k": 10,
        "27.5k": 9,
        "30k": 5,
        "32.5k": 4,
        "35k": 1,
        "37.5k": 10,
        "40k": 2,
        "42.5k": 3,
        "45k": 0,
        "47.5k": 6,
        "50k": 3,
        "52.5k": 1,
        "55k": 2,
        "57.5k": 0,
        "60k": 1,
        "62.5k": 2,
        "65k": 1,
        "67.5k": 4,
        "70k": 8,
        "72.5k": 1,
        "75k": 3,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 1,
        "92.5k": 0,
        "95k": 3,
        "97.5k": 0,
        "100k": 4,
        "200k": "24"
      }
    }
  },
  "2008": {
    "17": {
      houseLoitererm: {
        "2.5k": 6,
        "5k": 0,
        "7.5k": 0,
        "10k": 5,
        "12.5k": 2,
        "15k": 3,
        "17.5k": 5,
        "20k": 0,
        "22.5k": 0,
        "25k": 3,
        "27.5k": 5,
        "30k": 1,
        "32.5k": 6,
        "35k": 3,
        "37.5k": 6,
        "40k": 0,
        "42.5k": 6,
        "45k": 4,
        "47.5k": 2,
        "50k": 3,
        "52.5k": 3,
        "55k": 2,
        "57.5k": 2,
        "60k": 1,
        "62.5k": 3,
        "65k": 0,
        "67.5k": 0,
        "70k": 2,
        "72.5k": 2,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 1,
        "87.5k": 1,
        "90k": 0,
        "92.5k": 1,
        "95k": 0,
        "97.5k": 3,
        "100k": 0,
        "200k": "8"
      },
      spousem: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 0,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 0,
        "25k": 0,
        "27.5k": 1,
        "30k": 0,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      childm: {
        "2.5k": 181,
        "5k": 77,
        "7.5k": 101,
        "10k": 157,
        "12.5k": 158,
        "15k": 184,
        "17.5k": 204,
        "20k": 224,
        "22.5k": 302,
        "25k": 177,
        "27.5k": 231,
        "30k": 197,
        "32.5k": 284,
        "35k": 206,
        "37.5k": 300,
        "40k": 194,
        "42.5k": 283,
        "45k": 221,
        "47.5k": 257,
        "50k": 203,
        "52.5k": 323,
        "55k": 183,
        "57.5k": 232,
        "60k": 186,
        "62.5k": 296,
        "65k": 174,
        "67.5k": 265,
        "70k": 194,
        "72.5k": 257,
        "75k": 185,
        "77.5k": 249,
        "80k": 165,
        "82.5k": 235,
        "85k": 142,
        "87.5k": 189,
        "90k": 183,
        "92.5k": 230,
        "95k": 144,
        "97.5k": 183,
        "100k": 131,
        "200k": "3,230"
      },
      otherRelativem: {
        "2.5k": 36,
        "5k": 14,
        "7.5k": 19,
        "10k": 22,
        "12.5k": 40,
        "15k": 19,
        "17.5k": 22,
        "20k": 34,
        "22.5k": 25,
        "25k": 25,
        "27.5k": 29,
        "30k": 36,
        "32.5k": 40,
        "35k": 21,
        "37.5k": 29,
        "40k": 28,
        "42.5k": 29,
        "45k": 22,
        "47.5k": 17,
        "50k": 13,
        "52.5k": 34,
        "55k": 16,
        "57.5k": 26,
        "60k": 15,
        "62.5k": 26,
        "65k": 17,
        "67.5k": 18,
        "70k": 21,
        "72.5k": 9,
        "75k": 5,
        "77.5k": 12,
        "80k": 16,
        "82.5k": 15,
        "85k": 14,
        "87.5k": 17,
        "90k": 8,
        "92.5k": 19,
        "95k": 12,
        "97.5k": 9,
        "100k": 18,
        "200k": "138"
      },
      nonRelativem: {
        "2.5k": 3,
        "5k": 1,
        "7.5k": 1,
        "10k": 1,
        "12.5k": 0,
        "15k": 5,
        "17.5k": 5,
        "20k": 5,
        "22.5k": 5,
        "25k": 3,
        "27.5k": 7,
        "30k": 4,
        "32.5k": 15,
        "35k": 10,
        "37.5k": 9,
        "40k": 11,
        "42.5k": 2,
        "45k": 10,
        "47.5k": 8,
        "50k": 5,
        "52.5k": 10,
        "55k": 3,
        "57.5k": 5,
        "60k": 5,
        "62.5k": 5,
        "65k": 6,
        "67.5k": 6,
        "70k": 11,
        "72.5k": 9,
        "75k": 3,
        "77.5k": 5,
        "80k": 8,
        "82.5k": 4,
        "85k": 1,
        "87.5k": 4,
        "90k": 6,
        "92.5k": 4,
        "95k": 7,
        "97.5k": 2,
        "100k": 3,
        "200k": "56"
      },
      houseLoitererfm: {
        "2.5k": 9,
        "5k": 3,
        "7.5k": 0,
        "10k": 3,
        "12.5k": 3,
        "15k": 0,
        "17.5k": 12,
        "20k": 4,
        "22.5k": 6,
        "25k": 5,
        "27.5k": 2,
        "30k": 5,
        "32.5k": 5,
        "35k": 4,
        "37.5k": 2,
        "40k": 0,
        "42.5k": 0,
        "45k": 3,
        "47.5k": 1,
        "50k": 2,
        "52.5k": 3,
        "55k": 1,
        "57.5k": 5,
        "60k": 1,
        "62.5k": 1,
        "65k": 1,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 2,
        "75k": 1,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 2,
        "85k": 1,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 2,
        "100k": 0,
        "200k": "6"
      },
      spousefm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 0,
        "15k": 0,
        "17.5k": 3,
        "20k": 0,
        "22.5k": 0,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 0,
        "45k": 0,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      childfm: {
        "2.5k": 170,
        "5k": 62,
        "7.5k": 108,
        "10k": 138,
        "12.5k": 181,
        "15k": 175,
        "17.5k": 189,
        "20k": 237,
        "22.5k": 313,
        "25k": 209,
        "27.5k": 275,
        "30k": 178,
        "32.5k": 247,
        "35k": 179,
        "37.5k": 272,
        "40k": 210,
        "42.5k": 288,
        "45k": 191,
        "47.5k": 235,
        "50k": 205,
        "52.5k": 352,
        "55k": 177,
        "57.5k": 207,
        "60k": 178,
        "62.5k": 270,
        "65k": 154,
        "67.5k": 226,
        "70k": 188,
        "72.5k": 221,
        "75k": 160,
        "77.5k": 237,
        "80k": 144,
        "82.5k": 201,
        "85k": 133,
        "87.5k": 187,
        "90k": 156,
        "92.5k": 182,
        "95k": 144,
        "97.5k": 157,
        "100k": 127,
        "200k": "3,165"
      },
      otherRelativefm: {
        "2.5k": 27,
        "5k": 7,
        "7.5k": 18,
        "10k": 17,
        "12.5k": 44,
        "15k": 26,
        "17.5k": 26,
        "20k": 34,
        "22.5k": 27,
        "25k": 17,
        "27.5k": 41,
        "30k": 29,
        "32.5k": 47,
        "35k": 31,
        "37.5k": 45,
        "40k": 31,
        "42.5k": 29,
        "45k": 21,
        "47.5k": 25,
        "50k": 20,
        "52.5k": 20,
        "55k": 15,
        "57.5k": 14,
        "60k": 15,
        "62.5k": 24,
        "65k": 12,
        "67.5k": 23,
        "70k": 6,
        "72.5k": 20,
        "75k": 22,
        "77.5k": 14,
        "80k": 7,
        "82.5k": 7,
        "85k": 14,
        "87.5k": 17,
        "90k": 13,
        "92.5k": 7,
        "95k": 10,
        "97.5k": 5,
        "100k": 7,
        "200k": "124"
      },
      nonRelativefm: {
        "2.5k": 6,
        "5k": 2,
        "7.5k": 9,
        "10k": 9,
        "12.5k": 1,
        "15k": 3,
        "17.5k": 4,
        "20k": 6,
        "22.5k": 4,
        "25k": 3,
        "27.5k": 7,
        "30k": 2,
        "32.5k": 13,
        "35k": 7,
        "37.5k": 10,
        "40k": 5,
        "42.5k": 4,
        "45k": 13,
        "47.5k": 8,
        "50k": 8,
        "52.5k": 9,
        "55k": 6,
        "57.5k": 6,
        "60k": 3,
        "62.5k": 6,
        "65k": 7,
        "67.5k": 4,
        "70k": 10,
        "72.5k": 7,
        "75k": 5,
        "77.5k": 6,
        "80k": 4,
        "82.5k": 2,
        "85k": 8,
        "87.5k": 3,
        "90k": 4,
        "92.5k": 3,
        "95k": 6,
        "97.5k": 2,
        "100k": 2,
        "200k": "58"
      }
    },
    "21": {
      houseLoitererm: {
        "2.5k": 74,
        "5k": 28,
        "7.5k": 19,
        "10k": 33,
        "12.5k": 52,
        "15k": 41,
        "17.5k": 42,
        "20k": 32,
        "22.5k": 42,
        "25k": 48,
        "27.5k": 48,
        "30k": 31,
        "32.5k": 39,
        "35k": 37,
        "37.5k": 46,
        "40k": 34,
        "42.5k": 33,
        "45k": 25,
        "47.5k": 17,
        "50k": 17,
        "52.5k": 17,
        "55k": 10,
        "57.5k": 6,
        "60k": 15,
        "62.5k": 20,
        "65k": 6,
        "67.5k": 7,
        "70k": 13,
        "72.5k": 11,
        "75k": 6,
        "77.5k": 11,
        "80k": 12,
        "82.5k": 7,
        "85k": 15,
        "87.5k": 5,
        "90k": 7,
        "92.5k": 8,
        "95k": 4,
        "97.5k": 4,
        "100k": 2,
        "200k": "56"
      },
      spousem: {
        "2.5k": 6,
        "5k": 0,
        "7.5k": 0,
        "10k": 1,
        "12.5k": 2,
        "15k": 0,
        "17.5k": 2,
        "20k": 0,
        "22.5k": 0,
        "25k": 3,
        "27.5k": 3,
        "30k": 1,
        "32.5k": 4,
        "35k": 6,
        "37.5k": 4,
        "40k": 1,
        "42.5k": 5,
        "45k": 0,
        "47.5k": 1,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 3,
        "60k": 3,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 2,
        "72.5k": 1,
        "75k": 2,
        "77.5k": 0,
        "80k": 1,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "2"
      },
      childm: {
        "2.5k": 73,
        "5k": 11,
        "7.5k": 33,
        "10k": 59,
        "12.5k": 44,
        "15k": 67,
        "17.5k": 93,
        "20k": 78,
        "22.5k": 118,
        "25k": 72,
        "27.5k": 110,
        "30k": 65,
        "32.5k": 119,
        "35k": 107,
        "37.5k": 99,
        "40k": 111,
        "42.5k": 136,
        "45k": 90,
        "47.5k": 121,
        "50k": 75,
        "52.5k": 112,
        "55k": 78,
        "57.5k": 124,
        "60k": 95,
        "62.5k": 174,
        "65k": 92,
        "67.5k": 93,
        "70k": 94,
        "72.5k": 112,
        "75k": 103,
        "77.5k": 75,
        "80k": 108,
        "82.5k": 95,
        "85k": 78,
        "87.5k": 111,
        "90k": 81,
        "92.5k": 104,
        "95k": 93,
        "97.5k": 100,
        "100k": 83,
        "200k": "2,057"
      },
      otherRelativem: {
        "2.5k": 8,
        "5k": 2,
        "7.5k": 15,
        "10k": 19,
        "12.5k": 14,
        "15k": 23,
        "17.5k": 18,
        "20k": 15,
        "22.5k": 21,
        "25k": 13,
        "27.5k": 36,
        "30k": 21,
        "32.5k": 31,
        "35k": 21,
        "37.5k": 18,
        "40k": 33,
        "42.5k": 26,
        "45k": 20,
        "47.5k": 18,
        "50k": 16,
        "52.5k": 38,
        "55k": 4,
        "57.5k": 28,
        "60k": 12,
        "62.5k": 10,
        "65k": 17,
        "67.5k": 12,
        "70k": 9,
        "72.5k": 6,
        "75k": 10,
        "77.5k": 10,
        "80k": 17,
        "82.5k": 14,
        "85k": 9,
        "87.5k": 20,
        "90k": 8,
        "92.5k": 12,
        "95k": 6,
        "97.5k": 4,
        "100k": 9,
        "200k": "127"
      },
      nonRelativem: {
        "2.5k": 51,
        "5k": 6,
        "7.5k": 4,
        "10k": 11,
        "12.5k": 17,
        "15k": 20,
        "17.5k": 3,
        "20k": 14,
        "22.5k": 24,
        "25k": 17,
        "27.5k": 48,
        "30k": 21,
        "32.5k": 29,
        "35k": 20,
        "37.5k": 16,
        "40k": 27,
        "42.5k": 32,
        "45k": 16,
        "47.5k": 10,
        "50k": 17,
        "52.5k": 27,
        "55k": 7,
        "57.5k": 12,
        "60k": 9,
        "62.5k": 12,
        "65k": 11,
        "67.5k": 23,
        "70k": 9,
        "72.5k": 14,
        "75k": 15,
        "77.5k": 9,
        "80k": 13,
        "82.5k": 5,
        "85k": 10,
        "87.5k": 7,
        "90k": 12,
        "92.5k": 4,
        "95k": 12,
        "97.5k": 11,
        "100k": 7,
        "200k": "96"
      },
      houseLoitererfm: {
        "2.5k": 105,
        "5k": 78,
        "7.5k": 43,
        "10k": 45,
        "12.5k": 61,
        "15k": 35,
        "17.5k": 98,
        "20k": 31,
        "22.5k": 57,
        "25k": 51,
        "27.5k": 60,
        "30k": 36,
        "32.5k": 53,
        "35k": 35,
        "37.5k": 40,
        "40k": 19,
        "42.5k": 32,
        "45k": 12,
        "47.5k": 47,
        "50k": 12,
        "52.5k": 40,
        "55k": 14,
        "57.5k": 20,
        "60k": 11,
        "62.5k": 22,
        "65k": 8,
        "67.5k": 9,
        "70k": 7,
        "72.5k": 9,
        "75k": 8,
        "77.5k": 10,
        "80k": 8,
        "82.5k": 8,
        "85k": 9,
        "87.5k": 7,
        "90k": 2,
        "92.5k": 8,
        "95k": 1,
        "97.5k": 0,
        "100k": 1,
        "200k": "49"
      },
      spousefm: {
        "2.5k": 3,
        "5k": 5,
        "7.5k": 2,
        "10k": 4,
        "12.5k": 9,
        "15k": 13,
        "17.5k": 15,
        "20k": 8,
        "22.5k": 14,
        "25k": 4,
        "27.5k": 11,
        "30k": 6,
        "32.5k": 7,
        "35k": 9,
        "37.5k": 14,
        "40k": 11,
        "42.5k": 17,
        "45k": 7,
        "47.5k": 11,
        "50k": 1,
        "52.5k": 2,
        "55k": 0,
        "57.5k": 12,
        "60k": 1,
        "62.5k": 10,
        "65k": 4,
        "67.5k": 8,
        "70k": 3,
        "72.5k": 1,
        "75k": 3,
        "77.5k": 0,
        "80k": 4,
        "82.5k": 0,
        "85k": 6,
        "87.5k": 0,
        "90k": 1,
        "92.5k": 1,
        "95k": 5,
        "97.5k": 0,
        "100k": 0,
        "200k": "20"
      },
      childfm: {
        "2.5k": 37,
        "5k": 20,
        "7.5k": 47,
        "10k": 41,
        "12.5k": 47,
        "15k": 56,
        "17.5k": 56,
        "20k": 73,
        "22.5k": 97,
        "25k": 71,
        "27.5k": 116,
        "30k": 68,
        "32.5k": 116,
        "35k": 79,
        "37.5k": 100,
        "40k": 91,
        "42.5k": 117,
        "45k": 72,
        "47.5k": 114,
        "50k": 70,
        "52.5k": 123,
        "55k": 76,
        "57.5k": 88,
        "60k": 92,
        "62.5k": 106,
        "65k": 76,
        "67.5k": 78,
        "70k": 57,
        "72.5k": 105,
        "75k": 77,
        "77.5k": 97,
        "80k": 71,
        "82.5k": 107,
        "85k": 73,
        "87.5k": 60,
        "90k": 92,
        "92.5k": 103,
        "95k": 91,
        "97.5k": 73,
        "100k": 65,
        "200k": "1,771"
      },
      otherRelativefm: {
        "2.5k": 15,
        "5k": 7,
        "7.5k": 2,
        "10k": 11,
        "12.5k": 19,
        "15k": 17,
        "17.5k": 30,
        "20k": 18,
        "22.5k": 18,
        "25k": 22,
        "27.5k": 21,
        "30k": 21,
        "32.5k": 16,
        "35k": 18,
        "37.5k": 31,
        "40k": 10,
        "42.5k": 14,
        "45k": 7,
        "47.5k": 11,
        "50k": 11,
        "52.5k": 28,
        "55k": 12,
        "57.5k": 12,
        "60k": 11,
        "62.5k": 17,
        "65k": 12,
        "67.5k": 19,
        "70k": 8,
        "72.5k": 10,
        "75k": 10,
        "77.5k": 12,
        "80k": 8,
        "82.5k": 12,
        "85k": 5,
        "87.5k": 7,
        "90k": 5,
        "92.5k": 9,
        "95k": 3,
        "97.5k": 14,
        "100k": 12,
        "200k": "111"
      },
      nonRelativefm: {
        "2.5k": 11,
        "5k": 7,
        "7.5k": 8,
        "10k": 16,
        "12.5k": 9,
        "15k": 20,
        "17.5k": 43,
        "20k": 31,
        "22.5k": 28,
        "25k": 40,
        "27.5k": 15,
        "30k": 21,
        "32.5k": 32,
        "35k": 35,
        "37.5k": 24,
        "40k": 29,
        "42.5k": 34,
        "45k": 36,
        "47.5k": 30,
        "50k": 13,
        "52.5k": 24,
        "55k": 11,
        "57.5k": 21,
        "60k": 7,
        "62.5k": 22,
        "65k": 8,
        "67.5k": 16,
        "70k": 8,
        "72.5k": 13,
        "75k": 2,
        "77.5k": 9,
        "80k": 8,
        "82.5k": 9,
        "85k": 15,
        "87.5k": 6,
        "90k": 7,
        "92.5k": 3,
        "95k": 2,
        "97.5k": 2,
        "100k": 1,
        "200k": "107"
      }
    },
    "24": {
      houseLoitererm: {
        "2.5k": 104,
        "5k": 13,
        "7.5k": 29,
        "10k": 63,
        "12.5k": 91,
        "15k": 49,
        "17.5k": 119,
        "20k": 84,
        "22.5k": 159,
        "25k": 121,
        "27.5k": 156,
        "30k": 106,
        "32.5k": 173,
        "35k": 79,
        "37.5k": 210,
        "40k": 106,
        "42.5k": 215,
        "45k": 98,
        "47.5k": 142,
        "50k": 103,
        "52.5k": 157,
        "55k": 115,
        "57.5k": 153,
        "60k": 89,
        "62.5k": 160,
        "65k": 76,
        "67.5k": 119,
        "70k": 69,
        "72.5k": 134,
        "75k": 65,
        "77.5k": 99,
        "80k": 87,
        "82.5k": 121,
        "85k": 81,
        "87.5k": 62,
        "90k": 57,
        "92.5k": 60,
        "95k": 41,
        "97.5k": 54,
        "100k": 34,
        "200k": 714
      },
      spousem: {
        "2.5k": 16,
        "5k": 0,
        "7.5k": 2,
        "10k": 14,
        "12.5k": 18,
        "15k": 8,
        "17.5k": 36,
        "20k": 17,
        "22.5k": 24,
        "25k": 28,
        "27.5k": 34,
        "30k": 38,
        "32.5k": 36,
        "35k": 25,
        "37.5k": 49,
        "40k": 29,
        "42.5k": 52,
        "45k": 47,
        "47.5k": 36,
        "50k": 25,
        "52.5k": 38,
        "55k": 12,
        "57.5k": 39,
        "60k": 31,
        "62.5k": 53,
        "65k": 37,
        "67.5k": 45,
        "70k": 22,
        "72.5k": 49,
        "75k": 29,
        "77.5k": 46,
        "80k": 22,
        "82.5k": 33,
        "85k": 22,
        "87.5k": 24,
        "90k": 15,
        "92.5k": 14,
        "95k": 18,
        "97.5k": 19,
        "100k": 16,
        "200k": 207
      },
      childm: {
        "2.5k": 13,
        "5k": 5,
        "7.5k": 13,
        "10k": 19,
        "12.5k": 11,
        "15k": 15,
        "17.5k": 32,
        "20k": 14,
        "22.5k": 32,
        "25k": 36,
        "27.5k": 24,
        "30k": 37,
        "32.5k": 23,
        "35k": 47,
        "37.5k": 50,
        "40k": 38,
        "42.5k": 63,
        "45k": 46,
        "47.5k": 67,
        "50k": 33,
        "52.5k": 51,
        "55k": 32,
        "57.5k": 45,
        "60k": 41,
        "62.5k": 48,
        "65k": 25,
        "67.5k": 29,
        "70k": 38,
        "72.5k": 47,
        "75k": 46,
        "77.5k": 52,
        "80k": 34,
        "82.5k": 31,
        "85k": 41,
        "87.5k": 42,
        "90k": 48,
        "92.5k": 24,
        "95k": 23,
        "97.5k": 16,
        "100k": 22,
        "200k": 894
      },
      otherRelativem: {
        "2.5k": 15,
        "5k": 4,
        "7.5k": 3,
        "10k": 3,
        "12.5k": 8,
        "15k": 6,
        "17.5k": 11,
        "20k": 11,
        "22.5k": 15,
        "25k": 20,
        "27.5k": 13,
        "30k": 14,
        "32.5k": 27,
        "35k": 8,
        "37.5k": 18,
        "40k": 17,
        "42.5k": 22,
        "45k": 27,
        "47.5k": 37,
        "50k": 19,
        "52.5k": 22,
        "55k": 14,
        "57.5k": 37,
        "60k": 16,
        "62.5k": 35,
        "65k": 16,
        "67.5k": 33,
        "70k": 13,
        "72.5k": 16,
        "75k": 14,
        "77.5k": 18,
        "80k": 20,
        "82.5k": 35,
        "85k": 9,
        "87.5k": 18,
        "90k": 16,
        "92.5k": 19,
        "95k": 12,
        "97.5k": 14,
        "100k": 11,
        "200k": 209
      },
      nonRelativem: {
        "2.5k": 6,
        "5k": 3,
        "7.5k": 8,
        "10k": 14,
        "12.5k": 17,
        "15k": 5,
        "17.5k": 42,
        "20k": 31,
        "22.5k": 34,
        "25k": 21,
        "27.5k": 16,
        "30k": 56,
        "32.5k": 55,
        "35k": 46,
        "37.5k": 37,
        "40k": 40,
        "42.5k": 42,
        "45k": 28,
        "47.5k": 30,
        "50k": 46,
        "52.5k": 53,
        "55k": 26,
        "57.5k": 49,
        "60k": 21,
        "62.5k": 55,
        "65k": 23,
        "67.5k": 55,
        "70k": 20,
        "72.5k": 41,
        "75k": 32,
        "77.5k": 39,
        "80k": 15,
        "82.5k": 29,
        "85k": 18,
        "87.5k": 37,
        "90k": 34,
        "92.5k": 31,
        "95k": 13,
        "97.5k": 19,
        "100k": 10,
        "200k": 416
      },
      houseLoitererfm: {
        "2.5k": 172,
        "5k": 63,
        "7.5k": 108,
        "10k": 136,
        "12.5k": 178,
        "15k": 89,
        "17.5k": 165,
        "20k": 152,
        "22.5k": 164,
        "25k": 128,
        "27.5k": 167,
        "30k": 119,
        "32.5k": 182,
        "35k": 119,
        "37.5k": 203,
        "40k": 127,
        "42.5k": 148,
        "45k": 92,
        "47.5k": 134,
        "50k": 79,
        "52.5k": 116,
        "55k": 69,
        "57.5k": 103,
        "60k": 65,
        "62.5k": 124,
        "65k": 75,
        "67.5k": 110,
        "70k": 62,
        "72.5k": 100,
        "75k": 56,
        "77.5k": 83,
        "80k": 48,
        "82.5k": 79,
        "85k": 52,
        "87.5k": 70,
        "90k": 36,
        "92.5k": 55,
        "95k": 32,
        "97.5k": 41,
        "100k": 30,
        "200k": 561
      },
      spousefm: {
        "2.5k": 21,
        "5k": 6,
        "7.5k": 6,
        "10k": 18,
        "12.5k": 25,
        "15k": 38,
        "17.5k": 27,
        "20k": 35,
        "22.5k": 63,
        "25k": 43,
        "27.5k": 51,
        "30k": 38,
        "32.5k": 57,
        "35k": 51,
        "37.5k": 77,
        "40k": 55,
        "42.5k": 85,
        "45k": 37,
        "47.5k": 64,
        "50k": 49,
        "52.5k": 94,
        "55k": 65,
        "57.5k": 99,
        "60k": 71,
        "62.5k": 86,
        "65k": 67,
        "67.5k": 62,
        "70k": 48,
        "72.5k": 76,
        "75k": 63,
        "77.5k": 87,
        "80k": 50,
        "82.5k": 82,
        "85k": 40,
        "87.5k": 64,
        "90k": 30,
        "92.5k": 44,
        "95k": 31,
        "97.5k": 53,
        "100k": 31,
        "200k": 543
      },
      childfm: {
        "2.5k": 12,
        "5k": 3,
        "7.5k": 15,
        "10k": 10,
        "12.5k": 5,
        "15k": 8,
        "17.5k": 15,
        "20k": 3,
        "22.5k": 13,
        "25k": 12,
        "27.5k": 22,
        "30k": 15,
        "32.5k": 16,
        "35k": 19,
        "37.5k": 31,
        "40k": 24,
        "42.5k": 34,
        "45k": 25,
        "47.5k": 31,
        "50k": 28,
        "52.5k": 22,
        "55k": 19,
        "57.5k": 18,
        "60k": 13,
        "62.5k": 31,
        "65k": 31,
        "67.5k": 26,
        "70k": 15,
        "72.5k": 38,
        "75k": 26,
        "77.5k": 31,
        "80k": 23,
        "82.5k": 42,
        "85k": 16,
        "87.5k": 26,
        "90k": 24,
        "92.5k": 14,
        "95k": 14,
        "97.5k": 22,
        "100k": 16,
        "200k": 569
      },
      otherRelativefm: {
        "2.5k": 10,
        "5k": 1,
        "7.5k": 0,
        "10k": 6,
        "12.5k": 4,
        "15k": 0,
        "17.5k": 17,
        "20k": 2,
        "22.5k": 4,
        "25k": 8,
        "27.5k": 9,
        "30k": 13,
        "32.5k": 10,
        "35k": 5,
        "37.5k": 13,
        "40k": 7,
        "42.5k": 3,
        "45k": 19,
        "47.5k": 15,
        "50k": 17,
        "52.5k": 17,
        "55k": 19,
        "57.5k": 12,
        "60k": 7,
        "62.5k": 7,
        "65k": 2,
        "67.5k": 9,
        "70k": 11,
        "72.5k": 5,
        "75k": 4,
        "77.5k": 10,
        "80k": 6,
        "82.5k": 3,
        "85k": 3,
        "87.5k": 6,
        "90k": 12,
        "92.5k": 7,
        "95k": 13,
        "97.5k": 13,
        "100k": 2,
        "200k": 140
      },
      nonRelativefm: {
        "2.5k": 15,
        "5k": 0,
        "7.5k": 6,
        "10k": 4,
        "12.5k": 20,
        "15k": 11,
        "17.5k": 23,
        "20k": 15,
        "22.5k": 20,
        "25k": 13,
        "27.5k": 39,
        "30k": 26,
        "32.5k": 30,
        "35k": 21,
        "37.5k": 26,
        "40k": 19,
        "42.5k": 38,
        "45k": 22,
        "47.5k": 31,
        "50k": 34,
        "52.5k": 43,
        "55k": 24,
        "57.5k": 34,
        "60k": 23,
        "62.5k": 43,
        "65k": 14,
        "67.5k": 24,
        "70k": 21,
        "72.5k": 22,
        "75k": 21,
        "77.5k": 28,
        "80k": 19,
        "82.5k": 34,
        "85k": 18,
        "87.5k": 34,
        "90k": 24,
        "92.5k": 20,
        "95k": 12,
        "97.5k": 22,
        "100k": 12,
        "200k": 304
      }
    },
    "34": {
      houseLoitererm: {
        "2.5k": 75,
        "5k": 24,
        "7.5k": 39,
        "10k": 61,
        "12.5k": 71,
        "15k": 61,
        "17.5k": 83,
        "20k": 71,
        "22.5k": 125,
        "25k": 88,
        "27.5k": 158,
        "30k": 86,
        "32.5k": 199,
        "35k": 80,
        "37.5k": 143,
        "40k": 109,
        "42.5k": 157,
        "45k": 79,
        "47.5k": 126,
        "50k": 131,
        "52.5k": 179,
        "55k": 88,
        "57.5k": 132,
        "60k": 113,
        "62.5k": 182,
        "65k": 71,
        "67.5k": 107,
        "70k": 101,
        "72.5k": 100,
        "75k": 76,
        "77.5k": 144,
        "80k": 61,
        "82.5k": 119,
        "85k": 70,
        "87.5k": 113,
        "90k": 58,
        "92.5k": 88,
        "95k": 47,
        "97.5k": 86,
        "100k": 55,
        "200k": "1,168"
      },
      spousem: {
        "2.5k": 21,
        "5k": 9,
        "7.5k": 11,
        "10k": 5,
        "12.5k": 13,
        "15k": 26,
        "17.5k": 26,
        "20k": 10,
        "22.5k": 33,
        "25k": 32,
        "27.5k": 38,
        "30k": 26,
        "32.5k": 42,
        "35k": 35,
        "37.5k": 83,
        "40k": 41,
        "42.5k": 45,
        "45k": 40,
        "47.5k": 58,
        "50k": 22,
        "52.5k": 50,
        "55k": 44,
        "57.5k": 59,
        "60k": 31,
        "62.5k": 72,
        "65k": 23,
        "67.5k": 57,
        "70k": 33,
        "72.5k": 52,
        "75k": 29,
        "77.5k": 33,
        "80k": 43,
        "82.5k": 70,
        "85k": 30,
        "87.5k": 52,
        "90k": 35,
        "92.5k": 46,
        "95k": 26,
        "97.5k": 33,
        "100k": 17,
        "200k": "578"
      },
      childm: {
        "2.5k": 13,
        "5k": 0,
        "7.5k": 3,
        "10k": 5,
        "12.5k": 10,
        "15k": 10,
        "17.5k": 12,
        "20k": 13,
        "22.5k": 12,
        "25k": 15,
        "27.5k": 15,
        "30k": 16,
        "32.5k": 32,
        "35k": 20,
        "37.5k": 10,
        "40k": 6,
        "42.5k": 46,
        "45k": 14,
        "47.5k": 16,
        "50k": 20,
        "52.5k": 14,
        "55k": 24,
        "57.5k": 34,
        "60k": 26,
        "62.5k": 20,
        "65k": 14,
        "67.5k": 30,
        "70k": 17,
        "72.5k": 37,
        "75k": 14,
        "77.5k": 6,
        "80k": 14,
        "82.5k": 14,
        "85k": 21,
        "87.5k": 16,
        "90k": 15,
        "92.5k": 19,
        "95k": 5,
        "97.5k": 14,
        "100k": 24,
        "200k": "264"
      },
      otherRelativem: {
        "2.5k": 3,
        "5k": 0,
        "7.5k": 3,
        "10k": 3,
        "12.5k": 0,
        "15k": 13,
        "17.5k": 19,
        "20k": 3,
        "22.5k": 27,
        "25k": 13,
        "27.5k": 24,
        "30k": 26,
        "32.5k": 11,
        "35k": 20,
        "37.5k": 25,
        "40k": 10,
        "42.5k": 16,
        "45k": 31,
        "47.5k": 12,
        "50k": 10,
        "52.5k": 21,
        "55k": 6,
        "57.5k": 17,
        "60k": 14,
        "62.5k": 11,
        "65k": 14,
        "67.5k": 13,
        "70k": 6,
        "72.5k": 7,
        "75k": 4,
        "77.5k": 16,
        "80k": 6,
        "82.5k": 18,
        "85k": 2,
        "87.5k": 11,
        "90k": 4,
        "92.5k": 8,
        "95k": 12,
        "97.5k": 3,
        "100k": 3,
        "200k": "126"
      },
      nonRelativem: {
        "2.5k": 13,
        "5k": 1,
        "7.5k": 1,
        "10k": 6,
        "12.5k": 9,
        "15k": 8,
        "17.5k": 12,
        "20k": 6,
        "22.5k": 17,
        "25k": 10,
        "27.5k": 26,
        "30k": 15,
        "32.5k": 11,
        "35k": 14,
        "37.5k": 15,
        "40k": 20,
        "42.5k": 31,
        "45k": 20,
        "47.5k": 25,
        "50k": 13,
        "52.5k": 21,
        "55k": 17,
        "57.5k": 32,
        "60k": 31,
        "62.5k": 28,
        "65k": 14,
        "67.5k": 20,
        "70k": 19,
        "72.5k": 21,
        "75k": 11,
        "77.5k": 27,
        "80k": 19,
        "82.5k": 25,
        "85k": 16,
        "87.5k": 16,
        "90k": 20,
        "92.5k": 19,
        "95k": 5,
        "97.5k": 17,
        "100k": 14,
        "200k": "226"
      },
      houseLoitererfm: {
        "2.5k": 134,
        "5k": 67,
        "7.5k": 74,
        "10k": 114,
        "12.5k": 131,
        "15k": 100,
        "17.5k": 137,
        "20k": 118,
        "22.5k": 152,
        "25k": 113,
        "27.5k": 159,
        "30k": 116,
        "32.5k": 125,
        "35k": 97,
        "37.5k": 194,
        "40k": 98,
        "42.5k": 147,
        "45k": 99,
        "47.5k": 153,
        "50k": 91,
        "52.5k": 115,
        "55k": 86,
        "57.5k": 92,
        "60k": 92,
        "62.5k": 132,
        "65k": 57,
        "67.5k": 88,
        "70k": 63,
        "72.5k": 86,
        "75k": 58,
        "77.5k": 80,
        "80k": 57,
        "82.5k": 92,
        "85k": 50,
        "87.5k": 56,
        "90k": 48,
        "92.5k": 89,
        "95k": 49,
        "97.5k": 41,
        "100k": 31,
        "200k": "833"
      },
      spousefm: {
        "2.5k": 24,
        "5k": 6,
        "7.5k": 17,
        "10k": 17,
        "12.5k": 25,
        "15k": 32,
        "17.5k": 33,
        "20k": 54,
        "22.5k": 52,
        "25k": 23,
        "27.5k": 65,
        "30k": 35,
        "32.5k": 72,
        "35k": 42,
        "37.5k": 66,
        "40k": 40,
        "42.5k": 64,
        "45k": 69,
        "47.5k": 67,
        "50k": 77,
        "52.5k": 97,
        "55k": 61,
        "57.5k": 81,
        "60k": 72,
        "62.5k": 97,
        "65k": 48,
        "67.5k": 94,
        "70k": 81,
        "72.5k": 97,
        "75k": 46,
        "77.5k": 106,
        "80k": 51,
        "82.5k": 90,
        "85k": 41,
        "87.5k": 79,
        "90k": 38,
        "92.5k": 77,
        "95k": 58,
        "97.5k": 57,
        "100k": 38,
        "200k": "1,141"
      },
      childfm: {
        "2.5k": 4,
        "5k": 0,
        "7.5k": 7,
        "10k": 0,
        "12.5k": 13,
        "15k": 3,
        "17.5k": 6,
        "20k": 10,
        "22.5k": 6,
        "25k": 14,
        "27.5k": 10,
        "30k": 16,
        "32.5k": 11,
        "35k": 11,
        "37.5k": 21,
        "40k": 15,
        "42.5k": 16,
        "45k": 21,
        "47.5k": 15,
        "50k": 12,
        "52.5k": 19,
        "55k": 10,
        "57.5k": 18,
        "60k": 10,
        "62.5k": 17,
        "65k": 14,
        "67.5k": 13,
        "70k": 5,
        "72.5k": 9,
        "75k": 18,
        "77.5k": 11,
        "80k": 4,
        "82.5k": 6,
        "85k": 4,
        "87.5k": 15,
        "90k": 13,
        "92.5k": 15,
        "95k": 16,
        "97.5k": 9,
        "100k": 2,
        "200k": "172"
      },
      otherRelativefm: {
        "2.5k": 6,
        "5k": 0,
        "7.5k": 4,
        "10k": 2,
        "12.5k": 1,
        "15k": 5,
        "17.5k": 7,
        "20k": 5,
        "22.5k": 6,
        "25k": 3,
        "27.5k": 12,
        "30k": 10,
        "32.5k": 9,
        "35k": 6,
        "37.5k": 6,
        "40k": 6,
        "42.5k": 21,
        "45k": 3,
        "47.5k": 7,
        "50k": 2,
        "52.5k": 12,
        "55k": 2,
        "57.5k": 9,
        "60k": 4,
        "62.5k": 8,
        "65k": 0,
        "67.5k": 6,
        "70k": 2,
        "72.5k": 2,
        "75k": 0,
        "77.5k": 3,
        "80k": 9,
        "82.5k": 3,
        "85k": 5,
        "87.5k": 3,
        "90k": 4,
        "92.5k": 7,
        "95k": 3,
        "97.5k": 4,
        "100k": 0,
        "200k": "75"
      },
      nonRelativefm: {
        "2.5k": 7,
        "5k": 4,
        "7.5k": 2,
        "10k": 7,
        "12.5k": 4,
        "15k": 3,
        "17.5k": 15,
        "20k": 9,
        "22.5k": 16,
        "25k": 8,
        "27.5k": 8,
        "30k": 3,
        "32.5k": 15,
        "35k": 5,
        "37.5k": 25,
        "40k": 9,
        "42.5k": 13,
        "45k": 20,
        "47.5k": 22,
        "50k": 12,
        "52.5k": 21,
        "55k": 11,
        "57.5k": 18,
        "60k": 17,
        "62.5k": 22,
        "65k": 6,
        "67.5k": 8,
        "70k": 16,
        "72.5k": 16,
        "75k": 12,
        "77.5k": 23,
        "80k": 13,
        "82.5k": 11,
        "85k": 18,
        "87.5k": 14,
        "90k": 7,
        "92.5k": 11,
        "95k": 14,
        "97.5k": 11,
        "100k": 7,
        "200k": "158"
      }
    },
    "44": {
      houseLoitererm: {
        "2.5k": 142,
        "5k": 51,
        "7.5k": 64,
        "10k": 124,
        "12.5k": 185,
        "15k": 113,
        "17.5k": 156,
        "20k": 181,
        "22.5k": 304,
        "25k": 153,
        "27.5k": 261,
        "30k": 194,
        "32.5k": 315,
        "35k": 161,
        "37.5k": 321,
        "40k": 141,
        "42.5k": 297,
        "45k": 176,
        "47.5k": 251,
        "50k": 174,
        "52.5k": 391,
        "55k": 193,
        "57.5k": 261,
        "60k": 227,
        "62.5k": 331,
        "65k": 183,
        "67.5k": 239,
        "70k": 168,
        "72.5k": 324,
        "75k": 202,
        "77.5k": 287,
        "80k": 154,
        "82.5k": 243,
        "85k": 147,
        "87.5k": 230,
        "90k": 153,
        "92.5k": 213,
        "95k": 107,
        "97.5k": 205,
        "100k": 103,
        "200k": "3,352"
      },
      spousem: {
        "2.5k": 34,
        "5k": 11,
        "7.5k": 12,
        "10k": 22,
        "12.5k": 38,
        "15k": 19,
        "17.5k": 38,
        "20k": 53,
        "22.5k": 86,
        "25k": 40,
        "27.5k": 85,
        "30k": 47,
        "32.5k": 109,
        "35k": 66,
        "37.5k": 98,
        "40k": 85,
        "42.5k": 155,
        "45k": 75,
        "47.5k": 103,
        "50k": 87,
        "52.5k": 161,
        "55k": 88,
        "57.5k": 100,
        "60k": 83,
        "62.5k": 145,
        "65k": 74,
        "67.5k": 124,
        "70k": 97,
        "72.5k": 143,
        "75k": 93,
        "77.5k": 140,
        "80k": 92,
        "82.5k": 124,
        "85k": 80,
        "87.5k": 110,
        "90k": 74,
        "92.5k": 149,
        "95k": 62,
        "97.5k": 88,
        "100k": 58,
        "200k": "1,929"
      },
      childm: {
        "2.5k": 13,
        "5k": 0,
        "7.5k": 10,
        "10k": 15,
        "12.5k": 14,
        "15k": 21,
        "17.5k": 21,
        "20k": 34,
        "22.5k": 38,
        "25k": 13,
        "27.5k": 31,
        "30k": 39,
        "32.5k": 47,
        "35k": 20,
        "37.5k": 33,
        "40k": 35,
        "42.5k": 68,
        "45k": 28,
        "47.5k": 39,
        "50k": 44,
        "52.5k": 40,
        "55k": 11,
        "57.5k": 37,
        "60k": 33,
        "62.5k": 35,
        "65k": 32,
        "67.5k": 19,
        "70k": 18,
        "72.5k": 27,
        "75k": 44,
        "77.5k": 34,
        "80k": 32,
        "82.5k": 27,
        "85k": 17,
        "87.5k": 24,
        "90k": 27,
        "92.5k": 32,
        "95k": 17,
        "97.5k": 38,
        "100k": 27,
        "200k": "264"
      },
      otherRelativem: {
        "2.5k": 5,
        "5k": 13,
        "7.5k": 7,
        "10k": 17,
        "12.5k": 6,
        "15k": 12,
        "17.5k": 12,
        "20k": 13,
        "22.5k": 8,
        "25k": 17,
        "27.5k": 44,
        "30k": 18,
        "32.5k": 27,
        "35k": 13,
        "37.5k": 30,
        "40k": 15,
        "42.5k": 14,
        "45k": 41,
        "47.5k": 41,
        "50k": 16,
        "52.5k": 29,
        "55k": 22,
        "57.5k": 27,
        "60k": 22,
        "62.5k": 29,
        "65k": 17,
        "67.5k": 14,
        "70k": 26,
        "72.5k": 24,
        "75k": 19,
        "77.5k": 15,
        "80k": 17,
        "82.5k": 20,
        "85k": 24,
        "87.5k": 18,
        "90k": 11,
        "92.5k": 15,
        "95k": 16,
        "97.5k": 9,
        "100k": 8,
        "200k": "193"
      },
      nonRelativem: {
        "2.5k": 5,
        "5k": 0,
        "7.5k": 9,
        "10k": 16,
        "12.5k": 19,
        "15k": 22,
        "17.5k": 26,
        "20k": 16,
        "22.5k": 12,
        "25k": 17,
        "27.5k": 34,
        "30k": 27,
        "32.5k": 23,
        "35k": 18,
        "37.5k": 38,
        "40k": 34,
        "42.5k": 46,
        "45k": 43,
        "47.5k": 36,
        "50k": 25,
        "52.5k": 35,
        "55k": 25,
        "57.5k": 28,
        "60k": 52,
        "62.5k": 54,
        "65k": 29,
        "67.5k": 24,
        "70k": 11,
        "72.5k": 33,
        "75k": 23,
        "77.5k": 25,
        "80k": 30,
        "82.5k": 30,
        "85k": 27,
        "87.5k": 47,
        "90k": 24,
        "92.5k": 23,
        "95k": 26,
        "97.5k": 18,
        "100k": 7,
        "200k": "340"
      },
      houseLoitererfm: {
        "2.5k": 236,
        "5k": 71,
        "7.5k": 141,
        "10k": 228,
        "12.5k": 236,
        "15k": 202,
        "17.5k": 240,
        "20k": 261,
        "22.5k": 329,
        "25k": 201,
        "27.5k": 330,
        "30k": 183,
        "32.5k": 344,
        "35k": 237,
        "37.5k": 318,
        "40k": 217,
        "42.5k": 324,
        "45k": 214,
        "47.5k": 253,
        "50k": 183,
        "52.5k": 329,
        "55k": 166,
        "57.5k": 214,
        "60k": 152,
        "62.5k": 250,
        "65k": 145,
        "67.5k": 214,
        "70k": 168,
        "72.5k": 232,
        "75k": 136,
        "77.5k": 191,
        "80k": 137,
        "82.5k": 185,
        "85k": 121,
        "87.5k": 142,
        "90k": 116,
        "92.5k": 186,
        "95k": 92,
        "97.5k": 113,
        "100k": 90,
        "200k": "2,566"
      },
      spousefm: {
        "2.5k": 45,
        "5k": 12,
        "7.5k": 16,
        "10k": 32,
        "12.5k": 56,
        "15k": 46,
        "17.5k": 57,
        "20k": 80,
        "22.5k": 136,
        "25k": 77,
        "27.5k": 89,
        "30k": 86,
        "32.5k": 138,
        "35k": 82,
        "37.5k": 140,
        "40k": 78,
        "42.5k": 157,
        "45k": 122,
        "47.5k": 152,
        "50k": 95,
        "52.5k": 232,
        "55k": 122,
        "57.5k": 154,
        "60k": 147,
        "62.5k": 209,
        "65k": 149,
        "67.5k": 176,
        "70k": 120,
        "72.5k": 198,
        "75k": 162,
        "77.5k": 218,
        "80k": 114,
        "82.5k": 210,
        "85k": 135,
        "87.5k": 172,
        "90k": 158,
        "92.5k": 149,
        "95k": 90,
        "97.5k": 162,
        "100k": 105,
        "200k": "3,063"
      },
      childfm: {
        "2.5k": 10,
        "5k": 0,
        "7.5k": 4,
        "10k": 9,
        "12.5k": 16,
        "15k": 3,
        "17.5k": 14,
        "20k": 20,
        "22.5k": 13,
        "25k": 16,
        "27.5k": 15,
        "30k": 21,
        "32.5k": 22,
        "35k": 17,
        "37.5k": 19,
        "40k": 26,
        "42.5k": 26,
        "45k": 32,
        "47.5k": 8,
        "50k": 27,
        "52.5k": 18,
        "55k": 11,
        "57.5k": 12,
        "60k": 12,
        "62.5k": 19,
        "65k": 12,
        "67.5k": 16,
        "70k": 14,
        "72.5k": 11,
        "75k": 9,
        "77.5k": 16,
        "80k": 11,
        "82.5k": 9,
        "85k": 2,
        "87.5k": 15,
        "90k": 2,
        "92.5k": 11,
        "95k": 8,
        "97.5k": 10,
        "100k": 8,
        "200k": "131"
      },
      otherRelativefm: {
        "2.5k": 24,
        "5k": 0,
        "7.5k": 5,
        "10k": 8,
        "12.5k": 11,
        "15k": 10,
        "17.5k": 13,
        "20k": 19,
        "22.5k": 15,
        "25k": 13,
        "27.5k": 28,
        "30k": 14,
        "32.5k": 18,
        "35k": 22,
        "37.5k": 21,
        "40k": 5,
        "42.5k": 19,
        "45k": 15,
        "47.5k": 16,
        "50k": 19,
        "52.5k": 27,
        "55k": 12,
        "57.5k": 18,
        "60k": 13,
        "62.5k": 11,
        "65k": 5,
        "67.5k": 15,
        "70k": 13,
        "72.5k": 14,
        "75k": 5,
        "77.5k": 6,
        "80k": 11,
        "82.5k": 20,
        "85k": 12,
        "87.5k": 8,
        "90k": 9,
        "92.5k": 7,
        "95k": 9,
        "97.5k": 8,
        "100k": 7,
        "200k": "111"
      },
      nonRelativefm: {
        "2.5k": 7,
        "5k": 8,
        "7.5k": 5,
        "10k": 10,
        "12.5k": 6,
        "15k": 14,
        "17.5k": 18,
        "20k": 7,
        "22.5k": 8,
        "25k": 20,
        "27.5k": 15,
        "30k": 22,
        "32.5k": 25,
        "35k": 11,
        "37.5k": 32,
        "40k": 11,
        "42.5k": 19,
        "45k": 25,
        "47.5k": 25,
        "50k": 28,
        "52.5k": 21,
        "55k": 18,
        "57.5k": 13,
        "60k": 15,
        "62.5k": 21,
        "65k": 12,
        "67.5k": 17,
        "70k": 23,
        "72.5k": 23,
        "75k": 20,
        "77.5k": 18,
        "80k": 17,
        "82.5k": 14,
        "85k": 19,
        "87.5k": 11,
        "90k": 15,
        "92.5k": 23,
        "95k": 7,
        "97.5k": 15,
        "100k": 4,
        "200k": "243"
      }
    },
    "54": {
      houseLoitererm: {
        "2.5k": 226,
        "5k": 77,
        "7.5k": 131,
        "10k": 236,
        "12.5k": 176,
        "15k": 157,
        "17.5k": 210,
        "20k": 187,
        "22.5k": 264,
        "25k": 193,
        "27.5k": 249,
        "30k": 147,
        "32.5k": 305,
        "35k": 206,
        "37.5k": 260,
        "40k": 187,
        "42.5k": 344,
        "45k": 230,
        "47.5k": 297,
        "50k": 199,
        "52.5k": 355,
        "55k": 185,
        "57.5k": 248,
        "60k": 202,
        "62.5k": 349,
        "65k": 207,
        "67.5k": 300,
        "70k": 155,
        "72.5k": 274,
        "75k": 207,
        "77.5k": 295,
        "80k": 173,
        "82.5k": 258,
        "85k": 158,
        "87.5k": 187,
        "90k": 192,
        "92.5k": 227,
        "95k": 165,
        "97.5k": 208,
        "100k": 150,
        "200k": "4,135"
      },
      spousem: {
        "2.5k": 27,
        "5k": 13,
        "7.5k": 8,
        "10k": 23,
        "12.5k": 51,
        "15k": 32,
        "17.5k": 33,
        "20k": 60,
        "22.5k": 93,
        "25k": 47,
        "27.5k": 60,
        "30k": 66,
        "32.5k": 85,
        "35k": 77,
        "37.5k": 89,
        "40k": 99,
        "42.5k": 123,
        "45k": 69,
        "47.5k": 122,
        "50k": 92,
        "52.5k": 136,
        "55k": 80,
        "57.5k": 132,
        "60k": 87,
        "62.5k": 118,
        "65k": 81,
        "67.5k": 104,
        "70k": 77,
        "72.5k": 123,
        "75k": 101,
        "77.5k": 149,
        "80k": 76,
        "82.5k": 138,
        "85k": 97,
        "87.5k": 105,
        "90k": 107,
        "92.5k": 130,
        "95k": 98,
        "97.5k": 80,
        "100k": 81,
        "200k": "2,271"
      },
      childm: {
        "2.5k": 12,
        "5k": 4,
        "7.5k": 12,
        "10k": 12,
        "12.5k": 37,
        "15k": 30,
        "17.5k": 58,
        "20k": 66,
        "22.5k": 39,
        "25k": 37,
        "27.5k": 29,
        "30k": 30,
        "32.5k": 52,
        "35k": 18,
        "37.5k": 40,
        "40k": 63,
        "42.5k": 42,
        "45k": 21,
        "47.5k": 35,
        "50k": 22,
        "52.5k": 41,
        "55k": 10,
        "57.5k": 39,
        "60k": 13,
        "62.5k": 12,
        "65k": 22,
        "67.5k": 12,
        "70k": 11,
        "72.5k": 10,
        "75k": 7,
        "77.5k": 13,
        "80k": 19,
        "82.5k": 10,
        "85k": 17,
        "87.5k": 17,
        "90k": 13,
        "92.5k": 10,
        "95k": 10,
        "97.5k": 2,
        "100k": 9,
        "200k": "108"
      },
      otherRelativem: {
        "2.5k": 13,
        "5k": 2,
        "7.5k": 2,
        "10k": 14,
        "12.5k": 14,
        "15k": 21,
        "17.5k": 8,
        "20k": 16,
        "22.5k": 23,
        "25k": 31,
        "27.5k": 33,
        "30k": 34,
        "32.5k": 32,
        "35k": 38,
        "37.5k": 35,
        "40k": 24,
        "42.5k": 19,
        "45k": 31,
        "47.5k": 24,
        "50k": 17,
        "52.5k": 29,
        "55k": 11,
        "57.5k": 34,
        "60k": 18,
        "62.5k": 18,
        "65k": 26,
        "67.5k": 23,
        "70k": 17,
        "72.5k": 22,
        "75k": 18,
        "77.5k": 13,
        "80k": 9,
        "82.5k": 21,
        "85k": 12,
        "87.5k": 13,
        "90k": 11,
        "92.5k": 21,
        "95k": 8,
        "97.5k": 22,
        "100k": 11,
        "200k": "302"
      },
      nonRelativem: {
        "2.5k": 16,
        "5k": 4,
        "7.5k": 3,
        "10k": 12,
        "12.5k": 9,
        "15k": 23,
        "17.5k": 34,
        "20k": 23,
        "22.5k": 22,
        "25k": 13,
        "27.5k": 32,
        "30k": 26,
        "32.5k": 9,
        "35k": 28,
        "37.5k": 24,
        "40k": 31,
        "42.5k": 45,
        "45k": 5,
        "47.5k": 16,
        "50k": 16,
        "52.5k": 25,
        "55k": 26,
        "57.5k": 23,
        "60k": 23,
        "62.5k": 34,
        "65k": 17,
        "67.5k": 24,
        "70k": 18,
        "72.5k": 20,
        "75k": 21,
        "77.5k": 9,
        "80k": 13,
        "82.5k": 12,
        "85k": 13,
        "87.5k": 24,
        "90k": 16,
        "92.5k": 7,
        "95k": 12,
        "97.5k": 22,
        "100k": 13,
        "200k": "297"
      },
      houseLoitererfm: {
        "2.5k": 268,
        "5k": 88,
        "7.5k": 134,
        "10k": 299,
        "12.5k": 255,
        "15k": 217,
        "17.5k": 260,
        "20k": 212,
        "22.5k": 353,
        "25k": 259,
        "27.5k": 272,
        "30k": 268,
        "32.5k": 326,
        "35k": 246,
        "37.5k": 354,
        "40k": 254,
        "42.5k": 342,
        "45k": 189,
        "47.5k": 266,
        "50k": 271,
        "52.5k": 389,
        "55k": 197,
        "57.5k": 229,
        "60k": 178,
        "62.5k": 293,
        "65k": 162,
        "67.5k": 212,
        "70k": 189,
        "72.5k": 224,
        "75k": 142,
        "77.5k": 230,
        "80k": 112,
        "82.5k": 187,
        "85k": 154,
        "87.5k": 190,
        "90k": 126,
        "92.5k": 155,
        "95k": 136,
        "97.5k": 132,
        "100k": 103,
        "200k": "2,841"
      },
      spousefm: {
        "2.5k": 66,
        "5k": 7,
        "7.5k": 14,
        "10k": 37,
        "12.5k": 47,
        "15k": 37,
        "17.5k": 65,
        "20k": 58,
        "22.5k": 98,
        "25k": 91,
        "27.5k": 105,
        "30k": 65,
        "32.5k": 134,
        "35k": 102,
        "37.5k": 136,
        "40k": 145,
        "42.5k": 175,
        "45k": 133,
        "47.5k": 156,
        "50k": 104,
        "52.5k": 173,
        "55k": 105,
        "57.5k": 164,
        "60k": 135,
        "62.5k": 234,
        "65k": 160,
        "67.5k": 199,
        "70k": 114,
        "72.5k": 201,
        "75k": 141,
        "77.5k": 172,
        "80k": 128,
        "82.5k": 165,
        "85k": 129,
        "87.5k": 139,
        "90k": 149,
        "92.5k": 181,
        "95k": 108,
        "97.5k": 180,
        "100k": 114,
        "200k": "3,588"
      },
      childfm: {
        "2.5k": 10,
        "5k": 3,
        "7.5k": 0,
        "10k": 9,
        "12.5k": 10,
        "15k": 10,
        "17.5k": 10,
        "20k": 13,
        "22.5k": 25,
        "25k": 9,
        "27.5k": 4,
        "30k": 15,
        "32.5k": 25,
        "35k": 8,
        "37.5k": 6,
        "40k": 11,
        "42.5k": 22,
        "45k": 17,
        "47.5k": 16,
        "50k": 16,
        "52.5k": 15,
        "55k": 11,
        "57.5k": 7,
        "60k": 13,
        "62.5k": 11,
        "65k": 27,
        "67.5k": 10,
        "70k": 4,
        "72.5k": 6,
        "75k": 11,
        "77.5k": 3,
        "80k": 10,
        "82.5k": 6,
        "85k": 5,
        "87.5k": 12,
        "90k": 8,
        "92.5k": 2,
        "95k": 0,
        "97.5k": 8,
        "100k": 5,
        "200k": "81"
      },
      otherRelativefm: {
        "2.5k": 15,
        "5k": 13,
        "7.5k": 10,
        "10k": 19,
        "12.5k": 14,
        "15k": 15,
        "17.5k": 33,
        "20k": 34,
        "22.5k": 27,
        "25k": 24,
        "27.5k": 44,
        "30k": 31,
        "32.5k": 33,
        "35k": 37,
        "37.5k": 29,
        "40k": 24,
        "42.5k": 25,
        "45k": 17,
        "47.5k": 27,
        "50k": 22,
        "52.5k": 18,
        "55k": 8,
        "57.5k": 37,
        "60k": 19,
        "62.5k": 28,
        "65k": 15,
        "67.5k": 25,
        "70k": 24,
        "72.5k": 22,
        "75k": 14,
        "77.5k": 18,
        "80k": 17,
        "82.5k": 18,
        "85k": 15,
        "87.5k": 15,
        "90k": 14,
        "92.5k": 16,
        "95k": 13,
        "97.5k": 27,
        "100k": 11,
        "200k": "251"
      },
      nonRelativefm: {
        "2.5k": 10,
        "5k": 7,
        "7.5k": 6,
        "10k": 15,
        "12.5k": 9,
        "15k": 13,
        "17.5k": 14,
        "20k": 20,
        "22.5k": 24,
        "25k": 17,
        "27.5k": 18,
        "30k": 15,
        "32.5k": 15,
        "35k": 9,
        "37.5k": 11,
        "40k": 18,
        "42.5k": 32,
        "45k": 15,
        "47.5k": 23,
        "50k": 15,
        "52.5k": 28,
        "55k": 15,
        "57.5k": 18,
        "60k": 14,
        "62.5k": 21,
        "65k": 14,
        "67.5k": 21,
        "70k": 7,
        "72.5k": 18,
        "75k": 22,
        "77.5k": 19,
        "80k": 7,
        "82.5k": 21,
        "85k": 2,
        "87.5k": 6,
        "90k": 7,
        "92.5k": 25,
        "95k": 16,
        "97.5k": 3,
        "100k": 4,
        "200k": "210"
      }
    },
    "64": {
      houseLoitererm: {
        "2.5k": 226,
        "5k": 77,
        "7.5k": 131,
        "10k": 236,
        "12.5k": 176,
        "15k": 157,
        "17.5k": 210,
        "20k": 187,
        "22.5k": 264,
        "25k": 193,
        "27.5k": 249,
        "30k": 147,
        "32.5k": 305,
        "35k": 206,
        "37.5k": 260,
        "40k": 187,
        "42.5k": 344,
        "45k": 230,
        "47.5k": 297,
        "50k": 199,
        "52.5k": 355,
        "55k": 185,
        "57.5k": 248,
        "60k": 202,
        "62.5k": 349,
        "65k": 207,
        "67.5k": 300,
        "70k": 155,
        "72.5k": 274,
        "75k": 207,
        "77.5k": 295,
        "80k": 173,
        "82.5k": 258,
        "85k": 158,
        "87.5k": 187,
        "90k": 192,
        "92.5k": 227,
        "95k": 165,
        "97.5k": 208,
        "100k": 150,
        "200k": "4,135"
      },
      spousem: {
        "2.5k": 27,
        "5k": 13,
        "7.5k": 8,
        "10k": 23,
        "12.5k": 51,
        "15k": 32,
        "17.5k": 33,
        "20k": 60,
        "22.5k": 93,
        "25k": 47,
        "27.5k": 60,
        "30k": 66,
        "32.5k": 85,
        "35k": 77,
        "37.5k": 89,
        "40k": 99,
        "42.5k": 123,
        "45k": 69,
        "47.5k": 122,
        "50k": 92,
        "52.5k": 136,
        "55k": 80,
        "57.5k": 132,
        "60k": 87,
        "62.5k": 118,
        "65k": 81,
        "67.5k": 104,
        "70k": 77,
        "72.5k": 123,
        "75k": 101,
        "77.5k": 149,
        "80k": 76,
        "82.5k": 138,
        "85k": 97,
        "87.5k": 105,
        "90k": 107,
        "92.5k": 130,
        "95k": 98,
        "97.5k": 80,
        "100k": 81,
        "200k": "2,271"
      },
      childm: {
        "2.5k": 12,
        "5k": 4,
        "7.5k": 12,
        "10k": 12,
        "12.5k": 37,
        "15k": 30,
        "17.5k": 58,
        "20k": 66,
        "22.5k": 39,
        "25k": 37,
        "27.5k": 29,
        "30k": 30,
        "32.5k": 52,
        "35k": 18,
        "37.5k": 40,
        "40k": 63,
        "42.5k": 42,
        "45k": 21,
        "47.5k": 35,
        "50k": 22,
        "52.5k": 41,
        "55k": 10,
        "57.5k": 39,
        "60k": 13,
        "62.5k": 12,
        "65k": 22,
        "67.5k": 12,
        "70k": 11,
        "72.5k": 10,
        "75k": 7,
        "77.5k": 13,
        "80k": 19,
        "82.5k": 10,
        "85k": 17,
        "87.5k": 17,
        "90k": 13,
        "92.5k": 10,
        "95k": 10,
        "97.5k": 2,
        "100k": 9,
        "200k": "108"
      },
      otherRelativem: {
        "2.5k": 13,
        "5k": 2,
        "7.5k": 2,
        "10k": 14,
        "12.5k": 14,
        "15k": 21,
        "17.5k": 8,
        "20k": 16,
        "22.5k": 23,
        "25k": 31,
        "27.5k": 33,
        "30k": 34,
        "32.5k": 32,
        "35k": 38,
        "37.5k": 35,
        "40k": 24,
        "42.5k": 19,
        "45k": 31,
        "47.5k": 24,
        "50k": 17,
        "52.5k": 29,
        "55k": 11,
        "57.5k": 34,
        "60k": 18,
        "62.5k": 18,
        "65k": 26,
        "67.5k": 23,
        "70k": 17,
        "72.5k": 22,
        "75k": 18,
        "77.5k": 13,
        "80k": 9,
        "82.5k": 21,
        "85k": 12,
        "87.5k": 13,
        "90k": 11,
        "92.5k": 21,
        "95k": 8,
        "97.5k": 22,
        "100k": 11,
        "200k": "302"
      },
      nonRelativem: {
        "2.5k": 16,
        "5k": 4,
        "7.5k": 3,
        "10k": 12,
        "12.5k": 9,
        "15k": 23,
        "17.5k": 34,
        "20k": 23,
        "22.5k": 22,
        "25k": 13,
        "27.5k": 32,
        "30k": 26,
        "32.5k": 9,
        "35k": 28,
        "37.5k": 24,
        "40k": 31,
        "42.5k": 45,
        "45k": 5,
        "47.5k": 16,
        "50k": 16,
        "52.5k": 25,
        "55k": 26,
        "57.5k": 23,
        "60k": 23,
        "62.5k": 34,
        "65k": 17,
        "67.5k": 24,
        "70k": 18,
        "72.5k": 20,
        "75k": 21,
        "77.5k": 9,
        "80k": 13,
        "82.5k": 12,
        "85k": 13,
        "87.5k": 24,
        "90k": 16,
        "92.5k": 7,
        "95k": 12,
        "97.5k": 22,
        "100k": 13,
        "200k": "297"
      },
      houseLoitererfm: {
        "2.5k": 268,
        "5k": 88,
        "7.5k": 134,
        "10k": 299,
        "12.5k": 255,
        "15k": 217,
        "17.5k": 260,
        "20k": 212,
        "22.5k": 353,
        "25k": 259,
        "27.5k": 272,
        "30k": 268,
        "32.5k": 326,
        "35k": 246,
        "37.5k": 354,
        "40k": 254,
        "42.5k": 342,
        "45k": 189,
        "47.5k": 266,
        "50k": 271,
        "52.5k": 389,
        "55k": 197,
        "57.5k": 229,
        "60k": 178,
        "62.5k": 293,
        "65k": 162,
        "67.5k": 212,
        "70k": 189,
        "72.5k": 224,
        "75k": 142,
        "77.5k": 230,
        "80k": 112,
        "82.5k": 187,
        "85k": 154,
        "87.5k": 190,
        "90k": 126,
        "92.5k": 155,
        "95k": 136,
        "97.5k": 132,
        "100k": 103,
        "200k": "2,841"
      },
      spousefm: {
        "2.5k": 66,
        "5k": 7,
        "7.5k": 14,
        "10k": 37,
        "12.5k": 47,
        "15k": 37,
        "17.5k": 65,
        "20k": 58,
        "22.5k": 98,
        "25k": 91,
        "27.5k": 105,
        "30k": 65,
        "32.5k": 134,
        "35k": 102,
        "37.5k": 136,
        "40k": 145,
        "42.5k": 175,
        "45k": 133,
        "47.5k": 156,
        "50k": 104,
        "52.5k": 173,
        "55k": 105,
        "57.5k": 164,
        "60k": 135,
        "62.5k": 234,
        "65k": 160,
        "67.5k": 199,
        "70k": 114,
        "72.5k": 201,
        "75k": 141,
        "77.5k": 172,
        "80k": 128,
        "82.5k": 165,
        "85k": 129,
        "87.5k": 139,
        "90k": 149,
        "92.5k": 181,
        "95k": 108,
        "97.5k": 180,
        "100k": 114,
        "200k": "3,588"
      },
      childfm: {
        "2.5k": 10,
        "5k": 3,
        "7.5k": 0,
        "10k": 9,
        "12.5k": 10,
        "15k": 10,
        "17.5k": 10,
        "20k": 13,
        "22.5k": 25,
        "25k": 9,
        "27.5k": 4,
        "30k": 15,
        "32.5k": 25,
        "35k": 8,
        "37.5k": 6,
        "40k": 11,
        "42.5k": 22,
        "45k": 17,
        "47.5k": 16,
        "50k": 16,
        "52.5k": 15,
        "55k": 11,
        "57.5k": 7,
        "60k": 13,
        "62.5k": 11,
        "65k": 27,
        "67.5k": 10,
        "70k": 4,
        "72.5k": 6,
        "75k": 11,
        "77.5k": 3,
        "80k": 10,
        "82.5k": 6,
        "85k": 5,
        "87.5k": 12,
        "90k": 8,
        "92.5k": 2,
        "95k": 0,
        "97.5k": 8,
        "100k": 5,
        "200k": "81"
      },
      otherRelativefm: {
        "2.5k": 15,
        "5k": 13,
        "7.5k": 10,
        "10k": 19,
        "12.5k": 14,
        "15k": 15,
        "17.5k": 33,
        "20k": 34,
        "22.5k": 27,
        "25k": 24,
        "27.5k": 44,
        "30k": 31,
        "32.5k": 33,
        "35k": 37,
        "37.5k": 29,
        "40k": 24,
        "42.5k": 25,
        "45k": 17,
        "47.5k": 27,
        "50k": 22,
        "52.5k": 18,
        "55k": 8,
        "57.5k": 37,
        "60k": 19,
        "62.5k": 28,
        "65k": 15,
        "67.5k": 25,
        "70k": 24,
        "72.5k": 22,
        "75k": 14,
        "77.5k": 18,
        "80k": 17,
        "82.5k": 18,
        "85k": 15,
        "87.5k": 15,
        "90k": 14,
        "92.5k": 16,
        "95k": 13,
        "97.5k": 27,
        "100k": 11,
        "200k": "251"
      },
      nonRelativefm: {
        "2.5k": 10,
        "5k": 7,
        "7.5k": 6,
        "10k": 15,
        "12.5k": 9,
        "15k": 13,
        "17.5k": 14,
        "20k": 20,
        "22.5k": 24,
        "25k": 17,
        "27.5k": 18,
        "30k": 15,
        "32.5k": 15,
        "35k": 9,
        "37.5k": 11,
        "40k": 18,
        "42.5k": 32,
        "45k": 15,
        "47.5k": 23,
        "50k": 15,
        "52.5k": 28,
        "55k": 15,
        "57.5k": 18,
        "60k": 14,
        "62.5k": 21,
        "65k": 14,
        "67.5k": 21,
        "70k": 7,
        "72.5k": 18,
        "75k": 22,
        "77.5k": 19,
        "80k": 7,
        "82.5k": 21,
        "85k": 2,
        "87.5k": 6,
        "90k": 7,
        "92.5k": 25,
        "95k": 16,
        "97.5k": 3,
        "100k": 4,
        "200k": "210"
      }
    },
    "74": {
      houseLoitererm: {
        "2.5k": 78,
        "5k": 11,
        "7.5k": 57,
        "10k": 116,
        "12.5k": 200,
        "15k": 182,
        "17.5k": 203,
        "20k": 198,
        "22.5k": 255,
        "25k": 254,
        "27.5k": 222,
        "30k": 174,
        "32.5k": 234,
        "35k": 190,
        "37.5k": 187,
        "40k": 181,
        "42.5k": 182,
        "45k": 150,
        "47.5k": 149,
        "50k": 152,
        "52.5k": 173,
        "55k": 122,
        "57.5k": 132,
        "60k": 111,
        "62.5k": 138,
        "65k": 109,
        "67.5k": 109,
        "70k": 87,
        "72.5k": 96,
        "75k": 111,
        "77.5k": 88,
        "80k": 96,
        "82.5k": 67,
        "85k": 73,
        "87.5k": 83,
        "90k": 65,
        "92.5k": 80,
        "95k": 53,
        "97.5k": 57,
        "100k": 58,
        "200k": "1,180"
      },
      spousem: {
        "2.5k": 22,
        "5k": 9,
        "7.5k": 17,
        "10k": 12,
        "12.5k": 20,
        "15k": 34,
        "17.5k": 45,
        "20k": 53,
        "22.5k": 82,
        "25k": 93,
        "27.5k": 64,
        "30k": 82,
        "32.5k": 79,
        "35k": 88,
        "37.5k": 65,
        "40k": 84,
        "42.5k": 75,
        "45k": 81,
        "47.5k": 71,
        "50k": 29,
        "52.5k": 51,
        "55k": 55,
        "57.5k": 52,
        "60k": 61,
        "62.5k": 53,
        "65k": 75,
        "67.5k": 44,
        "70k": 40,
        "72.5k": 67,
        "75k": 27,
        "77.5k": 37,
        "80k": 35,
        "82.5k": 28,
        "85k": 26,
        "87.5k": 37,
        "90k": 26,
        "92.5k": 39,
        "95k": 23,
        "97.5k": 19,
        "100k": 32,
        "200k": "480"
      },
      childm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 1,
        "15k": 0,
        "17.5k": 0,
        "20k": 0,
        "22.5k": 0,
        "25k": 2,
        "27.5k": 3,
        "30k": 2,
        "32.5k": 2,
        "35k": 1,
        "37.5k": 0,
        "40k": 0,
        "42.5k": 2,
        "45k": 4,
        "47.5k": 0,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 0,
        "60k": 0,
        "62.5k": 1,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 1,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 0,
        "90k": 0,
        "92.5k": 0,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "0"
      },
      otherRelativem: {
        "2.5k": 1,
        "5k": 0,
        "7.5k": 0,
        "10k": 8,
        "12.5k": 0,
        "15k": 3,
        "17.5k": 6,
        "20k": 6,
        "22.5k": 16,
        "25k": 7,
        "27.5k": 7,
        "30k": 7,
        "32.5k": 8,
        "35k": 5,
        "37.5k": 12,
        "40k": 2,
        "42.5k": 14,
        "45k": 15,
        "47.5k": 8,
        "50k": 2,
        "52.5k": 5,
        "55k": 5,
        "57.5k": 5,
        "60k": 16,
        "62.5k": 14,
        "65k": 3,
        "67.5k": 7,
        "70k": 5,
        "72.5k": 5,
        "75k": 6,
        "77.5k": 7,
        "80k": 0,
        "82.5k": 9,
        "85k": 7,
        "87.5k": 3,
        "90k": 7,
        "92.5k": 10,
        "95k": 2,
        "97.5k": 2,
        "100k": 0,
        "200k": "104"
      },
      nonRelativem: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 2,
        "10k": 2,
        "12.5k": 2,
        "15k": 5,
        "17.5k": 2,
        "20k": 0,
        "22.5k": 5,
        "25k": 7,
        "27.5k": 10,
        "30k": 0,
        "32.5k": 8,
        "35k": 6,
        "37.5k": 5,
        "40k": 4,
        "42.5k": 7,
        "45k": 2,
        "47.5k": 3,
        "50k": 17,
        "52.5k": 2,
        "55k": 10,
        "57.5k": 12,
        "60k": 3,
        "62.5k": 7,
        "65k": 3,
        "67.5k": 4,
        "70k": 0,
        "72.5k": 4,
        "75k": 1,
        "77.5k": 2,
        "80k": 0,
        "82.5k": 3,
        "85k": 0,
        "87.5k": 3,
        "90k": 3,
        "92.5k": 2,
        "95k": 2,
        "97.5k": 0,
        "100k": 1,
        "200k": "13"
      },
      houseLoitererfm: {
        "2.5k": 95,
        "5k": 69,
        "7.5k": 138,
        "10k": 405,
        "12.5k": 427,
        "15k": 358,
        "17.5k": 327,
        "20k": 279,
        "22.5k": 324,
        "25k": 324,
        "27.5k": 264,
        "30k": 268,
        "32.5k": 200,
        "35k": 215,
        "37.5k": 185,
        "40k": 175,
        "42.5k": 149,
        "45k": 146,
        "47.5k": 137,
        "50k": 87,
        "52.5k": 149,
        "55k": 105,
        "57.5k": 94,
        "60k": 76,
        "62.5k": 93,
        "65k": 75,
        "67.5k": 68,
        "70k": 47,
        "72.5k": 68,
        "75k": 42,
        "77.5k": 61,
        "80k": 68,
        "82.5k": 56,
        "85k": 29,
        "87.5k": 48,
        "90k": 29,
        "92.5k": 46,
        "95k": 33,
        "97.5k": 33,
        "100k": 33,
        "200k": "556"
      },
      spousefm: {
        "2.5k": 57,
        "5k": 10,
        "7.5k": 25,
        "10k": 16,
        "12.5k": 61,
        "15k": 59,
        "17.5k": 62,
        "20k": 108,
        "22.5k": 141,
        "25k": 162,
        "27.5k": 129,
        "30k": 153,
        "32.5k": 140,
        "35k": 148,
        "37.5k": 137,
        "40k": 120,
        "42.5k": 105,
        "45k": 84,
        "47.5k": 89,
        "50k": 114,
        "52.5k": 96,
        "55k": 75,
        "57.5k": 69,
        "60k": 76,
        "62.5k": 90,
        "65k": 80,
        "67.5k": 77,
        "70k": 53,
        "72.5k": 50,
        "75k": 46,
        "77.5k": 51,
        "80k": 79,
        "82.5k": 35,
        "85k": 53,
        "87.5k": 40,
        "90k": 47,
        "92.5k": 34,
        "95k": 34,
        "97.5k": 38,
        "100k": 29,
        "200k": "645"
      },
      childfm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 0,
        "12.5k": 2,
        "15k": 1,
        "17.5k": 3,
        "20k": 0,
        "22.5k": 2,
        "25k": 0,
        "27.5k": 0,
        "30k": 0,
        "32.5k": 0,
        "35k": 0,
        "37.5k": 1,
        "40k": 0,
        "42.5k": 1,
        "45k": 0,
        "47.5k": 3,
        "50k": 0,
        "52.5k": 0,
        "55k": 0,
        "57.5k": 3,
        "60k": 0,
        "62.5k": 0,
        "65k": 0,
        "67.5k": 0,
        "70k": 0,
        "72.5k": 0,
        "75k": 0,
        "77.5k": 0,
        "80k": 0,
        "82.5k": 0,
        "85k": 0,
        "87.5k": 2,
        "90k": 0,
        "92.5k": 3,
        "95k": 0,
        "97.5k": 0,
        "100k": 0,
        "200k": "3"
      },
      otherRelativefm: {
        "2.5k": 3,
        "5k": 2,
        "7.5k": 0,
        "10k": 10,
        "12.5k": 14,
        "15k": 16,
        "17.5k": 14,
        "20k": 11,
        "22.5k": 21,
        "25k": 25,
        "27.5k": 19,
        "30k": 8,
        "32.5k": 25,
        "35k": 21,
        "37.5k": 25,
        "40k": 11,
        "42.5k": 17,
        "45k": 13,
        "47.5k": 20,
        "50k": 14,
        "52.5k": 16,
        "55k": 8,
        "57.5k": 10,
        "60k": 18,
        "62.5k": 22,
        "65k": 15,
        "67.5k": 16,
        "70k": 11,
        "72.5k": 12,
        "75k": 2,
        "77.5k": 28,
        "80k": 12,
        "82.5k": 15,
        "85k": 14,
        "87.5k": 10,
        "90k": 15,
        "92.5k": 6,
        "95k": 12,
        "97.5k": 8,
        "100k": 8,
        "200k": "189"
      },
      nonRelativefm: {
        "2.5k": 0,
        "5k": 0,
        "7.5k": 0,
        "10k": 2,
        "12.5k": 1,
        "15k": 2,
        "17.5k": 7,
        "20k": 3,
        "22.5k": 4,
        "25k": 8,
        "27.5k": 11,
        "30k": 4,
        "32.5k": 3,
        "35k": 10,
        "37.5k": 6,
        "40k": 0,
        "42.5k": 1,
        "45k": 2,
        "47.5k": 6,
        "50k": 8,
        "52.5k": 6,
        "55k": 3,
        "57.5k": 8,
        "60k": 1,
        "62.5k": 0,
        "65k": 3,
        "67.5k": 1,
        "70k": 0,
        "72.5k": 0,
        "75k": 2,
        "77.5k": 4,
        "80k": 1,
        "82.5k": 2,
        "85k": 0,
        "87.5k": 4,
        "90k": 0,
        "92.5k": 3,
        "95k": 1,
        "97.5k": 2,
        "100k": 2,
        "200k": "27"
      }
    }
  },
  "2009": {
    "17": {
      houseLoitererm: {
        "5k": 8,
        "10k": 6,
        "15k": 5,
        "20k": 5,
        "25k": 7,
        "30k": 6,
        "35k": 9,
        "40k": 6,
        "45k": 6,
        "50k": 5,
        "55k": 3,
        "60k": 1,
        "65k": 3,
        "70k": 0,
        "75k": 3,
        "80k": 2,
        "85k": 2,
        "90k": 2,
        "95k": 1,
        "100k": 2,
        "105k": 5,
        "110k": 2,
        "115k": 4,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 2,
        "140k": 2,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 1,
        "50k": 0,
        "55k": 0,
        "60k": 2,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 2,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 246,
        "10k": 284,
        "15k": 359,
        "20k": 458,
        "25k": 470,
        "30k": 504,
        "35k": 527,
        "40k": 490,
        "45k": 515,
        "50k": 445,
        "55k": 488,
        "60k": 455,
        "65k": 469,
        "70k": 384,
        "75k": 368,
        "80k": 367,
        "85k": 374,
        "90k": 321,
        "95k": 278,
        "100k": 243,
        "105k": 304,
        "110k": 203,
        "115k": 238,
        "120k": 188,
        "125k": 200,
        "130k": 168,
        "135k": 129,
        "140k": 124,
        "145k": 118,
        "150k": 103,
        "155k": 161,
        "160k": 87,
        "165k": 116,
        "170k": 60,
        "175k": 72,
        "180k": 44,
        "185k": 54,
        "190k": 57,
        "195k": 47,
        "200k": 38,
        "350k": 719
      },
      otherRelativem: {
        "5k": 42,
        "10k": 37,
        "15k": 55,
        "20k": 74,
        "25k": 78,
        "30k": 70,
        "35k": 68,
        "40k": 59,
        "45k": 51,
        "50k": 58,
        "55k": 58,
        "60k": 49,
        "65k": 45,
        "70k": 28,
        "75k": 47,
        "80k": 34,
        "85k": 14,
        "90k": 24,
        "95k": 21,
        "100k": 18,
        "105k": 14,
        "110k": 24,
        "115k": 20,
        "120k": 7,
        "125k": 7,
        "130k": 7,
        "135k": 5,
        "140k": 2,
        "145k": 6,
        "150k": 8,
        "155k": 5,
        "160k": 7,
        "165k": 5,
        "170k": 3,
        "175k": 5,
        "180k": 0,
        "185k": 5,
        "190k": 0,
        "195k": 1,
        "200k": 3,
        "350k": 17
      },
      nonRelativem: {
        "5k": 5,
        "10k": 5,
        "15k": 6,
        "20k": 9,
        "25k": 16,
        "30k": 10,
        "35k": 10,
        "40k": 22,
        "45k": 9,
        "50k": 15,
        "55k": 6,
        "60k": 5,
        "65k": 4,
        "70k": 10,
        "75k": 15,
        "80k": 5,
        "85k": 15,
        "90k": 7,
        "95k": 9,
        "100k": 3,
        "105k": 8,
        "110k": 1,
        "115k": 5,
        "120k": 2,
        "125k": 3,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 2,
        "150k": 2,
        "155k": 4,
        "160k": 2,
        "165k": 4,
        "170k": 1,
        "175k": 0,
        "180k": 1,
        "185k": 4,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      houseLoitererfm: {
        "5k": 7,
        "10k": 4,
        "15k": 4,
        "20k": 12,
        "25k": 5,
        "30k": 17,
        "35k": 3,
        "40k": 2,
        "45k": 5,
        "50k": 8,
        "55k": 2,
        "60k": 2,
        "65k": 4,
        "70k": 1,
        "75k": 4,
        "80k": 5,
        "85k": 4,
        "90k": 3,
        "95k": 0,
        "100k": 0,
        "105k": 2,
        "110k": 0,
        "115k": 3,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      spousefm: {
        "5k": 4,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 2,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 1,
        "50k": 0,
        "55k": 0,
        "60k": 1,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 267,
        "10k": 254,
        "15k": 397,
        "20k": 437,
        "25k": 462,
        "30k": 445,
        "35k": 448,
        "40k": 461,
        "45k": 488,
        "50k": 467,
        "55k": 446,
        "60k": 486,
        "65k": 455,
        "70k": 353,
        "75k": 362,
        "80k": 363,
        "85k": 384,
        "90k": 296,
        "95k": 298,
        "100k": 204,
        "105k": 287,
        "110k": 238,
        "115k": 195,
        "120k": 175,
        "125k": 178,
        "130k": 175,
        "135k": 134,
        "140k": 101,
        "145k": 110,
        "150k": 110,
        "155k": 133,
        "160k": 79,
        "165k": 105,
        "170k": 74,
        "175k": 51,
        "180k": 50,
        "185k": 70,
        "190k": 54,
        "195k": 60,
        "200k": 23,
        "350k": 659
      },
      otherRelativefm: {
        "5k": 29,
        "10k": 38,
        "15k": 48,
        "20k": 44,
        "25k": 47,
        "30k": 68,
        "35k": 63,
        "40k": 68,
        "45k": 50,
        "50k": 26,
        "55k": 45,
        "60k": 39,
        "65k": 46,
        "70k": 37,
        "75k": 30,
        "80k": 32,
        "85k": 19,
        "90k": 29,
        "95k": 21,
        "100k": 16,
        "105k": 16,
        "110k": 15,
        "115k": 18,
        "120k": 19,
        "125k": 9,
        "130k": 0,
        "135k": 5,
        "140k": 12,
        "145k": 6,
        "150k": 1,
        "155k": 6,
        "160k": 5,
        "165k": 3,
        "170k": 2,
        "175k": 1,
        "180k": 1,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 20
      },
      nonRelativefm: {
        "5k": 7,
        "10k": 4,
        "15k": 19,
        "20k": 8,
        "25k": 14,
        "30k": 22,
        "35k": 15,
        "40k": 22,
        "45k": 15,
        "50k": 30,
        "55k": 15,
        "60k": 16,
        "65k": 14,
        "70k": 10,
        "75k": 6,
        "80k": 4,
        "85k": 22,
        "90k": 3,
        "95k": 9,
        "100k": 10,
        "105k": 1,
        "110k": 11,
        "115k": 3,
        "120k": 5,
        "125k": 2,
        "130k": 8,
        "135k": 3,
        "140k": 0,
        "145k": 0,
        "150k": 3,
        "155k": 4,
        "160k": 0,
        "165k": 1,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 10
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 108,
        "10k": 66,
        "15k": 92,
        "20k": 82,
        "25k": 95,
        "30k": 89,
        "35k": 74,
        "40k": 56,
        "45k": 62,
        "50k": 33,
        "55k": 32,
        "60k": 35,
        "65k": 19,
        "70k": 22,
        "75k": 16,
        "80k": 15,
        "85k": 7,
        "90k": 7,
        "95k": 2,
        "100k": 4,
        "105k": 8,
        "110k": 10,
        "115k": 8,
        "120k": 7,
        "125k": 11,
        "130k": 7,
        "135k": 0,
        "140k": 4,
        "145k": 3,
        "150k": 5,
        "155k": 1,
        "160k": 3,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 4,
        "185k": 3,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 9
      },
      spousem: {
        "5k": 7,
        "10k": 9,
        "15k": 7,
        "20k": 4,
        "25k": 6,
        "30k": 7,
        "35k": 6,
        "40k": 7,
        "45k": 4,
        "50k": 10,
        "55k": 3,
        "60k": 2,
        "65k": 2,
        "70k": 5,
        "75k": 2,
        "80k": 0,
        "85k": 0,
        "90k": 1,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 78,
        "10k": 111,
        "15k": 112,
        "20k": 186,
        "25k": 204,
        "30k": 184,
        "35k": 213,
        "40k": 229,
        "45k": 239,
        "50k": 248,
        "55k": 267,
        "60k": 228,
        "65k": 259,
        "70k": 226,
        "75k": 188,
        "80k": 242,
        "85k": 202,
        "90k": 187,
        "95k": 173,
        "100k": 151,
        "105k": 158,
        "110k": 162,
        "115k": 108,
        "120k": 153,
        "125k": 98,
        "130k": 101,
        "135k": 109,
        "140k": 86,
        "145k": 76,
        "150k": 87,
        "155k": 70,
        "160k": 53,
        "165k": 69,
        "170k": 33,
        "175k": 50,
        "180k": 59,
        "185k": 35,
        "190k": 27,
        "195k": 28,
        "200k": 20,
        "350k": 402
      },
      otherRelativem: {
        "5k": 21,
        "10k": 28,
        "15k": 23,
        "20k": 62,
        "25k": 54,
        "30k": 57,
        "35k": 60,
        "40k": 50,
        "45k": 44,
        "50k": 39,
        "55k": 41,
        "60k": 49,
        "65k": 34,
        "70k": 21,
        "75k": 28,
        "80k": 19,
        "85k": 14,
        "90k": 41,
        "95k": 23,
        "100k": 18,
        "105k": 19,
        "110k": 9,
        "115k": 10,
        "120k": 9,
        "125k": 4,
        "130k": 5,
        "135k": 8,
        "140k": 5,
        "145k": 10,
        "150k": 7,
        "155k": 2,
        "160k": 2,
        "165k": 8,
        "170k": 4,
        "175k": 3,
        "180k": 2,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 3,
        "350k": 20
      },
      nonRelativem: {
        "5k": 39,
        "10k": 12,
        "15k": 32,
        "20k": 19,
        "25k": 66,
        "30k": 62,
        "35k": 36,
        "40k": 33,
        "45k": 60,
        "50k": 41,
        "55k": 37,
        "60k": 36,
        "65k": 22,
        "70k": 14,
        "75k": 48,
        "80k": 20,
        "85k": 12,
        "90k": 7,
        "95k": 9,
        "100k": 11,
        "105k": 20,
        "110k": 3,
        "115k": 4,
        "120k": 12,
        "125k": 12,
        "130k": 5,
        "135k": 8,
        "140k": 6,
        "145k": 3,
        "150k": 3,
        "155k": 6,
        "160k": 6,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 2,
        "185k": 7,
        "190k": 2,
        "195k": 0,
        "200k": 4,
        "350k": 10
      },
      houseLoitererfm: {
        "5k": 115,
        "10k": 142,
        "15k": 133,
        "20k": 102,
        "25k": 115,
        "30k": 73,
        "35k": 73,
        "40k": 73,
        "45k": 54,
        "50k": 52,
        "55k": 36,
        "60k": 35,
        "65k": 40,
        "70k": 11,
        "75k": 27,
        "80k": 25,
        "85k": 16,
        "90k": 9,
        "95k": 5,
        "100k": 2,
        "105k": 8,
        "110k": 2,
        "115k": 4,
        "120k": 8,
        "125k": 0,
        "130k": 5,
        "135k": 3,
        "140k": 2,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 1,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      spousefm: {
        "5k": 4,
        "10k": 9,
        "15k": 19,
        "20k": 6,
        "25k": 22,
        "30k": 20,
        "35k": 29,
        "40k": 13,
        "45k": 11,
        "50k": 11,
        "55k": 23,
        "60k": 7,
        "65k": 7,
        "70k": 5,
        "75k": 6,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 4,
        "100k": 1,
        "105k": 1,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 2,
        "130k": 0,
        "135k": 1,
        "140k": 0,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 4,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 1
      },
      childfm: {
        "5k": 67,
        "10k": 93,
        "15k": 140,
        "20k": 143,
        "25k": 149,
        "30k": 164,
        "35k": 204,
        "40k": 198,
        "45k": 200,
        "50k": 182,
        "55k": 184,
        "60k": 207,
        "65k": 203,
        "70k": 216,
        "75k": 166,
        "80k": 183,
        "85k": 173,
        "90k": 151,
        "95k": 139,
        "100k": 128,
        "105k": 158,
        "110k": 85,
        "115k": 122,
        "120k": 108,
        "125k": 115,
        "130k": 127,
        "135k": 100,
        "140k": 93,
        "145k": 79,
        "150k": 55,
        "155k": 85,
        "160k": 51,
        "165k": 79,
        "170k": 49,
        "175k": 28,
        "180k": 37,
        "185k": 35,
        "190k": 18,
        "195k": 30,
        "200k": 23,
        "350k": 364
      },
      otherRelativefm: {
        "5k": 34,
        "10k": 22,
        "15k": 41,
        "20k": 26,
        "25k": 37,
        "30k": 48,
        "35k": 55,
        "40k": 46,
        "45k": 36,
        "50k": 30,
        "55k": 41,
        "60k": 25,
        "65k": 39,
        "70k": 32,
        "75k": 7,
        "80k": 19,
        "85k": 19,
        "90k": 17,
        "95k": 15,
        "100k": 12,
        "105k": 2,
        "110k": 13,
        "115k": 2,
        "120k": 2,
        "125k": 5,
        "130k": 15,
        "135k": 4,
        "140k": 4,
        "145k": 0,
        "150k": 10,
        "155k": 4,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 6,
        "180k": 0,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 13
      },
      nonRelativefm: {
        "5k": 53,
        "10k": 36,
        "15k": 74,
        "20k": 62,
        "25k": 95,
        "30k": 55,
        "35k": 65,
        "40k": 57,
        "45k": 68,
        "50k": 58,
        "55k": 58,
        "60k": 53,
        "65k": 45,
        "70k": 24,
        "75k": 33,
        "80k": 13,
        "85k": 21,
        "90k": 17,
        "95k": 6,
        "100k": 11,
        "105k": 15,
        "110k": 7,
        "115k": 11,
        "120k": 13,
        "125k": 5,
        "130k": 0,
        "135k": 6,
        "140k": 1,
        "145k": 3,
        "150k": 1,
        "155k": 5,
        "160k": 7,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 1,
        "190k": 1,
        "195k": 0,
        "200k": 2,
        "350k": 8
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 108,
        "10k": 80,
        "15k": 80,
        "20k": 121,
        "25k": 133,
        "30k": 126,
        "35k": 126,
        "40k": 126,
        "45k": 149,
        "50k": 95,
        "55k": 114,
        "60k": 64,
        "65k": 69,
        "70k": 57,
        "75k": 41,
        "80k": 31,
        "85k": 37,
        "90k": 20,
        "95k": 25,
        "100k": 19,
        "105k": 24,
        "110k": 12,
        "115k": 10,
        "120k": 18,
        "125k": 7,
        "130k": 2,
        "135k": 11,
        "140k": 5,
        "145k": 9,
        "150k": 2,
        "155k": 8,
        "160k": 7,
        "165k": 2,
        "170k": 6,
        "175k": 0,
        "180k": 2,
        "185k": 2,
        "190k": 2,
        "195k": 0,
        "200k": 5,
        "350k": 16
      },
      spousem: {
        "5k": 3,
        "10k": 13,
        "15k": 21,
        "20k": 14,
        "25k": 15,
        "30k": 24,
        "35k": 18,
        "40k": 22,
        "45k": 30,
        "50k": 17,
        "55k": 13,
        "60k": 9,
        "65k": 18,
        "70k": 5,
        "75k": 4,
        "80k": 2,
        "85k": 7,
        "90k": 1,
        "95k": 7,
        "100k": 6,
        "105k": 1,
        "110k": 7,
        "115k": 1,
        "120k": 4,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      childm: {
        "5k": 31,
        "10k": 23,
        "15k": 48,
        "20k": 53,
        "25k": 84,
        "30k": 61,
        "35k": 81,
        "40k": 87,
        "45k": 60,
        "50k": 78,
        "55k": 127,
        "60k": 76,
        "65k": 92,
        "70k": 88,
        "75k": 97,
        "80k": 129,
        "85k": 110,
        "90k": 68,
        "95k": 87,
        "100k": 89,
        "105k": 53,
        "110k": 52,
        "115k": 57,
        "120k": 75,
        "125k": 60,
        "130k": 59,
        "135k": 52,
        "140k": 31,
        "145k": 35,
        "150k": 41,
        "155k": 39,
        "160k": 30,
        "165k": 44,
        "170k": 31,
        "175k": 27,
        "180k": 9,
        "185k": 23,
        "190k": 28,
        "195k": 17,
        "200k": 11,
        "350k": 246
      },
      otherRelativem: {
        "5k": 18,
        "10k": 24,
        "15k": 24,
        "20k": 32,
        "25k": 32,
        "30k": 27,
        "35k": 42,
        "40k": 47,
        "45k": 23,
        "50k": 43,
        "55k": 25,
        "60k": 25,
        "65k": 24,
        "70k": 11,
        "75k": 28,
        "80k": 9,
        "85k": 12,
        "90k": 23,
        "95k": 4,
        "100k": 13,
        "105k": 10,
        "110k": 10,
        "115k": 8,
        "120k": 9,
        "125k": 1,
        "130k": 8,
        "135k": 10,
        "140k": 7,
        "145k": 6,
        "150k": 8,
        "155k": 10,
        "160k": 4,
        "165k": 6,
        "170k": 13,
        "175k": 6,
        "180k": 5,
        "185k": 10,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 10
      },
      nonRelativem: {
        "5k": 36,
        "10k": 35,
        "15k": 37,
        "20k": 59,
        "25k": 55,
        "30k": 64,
        "35k": 50,
        "40k": 55,
        "45k": 58,
        "50k": 55,
        "55k": 47,
        "60k": 44,
        "65k": 38,
        "70k": 21,
        "75k": 21,
        "80k": 21,
        "85k": 47,
        "90k": 23,
        "95k": 29,
        "100k": 32,
        "105k": 16,
        "110k": 17,
        "115k": 15,
        "120k": 16,
        "125k": 13,
        "130k": 10,
        "135k": 12,
        "140k": 3,
        "145k": 19,
        "150k": 9,
        "155k": 1,
        "160k": 5,
        "165k": 5,
        "170k": 4,
        "175k": 0,
        "180k": 3,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 7,
        "350k": 8
      },
      houseLoitererfm: {
        "5k": 202,
        "10k": 169,
        "15k": 204,
        "20k": 210,
        "25k": 149,
        "30k": 166,
        "35k": 122,
        "40k": 120,
        "45k": 98,
        "50k": 100,
        "55k": 98,
        "60k": 71,
        "65k": 60,
        "70k": 39,
        "75k": 45,
        "80k": 25,
        "85k": 23,
        "90k": 8,
        "95k": 28,
        "100k": 23,
        "105k": 19,
        "110k": 19,
        "115k": 11,
        "120k": 14,
        "125k": 18,
        "130k": 5,
        "135k": 4,
        "140k": 0,
        "145k": 0,
        "150k": 6,
        "155k": 4,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 1,
        "190k": 4,
        "195k": 0,
        "200k": 2,
        "350k": 7
      },
      spousefm: {
        "5k": 11,
        "10k": 11,
        "15k": 30,
        "20k": 35,
        "25k": 29,
        "30k": 31,
        "35k": 47,
        "40k": 58,
        "45k": 76,
        "50k": 46,
        "55k": 71,
        "60k": 36,
        "65k": 45,
        "70k": 46,
        "75k": 17,
        "80k": 20,
        "85k": 10,
        "90k": 19,
        "95k": 4,
        "100k": 12,
        "105k": 12,
        "110k": 11,
        "115k": 3,
        "120k": 9,
        "125k": 12,
        "130k": 0,
        "135k": 4,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 4,
        "160k": 0,
        "165k": 1,
        "170k": 1,
        "175k": 0,
        "180k": 6,
        "185k": 4,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 9
      },
      childfm: {
        "5k": 7,
        "10k": 15,
        "15k": 33,
        "20k": 54,
        "25k": 64,
        "30k": 64,
        "35k": 65,
        "40k": 67,
        "45k": 55,
        "50k": 79,
        "55k": 51,
        "60k": 87,
        "65k": 84,
        "70k": 64,
        "75k": 65,
        "80k": 68,
        "85k": 82,
        "90k": 68,
        "95k": 67,
        "100k": 78,
        "105k": 67,
        "110k": 46,
        "115k": 66,
        "120k": 36,
        "125k": 43,
        "130k": 43,
        "135k": 26,
        "140k": 37,
        "145k": 28,
        "150k": 43,
        "155k": 30,
        "160k": 24,
        "165k": 20,
        "170k": 20,
        "175k": 22,
        "180k": 25,
        "185k": 28,
        "190k": 5,
        "195k": 14,
        "200k": 10,
        "350k": 141
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 3,
        "15k": 27,
        "20k": 15,
        "25k": 19,
        "30k": 18,
        "35k": 26,
        "40k": 31,
        "45k": 20,
        "50k": 29,
        "55k": 27,
        "60k": 29,
        "65k": 25,
        "70k": 9,
        "75k": 19,
        "80k": 5,
        "85k": 7,
        "90k": 13,
        "95k": 6,
        "100k": 14,
        "105k": 3,
        "110k": 10,
        "115k": 6,
        "120k": 7,
        "125k": 5,
        "130k": 2,
        "135k": 6,
        "140k": 1,
        "145k": 4,
        "150k": 0,
        "155k": 1,
        "160k": 4,
        "165k": 5,
        "170k": 2,
        "175k": 1,
        "180k": 3,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      nonRelativefm: {
        "5k": 26,
        "10k": 28,
        "15k": 36,
        "20k": 48,
        "25k": 44,
        "30k": 73,
        "35k": 68,
        "40k": 53,
        "45k": 60,
        "50k": 54,
        "55k": 49,
        "60k": 43,
        "65k": 52,
        "70k": 40,
        "75k": 46,
        "80k": 32,
        "85k": 43,
        "90k": 24,
        "95k": 23,
        "100k": 13,
        "105k": 15,
        "110k": 2,
        "115k": 12,
        "120k": 18,
        "125k": 13,
        "130k": 14,
        "135k": 12,
        "140k": 8,
        "145k": 3,
        "150k": 17,
        "155k": 4,
        "160k": 3,
        "165k": 6,
        "170k": 5,
        "175k": 2,
        "180k": 5,
        "185k": 8,
        "190k": 13,
        "195k": 0,
        "200k": 0,
        "350k": 26
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 108,
        "10k": 98,
        "15k": 161,
        "20k": 183,
        "25k": 267,
        "30k": 281,
        "35k": 313,
        "40k": 270,
        "45k": 313,
        "50k": 249,
        "55k": 264,
        "60k": 247,
        "65k": 239,
        "70k": 185,
        "75k": 185,
        "80k": 150,
        "85k": 197,
        "90k": 103,
        "95k": 101,
        "100k": 91,
        "105k": 111,
        "110k": 69,
        "115k": 54,
        "120k": 26,
        "125k": 61,
        "130k": 55,
        "135k": 36,
        "140k": 30,
        "145k": 26,
        "150k": 22,
        "155k": 38,
        "160k": 23,
        "165k": 25,
        "170k": 9,
        "175k": 6,
        "180k": 20,
        "185k": 12,
        "190k": 3,
        "195k": 14,
        "200k": 5,
        "350k": 88
      },
      spousem: {
        "5k": 24,
        "10k": 12,
        "15k": 48,
        "20k": 48,
        "25k": 54,
        "30k": 62,
        "35k": 60,
        "40k": 75,
        "45k": 65,
        "50k": 61,
        "55k": 67,
        "60k": 57,
        "65k": 80,
        "70k": 57,
        "75k": 72,
        "80k": 54,
        "85k": 44,
        "90k": 37,
        "95k": 34,
        "100k": 32,
        "105k": 30,
        "110k": 25,
        "115k": 17,
        "120k": 7,
        "125k": 14,
        "130k": 13,
        "135k": 3,
        "140k": 8,
        "145k": 6,
        "150k": 4,
        "155k": 5,
        "160k": 9,
        "165k": 4,
        "170k": 4,
        "175k": 3,
        "180k": 1,
        "185k": 3,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 18
      },
      childm: {
        "5k": 22,
        "10k": 17,
        "15k": 55,
        "20k": 57,
        "25k": 94,
        "30k": 56,
        "35k": 75,
        "40k": 114,
        "45k": 63,
        "50k": 108,
        "55k": 99,
        "60k": 70,
        "65k": 77,
        "70k": 83,
        "75k": 93,
        "80k": 88,
        "85k": 84,
        "90k": 64,
        "95k": 57,
        "100k": 82,
        "105k": 52,
        "110k": 58,
        "115k": 64,
        "120k": 34,
        "125k": 68,
        "130k": 43,
        "135k": 35,
        "140k": 51,
        "145k": 45,
        "150k": 43,
        "155k": 32,
        "160k": 21,
        "165k": 23,
        "170k": 16,
        "175k": 30,
        "180k": 23,
        "185k": 13,
        "190k": 9,
        "195k": 21,
        "200k": 18,
        "350k": 196
      },
      otherRelativem: {
        "5k": 4,
        "10k": 11,
        "15k": 18,
        "20k": 36,
        "25k": 42,
        "30k": 39,
        "35k": 60,
        "40k": 43,
        "45k": 41,
        "50k": 41,
        "55k": 49,
        "60k": 38,
        "65k": 33,
        "70k": 28,
        "75k": 54,
        "80k": 40,
        "85k": 34,
        "90k": 28,
        "95k": 26,
        "100k": 27,
        "105k": 21,
        "110k": 14,
        "115k": 18,
        "120k": 22,
        "125k": 7,
        "130k": 20,
        "135k": 21,
        "140k": 10,
        "145k": 8,
        "150k": 27,
        "155k": 9,
        "160k": 11,
        "165k": 5,
        "170k": 2,
        "175k": 5,
        "180k": 6,
        "185k": 1,
        "190k": 3,
        "195k": 0,
        "200k": 0,
        "350k": 34
      },
      nonRelativem: {
        "5k": 32,
        "10k": 23,
        "15k": 38,
        "20k": 74,
        "25k": 68,
        "30k": 41,
        "35k": 83,
        "40k": 73,
        "45k": 64,
        "50k": 100,
        "55k": 87,
        "60k": 99,
        "65k": 81,
        "70k": 65,
        "75k": 84,
        "80k": 84,
        "85k": 68,
        "90k": 59,
        "95k": 44,
        "100k": 35,
        "105k": 33,
        "110k": 22,
        "115k": 25,
        "120k": 19,
        "125k": 32,
        "130k": 21,
        "135k": 18,
        "140k": 33,
        "145k": 12,
        "150k": 7,
        "155k": 15,
        "160k": 9,
        "165k": 13,
        "170k": 18,
        "175k": 4,
        "180k": 19,
        "185k": 16,
        "190k": 2,
        "195k": 10,
        "200k": 0,
        "350k": 51
      },
      houseLoitererfm: {
        "5k": 273,
        "10k": 240,
        "15k": 295,
        "20k": 264,
        "25k": 376,
        "30k": 288,
        "35k": 300,
        "40k": 252,
        "45k": 228,
        "50k": 260,
        "55k": 225,
        "60k": 192,
        "65k": 213,
        "70k": 142,
        "75k": 156,
        "80k": 150,
        "85k": 98,
        "90k": 90,
        "95k": 97,
        "100k": 84,
        "105k": 70,
        "110k": 56,
        "115k": 50,
        "120k": 37,
        "125k": 39,
        "130k": 43,
        "135k": 14,
        "140k": 23,
        "145k": 23,
        "150k": 13,
        "155k": 8,
        "160k": 9,
        "165k": 13,
        "170k": 6,
        "175k": 12,
        "180k": 6,
        "185k": 5,
        "190k": 6,
        "195k": 2,
        "200k": 3,
        "350k": 48
      },
      spousefm: {
        "5k": 31,
        "10k": 47,
        "15k": 56,
        "20k": 63,
        "25k": 109,
        "30k": 115,
        "35k": 132,
        "40k": 134,
        "45k": 128,
        "50k": 106,
        "55k": 151,
        "60k": 127,
        "65k": 112,
        "70k": 139,
        "75k": 148,
        "80k": 92,
        "85k": 123,
        "90k": 69,
        "95k": 95,
        "100k": 68,
        "105k": 80,
        "110k": 42,
        "115k": 25,
        "120k": 39,
        "125k": 44,
        "130k": 35,
        "135k": 33,
        "140k": 13,
        "145k": 27,
        "150k": 21,
        "155k": 16,
        "160k": 21,
        "165k": 16,
        "170k": 4,
        "175k": 10,
        "180k": 11,
        "185k": 7,
        "190k": 7,
        "195k": 4,
        "200k": 6,
        "350k": 64
      },
      childfm: {
        "5k": 17,
        "10k": 24,
        "15k": 41,
        "20k": 33,
        "25k": 55,
        "30k": 57,
        "35k": 60,
        "40k": 69,
        "45k": 48,
        "50k": 47,
        "55k": 55,
        "60k": 51,
        "65k": 35,
        "70k": 57,
        "75k": 60,
        "80k": 59,
        "85k": 37,
        "90k": 40,
        "95k": 41,
        "100k": 27,
        "105k": 32,
        "110k": 41,
        "115k": 61,
        "120k": 42,
        "125k": 37,
        "130k": 20,
        "135k": 22,
        "140k": 27,
        "145k": 19,
        "150k": 17,
        "155k": 20,
        "160k": 19,
        "165k": 13,
        "170k": 21,
        "175k": 16,
        "180k": 13,
        "185k": 9,
        "190k": 4,
        "195k": 6,
        "200k": 8,
        "350k": 137
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 11,
        "15k": 6,
        "20k": 20,
        "25k": 18,
        "30k": 10,
        "35k": 30,
        "40k": 22,
        "45k": 22,
        "50k": 21,
        "55k": 36,
        "60k": 25,
        "65k": 25,
        "70k": 17,
        "75k": 12,
        "80k": 21,
        "85k": 15,
        "90k": 18,
        "95k": 6,
        "100k": 10,
        "105k": 19,
        "110k": 7,
        "115k": 5,
        "120k": 3,
        "125k": 9,
        "130k": 12,
        "135k": 12,
        "140k": 4,
        "145k": 7,
        "150k": 6,
        "155k": 6,
        "160k": 6,
        "165k": 2,
        "170k": 1,
        "175k": 7,
        "180k": 1,
        "185k": 4,
        "190k": 3,
        "195k": 0,
        "200k": 2,
        "350k": 30
      },
      nonRelativefm: {
        "5k": 29,
        "10k": 11,
        "15k": 32,
        "20k": 33,
        "25k": 45,
        "30k": 35,
        "35k": 72,
        "40k": 60,
        "45k": 69,
        "50k": 49,
        "55k": 44,
        "60k": 56,
        "65k": 88,
        "70k": 58,
        "75k": 57,
        "80k": 56,
        "85k": 58,
        "90k": 31,
        "95k": 41,
        "100k": 22,
        "105k": 34,
        "110k": 50,
        "115k": 19,
        "120k": 21,
        "125k": 18,
        "130k": 22,
        "135k": 9,
        "140k": 6,
        "145k": 10,
        "150k": 2,
        "155k": 14,
        "160k": 12,
        "165k": 3,
        "170k": 5,
        "175k": 9,
        "180k": 10,
        "185k": 7,
        "190k": 2,
        "195k": 3,
        "200k": 2,
        "350k": 49
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 92,
        "10k": 128,
        "15k": 138,
        "20k": 181,
        "25k": 227,
        "30k": 249,
        "35k": 301,
        "40k": 229,
        "45k": 244,
        "50k": 239,
        "55k": 280,
        "60k": 206,
        "65k": 257,
        "70k": 204,
        "75k": 236,
        "80k": 158,
        "85k": 170,
        "90k": 129,
        "95k": 153,
        "100k": 119,
        "105k": 136,
        "110k": 101,
        "115k": 81,
        "120k": 90,
        "125k": 74,
        "130k": 63,
        "135k": 76,
        "140k": 34,
        "145k": 46,
        "150k": 29,
        "155k": 47,
        "160k": 48,
        "165k": 25,
        "170k": 5,
        "175k": 24,
        "180k": 12,
        "185k": 13,
        "190k": 14,
        "195k": 11,
        "200k": 17,
        "350k": 151
      },
      spousem: {
        "5k": 32,
        "10k": 31,
        "15k": 43,
        "20k": 49,
        "25k": 82,
        "30k": 98,
        "35k": 92,
        "40k": 97,
        "45k": 98,
        "50k": 99,
        "55k": 88,
        "60k": 104,
        "65k": 108,
        "70k": 75,
        "75k": 114,
        "80k": 94,
        "85k": 66,
        "90k": 52,
        "95k": 65,
        "100k": 63,
        "105k": 72,
        "110k": 39,
        "115k": 46,
        "120k": 37,
        "125k": 37,
        "130k": 28,
        "135k": 43,
        "140k": 22,
        "145k": 21,
        "150k": 16,
        "155k": 14,
        "160k": 5,
        "165k": 9,
        "170k": 8,
        "175k": 9,
        "180k": 10,
        "185k": 8,
        "190k": 8,
        "195k": 7,
        "200k": 5,
        "350k": 67
      },
      childm: {
        "5k": 4,
        "10k": 9,
        "15k": 27,
        "20k": 39,
        "25k": 39,
        "30k": 34,
        "35k": 66,
        "40k": 38,
        "45k": 48,
        "50k": 39,
        "55k": 51,
        "60k": 42,
        "65k": 43,
        "70k": 40,
        "75k": 33,
        "80k": 23,
        "85k": 47,
        "90k": 21,
        "95k": 30,
        "100k": 18,
        "105k": 25,
        "110k": 35,
        "115k": 20,
        "120k": 16,
        "125k": 31,
        "130k": 26,
        "135k": 26,
        "140k": 9,
        "145k": 18,
        "150k": 11,
        "155k": 11,
        "160k": 4,
        "165k": 9,
        "170k": 9,
        "175k": 12,
        "180k": 4,
        "185k": 6,
        "190k": 4,
        "195k": 8,
        "200k": 5,
        "350k": 56
      },
      otherRelativem: {
        "5k": 6,
        "10k": 11,
        "15k": 19,
        "20k": 16,
        "25k": 24,
        "30k": 30,
        "35k": 25,
        "40k": 33,
        "45k": 16,
        "50k": 24,
        "55k": 15,
        "60k": 38,
        "65k": 35,
        "70k": 28,
        "75k": 13,
        "80k": 28,
        "85k": 23,
        "90k": 14,
        "95k": 17,
        "100k": 12,
        "105k": 11,
        "110k": 11,
        "115k": 14,
        "120k": 17,
        "125k": 4,
        "130k": 2,
        "135k": 10,
        "140k": 7,
        "145k": 0,
        "150k": 8,
        "155k": 3,
        "160k": 6,
        "165k": 2,
        "170k": 0,
        "175k": 10,
        "180k": 3,
        "185k": 0,
        "190k": 0,
        "195k": 4,
        "200k": 2,
        "350k": 24
      },
      nonRelativem: {
        "5k": 10,
        "10k": 24,
        "15k": 41,
        "20k": 60,
        "25k": 33,
        "30k": 61,
        "35k": 50,
        "40k": 45,
        "45k": 33,
        "50k": 51,
        "55k": 49,
        "60k": 54,
        "65k": 47,
        "70k": 48,
        "75k": 31,
        "80k": 53,
        "85k": 23,
        "90k": 33,
        "95k": 25,
        "100k": 24,
        "105k": 27,
        "110k": 33,
        "115k": 22,
        "120k": 13,
        "125k": 10,
        "130k": 6,
        "135k": 8,
        "140k": 28,
        "145k": 14,
        "150k": 0,
        "155k": 13,
        "160k": 12,
        "165k": 16,
        "170k": 3,
        "175k": 5,
        "180k": 1,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 43
      },
      houseLoitererfm: {
        "5k": 220,
        "10k": 221,
        "15k": 242,
        "20k": 253,
        "25k": 308,
        "30k": 296,
        "35k": 266,
        "40k": 249,
        "45k": 239,
        "50k": 240,
        "55k": 192,
        "60k": 178,
        "65k": 221,
        "70k": 119,
        "75k": 173,
        "80k": 148,
        "85k": 107,
        "90k": 102,
        "95k": 91,
        "100k": 95,
        "105k": 101,
        "110k": 61,
        "115k": 70,
        "120k": 52,
        "125k": 50,
        "130k": 44,
        "135k": 59,
        "140k": 31,
        "145k": 27,
        "150k": 23,
        "155k": 25,
        "160k": 19,
        "165k": 19,
        "170k": 18,
        "175k": 30,
        "180k": 22,
        "185k": 19,
        "190k": 6,
        "195k": 14,
        "200k": 5,
        "350k": 118
      },
      spousefm: {
        "5k": 22,
        "10k": 54,
        "15k": 53,
        "20k": 84,
        "25k": 104,
        "30k": 121,
        "35k": 155,
        "40k": 117,
        "45k": 123,
        "50k": 161,
        "55k": 165,
        "60k": 133,
        "65k": 162,
        "70k": 136,
        "75k": 129,
        "80k": 123,
        "85k": 151,
        "90k": 128,
        "95k": 124,
        "100k": 113,
        "105k": 125,
        "110k": 75,
        "115k": 72,
        "120k": 105,
        "125k": 65,
        "130k": 58,
        "135k": 74,
        "140k": 36,
        "145k": 51,
        "150k": 36,
        "155k": 46,
        "160k": 26,
        "165k": 15,
        "170k": 13,
        "175k": 19,
        "180k": 15,
        "185k": 15,
        "190k": 12,
        "195k": 13,
        "200k": 22,
        "350k": 147
      },
      childfm: {
        "5k": 4,
        "10k": 5,
        "15k": 10,
        "20k": 29,
        "25k": 21,
        "30k": 32,
        "35k": 32,
        "40k": 37,
        "45k": 21,
        "50k": 16,
        "55k": 24,
        "60k": 30,
        "65k": 23,
        "70k": 24,
        "75k": 27,
        "80k": 28,
        "85k": 24,
        "90k": 19,
        "95k": 26,
        "100k": 15,
        "105k": 14,
        "110k": 11,
        "115k": 12,
        "120k": 20,
        "125k": 12,
        "130k": 8,
        "135k": 7,
        "140k": 5,
        "145k": 4,
        "150k": 6,
        "155k": 1,
        "160k": 6,
        "165k": 7,
        "170k": 2,
        "175k": 2,
        "180k": 4,
        "185k": 4,
        "190k": 4,
        "195k": 2,
        "200k": 5,
        "350k": 55
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 1,
        "15k": 3,
        "20k": 17,
        "25k": 20,
        "30k": 22,
        "35k": 24,
        "40k": 26,
        "45k": 25,
        "50k": 8,
        "55k": 13,
        "60k": 18,
        "65k": 5,
        "70k": 15,
        "75k": 7,
        "80k": 8,
        "85k": 11,
        "90k": 8,
        "95k": 1,
        "100k": 4,
        "105k": 8,
        "110k": 5,
        "115k": 9,
        "120k": 7,
        "125k": 2,
        "130k": 7,
        "135k": 13,
        "140k": 4,
        "145k": 3,
        "150k": 3,
        "155k": 2,
        "160k": 5,
        "165k": 1,
        "170k": 2,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 3,
        "195k": 3,
        "200k": 2,
        "350k": 15
      },
      nonRelativefm: {
        "5k": 9,
        "10k": 4,
        "15k": 16,
        "20k": 18,
        "25k": 28,
        "30k": 55,
        "35k": 38,
        "40k": 43,
        "45k": 41,
        "50k": 21,
        "55k": 37,
        "60k": 37,
        "65k": 31,
        "70k": 30,
        "75k": 29,
        "80k": 42,
        "85k": 31,
        "90k": 27,
        "95k": 20,
        "100k": 23,
        "105k": 21,
        "110k": 9,
        "115k": 14,
        "120k": 14,
        "125k": 4,
        "130k": 7,
        "135k": 2,
        "140k": 4,
        "145k": 9,
        "150k": 5,
        "155k": 1,
        "160k": 11,
        "165k": 5,
        "170k": 2,
        "175k": 0,
        "180k": 3,
        "185k": 1,
        "190k": 0,
        "195k": 8,
        "200k": 0,
        "350k": 25
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 208,
        "10k": 199,
        "15k": 291,
        "20k": 363,
        "25k": 431,
        "30k": 434,
        "35k": 439,
        "40k": 514,
        "45k": 515,
        "50k": 500,
        "55k": 543,
        "60k": 442,
        "65k": 460,
        "70k": 413,
        "75k": 440,
        "80k": 422,
        "85k": 432,
        "90k": 345,
        "95k": 346,
        "100k": 308,
        "105k": 330,
        "110k": 256,
        "115k": 236,
        "120k": 217,
        "125k": 200,
        "130k": 162,
        "135k": 159,
        "140k": 106,
        "145k": 151,
        "150k": 101,
        "155k": 153,
        "160k": 84,
        "165k": 92,
        "170k": 76,
        "175k": 66,
        "180k": 68,
        "185k": 79,
        "190k": 52,
        "195k": 35,
        "200k": 43,
        "350k": 585
      },
      spousem: {
        "5k": 52,
        "10k": 40,
        "15k": 65,
        "20k": 107,
        "25k": 152,
        "30k": 148,
        "35k": 141,
        "40k": 160,
        "45k": 171,
        "50k": 174,
        "55k": 239,
        "60k": 197,
        "65k": 251,
        "70k": 162,
        "75k": 210,
        "80k": 203,
        "85k": 209,
        "90k": 177,
        "95k": 174,
        "100k": 121,
        "105k": 183,
        "110k": 139,
        "115k": 108,
        "120k": 107,
        "125k": 120,
        "130k": 104,
        "135k": 99,
        "140k": 85,
        "145k": 54,
        "150k": 76,
        "155k": 82,
        "160k": 40,
        "165k": 50,
        "170k": 41,
        "175k": 42,
        "180k": 37,
        "185k": 35,
        "190k": 22,
        "195k": 41,
        "200k": 15,
        "350k": 322
      },
      childm: {
        "5k": 20,
        "10k": 24,
        "15k": 46,
        "20k": 56,
        "25k": 70,
        "30k": 44,
        "35k": 50,
        "40k": 73,
        "45k": 68,
        "50k": 48,
        "55k": 75,
        "60k": 57,
        "65k": 39,
        "70k": 54,
        "75k": 78,
        "80k": 45,
        "85k": 32,
        "90k": 45,
        "95k": 44,
        "100k": 24,
        "105k": 37,
        "110k": 18,
        "115k": 16,
        "120k": 14,
        "125k": 3,
        "130k": 19,
        "135k": 13,
        "140k": 14,
        "145k": 14,
        "150k": 14,
        "155k": 8,
        "160k": 14,
        "165k": 5,
        "170k": 6,
        "175k": 3,
        "180k": 5,
        "185k": 4,
        "190k": 3,
        "195k": 0,
        "200k": 2,
        "350k": 29
      },
      otherRelativem: {
        "5k": 11,
        "10k": 21,
        "15k": 56,
        "20k": 51,
        "25k": 39,
        "30k": 57,
        "35k": 67,
        "40k": 33,
        "45k": 54,
        "50k": 46,
        "55k": 48,
        "60k": 55,
        "65k": 67,
        "70k": 26,
        "75k": 48,
        "80k": 32,
        "85k": 16,
        "90k": 29,
        "95k": 18,
        "100k": 18,
        "105k": 17,
        "110k": 17,
        "115k": 29,
        "120k": 14,
        "125k": 6,
        "130k": 10,
        "135k": 5,
        "140k": 13,
        "145k": 8,
        "150k": 10,
        "155k": 1,
        "160k": 8,
        "165k": 6,
        "170k": 3,
        "175k": 7,
        "180k": 4,
        "185k": 1,
        "190k": 5,
        "195k": 0,
        "200k": 5,
        "350k": 35
      },
      nonRelativem: {
        "5k": 20,
        "10k": 31,
        "15k": 38,
        "20k": 55,
        "25k": 83,
        "30k": 37,
        "35k": 88,
        "40k": 65,
        "45k": 59,
        "50k": 86,
        "55k": 64,
        "60k": 85,
        "65k": 65,
        "70k": 66,
        "75k": 84,
        "80k": 67,
        "85k": 61,
        "90k": 46,
        "95k": 40,
        "100k": 54,
        "105k": 34,
        "110k": 41,
        "115k": 34,
        "120k": 20,
        "125k": 16,
        "130k": 17,
        "135k": 15,
        "140k": 13,
        "145k": 9,
        "150k": 9,
        "155k": 10,
        "160k": 29,
        "165k": 7,
        "170k": 8,
        "175k": 13,
        "180k": 6,
        "185k": 7,
        "190k": 5,
        "195k": 5,
        "200k": 3,
        "350k": 89
      },
      houseLoitererfm: {
        "5k": 359,
        "10k": 366,
        "15k": 444,
        "20k": 485,
        "25k": 526,
        "30k": 542,
        "35k": 548,
        "40k": 525,
        "45k": 481,
        "50k": 448,
        "55k": 477,
        "60k": 419,
        "65k": 416,
        "70k": 290,
        "75k": 338,
        "80k": 317,
        "85k": 296,
        "90k": 252,
        "95k": 238,
        "100k": 178,
        "105k": 242,
        "110k": 185,
        "115k": 137,
        "120k": 152,
        "125k": 141,
        "130k": 123,
        "135k": 114,
        "140k": 100,
        "145k": 89,
        "150k": 83,
        "155k": 102,
        "160k": 55,
        "165k": 60,
        "170k": 47,
        "175k": 52,
        "180k": 29,
        "185k": 39,
        "190k": 34,
        "195k": 41,
        "200k": 19,
        "350k": 433
      },
      spousefm: {
        "5k": 54,
        "10k": 51,
        "15k": 114,
        "20k": 127,
        "25k": 177,
        "30k": 174,
        "35k": 185,
        "40k": 237,
        "45k": 320,
        "50k": 266,
        "55k": 309,
        "60k": 292,
        "65k": 316,
        "70k": 268,
        "75k": 324,
        "80k": 313,
        "85k": 308,
        "90k": 287,
        "95k": 283,
        "100k": 226,
        "105k": 257,
        "110k": 236,
        "115k": 230,
        "120k": 181,
        "125k": 178,
        "130k": 138,
        "135k": 139,
        "140k": 111,
        "145k": 126,
        "150k": 96,
        "155k": 156,
        "160k": 89,
        "165k": 98,
        "170k": 77,
        "175k": 64,
        "180k": 62,
        "185k": 66,
        "190k": 51,
        "195k": 31,
        "200k": 36,
        "350k": 578
      },
      childfm: {
        "5k": 9,
        "10k": 19,
        "15k": 29,
        "20k": 28,
        "25k": 19,
        "30k": 50,
        "35k": 50,
        "40k": 49,
        "45k": 22,
        "50k": 32,
        "55k": 32,
        "60k": 56,
        "65k": 56,
        "70k": 28,
        "75k": 28,
        "80k": 22,
        "85k": 24,
        "90k": 24,
        "95k": 24,
        "100k": 31,
        "105k": 9,
        "110k": 13,
        "115k": 17,
        "120k": 9,
        "125k": 13,
        "130k": 14,
        "135k": 1,
        "140k": 12,
        "145k": 11,
        "150k": 6,
        "155k": 7,
        "160k": 4,
        "165k": 5,
        "170k": 8,
        "175k": 1,
        "180k": 10,
        "185k": 4,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 37
      },
      otherRelativefm: {
        "5k": 19,
        "10k": 15,
        "15k": 31,
        "20k": 37,
        "25k": 32,
        "30k": 37,
        "35k": 44,
        "40k": 43,
        "45k": 43,
        "50k": 22,
        "55k": 41,
        "60k": 30,
        "65k": 26,
        "70k": 16,
        "75k": 28,
        "80k": 23,
        "85k": 15,
        "90k": 21,
        "95k": 20,
        "100k": 11,
        "105k": 17,
        "110k": 20,
        "115k": 18,
        "120k": 15,
        "125k": 4,
        "130k": 6,
        "135k": 2,
        "140k": 4,
        "145k": 2,
        "150k": 13,
        "155k": 3,
        "160k": 6,
        "165k": 1,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 3,
        "190k": 2,
        "195k": 0,
        "200k": 3,
        "350k": 23
      },
      nonRelativefm: {
        "5k": 11,
        "10k": 10,
        "15k": 39,
        "20k": 42,
        "25k": 39,
        "30k": 45,
        "35k": 33,
        "40k": 67,
        "45k": 43,
        "50k": 51,
        "55k": 46,
        "60k": 58,
        "65k": 44,
        "70k": 44,
        "75k": 51,
        "80k": 41,
        "85k": 34,
        "90k": 30,
        "95k": 26,
        "100k": 27,
        "105k": 40,
        "110k": 14,
        "115k": 14,
        "120k": 17,
        "125k": 17,
        "130k": 18,
        "135k": 12,
        "140k": 7,
        "145k": 10,
        "150k": 10,
        "155k": 4,
        "160k": 3,
        "165k": 2,
        "170k": 4,
        "175k": 2,
        "180k": 3,
        "185k": 7,
        "190k": 1,
        "195k": 5,
        "200k": 2,
        "350k": 34
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 347,
        "10k": 342,
        "15k": 454,
        "20k": 433,
        "25k": 463,
        "30k": 453,
        "35k": 496,
        "40k": 538,
        "45k": 537,
        "50k": 446,
        "55k": 610,
        "60k": 482,
        "65k": 539,
        "70k": 454,
        "75k": 515,
        "80k": 514,
        "85k": 423,
        "90k": 369,
        "95k": 383,
        "100k": 300,
        "105k": 408,
        "110k": 324,
        "115k": 285,
        "120k": 254,
        "125k": 255,
        "130k": 194,
        "135k": 182,
        "140k": 171,
        "145k": 153,
        "150k": 146,
        "155k": 188,
        "160k": 112,
        "165k": 151,
        "170k": 84,
        "175k": 92,
        "180k": 68,
        "185k": 90,
        "190k": 68,
        "195k": 46,
        "200k": 41,
        "350k": 854
      },
      spousem: {
        "5k": 43,
        "10k": 33,
        "15k": 67,
        "20k": 88,
        "25k": 119,
        "30k": 141,
        "35k": 136,
        "40k": 202,
        "45k": 202,
        "50k": 197,
        "55k": 204,
        "60k": 178,
        "65k": 230,
        "70k": 194,
        "75k": 194,
        "80k": 202,
        "85k": 240,
        "90k": 217,
        "95k": 157,
        "100k": 159,
        "105k": 212,
        "110k": 154,
        "115k": 177,
        "120k": 137,
        "125k": 112,
        "130k": 115,
        "135k": 116,
        "140k": 92,
        "145k": 83,
        "150k": 83,
        "155k": 86,
        "160k": 62,
        "165k": 63,
        "170k": 51,
        "175k": 37,
        "180k": 53,
        "185k": 52,
        "190k": 46,
        "195k": 29,
        "200k": 24,
        "350k": 538
      },
      childm: {
        "5k": 7,
        "10k": 22,
        "15k": 67,
        "20k": 72,
        "25k": 116,
        "30k": 73,
        "35k": 74,
        "40k": 54,
        "45k": 58,
        "50k": 47,
        "55k": 45,
        "60k": 29,
        "65k": 34,
        "70k": 21,
        "75k": 26,
        "80k": 26,
        "85k": 22,
        "90k": 15,
        "95k": 17,
        "100k": 7,
        "105k": 11,
        "110k": 17,
        "115k": 4,
        "120k": 7,
        "125k": 11,
        "130k": 9,
        "135k": 11,
        "140k": 4,
        "145k": 7,
        "150k": 2,
        "155k": 4,
        "160k": 4,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 2,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 22
      },
      otherRelativem: {
        "5k": 22,
        "10k": 11,
        "15k": 25,
        "20k": 39,
        "25k": 41,
        "30k": 44,
        "35k": 46,
        "40k": 28,
        "45k": 44,
        "50k": 58,
        "55k": 43,
        "60k": 76,
        "65k": 52,
        "70k": 35,
        "75k": 40,
        "80k": 30,
        "85k": 24,
        "90k": 25,
        "95k": 25,
        "100k": 27,
        "105k": 22,
        "110k": 21,
        "115k": 17,
        "120k": 22,
        "125k": 18,
        "130k": 11,
        "135k": 16,
        "140k": 1,
        "145k": 9,
        "150k": 12,
        "155k": 10,
        "160k": 4,
        "165k": 4,
        "170k": 9,
        "175k": 7,
        "180k": 3,
        "185k": 2,
        "190k": 0,
        "195k": 3,
        "200k": 3,
        "350k": 36
      },
      nonRelativem: {
        "5k": 22,
        "10k": 31,
        "15k": 40,
        "20k": 46,
        "25k": 56,
        "30k": 34,
        "35k": 54,
        "40k": 47,
        "45k": 42,
        "50k": 42,
        "55k": 49,
        "60k": 49,
        "65k": 46,
        "70k": 45,
        "75k": 57,
        "80k": 28,
        "85k": 34,
        "90k": 45,
        "95k": 44,
        "100k": 10,
        "105k": 29,
        "110k": 27,
        "115k": 20,
        "120k": 15,
        "125k": 14,
        "130k": 4,
        "135k": 22,
        "140k": 4,
        "145k": 5,
        "150k": 7,
        "155k": 6,
        "160k": 14,
        "165k": 8,
        "170k": 0,
        "175k": 7,
        "180k": 3,
        "185k": 10,
        "190k": 7,
        "195k": 5,
        "200k": 6,
        "350k": 44
      },
      houseLoitererfm: {
        "5k": 414,
        "10k": 490,
        "15k": 481,
        "20k": 515,
        "25k": 574,
        "30k": 480,
        "35k": 541,
        "40k": 517,
        "45k": 523,
        "50k": 493,
        "55k": 488,
        "60k": 430,
        "65k": 463,
        "70k": 378,
        "75k": 375,
        "80k": 327,
        "85k": 357,
        "90k": 327,
        "95k": 268,
        "100k": 248,
        "105k": 300,
        "110k": 202,
        "115k": 235,
        "120k": 165,
        "125k": 142,
        "130k": 141,
        "135k": 159,
        "140k": 104,
        "145k": 104,
        "150k": 109,
        "155k": 115,
        "160k": 70,
        "165k": 87,
        "170k": 67,
        "175k": 62,
        "180k": 62,
        "185k": 65,
        "190k": 46,
        "195k": 38,
        "200k": 25,
        "350k": 621
      },
      spousefm: {
        "5k": 73,
        "10k": 74,
        "15k": 107,
        "20k": 144,
        "25k": 178,
        "30k": 177,
        "35k": 246,
        "40k": 241,
        "45k": 259,
        "50k": 249,
        "55k": 333,
        "60k": 326,
        "65k": 311,
        "70k": 333,
        "75k": 365,
        "80k": 334,
        "85k": 299,
        "90k": 254,
        "95k": 259,
        "100k": 244,
        "105k": 298,
        "110k": 224,
        "115k": 229,
        "120k": 214,
        "125k": 230,
        "130k": 182,
        "135k": 142,
        "140k": 155,
        "145k": 120,
        "150k": 135,
        "155k": 157,
        "160k": 106,
        "165k": 136,
        "170k": 71,
        "175k": 80,
        "180k": 68,
        "185k": 71,
        "190k": 60,
        "195k": 56,
        "200k": 39,
        "350k": 774
      },
      childfm: {
        "5k": 6,
        "10k": 8,
        "15k": 11,
        "20k": 35,
        "25k": 27,
        "30k": 25,
        "35k": 24,
        "40k": 37,
        "45k": 20,
        "50k": 26,
        "55k": 20,
        "60k": 32,
        "65k": 20,
        "70k": 35,
        "75k": 19,
        "80k": 16,
        "85k": 8,
        "90k": 20,
        "95k": 17,
        "100k": 14,
        "105k": 7,
        "110k": 6,
        "115k": 4,
        "120k": 21,
        "125k": 4,
        "130k": 9,
        "135k": 9,
        "140k": 6,
        "145k": 6,
        "150k": 0,
        "155k": 4,
        "160k": 15,
        "165k": 3,
        "170k": 7,
        "175k": 0,
        "180k": 9,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 12
      },
      otherRelativefm: {
        "5k": 27,
        "10k": 22,
        "15k": 45,
        "20k": 46,
        "25k": 54,
        "30k": 52,
        "35k": 61,
        "40k": 67,
        "45k": 49,
        "50k": 62,
        "55k": 54,
        "60k": 50,
        "65k": 41,
        "70k": 40,
        "75k": 35,
        "80k": 50,
        "85k": 43,
        "90k": 23,
        "95k": 34,
        "100k": 32,
        "105k": 20,
        "110k": 32,
        "115k": 19,
        "120k": 20,
        "125k": 14,
        "130k": 11,
        "135k": 13,
        "140k": 14,
        "145k": 10,
        "150k": 14,
        "155k": 10,
        "160k": 4,
        "165k": 6,
        "170k": 6,
        "175k": 4,
        "180k": 6,
        "185k": 4,
        "190k": 0,
        "195k": 3,
        "200k": 8,
        "350k": 41
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 21,
        "15k": 54,
        "20k": 34,
        "25k": 37,
        "30k": 50,
        "35k": 41,
        "40k": 48,
        "45k": 39,
        "50k": 39,
        "55k": 50,
        "60k": 29,
        "65k": 53,
        "70k": 46,
        "75k": 34,
        "80k": 38,
        "85k": 29,
        "90k": 16,
        "95k": 21,
        "100k": 30,
        "105k": 21,
        "110k": 20,
        "115k": 23,
        "120k": 8,
        "125k": 11,
        "130k": 14,
        "135k": 8,
        "140k": 1,
        "145k": 7,
        "150k": 6,
        "155k": 17,
        "160k": 6,
        "165k": 5,
        "170k": 5,
        "175k": 5,
        "180k": 4,
        "185k": 8,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 43
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 334,
        "10k": 294,
        "15k": 429,
        "20k": 389,
        "25k": 457,
        "30k": 437,
        "35k": 503,
        "40k": 421,
        "45k": 498,
        "50k": 409,
        "55k": 425,
        "60k": 381,
        "65k": 443,
        "70k": 356,
        "75k": 362,
        "80k": 379,
        "85k": 299,
        "90k": 272,
        "95k": 305,
        "100k": 212,
        "105k": 266,
        "110k": 218,
        "115k": 197,
        "120k": 171,
        "125k": 176,
        "130k": 204,
        "135k": 137,
        "140k": 112,
        "145k": 108,
        "150k": 105,
        "155k": 156,
        "160k": 86,
        "165k": 96,
        "170k": 56,
        "175k": 86,
        "180k": 82,
        "185k": 67,
        "190k": 34,
        "195k": 72,
        "200k": 41,
        "350k": 682
      },
      spousem: {
        "5k": 42,
        "10k": 31,
        "15k": 65,
        "20k": 76,
        "25k": 132,
        "30k": 155,
        "35k": 166,
        "40k": 161,
        "45k": 172,
        "50k": 162,
        "55k": 182,
        "60k": 186,
        "65k": 204,
        "70k": 153,
        "75k": 183,
        "80k": 156,
        "85k": 132,
        "90k": 140,
        "95k": 145,
        "100k": 112,
        "105k": 169,
        "110k": 131,
        "115k": 96,
        "120k": 98,
        "125k": 84,
        "130k": 86,
        "135k": 97,
        "140k": 61,
        "145k": 71,
        "150k": 59,
        "155k": 79,
        "160k": 35,
        "165k": 45,
        "170k": 46,
        "175k": 31,
        "180k": 29,
        "185k": 30,
        "190k": 30,
        "195k": 24,
        "200k": 29,
        "350k": 401
      },
      childm: {
        "5k": 2,
        "10k": 15,
        "15k": 16,
        "20k": 38,
        "25k": 22,
        "30k": 11,
        "35k": 45,
        "40k": 30,
        "45k": 28,
        "50k": 32,
        "55k": 15,
        "60k": 10,
        "65k": 6,
        "70k": 1,
        "75k": 9,
        "80k": 5,
        "85k": 4,
        "90k": 5,
        "95k": 4,
        "100k": 3,
        "105k": 10,
        "110k": 0,
        "115k": 0,
        "120k": 1,
        "125k": 0,
        "130k": 7,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      otherRelativem: {
        "5k": 15,
        "10k": 14,
        "15k": 27,
        "20k": 38,
        "25k": 31,
        "30k": 34,
        "35k": 43,
        "40k": 45,
        "45k": 35,
        "50k": 28,
        "55k": 48,
        "60k": 51,
        "65k": 29,
        "70k": 16,
        "75k": 23,
        "80k": 18,
        "85k": 24,
        "90k": 24,
        "95k": 16,
        "100k": 23,
        "105k": 22,
        "110k": 17,
        "115k": 16,
        "120k": 13,
        "125k": 7,
        "130k": 16,
        "135k": 7,
        "140k": 9,
        "145k": 8,
        "150k": 4,
        "155k": 16,
        "160k": 8,
        "165k": 1,
        "170k": 6,
        "175k": 9,
        "180k": 4,
        "185k": 1,
        "190k": 3,
        "195k": 0,
        "200k": 5,
        "350k": 36
      },
      nonRelativem: {
        "5k": 24,
        "10k": 11,
        "15k": 22,
        "20k": 24,
        "25k": 48,
        "30k": 34,
        "35k": 20,
        "40k": 17,
        "45k": 26,
        "50k": 27,
        "55k": 20,
        "60k": 25,
        "65k": 30,
        "70k": 20,
        "75k": 16,
        "80k": 18,
        "85k": 20,
        "90k": 16,
        "95k": 20,
        "100k": 21,
        "105k": 13,
        "110k": 11,
        "115k": 6,
        "120k": 17,
        "125k": 6,
        "130k": 0,
        "135k": 7,
        "140k": 0,
        "145k": 6,
        "150k": 10,
        "155k": 0,
        "160k": 9,
        "165k": 5,
        "170k": 16,
        "175k": 4,
        "180k": 1,
        "185k": 4,
        "190k": 4,
        "195k": 0,
        "200k": 0,
        "350k": 45
      },
      houseLoitererfm: {
        "5k": 270,
        "10k": 518,
        "15k": 590,
        "20k": 506,
        "25k": 565,
        "30k": 542,
        "35k": 519,
        "40k": 531,
        "45k": 460,
        "50k": 368,
        "55k": 382,
        "60k": 382,
        "65k": 371,
        "70k": 265,
        "75k": 283,
        "80k": 229,
        "85k": 231,
        "90k": 206,
        "95k": 194,
        "100k": 155,
        "105k": 170,
        "110k": 183,
        "115k": 144,
        "120k": 126,
        "125k": 116,
        "130k": 123,
        "135k": 105,
        "140k": 79,
        "145k": 79,
        "150k": 60,
        "155k": 79,
        "160k": 61,
        "165k": 58,
        "170k": 54,
        "175k": 38,
        "180k": 33,
        "185k": 28,
        "190k": 34,
        "195k": 19,
        "200k": 32,
        "350k": 445
      },
      spousefm: {
        "5k": 95,
        "10k": 67,
        "15k": 145,
        "20k": 175,
        "25k": 243,
        "30k": 267,
        "35k": 272,
        "40k": 311,
        "45k": 300,
        "50k": 281,
        "55k": 290,
        "60k": 275,
        "65k": 322,
        "70k": 292,
        "75k": 240,
        "80k": 276,
        "85k": 222,
        "90k": 213,
        "95k": 240,
        "100k": 176,
        "105k": 176,
        "110k": 193,
        "115k": 165,
        "120k": 142,
        "125k": 122,
        "130k": 137,
        "135k": 92,
        "140k": 69,
        "145k": 85,
        "150k": 76,
        "155k": 105,
        "160k": 58,
        "165k": 62,
        "170k": 49,
        "175k": 71,
        "180k": 61,
        "185k": 68,
        "190k": 34,
        "195k": 57,
        "200k": 31,
        "350k": 512
      },
      childfm: {
        "5k": 6,
        "10k": 4,
        "15k": 13,
        "20k": 17,
        "25k": 16,
        "30k": 18,
        "35k": 16,
        "40k": 14,
        "45k": 18,
        "50k": 22,
        "55k": 7,
        "60k": 11,
        "65k": 10,
        "70k": 8,
        "75k": 3,
        "80k": 12,
        "85k": 4,
        "90k": 0,
        "95k": 1,
        "100k": 1,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 3,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 20,
        "10k": 24,
        "15k": 25,
        "20k": 49,
        "25k": 26,
        "30k": 58,
        "35k": 34,
        "40k": 49,
        "45k": 47,
        "50k": 44,
        "55k": 54,
        "60k": 45,
        "65k": 50,
        "70k": 36,
        "75k": 41,
        "80k": 44,
        "85k": 36,
        "90k": 36,
        "95k": 19,
        "100k": 30,
        "105k": 25,
        "110k": 19,
        "115k": 24,
        "120k": 10,
        "125k": 16,
        "130k": 5,
        "135k": 6,
        "140k": 14,
        "145k": 12,
        "150k": 6,
        "155k": 17,
        "160k": 11,
        "165k": 8,
        "170k": 9,
        "175k": 13,
        "180k": 7,
        "185k": 4,
        "190k": 4,
        "195k": 3,
        "200k": 5,
        "350k": 43
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 9,
        "15k": 21,
        "20k": 20,
        "25k": 22,
        "30k": 16,
        "35k": 25,
        "40k": 23,
        "45k": 31,
        "50k": 19,
        "55k": 19,
        "60k": 20,
        "65k": 16,
        "70k": 21,
        "75k": 17,
        "80k": 27,
        "85k": 13,
        "90k": 15,
        "95k": 13,
        "100k": 0,
        "105k": 1,
        "110k": 8,
        "115k": 12,
        "120k": 3,
        "125k": 0,
        "130k": 10,
        "135k": 10,
        "140k": 8,
        "145k": 0,
        "150k": 8,
        "155k": 1,
        "160k": 1,
        "165k": 7,
        "170k": 6,
        "175k": 9,
        "180k": 3,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 18
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 107,
        "10k": 195,
        "15k": 382,
        "20k": 414,
        "25k": 414,
        "30k": 475,
        "35k": 423,
        "40k": 430,
        "45k": 414,
        "50k": 314,
        "55k": 306,
        "60k": 235,
        "65k": 277,
        "70k": 286,
        "75k": 168,
        "80k": 178,
        "85k": 154,
        "90k": 152,
        "95k": 106,
        "100k": 121,
        "105k": 113,
        "110k": 94,
        "115k": 83,
        "120k": 61,
        "125k": 81,
        "130k": 59,
        "135k": 82,
        "140k": 49,
        "145k": 47,
        "150k": 50,
        "155k": 52,
        "160k": 49,
        "165k": 20,
        "170k": 27,
        "175k": 19,
        "180k": 18,
        "185k": 28,
        "190k": 33,
        "195k": 12,
        "200k": 9,
        "350k": 237
      },
      spousem: {
        "5k": 23,
        "10k": 18,
        "15k": 52,
        "20k": 76,
        "25k": 157,
        "30k": 181,
        "35k": 171,
        "40k": 165,
        "45k": 123,
        "50k": 120,
        "55k": 140,
        "60k": 96,
        "65k": 96,
        "70k": 94,
        "75k": 77,
        "80k": 59,
        "85k": 65,
        "90k": 36,
        "95k": 64,
        "100k": 43,
        "105k": 49,
        "110k": 55,
        "115k": 32,
        "120k": 19,
        "125k": 38,
        "130k": 25,
        "135k": 24,
        "140k": 14,
        "145k": 20,
        "150k": 7,
        "155k": 15,
        "160k": 32,
        "165k": 16,
        "170k": 11,
        "175k": 14,
        "180k": 8,
        "185k": 0,
        "190k": 9,
        "195k": 7,
        "200k": 9,
        "350k": 139
      },
      childm: {
        "5k": 0,
        "10k": 0,
        "15k": 1,
        "20k": 0,
        "25k": 4,
        "30k": 8,
        "35k": 5,
        "40k": 2,
        "45k": 0,
        "50k": 0,
        "55k": 1,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 1,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 3,
        "10k": 4,
        "15k": 8,
        "20k": 15,
        "25k": 14,
        "30k": 14,
        "35k": 20,
        "40k": 7,
        "45k": 13,
        "50k": 21,
        "55k": 9,
        "60k": 18,
        "65k": 19,
        "70k": 16,
        "75k": 11,
        "80k": 7,
        "85k": 13,
        "90k": 7,
        "95k": 7,
        "100k": 4,
        "105k": 8,
        "110k": 8,
        "115k": 10,
        "120k": 6,
        "125k": 0,
        "130k": 7,
        "135k": 0,
        "140k": 0,
        "145k": 2,
        "150k": 4,
        "155k": 3,
        "160k": 4,
        "165k": 0,
        "170k": 4,
        "175k": 0,
        "180k": 2,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 26
      },
      nonRelativem: {
        "5k": 5,
        "10k": 6,
        "15k": 5,
        "20k": 21,
        "25k": 14,
        "30k": 14,
        "35k": 15,
        "40k": 21,
        "45k": 13,
        "50k": 24,
        "55k": 9,
        "60k": 5,
        "65k": 8,
        "70k": 6,
        "75k": 13,
        "80k": 3,
        "85k": 2,
        "90k": 4,
        "95k": 4,
        "100k": 3,
        "105k": 3,
        "110k": 1,
        "115k": 3,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 5,
        "145k": 0,
        "150k": 0,
        "155k": 3,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      houseLoitererfm: {
        "5k": 160,
        "10k": 457,
        "15k": 689,
        "20k": 666,
        "25k": 608,
        "30k": 526,
        "35k": 421,
        "40k": 404,
        "45k": 281,
        "50k": 269,
        "55k": 262,
        "60k": 230,
        "65k": 184,
        "70k": 157,
        "75k": 150,
        "80k": 95,
        "85k": 66,
        "90k": 78,
        "95k": 93,
        "100k": 64,
        "105k": 63,
        "110k": 48,
        "115k": 26,
        "120k": 37,
        "125k": 30,
        "130k": 21,
        "135k": 18,
        "140k": 21,
        "145k": 12,
        "150k": 17,
        "155k": 21,
        "160k": 17,
        "165k": 8,
        "170k": 25,
        "175k": 12,
        "180k": 6,
        "185k": 8,
        "190k": 5,
        "195k": 10,
        "200k": 10,
        "350k": 119
      },
      spousefm: {
        "5k": 36,
        "10k": 53,
        "15k": 88,
        "20k": 187,
        "25k": 249,
        "30k": 309,
        "35k": 265,
        "40k": 276,
        "45k": 240,
        "50k": 176,
        "55k": 178,
        "60k": 151,
        "65k": 174,
        "70k": 153,
        "75k": 104,
        "80k": 130,
        "85k": 101,
        "90k": 110,
        "95k": 58,
        "100k": 77,
        "105k": 64,
        "110k": 37,
        "115k": 52,
        "120k": 36,
        "125k": 35,
        "130k": 36,
        "135k": 58,
        "140k": 27,
        "145k": 29,
        "150k": 37,
        "155k": 31,
        "160k": 36,
        "165k": 14,
        "170k": 15,
        "175k": 12,
        "180k": 5,
        "185k": 9,
        "190k": 10,
        "195k": 1,
        "200k": 9,
        "350k": 146
      },
      childfm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 2,
        "25k": 0,
        "30k": 3,
        "35k": 2,
        "40k": 11,
        "45k": 2,
        "50k": 0,
        "55k": 0,
        "60k": 3,
        "65k": 1,
        "70k": 1,
        "75k": 2,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 3,
        "120k": 0,
        "125k": 0,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 16,
        "15k": 23,
        "20k": 45,
        "25k": 30,
        "30k": 57,
        "35k": 50,
        "40k": 25,
        "45k": 31,
        "50k": 40,
        "55k": 44,
        "60k": 27,
        "65k": 21,
        "70k": 38,
        "75k": 30,
        "80k": 21,
        "85k": 36,
        "90k": 22,
        "95k": 26,
        "100k": 17,
        "105k": 13,
        "110k": 11,
        "115k": 16,
        "120k": 18,
        "125k": 12,
        "130k": 12,
        "135k": 10,
        "140k": 13,
        "145k": 9,
        "150k": 5,
        "155k": 8,
        "160k": 4,
        "165k": 5,
        "170k": 3,
        "175k": 5,
        "180k": 3,
        "185k": 5,
        "190k": 7,
        "195k": 2,
        "200k": 4,
        "350k": 43
      },
      nonRelativefm: {
        "5k": 1,
        "10k": 5,
        "15k": 3,
        "20k": 12,
        "25k": 9,
        "30k": 24,
        "35k": 2,
        "40k": 12,
        "45k": 3,
        "50k": 8,
        "55k": 6,
        "60k": 2,
        "65k": 10,
        "70k": 0,
        "75k": 0,
        "80k": 1,
        "85k": 8,
        "90k": 3,
        "95k": 3,
        "100k": 7,
        "105k": 2,
        "110k": 0,
        "115k": 5,
        "120k": 1,
        "125k": 2,
        "130k": 10,
        "135k": 0,
        "140k": 0,
        "145k": 2,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 2,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      }
    }
  },
  "2010": {
    "17": {
      houseLoitererm: {
        "5k": 3,
        "10k": 5,
        "15k": 8,
        "20k": 9,
        "25k": 12,
        "30k": 7,
        "35k": 5,
        "40k": 7,
        "45k": 1,
        "50k": 6,
        "55k": 2,
        "60k": 6,
        "65k": 2,
        "70k": 0,
        "75k": 2,
        "80k": 0,
        "85k": 3,
        "90k": 2,
        "95k": 2,
        "100k": 4,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 1,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 3,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 2,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 373,
        "10k": 292,
        "15k": 409,
        "20k": 466,
        "25k": 514,
        "30k": 551,
        "35k": 470,
        "40k": 533,
        "45k": 513,
        "50k": 503,
        "55k": 466,
        "60k": 399,
        "65k": 461,
        "70k": 386,
        "75k": 365,
        "80k": 361,
        "85k": 346,
        "90k": 316,
        "95k": 307,
        "100k": 282,
        "105k": 301,
        "110k": 229,
        "115k": 222,
        "120k": 210,
        "125k": 178,
        "130k": 174,
        "135k": 171,
        "140k": 129,
        "145k": 128,
        "150k": 91,
        "155k": 179,
        "160k": 100,
        "165k": 98,
        "170k": 63,
        "175k": 77,
        "180k": 61,
        "185k": 66,
        "190k": 47,
        "195k": 68,
        "200k": 43,
        "350k": 730
      },
      otherRelativem: {
        "5k": 25,
        "10k": 43,
        "15k": 84,
        "20k": 52,
        "25k": 67,
        "30k": 60,
        "35k": 45,
        "40k": 64,
        "45k": 69,
        "50k": 30,
        "55k": 40,
        "60k": 42,
        "65k": 48,
        "70k": 36,
        "75k": 28,
        "80k": 33,
        "85k": 47,
        "90k": 22,
        "95k": 20,
        "100k": 21,
        "105k": 18,
        "110k": 19,
        "115k": 20,
        "120k": 10,
        "125k": 3,
        "130k": 19,
        "135k": 6,
        "140k": 10,
        "145k": 6,
        "150k": 3,
        "155k": 9,
        "160k": 3,
        "165k": 3,
        "170k": 3,
        "175k": 6,
        "180k": 1,
        "185k": 2,
        "190k": 2,
        "195k": 1,
        "200k": 2,
        "350k": 19
      },
      nonRelativem: {
        "5k": 3,
        "10k": 5,
        "15k": 6,
        "20k": 2,
        "25k": 15,
        "30k": 11,
        "35k": 6,
        "40k": 21,
        "45k": 15,
        "50k": 5,
        "55k": 13,
        "60k": 11,
        "65k": 6,
        "70k": 25,
        "75k": 7,
        "80k": 6,
        "85k": 4,
        "90k": 11,
        "95k": 11,
        "100k": 4,
        "105k": 2,
        "110k": 14,
        "115k": 2,
        "120k": 1,
        "125k": 2,
        "130k": 2,
        "135k": 3,
        "140k": 0,
        "145k": 2,
        "150k": 0,
        "155k": 0,
        "160k": 2,
        "165k": 0,
        "170k": 1,
        "175k": 5,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      houseLoitererfm: {
        "5k": 7,
        "10k": 3,
        "15k": 11,
        "20k": 16,
        "25k": 11,
        "30k": 3,
        "35k": 7,
        "40k": 6,
        "45k": 6,
        "50k": 4,
        "55k": 7,
        "60k": 1,
        "65k": 2,
        "70k": 2,
        "75k": 1,
        "80k": 2,
        "85k": 3,
        "90k": 2,
        "95k": 0,
        "100k": 1,
        "105k": 0,
        "110k": 0,
        "115k": 1,
        "120k": 1,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 1,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 1,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 284,
        "10k": 289,
        "15k": 377,
        "20k": 409,
        "25k": 586,
        "30k": 520,
        "35k": 461,
        "40k": 445,
        "45k": 453,
        "50k": 420,
        "55k": 445,
        "60k": 407,
        "65k": 426,
        "70k": 342,
        "75k": 387,
        "80k": 308,
        "85k": 332,
        "90k": 313,
        "95k": 312,
        "100k": 253,
        "105k": 292,
        "110k": 213,
        "115k": 253,
        "120k": 175,
        "125k": 185,
        "130k": 184,
        "135k": 156,
        "140k": 101,
        "145k": 143,
        "150k": 96,
        "155k": 148,
        "160k": 94,
        "165k": 76,
        "170k": 58,
        "175k": 80,
        "180k": 67,
        "185k": 55,
        "190k": 50,
        "195k": 40,
        "200k": 31,
        "350k": 720
      },
      otherRelativefm: {
        "5k": 31,
        "10k": 57,
        "15k": 50,
        "20k": 36,
        "25k": 75,
        "30k": 71,
        "35k": 56,
        "40k": 55,
        "45k": 40,
        "50k": 55,
        "55k": 52,
        "60k": 44,
        "65k": 42,
        "70k": 41,
        "75k": 42,
        "80k": 20,
        "85k": 43,
        "90k": 30,
        "95k": 19,
        "100k": 15,
        "105k": 9,
        "110k": 26,
        "115k": 10,
        "120k": 17,
        "125k": 7,
        "130k": 4,
        "135k": 15,
        "140k": 7,
        "145k": 7,
        "150k": 6,
        "155k": 13,
        "160k": 4,
        "165k": 4,
        "170k": 2,
        "175k": 2,
        "180k": 0,
        "185k": 3,
        "190k": 2,
        "195k": 1,
        "200k": 1,
        "350k": 20
      },
      nonRelativefm: {
        "5k": 9,
        "10k": 16,
        "15k": 8,
        "20k": 8,
        "25k": 18,
        "30k": 6,
        "35k": 14,
        "40k": 20,
        "45k": 12,
        "50k": 15,
        "55k": 14,
        "60k": 26,
        "65k": 13,
        "70k": 10,
        "75k": 13,
        "80k": 10,
        "85k": 6,
        "90k": 10,
        "95k": 7,
        "100k": 5,
        "105k": 6,
        "110k": 5,
        "115k": 2,
        "120k": 13,
        "125k": 5,
        "130k": 4,
        "135k": 5,
        "140k": 0,
        "145k": 4,
        "150k": 4,
        "155k": 4,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 5,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 1,
        "200k": 0,
        "350k": 4
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 97,
        "10k": 82,
        "15k": 86,
        "20k": 84,
        "25k": 89,
        "30k": 68,
        "35k": 43,
        "40k": 42,
        "45k": 44,
        "50k": 22,
        "55k": 16,
        "60k": 27,
        "65k": 19,
        "70k": 24,
        "75k": 10,
        "80k": 1,
        "85k": 7,
        "90k": 3,
        "95k": 7,
        "100k": 11,
        "105k": 8,
        "110k": 8,
        "115k": 13,
        "120k": 3,
        "125k": 3,
        "130k": 5,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 5,
        "155k": 1,
        "160k": 2,
        "165k": 3,
        "170k": 0,
        "175k": 2,
        "180k": 5,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      spousem: {
        "5k": 9,
        "10k": 8,
        "15k": 5,
        "20k": 4,
        "25k": 5,
        "30k": 8,
        "35k": 1,
        "40k": 11,
        "45k": 4,
        "50k": 1,
        "55k": 5,
        "60k": 5,
        "65k": 0,
        "70k": 2,
        "75k": 0,
        "80k": 2,
        "85k": 0,
        "90k": 0,
        "95k": 1,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 127,
        "10k": 139,
        "15k": 113,
        "20k": 188,
        "25k": 212,
        "30k": 272,
        "35k": 261,
        "40k": 218,
        "45k": 216,
        "50k": 182,
        "55k": 231,
        "60k": 217,
        "65k": 250,
        "70k": 206,
        "75k": 244,
        "80k": 220,
        "85k": 188,
        "90k": 184,
        "95k": 150,
        "100k": 204,
        "105k": 142,
        "110k": 152,
        "115k": 122,
        "120k": 126,
        "125k": 118,
        "130k": 101,
        "135k": 84,
        "140k": 116,
        "145k": 103,
        "150k": 100,
        "155k": 70,
        "160k": 57,
        "165k": 61,
        "170k": 66,
        "175k": 53,
        "180k": 43,
        "185k": 29,
        "190k": 30,
        "195k": 23,
        "200k": 20,
        "350k": 444
      },
      otherRelativem: {
        "5k": 32,
        "10k": 34,
        "15k": 25,
        "20k": 46,
        "25k": 66,
        "30k": 44,
        "35k": 30,
        "40k": 38,
        "45k": 48,
        "50k": 37,
        "55k": 47,
        "60k": 45,
        "65k": 53,
        "70k": 34,
        "75k": 25,
        "80k": 21,
        "85k": 43,
        "90k": 15,
        "95k": 25,
        "100k": 21,
        "105k": 21,
        "110k": 9,
        "115k": 17,
        "120k": 19,
        "125k": 7,
        "130k": 8,
        "135k": 19,
        "140k": 8,
        "145k": 8,
        "150k": 4,
        "155k": 6,
        "160k": 5,
        "165k": 0,
        "170k": 2,
        "175k": 1,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 6,
        "200k": 1,
        "350k": 24
      },
      nonRelativem: {
        "5k": 41,
        "10k": 40,
        "15k": 42,
        "20k": 49,
        "25k": 33,
        "30k": 53,
        "35k": 34,
        "40k": 51,
        "45k": 40,
        "50k": 49,
        "55k": 20,
        "60k": 23,
        "65k": 14,
        "70k": 24,
        "75k": 23,
        "80k": 14,
        "85k": 9,
        "90k": 22,
        "95k": 10,
        "100k": 22,
        "105k": 8,
        "110k": 4,
        "115k": 26,
        "120k": 1,
        "125k": 9,
        "130k": 2,
        "135k": 3,
        "140k": 3,
        "145k": 6,
        "150k": 0,
        "155k": 0,
        "160k": 4,
        "165k": 5,
        "170k": 1,
        "175k": 0,
        "180k": 3,
        "185k": 0,
        "190k": 4,
        "195k": 0,
        "200k": 3,
        "350k": 23
      },
      houseLoitererfm: {
        "5k": 155,
        "10k": 147,
        "15k": 150,
        "20k": 120,
        "25k": 126,
        "30k": 114,
        "35k": 69,
        "40k": 70,
        "45k": 49,
        "50k": 33,
        "55k": 52,
        "60k": 26,
        "65k": 12,
        "70k": 18,
        "75k": 21,
        "80k": 8,
        "85k": 3,
        "90k": 13,
        "95k": 5,
        "100k": 5,
        "105k": 3,
        "110k": 10,
        "115k": 7,
        "120k": 1,
        "125k": 4,
        "130k": 5,
        "135k": 2,
        "140k": 1,
        "145k": 4,
        "150k": 2,
        "155k": 6,
        "160k": 2,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 2,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": 11
      },
      spousefm: {
        "5k": 5,
        "10k": 13,
        "15k": 15,
        "20k": 29,
        "25k": 20,
        "30k": 17,
        "35k": 11,
        "40k": 18,
        "45k": 20,
        "50k": 4,
        "55k": 11,
        "60k": 6,
        "65k": 2,
        "70k": 12,
        "75k": 2,
        "80k": 9,
        "85k": 0,
        "90k": 1,
        "95k": 2,
        "100k": 2,
        "105k": 4,
        "110k": 0,
        "115k": 4,
        "120k": 2,
        "125k": 2,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 1
      },
      childfm: {
        "5k": 99,
        "10k": 101,
        "15k": 159,
        "20k": 146,
        "25k": 237,
        "30k": 209,
        "35k": 196,
        "40k": 201,
        "45k": 210,
        "50k": 176,
        "55k": 188,
        "60k": 230,
        "65k": 207,
        "70k": 199,
        "75k": 221,
        "80k": 158,
        "85k": 167,
        "90k": 160,
        "95k": 164,
        "100k": 152,
        "105k": 163,
        "110k": 129,
        "115k": 127,
        "120k": 136,
        "125k": 99,
        "130k": 94,
        "135k": 79,
        "140k": 69,
        "145k": 60,
        "150k": 59,
        "155k": 71,
        "160k": 63,
        "165k": 45,
        "170k": 51,
        "175k": 67,
        "180k": 44,
        "185k": 33,
        "190k": 19,
        "195k": 16,
        "200k": 17,
        "350k": 404
      },
      otherRelativefm: {
        "5k": 37,
        "10k": 13,
        "15k": 45,
        "20k": 40,
        "25k": 51,
        "30k": 60,
        "35k": 61,
        "40k": 38,
        "45k": 31,
        "50k": 29,
        "55k": 37,
        "60k": 33,
        "65k": 27,
        "70k": 11,
        "75k": 25,
        "80k": 28,
        "85k": 12,
        "90k": 23,
        "95k": 35,
        "100k": 11,
        "105k": 12,
        "110k": 19,
        "115k": 15,
        "120k": 9,
        "125k": 8,
        "130k": 11,
        "135k": 15,
        "140k": 7,
        "145k": 9,
        "150k": 6,
        "155k": 6,
        "160k": 4,
        "165k": 5,
        "170k": 3,
        "175k": 2,
        "180k": 2,
        "185k": 0,
        "190k": 1,
        "195k": 3,
        "200k": 0,
        "350k": 12
      },
      nonRelativefm: {
        "5k": 54,
        "10k": 30,
        "15k": 50,
        "20k": 61,
        "25k": 50,
        "30k": 87,
        "35k": 56,
        "40k": 54,
        "45k": 64,
        "50k": 47,
        "55k": 35,
        "60k": 43,
        "65k": 32,
        "70k": 14,
        "75k": 22,
        "80k": 23,
        "85k": 20,
        "90k": 12,
        "95k": 9,
        "100k": 14,
        "105k": 3,
        "110k": 8,
        "115k": 11,
        "120k": 6,
        "125k": 1,
        "130k": 3,
        "135k": 8,
        "140k": 3,
        "145k": 1,
        "150k": 0,
        "155k": 2,
        "160k": 1,
        "165k": 1,
        "170k": 5,
        "175k": 2,
        "180k": 3,
        "185k": 2,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 15
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 113,
        "10k": 115,
        "15k": 122,
        "20k": 137,
        "25k": 136,
        "30k": 125,
        "35k": 119,
        "40k": 129,
        "45k": 106,
        "50k": 84,
        "55k": 68,
        "60k": 78,
        "65k": 112,
        "70k": 65,
        "75k": 55,
        "80k": 42,
        "85k": 41,
        "90k": 24,
        "95k": 14,
        "100k": 14,
        "105k": 17,
        "110k": 19,
        "115k": 16,
        "120k": 9,
        "125k": 6,
        "130k": 8,
        "135k": 3,
        "140k": 10,
        "145k": 1,
        "150k": 6,
        "155k": 6,
        "160k": 2,
        "165k": 1,
        "170k": 2,
        "175k": 6,
        "180k": 0,
        "185k": 0,
        "190k": 4,
        "195k": 6,
        "200k": 0,
        "350k": 23
      },
      spousem: {
        "5k": 7,
        "10k": 7,
        "15k": 16,
        "20k": 27,
        "25k": 14,
        "30k": 16,
        "35k": 31,
        "40k": 17,
        "45k": 16,
        "50k": 14,
        "55k": 17,
        "60k": 14,
        "65k": 8,
        "70k": 10,
        "75k": 4,
        "80k": 4,
        "85k": 9,
        "90k": 2,
        "95k": 2,
        "100k": 2,
        "105k": 1,
        "110k": 2,
        "115k": 1,
        "120k": 1,
        "125k": 8,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 0
      },
      childm: {
        "5k": 26,
        "10k": 38,
        "15k": 43,
        "20k": 50,
        "25k": 74,
        "30k": 98,
        "35k": 95,
        "40k": 117,
        "45k": 111,
        "50k": 82,
        "55k": 108,
        "60k": 83,
        "65k": 82,
        "70k": 108,
        "75k": 129,
        "80k": 70,
        "85k": 98,
        "90k": 65,
        "95k": 85,
        "100k": 93,
        "105k": 76,
        "110k": 41,
        "115k": 46,
        "120k": 65,
        "125k": 57,
        "130k": 45,
        "135k": 37,
        "140k": 52,
        "145k": 47,
        "150k": 42,
        "155k": 14,
        "160k": 38,
        "165k": 35,
        "170k": 44,
        "175k": 28,
        "180k": 34,
        "185k": 20,
        "190k": 18,
        "195k": 18,
        "200k": 20,
        "350k": 258
      },
      otherRelativem: {
        "5k": 9,
        "10k": 18,
        "15k": 14,
        "20k": 27,
        "25k": 28,
        "30k": 28,
        "35k": 23,
        "40k": 28,
        "45k": 23,
        "50k": 19,
        "55k": 38,
        "60k": 25,
        "65k": 33,
        "70k": 20,
        "75k": 27,
        "80k": 23,
        "85k": 16,
        "90k": 14,
        "95k": 16,
        "100k": 9,
        "105k": 8,
        "110k": 21,
        "115k": 13,
        "120k": 11,
        "125k": 14,
        "130k": 10,
        "135k": 14,
        "140k": 4,
        "145k": 11,
        "150k": 7,
        "155k": 1,
        "160k": 2,
        "165k": 4,
        "170k": 5,
        "175k": 2,
        "180k": 2,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 3,
        "350k": 11
      },
      nonRelativem: {
        "5k": 40,
        "10k": 28,
        "15k": 35,
        "20k": 40,
        "25k": 52,
        "30k": 47,
        "35k": 46,
        "40k": 76,
        "45k": 40,
        "50k": 51,
        "55k": 49,
        "60k": 35,
        "65k": 47,
        "70k": 46,
        "75k": 42,
        "80k": 31,
        "85k": 30,
        "90k": 18,
        "95k": 15,
        "100k": 24,
        "105k": 12,
        "110k": 21,
        "115k": 21,
        "120k": 8,
        "125k": 6,
        "130k": 7,
        "135k": 3,
        "140k": 15,
        "145k": 21,
        "150k": 2,
        "155k": 4,
        "160k": 0,
        "165k": 9,
        "170k": 2,
        "175k": 5,
        "180k": 9,
        "185k": 2,
        "190k": 10,
        "195k": 11,
        "200k": 0,
        "350k": 30
      },
      houseLoitererfm: {
        "5k": 248,
        "10k": 189,
        "15k": 158,
        "20k": 171,
        "25k": 166,
        "30k": 161,
        "35k": 130,
        "40k": 132,
        "45k": 123,
        "50k": 106,
        "55k": 64,
        "60k": 64,
        "65k": 67,
        "70k": 44,
        "75k": 38,
        "80k": 26,
        "85k": 46,
        "90k": 22,
        "95k": 10,
        "100k": 9,
        "105k": 20,
        "110k": 5,
        "115k": 11,
        "120k": 0,
        "125k": 10,
        "130k": 13,
        "135k": 5,
        "140k": 6,
        "145k": 2,
        "150k": 4,
        "155k": 1,
        "160k": 4,
        "165k": 0,
        "170k": 1,
        "175k": 3,
        "180k": 0,
        "185k": 4,
        "190k": 3,
        "195k": 0,
        "200k": 2,
        "350k": 19
      },
      spousefm: {
        "5k": 23,
        "10k": 19,
        "15k": 28,
        "20k": 49,
        "25k": 29,
        "30k": 45,
        "35k": 45,
        "40k": 40,
        "45k": 53,
        "50k": 42,
        "55k": 43,
        "60k": 31,
        "65k": 28,
        "70k": 43,
        "75k": 30,
        "80k": 13,
        "85k": 18,
        "90k": 14,
        "95k": 8,
        "100k": 8,
        "105k": 2,
        "110k": 9,
        "115k": 4,
        "120k": 2,
        "125k": 0,
        "130k": 2,
        "135k": 2,
        "140k": 1,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 16,
        "10k": 20,
        "15k": 34,
        "20k": 39,
        "25k": 42,
        "30k": 75,
        "35k": 65,
        "40k": 73,
        "45k": 65,
        "50k": 72,
        "55k": 102,
        "60k": 84,
        "65k": 96,
        "70k": 66,
        "75k": 55,
        "80k": 54,
        "85k": 64,
        "90k": 94,
        "95k": 58,
        "100k": 50,
        "105k": 41,
        "110k": 42,
        "115k": 62,
        "120k": 47,
        "125k": 47,
        "130k": 55,
        "135k": 36,
        "140k": 25,
        "145k": 42,
        "150k": 22,
        "155k": 48,
        "160k": 29,
        "165k": 18,
        "170k": 34,
        "175k": 15,
        "180k": 16,
        "185k": 5,
        "190k": 8,
        "195k": 5,
        "200k": 25,
        "350k": 180
      },
      otherRelativefm: {
        "5k": 7,
        "10k": 13,
        "15k": 7,
        "20k": 17,
        "25k": 18,
        "30k": 24,
        "35k": 35,
        "40k": 26,
        "45k": 28,
        "50k": 15,
        "55k": 10,
        "60k": 35,
        "65k": 5,
        "70k": 11,
        "75k": 24,
        "80k": 10,
        "85k": 16,
        "90k": 19,
        "95k": 17,
        "100k": 9,
        "105k": 11,
        "110k": 5,
        "115k": 7,
        "120k": 5,
        "125k": 4,
        "130k": 3,
        "135k": 10,
        "140k": 6,
        "145k": 2,
        "150k": 4,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 2,
        "180k": 3,
        "185k": 1,
        "190k": 2,
        "195k": 2,
        "200k": 0,
        "350k": 9
      },
      nonRelativefm: {
        "5k": 39,
        "10k": 38,
        "15k": 48,
        "20k": 52,
        "25k": 59,
        "30k": 56,
        "35k": 54,
        "40k": 64,
        "45k": 62,
        "50k": 65,
        "55k": 63,
        "60k": 66,
        "65k": 58,
        "70k": 36,
        "75k": 44,
        "80k": 28,
        "85k": 23,
        "90k": 19,
        "95k": 19,
        "100k": 19,
        "105k": 8,
        "110k": 20,
        "115k": 19,
        "120k": 16,
        "125k": 10,
        "130k": 9,
        "135k": 6,
        "140k": 25,
        "145k": 12,
        "150k": 0,
        "155k": 4,
        "160k": 4,
        "165k": 4,
        "170k": 3,
        "175k": 17,
        "180k": 0,
        "185k": 7,
        "190k": 4,
        "195k": 2,
        "200k": 0,
        "350k": 26
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 117,
        "10k": 103,
        "15k": 155,
        "20k": 217,
        "25k": 271,
        "30k": 244,
        "35k": 227,
        "40k": 268,
        "45k": 293,
        "50k": 200,
        "55k": 281,
        "60k": 193,
        "65k": 253,
        "70k": 187,
        "75k": 181,
        "80k": 147,
        "85k": 154,
        "90k": 124,
        "95k": 92,
        "100k": 88,
        "105k": 104,
        "110k": 73,
        "115k": 62,
        "120k": 52,
        "125k": 40,
        "130k": 34,
        "135k": 39,
        "140k": 43,
        "145k": 30,
        "150k": 30,
        "155k": 15,
        "160k": 8,
        "165k": 8,
        "170k": 4,
        "175k": 11,
        "180k": 5,
        "185k": 14,
        "190k": 7,
        "195k": 5,
        "200k": 0,
        "350k": 74
      },
      spousem: {
        "5k": 20,
        "10k": 25,
        "15k": 36,
        "20k": 53,
        "25k": 71,
        "30k": 72,
        "35k": 72,
        "40k": 73,
        "45k": 89,
        "50k": 48,
        "55k": 78,
        "60k": 64,
        "65k": 52,
        "70k": 44,
        "75k": 67,
        "80k": 41,
        "85k": 41,
        "90k": 41,
        "95k": 39,
        "100k": 21,
        "105k": 23,
        "110k": 17,
        "115k": 24,
        "120k": 17,
        "125k": 5,
        "130k": 12,
        "135k": 12,
        "140k": 14,
        "145k": 2,
        "150k": 7,
        "155k": 2,
        "160k": 2,
        "165k": 3,
        "170k": 1,
        "175k": 2,
        "180k": 6,
        "185k": 2,
        "190k": 1,
        "195k": 5,
        "200k": 0,
        "350k": 27
      },
      childm: {
        "5k": 34,
        "10k": 38,
        "15k": 48,
        "20k": 47,
        "25k": 55,
        "30k": 69,
        "35k": 89,
        "40k": 104,
        "45k": 70,
        "50k": 78,
        "55k": 106,
        "60k": 85,
        "65k": 78,
        "70k": 103,
        "75k": 91,
        "80k": 64,
        "85k": 73,
        "90k": 61,
        "95k": 72,
        "100k": 61,
        "105k": 61,
        "110k": 64,
        "115k": 59,
        "120k": 51,
        "125k": 50,
        "130k": 57,
        "135k": 39,
        "140k": 26,
        "145k": 30,
        "150k": 27,
        "155k": 38,
        "160k": 40,
        "165k": 11,
        "170k": 31,
        "175k": 30,
        "180k": 23,
        "185k": 26,
        "190k": 6,
        "195k": 18,
        "200k": 21,
        "350k": 182
      },
      otherRelativem: {
        "5k": 8,
        "10k": 11,
        "15k": 20,
        "20k": 26,
        "25k": 39,
        "30k": 29,
        "35k": 38,
        "40k": 30,
        "45k": 34,
        "50k": 40,
        "55k": 18,
        "60k": 47,
        "65k": 47,
        "70k": 26,
        "75k": 33,
        "80k": 34,
        "85k": 32,
        "90k": 11,
        "95k": 6,
        "100k": 21,
        "105k": 21,
        "110k": 20,
        "115k": 10,
        "120k": 14,
        "125k": 10,
        "130k": 11,
        "135k": 16,
        "140k": 4,
        "145k": 14,
        "150k": 7,
        "155k": 8,
        "160k": 5,
        "165k": 10,
        "170k": 6,
        "175k": 6,
        "180k": 10,
        "185k": 1,
        "190k": 3,
        "195k": 4,
        "200k": 2,
        "350k": 37
      },
      nonRelativem: {
        "5k": 15,
        "10k": 34,
        "15k": 34,
        "20k": 61,
        "25k": 64,
        "30k": 82,
        "35k": 76,
        "40k": 76,
        "45k": 82,
        "50k": 61,
        "55k": 67,
        "60k": 61,
        "65k": 73,
        "70k": 58,
        "75k": 68,
        "80k": 46,
        "85k": 75,
        "90k": 38,
        "95k": 32,
        "100k": 30,
        "105k": 46,
        "110k": 44,
        "115k": 31,
        "120k": 28,
        "125k": 19,
        "130k": 15,
        "135k": 19,
        "140k": 26,
        "145k": 16,
        "150k": 20,
        "155k": 20,
        "160k": 22,
        "165k": 11,
        "170k": 8,
        "175k": 12,
        "180k": 10,
        "185k": 7,
        "190k": 6,
        "195k": 6,
        "200k": 1,
        "350k": 64
      },
      houseLoitererfm: {
        "5k": 289,
        "10k": 285,
        "15k": 263,
        "20k": 311,
        "25k": 357,
        "30k": 382,
        "35k": 280,
        "40k": 302,
        "45k": 253,
        "50k": 225,
        "55k": 245,
        "60k": 188,
        "65k": 164,
        "70k": 127,
        "75k": 158,
        "80k": 109,
        "85k": 123,
        "90k": 91,
        "95k": 88,
        "100k": 67,
        "105k": 55,
        "110k": 58,
        "115k": 40,
        "120k": 50,
        "125k": 59,
        "130k": 39,
        "135k": 33,
        "140k": 27,
        "145k": 20,
        "150k": 12,
        "155k": 10,
        "160k": 8,
        "165k": 9,
        "170k": 10,
        "175k": 6,
        "180k": 10,
        "185k": 5,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 63
      },
      spousefm: {
        "5k": 19,
        "10k": 27,
        "15k": 62,
        "20k": 75,
        "25k": 94,
        "30k": 100,
        "35k": 88,
        "40k": 121,
        "45k": 125,
        "50k": 96,
        "55k": 141,
        "60k": 131,
        "65k": 147,
        "70k": 111,
        "75k": 108,
        "80k": 94,
        "85k": 115,
        "90k": 85,
        "95k": 89,
        "100k": 67,
        "105k": 72,
        "110k": 45,
        "115k": 49,
        "120k": 48,
        "125k": 35,
        "130k": 31,
        "135k": 32,
        "140k": 33,
        "145k": 17,
        "150k": 17,
        "155k": 10,
        "160k": 12,
        "165k": 12,
        "170k": 10,
        "175k": 12,
        "180k": 8,
        "185k": 9,
        "190k": 4,
        "195k": 2,
        "200k": 5,
        "350k": 44
      },
      childfm: {
        "5k": 16,
        "10k": 18,
        "15k": 37,
        "20k": 39,
        "25k": 35,
        "30k": 36,
        "35k": 48,
        "40k": 42,
        "45k": 51,
        "50k": 45,
        "55k": 51,
        "60k": 51,
        "65k": 50,
        "70k": 56,
        "75k": 64,
        "80k": 48,
        "85k": 30,
        "90k": 38,
        "95k": 53,
        "100k": 25,
        "105k": 31,
        "110k": 39,
        "115k": 33,
        "120k": 42,
        "125k": 16,
        "130k": 27,
        "135k": 25,
        "140k": 20,
        "145k": 31,
        "150k": 19,
        "155k": 24,
        "160k": 17,
        "165k": 8,
        "170k": 20,
        "175k": 8,
        "180k": 18,
        "185k": 9,
        "190k": 9,
        "195k": 13,
        "200k": 6,
        "350k": 132
      },
      otherRelativefm: {
        "5k": 11,
        "10k": 8,
        "15k": 10,
        "20k": 20,
        "25k": 27,
        "30k": 24,
        "35k": 14,
        "40k": 15,
        "45k": 18,
        "50k": 24,
        "55k": 15,
        "60k": 20,
        "65k": 31,
        "70k": 16,
        "75k": 28,
        "80k": 18,
        "85k": 10,
        "90k": 16,
        "95k": 4,
        "100k": 17,
        "105k": 9,
        "110k": 7,
        "115k": 17,
        "120k": 9,
        "125k": 6,
        "130k": 10,
        "135k": 6,
        "140k": 3,
        "145k": 6,
        "150k": 2,
        "155k": 2,
        "160k": 5,
        "165k": 7,
        "170k": 1,
        "175k": 4,
        "180k": 5,
        "185k": 5,
        "190k": 5,
        "195k": 1,
        "200k": 2,
        "350k": 17
      },
      nonRelativefm: {
        "5k": 25,
        "10k": 25,
        "15k": 31,
        "20k": 51,
        "25k": 67,
        "30k": 57,
        "35k": 51,
        "40k": 58,
        "45k": 72,
        "50k": 73,
        "55k": 48,
        "60k": 76,
        "65k": 79,
        "70k": 54,
        "75k": 46,
        "80k": 44,
        "85k": 50,
        "90k": 55,
        "95k": 32,
        "100k": 26,
        "105k": 33,
        "110k": 16,
        "115k": 15,
        "120k": 29,
        "125k": 33,
        "130k": 22,
        "135k": 8,
        "140k": 16,
        "145k": 9,
        "150k": 18,
        "155k": 18,
        "160k": 14,
        "165k": 5,
        "170k": 8,
        "175k": 12,
        "180k": 3,
        "185k": 3,
        "190k": 3,
        "195k": 5,
        "200k": 13,
        "350k": 35
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 124,
        "10k": 122,
        "15k": 173,
        "20k": 195,
        "25k": 235,
        "30k": 215,
        "35k": 251,
        "40k": 279,
        "45k": 285,
        "50k": 237,
        "55k": 237,
        "60k": 222,
        "65k": 235,
        "70k": 198,
        "75k": 241,
        "80k": 167,
        "85k": 236,
        "90k": 161,
        "95k": 141,
        "100k": 110,
        "105k": 122,
        "110k": 78,
        "115k": 103,
        "120k": 67,
        "125k": 89,
        "130k": 58,
        "135k": 64,
        "140k": 62,
        "145k": 43,
        "150k": 43,
        "155k": 31,
        "160k": 35,
        "165k": 15,
        "170k": 26,
        "175k": 19,
        "180k": 24,
        "185k": 28,
        "190k": 16,
        "195k": 25,
        "200k": 18,
        "350k": 132
      },
      spousem: {
        "5k": 17,
        "10k": 28,
        "15k": 41,
        "20k": 59,
        "25k": 82,
        "30k": 71,
        "35k": 57,
        "40k": 90,
        "45k": 98,
        "50k": 98,
        "55k": 114,
        "60k": 105,
        "65k": 104,
        "70k": 94,
        "75k": 93,
        "80k": 75,
        "85k": 73,
        "90k": 66,
        "95k": 73,
        "100k": 53,
        "105k": 65,
        "110k": 45,
        "115k": 63,
        "120k": 33,
        "125k": 58,
        "130k": 30,
        "135k": 21,
        "140k": 24,
        "145k": 24,
        "150k": 14,
        "155k": 24,
        "160k": 8,
        "165k": 7,
        "170k": 4,
        "175k": 16,
        "180k": 8,
        "185k": 7,
        "190k": 4,
        "195k": 5,
        "200k": 10,
        "350k": 75
      },
      childm: {
        "5k": 11,
        "10k": 18,
        "15k": 39,
        "20k": 28,
        "25k": 55,
        "30k": 64,
        "35k": 36,
        "40k": 34,
        "45k": 61,
        "50k": 37,
        "55k": 64,
        "60k": 29,
        "65k": 39,
        "70k": 28,
        "75k": 65,
        "80k": 37,
        "85k": 58,
        "90k": 30,
        "95k": 21,
        "100k": 40,
        "105k": 29,
        "110k": 30,
        "115k": 18,
        "120k": 34,
        "125k": 13,
        "130k": 16,
        "135k": 9,
        "140k": 25,
        "145k": 20,
        "150k": 8,
        "155k": 12,
        "160k": 8,
        "165k": 12,
        "170k": 16,
        "175k": 3,
        "180k": 13,
        "185k": 8,
        "190k": 5,
        "195k": 9,
        "200k": 4,
        "350k": 60
      },
      otherRelativem: {
        "5k": 0,
        "10k": 7,
        "15k": 10,
        "20k": 4,
        "25k": 28,
        "30k": 21,
        "35k": 31,
        "40k": 23,
        "45k": 33,
        "50k": 21,
        "55k": 25,
        "60k": 17,
        "65k": 22,
        "70k": 13,
        "75k": 25,
        "80k": 18,
        "85k": 20,
        "90k": 19,
        "95k": 24,
        "100k": 7,
        "105k": 12,
        "110k": 9,
        "115k": 8,
        "120k": 15,
        "125k": 9,
        "130k": 6,
        "135k": 9,
        "140k": 8,
        "145k": 8,
        "150k": 3,
        "155k": 3,
        "160k": 2,
        "165k": 3,
        "170k": 2,
        "175k": 4,
        "180k": 0,
        "185k": 2,
        "190k": 2,
        "195k": 3,
        "200k": 0,
        "350k": 18
      },
      nonRelativem: {
        "5k": 9,
        "10k": 31,
        "15k": 33,
        "20k": 39,
        "25k": 44,
        "30k": 46,
        "35k": 51,
        "40k": 60,
        "45k": 63,
        "50k": 53,
        "55k": 53,
        "60k": 61,
        "65k": 37,
        "70k": 35,
        "75k": 33,
        "80k": 40,
        "85k": 30,
        "90k": 36,
        "95k": 23,
        "100k": 34,
        "105k": 27,
        "110k": 20,
        "115k": 7,
        "120k": 12,
        "125k": 7,
        "130k": 14,
        "135k": 11,
        "140k": 23,
        "145k": 10,
        "150k": 8,
        "155k": 3,
        "160k": 5,
        "165k": 8,
        "170k": 4,
        "175k": 9,
        "180k": 17,
        "185k": 8,
        "190k": 2,
        "195k": 6,
        "200k": 0,
        "350k": 37
      },
      houseLoitererfm: {
        "5k": 241,
        "10k": 198,
        "15k": 245,
        "20k": 255,
        "25k": 312,
        "30k": 269,
        "35k": 241,
        "40k": 275,
        "45k": 306,
        "50k": 265,
        "55k": 264,
        "60k": 174,
        "65k": 176,
        "70k": 172,
        "75k": 178,
        "80k": 126,
        "85k": 124,
        "90k": 131,
        "95k": 130,
        "100k": 85,
        "105k": 116,
        "110k": 61,
        "115k": 80,
        "120k": 75,
        "125k": 59,
        "130k": 42,
        "135k": 41,
        "140k": 47,
        "145k": 33,
        "150k": 23,
        "155k": 48,
        "160k": 22,
        "165k": 17,
        "170k": 8,
        "175k": 23,
        "180k": 10,
        "185k": 14,
        "190k": 4,
        "195k": 11,
        "200k": 13,
        "350k": 130
      },
      spousefm: {
        "5k": 32,
        "10k": 30,
        "15k": 65,
        "20k": 98,
        "25k": 96,
        "30k": 117,
        "35k": 111,
        "40k": 121,
        "45k": 136,
        "50k": 137,
        "55k": 158,
        "60k": 153,
        "65k": 131,
        "70k": 150,
        "75k": 168,
        "80k": 138,
        "85k": 179,
        "90k": 114,
        "95k": 123,
        "100k": 84,
        "105k": 119,
        "110k": 82,
        "115k": 79,
        "120k": 46,
        "125k": 84,
        "130k": 46,
        "135k": 42,
        "140k": 51,
        "145k": 46,
        "150k": 34,
        "155k": 30,
        "160k": 20,
        "165k": 21,
        "170k": 17,
        "175k": 18,
        "180k": 21,
        "185k": 23,
        "190k": 15,
        "195k": 22,
        "200k": 14,
        "350k": 146
      },
      childfm: {
        "5k": 11,
        "10k": 5,
        "15k": 5,
        "20k": 25,
        "25k": 21,
        "30k": 28,
        "35k": 13,
        "40k": 24,
        "45k": 37,
        "50k": 37,
        "55k": 42,
        "60k": 24,
        "65k": 26,
        "70k": 30,
        "75k": 27,
        "80k": 28,
        "85k": 23,
        "90k": 24,
        "95k": 19,
        "100k": 11,
        "105k": 7,
        "110k": 15,
        "115k": 5,
        "120k": 18,
        "125k": 6,
        "130k": 3,
        "135k": 8,
        "140k": 9,
        "145k": 4,
        "150k": 7,
        "155k": 14,
        "160k": 4,
        "165k": 2,
        "170k": 3,
        "175k": 12,
        "180k": 2,
        "185k": 6,
        "190k": 6,
        "195k": 6,
        "200k": 0,
        "350k": 21
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 15,
        "15k": 14,
        "20k": 15,
        "25k": 19,
        "30k": 17,
        "35k": 25,
        "40k": 12,
        "45k": 15,
        "50k": 17,
        "55k": 11,
        "60k": 13,
        "65k": 16,
        "70k": 19,
        "75k": 18,
        "80k": 8,
        "85k": 8,
        "90k": 4,
        "95k": 20,
        "100k": 8,
        "105k": 5,
        "110k": 8,
        "115k": 0,
        "120k": 8,
        "125k": 3,
        "130k": 4,
        "135k": 4,
        "140k": 2,
        "145k": 4,
        "150k": 5,
        "155k": 2,
        "160k": 0,
        "165k": 1,
        "170k": 6,
        "175k": 2,
        "180k": 3,
        "185k": 0,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 35
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 8,
        "15k": 19,
        "20k": 22,
        "25k": 33,
        "30k": 27,
        "35k": 37,
        "40k": 36,
        "45k": 22,
        "50k": 40,
        "55k": 21,
        "60k": 38,
        "65k": 36,
        "70k": 48,
        "75k": 43,
        "80k": 30,
        "85k": 29,
        "90k": 17,
        "95k": 26,
        "100k": 25,
        "105k": 34,
        "110k": 4,
        "115k": 6,
        "120k": 18,
        "125k": 12,
        "130k": 7,
        "135k": 11,
        "140k": 8,
        "145k": 7,
        "150k": 6,
        "155k": 3,
        "160k": 7,
        "165k": 0,
        "170k": 2,
        "175k": 2,
        "180k": 6,
        "185k": 2,
        "190k": 8,
        "195k": 2,
        "200k": 5,
        "350k": 41
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 246,
        "10k": 200,
        "15k": 347,
        "20k": 302,
        "25k": 458,
        "30k": 434,
        "35k": 467,
        "40k": 476,
        "45k": 457,
        "50k": 480,
        "55k": 502,
        "60k": 410,
        "65k": 464,
        "70k": 411,
        "75k": 448,
        "80k": 401,
        "85k": 413,
        "90k": 300,
        "95k": 365,
        "100k": 272,
        "105k": 389,
        "110k": 222,
        "115k": 269,
        "120k": 171,
        "125k": 200,
        "130k": 138,
        "135k": 184,
        "140k": 131,
        "145k": 140,
        "150k": 103,
        "155k": 117,
        "160k": 84,
        "165k": 93,
        "170k": 61,
        "175k": 50,
        "180k": 59,
        "185k": 64,
        "190k": 45,
        "195k": 40,
        "200k": 51,
        "350k": 632
      },
      spousem: {
        "5k": 65,
        "10k": 33,
        "15k": 69,
        "20k": 83,
        "25k": 132,
        "30k": 163,
        "35k": 168,
        "40k": 193,
        "45k": 210,
        "50k": 197,
        "55k": 234,
        "60k": 186,
        "65k": 198,
        "70k": 199,
        "75k": 196,
        "80k": 162,
        "85k": 228,
        "90k": 177,
        "95k": 169,
        "100k": 124,
        "105k": 176,
        "110k": 153,
        "115k": 127,
        "120k": 120,
        "125k": 111,
        "130k": 92,
        "135k": 93,
        "140k": 79,
        "145k": 72,
        "150k": 73,
        "155k": 86,
        "160k": 55,
        "165k": 47,
        "170k": 42,
        "175k": 34,
        "180k": 23,
        "185k": 35,
        "190k": 12,
        "195k": 29,
        "200k": 24,
        "350k": 336
      },
      childm: {
        "5k": 34,
        "10k": 17,
        "15k": 32,
        "20k": 47,
        "25k": 72,
        "30k": 59,
        "35k": 66,
        "40k": 71,
        "45k": 46,
        "50k": 75,
        "55k": 46,
        "60k": 66,
        "65k": 41,
        "70k": 49,
        "75k": 45,
        "80k": 37,
        "85k": 47,
        "90k": 57,
        "95k": 49,
        "100k": 26,
        "105k": 38,
        "110k": 22,
        "115k": 17,
        "120k": 28,
        "125k": 14,
        "130k": 17,
        "135k": 12,
        "140k": 9,
        "145k": 25,
        "150k": 13,
        "155k": 18,
        "160k": 4,
        "165k": 5,
        "170k": 5,
        "175k": 9,
        "180k": 9,
        "185k": 4,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 51
      },
      otherRelativem: {
        "5k": 17,
        "10k": 26,
        "15k": 23,
        "20k": 25,
        "25k": 46,
        "30k": 28,
        "35k": 49,
        "40k": 41,
        "45k": 49,
        "50k": 46,
        "55k": 51,
        "60k": 29,
        "65k": 48,
        "70k": 32,
        "75k": 24,
        "80k": 18,
        "85k": 32,
        "90k": 31,
        "95k": 31,
        "100k": 19,
        "105k": 17,
        "110k": 26,
        "115k": 17,
        "120k": 9,
        "125k": 10,
        "130k": 5,
        "135k": 23,
        "140k": 16,
        "145k": 7,
        "150k": 9,
        "155k": 13,
        "160k": 2,
        "165k": 6,
        "170k": 6,
        "175k": 4,
        "180k": 2,
        "185k": 5,
        "190k": 2,
        "195k": 1,
        "200k": 4,
        "350k": 24
      },
      nonRelativem: {
        "5k": 29,
        "10k": 35,
        "15k": 84,
        "20k": 61,
        "25k": 68,
        "30k": 75,
        "35k": 69,
        "40k": 67,
        "45k": 79,
        "50k": 65,
        "55k": 97,
        "60k": 55,
        "65k": 71,
        "70k": 52,
        "75k": 67,
        "80k": 46,
        "85k": 37,
        "90k": 45,
        "95k": 35,
        "100k": 35,
        "105k": 35,
        "110k": 20,
        "115k": 13,
        "120k": 15,
        "125k": 21,
        "130k": 18,
        "135k": 20,
        "140k": 21,
        "145k": 10,
        "150k": 16,
        "155k": 14,
        "160k": 4,
        "165k": 4,
        "170k": 13,
        "175k": 15,
        "180k": 6,
        "185k": 3,
        "190k": 9,
        "195k": 7,
        "200k": 5,
        "350k": 61
      },
      houseLoitererfm: {
        "5k": 433,
        "10k": 357,
        "15k": 419,
        "20k": 519,
        "25k": 527,
        "30k": 515,
        "35k": 575,
        "40k": 516,
        "45k": 480,
        "50k": 451,
        "55k": 469,
        "60k": 384,
        "65k": 396,
        "70k": 334,
        "75k": 341,
        "80k": 288,
        "85k": 319,
        "90k": 224,
        "95k": 228,
        "100k": 192,
        "105k": 257,
        "110k": 194,
        "115k": 176,
        "120k": 149,
        "125k": 143,
        "130k": 139,
        "135k": 120,
        "140k": 82,
        "145k": 87,
        "150k": 85,
        "155k": 107,
        "160k": 68,
        "165k": 54,
        "170k": 48,
        "175k": 44,
        "180k": 37,
        "185k": 53,
        "190k": 15,
        "195k": 46,
        "200k": 31,
        "350k": 460
      },
      spousefm: {
        "5k": 62,
        "10k": 68,
        "15k": 117,
        "20k": 124,
        "25k": 177,
        "30k": 226,
        "35k": 227,
        "40k": 231,
        "45k": 239,
        "50k": 268,
        "55k": 286,
        "60k": 224,
        "65k": 304,
        "70k": 248,
        "75k": 296,
        "80k": 307,
        "85k": 247,
        "90k": 263,
        "95k": 306,
        "100k": 228,
        "105k": 305,
        "110k": 183,
        "115k": 267,
        "120k": 149,
        "125k": 168,
        "130k": 115,
        "135k": 165,
        "140k": 116,
        "145k": 127,
        "150k": 112,
        "155k": 124,
        "160k": 95,
        "165k": 85,
        "170k": 51,
        "175k": 52,
        "180k": 53,
        "185k": 57,
        "190k": 54,
        "195k": 39,
        "200k": 46,
        "350k": 627
      },
      childfm: {
        "5k": 20,
        "10k": 24,
        "15k": 33,
        "20k": 19,
        "25k": 45,
        "30k": 38,
        "35k": 49,
        "40k": 37,
        "45k": 28,
        "50k": 46,
        "55k": 37,
        "60k": 37,
        "65k": 34,
        "70k": 37,
        "75k": 35,
        "80k": 43,
        "85k": 24,
        "90k": 26,
        "95k": 21,
        "100k": 24,
        "105k": 24,
        "110k": 26,
        "115k": 12,
        "120k": 8,
        "125k": 14,
        "130k": 15,
        "135k": 13,
        "140k": 10,
        "145k": 8,
        "150k": 10,
        "155k": 12,
        "160k": 5,
        "165k": 8,
        "170k": 3,
        "175k": 8,
        "180k": 3,
        "185k": 1,
        "190k": 2,
        "195k": 4,
        "200k": 2,
        "350k": 26
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 23,
        "15k": 33,
        "20k": 28,
        "25k": 53,
        "30k": 40,
        "35k": 49,
        "40k": 32,
        "45k": 54,
        "50k": 37,
        "55k": 38,
        "60k": 27,
        "65k": 26,
        "70k": 28,
        "75k": 27,
        "80k": 18,
        "85k": 34,
        "90k": 15,
        "95k": 16,
        "100k": 13,
        "105k": 8,
        "110k": 25,
        "115k": 17,
        "120k": 6,
        "125k": 17,
        "130k": 0,
        "135k": 13,
        "140k": 6,
        "145k": 2,
        "150k": 9,
        "155k": 13,
        "160k": 5,
        "165k": 4,
        "170k": 0,
        "175k": 11,
        "180k": 4,
        "185k": 4,
        "190k": 1,
        "195k": 0,
        "200k": 2,
        "350k": 26
      },
      nonRelativefm: {
        "5k": 11,
        "10k": 17,
        "15k": 20,
        "20k": 25,
        "25k": 50,
        "30k": 29,
        "35k": 37,
        "40k": 56,
        "45k": 37,
        "50k": 42,
        "55k": 39,
        "60k": 51,
        "65k": 45,
        "70k": 51,
        "75k": 30,
        "80k": 25,
        "85k": 42,
        "90k": 27,
        "95k": 26,
        "100k": 30,
        "105k": 19,
        "110k": 22,
        "115k": 18,
        "120k": 6,
        "125k": 22,
        "130k": 18,
        "135k": 12,
        "140k": 16,
        "145k": 24,
        "150k": 9,
        "155k": 18,
        "160k": 6,
        "165k": 5,
        "170k": 4,
        "175k": 10,
        "180k": 4,
        "185k": 2,
        "190k": 1,
        "195k": 3,
        "200k": 2,
        "350k": 21
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 360,
        "10k": 357,
        "15k": 455,
        "20k": 422,
        "25k": 495,
        "30k": 494,
        "35k": 523,
        "40k": 473,
        "45k": 515,
        "50k": 468,
        "55k": 596,
        "60k": 449,
        "65k": 487,
        "70k": 439,
        "75k": 467,
        "80k": 456,
        "85k": 408,
        "90k": 390,
        "95k": 339,
        "100k": 344,
        "105k": 368,
        "110k": 289,
        "115k": 265,
        "120k": 259,
        "125k": 252,
        "130k": 210,
        "135k": 207,
        "140k": 178,
        "145k": 201,
        "150k": 129,
        "155k": 181,
        "160k": 117,
        "165k": 134,
        "170k": 108,
        "175k": 98,
        "180k": 66,
        "185k": 79,
        "190k": 55,
        "195k": 49,
        "200k": 70,
        "350k": 867
      },
      spousem: {
        "5k": 73,
        "10k": 38,
        "15k": 102,
        "20k": 103,
        "25k": 132,
        "30k": 138,
        "35k": 159,
        "40k": 124,
        "45k": 173,
        "50k": 168,
        "55k": 210,
        "60k": 180,
        "65k": 234,
        "70k": 189,
        "75k": 222,
        "80k": 172,
        "85k": 216,
        "90k": 205,
        "95k": 183,
        "100k": 180,
        "105k": 193,
        "110k": 149,
        "115k": 139,
        "120k": 134,
        "125k": 137,
        "130k": 121,
        "135k": 98,
        "140k": 80,
        "145k": 82,
        "150k": 66,
        "155k": 105,
        "160k": 75,
        "165k": 55,
        "170k": 70,
        "175k": 67,
        "180k": 41,
        "185k": 36,
        "190k": 33,
        "195k": 32,
        "200k": 25,
        "350k": 516
      },
      childm: {
        "5k": 21,
        "10k": 26,
        "15k": 54,
        "20k": 50,
        "25k": 63,
        "30k": 44,
        "35k": 69,
        "40k": 42,
        "45k": 51,
        "50k": 48,
        "55k": 40,
        "60k": 22,
        "65k": 50,
        "70k": 28,
        "75k": 26,
        "80k": 24,
        "85k": 21,
        "90k": 17,
        "95k": 20,
        "100k": 18,
        "105k": 11,
        "110k": 20,
        "115k": 9,
        "120k": 11,
        "125k": 6,
        "130k": 8,
        "135k": 9,
        "140k": 9,
        "145k": 5,
        "150k": 1,
        "155k": 7,
        "160k": 4,
        "165k": 2,
        "170k": 1,
        "175k": 0,
        "180k": 3,
        "185k": 4,
        "190k": 2,
        "195k": 2,
        "200k": 0,
        "350k": 15
      },
      otherRelativem: {
        "5k": 18,
        "10k": 16,
        "15k": 26,
        "20k": 29,
        "25k": 60,
        "30k": 71,
        "35k": 53,
        "40k": 49,
        "45k": 61,
        "50k": 42,
        "55k": 51,
        "60k": 51,
        "65k": 64,
        "70k": 38,
        "75k": 48,
        "80k": 35,
        "85k": 39,
        "90k": 20,
        "95k": 34,
        "100k": 27,
        "105k": 27,
        "110k": 21,
        "115k": 22,
        "120k": 16,
        "125k": 16,
        "130k": 21,
        "135k": 12,
        "140k": 11,
        "145k": 5,
        "150k": 10,
        "155k": 8,
        "160k": 8,
        "165k": 1,
        "170k": 7,
        "175k": 3,
        "180k": 4,
        "185k": 2,
        "190k": 0,
        "195k": 4,
        "200k": 2,
        "350k": 44
      },
      nonRelativem: {
        "5k": 13,
        "10k": 29,
        "15k": 35,
        "20k": 50,
        "25k": 51,
        "30k": 32,
        "35k": 74,
        "40k": 40,
        "45k": 40,
        "50k": 35,
        "55k": 48,
        "60k": 49,
        "65k": 27,
        "70k": 36,
        "75k": 30,
        "80k": 36,
        "85k": 26,
        "90k": 23,
        "95k": 18,
        "100k": 39,
        "105k": 28,
        "110k": 24,
        "115k": 21,
        "120k": 14,
        "125k": 11,
        "130k": 11,
        "135k": 9,
        "140k": 6,
        "145k": 5,
        "150k": 11,
        "155k": 7,
        "160k": 6,
        "165k": 3,
        "170k": 8,
        "175k": 7,
        "180k": 6,
        "185k": 10,
        "190k": 10,
        "195k": 11,
        "200k": 4,
        "350k": 44
      },
      houseLoitererfm: {
        "5k": 461,
        "10k": 541,
        "15k": 528,
        "20k": 562,
        "25k": 604,
        "30k": 570,
        "35k": 555,
        "40k": 518,
        "45k": 528,
        "50k": 423,
        "55k": 472,
        "60k": 441,
        "65k": 458,
        "70k": 368,
        "75k": 421,
        "80k": 295,
        "85k": 301,
        "90k": 305,
        "95k": 243,
        "100k": 261,
        "105k": 236,
        "110k": 183,
        "115k": 213,
        "120k": 189,
        "125k": 183,
        "130k": 116,
        "135k": 111,
        "140k": 122,
        "145k": 90,
        "150k": 100,
        "155k": 117,
        "160k": 90,
        "165k": 74,
        "170k": 73,
        "175k": 87,
        "180k": 52,
        "185k": 41,
        "190k": 44,
        "195k": 42,
        "200k": 24,
        "350k": 608
      },
      spousefm: {
        "5k": 95,
        "10k": 61,
        "15k": 122,
        "20k": 120,
        "25k": 207,
        "30k": 217,
        "35k": 243,
        "40k": 246,
        "45k": 209,
        "50k": 247,
        "55k": 311,
        "60k": 299,
        "65k": 279,
        "70k": 336,
        "75k": 294,
        "80k": 304,
        "85k": 299,
        "90k": 297,
        "95k": 248,
        "100k": 273,
        "105k": 261,
        "110k": 245,
        "115k": 192,
        "120k": 244,
        "125k": 191,
        "130k": 216,
        "135k": 188,
        "140k": 172,
        "145k": 190,
        "150k": 120,
        "155k": 142,
        "160k": 118,
        "165k": 118,
        "170k": 113,
        "175k": 110,
        "180k": 67,
        "185k": 75,
        "190k": 44,
        "195k": 50,
        "200k": 76,
        "350k": 803
      },
      childfm: {
        "5k": 7,
        "10k": 8,
        "15k": 13,
        "20k": 24,
        "25k": 21,
        "30k": 37,
        "35k": 33,
        "40k": 67,
        "45k": 26,
        "50k": 26,
        "55k": 35,
        "60k": 21,
        "65k": 28,
        "70k": 16,
        "75k": 18,
        "80k": 12,
        "85k": 20,
        "90k": 15,
        "95k": 13,
        "100k": 8,
        "105k": 15,
        "110k": 14,
        "115k": 2,
        "120k": 4,
        "125k": 4,
        "130k": 3,
        "135k": 8,
        "140k": 4,
        "145k": 12,
        "150k": 1,
        "155k": 5,
        "160k": 7,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 15
      },
      otherRelativefm: {
        "5k": 28,
        "10k": 29,
        "15k": 34,
        "20k": 52,
        "25k": 60,
        "30k": 51,
        "35k": 56,
        "40k": 46,
        "45k": 73,
        "50k": 51,
        "55k": 66,
        "60k": 39,
        "65k": 44,
        "70k": 34,
        "75k": 57,
        "80k": 27,
        "85k": 38,
        "90k": 28,
        "95k": 28,
        "100k": 38,
        "105k": 34,
        "110k": 31,
        "115k": 29,
        "120k": 10,
        "125k": 25,
        "130k": 24,
        "135k": 20,
        "140k": 8,
        "145k": 7,
        "150k": 12,
        "155k": 6,
        "160k": 6,
        "165k": 8,
        "170k": 7,
        "175k": 10,
        "180k": 5,
        "185k": 2,
        "190k": 1,
        "195k": 2,
        "200k": 2,
        "350k": 29
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 18,
        "15k": 17,
        "20k": 45,
        "25k": 53,
        "30k": 40,
        "35k": 43,
        "40k": 40,
        "45k": 26,
        "50k": 40,
        "55k": 41,
        "60k": 39,
        "65k": 31,
        "70k": 26,
        "75k": 45,
        "80k": 29,
        "85k": 18,
        "90k": 19,
        "95k": 27,
        "100k": 15,
        "105k": 18,
        "110k": 8,
        "115k": 16,
        "120k": 14,
        "125k": 31,
        "130k": 15,
        "135k": 14,
        "140k": 11,
        "145k": 14,
        "150k": 15,
        "155k": 13,
        "160k": 7,
        "165k": 3,
        "170k": 6,
        "175k": 4,
        "180k": 2,
        "185k": 0,
        "190k": 10,
        "195k": 1,
        "200k": 2,
        "350k": 47
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 388,
        "10k": 382,
        "15k": 479,
        "20k": 459,
        "25k": 520,
        "30k": 460,
        "35k": 489,
        "40k": 524,
        "45k": 469,
        "50k": 487,
        "55k": 504,
        "60k": 444,
        "65k": 421,
        "70k": 389,
        "75k": 376,
        "80k": 403,
        "85k": 374,
        "90k": 319,
        "95k": 318,
        "100k": 260,
        "105k": 318,
        "110k": 263,
        "115k": 207,
        "120k": 165,
        "125k": 176,
        "130k": 185,
        "135k": 180,
        "140k": 152,
        "145k": 157,
        "150k": 99,
        "155k": 151,
        "160k": 97,
        "165k": 81,
        "170k": 93,
        "175k": 120,
        "180k": 107,
        "185k": 71,
        "190k": 63,
        "195k": 68,
        "200k": 40,
        "350k": 749
      },
      spousem: {
        "5k": 42,
        "10k": 37,
        "15k": 90,
        "20k": 105,
        "25k": 100,
        "30k": 145,
        "35k": 166,
        "40k": 143,
        "45k": 190,
        "50k": 185,
        "55k": 200,
        "60k": 154,
        "65k": 187,
        "70k": 177,
        "75k": 201,
        "80k": 155,
        "85k": 148,
        "90k": 175,
        "95k": 122,
        "100k": 123,
        "105k": 149,
        "110k": 85,
        "115k": 133,
        "120k": 103,
        "125k": 82,
        "130k": 98,
        "135k": 94,
        "140k": 67,
        "145k": 53,
        "150k": 66,
        "155k": 81,
        "160k": 37,
        "165k": 66,
        "170k": 36,
        "175k": 54,
        "180k": 45,
        "185k": 44,
        "190k": 32,
        "195k": 26,
        "200k": 18,
        "350k": 390
      },
      childm: {
        "5k": 4,
        "10k": 10,
        "15k": 9,
        "20k": 12,
        "25k": 29,
        "30k": 21,
        "35k": 19,
        "40k": 20,
        "45k": 13,
        "50k": 11,
        "55k": 18,
        "60k": 8,
        "65k": 9,
        "70k": 6,
        "75k": 8,
        "80k": 2,
        "85k": 0,
        "90k": 2,
        "95k": 6,
        "100k": 2,
        "105k": 8,
        "110k": 4,
        "115k": 0,
        "120k": 1,
        "125k": 4,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 1,
        "160k": 0,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      otherRelativem: {
        "5k": 14,
        "10k": 15,
        "15k": 31,
        "20k": 15,
        "25k": 29,
        "30k": 38,
        "35k": 16,
        "40k": 18,
        "45k": 27,
        "50k": 38,
        "55k": 25,
        "60k": 29,
        "65k": 34,
        "70k": 26,
        "75k": 33,
        "80k": 19,
        "85k": 27,
        "90k": 19,
        "95k": 9,
        "100k": 12,
        "105k": 30,
        "110k": 20,
        "115k": 17,
        "120k": 10,
        "125k": 9,
        "130k": 7,
        "135k": 13,
        "140k": 6,
        "145k": 9,
        "150k": 24,
        "155k": 3,
        "160k": 12,
        "165k": 2,
        "170k": 3,
        "175k": 7,
        "180k": 2,
        "185k": 8,
        "190k": 3,
        "195k": 1,
        "200k": 4,
        "350k": 41
      },
      nonRelativem: {
        "5k": 11,
        "10k": 15,
        "15k": 8,
        "20k": 25,
        "25k": 29,
        "30k": 23,
        "35k": 23,
        "40k": 34,
        "45k": 15,
        "50k": 19,
        "55k": 21,
        "60k": 30,
        "65k": 27,
        "70k": 13,
        "75k": 16,
        "80k": 16,
        "85k": 13,
        "90k": 22,
        "95k": 19,
        "100k": 11,
        "105k": 25,
        "110k": 16,
        "115k": 8,
        "120k": 6,
        "125k": 14,
        "130k": 11,
        "135k": 3,
        "140k": 4,
        "145k": 5,
        "150k": 7,
        "155k": 6,
        "160k": 0,
        "165k": 8,
        "170k": 7,
        "175k": 1,
        "180k": 1,
        "185k": 3,
        "190k": 6,
        "195k": 2,
        "200k": 2,
        "350k": 24
      },
      houseLoitererfm: {
        "5k": 385,
        "10k": 568,
        "15k": 673,
        "20k": 628,
        "25k": 474,
        "30k": 572,
        "35k": 531,
        "40k": 485,
        "45k": 547,
        "50k": 421,
        "55k": 409,
        "60k": 373,
        "65k": 387,
        "70k": 270,
        "75k": 355,
        "80k": 266,
        "85k": 223,
        "90k": 238,
        "95k": 218,
        "100k": 129,
        "105k": 221,
        "110k": 125,
        "115k": 141,
        "120k": 140,
        "125k": 108,
        "130k": 121,
        "135k": 114,
        "140k": 68,
        "145k": 89,
        "150k": 65,
        "155k": 95,
        "160k": 36,
        "165k": 83,
        "170k": 47,
        "175k": 56,
        "180k": 44,
        "185k": 43,
        "190k": 27,
        "195k": 22,
        "200k": 22,
        "350k": 418
      },
      spousefm: {
        "5k": 108,
        "10k": 51,
        "15k": 129,
        "20k": 162,
        "25k": 211,
        "30k": 231,
        "35k": 230,
        "40k": 313,
        "45k": 291,
        "50k": 301,
        "55k": 287,
        "60k": 320,
        "65k": 304,
        "70k": 267,
        "75k": 341,
        "80k": 279,
        "85k": 274,
        "90k": 244,
        "95k": 226,
        "100k": 219,
        "105k": 217,
        "110k": 210,
        "115k": 154,
        "120k": 125,
        "125k": 140,
        "130k": 130,
        "135k": 122,
        "140k": 103,
        "145k": 103,
        "150k": 72,
        "155k": 88,
        "160k": 66,
        "165k": 52,
        "170k": 74,
        "175k": 65,
        "180k": 76,
        "185k": 56,
        "190k": 42,
        "195k": 57,
        "200k": 21,
        "350k": 568
      },
      childfm: {
        "5k": 0,
        "10k": 9,
        "15k": 12,
        "20k": 10,
        "25k": 19,
        "30k": 15,
        "35k": 18,
        "40k": 17,
        "45k": 29,
        "50k": 16,
        "55k": 2,
        "60k": 13,
        "65k": 5,
        "70k": 1,
        "75k": 7,
        "80k": 4,
        "85k": 4,
        "90k": 2,
        "95k": 3,
        "100k": 4,
        "105k": 3,
        "110k": 3,
        "115k": 0,
        "120k": 4,
        "125k": 1,
        "130k": 0,
        "135k": 3,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 3,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 18,
        "10k": 19,
        "15k": 34,
        "20k": 46,
        "25k": 57,
        "30k": 56,
        "35k": 55,
        "40k": 54,
        "45k": 48,
        "50k": 65,
        "55k": 44,
        "60k": 52,
        "65k": 41,
        "70k": 41,
        "75k": 35,
        "80k": 33,
        "85k": 55,
        "90k": 41,
        "95k": 15,
        "100k": 16,
        "105k": 37,
        "110k": 18,
        "115k": 30,
        "120k": 25,
        "125k": 18,
        "130k": 15,
        "135k": 10,
        "140k": 8,
        "145k": 13,
        "150k": 14,
        "155k": 6,
        "160k": 8,
        "165k": 6,
        "170k": 2,
        "175k": 9,
        "180k": 2,
        "185k": 12,
        "190k": 3,
        "195k": 3,
        "200k": 5,
        "350k": 52
      },
      nonRelativefm: {
        "5k": 9,
        "10k": 17,
        "15k": 30,
        "20k": 21,
        "25k": 21,
        "30k": 34,
        "35k": 20,
        "40k": 17,
        "45k": 30,
        "50k": 35,
        "55k": 21,
        "60k": 21,
        "65k": 18,
        "70k": 13,
        "75k": 23,
        "80k": 15,
        "85k": 18,
        "90k": 10,
        "95k": 13,
        "100k": 16,
        "105k": 11,
        "110k": 4,
        "115k": 5,
        "120k": 15,
        "125k": 8,
        "130k": 9,
        "135k": 5,
        "140k": 10,
        "145k": 4,
        "150k": 4,
        "155k": 3,
        "160k": 7,
        "165k": 0,
        "170k": 3,
        "175k": 5,
        "180k": 1,
        "185k": 7,
        "190k": 9,
        "195k": 3,
        "200k": 2,
        "350k": 24
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 117,
        "10k": 206,
        "15k": 363,
        "20k": 446,
        "25k": 430,
        "30k": 465,
        "35k": 416,
        "40k": 398,
        "45k": 339,
        "50k": 322,
        "55k": 338,
        "60k": 295,
        "65k": 298,
        "70k": 237,
        "75k": 195,
        "80k": 172,
        "85k": 163,
        "90k": 166,
        "95k": 100,
        "100k": 134,
        "105k": 127,
        "110k": 73,
        "115k": 61,
        "120k": 69,
        "125k": 73,
        "130k": 75,
        "135k": 53,
        "140k": 58,
        "145k": 38,
        "150k": 35,
        "155k": 54,
        "160k": 50,
        "165k": 26,
        "170k": 43,
        "175k": 18,
        "180k": 19,
        "185k": 35,
        "190k": 33,
        "195k": 24,
        "200k": 15,
        "350k": 288
      },
      spousem: {
        "5k": 28,
        "10k": 9,
        "15k": 74,
        "20k": 96,
        "25k": 105,
        "30k": 178,
        "35k": 139,
        "40k": 172,
        "45k": 133,
        "50k": 134,
        "55k": 97,
        "60k": 131,
        "65k": 86,
        "70k": 70,
        "75k": 79,
        "80k": 70,
        "85k": 68,
        "90k": 55,
        "95k": 42,
        "100k": 32,
        "105k": 68,
        "110k": 52,
        "115k": 50,
        "120k": 48,
        "125k": 34,
        "130k": 19,
        "135k": 42,
        "140k": 29,
        "145k": 21,
        "150k": 14,
        "155k": 26,
        "160k": 17,
        "165k": 23,
        "170k": 11,
        "175k": 5,
        "180k": 15,
        "185k": 4,
        "190k": 11,
        "195k": 2,
        "200k": 1,
        "350k": 119
      },
      childm: {
        "5k": 0,
        "10k": 0,
        "15k": 3,
        "20k": 1,
        "25k": 7,
        "30k": 5,
        "35k": 8,
        "40k": 0,
        "45k": 0,
        "50k": 3,
        "55k": 3,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 4,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 14
      },
      otherRelativem: {
        "5k": 2,
        "10k": 5,
        "15k": 12,
        "20k": 15,
        "25k": 25,
        "30k": 16,
        "35k": 19,
        "40k": 13,
        "45k": 18,
        "50k": 30,
        "55k": 25,
        "60k": 24,
        "65k": 20,
        "70k": 11,
        "75k": 15,
        "80k": 15,
        "85k": 15,
        "90k": 15,
        "95k": 3,
        "100k": 14,
        "105k": 16,
        "110k": 8,
        "115k": 9,
        "120k": 7,
        "125k": 29,
        "130k": 6,
        "135k": 5,
        "140k": 8,
        "145k": 0,
        "150k": 2,
        "155k": 4,
        "160k": 2,
        "165k": 4,
        "170k": 2,
        "175k": 2,
        "180k": 2,
        "185k": 10,
        "190k": 4,
        "195k": 2,
        "200k": 0,
        "350k": 24
      },
      nonRelativem: {
        "5k": 0,
        "10k": 6,
        "15k": 7,
        "20k": 7,
        "25k": 27,
        "30k": 7,
        "35k": 15,
        "40k": 21,
        "45k": 15,
        "50k": 9,
        "55k": 14,
        "60k": 7,
        "65k": 20,
        "70k": 4,
        "75k": 8,
        "80k": 3,
        "85k": 6,
        "90k": 3,
        "95k": 5,
        "100k": 0,
        "105k": 4,
        "110k": 2,
        "115k": 3,
        "120k": 2,
        "125k": 0,
        "130k": 3,
        "135k": 2,
        "140k": 1,
        "145k": 0,
        "150k": 0,
        "155k": 3,
        "160k": 5,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 12,
        "195k": 1,
        "200k": 0,
        "350k": 11
      },
      houseLoitererfm: {
        "5k": 163,
        "10k": 439,
        "15k": 731,
        "20k": 715,
        "25k": 539,
        "30k": 545,
        "35k": 447,
        "40k": 412,
        "45k": 328,
        "50k": 315,
        "55k": 225,
        "60k": 198,
        "65k": 233,
        "70k": 157,
        "75k": 159,
        "80k": 73,
        "85k": 112,
        "90k": 99,
        "95k": 92,
        "100k": 67,
        "105k": 68,
        "110k": 60,
        "115k": 73,
        "120k": 45,
        "125k": 30,
        "130k": 35,
        "135k": 34,
        "140k": 40,
        "145k": 18,
        "150k": 25,
        "155k": 37,
        "160k": 33,
        "165k": 19,
        "170k": 14,
        "175k": 8,
        "180k": 21,
        "185k": 4,
        "190k": 10,
        "195k": 11,
        "200k": 1,
        "350k": 88
      },
      spousefm: {
        "5k": 55,
        "10k": 21,
        "15k": 90,
        "20k": 170,
        "25k": 248,
        "30k": 312,
        "35k": 290,
        "40k": 280,
        "45k": 253,
        "50k": 224,
        "55k": 230,
        "60k": 184,
        "65k": 176,
        "70k": 135,
        "75k": 105,
        "80k": 118,
        "85k": 123,
        "90k": 98,
        "95k": 80,
        "100k": 85,
        "105k": 74,
        "110k": 42,
        "115k": 47,
        "120k": 38,
        "125k": 40,
        "130k": 50,
        "135k": 30,
        "140k": 35,
        "145k": 39,
        "150k": 17,
        "155k": 36,
        "160k": 34,
        "165k": 17,
        "170k": 13,
        "175k": 14,
        "180k": 17,
        "185k": 17,
        "190k": 18,
        "195k": 10,
        "200k": 6,
        "350k": 145
      },
      childfm: {
        "5k": 1,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 3,
        "35k": 3,
        "40k": 2,
        "45k": 2,
        "50k": 3,
        "55k": 2,
        "60k": 0,
        "65k": 0,
        "70k": 4,
        "75k": 0,
        "80k": 0,
        "85k": 1,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 3,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      otherRelativefm: {
        "5k": 10,
        "10k": 7,
        "15k": 18,
        "20k": 37,
        "25k": 27,
        "30k": 54,
        "35k": 39,
        "40k": 46,
        "45k": 42,
        "50k": 39,
        "55k": 30,
        "60k": 27,
        "65k": 25,
        "70k": 30,
        "75k": 30,
        "80k": 24,
        "85k": 14,
        "90k": 38,
        "95k": 22,
        "100k": 13,
        "105k": 14,
        "110k": 12,
        "115k": 21,
        "120k": 14,
        "125k": 17,
        "130k": 17,
        "135k": 13,
        "140k": 14,
        "145k": 11,
        "150k": 7,
        "155k": 6,
        "160k": 12,
        "165k": 13,
        "170k": 0,
        "175k": 4,
        "180k": 4,
        "185k": 6,
        "190k": 4,
        "195k": 2,
        "200k": 2,
        "350k": 51
      },
      nonRelativefm: {
        "5k": 5,
        "10k": 5,
        "15k": 5,
        "20k": 12,
        "25k": 7,
        "30k": 24,
        "35k": 7,
        "40k": 23,
        "45k": 1,
        "50k": 6,
        "55k": 16,
        "60k": 0,
        "65k": 11,
        "70k": 3,
        "75k": 5,
        "80k": 4,
        "85k": 0,
        "90k": 11,
        "95k": 5,
        "100k": 2,
        "105k": 0,
        "110k": 5,
        "115k": 0,
        "120k": 2,
        "125k": 1,
        "130k": 0,
        "135k": 0,
        "140k": 3,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 3,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      }
    }
  },
  "2011": {
    "17": {
      houseLoitererm: {
        "5k": 6,
        "10k": 4,
        "15k": 3,
        "20k": 10,
        "25k": 7,
        "30k": 8,
        "35k": 6,
        "40k": 10,
        "45k": 9,
        "50k": 0,
        "55k": 2,
        "60k": 6,
        "65k": 8,
        "70k": 3,
        "75k": 7,
        "80k": 0,
        "85k": 6,
        "90k": 5,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 1,
        "120k": 3,
        "125k": 2,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 1
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 318,
        "10k": 266,
        "15k": 374,
        "20k": 488,
        "25k": 492,
        "30k": 498,
        "35k": 516,
        "40k": 465,
        "45k": 476,
        "50k": 459,
        "55k": 505,
        "60k": 406,
        "65k": 415,
        "70k": 387,
        "75k": 364,
        "80k": 396,
        "85k": 352,
        "90k": 309,
        "95k": 333,
        "100k": 304,
        "105k": 256,
        "110k": 212,
        "115k": 215,
        "120k": 201,
        "125k": 214,
        "130k": 144,
        "135k": 199,
        "140k": 138,
        "145k": 176,
        "150k": 118,
        "155k": 173,
        "160k": 82,
        "165k": 90,
        "170k": 91,
        "175k": 89,
        "180k": 68,
        "185k": 65,
        "190k": 61,
        "195k": 38,
        "200k": 33,
        "350k": 843
      },
      otherRelativem: {
        "5k": 29,
        "10k": 33,
        "15k": 63,
        "20k": 62,
        "25k": 91,
        "30k": 67,
        "35k": 69,
        "40k": 55,
        "45k": 70,
        "50k": 43,
        "55k": 41,
        "60k": 38,
        "65k": 33,
        "70k": 26,
        "75k": 46,
        "80k": 24,
        "85k": 29,
        "90k": 17,
        "95k": 17,
        "100k": 15,
        "105k": 20,
        "110k": 15,
        "115k": 9,
        "120k": 15,
        "125k": 10,
        "130k": 3,
        "135k": 6,
        "140k": 9,
        "145k": 9,
        "150k": 4,
        "155k": 5,
        "160k": 2,
        "165k": 6,
        "170k": 2,
        "175k": 6,
        "180k": 0,
        "185k": 3,
        "190k": 5,
        "195k": 2,
        "200k": 0,
        "350k": 21
      },
      nonRelativem: {
        "5k": 0,
        "10k": 2,
        "15k": 3,
        "20k": 8,
        "25k": 14,
        "30k": 4,
        "35k": 15,
        "40k": 5,
        "45k": 4,
        "50k": 10,
        "55k": 10,
        "60k": 14,
        "65k": 11,
        "70k": 4,
        "75k": 10,
        "80k": 14,
        "85k": 4,
        "90k": 7,
        "95k": 6,
        "100k": 12,
        "105k": 10,
        "110k": 6,
        "115k": 6,
        "120k": 1,
        "125k": 0,
        "130k": 3,
        "135k": 5,
        "140k": 5,
        "145k": 6,
        "150k": 0,
        "155k": 6,
        "160k": 2,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 4,
        "200k": 1,
        "350k": 7
      },
      houseLoitererfm: {
        "5k": 11,
        "10k": 3,
        "15k": 7,
        "20k": 13,
        "25k": 4,
        "30k": 5,
        "35k": 8,
        "40k": 5,
        "45k": 3,
        "50k": 5,
        "55k": 2,
        "60k": 3,
        "65k": 6,
        "70k": 2,
        "75k": 1,
        "80k": 0,
        "85k": 0,
        "90k": 1,
        "95k": 3,
        "100k": 1,
        "105k": 2,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousefm: {
        "5k": 4,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 2,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 310,
        "10k": 274,
        "15k": 367,
        "20k": 451,
        "25k": 461,
        "30k": 534,
        "35k": 531,
        "40k": 507,
        "45k": 463,
        "50k": 443,
        "55k": 405,
        "60k": 354,
        "65k": 466,
        "70k": 374,
        "75k": 346,
        "80k": 405,
        "85k": 321,
        "90k": 281,
        "95k": 278,
        "100k": 229,
        "105k": 285,
        "110k": 198,
        "115k": 224,
        "120k": 166,
        "125k": 164,
        "130k": 141,
        "135k": 142,
        "140k": 116,
        "145k": 126,
        "150k": 126,
        "155k": 138,
        "160k": 98,
        "165k": 92,
        "170k": 84,
        "175k": 72,
        "180k": 56,
        "185k": 68,
        "190k": 45,
        "195k": 49,
        "200k": 48,
        "350k": 729
      },
      otherRelativefm: {
        "5k": 29,
        "10k": 45,
        "15k": 54,
        "20k": 44,
        "25k": 73,
        "30k": 60,
        "35k": 70,
        "40k": 56,
        "45k": 60,
        "50k": 53,
        "55k": 78,
        "60k": 36,
        "65k": 40,
        "70k": 29,
        "75k": 33,
        "80k": 18,
        "85k": 24,
        "90k": 19,
        "95k": 16,
        "100k": 21,
        "105k": 9,
        "110k": 15,
        "115k": 19,
        "120k": 19,
        "125k": 1,
        "130k": 7,
        "135k": 6,
        "140k": 7,
        "145k": 11,
        "150k": 12,
        "155k": 3,
        "160k": 7,
        "165k": 9,
        "170k": 3,
        "175k": 3,
        "180k": 1,
        "185k": 2,
        "190k": 5,
        "195k": 5,
        "200k": 0,
        "350k": 26
      },
      nonRelativefm: {
        "5k": 5,
        "10k": 0,
        "15k": 10,
        "20k": 6,
        "25k": 19,
        "30k": 12,
        "35k": 9,
        "40k": 9,
        "45k": 11,
        "50k": 11,
        "55k": 10,
        "60k": 10,
        "65k": 12,
        "70k": 6,
        "75k": 7,
        "80k": 6,
        "85k": 9,
        "90k": 8,
        "95k": 16,
        "100k": 12,
        "105k": 8,
        "110k": 4,
        "115k": 3,
        "120k": 2,
        "125k": 2,
        "130k": 3,
        "135k": 5,
        "140k": 4,
        "145k": 4,
        "150k": 2,
        "155k": 3,
        "160k": 2,
        "165k": 4,
        "170k": 2,
        "175k": 2,
        "180k": 1,
        "185k": 6,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 6
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 97,
        "10k": 68,
        "15k": 98,
        "20k": 63,
        "25k": 96,
        "30k": 39,
        "35k": 83,
        "40k": 44,
        "45k": 47,
        "50k": 30,
        "55k": 36,
        "60k": 11,
        "65k": 37,
        "70k": 33,
        "75k": 19,
        "80k": 10,
        "85k": 14,
        "90k": 9,
        "95k": 8,
        "100k": 25,
        "105k": 9,
        "110k": 5,
        "115k": 14,
        "120k": 4,
        "125k": 3,
        "130k": 1,
        "135k": 3,
        "140k": 0,
        "145k": 6,
        "150k": 0,
        "155k": 6,
        "160k": 0,
        "165k": 2,
        "170k": 2,
        "175k": 2,
        "180k": 1,
        "185k": 2,
        "190k": 2,
        "195k": 0,
        "200k": 4,
        "350k": 1
      },
      spousem: {
        "5k": 1,
        "10k": 2,
        "15k": 4,
        "20k": 7,
        "25k": 3,
        "30k": 9,
        "35k": 9,
        "40k": 5,
        "45k": 8,
        "50k": 4,
        "55k": 3,
        "60k": 2,
        "65k": 0,
        "70k": 4,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 3,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 111,
        "10k": 109,
        "15k": 177,
        "20k": 206,
        "25k": 210,
        "30k": 262,
        "35k": 236,
        "40k": 235,
        "45k": 275,
        "50k": 229,
        "55k": 249,
        "60k": 247,
        "65k": 234,
        "70k": 221,
        "75k": 233,
        "80k": 193,
        "85k": 189,
        "90k": 182,
        "95k": 187,
        "100k": 146,
        "105k": 139,
        "110k": 143,
        "115k": 148,
        "120k": 133,
        "125k": 137,
        "130k": 83,
        "135k": 126,
        "140k": 90,
        "145k": 88,
        "150k": 83,
        "155k": 82,
        "160k": 68,
        "165k": 71,
        "170k": 68,
        "175k": 46,
        "180k": 48,
        "185k": 39,
        "190k": 42,
        "195k": 39,
        "200k": 31,
        "350k": 443
      },
      otherRelativem: {
        "5k": 20,
        "10k": 20,
        "15k": 40,
        "20k": 45,
        "25k": 49,
        "30k": 46,
        "35k": 67,
        "40k": 59,
        "45k": 37,
        "50k": 30,
        "55k": 44,
        "60k": 37,
        "65k": 42,
        "70k": 24,
        "75k": 21,
        "80k": 20,
        "85k": 24,
        "90k": 20,
        "95k": 20,
        "100k": 14,
        "105k": 15,
        "110k": 6,
        "115k": 12,
        "120k": 8,
        "125k": 15,
        "130k": 11,
        "135k": 6,
        "140k": 3,
        "145k": 11,
        "150k": 4,
        "155k": 2,
        "160k": 2,
        "165k": 8,
        "170k": 0,
        "175k": 2,
        "180k": 2,
        "185k": 1,
        "190k": 6,
        "195k": 1,
        "200k": 4,
        "350k": 9
      },
      nonRelativem: {
        "5k": 64,
        "10k": 21,
        "15k": 48,
        "20k": 37,
        "25k": 39,
        "30k": 38,
        "35k": 54,
        "40k": 43,
        "45k": 34,
        "50k": 38,
        "55k": 22,
        "60k": 23,
        "65k": 21,
        "70k": 31,
        "75k": 13,
        "80k": 32,
        "85k": 20,
        "90k": 13,
        "95k": 12,
        "100k": 14,
        "105k": 10,
        "110k": 2,
        "115k": 9,
        "120k": 7,
        "125k": 9,
        "130k": 1,
        "135k": 3,
        "140k": 2,
        "145k": 1,
        "150k": 3,
        "155k": 1,
        "160k": 1,
        "165k": 4,
        "170k": 2,
        "175k": 4,
        "180k": 6,
        "185k": 5,
        "190k": 8,
        "195k": 0,
        "200k": 0,
        "350k": 24
      },
      houseLoitererfm: {
        "5k": 182,
        "10k": 132,
        "15k": 121,
        "20k": 92,
        "25k": 115,
        "30k": 84,
        "35k": 66,
        "40k": 74,
        "45k": 69,
        "50k": 36,
        "55k": 28,
        "60k": 15,
        "65k": 30,
        "70k": 18,
        "75k": 14,
        "80k": 21,
        "85k": 8,
        "90k": 9,
        "95k": 5,
        "100k": 6,
        "105k": 10,
        "110k": 3,
        "115k": 5,
        "120k": 4,
        "125k": 3,
        "130k": 0,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 3,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      spousefm: {
        "5k": 2,
        "10k": 7,
        "15k": 14,
        "20k": 14,
        "25k": 21,
        "30k": 15,
        "35k": 16,
        "40k": 12,
        "45k": 5,
        "50k": 17,
        "55k": 15,
        "60k": 2,
        "65k": 10,
        "70k": 5,
        "75k": 5,
        "80k": 0,
        "85k": 3,
        "90k": 4,
        "95k": 0,
        "100k": 0,
        "105k": 2,
        "110k": 3,
        "115k": 0,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 100,
        "10k": 91,
        "15k": 116,
        "20k": 126,
        "25k": 217,
        "30k": 202,
        "35k": 227,
        "40k": 190,
        "45k": 210,
        "50k": 214,
        "55k": 240,
        "60k": 189,
        "65k": 220,
        "70k": 174,
        "75k": 165,
        "80k": 246,
        "85k": 151,
        "90k": 181,
        "95k": 200,
        "100k": 125,
        "105k": 139,
        "110k": 147,
        "115k": 148,
        "120k": 115,
        "125k": 116,
        "130k": 82,
        "135k": 61,
        "140k": 67,
        "145k": 94,
        "150k": 70,
        "155k": 81,
        "160k": 70,
        "165k": 63,
        "170k": 39,
        "175k": 62,
        "180k": 54,
        "185k": 40,
        "190k": 31,
        "195k": 22,
        "200k": 14,
        "350k": 427
      },
      otherRelativefm: {
        "5k": 24,
        "10k": 24,
        "15k": 22,
        "20k": 47,
        "25k": 31,
        "30k": 40,
        "35k": 53,
        "40k": 38,
        "45k": 51,
        "50k": 17,
        "55k": 30,
        "60k": 43,
        "65k": 31,
        "70k": 36,
        "75k": 23,
        "80k": 18,
        "85k": 19,
        "90k": 11,
        "95k": 20,
        "100k": 5,
        "105k": 24,
        "110k": 7,
        "115k": 16,
        "120k": 0,
        "125k": 8,
        "130k": 12,
        "135k": 13,
        "140k": 8,
        "145k": 4,
        "150k": 4,
        "155k": 7,
        "160k": 3,
        "165k": 13,
        "170k": 3,
        "175k": 0,
        "180k": 5,
        "185k": 5,
        "190k": 0,
        "195k": 3,
        "200k": 6,
        "350k": 12
      },
      nonRelativefm: {
        "5k": 30,
        "10k": 39,
        "15k": 34,
        "20k": 56,
        "25k": 87,
        "30k": 58,
        "35k": 72,
        "40k": 43,
        "45k": 70,
        "50k": 54,
        "55k": 19,
        "60k": 30,
        "65k": 28,
        "70k": 18,
        "75k": 22,
        "80k": 28,
        "85k": 11,
        "90k": 17,
        "95k": 8,
        "100k": 11,
        "105k": 25,
        "110k": 10,
        "115k": 6,
        "120k": 9,
        "125k": 9,
        "130k": 7,
        "135k": 1,
        "140k": 0,
        "145k": 2,
        "150k": 4,
        "155k": 3,
        "160k": 0,
        "165k": 2,
        "170k": 6,
        "175k": 8,
        "180k": 0,
        "185k": 3,
        "190k": 1,
        "195k": 0,
        "200k": 1,
        "350k": 13
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 121,
        "10k": 76,
        "15k": 85,
        "20k": 140,
        "25k": 153,
        "30k": 106,
        "35k": 154,
        "40k": 110,
        "45k": 129,
        "50k": 113,
        "55k": 68,
        "60k": 80,
        "65k": 52,
        "70k": 61,
        "75k": 35,
        "80k": 15,
        "85k": 20,
        "90k": 21,
        "95k": 15,
        "100k": 15,
        "105k": 10,
        "110k": 17,
        "115k": 8,
        "120k": 10,
        "125k": 3,
        "130k": 10,
        "135k": 10,
        "140k": 6,
        "145k": 0,
        "150k": 2,
        "155k": 9,
        "160k": 3,
        "165k": 2,
        "170k": 0,
        "175k": 2,
        "180k": 2,
        "185k": 0,
        "190k": 3,
        "195k": 0,
        "200k": 0,
        "350k": 18
      },
      spousem: {
        "5k": 1,
        "10k": 3,
        "15k": 17,
        "20k": 21,
        "25k": 28,
        "30k": 11,
        "35k": 19,
        "40k": 26,
        "45k": 27,
        "50k": 11,
        "55k": 22,
        "60k": 5,
        "65k": 13,
        "70k": 6,
        "75k": 13,
        "80k": 9,
        "85k": 3,
        "90k": 1,
        "95k": 0,
        "100k": 5,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 4,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 41,
        "10k": 47,
        "15k": 57,
        "20k": 71,
        "25k": 52,
        "30k": 86,
        "35k": 94,
        "40k": 93,
        "45k": 101,
        "50k": 140,
        "55k": 71,
        "60k": 102,
        "65k": 88,
        "70k": 97,
        "75k": 90,
        "80k": 127,
        "85k": 108,
        "90k": 65,
        "95k": 74,
        "100k": 94,
        "105k": 91,
        "110k": 89,
        "115k": 81,
        "120k": 67,
        "125k": 87,
        "130k": 42,
        "135k": 47,
        "140k": 24,
        "145k": 45,
        "150k": 50,
        "155k": 46,
        "160k": 40,
        "165k": 11,
        "170k": 22,
        "175k": 42,
        "180k": 21,
        "185k": 14,
        "190k": 21,
        "195k": 19,
        "200k": 32,
        "350k": 289
      },
      otherRelativem: {
        "5k": 14,
        "10k": 19,
        "15k": 30,
        "20k": 7,
        "25k": 15,
        "30k": 32,
        "35k": 37,
        "40k": 28,
        "45k": 45,
        "50k": 29,
        "55k": 35,
        "60k": 38,
        "65k": 30,
        "70k": 19,
        "75k": 18,
        "80k": 13,
        "85k": 19,
        "90k": 21,
        "95k": 13,
        "100k": 21,
        "105k": 12,
        "110k": 15,
        "115k": 12,
        "120k": 12,
        "125k": 15,
        "130k": 3,
        "135k": 6,
        "140k": 2,
        "145k": 1,
        "150k": 6,
        "155k": 3,
        "160k": 0,
        "165k": 2,
        "170k": 8,
        "175k": 3,
        "180k": 3,
        "185k": 0,
        "190k": 5,
        "195k": 0,
        "200k": 2,
        "350k": 7
      },
      nonRelativem: {
        "5k": 33,
        "10k": 24,
        "15k": 31,
        "20k": 23,
        "25k": 69,
        "30k": 35,
        "35k": 41,
        "40k": 49,
        "45k": 81,
        "50k": 57,
        "55k": 50,
        "60k": 50,
        "65k": 27,
        "70k": 49,
        "75k": 84,
        "80k": 36,
        "85k": 9,
        "90k": 8,
        "95k": 13,
        "100k": 23,
        "105k": 5,
        "110k": 13,
        "115k": 10,
        "120k": 1,
        "125k": 12,
        "130k": 4,
        "135k": 5,
        "140k": 14,
        "145k": 5,
        "150k": 5,
        "155k": 10,
        "160k": 6,
        "165k": 3,
        "170k": 8,
        "175k": 8,
        "180k": 0,
        "185k": 0,
        "190k": 13,
        "195k": 0,
        "200k": 0,
        "350k": 22
      },
      houseLoitererfm: {
        "5k": 208,
        "10k": 179,
        "15k": 166,
        "20k": 151,
        "25k": 193,
        "30k": 186,
        "35k": 172,
        "40k": 142,
        "45k": 129,
        "50k": 87,
        "55k": 84,
        "60k": 88,
        "65k": 89,
        "70k": 61,
        "75k": 60,
        "80k": 33,
        "85k": 26,
        "90k": 13,
        "95k": 12,
        "100k": 11,
        "105k": 16,
        "110k": 7,
        "115k": 4,
        "120k": 10,
        "125k": 7,
        "130k": 10,
        "135k": 2,
        "140k": 2,
        "145k": 3,
        "150k": 3,
        "155k": 1,
        "160k": 8,
        "165k": 0,
        "170k": 3,
        "175k": 1,
        "180k": 7,
        "185k": 11,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 12
      },
      spousefm: {
        "5k": 23,
        "10k": 18,
        "15k": 27,
        "20k": 35,
        "25k": 50,
        "30k": 44,
        "35k": 44,
        "40k": 47,
        "45k": 42,
        "50k": 48,
        "55k": 52,
        "60k": 44,
        "65k": 42,
        "70k": 27,
        "75k": 15,
        "80k": 20,
        "85k": 13,
        "90k": 22,
        "95k": 4,
        "100k": 6,
        "105k": 5,
        "110k": 3,
        "115k": 1,
        "120k": 6,
        "125k": 0,
        "130k": 8,
        "135k": 7,
        "140k": 3,
        "145k": 2,
        "150k": 2,
        "155k": 1,
        "160k": 1,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      childfm: {
        "5k": 36,
        "10k": 24,
        "15k": 38,
        "20k": 72,
        "25k": 52,
        "30k": 73,
        "35k": 82,
        "40k": 62,
        "45k": 69,
        "50k": 87,
        "55k": 97,
        "60k": 92,
        "65k": 83,
        "70k": 71,
        "75k": 64,
        "80k": 96,
        "85k": 57,
        "90k": 71,
        "95k": 76,
        "100k": 63,
        "105k": 48,
        "110k": 49,
        "115k": 31,
        "120k": 37,
        "125k": 45,
        "130k": 46,
        "135k": 42,
        "140k": 18,
        "145k": 61,
        "150k": 48,
        "155k": 34,
        "160k": 25,
        "165k": 33,
        "170k": 11,
        "175k": 14,
        "180k": 8,
        "185k": 32,
        "190k": 16,
        "195k": 18,
        "200k": 17,
        "350k": 240
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 12,
        "15k": 7,
        "20k": 21,
        "25k": 34,
        "30k": 20,
        "35k": 25,
        "40k": 24,
        "45k": 24,
        "50k": 21,
        "55k": 17,
        "60k": 19,
        "65k": 28,
        "70k": 11,
        "75k": 12,
        "80k": 8,
        "85k": 10,
        "90k": 19,
        "95k": 9,
        "100k": 25,
        "105k": 9,
        "110k": 9,
        "115k": 7,
        "120k": 4,
        "125k": 7,
        "130k": 6,
        "135k": 1,
        "140k": 2,
        "145k": 3,
        "150k": 7,
        "155k": 0,
        "160k": 2,
        "165k": 2,
        "170k": 7,
        "175k": 1,
        "180k": 2,
        "185k": 6,
        "190k": 2,
        "195k": 0,
        "200k": 4,
        "350k": 9
      },
      nonRelativefm: {
        "5k": 61,
        "10k": 26,
        "15k": 31,
        "20k": 36,
        "25k": 49,
        "30k": 41,
        "35k": 70,
        "40k": 66,
        "45k": 54,
        "50k": 39,
        "55k": 61,
        "60k": 79,
        "65k": 49,
        "70k": 44,
        "75k": 30,
        "80k": 29,
        "85k": 15,
        "90k": 20,
        "95k": 28,
        "100k": 8,
        "105k": 30,
        "110k": 20,
        "115k": 2,
        "120k": 11,
        "125k": 10,
        "130k": 11,
        "135k": 6,
        "140k": 3,
        "145k": 4,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 4,
        "170k": 0,
        "175k": 2,
        "180k": 2,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 16
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 170,
        "10k": 85,
        "15k": 142,
        "20k": 203,
        "25k": 259,
        "30k": 259,
        "35k": 289,
        "40k": 267,
        "45k": 267,
        "50k": 201,
        "55k": 300,
        "60k": 181,
        "65k": 258,
        "70k": 154,
        "75k": 138,
        "80k": 178,
        "85k": 141,
        "90k": 97,
        "95k": 116,
        "100k": 93,
        "105k": 121,
        "110k": 60,
        "115k": 52,
        "120k": 72,
        "125k": 41,
        "130k": 42,
        "135k": 27,
        "140k": 19,
        "145k": 39,
        "150k": 41,
        "155k": 20,
        "160k": 22,
        "165k": 13,
        "170k": 12,
        "175k": 3,
        "180k": 15,
        "185k": 9,
        "190k": 15,
        "195k": 5,
        "200k": 4,
        "350k": 89
      },
      spousem: {
        "5k": 18,
        "10k": 17,
        "15k": 27,
        "20k": 37,
        "25k": 50,
        "30k": 64,
        "35k": 77,
        "40k": 74,
        "45k": 63,
        "50k": 80,
        "55k": 58,
        "60k": 52,
        "65k": 61,
        "70k": 47,
        "75k": 68,
        "80k": 68,
        "85k": 43,
        "90k": 28,
        "95k": 24,
        "100k": 28,
        "105k": 28,
        "110k": 27,
        "115k": 16,
        "120k": 16,
        "125k": 22,
        "130k": 3,
        "135k": 4,
        "140k": 17,
        "145k": 14,
        "150k": 4,
        "155k": 5,
        "160k": 4,
        "165k": 4,
        "170k": 2,
        "175k": 0,
        "180k": 1,
        "185k": 2,
        "190k": 3,
        "195k": 0,
        "200k": 4,
        "350k": 16
      },
      childm: {
        "5k": 22,
        "10k": 48,
        "15k": 48,
        "20k": 66,
        "25k": 74,
        "30k": 70,
        "35k": 114,
        "40k": 77,
        "45k": 60,
        "50k": 77,
        "55k": 98,
        "60k": 90,
        "65k": 96,
        "70k": 96,
        "75k": 77,
        "80k": 61,
        "85k": 63,
        "90k": 54,
        "95k": 58,
        "100k": 66,
        "105k": 55,
        "110k": 70,
        "115k": 46,
        "120k": 46,
        "125k": 49,
        "130k": 31,
        "135k": 45,
        "140k": 37,
        "145k": 46,
        "150k": 38,
        "155k": 31,
        "160k": 30,
        "165k": 37,
        "170k": 38,
        "175k": 13,
        "180k": 26,
        "185k": 27,
        "190k": 12,
        "195k": 16,
        "200k": 16,
        "350k": 198
      },
      otherRelativem: {
        "5k": 3,
        "10k": 5,
        "15k": 11,
        "20k": 44,
        "25k": 29,
        "30k": 43,
        "35k": 26,
        "40k": 54,
        "45k": 35,
        "50k": 47,
        "55k": 37,
        "60k": 35,
        "65k": 39,
        "70k": 44,
        "75k": 24,
        "80k": 24,
        "85k": 14,
        "90k": 17,
        "95k": 16,
        "100k": 21,
        "105k": 12,
        "110k": 17,
        "115k": 19,
        "120k": 29,
        "125k": 34,
        "130k": 15,
        "135k": 6,
        "140k": 4,
        "145k": 14,
        "150k": 0,
        "155k": 6,
        "160k": 3,
        "165k": 10,
        "170k": 15,
        "175k": 2,
        "180k": 1,
        "185k": 0,
        "190k": 5,
        "195k": 2,
        "200k": 8,
        "350k": 36
      },
      nonRelativem: {
        "5k": 56,
        "10k": 35,
        "15k": 34,
        "20k": 39,
        "25k": 72,
        "30k": 74,
        "35k": 72,
        "40k": 67,
        "45k": 104,
        "50k": 84,
        "55k": 59,
        "60k": 78,
        "65k": 67,
        "70k": 71,
        "75k": 52,
        "80k": 36,
        "85k": 62,
        "90k": 54,
        "95k": 44,
        "100k": 36,
        "105k": 54,
        "110k": 29,
        "115k": 15,
        "120k": 29,
        "125k": 21,
        "130k": 33,
        "135k": 18,
        "140k": 23,
        "145k": 18,
        "150k": 23,
        "155k": 24,
        "160k": 10,
        "165k": 13,
        "170k": 0,
        "175k": 4,
        "180k": 6,
        "185k": 0,
        "190k": 11,
        "195k": 4,
        "200k": 3,
        "350k": 67
      },
      houseLoitererfm: {
        "5k": 291,
        "10k": 292,
        "15k": 253,
        "20k": 290,
        "25k": 298,
        "30k": 262,
        "35k": 313,
        "40k": 302,
        "45k": 275,
        "50k": 205,
        "55k": 233,
        "60k": 176,
        "65k": 161,
        "70k": 140,
        "75k": 146,
        "80k": 123,
        "85k": 116,
        "90k": 92,
        "95k": 78,
        "100k": 75,
        "105k": 93,
        "110k": 66,
        "115k": 52,
        "120k": 35,
        "125k": 50,
        "130k": 13,
        "135k": 14,
        "140k": 44,
        "145k": 12,
        "150k": 8,
        "155k": 25,
        "160k": 13,
        "165k": 15,
        "170k": 11,
        "175k": 5,
        "180k": 7,
        "185k": 14,
        "190k": 13,
        "195k": 5,
        "200k": 7,
        "350k": 63
      },
      spousefm: {
        "5k": 42,
        "10k": 36,
        "15k": 50,
        "20k": 83,
        "25k": 91,
        "30k": 103,
        "35k": 141,
        "40k": 124,
        "45k": 139,
        "50k": 127,
        "55k": 139,
        "60k": 131,
        "65k": 131,
        "70k": 91,
        "75k": 77,
        "80k": 136,
        "85k": 99,
        "90k": 66,
        "95k": 85,
        "100k": 70,
        "105k": 71,
        "110k": 39,
        "115k": 49,
        "120k": 37,
        "125k": 34,
        "130k": 24,
        "135k": 26,
        "140k": 20,
        "145k": 22,
        "150k": 14,
        "155k": 16,
        "160k": 25,
        "165k": 18,
        "170k": 10,
        "175k": 7,
        "180k": 6,
        "185k": 3,
        "190k": 7,
        "195k": 0,
        "200k": 1,
        "350k": 52
      },
      childfm: {
        "5k": 11,
        "10k": 25,
        "15k": 17,
        "20k": 32,
        "25k": 40,
        "30k": 50,
        "35k": 51,
        "40k": 55,
        "45k": 60,
        "50k": 43,
        "55k": 64,
        "60k": 51,
        "65k": 48,
        "70k": 55,
        "75k": 62,
        "80k": 53,
        "85k": 38,
        "90k": 43,
        "95k": 62,
        "100k": 50,
        "105k": 47,
        "110k": 45,
        "115k": 50,
        "120k": 32,
        "125k": 36,
        "130k": 32,
        "135k": 13,
        "140k": 27,
        "145k": 17,
        "150k": 32,
        "155k": 28,
        "160k": 29,
        "165k": 18,
        "170k": 11,
        "175k": 14,
        "180k": 11,
        "185k": 11,
        "190k": 8,
        "195k": 8,
        "200k": 6,
        "350k": 111
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 6,
        "15k": 6,
        "20k": 13,
        "25k": 16,
        "30k": 12,
        "35k": 21,
        "40k": 30,
        "45k": 31,
        "50k": 23,
        "55k": 31,
        "60k": 29,
        "65k": 28,
        "70k": 34,
        "75k": 18,
        "80k": 12,
        "85k": 16,
        "90k": 16,
        "95k": 15,
        "100k": 19,
        "105k": 5,
        "110k": 15,
        "115k": 5,
        "120k": 12,
        "125k": 11,
        "130k": 18,
        "135k": 7,
        "140k": 0,
        "145k": 4,
        "150k": 5,
        "155k": 12,
        "160k": 1,
        "165k": 5,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 2,
        "190k": 5,
        "195k": 1,
        "200k": 3,
        "350k": 14
      },
      nonRelativefm: {
        "5k": 30,
        "10k": 28,
        "15k": 31,
        "20k": 38,
        "25k": 46,
        "30k": 51,
        "35k": 53,
        "40k": 46,
        "45k": 63,
        "50k": 46,
        "55k": 67,
        "60k": 58,
        "65k": 74,
        "70k": 75,
        "75k": 71,
        "80k": 41,
        "85k": 54,
        "90k": 36,
        "95k": 38,
        "100k": 57,
        "105k": 48,
        "110k": 39,
        "115k": 12,
        "120k": 35,
        "125k": 21,
        "130k": 22,
        "135k": 18,
        "140k": 6,
        "145k": 9,
        "150k": 10,
        "155k": 6,
        "160k": 8,
        "165k": 4,
        "170k": 13,
        "175k": 9,
        "180k": 1,
        "185k": 4,
        "190k": 6,
        "195k": 0,
        "200k": 13,
        "350k": 37
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 120,
        "10k": 109,
        "15k": 160,
        "20k": 195,
        "25k": 256,
        "30k": 231,
        "35k": 276,
        "40k": 244,
        "45k": 240,
        "50k": 216,
        "55k": 243,
        "60k": 299,
        "65k": 290,
        "70k": 213,
        "75k": 209,
        "80k": 208,
        "85k": 189,
        "90k": 166,
        "95k": 139,
        "100k": 119,
        "105k": 157,
        "110k": 91,
        "115k": 95,
        "120k": 80,
        "125k": 82,
        "130k": 115,
        "135k": 64,
        "140k": 46,
        "145k": 80,
        "150k": 23,
        "155k": 65,
        "160k": 37,
        "165k": 36,
        "170k": 33,
        "175k": 20,
        "180k": 24,
        "185k": 14,
        "190k": 4,
        "195k": 21,
        "200k": 13,
        "350k": 157
      },
      spousem: {
        "5k": 23,
        "10k": 32,
        "15k": 25,
        "20k": 43,
        "25k": 79,
        "30k": 50,
        "35k": 80,
        "40k": 101,
        "45k": 80,
        "50k": 102,
        "55k": 94,
        "60k": 79,
        "65k": 89,
        "70k": 94,
        "75k": 84,
        "80k": 72,
        "85k": 71,
        "90k": 58,
        "95k": 63,
        "100k": 57,
        "105k": 87,
        "110k": 50,
        "115k": 37,
        "120k": 46,
        "125k": 38,
        "130k": 17,
        "135k": 35,
        "140k": 31,
        "145k": 28,
        "150k": 19,
        "155k": 32,
        "160k": 23,
        "165k": 15,
        "170k": 16,
        "175k": 5,
        "180k": 5,
        "185k": 9,
        "190k": 11,
        "195k": 14,
        "200k": 3,
        "350k": 108
      },
      childm: {
        "5k": 21,
        "10k": 19,
        "15k": 45,
        "20k": 24,
        "25k": 65,
        "30k": 43,
        "35k": 37,
        "40k": 51,
        "45k": 44,
        "50k": 49,
        "55k": 19,
        "60k": 55,
        "65k": 55,
        "70k": 51,
        "75k": 41,
        "80k": 46,
        "85k": 52,
        "90k": 38,
        "95k": 28,
        "100k": 30,
        "105k": 30,
        "110k": 25,
        "115k": 15,
        "120k": 10,
        "125k": 19,
        "130k": 20,
        "135k": 12,
        "140k": 12,
        "145k": 19,
        "150k": 20,
        "155k": 27,
        "160k": 8,
        "165k": 9,
        "170k": 7,
        "175k": 3,
        "180k": 7,
        "185k": 4,
        "190k": 3,
        "195k": 10,
        "200k": 2,
        "350k": 65
      },
      otherRelativem: {
        "5k": 0,
        "10k": 11,
        "15k": 4,
        "20k": 12,
        "25k": 18,
        "30k": 16,
        "35k": 14,
        "40k": 39,
        "45k": 24,
        "50k": 19,
        "55k": 26,
        "60k": 12,
        "65k": 29,
        "70k": 11,
        "75k": 23,
        "80k": 16,
        "85k": 10,
        "90k": 18,
        "95k": 9,
        "100k": 15,
        "105k": 2,
        "110k": 10,
        "115k": 4,
        "120k": 10,
        "125k": 9,
        "130k": 6,
        "135k": 7,
        "140k": 9,
        "145k": 7,
        "150k": 5,
        "155k": 0,
        "160k": 3,
        "165k": 8,
        "170k": 6,
        "175k": 5,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 4,
        "200k": 3,
        "350k": 31
      },
      nonRelativem: {
        "5k": 24,
        "10k": 12,
        "15k": 19,
        "20k": 56,
        "25k": 39,
        "30k": 38,
        "35k": 46,
        "40k": 73,
        "45k": 57,
        "50k": 51,
        "55k": 43,
        "60k": 54,
        "65k": 47,
        "70k": 32,
        "75k": 26,
        "80k": 51,
        "85k": 36,
        "90k": 34,
        "95k": 31,
        "100k": 31,
        "105k": 22,
        "110k": 19,
        "115k": 22,
        "120k": 19,
        "125k": 14,
        "130k": 18,
        "135k": 10,
        "140k": 18,
        "145k": 15,
        "150k": 5,
        "155k": 12,
        "160k": 7,
        "165k": 5,
        "170k": 10,
        "175k": 0,
        "180k": 3,
        "185k": 1,
        "190k": 2,
        "195k": 1,
        "200k": 0,
        "350k": 47
      },
      houseLoitererfm: {
        "5k": 247,
        "10k": 258,
        "15k": 262,
        "20k": 298,
        "25k": 281,
        "30k": 268,
        "35k": 340,
        "40k": 333,
        "45k": 246,
        "50k": 222,
        "55k": 226,
        "60k": 197,
        "65k": 190,
        "70k": 168,
        "75k": 150,
        "80k": 153,
        "85k": 140,
        "90k": 115,
        "95k": 102,
        "100k": 96,
        "105k": 95,
        "110k": 75,
        "115k": 62,
        "120k": 71,
        "125k": 75,
        "130k": 46,
        "135k": 55,
        "140k": 51,
        "145k": 45,
        "150k": 34,
        "155k": 33,
        "160k": 31,
        "165k": 25,
        "170k": 20,
        "175k": 17,
        "180k": 16,
        "185k": 8,
        "190k": 13,
        "195k": 17,
        "200k": 7,
        "350k": 172
      },
      spousefm: {
        "5k": 36,
        "10k": 23,
        "15k": 58,
        "20k": 71,
        "25k": 101,
        "30k": 99,
        "35k": 113,
        "40k": 126,
        "45k": 116,
        "50k": 155,
        "55k": 147,
        "60k": 123,
        "65k": 155,
        "70k": 130,
        "75k": 156,
        "80k": 134,
        "85k": 128,
        "90k": 101,
        "95k": 107,
        "100k": 86,
        "105k": 127,
        "110k": 84,
        "115k": 76,
        "120k": 82,
        "125k": 72,
        "130k": 83,
        "135k": 47,
        "140k": 45,
        "145k": 50,
        "150k": 29,
        "155k": 46,
        "160k": 30,
        "165k": 25,
        "170k": 25,
        "175k": 19,
        "180k": 27,
        "185k": 18,
        "190k": 7,
        "195k": 19,
        "200k": 6,
        "350k": 151
      },
      childfm: {
        "5k": 13,
        "10k": 12,
        "15k": 13,
        "20k": 25,
        "25k": 22,
        "30k": 22,
        "35k": 21,
        "40k": 21,
        "45k": 28,
        "50k": 20,
        "55k": 22,
        "60k": 34,
        "65k": 31,
        "70k": 38,
        "75k": 23,
        "80k": 27,
        "85k": 14,
        "90k": 20,
        "95k": 12,
        "100k": 21,
        "105k": 21,
        "110k": 14,
        "115k": 10,
        "120k": 14,
        "125k": 6,
        "130k": 0,
        "135k": 14,
        "140k": 8,
        "145k": 12,
        "150k": 7,
        "155k": 13,
        "160k": 11,
        "165k": 16,
        "170k": 9,
        "175k": 3,
        "180k": 9,
        "185k": 8,
        "190k": 1,
        "195k": 1,
        "200k": 4,
        "350k": 38
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 5,
        "15k": 9,
        "20k": 16,
        "25k": 12,
        "30k": 9,
        "35k": 15,
        "40k": 15,
        "45k": 16,
        "50k": 8,
        "55k": 5,
        "60k": 19,
        "65k": 17,
        "70k": 20,
        "75k": 12,
        "80k": 17,
        "85k": 9,
        "90k": 9,
        "95k": 13,
        "100k": 11,
        "105k": 9,
        "110k": 8,
        "115k": 2,
        "120k": 5,
        "125k": 2,
        "130k": 2,
        "135k": 3,
        "140k": 1,
        "145k": 13,
        "150k": 7,
        "155k": 4,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 0,
        "180k": 2,
        "185k": 4,
        "190k": 1,
        "195k": 3,
        "200k": 0,
        "350k": 14
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 8,
        "15k": 11,
        "20k": 26,
        "25k": 37,
        "30k": 28,
        "35k": 50,
        "40k": 33,
        "45k": 25,
        "50k": 28,
        "55k": 20,
        "60k": 44,
        "65k": 43,
        "70k": 30,
        "75k": 35,
        "80k": 34,
        "85k": 23,
        "90k": 33,
        "95k": 15,
        "100k": 25,
        "105k": 25,
        "110k": 16,
        "115k": 24,
        "120k": 13,
        "125k": 21,
        "130k": 9,
        "135k": 18,
        "140k": 14,
        "145k": 16,
        "150k": 17,
        "155k": 5,
        "160k": 0,
        "165k": 3,
        "170k": 7,
        "175k": 0,
        "180k": 5,
        "185k": 4,
        "190k": 7,
        "195k": 8,
        "200k": 1,
        "350k": 24
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 217,
        "10k": 224,
        "15k": 275,
        "20k": 332,
        "25k": 436,
        "30k": 423,
        "35k": 463,
        "40k": 415,
        "45k": 485,
        "50k": 483,
        "55k": 480,
        "60k": 399,
        "65k": 462,
        "70k": 359,
        "75k": 420,
        "80k": 380,
        "85k": 363,
        "90k": 309,
        "95k": 279,
        "100k": 293,
        "105k": 327,
        "110k": 269,
        "115k": 237,
        "120k": 186,
        "125k": 228,
        "130k": 152,
        "135k": 185,
        "140k": 124,
        "145k": 109,
        "150k": 105,
        "155k": 162,
        "160k": 101,
        "165k": 85,
        "170k": 79,
        "175k": 67,
        "180k": 66,
        "185k": 73,
        "190k": 42,
        "195k": 43,
        "200k": 46,
        "350k": 668
      },
      spousem: {
        "5k": 46,
        "10k": 41,
        "15k": 67,
        "20k": 106,
        "25k": 132,
        "30k": 130,
        "35k": 173,
        "40k": 169,
        "45k": 200,
        "50k": 147,
        "55k": 200,
        "60k": 223,
        "65k": 202,
        "70k": 197,
        "75k": 211,
        "80k": 206,
        "85k": 224,
        "90k": 193,
        "95k": 193,
        "100k": 145,
        "105k": 165,
        "110k": 119,
        "115k": 134,
        "120k": 107,
        "125k": 128,
        "130k": 95,
        "135k": 88,
        "140k": 62,
        "145k": 77,
        "150k": 62,
        "155k": 110,
        "160k": 36,
        "165k": 52,
        "170k": 50,
        "175k": 46,
        "180k": 29,
        "185k": 34,
        "190k": 21,
        "195k": 19,
        "200k": 23,
        "350k": 337
      },
      childm: {
        "5k": 18,
        "10k": 42,
        "15k": 49,
        "20k": 54,
        "25k": 84,
        "30k": 77,
        "35k": 64,
        "40k": 72,
        "45k": 97,
        "50k": 80,
        "55k": 52,
        "60k": 77,
        "65k": 35,
        "70k": 44,
        "75k": 63,
        "80k": 42,
        "85k": 43,
        "90k": 36,
        "95k": 31,
        "100k": 34,
        "105k": 32,
        "110k": 30,
        "115k": 35,
        "120k": 30,
        "125k": 13,
        "130k": 3,
        "135k": 15,
        "140k": 33,
        "145k": 6,
        "150k": 5,
        "155k": 6,
        "160k": 10,
        "165k": 13,
        "170k": 9,
        "175k": 10,
        "180k": 2,
        "185k": 9,
        "190k": 0,
        "195k": 1,
        "200k": 4,
        "350k": 46
      },
      otherRelativem: {
        "5k": 18,
        "10k": 8,
        "15k": 14,
        "20k": 31,
        "25k": 41,
        "30k": 32,
        "35k": 61,
        "40k": 39,
        "45k": 54,
        "50k": 58,
        "55k": 58,
        "60k": 28,
        "65k": 57,
        "70k": 25,
        "75k": 36,
        "80k": 45,
        "85k": 19,
        "90k": 27,
        "95k": 21,
        "100k": 35,
        "105k": 20,
        "110k": 26,
        "115k": 20,
        "120k": 24,
        "125k": 16,
        "130k": 4,
        "135k": 4,
        "140k": 3,
        "145k": 16,
        "150k": 9,
        "155k": 8,
        "160k": 6,
        "165k": 6,
        "170k": 8,
        "175k": 5,
        "180k": 2,
        "185k": 3,
        "190k": 11,
        "195k": 12,
        "200k": 1,
        "350k": 27
      },
      nonRelativem: {
        "5k": 32,
        "10k": 31,
        "15k": 42,
        "20k": 42,
        "25k": 50,
        "30k": 59,
        "35k": 60,
        "40k": 47,
        "45k": 96,
        "50k": 100,
        "55k": 56,
        "60k": 42,
        "65k": 53,
        "70k": 71,
        "75k": 75,
        "80k": 63,
        "85k": 36,
        "90k": 39,
        "95k": 52,
        "100k": 23,
        "105k": 33,
        "110k": 51,
        "115k": 21,
        "120k": 9,
        "125k": 28,
        "130k": 14,
        "135k": 17,
        "140k": 11,
        "145k": 15,
        "150k": 12,
        "155k": 20,
        "160k": 15,
        "165k": 11,
        "170k": 10,
        "175k": 16,
        "180k": 3,
        "185k": 10,
        "190k": 12,
        "195k": 16,
        "200k": 11,
        "350k": 71
      },
      houseLoitererfm: {
        "5k": 394,
        "10k": 356,
        "15k": 437,
        "20k": 462,
        "25k": 533,
        "30k": 514,
        "35k": 565,
        "40k": 516,
        "45k": 490,
        "50k": 445,
        "55k": 442,
        "60k": 385,
        "65k": 428,
        "70k": 366,
        "75k": 358,
        "80k": 313,
        "85k": 296,
        "90k": 244,
        "95k": 255,
        "100k": 199,
        "105k": 220,
        "110k": 162,
        "115k": 153,
        "120k": 160,
        "125k": 168,
        "130k": 118,
        "135k": 111,
        "140k": 75,
        "145k": 107,
        "150k": 84,
        "155k": 137,
        "160k": 55,
        "165k": 60,
        "170k": 58,
        "175k": 75,
        "180k": 36,
        "185k": 51,
        "190k": 29,
        "195k": 37,
        "200k": 27,
        "350k": 471
      },
      spousefm: {
        "5k": 67,
        "10k": 50,
        "15k": 96,
        "20k": 143,
        "25k": 169,
        "30k": 177,
        "35k": 210,
        "40k": 221,
        "45k": 247,
        "50k": 257,
        "55k": 259,
        "60k": 260,
        "65k": 298,
        "70k": 240,
        "75k": 255,
        "80k": 322,
        "85k": 258,
        "90k": 238,
        "95k": 226,
        "100k": 229,
        "105k": 251,
        "110k": 218,
        "115k": 218,
        "120k": 178,
        "125k": 189,
        "130k": 154,
        "135k": 151,
        "140k": 118,
        "145k": 110,
        "150k": 98,
        "155k": 151,
        "160k": 88,
        "165k": 78,
        "170k": 63,
        "175k": 67,
        "180k": 59,
        "185k": 65,
        "190k": 36,
        "195k": 38,
        "200k": 44,
        "350k": 680
      },
      childfm: {
        "5k": 8,
        "10k": 22,
        "15k": 23,
        "20k": 22,
        "25k": 27,
        "30k": 44,
        "35k": 55,
        "40k": 45,
        "45k": 42,
        "50k": 40,
        "55k": 43,
        "60k": 38,
        "65k": 32,
        "70k": 51,
        "75k": 31,
        "80k": 32,
        "85k": 22,
        "90k": 29,
        "95k": 19,
        "100k": 16,
        "105k": 30,
        "110k": 23,
        "115k": 18,
        "120k": 23,
        "125k": 14,
        "130k": 0,
        "135k": 16,
        "140k": 11,
        "145k": 11,
        "150k": 12,
        "155k": 4,
        "160k": 6,
        "165k": 4,
        "170k": 5,
        "175k": 2,
        "180k": 5,
        "185k": 5,
        "190k": 5,
        "195k": 4,
        "200k": 0,
        "350k": 21
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 11,
        "15k": 23,
        "20k": 43,
        "25k": 44,
        "30k": 35,
        "35k": 53,
        "40k": 42,
        "45k": 41,
        "50k": 32,
        "55k": 39,
        "60k": 29,
        "65k": 30,
        "70k": 15,
        "75k": 23,
        "80k": 34,
        "85k": 38,
        "90k": 21,
        "95k": 10,
        "100k": 17,
        "105k": 17,
        "110k": 17,
        "115k": 10,
        "120k": 22,
        "125k": 12,
        "130k": 5,
        "135k": 4,
        "140k": 11,
        "145k": 10,
        "150k": 2,
        "155k": 4,
        "160k": 1,
        "165k": 12,
        "170k": 5,
        "175k": 3,
        "180k": 3,
        "185k": 2,
        "190k": 1,
        "195k": 7,
        "200k": 1,
        "350k": 33
      },
      nonRelativefm: {
        "5k": 7,
        "10k": 11,
        "15k": 20,
        "20k": 28,
        "25k": 49,
        "30k": 48,
        "35k": 48,
        "40k": 37,
        "45k": 42,
        "50k": 64,
        "55k": 50,
        "60k": 43,
        "65k": 33,
        "70k": 39,
        "75k": 44,
        "80k": 20,
        "85k": 34,
        "90k": 29,
        "95k": 29,
        "100k": 30,
        "105k": 17,
        "110k": 26,
        "115k": 13,
        "120k": 15,
        "125k": 10,
        "130k": 6,
        "135k": 10,
        "140k": 6,
        "145k": 9,
        "150k": 2,
        "155k": 8,
        "160k": 16,
        "165k": 13,
        "170k": 10,
        "175k": 3,
        "180k": 10,
        "185k": 5,
        "190k": 4,
        "195k": 10,
        "200k": 3,
        "350k": 38
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 365,
        "10k": 309,
        "15k": 449,
        "20k": 365,
        "25k": 487,
        "30k": 487,
        "35k": 489,
        "40k": 481,
        "45k": 482,
        "50k": 491,
        "55k": 495,
        "60k": 440,
        "65k": 477,
        "70k": 367,
        "75k": 397,
        "80k": 391,
        "85k": 400,
        "90k": 340,
        "95k": 416,
        "100k": 318,
        "105k": 376,
        "110k": 244,
        "115k": 275,
        "120k": 208,
        "125k": 259,
        "130k": 187,
        "135k": 197,
        "140k": 163,
        "145k": 207,
        "150k": 170,
        "155k": 184,
        "160k": 119,
        "165k": 148,
        "170k": 132,
        "175k": 116,
        "180k": 104,
        "185k": 65,
        "190k": 63,
        "195k": 51,
        "200k": 72,
        "350k": 973
      },
      spousem: {
        "5k": 51,
        "10k": 46,
        "15k": 82,
        "20k": 111,
        "25k": 128,
        "30k": 105,
        "35k": 141,
        "40k": 160,
        "45k": 160,
        "50k": 200,
        "55k": 224,
        "60k": 192,
        "65k": 244,
        "70k": 195,
        "75k": 233,
        "80k": 194,
        "85k": 187,
        "90k": 159,
        "95k": 168,
        "100k": 147,
        "105k": 178,
        "110k": 133,
        "115k": 117,
        "120k": 117,
        "125k": 136,
        "130k": 126,
        "135k": 117,
        "140k": 78,
        "145k": 86,
        "150k": 108,
        "155k": 89,
        "160k": 64,
        "165k": 67,
        "170k": 47,
        "175k": 72,
        "180k": 56,
        "185k": 52,
        "190k": 44,
        "195k": 55,
        "200k": 31,
        "350k": 549
      },
      childm: {
        "5k": 3,
        "10k": 22,
        "15k": 51,
        "20k": 64,
        "25k": 72,
        "30k": 52,
        "35k": 56,
        "40k": 67,
        "45k": 74,
        "50k": 44,
        "55k": 53,
        "60k": 50,
        "65k": 38,
        "70k": 57,
        "75k": 42,
        "80k": 32,
        "85k": 24,
        "90k": 25,
        "95k": 4,
        "100k": 26,
        "105k": 18,
        "110k": 11,
        "115k": 5,
        "120k": 17,
        "125k": 7,
        "130k": 15,
        "135k": 8,
        "140k": 3,
        "145k": 4,
        "150k": 8,
        "155k": 6,
        "160k": 5,
        "165k": 2,
        "170k": 0,
        "175k": 3,
        "180k": 10,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 18
      },
      otherRelativem: {
        "5k": 31,
        "10k": 20,
        "15k": 31,
        "20k": 15,
        "25k": 43,
        "30k": 55,
        "35k": 69,
        "40k": 54,
        "45k": 67,
        "50k": 47,
        "55k": 53,
        "60k": 35,
        "65k": 57,
        "70k": 54,
        "75k": 57,
        "80k": 25,
        "85k": 44,
        "90k": 22,
        "95k": 18,
        "100k": 19,
        "105k": 19,
        "110k": 27,
        "115k": 19,
        "120k": 18,
        "125k": 25,
        "130k": 18,
        "135k": 18,
        "140k": 14,
        "145k": 16,
        "150k": 11,
        "155k": 14,
        "160k": 1,
        "165k": 9,
        "170k": 5,
        "175k": 6,
        "180k": 8,
        "185k": 7,
        "190k": 8,
        "195k": 5,
        "200k": 8,
        "350k": 28
      },
      nonRelativem: {
        "5k": 28,
        "10k": 18,
        "15k": 68,
        "20k": 30,
        "25k": 63,
        "30k": 39,
        "35k": 62,
        "40k": 52,
        "45k": 64,
        "50k": 53,
        "55k": 29,
        "60k": 46,
        "65k": 37,
        "70k": 54,
        "75k": 60,
        "80k": 32,
        "85k": 50,
        "90k": 33,
        "95k": 23,
        "100k": 22,
        "105k": 32,
        "110k": 15,
        "115k": 15,
        "120k": 24,
        "125k": 26,
        "130k": 13,
        "135k": 5,
        "140k": 11,
        "145k": 7,
        "150k": 18,
        "155k": 12,
        "160k": 8,
        "165k": 8,
        "170k": 7,
        "175k": 15,
        "180k": 4,
        "185k": 4,
        "190k": 7,
        "195k": 5,
        "200k": 5,
        "350k": 53
      },
      houseLoitererfm: {
        "5k": 474,
        "10k": 499,
        "15k": 538,
        "20k": 488,
        "25k": 508,
        "30k": 481,
        "35k": 537,
        "40k": 493,
        "45k": 550,
        "50k": 499,
        "55k": 504,
        "60k": 407,
        "65k": 425,
        "70k": 394,
        "75k": 419,
        "80k": 355,
        "85k": 283,
        "90k": 238,
        "95k": 258,
        "100k": 239,
        "105k": 247,
        "110k": 188,
        "115k": 181,
        "120k": 155,
        "125k": 188,
        "130k": 121,
        "135k": 122,
        "140k": 99,
        "145k": 93,
        "150k": 105,
        "155k": 137,
        "160k": 71,
        "165k": 88,
        "170k": 60,
        "175k": 80,
        "180k": 61,
        "185k": 57,
        "190k": 61,
        "195k": 50,
        "200k": 41,
        "350k": 643
      },
      spousefm: {
        "5k": 76,
        "10k": 70,
        "15k": 116,
        "20k": 125,
        "25k": 179,
        "30k": 226,
        "35k": 232,
        "40k": 248,
        "45k": 259,
        "50k": 248,
        "55k": 292,
        "60k": 250,
        "65k": 284,
        "70k": 297,
        "75k": 297,
        "80k": 280,
        "85k": 284,
        "90k": 275,
        "95k": 312,
        "100k": 265,
        "105k": 279,
        "110k": 229,
        "115k": 243,
        "120k": 187,
        "125k": 217,
        "130k": 135,
        "135k": 171,
        "140k": 125,
        "145k": 220,
        "150k": 169,
        "155k": 171,
        "160k": 129,
        "165k": 128,
        "170k": 98,
        "175k": 105,
        "180k": 93,
        "185k": 69,
        "190k": 66,
        "195k": 59,
        "200k": 61,
        "350k": 806
      },
      childfm: {
        "5k": 3,
        "10k": 7,
        "15k": 7,
        "20k": 19,
        "25k": 32,
        "30k": 36,
        "35k": 61,
        "40k": 25,
        "45k": 30,
        "50k": 10,
        "55k": 29,
        "60k": 33,
        "65k": 30,
        "70k": 26,
        "75k": 24,
        "80k": 20,
        "85k": 17,
        "90k": 10,
        "95k": 15,
        "100k": 17,
        "105k": 16,
        "110k": 13,
        "115k": 8,
        "120k": 4,
        "125k": 1,
        "130k": 7,
        "135k": 10,
        "140k": 2,
        "145k": 2,
        "150k": 4,
        "155k": 3,
        "160k": 3,
        "165k": 2,
        "170k": 6,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 17
      },
      otherRelativefm: {
        "5k": 28,
        "10k": 33,
        "15k": 38,
        "20k": 29,
        "25k": 57,
        "30k": 53,
        "35k": 46,
        "40k": 76,
        "45k": 66,
        "50k": 42,
        "55k": 66,
        "60k": 44,
        "65k": 68,
        "70k": 44,
        "75k": 56,
        "80k": 42,
        "85k": 33,
        "90k": 24,
        "95k": 34,
        "100k": 27,
        "105k": 15,
        "110k": 12,
        "115k": 18,
        "120k": 18,
        "125k": 28,
        "130k": 18,
        "135k": 17,
        "140k": 14,
        "145k": 6,
        "150k": 8,
        "155k": 12,
        "160k": 1,
        "165k": 12,
        "170k": 3,
        "175k": 5,
        "180k": 9,
        "185k": 2,
        "190k": 0,
        "195k": 3,
        "200k": 9,
        "350k": 37
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 26,
        "15k": 29,
        "20k": 32,
        "25k": 39,
        "30k": 58,
        "35k": 48,
        "40k": 31,
        "45k": 44,
        "50k": 49,
        "55k": 48,
        "60k": 41,
        "65k": 41,
        "70k": 22,
        "75k": 32,
        "80k": 29,
        "85k": 33,
        "90k": 24,
        "95k": 36,
        "100k": 26,
        "105k": 22,
        "110k": 20,
        "115k": 12,
        "120k": 7,
        "125k": 15,
        "130k": 15,
        "135k": 14,
        "140k": 8,
        "145k": 2,
        "150k": 3,
        "155k": 13,
        "160k": 9,
        "165k": 3,
        "170k": 10,
        "175k": 8,
        "180k": 5,
        "185k": 4,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": 65
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 364,
        "10k": 431,
        "15k": 561,
        "20k": 436,
        "25k": 515,
        "30k": 518,
        "35k": 520,
        "40k": 549,
        "45k": 526,
        "50k": 431,
        "55k": 525,
        "60k": 389,
        "65k": 391,
        "70k": 430,
        "75k": 380,
        "80k": 438,
        "85k": 354,
        "90k": 333,
        "95k": 276,
        "100k": 267,
        "105k": 299,
        "110k": 217,
        "115k": 224,
        "120k": 196,
        "125k": 196,
        "130k": 178,
        "135k": 149,
        "140k": 109,
        "145k": 162,
        "150k": 138,
        "155k": 155,
        "160k": 123,
        "165k": 100,
        "170k": 65,
        "175k": 83,
        "180k": 100,
        "185k": 63,
        "190k": 44,
        "195k": 72,
        "200k": 30,
        "350k": 830
      },
      spousem: {
        "5k": 68,
        "10k": 51,
        "15k": 53,
        "20k": 107,
        "25k": 131,
        "30k": 120,
        "35k": 153,
        "40k": 177,
        "45k": 201,
        "50k": 147,
        "55k": 163,
        "60k": 158,
        "65k": 159,
        "70k": 154,
        "75k": 171,
        "80k": 178,
        "85k": 149,
        "90k": 127,
        "95k": 165,
        "100k": 112,
        "105k": 139,
        "110k": 107,
        "115k": 108,
        "120k": 111,
        "125k": 98,
        "130k": 79,
        "135k": 74,
        "140k": 53,
        "145k": 56,
        "150k": 49,
        "155k": 89,
        "160k": 59,
        "165k": 49,
        "170k": 55,
        "175k": 51,
        "180k": 23,
        "185k": 41,
        "190k": 43,
        "195k": 21,
        "200k": 29,
        "350k": 418
      },
      childm: {
        "5k": 2,
        "10k": 6,
        "15k": 16,
        "20k": 22,
        "25k": 13,
        "30k": 16,
        "35k": 13,
        "40k": 17,
        "45k": 4,
        "50k": 14,
        "55k": 13,
        "60k": 7,
        "65k": 1,
        "70k": 9,
        "75k": 11,
        "80k": 10,
        "85k": 4,
        "90k": 0,
        "95k": 3,
        "100k": 2,
        "105k": 5,
        "110k": 3,
        "115k": 0,
        "120k": 1,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      otherRelativem: {
        "5k": 4,
        "10k": 14,
        "15k": 16,
        "20k": 20,
        "25k": 17,
        "30k": 17,
        "35k": 45,
        "40k": 48,
        "45k": 34,
        "50k": 29,
        "55k": 37,
        "60k": 30,
        "65k": 32,
        "70k": 25,
        "75k": 23,
        "80k": 25,
        "85k": 22,
        "90k": 20,
        "95k": 12,
        "100k": 20,
        "105k": 18,
        "110k": 10,
        "115k": 15,
        "120k": 19,
        "125k": 16,
        "130k": 6,
        "135k": 10,
        "140k": 5,
        "145k": 4,
        "150k": 2,
        "155k": 7,
        "160k": 9,
        "165k": 11,
        "170k": 8,
        "175k": 5,
        "180k": 6,
        "185k": 5,
        "190k": 8,
        "195k": 0,
        "200k": 4,
        "350k": 42
      },
      nonRelativem: {
        "5k": 7,
        "10k": 10,
        "15k": 27,
        "20k": 30,
        "25k": 35,
        "30k": 41,
        "35k": 28,
        "40k": 26,
        "45k": 31,
        "50k": 22,
        "55k": 24,
        "60k": 13,
        "65k": 17,
        "70k": 21,
        "75k": 25,
        "80k": 23,
        "85k": 16,
        "90k": 8,
        "95k": 17,
        "100k": 25,
        "105k": 28,
        "110k": 5,
        "115k": 8,
        "120k": 7,
        "125k": 3,
        "130k": 4,
        "135k": 0,
        "140k": 0,
        "145k": 2,
        "150k": 0,
        "155k": 0,
        "160k": 1,
        "165k": 4,
        "170k": 7,
        "175k": 2,
        "180k": 6,
        "185k": 2,
        "190k": 5,
        "195k": 3,
        "200k": 0,
        "350k": 45
      },
      houseLoitererfm: {
        "5k": 445,
        "10k": 561,
        "15k": 648,
        "20k": 611,
        "25k": 566,
        "30k": 498,
        "35k": 541,
        "40k": 562,
        "45k": 540,
        "50k": 437,
        "55k": 435,
        "60k": 360,
        "65k": 411,
        "70k": 345,
        "75k": 316,
        "80k": 262,
        "85k": 245,
        "90k": 205,
        "95k": 289,
        "100k": 154,
        "105k": 221,
        "110k": 151,
        "115k": 122,
        "120k": 117,
        "125k": 104,
        "130k": 124,
        "135k": 108,
        "140k": 75,
        "145k": 71,
        "150k": 80,
        "155k": 100,
        "160k": 70,
        "165k": 47,
        "170k": 72,
        "175k": 66,
        "180k": 41,
        "185k": 39,
        "190k": 42,
        "195k": 40,
        "200k": 28,
        "350k": 464
      },
      spousefm: {
        "5k": 121,
        "10k": 74,
        "15k": 122,
        "20k": 123,
        "25k": 217,
        "30k": 240,
        "35k": 226,
        "40k": 283,
        "45k": 289,
        "50k": 248,
        "55k": 320,
        "60k": 325,
        "65k": 272,
        "70k": 276,
        "75k": 238,
        "80k": 321,
        "85k": 262,
        "90k": 227,
        "95k": 198,
        "100k": 206,
        "105k": 240,
        "110k": 150,
        "115k": 194,
        "120k": 152,
        "125k": 130,
        "130k": 135,
        "135k": 146,
        "140k": 120,
        "145k": 94,
        "150k": 111,
        "155k": 93,
        "160k": 73,
        "165k": 81,
        "170k": 55,
        "175k": 48,
        "180k": 77,
        "185k": 53,
        "190k": 28,
        "195k": 46,
        "200k": 30,
        "350k": 662
      },
      childfm: {
        "5k": 0,
        "10k": 3,
        "15k": 11,
        "20k": 6,
        "25k": 11,
        "30k": 23,
        "35k": 42,
        "40k": 12,
        "45k": 8,
        "50k": 15,
        "55k": 6,
        "60k": 13,
        "65k": 7,
        "70k": 9,
        "75k": 3,
        "80k": 2,
        "85k": 6,
        "90k": 0,
        "95k": 12,
        "100k": 7,
        "105k": 6,
        "110k": 1,
        "115k": 1,
        "120k": 0,
        "125k": 3,
        "130k": 3,
        "135k": 9,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 2,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      otherRelativefm: {
        "5k": 21,
        "10k": 15,
        "15k": 19,
        "20k": 39,
        "25k": 47,
        "30k": 34,
        "35k": 57,
        "40k": 51,
        "45k": 63,
        "50k": 60,
        "55k": 52,
        "60k": 36,
        "65k": 39,
        "70k": 51,
        "75k": 39,
        "80k": 24,
        "85k": 33,
        "90k": 35,
        "95k": 22,
        "100k": 29,
        "105k": 23,
        "110k": 27,
        "115k": 32,
        "120k": 19,
        "125k": 29,
        "130k": 29,
        "135k": 15,
        "140k": 5,
        "145k": 9,
        "150k": 3,
        "155k": 21,
        "160k": 9,
        "165k": 4,
        "170k": 10,
        "175k": 9,
        "180k": 6,
        "185k": 7,
        "190k": 9,
        "195k": 3,
        "200k": 2,
        "350k": 56
      },
      nonRelativefm: {
        "5k": 18,
        "10k": 15,
        "15k": 17,
        "20k": 29,
        "25k": 22,
        "30k": 31,
        "35k": 23,
        "40k": 27,
        "45k": 41,
        "50k": 16,
        "55k": 34,
        "60k": 27,
        "65k": 6,
        "70k": 18,
        "75k": 18,
        "80k": 19,
        "85k": 16,
        "90k": 8,
        "95k": 9,
        "100k": 12,
        "105k": 4,
        "110k": 5,
        "115k": 12,
        "120k": 10,
        "125k": 0,
        "130k": 14,
        "135k": 19,
        "140k": 1,
        "145k": 9,
        "150k": 5,
        "155k": 6,
        "160k": 3,
        "165k": 0,
        "170k": 0,
        "175k": 10,
        "180k": 4,
        "185k": 11,
        "190k": 4,
        "195k": 6,
        "200k": 2,
        "350k": 37
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 92,
        "10k": 168,
        "15k": 397,
        "20k": 369,
        "25k": 460,
        "30k": 569,
        "35k": 469,
        "40k": 473,
        "45k": 385,
        "50k": 342,
        "55k": 377,
        "60k": 342,
        "65k": 300,
        "70k": 284,
        "75k": 205,
        "80k": 247,
        "85k": 182,
        "90k": 158,
        "95k": 151,
        "100k": 140,
        "105k": 170,
        "110k": 85,
        "115k": 115,
        "120k": 120,
        "125k": 59,
        "130k": 47,
        "135k": 90,
        "140k": 51,
        "145k": 49,
        "150k": 55,
        "155k": 61,
        "160k": 39,
        "165k": 32,
        "170k": 37,
        "175k": 36,
        "180k": 27,
        "185k": 20,
        "190k": 12,
        "195k": 22,
        "200k": 20,
        "350k": 295
      },
      spousem: {
        "5k": 33,
        "10k": 23,
        "15k": 50,
        "20k": 133,
        "25k": 145,
        "30k": 142,
        "35k": 156,
        "40k": 180,
        "45k": 131,
        "50k": 112,
        "55k": 123,
        "60k": 110,
        "65k": 123,
        "70k": 110,
        "75k": 102,
        "80k": 104,
        "85k": 79,
        "90k": 70,
        "95k": 102,
        "100k": 66,
        "105k": 73,
        "110k": 67,
        "115k": 51,
        "120k": 37,
        "125k": 23,
        "130k": 29,
        "135k": 26,
        "140k": 23,
        "145k": 21,
        "150k": 21,
        "155k": 32,
        "160k": 29,
        "165k": 3,
        "170k": 8,
        "175k": 27,
        "180k": 19,
        "185k": 5,
        "190k": 10,
        "195k": 16,
        "200k": 12,
        "350k": 158
      },
      childm: {
        "5k": 2,
        "10k": 0,
        "15k": 2,
        "20k": 5,
        "25k": 2,
        "30k": 0,
        "35k": 1,
        "40k": 4,
        "45k": 3,
        "50k": 0,
        "55k": 2,
        "60k": 0,
        "65k": 0,
        "70k": 7,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 1,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 5,
        "10k": 6,
        "15k": 9,
        "20k": 16,
        "25k": 19,
        "30k": 4,
        "35k": 23,
        "40k": 28,
        "45k": 13,
        "50k": 10,
        "55k": 12,
        "60k": 14,
        "65k": 23,
        "70k": 15,
        "75k": 11,
        "80k": 13,
        "85k": 24,
        "90k": 12,
        "95k": 18,
        "100k": 19,
        "105k": 7,
        "110k": 12,
        "115k": 9,
        "120k": 7,
        "125k": 4,
        "130k": 7,
        "135k": 2,
        "140k": 3,
        "145k": 8,
        "150k": 4,
        "155k": 6,
        "160k": 7,
        "165k": 2,
        "170k": 3,
        "175k": 3,
        "180k": 4,
        "185k": 3,
        "190k": 0,
        "195k": 3,
        "200k": 6,
        "350k": 15
      },
      nonRelativem: {
        "5k": 2,
        "10k": 2,
        "15k": 9,
        "20k": 3,
        "25k": 8,
        "30k": 21,
        "35k": 12,
        "40k": 17,
        "45k": 7,
        "50k": 19,
        "55k": 10,
        "60k": 5,
        "65k": 7,
        "70k": 4,
        "75k": 11,
        "80k": 1,
        "85k": 1,
        "90k": 6,
        "95k": 5,
        "100k": 4,
        "105k": 8,
        "110k": 1,
        "115k": 5,
        "120k": 2,
        "125k": 0,
        "130k": 0,
        "135k": 4,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 3,
        "160k": 4,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 16
      },
      houseLoitererfm: {
        "5k": 153,
        "10k": 436,
        "15k": 747,
        "20k": 713,
        "25k": 587,
        "30k": 524,
        "35k": 436,
        "40k": 442,
        "45k": 349,
        "50k": 249,
        "55k": 308,
        "60k": 209,
        "65k": 189,
        "70k": 188,
        "75k": 133,
        "80k": 156,
        "85k": 99,
        "90k": 103,
        "95k": 97,
        "100k": 101,
        "105k": 76,
        "110k": 66,
        "115k": 62,
        "120k": 80,
        "125k": 33,
        "130k": 42,
        "135k": 32,
        "140k": 19,
        "145k": 24,
        "150k": 22,
        "155k": 37,
        "160k": 24,
        "165k": 24,
        "170k": 14,
        "175k": 19,
        "180k": 7,
        "185k": 6,
        "190k": 7,
        "195k": 13,
        "200k": 10,
        "350k": 127
      },
      spousefm: {
        "5k": 32,
        "10k": 20,
        "15k": 76,
        "20k": 132,
        "25k": 232,
        "30k": 320,
        "35k": 281,
        "40k": 319,
        "45k": 284,
        "50k": 260,
        "55k": 256,
        "60k": 172,
        "65k": 178,
        "70k": 201,
        "75k": 148,
        "80k": 158,
        "85k": 114,
        "90k": 112,
        "95k": 104,
        "100k": 105,
        "105k": 90,
        "110k": 50,
        "115k": 66,
        "120k": 72,
        "125k": 41,
        "130k": 26,
        "135k": 53,
        "140k": 17,
        "145k": 29,
        "150k": 24,
        "155k": 33,
        "160k": 23,
        "165k": 17,
        "170k": 21,
        "175k": 27,
        "180k": 18,
        "185k": 11,
        "190k": 8,
        "195k": 13,
        "200k": 13,
        "350k": 174
      },
      childfm: {
        "5k": 3,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 4,
        "30k": 3,
        "35k": 2,
        "40k": 3,
        "45k": 2,
        "50k": 1,
        "55k": 0,
        "60k": 5,
        "65k": 0,
        "70k": 6,
        "75k": 2,
        "80k": 0,
        "85k": 8,
        "90k": 3,
        "95k": 0,
        "100k": 0,
        "105k": 1,
        "110k": 0,
        "115k": 0,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 10,
        "10k": 8,
        "15k": 15,
        "20k": 29,
        "25k": 45,
        "30k": 35,
        "35k": 49,
        "40k": 37,
        "45k": 37,
        "50k": 31,
        "55k": 29,
        "60k": 42,
        "65k": 47,
        "70k": 24,
        "75k": 25,
        "80k": 31,
        "85k": 41,
        "90k": 34,
        "95k": 21,
        "100k": 36,
        "105k": 17,
        "110k": 20,
        "115k": 9,
        "120k": 9,
        "125k": 9,
        "130k": 7,
        "135k": 23,
        "140k": 10,
        "145k": 12,
        "150k": 12,
        "155k": 4,
        "160k": 6,
        "165k": 9,
        "170k": 3,
        "175k": 9,
        "180k": 3,
        "185k": 10,
        "190k": 3,
        "195k": 9,
        "200k": 6,
        "350k": 50
      },
      nonRelativefm: {
        "5k": 2,
        "10k": 4,
        "15k": 3,
        "20k": 8,
        "25k": 11,
        "30k": 23,
        "35k": 5,
        "40k": 11,
        "45k": 8,
        "50k": 17,
        "55k": 10,
        "60k": 2,
        "65k": 4,
        "70k": 7,
        "75k": 15,
        "80k": 3,
        "85k": 3,
        "90k": 2,
        "95k": 5,
        "100k": 3,
        "105k": 10,
        "110k": 3,
        "115k": 2,
        "120k": 6,
        "125k": 0,
        "130k": 3,
        "135k": 0,
        "140k": 5,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 2,
        "165k": 3,
        "170k": 3,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      }
    }
  },
  "2012": {
    "17": {
      houseLoitererm: {
        "5k": 4,
        "10k": 3,
        "15k": 9,
        "20k": 6,
        "25k": 1,
        "30k": 8,
        "35k": 0,
        "40k": 3,
        "45k": 4,
        "50k": 4,
        "55k": 5,
        "60k": 4,
        "65k": 4,
        "70k": 2,
        "75k": 3,
        "80k": 4,
        "85k": 1,
        "90k": 2,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 2,
        "120k": 2,
        "125k": 0,
        "130k": 2,
        "135k": 2,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 301,
        "10k": 241,
        "15k": 384,
        "20k": 436,
        "25k": 492,
        "30k": 503,
        "35k": 518,
        "40k": 532,
        "45k": 468,
        "50k": 422,
        "55k": 404,
        "60k": 433,
        "65k": 443,
        "70k": 415,
        "75k": 396,
        "80k": 364,
        "85k": 333,
        "90k": 339,
        "95k": 273,
        "100k": 265,
        "105k": 335,
        "110k": 248,
        "115k": 245,
        "120k": 212,
        "125k": 215,
        "130k": 179,
        "135k": 174,
        "140k": 127,
        "145k": 158,
        "150k": 146,
        "155k": 143,
        "160k": 114,
        "165k": 93,
        "170k": 89,
        "175k": 104,
        "180k": 90,
        "185k": 67,
        "190k": 56,
        "195k": 69,
        "200k": 55,
        "350k": 846
      },
      otherRelativem: {
        "5k": 31,
        "10k": 38,
        "15k": 47,
        "20k": 62,
        "25k": 75,
        "30k": 46,
        "35k": 75,
        "40k": 72,
        "45k": 52,
        "50k": 50,
        "55k": 39,
        "60k": 55,
        "65k": 37,
        "70k": 38,
        "75k": 28,
        "80k": 14,
        "85k": 29,
        "90k": 23,
        "95k": 28,
        "100k": 15,
        "105k": 8,
        "110k": 18,
        "115k": 12,
        "120k": 10,
        "125k": 3,
        "130k": 13,
        "135k": 9,
        "140k": 10,
        "145k": 6,
        "150k": 6,
        "155k": 11,
        "160k": 3,
        "165k": 6,
        "170k": 0,
        "175k": 4,
        "180k": 1,
        "185k": 3,
        "190k": 1,
        "195k": 0,
        "200k": 2,
        "350k": 28
      },
      nonRelativem: {
        "5k": 7,
        "10k": 4,
        "15k": 12,
        "20k": 7,
        "25k": 20,
        "30k": 6,
        "35k": 7,
        "40k": 6,
        "45k": 10,
        "50k": 13,
        "55k": 11,
        "60k": 5,
        "65k": 9,
        "70k": 14,
        "75k": 9,
        "80k": 17,
        "85k": 9,
        "90k": 6,
        "95k": 10,
        "100k": 8,
        "105k": 7,
        "110k": 2,
        "115k": 6,
        "120k": 4,
        "125k": 4,
        "130k": 2,
        "135k": 2,
        "140k": 5,
        "145k": 1,
        "150k": 3,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 1,
        "200k": 3,
        "350k": 11
      },
      houseLoitererfm: {
        "5k": 14,
        "10k": 7,
        "15k": 2,
        "20k": 6,
        "25k": 11,
        "30k": 7,
        "35k": 6,
        "40k": 3,
        "45k": 3,
        "50k": 3,
        "55k": 5,
        "60k": 3,
        "65k": 4,
        "70k": 1,
        "75k": 7,
        "80k": 2,
        "85k": 2,
        "90k": 3,
        "95k": 1,
        "100k": 0,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 325,
        "10k": 232,
        "15k": 379,
        "20k": 396,
        "25k": 458,
        "30k": 474,
        "35k": 501,
        "40k": 462,
        "45k": 536,
        "50k": 429,
        "55k": 437,
        "60k": 362,
        "65k": 420,
        "70k": 364,
        "75k": 362,
        "80k": 368,
        "85k": 308,
        "90k": 322,
        "95k": 284,
        "100k": 289,
        "105k": 320,
        "110k": 222,
        "115k": 228,
        "120k": 178,
        "125k": 192,
        "130k": 158,
        "135k": 173,
        "140k": 111,
        "145k": 142,
        "150k": 110,
        "155k": 161,
        "160k": 112,
        "165k": 108,
        "170k": 65,
        "175k": 83,
        "180k": 66,
        "185k": 57,
        "190k": 44,
        "195k": 68,
        "200k": 85,
        "350k": 733
      },
      otherRelativefm: {
        "5k": 24,
        "10k": 26,
        "15k": 54,
        "20k": 66,
        "25k": 68,
        "30k": 52,
        "35k": 53,
        "40k": 72,
        "45k": 58,
        "50k": 42,
        "55k": 48,
        "60k": 53,
        "65k": 40,
        "70k": 39,
        "75k": 38,
        "80k": 13,
        "85k": 24,
        "90k": 29,
        "95k": 23,
        "100k": 16,
        "105k": 24,
        "110k": 24,
        "115k": 18,
        "120k": 5,
        "125k": 12,
        "130k": 4,
        "135k": 10,
        "140k": 3,
        "145k": 6,
        "150k": 5,
        "155k": 13,
        "160k": 3,
        "165k": 9,
        "170k": 2,
        "175k": 1,
        "180k": 4,
        "185k": 0,
        "190k": 7,
        "195k": 4,
        "200k": 2,
        "350k": 29
      },
      nonRelativefm: {
        "5k": 3,
        "10k": 5,
        "15k": 7,
        "20k": 13,
        "25k": 17,
        "30k": 7,
        "35k": 11,
        "40k": 13,
        "45k": 14,
        "50k": 13,
        "55k": 22,
        "60k": 16,
        "65k": 7,
        "70k": 5,
        "75k": 9,
        "80k": 8,
        "85k": 16,
        "90k": 11,
        "95k": 9,
        "100k": 6,
        "105k": 8,
        "110k": 3,
        "115k": 6,
        "120k": 5,
        "125k": 5,
        "130k": 0,
        "135k": 2,
        "140k": 4,
        "145k": 5,
        "150k": 0,
        "155k": 4,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 3,
        "200k": 1,
        "350k": 7
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 76,
        "10k": 68,
        "15k": 99,
        "20k": 70,
        "25k": 106,
        "30k": 67,
        "35k": 68,
        "40k": 70,
        "45k": 47,
        "50k": 42,
        "55k": 32,
        "60k": 31,
        "65k": 26,
        "70k": 23,
        "75k": 11,
        "80k": 15,
        "85k": 25,
        "90k": 16,
        "95k": 4,
        "100k": 12,
        "105k": 5,
        "110k": 4,
        "115k": 4,
        "120k": 8,
        "125k": 11,
        "130k": 4,
        "135k": 0,
        "140k": 2,
        "145k": 2,
        "150k": 0,
        "155k": 2,
        "160k": 1,
        "165k": 6,
        "170k": 0,
        "175k": 3,
        "180k": 2,
        "185k": 0,
        "190k": 2,
        "195k": 1,
        "200k": 0,
        "350k": 6
      },
      spousem: {
        "5k": 2,
        "10k": 8,
        "15k": 5,
        "20k": 7,
        "25k": 7,
        "30k": 3,
        "35k": 6,
        "40k": 5,
        "45k": 5,
        "50k": 3,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 3,
        "80k": 6,
        "85k": 0,
        "90k": 2,
        "95k": 1,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 2,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 147,
        "10k": 65,
        "15k": 142,
        "20k": 192,
        "25k": 225,
        "30k": 205,
        "35k": 219,
        "40k": 213,
        "45k": 210,
        "50k": 250,
        "55k": 195,
        "60k": 193,
        "65k": 218,
        "70k": 225,
        "75k": 176,
        "80k": 196,
        "85k": 176,
        "90k": 198,
        "95k": 183,
        "100k": 140,
        "105k": 152,
        "110k": 151,
        "115k": 139,
        "120k": 133,
        "125k": 139,
        "130k": 101,
        "135k": 83,
        "140k": 91,
        "145k": 87,
        "150k": 71,
        "155k": 71,
        "160k": 87,
        "165k": 48,
        "170k": 52,
        "175k": 64,
        "180k": 34,
        "185k": 49,
        "190k": 38,
        "195k": 20,
        "200k": 35,
        "350k": 466
      },
      otherRelativem: {
        "5k": 21,
        "10k": 14,
        "15k": 17,
        "20k": 42,
        "25k": 47,
        "30k": 51,
        "35k": 68,
        "40k": 49,
        "45k": 51,
        "50k": 46,
        "55k": 41,
        "60k": 43,
        "65k": 25,
        "70k": 27,
        "75k": 26,
        "80k": 8,
        "85k": 34,
        "90k": 19,
        "95k": 17,
        "100k": 11,
        "105k": 7,
        "110k": 15,
        "115k": 25,
        "120k": 10,
        "125k": 7,
        "130k": 9,
        "135k": 14,
        "140k": 6,
        "145k": 2,
        "150k": 6,
        "155k": 10,
        "160k": 7,
        "165k": 7,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 0,
        "190k": 2,
        "195k": 2,
        "200k": 0,
        "350k": 22
      },
      nonRelativem: {
        "5k": 45,
        "10k": 24,
        "15k": 61,
        "20k": 18,
        "25k": 52,
        "30k": 27,
        "35k": 40,
        "40k": 45,
        "45k": 28,
        "50k": 36,
        "55k": 61,
        "60k": 44,
        "65k": 27,
        "70k": 26,
        "75k": 25,
        "80k": 10,
        "85k": 10,
        "90k": 10,
        "95k": 6,
        "100k": 15,
        "105k": 6,
        "110k": 8,
        "115k": 15,
        "120k": 3,
        "125k": 3,
        "130k": 7,
        "135k": 7,
        "140k": 7,
        "145k": 0,
        "150k": 0,
        "155k": 3,
        "160k": 11,
        "165k": 12,
        "170k": 3,
        "175k": 7,
        "180k": 3,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 10
      },
      houseLoitererfm: {
        "5k": 179,
        "10k": 121,
        "15k": 113,
        "20k": 117,
        "25k": 109,
        "30k": 79,
        "35k": 78,
        "40k": 73,
        "45k": 38,
        "50k": 20,
        "55k": 39,
        "60k": 33,
        "65k": 6,
        "70k": 31,
        "75k": 26,
        "80k": 4,
        "85k": 10,
        "90k": 12,
        "95k": 8,
        "100k": 13,
        "105k": 0,
        "110k": 3,
        "115k": 2,
        "120k": 13,
        "125k": 3,
        "130k": 5,
        "135k": 2,
        "140k": 1,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 1,
        "170k": 2,
        "175k": 0,
        "180k": 1,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      spousefm: {
        "5k": 3,
        "10k": 11,
        "15k": 17,
        "20k": 19,
        "25k": 8,
        "30k": 15,
        "35k": 22,
        "40k": 25,
        "45k": 9,
        "50k": 17,
        "55k": 13,
        "60k": 3,
        "65k": 4,
        "70k": 0,
        "75k": 0,
        "80k": 10,
        "85k": 0,
        "90k": 6,
        "95k": 2,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 0,
        "140k": 1,
        "145k": 0,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 5
      },
      childfm: {
        "5k": 91,
        "10k": 84,
        "15k": 95,
        "20k": 114,
        "25k": 188,
        "30k": 192,
        "35k": 198,
        "40k": 187,
        "45k": 195,
        "50k": 190,
        "55k": 186,
        "60k": 186,
        "65k": 207,
        "70k": 190,
        "75k": 220,
        "80k": 169,
        "85k": 136,
        "90k": 141,
        "95k": 145,
        "100k": 155,
        "105k": 168,
        "110k": 111,
        "115k": 143,
        "120k": 95,
        "125k": 105,
        "130k": 95,
        "135k": 78,
        "140k": 95,
        "145k": 109,
        "150k": 77,
        "155k": 80,
        "160k": 61,
        "165k": 60,
        "170k": 39,
        "175k": 66,
        "180k": 38,
        "185k": 47,
        "190k": 20,
        "195k": 31,
        "200k": 26,
        "350k": 477
      },
      otherRelativefm: {
        "5k": 11,
        "10k": 35,
        "15k": 26,
        "20k": 17,
        "25k": 45,
        "30k": 71,
        "35k": 56,
        "40k": 39,
        "45k": 43,
        "50k": 36,
        "55k": 30,
        "60k": 25,
        "65k": 20,
        "70k": 30,
        "75k": 21,
        "80k": 23,
        "85k": 13,
        "90k": 25,
        "95k": 17,
        "100k": 14,
        "105k": 20,
        "110k": 6,
        "115k": 9,
        "120k": 5,
        "125k": 6,
        "130k": 9,
        "135k": 8,
        "140k": 2,
        "145k": 0,
        "150k": 7,
        "155k": 9,
        "160k": 1,
        "165k": 2,
        "170k": 1,
        "175k": 2,
        "180k": 0,
        "185k": 5,
        "190k": 0,
        "195k": 4,
        "200k": 0,
        "350k": 19
      },
      nonRelativefm: {
        "5k": 46,
        "10k": 21,
        "15k": 48,
        "20k": 34,
        "25k": 63,
        "30k": 45,
        "35k": 62,
        "40k": 19,
        "45k": 45,
        "50k": 42,
        "55k": 42,
        "60k": 38,
        "65k": 25,
        "70k": 12,
        "75k": 42,
        "80k": 17,
        "85k": 22,
        "90k": 13,
        "95k": 15,
        "100k": 28,
        "105k": 7,
        "110k": 7,
        "115k": 10,
        "120k": 8,
        "125k": 17,
        "130k": 8,
        "135k": 5,
        "140k": 7,
        "145k": 0,
        "150k": 5,
        "155k": 4,
        "160k": 10,
        "165k": 12,
        "170k": 0,
        "175k": 6,
        "180k": 6,
        "185k": 0,
        "190k": 5,
        "195k": 0,
        "200k": 0,
        "350k": 29
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 105,
        "10k": 104,
        "15k": 134,
        "20k": 126,
        "25k": 140,
        "30k": 127,
        "35k": 105,
        "40k": 89,
        "45k": 110,
        "50k": 108,
        "55k": 122,
        "60k": 77,
        "65k": 44,
        "70k": 56,
        "75k": 70,
        "80k": 53,
        "85k": 36,
        "90k": 30,
        "95k": 29,
        "100k": 31,
        "105k": 14,
        "110k": 17,
        "115k": 17,
        "120k": 9,
        "125k": 4,
        "130k": 7,
        "135k": 4,
        "140k": 9,
        "145k": 3,
        "150k": 11,
        "155k": 10,
        "160k": 8,
        "165k": 7,
        "170k": 2,
        "175k": 0,
        "180k": 5,
        "185k": 4,
        "190k": 1,
        "195k": 2,
        "200k": 4,
        "350k": 19
      },
      spousem: {
        "5k": 6,
        "10k": 7,
        "15k": 24,
        "20k": 18,
        "25k": 28,
        "30k": 16,
        "35k": 30,
        "40k": 24,
        "45k": 16,
        "50k": 27,
        "55k": 29,
        "60k": 5,
        "65k": 7,
        "70k": 8,
        "75k": 2,
        "80k": 3,
        "85k": 11,
        "90k": 3,
        "95k": 2,
        "100k": 1,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 0,
        "140k": 3,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 27,
        "10k": 64,
        "15k": 65,
        "20k": 71,
        "25k": 91,
        "30k": 75,
        "35k": 89,
        "40k": 136,
        "45k": 102,
        "50k": 103,
        "55k": 96,
        "60k": 111,
        "65k": 86,
        "70k": 100,
        "75k": 80,
        "80k": 95,
        "85k": 83,
        "90k": 73,
        "95k": 90,
        "100k": 74,
        "105k": 83,
        "110k": 84,
        "115k": 92,
        "120k": 44,
        "125k": 69,
        "130k": 47,
        "135k": 53,
        "140k": 65,
        "145k": 55,
        "150k": 59,
        "155k": 43,
        "160k": 63,
        "165k": 31,
        "170k": 15,
        "175k": 18,
        "180k": 44,
        "185k": 24,
        "190k": 26,
        "195k": 24,
        "200k": 12,
        "350k": 324
      },
      otherRelativem: {
        "5k": 9,
        "10k": 12,
        "15k": 13,
        "20k": 35,
        "25k": 31,
        "30k": 29,
        "35k": 32,
        "40k": 33,
        "45k": 28,
        "50k": 23,
        "55k": 35,
        "60k": 30,
        "65k": 24,
        "70k": 26,
        "75k": 23,
        "80k": 17,
        "85k": 22,
        "90k": 19,
        "95k": 11,
        "100k": 7,
        "105k": 6,
        "110k": 11,
        "115k": 10,
        "120k": 8,
        "125k": 7,
        "130k": 8,
        "135k": 7,
        "140k": 3,
        "145k": 10,
        "150k": 17,
        "155k": 1,
        "160k": 4,
        "165k": 1,
        "170k": 1,
        "175k": 2,
        "180k": 2,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 20
      },
      nonRelativem: {
        "5k": 38,
        "10k": 13,
        "15k": 34,
        "20k": 45,
        "25k": 56,
        "30k": 81,
        "35k": 61,
        "40k": 47,
        "45k": 42,
        "50k": 40,
        "55k": 52,
        "60k": 35,
        "65k": 45,
        "70k": 40,
        "75k": 38,
        "80k": 38,
        "85k": 21,
        "90k": 32,
        "95k": 11,
        "100k": 23,
        "105k": 22,
        "110k": 14,
        "115k": 3,
        "120k": 21,
        "125k": 6,
        "130k": 4,
        "135k": 14,
        "140k": 10,
        "145k": 6,
        "150k": 1,
        "155k": 10,
        "160k": 2,
        "165k": 3,
        "170k": 16,
        "175k": 3,
        "180k": 12,
        "185k": 3,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 54
      },
      houseLoitererfm: {
        "5k": 179,
        "10k": 124,
        "15k": 200,
        "20k": 198,
        "25k": 216,
        "30k": 164,
        "35k": 144,
        "40k": 112,
        "45k": 97,
        "50k": 87,
        "55k": 88,
        "60k": 62,
        "65k": 75,
        "70k": 54,
        "75k": 39,
        "80k": 33,
        "85k": 49,
        "90k": 23,
        "95k": 23,
        "100k": 17,
        "105k": 30,
        "110k": 13,
        "115k": 25,
        "120k": 11,
        "125k": 18,
        "130k": 8,
        "135k": 6,
        "140k": 6,
        "145k": 2,
        "150k": 4,
        "155k": 6,
        "160k": 6,
        "165k": 0,
        "170k": 5,
        "175k": 2,
        "180k": 10,
        "185k": 6,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 20
      },
      spousefm: {
        "5k": 17,
        "10k": 10,
        "15k": 9,
        "20k": 27,
        "25k": 27,
        "30k": 42,
        "35k": 45,
        "40k": 38,
        "45k": 32,
        "50k": 37,
        "55k": 49,
        "60k": 24,
        "65k": 22,
        "70k": 33,
        "75k": 18,
        "80k": 42,
        "85k": 25,
        "90k": 7,
        "95k": 16,
        "100k": 3,
        "105k": 9,
        "110k": 10,
        "115k": 0,
        "120k": 7,
        "125k": 6,
        "130k": 0,
        "135k": 2,
        "140k": 7,
        "145k": 1,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 28,
        "10k": 51,
        "15k": 60,
        "20k": 51,
        "25k": 82,
        "30k": 49,
        "35k": 80,
        "40k": 88,
        "45k": 74,
        "50k": 88,
        "55k": 77,
        "60k": 88,
        "65k": 88,
        "70k": 126,
        "75k": 84,
        "80k": 70,
        "85k": 72,
        "90k": 63,
        "95k": 72,
        "100k": 60,
        "105k": 97,
        "110k": 73,
        "115k": 66,
        "120k": 48,
        "125k": 55,
        "130k": 57,
        "135k": 52,
        "140k": 29,
        "145k": 52,
        "150k": 36,
        "155k": 22,
        "160k": 26,
        "165k": 34,
        "170k": 33,
        "175k": 30,
        "180k": 27,
        "185k": 15,
        "190k": 17,
        "195k": 9,
        "200k": 31,
        "350k": 185
      },
      otherRelativefm: {
        "5k": 13,
        "10k": 6,
        "15k": 7,
        "20k": 14,
        "25k": 14,
        "30k": 11,
        "35k": 15,
        "40k": 35,
        "45k": 27,
        "50k": 8,
        "55k": 12,
        "60k": 30,
        "65k": 19,
        "70k": 17,
        "75k": 10,
        "80k": 12,
        "85k": 22,
        "90k": 15,
        "95k": 10,
        "100k": 6,
        "105k": 14,
        "110k": 13,
        "115k": 6,
        "120k": 3,
        "125k": 6,
        "130k": 2,
        "135k": 15,
        "140k": 1,
        "145k": 4,
        "150k": 2,
        "155k": 2,
        "160k": 4,
        "165k": 0,
        "170k": 3,
        "175k": 6,
        "180k": 1,
        "185k": 0,
        "190k": 5,
        "195k": 0,
        "200k": 1,
        "350k": 23
      },
      nonRelativefm: {
        "5k": 42,
        "10k": 12,
        "15k": 50,
        "20k": 40,
        "25k": 54,
        "30k": 63,
        "35k": 69,
        "40k": 83,
        "45k": 72,
        "50k": 58,
        "55k": 52,
        "60k": 37,
        "65k": 55,
        "70k": 42,
        "75k": 23,
        "80k": 32,
        "85k": 51,
        "90k": 31,
        "95k": 18,
        "100k": 28,
        "105k": 14,
        "110k": 19,
        "115k": 13,
        "120k": 14,
        "125k": 17,
        "130k": 4,
        "135k": 8,
        "140k": 7,
        "145k": 15,
        "150k": 11,
        "155k": 6,
        "160k": 8,
        "165k": 2,
        "170k": 2,
        "175k": 0,
        "180k": 5,
        "185k": 6,
        "190k": 3,
        "195k": 7,
        "200k": 0,
        "350k": 28
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 120,
        "10k": 110,
        "15k": 162,
        "20k": 169,
        "25k": 256,
        "30k": 241,
        "35k": 238,
        "40k": 312,
        "45k": 246,
        "50k": 235,
        "55k": 255,
        "60k": 178,
        "65k": 237,
        "70k": 199,
        "75k": 144,
        "80k": 164,
        "85k": 151,
        "90k": 103,
        "95k": 129,
        "100k": 95,
        "105k": 82,
        "110k": 75,
        "115k": 57,
        "120k": 48,
        "125k": 55,
        "130k": 25,
        "135k": 50,
        "140k": 43,
        "145k": 44,
        "150k": 31,
        "155k": 24,
        "160k": 29,
        "165k": 20,
        "170k": 7,
        "175k": 5,
        "180k": 16,
        "185k": 7,
        "190k": 11,
        "195k": 8,
        "200k": 4,
        "350k": 88
      },
      spousem: {
        "5k": 22,
        "10k": 11,
        "15k": 32,
        "20k": 29,
        "25k": 52,
        "30k": 75,
        "35k": 54,
        "40k": 62,
        "45k": 49,
        "50k": 54,
        "55k": 66,
        "60k": 58,
        "65k": 84,
        "70k": 56,
        "75k": 48,
        "80k": 50,
        "85k": 43,
        "90k": 30,
        "95k": 44,
        "100k": 29,
        "105k": 27,
        "110k": 13,
        "115k": 13,
        "120k": 26,
        "125k": 17,
        "130k": 11,
        "135k": 10,
        "140k": 20,
        "145k": 11,
        "150k": 6,
        "155k": 5,
        "160k": 4,
        "165k": 2,
        "170k": 2,
        "175k": 2,
        "180k": 8,
        "185k": 4,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 15
      },
      childm: {
        "5k": 47,
        "10k": 33,
        "15k": 58,
        "20k": 49,
        "25k": 82,
        "30k": 84,
        "35k": 93,
        "40k": 89,
        "45k": 64,
        "50k": 93,
        "55k": 79,
        "60k": 92,
        "65k": 91,
        "70k": 54,
        "75k": 80,
        "80k": 76,
        "85k": 75,
        "90k": 79,
        "95k": 76,
        "100k": 56,
        "105k": 95,
        "110k": 43,
        "115k": 76,
        "120k": 38,
        "125k": 44,
        "130k": 37,
        "135k": 22,
        "140k": 40,
        "145k": 42,
        "150k": 30,
        "155k": 41,
        "160k": 53,
        "165k": 32,
        "170k": 49,
        "175k": 28,
        "180k": 22,
        "185k": 21,
        "190k": 20,
        "195k": 9,
        "200k": 13,
        "350k": 217
      },
      otherRelativem: {
        "5k": 8,
        "10k": 26,
        "15k": 41,
        "20k": 13,
        "25k": 29,
        "30k": 36,
        "35k": 33,
        "40k": 26,
        "45k": 47,
        "50k": 43,
        "55k": 59,
        "60k": 63,
        "65k": 22,
        "70k": 22,
        "75k": 18,
        "80k": 27,
        "85k": 27,
        "90k": 31,
        "95k": 17,
        "100k": 20,
        "105k": 35,
        "110k": 16,
        "115k": 35,
        "120k": 13,
        "125k": 16,
        "130k": 19,
        "135k": 11,
        "140k": 5,
        "145k": 4,
        "150k": 8,
        "155k": 14,
        "160k": 8,
        "165k": 2,
        "170k": 1,
        "175k": 0,
        "180k": 3,
        "185k": 0,
        "190k": 3,
        "195k": 0,
        "200k": 2,
        "350k": 33
      },
      nonRelativem: {
        "5k": 31,
        "10k": 32,
        "15k": 38,
        "20k": 50,
        "25k": 74,
        "30k": 71,
        "35k": 60,
        "40k": 84,
        "45k": 78,
        "50k": 60,
        "55k": 104,
        "60k": 85,
        "65k": 75,
        "70k": 74,
        "75k": 59,
        "80k": 50,
        "85k": 61,
        "90k": 38,
        "95k": 66,
        "100k": 53,
        "105k": 45,
        "110k": 30,
        "115k": 44,
        "120k": 12,
        "125k": 34,
        "130k": 17,
        "135k": 18,
        "140k": 38,
        "145k": 33,
        "150k": 23,
        "155k": 25,
        "160k": 25,
        "165k": 9,
        "170k": 10,
        "175k": 7,
        "180k": 22,
        "185k": 7,
        "190k": 13,
        "195k": 11,
        "200k": 2,
        "350k": 78
      },
      houseLoitererfm: {
        "5k": 282,
        "10k": 233,
        "15k": 268,
        "20k": 299,
        "25k": 337,
        "30k": 324,
        "35k": 284,
        "40k": 288,
        "45k": 227,
        "50k": 251,
        "55k": 231,
        "60k": 158,
        "65k": 181,
        "70k": 161,
        "75k": 132,
        "80k": 126,
        "85k": 116,
        "90k": 94,
        "95k": 112,
        "100k": 82,
        "105k": 81,
        "110k": 63,
        "115k": 47,
        "120k": 49,
        "125k": 42,
        "130k": 42,
        "135k": 30,
        "140k": 29,
        "145k": 33,
        "150k": 13,
        "155k": 21,
        "160k": 13,
        "165k": 14,
        "170k": 13,
        "175k": 12,
        "180k": 9,
        "185k": 2,
        "190k": 7,
        "195k": 3,
        "200k": 6,
        "350k": 60
      },
      spousefm: {
        "5k": 37,
        "10k": 18,
        "15k": 57,
        "20k": 72,
        "25k": 113,
        "30k": 74,
        "35k": 98,
        "40k": 107,
        "45k": 141,
        "50k": 123,
        "55k": 125,
        "60k": 121,
        "65k": 126,
        "70k": 106,
        "75k": 128,
        "80k": 116,
        "85k": 89,
        "90k": 79,
        "95k": 66,
        "100k": 57,
        "105k": 80,
        "110k": 44,
        "115k": 49,
        "120k": 32,
        "125k": 64,
        "130k": 16,
        "135k": 19,
        "140k": 16,
        "145k": 24,
        "150k": 13,
        "155k": 21,
        "160k": 20,
        "165k": 8,
        "170k": 2,
        "175k": 13,
        "180k": 6,
        "185k": 2,
        "190k": 5,
        "195k": 5,
        "200k": 3,
        "350k": 53
      },
      childfm: {
        "5k": 15,
        "10k": 23,
        "15k": 21,
        "20k": 69,
        "25k": 52,
        "30k": 50,
        "35k": 31,
        "40k": 51,
        "45k": 33,
        "50k": 69,
        "55k": 57,
        "60k": 63,
        "65k": 52,
        "70k": 53,
        "75k": 56,
        "80k": 58,
        "85k": 47,
        "90k": 35,
        "95k": 59,
        "100k": 49,
        "105k": 41,
        "110k": 35,
        "115k": 36,
        "120k": 26,
        "125k": 30,
        "130k": 47,
        "135k": 33,
        "140k": 20,
        "145k": 37,
        "150k": 9,
        "155k": 13,
        "160k": 13,
        "165k": 13,
        "170k": 19,
        "175k": 26,
        "180k": 11,
        "185k": 5,
        "190k": 11,
        "195k": 12,
        "200k": 5,
        "350k": 168
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 8,
        "15k": 16,
        "20k": 24,
        "25k": 23,
        "30k": 17,
        "35k": 14,
        "40k": 30,
        "45k": 30,
        "50k": 21,
        "55k": 20,
        "60k": 25,
        "65k": 27,
        "70k": 13,
        "75k": 13,
        "80k": 16,
        "85k": 17,
        "90k": 22,
        "95k": 10,
        "100k": 9,
        "105k": 7,
        "110k": 12,
        "115k": 7,
        "120k": 14,
        "125k": 9,
        "130k": 8,
        "135k": 5,
        "140k": 8,
        "145k": 7,
        "150k": 8,
        "155k": 6,
        "160k": 2,
        "165k": 0,
        "170k": 9,
        "175k": 1,
        "180k": 1,
        "185k": 0,
        "190k": 4,
        "195k": 0,
        "200k": 0,
        "350k": 31
      },
      nonRelativefm: {
        "5k": 33,
        "10k": 34,
        "15k": 19,
        "20k": 18,
        "25k": 51,
        "30k": 47,
        "35k": 47,
        "40k": 77,
        "45k": 58,
        "50k": 60,
        "55k": 85,
        "60k": 34,
        "65k": 75,
        "70k": 49,
        "75k": 59,
        "80k": 43,
        "85k": 44,
        "90k": 37,
        "95k": 52,
        "100k": 47,
        "105k": 44,
        "110k": 27,
        "115k": 28,
        "120k": 20,
        "125k": 19,
        "130k": 29,
        "135k": 25,
        "140k": 20,
        "145k": 14,
        "150k": 10,
        "155k": 12,
        "160k": 5,
        "165k": 14,
        "170k": 6,
        "175k": 3,
        "180k": 5,
        "185k": 7,
        "190k": 4,
        "195k": 13,
        "200k": 6,
        "350k": 50
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 117,
        "10k": 128,
        "15k": 136,
        "20k": 200,
        "25k": 213,
        "30k": 226,
        "35k": 259,
        "40k": 265,
        "45k": 256,
        "50k": 274,
        "55k": 325,
        "60k": 236,
        "65k": 229,
        "70k": 185,
        "75k": 276,
        "80k": 205,
        "85k": 217,
        "90k": 151,
        "95k": 194,
        "100k": 125,
        "105k": 160,
        "110k": 114,
        "115k": 124,
        "120k": 85,
        "125k": 105,
        "130k": 58,
        "135k": 55,
        "140k": 76,
        "145k": 55,
        "150k": 49,
        "155k": 45,
        "160k": 36,
        "165k": 37,
        "170k": 24,
        "175k": 43,
        "180k": 30,
        "185k": 27,
        "190k": 10,
        "195k": 22,
        "200k": 13,
        "350k": 210
      },
      spousem: {
        "5k": 31,
        "10k": 19,
        "15k": 40,
        "20k": 53,
        "25k": 73,
        "30k": 73,
        "35k": 68,
        "40k": 86,
        "45k": 80,
        "50k": 81,
        "55k": 87,
        "60k": 88,
        "65k": 116,
        "70k": 75,
        "75k": 100,
        "80k": 82,
        "85k": 99,
        "90k": 83,
        "95k": 83,
        "100k": 49,
        "105k": 76,
        "110k": 60,
        "115k": 43,
        "120k": 39,
        "125k": 37,
        "130k": 25,
        "135k": 30,
        "140k": 22,
        "145k": 21,
        "150k": 30,
        "155k": 22,
        "160k": 25,
        "165k": 12,
        "170k": 7,
        "175k": 10,
        "180k": 13,
        "185k": 9,
        "190k": 1,
        "195k": 4,
        "200k": 9,
        "350k": 64
      },
      childm: {
        "5k": 29,
        "10k": 13,
        "15k": 19,
        "20k": 37,
        "25k": 44,
        "30k": 42,
        "35k": 41,
        "40k": 53,
        "45k": 36,
        "50k": 35,
        "55k": 51,
        "60k": 45,
        "65k": 40,
        "70k": 40,
        "75k": 34,
        "80k": 23,
        "85k": 28,
        "90k": 41,
        "95k": 23,
        "100k": 32,
        "105k": 35,
        "110k": 22,
        "115k": 23,
        "120k": 20,
        "125k": 23,
        "130k": 8,
        "135k": 23,
        "140k": 19,
        "145k": 15,
        "150k": 11,
        "155k": 25,
        "160k": 9,
        "165k": 5,
        "170k": 7,
        "175k": 5,
        "180k": 12,
        "185k": 4,
        "190k": 4,
        "195k": 3,
        "200k": 3,
        "350k": 85
      },
      otherRelativem: {
        "5k": 5,
        "10k": 8,
        "15k": 0,
        "20k": 17,
        "25k": 17,
        "30k": 40,
        "35k": 14,
        "40k": 17,
        "45k": 22,
        "50k": 16,
        "55k": 23,
        "60k": 17,
        "65k": 25,
        "70k": 12,
        "75k": 16,
        "80k": 25,
        "85k": 24,
        "90k": 28,
        "95k": 8,
        "100k": 11,
        "105k": 7,
        "110k": 12,
        "115k": 10,
        "120k": 12,
        "125k": 7,
        "130k": 9,
        "135k": 4,
        "140k": 6,
        "145k": 3,
        "150k": 8,
        "155k": 2,
        "160k": 5,
        "165k": 0,
        "170k": 2,
        "175k": 2,
        "180k": 1,
        "185k": 4,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 36
      },
      nonRelativem: {
        "5k": 9,
        "10k": 13,
        "15k": 30,
        "20k": 39,
        "25k": 42,
        "30k": 43,
        "35k": 39,
        "40k": 52,
        "45k": 36,
        "50k": 45,
        "55k": 55,
        "60k": 46,
        "65k": 28,
        "70k": 48,
        "75k": 43,
        "80k": 16,
        "85k": 51,
        "90k": 32,
        "95k": 32,
        "100k": 22,
        "105k": 28,
        "110k": 19,
        "115k": 22,
        "120k": 18,
        "125k": 25,
        "130k": 7,
        "135k": 10,
        "140k": 14,
        "145k": 39,
        "150k": 12,
        "155k": 3,
        "160k": 12,
        "165k": 7,
        "170k": 7,
        "175k": 3,
        "180k": 6,
        "185k": 8,
        "190k": 2,
        "195k": 7,
        "200k": 2,
        "350k": 45
      },
      houseLoitererfm: {
        "5k": 232,
        "10k": 229,
        "15k": 295,
        "20k": 301,
        "25k": 323,
        "30k": 309,
        "35k": 270,
        "40k": 258,
        "45k": 207,
        "50k": 229,
        "55k": 229,
        "60k": 185,
        "65k": 208,
        "70k": 148,
        "75k": 153,
        "80k": 172,
        "85k": 127,
        "90k": 115,
        "95k": 133,
        "100k": 125,
        "105k": 122,
        "110k": 82,
        "115k": 81,
        "120k": 40,
        "125k": 68,
        "130k": 46,
        "135k": 48,
        "140k": 38,
        "145k": 34,
        "150k": 35,
        "155k": 54,
        "160k": 27,
        "165k": 24,
        "170k": 15,
        "175k": 20,
        "180k": 32,
        "185k": 19,
        "190k": 8,
        "195k": 14,
        "200k": 3,
        "350k": 117
      },
      spousefm: {
        "5k": 36,
        "10k": 47,
        "15k": 45,
        "20k": 84,
        "25k": 82,
        "30k": 100,
        "35k": 98,
        "40k": 137,
        "45k": 117,
        "50k": 128,
        "55k": 146,
        "60k": 130,
        "65k": 138,
        "70k": 136,
        "75k": 158,
        "80k": 145,
        "85k": 123,
        "90k": 107,
        "95k": 126,
        "100k": 86,
        "105k": 102,
        "110k": 94,
        "115k": 80,
        "120k": 84,
        "125k": 86,
        "130k": 56,
        "135k": 46,
        "140k": 68,
        "145k": 49,
        "150k": 54,
        "155k": 47,
        "160k": 41,
        "165k": 37,
        "170k": 32,
        "175k": 29,
        "180k": 30,
        "185k": 23,
        "190k": 15,
        "195k": 28,
        "200k": 16,
        "350k": 188
      },
      childfm: {
        "5k": 9,
        "10k": 9,
        "15k": 23,
        "20k": 34,
        "25k": 26,
        "30k": 28,
        "35k": 18,
        "40k": 26,
        "45k": 37,
        "50k": 21,
        "55k": 24,
        "60k": 25,
        "65k": 33,
        "70k": 11,
        "75k": 23,
        "80k": 26,
        "85k": 32,
        "90k": 32,
        "95k": 16,
        "100k": 22,
        "105k": 20,
        "110k": 14,
        "115k": 26,
        "120k": 8,
        "125k": 29,
        "130k": 29,
        "135k": 15,
        "140k": 4,
        "145k": 9,
        "150k": 4,
        "155k": 14,
        "160k": 10,
        "165k": 11,
        "170k": 4,
        "175k": 6,
        "180k": 3,
        "185k": 7,
        "190k": 0,
        "195k": 1,
        "200k": 12,
        "350k": 66
      },
      otherRelativefm: {
        "5k": 7,
        "10k": 5,
        "15k": 6,
        "20k": 14,
        "25k": 13,
        "30k": 15,
        "35k": 11,
        "40k": 10,
        "45k": 30,
        "50k": 16,
        "55k": 11,
        "60k": 16,
        "65k": 7,
        "70k": 10,
        "75k": 15,
        "80k": 11,
        "85k": 12,
        "90k": 18,
        "95k": 7,
        "100k": 9,
        "105k": 9,
        "110k": 4,
        "115k": 8,
        "120k": 7,
        "125k": 8,
        "130k": 7,
        "135k": 0,
        "140k": 1,
        "145k": 3,
        "150k": 5,
        "155k": 2,
        "160k": 2,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 1,
        "185k": 2,
        "190k": 1,
        "195k": 1,
        "200k": 0,
        "350k": 15
      },
      nonRelativefm: {
        "5k": 14,
        "10k": 13,
        "15k": 16,
        "20k": 31,
        "25k": 48,
        "30k": 35,
        "35k": 27,
        "40k": 35,
        "45k": 35,
        "50k": 40,
        "55k": 36,
        "60k": 43,
        "65k": 23,
        "70k": 31,
        "75k": 32,
        "80k": 27,
        "85k": 54,
        "90k": 29,
        "95k": 32,
        "100k": 25,
        "105k": 33,
        "110k": 9,
        "115k": 24,
        "120k": 7,
        "125k": 9,
        "130k": 8,
        "135k": 8,
        "140k": 7,
        "145k": 7,
        "150k": 0,
        "155k": 4,
        "160k": 6,
        "165k": 6,
        "170k": 2,
        "175k": 10,
        "180k": 14,
        "185k": 8,
        "190k": 3,
        "195k": 9,
        "200k": 0,
        "350k": 23
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 257,
        "10k": 152,
        "15k": 303,
        "20k": 355,
        "25k": 380,
        "30k": 483,
        "35k": 510,
        "40k": 443,
        "45k": 441,
        "50k": 445,
        "55k": 450,
        "60k": 380,
        "65k": 453,
        "70k": 395,
        "75k": 385,
        "80k": 425,
        "85k": 418,
        "90k": 311,
        "95k": 332,
        "100k": 284,
        "105k": 323,
        "110k": 239,
        "115k": 241,
        "120k": 175,
        "125k": 232,
        "130k": 185,
        "135k": 171,
        "140k": 108,
        "145k": 137,
        "150k": 97,
        "155k": 187,
        "160k": 102,
        "165k": 111,
        "170k": 72,
        "175k": 77,
        "180k": 56,
        "185k": 72,
        "190k": 44,
        "195k": 63,
        "200k": 56,
        "350k": 714
      },
      spousem: {
        "5k": 57,
        "10k": 41,
        "15k": 58,
        "20k": 108,
        "25k": 129,
        "30k": 119,
        "35k": 150,
        "40k": 189,
        "45k": 165,
        "50k": 204,
        "55k": 181,
        "60k": 187,
        "65k": 188,
        "70k": 208,
        "75k": 186,
        "80k": 205,
        "85k": 167,
        "90k": 181,
        "95k": 153,
        "100k": 157,
        "105k": 169,
        "110k": 136,
        "115k": 139,
        "120k": 93,
        "125k": 113,
        "130k": 92,
        "135k": 101,
        "140k": 68,
        "145k": 67,
        "150k": 64,
        "155k": 86,
        "160k": 44,
        "165k": 43,
        "170k": 40,
        "175k": 40,
        "180k": 40,
        "185k": 35,
        "190k": 30,
        "195k": 41,
        "200k": 23,
        "350k": 372
      },
      childm: {
        "5k": 26,
        "10k": 15,
        "15k": 59,
        "20k": 90,
        "25k": 65,
        "30k": 55,
        "35k": 63,
        "40k": 56,
        "45k": 50,
        "50k": 88,
        "55k": 65,
        "60k": 38,
        "65k": 51,
        "70k": 53,
        "75k": 36,
        "80k": 49,
        "85k": 49,
        "90k": 66,
        "95k": 37,
        "100k": 40,
        "105k": 32,
        "110k": 28,
        "115k": 13,
        "120k": 9,
        "125k": 21,
        "130k": 17,
        "135k": 25,
        "140k": 16,
        "145k": 19,
        "150k": 9,
        "155k": 3,
        "160k": 11,
        "165k": 4,
        "170k": 3,
        "175k": 2,
        "180k": 10,
        "185k": 2,
        "190k": 2,
        "195k": 2,
        "200k": 2,
        "350k": 52
      },
      otherRelativem: {
        "5k": 14,
        "10k": 10,
        "15k": 19,
        "20k": 12,
        "25k": 46,
        "30k": 49,
        "35k": 44,
        "40k": 54,
        "45k": 38,
        "50k": 26,
        "55k": 55,
        "60k": 27,
        "65k": 25,
        "70k": 36,
        "75k": 16,
        "80k": 36,
        "85k": 29,
        "90k": 30,
        "95k": 21,
        "100k": 10,
        "105k": 19,
        "110k": 25,
        "115k": 25,
        "120k": 18,
        "125k": 18,
        "130k": 9,
        "135k": 5,
        "140k": 10,
        "145k": 6,
        "150k": 10,
        "155k": 6,
        "160k": 7,
        "165k": 7,
        "170k": 20,
        "175k": 5,
        "180k": 7,
        "185k": 1,
        "190k": 0,
        "195k": 4,
        "200k": 4,
        "350k": 33
      },
      nonRelativem: {
        "5k": 15,
        "10k": 29,
        "15k": 46,
        "20k": 44,
        "25k": 64,
        "30k": 75,
        "35k": 64,
        "40k": 52,
        "45k": 67,
        "50k": 68,
        "55k": 85,
        "60k": 51,
        "65k": 66,
        "70k": 58,
        "75k": 65,
        "80k": 58,
        "85k": 35,
        "90k": 60,
        "95k": 51,
        "100k": 34,
        "105k": 45,
        "110k": 44,
        "115k": 19,
        "120k": 35,
        "125k": 16,
        "130k": 21,
        "135k": 18,
        "140k": 7,
        "145k": 18,
        "150k": 6,
        "155k": 19,
        "160k": 27,
        "165k": 8,
        "170k": 13,
        "175k": 9,
        "180k": 6,
        "185k": 8,
        "190k": 0,
        "195k": 8,
        "200k": 5,
        "350k": 103
      },
      houseLoitererfm: {
        "5k": 412,
        "10k": 310,
        "15k": 443,
        "20k": 494,
        "25k": 528,
        "30k": 441,
        "35k": 515,
        "40k": 516,
        "45k": 456,
        "50k": 448,
        "55k": 408,
        "60k": 426,
        "65k": 385,
        "70k": 414,
        "75k": 339,
        "80k": 301,
        "85k": 266,
        "90k": 257,
        "95k": 222,
        "100k": 200,
        "105k": 235,
        "110k": 177,
        "115k": 203,
        "120k": 128,
        "125k": 160,
        "130k": 139,
        "135k": 121,
        "140k": 83,
        "145k": 100,
        "150k": 81,
        "155k": 99,
        "160k": 68,
        "165k": 51,
        "170k": 56,
        "175k": 52,
        "180k": 45,
        "185k": 47,
        "190k": 30,
        "195k": 49,
        "200k": 33,
        "350k": 535
      },
      spousefm: {
        "5k": 72,
        "10k": 39,
        "15k": 102,
        "20k": 110,
        "25k": 168,
        "30k": 178,
        "35k": 188,
        "40k": 230,
        "45k": 241,
        "50k": 212,
        "55k": 273,
        "60k": 267,
        "65k": 269,
        "70k": 247,
        "75k": 256,
        "80k": 255,
        "85k": 327,
        "90k": 227,
        "95k": 264,
        "100k": 230,
        "105k": 266,
        "110k": 195,
        "115k": 226,
        "120k": 155,
        "125k": 195,
        "130k": 165,
        "135k": 157,
        "140k": 105,
        "145k": 128,
        "150k": 108,
        "155k": 168,
        "160k": 82,
        "165k": 111,
        "170k": 73,
        "175k": 80,
        "180k": 57,
        "185k": 59,
        "190k": 37,
        "195k": 51,
        "200k": 56,
        "350k": 692
      },
      childfm: {
        "5k": 14,
        "10k": 14,
        "15k": 17,
        "20k": 34,
        "25k": 39,
        "30k": 38,
        "35k": 47,
        "40k": 43,
        "45k": 58,
        "50k": 29,
        "55k": 48,
        "60k": 43,
        "65k": 39,
        "70k": 30,
        "75k": 34,
        "80k": 36,
        "85k": 34,
        "90k": 26,
        "95k": 29,
        "100k": 15,
        "105k": 23,
        "110k": 15,
        "115k": 30,
        "120k": 15,
        "125k": 6,
        "130k": 7,
        "135k": 17,
        "140k": 3,
        "145k": 9,
        "150k": 12,
        "155k": 3,
        "160k": 11,
        "165k": 1,
        "170k": 0,
        "175k": 8,
        "180k": 1,
        "185k": 2,
        "190k": 2,
        "195k": 6,
        "200k": 2,
        "350k": 42
      },
      otherRelativefm: {
        "5k": 14,
        "10k": 19,
        "15k": 20,
        "20k": 39,
        "25k": 39,
        "30k": 33,
        "35k": 49,
        "40k": 58,
        "45k": 19,
        "50k": 25,
        "55k": 30,
        "60k": 34,
        "65k": 18,
        "70k": 41,
        "75k": 19,
        "80k": 20,
        "85k": 12,
        "90k": 28,
        "95k": 18,
        "100k": 7,
        "105k": 10,
        "110k": 11,
        "115k": 19,
        "120k": 22,
        "125k": 8,
        "130k": 7,
        "135k": 5,
        "140k": 11,
        "145k": 3,
        "150k": 8,
        "155k": 3,
        "160k": 4,
        "165k": 2,
        "170k": 6,
        "175k": 6,
        "180k": 4,
        "185k": 3,
        "190k": 3,
        "195k": 7,
        "200k": 1,
        "350k": 25
      },
      nonRelativefm: {
        "5k": 14,
        "10k": 11,
        "15k": 14,
        "20k": 39,
        "25k": 35,
        "30k": 52,
        "35k": 48,
        "40k": 38,
        "45k": 50,
        "50k": 64,
        "55k": 58,
        "60k": 35,
        "65k": 57,
        "70k": 44,
        "75k": 20,
        "80k": 40,
        "85k": 45,
        "90k": 42,
        "95k": 34,
        "100k": 23,
        "105k": 40,
        "110k": 9,
        "115k": 27,
        "120k": 10,
        "125k": 25,
        "130k": 22,
        "135k": 14,
        "140k": 13,
        "145k": 9,
        "150k": 15,
        "155k": 11,
        "160k": 10,
        "165k": 7,
        "170k": 1,
        "175k": 8,
        "180k": 8,
        "185k": 8,
        "190k": 2,
        "195k": 9,
        "200k": 2,
        "350k": 37
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 368,
        "10k": 350,
        "15k": 366,
        "20k": 398,
        "25k": 429,
        "30k": 509,
        "35k": 474,
        "40k": 424,
        "45k": 508,
        "50k": 547,
        "55k": 466,
        "60k": 388,
        "65k": 408,
        "70k": 394,
        "75k": 438,
        "80k": 364,
        "85k": 402,
        "90k": 344,
        "95k": 317,
        "100k": 288,
        "105k": 414,
        "110k": 241,
        "115k": 328,
        "120k": 244,
        "125k": 292,
        "130k": 175,
        "135k": 177,
        "140k": 220,
        "145k": 238,
        "150k": 175,
        "155k": 217,
        "160k": 145,
        "165k": 130,
        "170k": 116,
        "175k": 101,
        "180k": 102,
        "185k": 111,
        "190k": 52,
        "195k": 91,
        "200k": 59,
        "350k": "1,052"
      },
      spousem: {
        "5k": 53,
        "10k": 40,
        "15k": 84,
        "20k": 81,
        "25k": 120,
        "30k": 123,
        "35k": 124,
        "40k": 154,
        "45k": 182,
        "50k": 141,
        "55k": 177,
        "60k": 169,
        "65k": 233,
        "70k": 182,
        "75k": 149,
        "80k": 213,
        "85k": 182,
        "90k": 156,
        "95k": 165,
        "100k": 144,
        "105k": 193,
        "110k": 113,
        "115k": 128,
        "120k": 106,
        "125k": 127,
        "130k": 105,
        "135k": 122,
        "140k": 84,
        "145k": 100,
        "150k": 89,
        "155k": 95,
        "160k": 74,
        "165k": 80,
        "170k": 57,
        "175k": 73,
        "180k": 66,
        "185k": 55,
        "190k": 47,
        "195k": 39,
        "200k": 37,
        "350k": "513"
      },
      childm: {
        "5k": 10,
        "10k": 4,
        "15k": 50,
        "20k": 43,
        "25k": 76,
        "30k": 69,
        "35k": 69,
        "40k": 68,
        "45k": 58,
        "50k": 46,
        "55k": 55,
        "60k": 36,
        "65k": 43,
        "70k": 27,
        "75k": 45,
        "80k": 29,
        "85k": 27,
        "90k": 30,
        "95k": 4,
        "100k": 11,
        "105k": 6,
        "110k": 15,
        "115k": 14,
        "120k": 3,
        "125k": 5,
        "130k": 11,
        "135k": 14,
        "140k": 12,
        "145k": 4,
        "150k": 0,
        "155k": 5,
        "160k": 9,
        "165k": 0,
        "170k": 2,
        "175k": 2,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "15"
      },
      otherRelativem: {
        "5k": 24,
        "10k": 28,
        "15k": 25,
        "20k": 46,
        "25k": 35,
        "30k": 31,
        "35k": 65,
        "40k": 56,
        "45k": 66,
        "50k": 36,
        "55k": 48,
        "60k": 36,
        "65k": 50,
        "70k": 38,
        "75k": 31,
        "80k": 33,
        "85k": 40,
        "90k": 48,
        "95k": 17,
        "100k": 21,
        "105k": 29,
        "110k": 11,
        "115k": 16,
        "120k": 27,
        "125k": 15,
        "130k": 11,
        "135k": 15,
        "140k": 9,
        "145k": 4,
        "150k": 16,
        "155k": 19,
        "160k": 5,
        "165k": 9,
        "170k": 9,
        "175k": 3,
        "180k": 5,
        "185k": 4,
        "190k": 4,
        "195k": 4,
        "200k": 12,
        "350k": "44"
      },
      nonRelativem: {
        "5k": 18,
        "10k": 33,
        "15k": 47,
        "20k": 58,
        "25k": 63,
        "30k": 49,
        "35k": 59,
        "40k": 47,
        "45k": 54,
        "50k": 45,
        "55k": 59,
        "60k": 65,
        "65k": 33,
        "70k": 40,
        "75k": 80,
        "80k": 41,
        "85k": 35,
        "90k": 47,
        "95k": 31,
        "100k": 36,
        "105k": 27,
        "110k": 29,
        "115k": 20,
        "120k": 18,
        "125k": 27,
        "130k": 8,
        "135k": 16,
        "140k": 23,
        "145k": 18,
        "150k": 9,
        "155k": 4,
        "160k": 7,
        "165k": 3,
        "170k": 6,
        "175k": 6,
        "180k": 12,
        "185k": 8,
        "190k": 10,
        "195k": 6,
        "200k": 2,
        "350k": "42"
      },
      houseLoitererfm: {
        "5k": 416,
        "10k": 457,
        "15k": 548,
        "20k": 469,
        "25k": 511,
        "30k": 533,
        "35k": 470,
        "40k": 406,
        "45k": 547,
        "50k": 417,
        "55k": 462,
        "60k": 462,
        "65k": 443,
        "70k": 372,
        "75k": 385,
        "80k": 351,
        "85k": 294,
        "90k": 241,
        "95k": 258,
        "100k": 193,
        "105k": 282,
        "110k": 175,
        "115k": 192,
        "120k": 154,
        "125k": 151,
        "130k": 135,
        "135k": 168,
        "140k": 113,
        "145k": 125,
        "150k": 109,
        "155k": 119,
        "160k": 99,
        "165k": 86,
        "170k": 64,
        "175k": 89,
        "180k": 87,
        "185k": 54,
        "190k": 48,
        "195k": 54,
        "200k": 51,
        "350k": "615"
      },
      spousefm: {
        "5k": 106,
        "10k": 47,
        "15k": 90,
        "20k": 125,
        "25k": 168,
        "30k": 153,
        "35k": 195,
        "40k": 205,
        "45k": 244,
        "50k": 259,
        "55k": 284,
        "60k": 237,
        "65k": 281,
        "70k": 315,
        "75k": 281,
        "80k": 289,
        "85k": 298,
        "90k": 278,
        "95k": 274,
        "100k": 251,
        "105k": 328,
        "110k": 193,
        "115k": 287,
        "120k": 207,
        "125k": 222,
        "130k": 139,
        "135k": 158,
        "140k": 190,
        "145k": 171,
        "150k": 150,
        "155k": 168,
        "160k": 126,
        "165k": 119,
        "170k": 94,
        "175k": 101,
        "180k": 80,
        "185k": 100,
        "190k": 63,
        "195k": 74,
        "200k": 67,
        "350k": "950"
      },
      childfm: {
        "5k": 1,
        "10k": 11,
        "15k": 12,
        "20k": 31,
        "25k": 29,
        "30k": 34,
        "35k": 40,
        "40k": 23,
        "45k": 28,
        "50k": 27,
        "55k": 22,
        "60k": 22,
        "65k": 36,
        "70k": 14,
        "75k": 9,
        "80k": 36,
        "85k": 17,
        "90k": 9,
        "95k": 16,
        "100k": 13,
        "105k": 11,
        "110k": 18,
        "115k": 16,
        "120k": 1,
        "125k": 4,
        "130k": 7,
        "135k": 2,
        "140k": 9,
        "145k": 6,
        "150k": 6,
        "155k": 5,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 1,
        "190k": 1,
        "195k": 0,
        "200k": 1,
        "350k": "16"
      },
      otherRelativefm: {
        "5k": 24,
        "10k": 22,
        "15k": 42,
        "20k": 46,
        "25k": 48,
        "30k": 59,
        "35k": 59,
        "40k": 66,
        "45k": 57,
        "50k": 53,
        "55k": 65,
        "60k": 58,
        "65k": 56,
        "70k": 43,
        "75k": 38,
        "80k": 32,
        "85k": 38,
        "90k": 27,
        "95k": 25,
        "100k": 22,
        "105k": 31,
        "110k": 16,
        "115k": 32,
        "120k": 14,
        "125k": 32,
        "130k": 19,
        "135k": 14,
        "140k": 8,
        "145k": 6,
        "150k": 10,
        "155k": 17,
        "160k": 12,
        "165k": 10,
        "170k": 9,
        "175k": 8,
        "180k": 2,
        "185k": 5,
        "190k": 4,
        "195k": 4,
        "200k": 6,
        "350k": "37"
      },
      nonRelativefm: {
        "5k": 27,
        "10k": 16,
        "15k": 26,
        "20k": 34,
        "25k": 35,
        "30k": 37,
        "35k": 60,
        "40k": 38,
        "45k": 43,
        "50k": 30,
        "55k": 35,
        "60k": 49,
        "65k": 45,
        "70k": 35,
        "75k": 43,
        "80k": 33,
        "85k": 23,
        "90k": 21,
        "95k": 26,
        "100k": 11,
        "105k": 23,
        "110k": 18,
        "115k": 9,
        "120k": 8,
        "125k": 3,
        "130k": 15,
        "135k": 6,
        "140k": 13,
        "145k": 23,
        "150k": 21,
        "155k": 5,
        "160k": 2,
        "165k": 6,
        "170k": 15,
        "175k": 4,
        "180k": 7,
        "185k": 0,
        "190k": 1,
        "195k": 5,
        "200k": 1,
        "350k": "62"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 354,
        "10k": 456,
        "15k": 490,
        "20k": 496,
        "25k": 462,
        "30k": 394,
        "35k": 445,
        "40k": 458,
        "45k": 510,
        "50k": 439,
        "55k": 523,
        "60k": 407,
        "65k": 427,
        "70k": 465,
        "75k": 445,
        "80k": 335,
        "85k": 370,
        "90k": 322,
        "95k": 314,
        "100k": 277,
        "105k": 319,
        "110k": 221,
        "115k": 247,
        "120k": 168,
        "125k": 161,
        "130k": 183,
        "135k": 141,
        "140k": 161,
        "145k": 104,
        "150k": 136,
        "155k": 168,
        "160k": 118,
        "165k": 102,
        "170k": 76,
        "175k": 84,
        "180k": 83,
        "185k": 63,
        "190k": 58,
        "195k": 44,
        "200k": 64,
        "350k": 878
      },
      spousem: {
        "5k": 75,
        "10k": 55,
        "15k": 71,
        "20k": 113,
        "25k": 137,
        "30k": 132,
        "35k": 142,
        "40k": 163,
        "45k": 153,
        "50k": 185,
        "55k": 181,
        "60k": 163,
        "65k": 195,
        "70k": 165,
        "75k": 176,
        "80k": 164,
        "85k": 160,
        "90k": 130,
        "95k": 129,
        "100k": 113,
        "105k": 180,
        "110k": 106,
        "115k": 118,
        "120k": 87,
        "125k": 96,
        "130k": 94,
        "135k": 98,
        "140k": 57,
        "145k": 88,
        "150k": 66,
        "155k": 89,
        "160k": 73,
        "165k": 45,
        "170k": 39,
        "175k": 34,
        "180k": 45,
        "185k": 20,
        "190k": 27,
        "195k": 27,
        "200k": 36,
        "350k": 472
      },
      childm: {
        "5k": 7,
        "10k": 9,
        "15k": 6,
        "20k": 22,
        "25k": 22,
        "30k": 23,
        "35k": 8,
        "40k": 15,
        "45k": 8,
        "50k": 2,
        "55k": 8,
        "60k": 4,
        "65k": 5,
        "70k": 16,
        "75k": 7,
        "80k": 14,
        "85k": 10,
        "90k": 2,
        "95k": 6,
        "100k": 3,
        "105k": 0,
        "110k": 0,
        "115k": 11,
        "120k": 5,
        "125k": 2,
        "130k": 3,
        "135k": 0,
        "140k": 4,
        "145k": 0,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      otherRelativem: {
        "5k": 11,
        "10k": 10,
        "15k": 20,
        "20k": 26,
        "25k": 23,
        "30k": 14,
        "35k": 29,
        "40k": 48,
        "45k": 27,
        "50k": 26,
        "55k": 32,
        "60k": 31,
        "65k": 33,
        "70k": 35,
        "75k": 23,
        "80k": 32,
        "85k": 25,
        "90k": 26,
        "95k": 8,
        "100k": 15,
        "105k": 25,
        "110k": 18,
        "115k": 14,
        "120k": 15,
        "125k": 9,
        "130k": 15,
        "135k": 2,
        "140k": 10,
        "145k": 15,
        "150k": 7,
        "155k": 5,
        "160k": 5,
        "165k": 13,
        "170k": 8,
        "175k": 3,
        "180k": 0,
        "185k": 9,
        "190k": 1,
        "195k": 10,
        "200k": 0,
        "350k": 53
      },
      nonRelativem: {
        "5k": 5,
        "10k": 17,
        "15k": 12,
        "20k": 36,
        "25k": 27,
        "30k": 31,
        "35k": 37,
        "40k": 25,
        "45k": 30,
        "50k": 36,
        "55k": 19,
        "60k": 19,
        "65k": 36,
        "70k": 26,
        "75k": 36,
        "80k": 33,
        "85k": 23,
        "90k": 16,
        "95k": 18,
        "100k": 12,
        "105k": 16,
        "110k": 26,
        "115k": 9,
        "120k": 6,
        "125k": 6,
        "130k": 23,
        "135k": 6,
        "140k": 11,
        "145k": 6,
        "150k": 4,
        "155k": 8,
        "160k": 5,
        "165k": 3,
        "170k": 4,
        "175k": 1,
        "180k": 10,
        "185k": 0,
        "190k": 3,
        "195k": 5,
        "200k": 3,
        "350k": 37
      },
      houseLoitererfm: {
        "5k": 406,
        "10k": 588,
        "15k": 650,
        "20k": 525,
        "25k": 636,
        "30k": 496,
        "35k": 580,
        "40k": 516,
        "45k": 487,
        "50k": 485,
        "55k": 400,
        "60k": 390,
        "65k": 428,
        "70k": 321,
        "75k": 317,
        "80k": 311,
        "85k": 320,
        "90k": 202,
        "95k": 269,
        "100k": 193,
        "105k": 226,
        "110k": 151,
        "115k": 160,
        "120k": 106,
        "125k": 125,
        "130k": 115,
        "135k": 114,
        "140k": 105,
        "145k": 113,
        "150k": 80,
        "155k": 84,
        "160k": 52,
        "165k": 49,
        "170k": 50,
        "175k": 64,
        "180k": 54,
        "185k": 31,
        "190k": 31,
        "195k": 34,
        "200k": 34,
        "350k": 537
      },
      spousefm: {
        "5k": 101,
        "10k": 93,
        "15k": 120,
        "20k": 166,
        "25k": 188,
        "30k": 248,
        "35k": 272,
        "40k": 250,
        "45k": 270,
        "50k": 294,
        "55k": 282,
        "60k": 230,
        "65k": 291,
        "70k": 312,
        "75k": 310,
        "80k": 255,
        "85k": 258,
        "90k": 245,
        "95k": 217,
        "100k": 230,
        "105k": 227,
        "110k": 165,
        "115k": 182,
        "120k": 129,
        "125k": 128,
        "130k": 144,
        "135k": 90,
        "140k": 122,
        "145k": 105,
        "150k": 96,
        "155k": 124,
        "160k": 98,
        "165k": 71,
        "170k": 78,
        "175k": 60,
        "180k": 40,
        "185k": 62,
        "190k": 39,
        "195k": 37,
        "200k": 38,
        "350k": 672
      },
      childfm: {
        "5k": 6,
        "10k": 0,
        "15k": 19,
        "20k": 18,
        "25k": 13,
        "30k": 19,
        "35k": 15,
        "40k": 8,
        "45k": 29,
        "50k": 12,
        "55k": 10,
        "60k": 14,
        "65k": 2,
        "70k": 7,
        "75k": 3,
        "80k": 4,
        "85k": 11,
        "90k": 10,
        "95k": 5,
        "100k": 5,
        "105k": 2,
        "110k": 5,
        "115k": 0,
        "120k": 11,
        "125k": 1,
        "130k": 0,
        "135k": 3,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      otherRelativefm: {
        "5k": 22,
        "10k": 18,
        "15k": 23,
        "20k": 43,
        "25k": 83,
        "30k": 59,
        "35k": 48,
        "40k": 33,
        "45k": 61,
        "50k": 28,
        "55k": 63,
        "60k": 50,
        "65k": 54,
        "70k": 44,
        "75k": 43,
        "80k": 41,
        "85k": 36,
        "90k": 28,
        "95k": 40,
        "100k": 34,
        "105k": 25,
        "110k": 34,
        "115k": 24,
        "120k": 16,
        "125k": 23,
        "130k": 27,
        "135k": 15,
        "140k": 10,
        "145k": 18,
        "150k": 17,
        "155k": 8,
        "160k": 15,
        "165k": 18,
        "170k": 2,
        "175k": 8,
        "180k": 0,
        "185k": 2,
        "190k": 4,
        "195k": 7,
        "200k": 1,
        "350k": 67
      },
      nonRelativefm: {
        "5k": 4,
        "10k": 13,
        "15k": 15,
        "20k": 21,
        "25k": 42,
        "30k": 36,
        "35k": 31,
        "40k": 21,
        "45k": 37,
        "50k": 16,
        "55k": 19,
        "60k": 21,
        "65k": 13,
        "70k": 30,
        "75k": 5,
        "80k": 11,
        "85k": 15,
        "90k": 11,
        "95k": 20,
        "100k": 6,
        "105k": 7,
        "110k": 10,
        "115k": 11,
        "120k": 7,
        "125k": 9,
        "130k": 4,
        "135k": 2,
        "140k": 4,
        "145k": 13,
        "150k": 2,
        "155k": 5,
        "160k": 8,
        "165k": 5,
        "170k": 6,
        "175k": 8,
        "180k": 11,
        "185k": 3,
        "190k": 3,
        "195k": 3,
        "200k": 0,
        "350k": 48
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 136,
        "10k": 204,
        "15k": 367,
        "20k": 460,
        "25k": 466,
        "30k": 550,
        "35k": 481,
        "40k": 414,
        "45k": 412,
        "50k": 389,
        "55k": 366,
        "60k": 334,
        "65k": 304,
        "70k": 308,
        "75k": 218,
        "80k": 254,
        "85k": 209,
        "90k": 172,
        "95k": 160,
        "100k": 117,
        "105k": 160,
        "110k": 141,
        "115k": 119,
        "120k": 101,
        "125k": 83,
        "130k": 113,
        "135k": 77,
        "140k": 76,
        "145k": 70,
        "150k": 40,
        "155k": 47,
        "160k": 44,
        "165k": 35,
        "170k": 41,
        "175k": 30,
        "180k": 20,
        "185k": 36,
        "190k": 40,
        "195k": 20,
        "200k": 24,
        "350k": 362
      },
      spousem: {
        "5k": 36,
        "10k": 35,
        "15k": 34,
        "20k": 88,
        "25k": 145,
        "30k": 152,
        "35k": 200,
        "40k": 184,
        "45k": 169,
        "50k": 184,
        "55k": 113,
        "60k": 108,
        "65k": 130,
        "70k": 106,
        "75k": 129,
        "80k": 87,
        "85k": 108,
        "90k": 82,
        "95k": 74,
        "100k": 63,
        "105k": 41,
        "110k": 47,
        "115k": 60,
        "120k": 39,
        "125k": 32,
        "130k": 25,
        "135k": 30,
        "140k": 40,
        "145k": 24,
        "150k": 32,
        "155k": 13,
        "160k": 22,
        "165k": 18,
        "170k": 13,
        "175k": 29,
        "180k": 12,
        "185k": 15,
        "190k": 14,
        "195k": 20,
        "200k": 6,
        "350k": 163
      },
      childm: {
        "5k": 0,
        "10k": 3,
        "15k": 0,
        "20k": 0,
        "25k": 6,
        "30k": 15,
        "35k": 0,
        "40k": 7,
        "45k": 0,
        "50k": 3,
        "55k": 2,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 2,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 3,
        "10k": 7,
        "15k": 11,
        "20k": 15,
        "25k": 16,
        "30k": 20,
        "35k": 37,
        "40k": 16,
        "45k": 13,
        "50k": 17,
        "55k": 6,
        "60k": 12,
        "65k": 12,
        "70k": 20,
        "75k": 14,
        "80k": 14,
        "85k": 12,
        "90k": 16,
        "95k": 15,
        "100k": 7,
        "105k": 11,
        "110k": 6,
        "115k": 8,
        "120k": 5,
        "125k": 9,
        "130k": 10,
        "135k": 5,
        "140k": 4,
        "145k": 8,
        "150k": 6,
        "155k": 7,
        "160k": 4,
        "165k": 7,
        "170k": 7,
        "175k": 3,
        "180k": 2,
        "185k": 3,
        "190k": 0,
        "195k": 1,
        "200k": 5,
        "350k": 29
      },
      nonRelativem: {
        "5k": 1,
        "10k": 1,
        "15k": 4,
        "20k": 6,
        "25k": 7,
        "30k": 7,
        "35k": 20,
        "40k": 11,
        "45k": 9,
        "50k": 11,
        "55k": 10,
        "60k": 13,
        "65k": 6,
        "70k": 10,
        "75k": 13,
        "80k": 11,
        "85k": 2,
        "90k": 3,
        "95k": 2,
        "100k": 2,
        "105k": 2,
        "110k": 11,
        "115k": 3,
        "120k": 4,
        "125k": 0,
        "130k": 0,
        "135k": 8,
        "140k": 4,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 5,
        "165k": 1,
        "170k": 4,
        "175k": 1,
        "180k": 5,
        "185k": 6,
        "190k": 5,
        "195k": 2,
        "200k": 0,
        "350k": 9
      },
      houseLoitererfm: {
        "5k": 186,
        "10k": 368,
        "15k": 722,
        "20k": 743,
        "25k": 625,
        "30k": 567,
        "35k": 519,
        "40k": 452,
        "45k": 377,
        "50k": 363,
        "55k": 280,
        "60k": 191,
        "65k": 261,
        "70k": 206,
        "75k": 199,
        "80k": 146,
        "85k": 130,
        "90k": 94,
        "95k": 86,
        "100k": 77,
        "105k": 70,
        "110k": 47,
        "115k": 58,
        "120k": 45,
        "125k": 43,
        "130k": 48,
        "135k": 43,
        "140k": 28,
        "145k": 39,
        "150k": 32,
        "155k": 31,
        "160k": 27,
        "165k": 23,
        "170k": 21,
        "175k": 17,
        "180k": 7,
        "185k": 17,
        "190k": 13,
        "195k": 20,
        "200k": 9,
        "350k": 118
      },
      spousefm: {
        "5k": 47,
        "10k": 37,
        "15k": 98,
        "20k": 154,
        "25k": 229,
        "30k": 331,
        "35k": 305,
        "40k": 251,
        "45k": 248,
        "50k": 236,
        "55k": 248,
        "60k": 226,
        "65k": 172,
        "70k": 187,
        "75k": 183,
        "80k": 145,
        "85k": 137,
        "90k": 127,
        "95k": 91,
        "100k": 67,
        "105k": 104,
        "110k": 87,
        "115k": 93,
        "120k": 80,
        "125k": 53,
        "130k": 63,
        "135k": 47,
        "140k": 74,
        "145k": 20,
        "150k": 30,
        "155k": 30,
        "160k": 23,
        "165k": 27,
        "170k": 16,
        "175k": 18,
        "180k": 22,
        "185k": 17,
        "190k": 20,
        "195k": 14,
        "200k": 16,
        "350k": 203
      },
      childfm: {
        "5k": 0,
        "10k": 0,
        "15k": 1,
        "20k": 6,
        "25k": 2,
        "30k": 2,
        "35k": 3,
        "40k": 3,
        "45k": 5,
        "50k": 5,
        "55k": 0,
        "60k": 6,
        "65k": 0,
        "70k": 1,
        "75k": 4,
        "80k": 0,
        "85k": 3,
        "90k": 0,
        "95k": 0,
        "100k": 2,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 19,
        "15k": 27,
        "20k": 20,
        "25k": 26,
        "30k": 49,
        "35k": 51,
        "40k": 39,
        "45k": 41,
        "50k": 51,
        "55k": 39,
        "60k": 34,
        "65k": 45,
        "70k": 39,
        "75k": 39,
        "80k": 22,
        "85k": 32,
        "90k": 43,
        "95k": 23,
        "100k": 8,
        "105k": 35,
        "110k": 14,
        "115k": 11,
        "120k": 23,
        "125k": 20,
        "130k": 16,
        "135k": 10,
        "140k": 12,
        "145k": 5,
        "150k": 11,
        "155k": 10,
        "160k": 4,
        "165k": 6,
        "170k": 8,
        "175k": 1,
        "180k": 7,
        "185k": 5,
        "190k": 5,
        "195k": 3,
        "200k": 4,
        "350k": 52
      },
      nonRelativefm: {
        "5k": 0,
        "10k": 4,
        "15k": 4,
        "20k": 3,
        "25k": 17,
        "30k": 18,
        "35k": 15,
        "40k": 6,
        "45k": 7,
        "50k": 3,
        "55k": 8,
        "60k": 6,
        "65k": 17,
        "70k": 14,
        "75k": 8,
        "80k": 3,
        "85k": 7,
        "90k": 3,
        "95k": 5,
        "100k": 7,
        "105k": 5,
        "110k": 4,
        "115k": 2,
        "120k": 4,
        "125k": 1,
        "130k": 5,
        "135k": 2,
        "140k": 9,
        "145k": 4,
        "150k": 0,
        "155k": 0,
        "160k": 1,
        "165k": 1,
        "170k": 2,
        "175k": 1,
        "180k": 2,
        "185k": 0,
        "190k": 3,
        "195k": 0,
        "200k": 0,
        "350k": 11
      }
    }
  },
  "2013": {
    "17": {
      houseLoitererm: {
        "5k": 9,
        "10k": 0,
        "15k": 5,
        "20k": 0,
        "25k": 18,
        "30k": 23,
        "35k": 0,
        "40k": 0,
        "45k": 9,
        "50k": 6,
        "55k": 0,
        "60k": 13,
        "65k": 0,
        "70k": 2,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 18,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 4,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 345,
        "10k": 257,
        "15k": 358,
        "20k": 375,
        "25k": 516,
        "30k": 446,
        "35k": 529,
        "40k": 374,
        "45k": 437,
        "50k": 473,
        "55k": 322,
        "60k": 298,
        "65k": 580,
        "70k": 366,
        "75k": 462,
        "80k": 407,
        "85k": 301,
        "90k": 269,
        "95k": 221,
        "100k": 312,
        "105k": 292,
        "110k": 185,
        "115k": 273,
        "120k": 188,
        "125k": 233,
        "130k": 192,
        "135k": 203,
        "140k": 161,
        "145k": 116,
        "150k": 150,
        "155k": 124,
        "160k": 108,
        "165k": 108,
        "170k": 87,
        "175k": 80,
        "180k": 108,
        "185k": 76,
        "190k": 54,
        "195k": 48,
        "200k": 39,
        "350k": 952
      },
      otherRelativem: {
        "5k": 27,
        "10k": 25,
        "15k": 93,
        "20k": 53,
        "25k": 58,
        "30k": 112,
        "35k": 75,
        "40k": 66,
        "45k": 105,
        "50k": 27,
        "55k": 77,
        "60k": 13,
        "65k": 43,
        "70k": 15,
        "75k": 25,
        "80k": 8,
        "85k": 31,
        "90k": 22,
        "95k": 48,
        "100k": 6,
        "105k": 10,
        "110k": 4,
        "115k": 13,
        "120k": 11,
        "125k": 32,
        "130k": 0,
        "135k": 5,
        "140k": 0,
        "145k": 19,
        "150k": 41,
        "155k": 5,
        "160k": 1,
        "165k": 11,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 10
      },
      nonRelativem: {
        "5k": 12,
        "10k": 10,
        "15k": 6,
        "20k": 5,
        "25k": 8,
        "30k": 7,
        "35k": 15,
        "40k": 4,
        "45k": 24,
        "50k": 9,
        "55k": 2,
        "60k": 15,
        "65k": 2,
        "70k": 0,
        "75k": 6,
        "80k": 20,
        "85k": 0,
        "90k": 6,
        "95k": 0,
        "100k": 10,
        "105k": 23,
        "110k": 0,
        "115k": 5,
        "120k": 13,
        "125k": 1,
        "130k": 10,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 4,
        "180k": 0,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 25
      },
      houseLoitererfm: {
        "5k": 0,
        "10k": 5,
        "15k": 0,
        "20k": 9,
        "25k": 8,
        "30k": 0,
        "35k": 15,
        "40k": 5,
        "45k": 1,
        "50k": 0,
        "55k": 2,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 8,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 300,
        "10k": 306,
        "15k": 300,
        "20k": 411,
        "25k": 420,
        "30k": 425,
        "35k": 510,
        "40k": 476,
        "45k": 430,
        "50k": 390,
        "55k": 481,
        "60k": 316,
        "65k": 558,
        "70k": 315,
        "75k": 301,
        "80k": 406,
        "85k": 295,
        "90k": 275,
        "95k": 205,
        "100k": 244,
        "105k": 329,
        "110k": 197,
        "115k": 196,
        "120k": 240,
        "125k": 159,
        "130k": 133,
        "135k": 175,
        "140k": 131,
        "145k": 158,
        "150k": 97,
        "155k": 156,
        "160k": 124,
        "165k": 116,
        "170k": 87,
        "175k": 76,
        "180k": 99,
        "185k": 120,
        "190k": 73,
        "195k": 23,
        "200k": 46,
        "350k": 976
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 13,
        "15k": 21,
        "20k": 78,
        "25k": 77,
        "30k": 85,
        "35k": 79,
        "40k": 28,
        "45k": 65,
        "50k": 39,
        "55k": 38,
        "60k": 21,
        "65k": 62,
        "70k": 19,
        "75k": 22,
        "80k": 11,
        "85k": 50,
        "90k": 9,
        "95k": 46,
        "100k": 5,
        "105k": 5,
        "110k": 11,
        "115k": 41,
        "120k": 8,
        "125k": 14,
        "130k": 7,
        "135k": 23,
        "140k": 4,
        "145k": 6,
        "150k": 7,
        "155k": 11,
        "160k": 2,
        "165k": 12,
        "170k": 1,
        "175k": 10,
        "180k": 7,
        "185k": 8,
        "190k": 8,
        "195k": 0,
        "200k": 0,
        "350k": 17
      },
      nonRelativefm: {
        "5k": 0,
        "10k": 2,
        "15k": 17,
        "20k": 27,
        "25k": 4,
        "30k": 0,
        "35k": 3,
        "40k": 18,
        "45k": 3,
        "50k": 18,
        "55k": 13,
        "60k": 0,
        "65k": 2,
        "70k": 12,
        "75k": 0,
        "80k": 29,
        "85k": 0,
        "90k": 5,
        "95k": 6,
        "100k": 0,
        "105k": 1,
        "110k": 0,
        "115k": 0,
        "120k": 9,
        "125k": 1,
        "130k": 1,
        "135k": 6,
        "140k": 9,
        "145k": 2,
        "150k": 0,
        "155k": 5,
        "160k": 0,
        "165k": 0,
        "170k": 8,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 17
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 46,
        "10k": 49,
        "15k": 80,
        "20k": 63,
        "25k": 90,
        "30k": 37,
        "35k": 88,
        "40k": 79,
        "45k": 111,
        "50k": 60,
        "55k": 28,
        "60k": 43,
        "65k": 28,
        "70k": 20,
        "75k": 6,
        "80k": 8,
        "85k": 11,
        "90k": 11,
        "95k": 10,
        "100k": 17,
        "105k": 4,
        "110k": 5,
        "115k": 11,
        "120k": 0,
        "125k": 10,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 5,
        "150k": 9,
        "155k": 0,
        "160k": 5,
        "165k": 6,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 13
      },
      spousem: {
        "5k": 3,
        "10k": 0,
        "15k": 15,
        "20k": 0,
        "25k": 16,
        "30k": 7,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 5,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 111,
        "10k": 96,
        "15k": 165,
        "20k": 139,
        "25k": 155,
        "30k": 198,
        "35k": 199,
        "40k": 245,
        "45k": 247,
        "50k": 170,
        "55k": 207,
        "60k": 166,
        "65k": 266,
        "70k": 147,
        "75k": 239,
        "80k": 195,
        "85k": 146,
        "90k": 147,
        "95k": 195,
        "100k": 168,
        "105k": 163,
        "110k": 163,
        "115k": 173,
        "120k": 143,
        "125k": 116,
        "130k": 109,
        "135k": 50,
        "140k": 28,
        "145k": 102,
        "150k": 106,
        "155k": 117,
        "160k": 67,
        "165k": 87,
        "170k": 45,
        "175k": 60,
        "180k": 42,
        "185k": 56,
        "190k": 47,
        "195k": 22,
        "200k": 25,
        "350k": 598
      },
      otherRelativem: {
        "5k": 30,
        "10k": 9,
        "15k": 27,
        "20k": 72,
        "25k": 25,
        "30k": 56,
        "35k": 78,
        "40k": 85,
        "45k": 20,
        "50k": 47,
        "55k": 35,
        "60k": 17,
        "65k": 55,
        "70k": 10,
        "75k": 5,
        "80k": 14,
        "85k": 49,
        "90k": 24,
        "95k": 20,
        "100k": 9,
        "105k": 10,
        "110k": 22,
        "115k": 34,
        "120k": 18,
        "125k": 8,
        "130k": 12,
        "135k": 12,
        "140k": 9,
        "145k": 21,
        "150k": 26,
        "155k": 14,
        "160k": 4,
        "165k": 0,
        "170k": 4,
        "175k": 10,
        "180k": 8,
        "185k": 8,
        "190k": 0,
        "195k": 4,
        "200k": 0,
        "350k": 25
      },
      nonRelativem: {
        "5k": 17,
        "10k": 19,
        "15k": 33,
        "20k": 14,
        "25k": 65,
        "30k": 28,
        "35k": 63,
        "40k": 4,
        "45k": 37,
        "50k": 20,
        "55k": 27,
        "60k": 28,
        "65k": 4,
        "70k": 19,
        "75k": 12,
        "80k": 15,
        "85k": 20,
        "90k": 3,
        "95k": 1,
        "100k": 8,
        "105k": 20,
        "110k": 8,
        "115k": 10,
        "120k": 0,
        "125k": 22,
        "130k": 2,
        "135k": 0,
        "140k": 5,
        "145k": 4,
        "150k": 19,
        "155k": 4,
        "160k": 17,
        "165k": 0,
        "170k": 1,
        "175k": 7,
        "180k": 10,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 61
      },
      houseLoitererfm: {
        "5k": 188,
        "10k": 105,
        "15k": 142,
        "20k": 36,
        "25k": 154,
        "30k": 103,
        "35k": 59,
        "40k": 26,
        "45k": 67,
        "50k": 43,
        "55k": 68,
        "60k": 13,
        "65k": 44,
        "70k": 39,
        "75k": 20,
        "80k": 1,
        "85k": 13,
        "90k": 3,
        "95k": 3,
        "100k": 0,
        "105k": 5,
        "110k": 4,
        "115k": 3,
        "120k": 8,
        "125k": 0,
        "130k": 3,
        "135k": 0,
        "140k": 0,
        "145k": 5,
        "150k": 0,
        "155k": 5,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 13,
        "20k": 0,
        "25k": 0,
        "30k": 5,
        "35k": 9,
        "40k": 13,
        "45k": 24,
        "50k": 16,
        "55k": 0,
        "60k": 17,
        "65k": 20,
        "70k": 23,
        "75k": 15,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 1,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 13,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      childfm: {
        "5k": 102,
        "10k": 82,
        "15k": 95,
        "20k": 161,
        "25k": 161,
        "30k": 171,
        "35k": 145,
        "40k": 189,
        "45k": 205,
        "50k": 210,
        "55k": 155,
        "60k": 174,
        "65k": 197,
        "70k": 138,
        "75k": 131,
        "80k": 187,
        "85k": 180,
        "90k": 160,
        "95k": 144,
        "100k": 143,
        "105k": 216,
        "110k": 101,
        "115k": 98,
        "120k": 193,
        "125k": 77,
        "130k": 47,
        "135k": 74,
        "140k": 58,
        "145k": 107,
        "150k": 82,
        "155k": 154,
        "160k": 70,
        "165k": 41,
        "170k": 100,
        "175k": 61,
        "180k": 19,
        "185k": 53,
        "190k": 38,
        "195k": 28,
        "200k": 35,
        "350k": 538
      },
      otherRelativefm: {
        "5k": 7,
        "10k": 8,
        "15k": 53,
        "20k": 16,
        "25k": 58,
        "30k": 20,
        "35k": 85,
        "40k": 71,
        "45k": 47,
        "50k": 40,
        "55k": 22,
        "60k": 25,
        "65k": 29,
        "70k": 22,
        "75k": 7,
        "80k": 26,
        "85k": 25,
        "90k": 45,
        "95k": 18,
        "100k": 12,
        "105k": 26,
        "110k": 6,
        "115k": 9,
        "120k": 0,
        "125k": 20,
        "130k": 16,
        "135k": 20,
        "140k": 0,
        "145k": 2,
        "150k": 5,
        "155k": 0,
        "160k": 0,
        "165k": 14,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 23
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 17,
        "15k": 30,
        "20k": 18,
        "25k": 57,
        "30k": 70,
        "35k": 87,
        "40k": 55,
        "45k": 34,
        "50k": 39,
        "55k": 28,
        "60k": 44,
        "65k": 12,
        "70k": 32,
        "75k": 3,
        "80k": 25,
        "85k": 21,
        "90k": 11,
        "95k": 13,
        "100k": 15,
        "105k": 25,
        "110k": 17,
        "115k": 26,
        "120k": 0,
        "125k": 0,
        "130k": 8,
        "135k": 9,
        "140k": 7,
        "145k": 16,
        "150k": 7,
        "155k": 8,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 6,
        "180k": 0,
        "185k": 0,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 16
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 122,
        "10k": 64,
        "15k": 145,
        "20k": 112,
        "25k": 189,
        "30k": 137,
        "35k": 106,
        "40k": 119,
        "45k": 136,
        "50k": 116,
        "55k": 71,
        "60k": 53,
        "65k": 91,
        "70k": 79,
        "75k": 48,
        "80k": 57,
        "85k": 40,
        "90k": 43,
        "95k": 11,
        "100k": 39,
        "105k": 21,
        "110k": 21,
        "115k": 33,
        "120k": 1,
        "125k": 11,
        "130k": 15,
        "135k": 14,
        "140k": 4,
        "145k": 6,
        "150k": 0,
        "155k": 4,
        "160k": 0,
        "165k": 10,
        "170k": 1,
        "175k": 13,
        "180k": 0,
        "185k": 14,
        "190k": 0,
        "195k": 5,
        "200k": 0,
        "350k": 49
      },
      spousem: {
        "5k": 22,
        "10k": 5,
        "15k": 4,
        "20k": 0,
        "25k": 47,
        "30k": 15,
        "35k": 1,
        "40k": 29,
        "45k": 26,
        "50k": 14,
        "55k": 10,
        "60k": 0,
        "65k": 10,
        "70k": 29,
        "75k": 11,
        "80k": 10,
        "85k": 0,
        "90k": 0,
        "95k": 13,
        "100k": 0,
        "105k": 10,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 1,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 41,
        "10k": 29,
        "15k": 94,
        "20k": 72,
        "25k": 68,
        "30k": 83,
        "35k": 141,
        "40k": 113,
        "45k": 90,
        "50k": 94,
        "55k": 66,
        "60k": 136,
        "65k": 57,
        "70k": 164,
        "75k": 56,
        "80k": 88,
        "85k": 101,
        "90k": 40,
        "95k": 61,
        "100k": 77,
        "105k": 105,
        "110k": 87,
        "115k": 93,
        "120k": 37,
        "125k": 57,
        "130k": 32,
        "135k": 59,
        "140k": 58,
        "145k": 29,
        "150k": 31,
        "155k": 89,
        "160k": 64,
        "165k": 41,
        "170k": 30,
        "175k": 55,
        "180k": 68,
        "185k": 36,
        "190k": 20,
        "195k": 48,
        "200k": 23,
        "350k": 383
      },
      otherRelativem: {
        "5k": 10,
        "10k": 9,
        "15k": 9,
        "20k": 0,
        "25k": 29,
        "30k": 39,
        "35k": 47,
        "40k": 35,
        "45k": 21,
        "50k": 16,
        "55k": 57,
        "60k": 51,
        "65k": 34,
        "70k": 14,
        "75k": 18,
        "80k": 1,
        "85k": 23,
        "90k": 18,
        "95k": 3,
        "100k": 29,
        "105k": 0,
        "110k": 34,
        "115k": 15,
        "120k": 0,
        "125k": 43,
        "130k": 0,
        "135k": 5,
        "140k": 0,
        "145k": 16,
        "150k": 20,
        "155k": 17,
        "160k": 1,
        "165k": 6,
        "170k": 9,
        "175k": 0,
        "180k": 7,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 11
      },
      nonRelativem: {
        "5k": 14,
        "10k": 8,
        "15k": 49,
        "20k": 21,
        "25k": 26,
        "30k": 33,
        "35k": 16,
        "40k": 32,
        "45k": 40,
        "50k": 81,
        "55k": 17,
        "60k": 31,
        "65k": 66,
        "70k": 38,
        "75k": 24,
        "80k": 20,
        "85k": 9,
        "90k": 2,
        "95k": 42,
        "100k": 45,
        "105k": 16,
        "110k": 28,
        "115k": 27,
        "120k": 10,
        "125k": 0,
        "130k": 2,
        "135k": 8,
        "140k": 6,
        "145k": 3,
        "150k": 15,
        "155k": 0,
        "160k": 17,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 6,
        "185k": 10,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 82
      },
      houseLoitererfm: {
        "5k": 182,
        "10k": 95,
        "15k": 171,
        "20k": 162,
        "25k": 278,
        "30k": 153,
        "35k": 155,
        "40k": 134,
        "45k": 95,
        "50k": 86,
        "55k": 119,
        "60k": 91,
        "65k": 92,
        "70k": 112,
        "75k": 17,
        "80k": 45,
        "85k": 46,
        "90k": 36,
        "95k": 18,
        "100k": 39,
        "105k": 30,
        "110k": 49,
        "115k": 29,
        "120k": 4,
        "125k": 29,
        "130k": 18,
        "135k": 6,
        "140k": 16,
        "145k": 25,
        "150k": 10,
        "155k": 10,
        "160k": 0,
        "165k": 15,
        "170k": 1,
        "175k": 0,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 1,
        "200k": 2,
        "350k": 1
      },
      spousefm: {
        "5k": 10,
        "10k": 4,
        "15k": 13,
        "20k": 33,
        "25k": 38,
        "30k": 43,
        "35k": 50,
        "40k": 32,
        "45k": 56,
        "50k": 51,
        "55k": 21,
        "60k": 33,
        "65k": 7,
        "70k": 9,
        "75k": 53,
        "80k": 16,
        "85k": 12,
        "90k": 0,
        "95k": 13,
        "100k": 9,
        "105k": 9,
        "110k": 8,
        "115k": 0,
        "120k": 0,
        "125k": 11,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 5,
        "160k": 5,
        "165k": 0,
        "170k": 4,
        "175k": 0,
        "180k": 0,
        "185k": 14,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 5
      },
      childfm: {
        "5k": 36,
        "10k": 56,
        "15k": 31,
        "20k": 47,
        "25k": 82,
        "30k": 32,
        "35k": 90,
        "40k": 39,
        "45k": 60,
        "50k": 92,
        "55k": 78,
        "60k": 92,
        "65k": 82,
        "70k": 86,
        "75k": 115,
        "80k": 84,
        "85k": 92,
        "90k": 60,
        "95k": 33,
        "100k": 118,
        "105k": 108,
        "110k": 84,
        "115k": 57,
        "120k": 91,
        "125k": 45,
        "130k": 21,
        "135k": 33,
        "140k": 26,
        "145k": 16,
        "150k": 50,
        "155k": 65,
        "160k": 49,
        "165k": 6,
        "170k": 28,
        "175k": 7,
        "180k": 31,
        "185k": 59,
        "190k": 2,
        "195k": 7,
        "200k": 31,
        "350k": 229
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 4,
        "15k": 20,
        "20k": 38,
        "25k": 46,
        "30k": 23,
        "35k": 48,
        "40k": 8,
        "45k": 23,
        "50k": 7,
        "55k": 30,
        "60k": 30,
        "65k": 45,
        "70k": 35,
        "75k": 12,
        "80k": 6,
        "85k": 7,
        "90k": 18,
        "95k": 18,
        "100k": 7,
        "105k": 0,
        "110k": 0,
        "115k": 16,
        "120k": 6,
        "125k": 2,
        "130k": 0,
        "135k": 2,
        "140k": 7,
        "145k": 15,
        "150k": 0,
        "155k": 10,
        "160k": 7,
        "165k": 0,
        "170k": 7,
        "175k": 0,
        "180k": 7,
        "185k": 10,
        "190k": 0,
        "195k": 1,
        "200k": 0,
        "350k": 4
      },
      nonRelativefm: {
        "5k": 5,
        "10k": 14,
        "15k": 9,
        "20k": 71,
        "25k": 24,
        "30k": 33,
        "35k": 10,
        "40k": 45,
        "45k": 33,
        "50k": 77,
        "55k": 33,
        "60k": 39,
        "65k": 52,
        "70k": 40,
        "75k": 28,
        "80k": 7,
        "85k": 29,
        "90k": 12,
        "95k": 24,
        "100k": 36,
        "105k": 38,
        "110k": 40,
        "115k": 53,
        "120k": 21,
        "125k": 11,
        "130k": 18,
        "135k": 0,
        "140k": 12,
        "145k": 5,
        "150k": 3,
        "155k": 3,
        "160k": 0,
        "165k": 1,
        "170k": 10,
        "175k": 0,
        "180k": 9,
        "185k": 6,
        "190k": 0,
        "195k": 10,
        "200k": 0,
        "350k": 15
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 154,
        "10k": 108,
        "15k": 138,
        "20k": 205,
        "25k": 234,
        "30k": 252,
        "35k": 241,
        "40k": 305,
        "45k": 336,
        "50k": 221,
        "55k": 156,
        "60k": 150,
        "65k": 246,
        "70k": 154,
        "75k": 223,
        "80k": 157,
        "85k": 165,
        "90k": 96,
        "95k": 168,
        "100k": 68,
        "105k": 71,
        "110k": 86,
        "115k": 93,
        "120k": 39,
        "125k": 51,
        "130k": 32,
        "135k": 60,
        "140k": 34,
        "145k": 24,
        "150k": 26,
        "155k": 22,
        "160k": 24,
        "165k": 8,
        "170k": 17,
        "175k": 24,
        "180k": 28,
        "185k": 42,
        "190k": 4,
        "195k": 9,
        "200k": 0,
        "350k": 100
      },
      spousem: {
        "5k": 4,
        "10k": 6,
        "15k": 0,
        "20k": 43,
        "25k": 45,
        "30k": 38,
        "35k": 68,
        "40k": 73,
        "45k": 67,
        "50k": 94,
        "55k": 106,
        "60k": 38,
        "65k": 64,
        "70k": 71,
        "75k": 44,
        "80k": 57,
        "85k": 45,
        "90k": 26,
        "95k": 30,
        "100k": 22,
        "105k": 24,
        "110k": 49,
        "115k": 19,
        "120k": 0,
        "125k": 13,
        "130k": 33,
        "135k": 25,
        "140k": 48,
        "145k": 16,
        "150k": 3,
        "155k": 7,
        "160k": 5,
        "165k": 0,
        "170k": 13,
        "175k": 7,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      childm: {
        "5k": 41,
        "10k": 9,
        "15k": 88,
        "20k": 51,
        "25k": 28,
        "30k": 109,
        "35k": 20,
        "40k": 81,
        "45k": 74,
        "50k": 96,
        "55k": 44,
        "60k": 84,
        "65k": 117,
        "70k": 103,
        "75k": 45,
        "80k": 58,
        "85k": 104,
        "90k": 86,
        "95k": 91,
        "100k": 79,
        "105k": 67,
        "110k": 50,
        "115k": 35,
        "120k": 76,
        "125k": 15,
        "130k": 74,
        "135k": 44,
        "140k": 35,
        "145k": 18,
        "150k": 11,
        "155k": 50,
        "160k": 70,
        "165k": 24,
        "170k": 60,
        "175k": 9,
        "180k": 11,
        "185k": 38,
        "190k": 48,
        "195k": 23,
        "200k": 7,
        "350k": 381
      },
      otherRelativem: {
        "5k": 7,
        "10k": 0,
        "15k": 11,
        "20k": 17,
        "25k": 61,
        "30k": 26,
        "35k": 41,
        "40k": 70,
        "45k": 82,
        "50k": 7,
        "55k": 70,
        "60k": 19,
        "65k": 49,
        "70k": 20,
        "75k": 22,
        "80k": 22,
        "85k": 43,
        "90k": 31,
        "95k": 17,
        "100k": 1,
        "105k": 20,
        "110k": 8,
        "115k": 12,
        "120k": 26,
        "125k": 30,
        "130k": 17,
        "135k": 9,
        "140k": 25,
        "145k": 5,
        "150k": 12,
        "155k": 0,
        "160k": 7,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 2,
        "190k": 9,
        "195k": 0,
        "200k": 6,
        "350k": 16
      },
      nonRelativem: {
        "5k": 34,
        "10k": 13,
        "15k": 25,
        "20k": 28,
        "25k": 41,
        "30k": 41,
        "35k": 120,
        "40k": 97,
        "45k": 58,
        "50k": 107,
        "55k": 82,
        "60k": 101,
        "65k": 52,
        "70k": 46,
        "75k": 34,
        "80k": 57,
        "85k": 79,
        "90k": 25,
        "95k": 51,
        "100k": 41,
        "105k": 44,
        "110k": 67,
        "115k": 18,
        "120k": 0,
        "125k": 29,
        "130k": 44,
        "135k": 27,
        "140k": 14,
        "145k": 27,
        "150k": 17,
        "155k": 0,
        "160k": 9,
        "165k": 11,
        "170k": 14,
        "175k": 6,
        "180k": 18,
        "185k": 2,
        "190k": 47,
        "195k": 0,
        "200k": 1,
        "350k": 97
      },
      houseLoitererfm: {
        "5k": 277,
        "10k": 232,
        "15k": 333,
        "20k": 350,
        "25k": 234,
        "30k": 269,
        "35k": 333,
        "40k": 293,
        "45k": 241,
        "50k": 224,
        "55k": 156,
        "60k": 180,
        "65k": 123,
        "70k": 132,
        "75k": 150,
        "80k": 135,
        "85k": 68,
        "90k": 118,
        "95k": 86,
        "100k": 57,
        "105k": 79,
        "110k": 102,
        "115k": 57,
        "120k": 36,
        "125k": 23,
        "130k": 43,
        "135k": 46,
        "140k": 45,
        "145k": 17,
        "150k": 12,
        "155k": 7,
        "160k": 35,
        "165k": 6,
        "170k": 17,
        "175k": 20,
        "180k": 8,
        "185k": 12,
        "190k": 11,
        "195k": 15,
        "200k": 14,
        "350k": 64
      },
      spousefm: {
        "5k": 46,
        "10k": 9,
        "15k": 37,
        "20k": 70,
        "25k": 77,
        "30k": 90,
        "35k": 100,
        "40k": 144,
        "45k": 90,
        "50k": 112,
        "55k": 103,
        "60k": 92,
        "65k": 75,
        "70k": 118,
        "75k": 139,
        "80k": 111,
        "85k": 68,
        "90k": 65,
        "95k": 121,
        "100k": 95,
        "105k": 40,
        "110k": 31,
        "115k": 54,
        "120k": 31,
        "125k": 36,
        "130k": 8,
        "135k": 86,
        "140k": 16,
        "145k": 12,
        "150k": 11,
        "155k": 14,
        "160k": 8,
        "165k": 14,
        "170k": 11,
        "175k": 20,
        "180k": 2,
        "185k": 28,
        "190k": 6,
        "195k": 0,
        "200k": 5,
        "350k": 64
      },
      childfm: {
        "5k": 13,
        "10k": 17,
        "15k": 28,
        "20k": 89,
        "25k": 50,
        "30k": 86,
        "35k": 78,
        "40k": 94,
        "45k": 68,
        "50k": 46,
        "55k": 46,
        "60k": 27,
        "65k": 98,
        "70k": 77,
        "75k": 51,
        "80k": 66,
        "85k": 60,
        "90k": 38,
        "95k": 44,
        "100k": 47,
        "105k": 65,
        "110k": 60,
        "115k": 51,
        "120k": 18,
        "125k": 0,
        "130k": 65,
        "135k": 2,
        "140k": 4,
        "145k": 7,
        "150k": 33,
        "155k": 55,
        "160k": 29,
        "165k": 21,
        "170k": 41,
        "175k": 16,
        "180k": 23,
        "185k": 0,
        "190k": 35,
        "195k": 24,
        "200k": 18,
        "350k": 151
      },
      otherRelativefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 17,
        "25k": 23,
        "30k": 43,
        "35k": 15,
        "40k": 21,
        "45k": 27,
        "50k": 19,
        "55k": 31,
        "60k": 6,
        "65k": 44,
        "70k": 15,
        "75k": 8,
        "80k": 18,
        "85k": 9,
        "90k": 18,
        "95k": 1,
        "100k": 2,
        "105k": 40,
        "110k": 0,
        "115k": 4,
        "120k": 0,
        "125k": 15,
        "130k": 0,
        "135k": 13,
        "140k": 0,
        "145k": 1,
        "150k": 22,
        "155k": 7,
        "160k": 0,
        "165k": 5,
        "170k": 0,
        "175k": 1,
        "180k": 0,
        "185k": 3,
        "190k": 0,
        "195k": 11,
        "200k": 0,
        "350k": 51
      },
      nonRelativefm: {
        "5k": 20,
        "10k": 17,
        "15k": 5,
        "20k": 48,
        "25k": 55,
        "30k": 55,
        "35k": 46,
        "40k": 62,
        "45k": 111,
        "50k": 85,
        "55k": 54,
        "60k": 59,
        "65k": 110,
        "70k": 51,
        "75k": 52,
        "80k": 88,
        "85k": 15,
        "90k": 17,
        "95k": 45,
        "100k": 23,
        "105k": 33,
        "110k": 19,
        "115k": 31,
        "120k": 25,
        "125k": 34,
        "130k": 5,
        "135k": 21,
        "140k": 41,
        "145k": 8,
        "150k": 5,
        "155k": 14,
        "160k": 10,
        "165k": 6,
        "170k": 1,
        "175k": 8,
        "180k": 14,
        "185k": 17,
        "190k": 0,
        "195k": 9,
        "200k": 14,
        "350k": 59
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 140,
        "10k": 75,
        "15k": 130,
        "20k": 170,
        "25k": 150,
        "30k": 211,
        "35k": 275,
        "40k": 280,
        "45k": 276,
        "50k": 304,
        "55k": 320,
        "60k": 288,
        "65k": 245,
        "70k": 225,
        "75k": 195,
        "80k": 190,
        "85k": 258,
        "90k": 115,
        "95k": 177,
        "100k": 116,
        "105k": 138,
        "110k": 90,
        "115k": 116,
        "120k": 75,
        "125k": 104,
        "130k": 69,
        "135k": 71,
        "140k": 73,
        "145k": 69,
        "150k": 43,
        "155k": 63,
        "160k": 42,
        "165k": 41,
        "170k": 45,
        "175k": 16,
        "180k": 10,
        "185k": 20,
        "190k": 15,
        "195k": 7,
        "200k": 22,
        "350k": 245
      },
      spousem: {
        "5k": 15,
        "10k": 6,
        "15k": 28,
        "20k": 48,
        "25k": 61,
        "30k": 50,
        "35k": 158,
        "40k": 55,
        "45k": 147,
        "50k": 119,
        "55k": 66,
        "60k": 86,
        "65k": 91,
        "70k": 105,
        "75k": 98,
        "80k": 82,
        "85k": 51,
        "90k": 76,
        "95k": 65,
        "100k": 44,
        "105k": 95,
        "110k": 64,
        "115k": 25,
        "120k": 53,
        "125k": 35,
        "130k": 26,
        "135k": 21,
        "140k": 31,
        "145k": 22,
        "150k": 1,
        "155k": 23,
        "160k": 9,
        "165k": 23,
        "170k": 41,
        "175k": 9,
        "180k": 11,
        "185k": 6,
        "190k": 12,
        "195k": 11,
        "200k": 0,
        "350k": 66
      },
      childm: {
        "5k": 37,
        "10k": 16,
        "15k": 37,
        "20k": 13,
        "25k": 38,
        "30k": 50,
        "35k": 35,
        "40k": 9,
        "45k": 57,
        "50k": 38,
        "55k": 26,
        "60k": 104,
        "65k": 42,
        "70k": 28,
        "75k": 45,
        "80k": 50,
        "85k": 39,
        "90k": 43,
        "95k": 44,
        "100k": 27,
        "105k": 18,
        "110k": 26,
        "115k": 28,
        "120k": 53,
        "125k": 11,
        "130k": 13,
        "135k": 17,
        "140k": 24,
        "145k": 0,
        "150k": 23,
        "155k": 0,
        "160k": 15,
        "165k": 5,
        "170k": 0,
        "175k": 9,
        "180k": 11,
        "185k": 54,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": 94
      },
      otherRelativem: {
        "5k": 13,
        "10k": 0,
        "15k": 11,
        "20k": 2,
        "25k": 6,
        "30k": 17,
        "35k": 23,
        "40k": 13,
        "45k": 57,
        "50k": 31,
        "55k": 26,
        "60k": 22,
        "65k": 58,
        "70k": 38,
        "75k": 16,
        "80k": 4,
        "85k": 15,
        "90k": 23,
        "95k": 6,
        "100k": 9,
        "105k": 1,
        "110k": 4,
        "115k": 0,
        "120k": 9,
        "125k": 13,
        "130k": 0,
        "135k": 6,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 1,
        "160k": 13,
        "165k": 0,
        "170k": 4,
        "175k": 1,
        "180k": 0,
        "185k": 15,
        "190k": 0,
        "195k": 6,
        "200k": 0,
        "350k": 56
      },
      nonRelativem: {
        "5k": 7,
        "10k": 18,
        "15k": 41,
        "20k": 21,
        "25k": 19,
        "30k": 21,
        "35k": 53,
        "40k": 21,
        "45k": 84,
        "50k": 15,
        "55k": 18,
        "60k": 55,
        "65k": 74,
        "70k": 49,
        "75k": 58,
        "80k": 54,
        "85k": 47,
        "90k": 35,
        "95k": 28,
        "100k": 11,
        "105k": 25,
        "110k": 44,
        "115k": 33,
        "120k": 29,
        "125k": 28,
        "130k": 22,
        "135k": 0,
        "140k": 6,
        "145k": 29,
        "150k": 21,
        "155k": 26,
        "160k": 20,
        "165k": 0,
        "170k": 0,
        "175k": 16,
        "180k": 0,
        "185k": 19,
        "190k": 2,
        "195k": 8,
        "200k": 0,
        "350k": 70
      },
      houseLoitererfm: {
        "5k": 249,
        "10k": 190,
        "15k": 248,
        "20k": 259,
        "25k": 259,
        "30k": 282,
        "35k": 337,
        "40k": 170,
        "45k": 261,
        "50k": 242,
        "55k": 217,
        "60k": 192,
        "65k": 378,
        "70k": 166,
        "75k": 178,
        "80k": 207,
        "85k": 125,
        "90k": 150,
        "95k": 129,
        "100k": 84,
        "105k": 146,
        "110k": 100,
        "115k": 55,
        "120k": 44,
        "125k": 71,
        "130k": 36,
        "135k": 39,
        "140k": 50,
        "145k": 42,
        "150k": 14,
        "155k": 44,
        "160k": 22,
        "165k": 20,
        "170k": 33,
        "175k": 11,
        "180k": 0,
        "185k": 17,
        "190k": 3,
        "195k": 6,
        "200k": 5,
        "350k": 160
      },
      spousefm: {
        "5k": 27,
        "10k": 38,
        "15k": 51,
        "20k": 83,
        "25k": 113,
        "30k": 84,
        "35k": 110,
        "40k": 99,
        "45k": 157,
        "50k": 112,
        "55k": 106,
        "60k": 124,
        "65k": 184,
        "70k": 101,
        "75k": 146,
        "80k": 141,
        "85k": 182,
        "90k": 137,
        "95k": 143,
        "100k": 89,
        "105k": 108,
        "110k": 100,
        "115k": 77,
        "120k": 74,
        "125k": 91,
        "130k": 55,
        "135k": 49,
        "140k": 48,
        "145k": 58,
        "150k": 32,
        "155k": 29,
        "160k": 43,
        "165k": 44,
        "170k": 24,
        "175k": 13,
        "180k": 35,
        "185k": 34,
        "190k": 26,
        "195k": 6,
        "200k": 7,
        "350k": 232
      },
      childfm: {
        "5k": 7,
        "10k": 11,
        "15k": 6,
        "20k": 28,
        "25k": 34,
        "30k": 21,
        "35k": 26,
        "40k": 32,
        "45k": 13,
        "50k": 22,
        "55k": 23,
        "60k": 37,
        "65k": 36,
        "70k": 44,
        "75k": 14,
        "80k": 16,
        "85k": 36,
        "90k": 11,
        "95k": 14,
        "100k": 22,
        "105k": 19,
        "110k": 0,
        "115k": 4,
        "120k": 23,
        "125k": 13,
        "130k": 0,
        "135k": 30,
        "140k": 32,
        "145k": 9,
        "150k": 3,
        "155k": 30,
        "160k": 8,
        "165k": 17,
        "170k": 22,
        "175k": 5,
        "180k": 11,
        "185k": 9,
        "190k": 18,
        "195k": 12,
        "200k": 0,
        "350k": 47
      },
      otherRelativefm: {
        "5k": 0,
        "10k": 4,
        "15k": 8,
        "20k": 36,
        "25k": 24,
        "30k": 12,
        "35k": 24,
        "40k": 14,
        "45k": 21,
        "50k": 20,
        "55k": 13,
        "60k": 17,
        "65k": 20,
        "70k": 14,
        "75k": 8,
        "80k": 3,
        "85k": 26,
        "90k": 25,
        "95k": 29,
        "100k": 10,
        "105k": 5,
        "110k": 6,
        "115k": 7,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 14,
        "140k": 0,
        "145k": 1,
        "150k": 4,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 17,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 5,
        "350k": 37
      },
      nonRelativefm: {
        "5k": 7,
        "10k": 24,
        "15k": 15,
        "20k": 8,
        "25k": 25,
        "30k": 32,
        "35k": 27,
        "40k": 24,
        "45k": 40,
        "50k": 35,
        "55k": 14,
        "60k": 32,
        "65k": 29,
        "70k": 7,
        "75k": 16,
        "80k": 21,
        "85k": 39,
        "90k": 29,
        "95k": 14,
        "100k": 3,
        "105k": 13,
        "110k": 29,
        "115k": 36,
        "120k": 26,
        "125k": 13,
        "130k": 20,
        "135k": 8,
        "140k": 8,
        "145k": 29,
        "150k": 10,
        "155k": 11,
        "160k": 8,
        "165k": 0,
        "170k": 17,
        "175k": 0,
        "180k": 3,
        "185k": 10,
        "190k": 0,
        "195k": 0,
        "200k": 12,
        "350k": 74
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 187,
        "10k": 183,
        "15k": 239,
        "20k": 257,
        "25k": 387,
        "30k": 345,
        "35k": 443,
        "40k": 375,
        "45k": 411,
        "50k": 367,
        "55k": 320,
        "60k": 377,
        "65k": 521,
        "70k": 339,
        "75k": 367,
        "80k": 400,
        "85k": 281,
        "90k": 396,
        "95k": 348,
        "100k": 252,
        "105k": 279,
        "110k": 289,
        "115k": 207,
        "120k": 246,
        "125k": 208,
        "130k": 158,
        "135k": 257,
        "140k": 144,
        "145k": 112,
        "150k": 163,
        "155k": 130,
        "160k": 119,
        "165k": 105,
        "170k": 63,
        "175k": 119,
        "180k": 84,
        "185k": 102,
        "190k": 83,
        "195k": 50,
        "200k": 39,
        "350k": 927
      },
      spousem: {
        "5k": 52,
        "10k": 32,
        "15k": 45,
        "20k": 77,
        "25k": 125,
        "30k": 115,
        "35k": 117,
        "40k": 147,
        "45k": 175,
        "50k": 242,
        "55k": 176,
        "60k": 222,
        "65k": 237,
        "70k": 162,
        "75k": 203,
        "80k": 171,
        "85k": 167,
        "90k": 167,
        "95k": 223,
        "100k": 96,
        "105k": 178,
        "110k": 101,
        "115k": 148,
        "120k": 123,
        "125k": 93,
        "130k": 154,
        "135k": 59,
        "140k": 67,
        "145k": 86,
        "150k": 72,
        "155k": 123,
        "160k": 32,
        "165k": 42,
        "170k": 57,
        "175k": 45,
        "180k": 39,
        "185k": 46,
        "190k": 41,
        "195k": 32,
        "200k": 31,
        "350k": 431
      },
      childm: {
        "5k": 2,
        "10k": 37,
        "15k": 46,
        "20k": 67,
        "25k": 111,
        "30k": 82,
        "35k": 42,
        "40k": 74,
        "45k": 119,
        "50k": 106,
        "55k": 74,
        "60k": 51,
        "65k": 75,
        "70k": 17,
        "75k": 59,
        "80k": 53,
        "85k": 60,
        "90k": 72,
        "95k": 72,
        "100k": 0,
        "105k": 32,
        "110k": 2,
        "115k": 36,
        "120k": 11,
        "125k": 17,
        "130k": 2,
        "135k": 35,
        "140k": 5,
        "145k": 1,
        "150k": 7,
        "155k": 0,
        "160k": 0,
        "165k": 7,
        "170k": 0,
        "175k": 9,
        "180k": 0,
        "185k": 17,
        "190k": 0,
        "195k": 13,
        "200k": 5,
        "350k": 84
      },
      otherRelativem: {
        "5k": 6,
        "10k": 9,
        "15k": 21,
        "20k": 24,
        "25k": 51,
        "30k": 24,
        "35k": 40,
        "40k": 74,
        "45k": 59,
        "50k": 59,
        "55k": 67,
        "60k": 32,
        "65k": 34,
        "70k": 35,
        "75k": 61,
        "80k": 18,
        "85k": 15,
        "90k": 19,
        "95k": 27,
        "100k": 12,
        "105k": 20,
        "110k": 42,
        "115k": 30,
        "120k": 4,
        "125k": 13,
        "130k": 0,
        "135k": 17,
        "140k": 7,
        "145k": 1,
        "150k": 10,
        "155k": 5,
        "160k": 28,
        "165k": 6,
        "170k": 16,
        "175k": 4,
        "180k": 2,
        "185k": 10,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 26
      },
      nonRelativem: {
        "5k": 28,
        "10k": 15,
        "15k": 25,
        "20k": 54,
        "25k": 71,
        "30k": 27,
        "35k": 85,
        "40k": 50,
        "45k": 61,
        "50k": 136,
        "55k": 73,
        "60k": 113,
        "65k": 99,
        "70k": 25,
        "75k": 70,
        "80k": 62,
        "85k": 115,
        "90k": 44,
        "95k": 21,
        "100k": 30,
        "105k": 19,
        "110k": 61,
        "115k": 32,
        "120k": 7,
        "125k": 23,
        "130k": 17,
        "135k": 15,
        "140k": 5,
        "145k": 7,
        "150k": 8,
        "155k": 16,
        "160k": 22,
        "165k": 8,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 20,
        "190k": 8,
        "195k": 1,
        "200k": 4,
        "350k": 31
      },
      houseLoitererfm: {
        "5k": 373,
        "10k": 345,
        "15k": 370,
        "20k": 453,
        "25k": 634,
        "30k": 470,
        "35k": 463,
        "40k": 504,
        "45k": 485,
        "50k": 458,
        "55k": 372,
        "60k": 415,
        "65k": 425,
        "70k": 387,
        "75k": 311,
        "80k": 289,
        "85k": 245,
        "90k": 201,
        "95k": 270,
        "100k": 193,
        "105k": 276,
        "110k": 140,
        "115k": 222,
        "120k": 142,
        "125k": 191,
        "130k": 219,
        "135k": 102,
        "140k": 80,
        "145k": 103,
        "150k": 132,
        "155k": 160,
        "160k": 50,
        "165k": 73,
        "170k": 82,
        "175k": 65,
        "180k": 82,
        "185k": 65,
        "190k": 49,
        "195k": 26,
        "200k": 29,
        "350k": 537
      },
      spousefm: {
        "5k": 52,
        "10k": 31,
        "15k": 79,
        "20k": 81,
        "25k": 131,
        "30k": 149,
        "35k": 233,
        "40k": 196,
        "45k": 235,
        "50k": 221,
        "55k": 159,
        "60k": 196,
        "65k": 296,
        "70k": 222,
        "75k": 259,
        "80k": 322,
        "85k": 206,
        "90k": 216,
        "95k": 188,
        "100k": 263,
        "105k": 266,
        "110k": 182,
        "115k": 204,
        "120k": 188,
        "125k": 139,
        "130k": 146,
        "135k": 190,
        "140k": 147,
        "145k": 94,
        "150k": 138,
        "155k": 171,
        "160k": 92,
        "165k": 94,
        "170k": 52,
        "175k": 106,
        "180k": 44,
        "185k": 72,
        "190k": 85,
        "195k": 28,
        "200k": 52,
        "350k": 912
      },
      childfm: {
        "5k": 6,
        "10k": 8,
        "15k": 18,
        "20k": 57,
        "25k": 40,
        "30k": 33,
        "35k": 42,
        "40k": 59,
        "45k": 39,
        "50k": 66,
        "55k": 39,
        "60k": 9,
        "65k": 36,
        "70k": 15,
        "75k": 32,
        "80k": 34,
        "85k": 40,
        "90k": 31,
        "95k": 41,
        "100k": 9,
        "105k": 9,
        "110k": 0,
        "115k": 11,
        "120k": 0,
        "125k": 11,
        "130k": 3,
        "135k": 41,
        "140k": 15,
        "145k": 2,
        "150k": 9,
        "155k": 7,
        "160k": 12,
        "165k": 19,
        "170k": 9,
        "175k": 11,
        "180k": 40,
        "185k": 30,
        "190k": 0,
        "195k": 0,
        "200k": 8,
        "350k": 37
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 16,
        "15k": 21,
        "20k": 43,
        "25k": 53,
        "30k": 33,
        "35k": 47,
        "40k": 27,
        "45k": 46,
        "50k": 14,
        "55k": 40,
        "60k": 18,
        "65k": 29,
        "70k": 17,
        "75k": 12,
        "80k": 11,
        "85k": 5,
        "90k": 26,
        "95k": 17,
        "100k": 10,
        "105k": 13,
        "110k": 5,
        "115k": 27,
        "120k": 7,
        "125k": 10,
        "130k": 1,
        "135k": 4,
        "140k": 12,
        "145k": 3,
        "150k": 9,
        "155k": 0,
        "160k": 2,
        "165k": 13,
        "170k": 1,
        "175k": 5,
        "180k": 0,
        "185k": 10,
        "190k": 0,
        "195k": 6,
        "200k": 0,
        "350k": 32
      },
      nonRelativefm: {
        "5k": 15,
        "10k": 19,
        "15k": 17,
        "20k": 49,
        "25k": 35,
        "30k": 32,
        "35k": 41,
        "40k": 22,
        "45k": 24,
        "50k": 33,
        "55k": 47,
        "60k": 23,
        "65k": 44,
        "70k": 55,
        "75k": 64,
        "80k": 41,
        "85k": 37,
        "90k": 23,
        "95k": 41,
        "100k": 6,
        "105k": 1,
        "110k": 21,
        "115k": 15,
        "120k": 16,
        "125k": 26,
        "130k": 5,
        "135k": 16,
        "140k": 14,
        "145k": 4,
        "150k": 26,
        "155k": 0,
        "160k": 12,
        "165k": 0,
        "170k": 37,
        "175k": 13,
        "180k": 18,
        "185k": 5,
        "190k": 5,
        "195k": 7,
        "200k": 1,
        "350k": 69
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 356,
        "10k": 363,
        "15k": 343,
        "20k": 462,
        "25k": 390,
        "30k": 337,
        "35k": 446,
        "40k": 408,
        "45k": 383,
        "50k": 362,
        "55k": 399,
        "60k": 381,
        "65k": 453,
        "70k": 347,
        "75k": 447,
        "80k": 351,
        "85k": 372,
        "90k": 288,
        "95k": 379,
        "100k": 335,
        "105k": 429,
        "110k": 245,
        "115k": 278,
        "120k": 238,
        "125k": 246,
        "130k": 200,
        "135k": 148,
        "140k": 163,
        "145k": 157,
        "150k": 162,
        "155k": 275,
        "160k": 99,
        "165k": 114,
        "170k": 119,
        "175k": 93,
        "180k": 95,
        "185k": 100,
        "190k": 103,
        "195k": 93,
        "200k": 63,
        "350k": "1,087"
      },
      spousem: {
        "5k": 27,
        "10k": 22,
        "15k": 70,
        "20k": 79,
        "25k": 90,
        "30k": 140,
        "35k": 122,
        "40k": 164,
        "45k": 133,
        "50k": 174,
        "55k": 175,
        "60k": 161,
        "65k": 265,
        "70k": 156,
        "75k": 161,
        "80k": 131,
        "85k": 113,
        "90k": 204,
        "95k": 146,
        "100k": 225,
        "105k": 227,
        "110k": 189,
        "115k": 187,
        "120k": 110,
        "125k": 105,
        "130k": 134,
        "135k": 87,
        "140k": 87,
        "145k": 120,
        "150k": 122,
        "155k": 147,
        "160k": 98,
        "165k": 93,
        "170k": 57,
        "175k": 90,
        "180k": 55,
        "185k": 73,
        "190k": 64,
        "195k": 37,
        "200k": 27,
        "350k": "765"
      },
      childm: {
        "5k": 10,
        "10k": 12,
        "15k": 67,
        "20k": 32,
        "25k": 130,
        "30k": 92,
        "35k": 30,
        "40k": 29,
        "45k": 45,
        "50k": 34,
        "55k": 55,
        "60k": 62,
        "65k": 27,
        "70k": 56,
        "75k": 22,
        "80k": 3,
        "85k": 12,
        "90k": 84,
        "95k": 17,
        "100k": 4,
        "105k": 34,
        "110k": 0,
        "115k": 0,
        "120k": 16,
        "125k": 10,
        "130k": 25,
        "135k": 0,
        "140k": 15,
        "145k": 10,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 9,
        "180k": 0,
        "185k": 0,
        "190k": 4,
        "195k": 0,
        "200k": 4,
        "350k": "46"
      },
      otherRelativem: {
        "5k": 0,
        "10k": 14,
        "15k": 44,
        "20k": 46,
        "25k": 73,
        "30k": 69,
        "35k": 41,
        "40k": 73,
        "45k": 50,
        "50k": 34,
        "55k": 44,
        "60k": 39,
        "65k": 38,
        "70k": 46,
        "75k": 48,
        "80k": 60,
        "85k": 32,
        "90k": 43,
        "95k": 9,
        "100k": 19,
        "105k": 16,
        "110k": 12,
        "115k": 36,
        "120k": 0,
        "125k": 67,
        "130k": 21,
        "135k": 9,
        "140k": 0,
        "145k": 15,
        "150k": 26,
        "155k": 19,
        "160k": 16,
        "165k": 18,
        "170k": 9,
        "175k": 5,
        "180k": 6,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 4,
        "350k": "43"
      },
      nonRelativem: {
        "5k": 26,
        "10k": 34,
        "15k": 31,
        "20k": 57,
        "25k": 34,
        "30k": 69,
        "35k": 25,
        "40k": 26,
        "45k": 104,
        "50k": 83,
        "55k": 58,
        "60k": 31,
        "65k": 26,
        "70k": 66,
        "75k": 34,
        "80k": 43,
        "85k": 21,
        "90k": 37,
        "95k": 27,
        "100k": 43,
        "105k": 8,
        "110k": 29,
        "115k": 14,
        "120k": 40,
        "125k": 8,
        "130k": 26,
        "135k": 26,
        "140k": 8,
        "145k": 10,
        "150k": 19,
        "155k": 16,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 25,
        "180k": 6,
        "185k": 0,
        "190k": 17,
        "195k": 0,
        "200k": 0,
        "350k": "59"
      },
      houseLoitererfm: {
        "5k": 419,
        "10k": 392,
        "15k": 436,
        "20k": 524,
        "25k": 585,
        "30k": 454,
        "35k": 489,
        "40k": 453,
        "45k": 530,
        "50k": 489,
        "55k": 409,
        "60k": 377,
        "65k": 407,
        "70k": 333,
        "75k": 338,
        "80k": 316,
        "85k": 320,
        "90k": 301,
        "95k": 229,
        "100k": 245,
        "105k": 260,
        "110k": 236,
        "115k": 217,
        "120k": 160,
        "125k": 165,
        "130k": 185,
        "135k": 127,
        "140k": 112,
        "145k": 120,
        "150k": 143,
        "155k": 176,
        "160k": 108,
        "165k": 113,
        "170k": 91,
        "175k": 116,
        "180k": 53,
        "185k": 89,
        "190k": 64,
        "195k": 55,
        "200k": 45,
        "350k": "878"
      },
      spousefm: {
        "5k": 84,
        "10k": 81,
        "15k": 95,
        "20k": 139,
        "25k": 153,
        "30k": 146,
        "35k": 215,
        "40k": 225,
        "45k": 196,
        "50k": 112,
        "55k": 235,
        "60k": 275,
        "65k": 227,
        "70k": 259,
        "75k": 336,
        "80k": 257,
        "85k": 353,
        "90k": 187,
        "95k": 243,
        "100k": 202,
        "105k": 307,
        "110k": 202,
        "115k": 191,
        "120k": 229,
        "125k": 166,
        "130k": 108,
        "135k": 177,
        "140k": 85,
        "145k": 147,
        "150k": 134,
        "155k": 187,
        "160k": 84,
        "165k": 123,
        "170k": 116,
        "175k": 75,
        "180k": 109,
        "185k": 74,
        "190k": 71,
        "195k": 105,
        "200k": 47,
        "350k": "1,014"
      },
      childfm: {
        "5k": 0,
        "10k": 13,
        "15k": 0,
        "20k": 15,
        "25k": 61,
        "30k": 45,
        "35k": 25,
        "40k": 38,
        "45k": 36,
        "50k": 26,
        "55k": 21,
        "60k": 14,
        "65k": 5,
        "70k": 14,
        "75k": 21,
        "80k": 26,
        "85k": 38,
        "90k": 0,
        "95k": 7,
        "100k": 24,
        "105k": 23,
        "110k": 0,
        "115k": 27,
        "120k": 6,
        "125k": 28,
        "130k": 15,
        "135k": 16,
        "140k": 10,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 7,
        "170k": 0,
        "175k": 9,
        "180k": 0,
        "185k": 7,
        "190k": 5,
        "195k": 2,
        "200k": 0,
        "350k": "24"
      },
      otherRelativefm: {
        "5k": 31,
        "10k": 32,
        "15k": 29,
        "20k": 30,
        "25k": 80,
        "30k": 56,
        "35k": 48,
        "40k": 78,
        "45k": 87,
        "50k": 42,
        "55k": 47,
        "60k": 19,
        "65k": 47,
        "70k": 53,
        "75k": 19,
        "80k": 57,
        "85k": 22,
        "90k": 70,
        "95k": 20,
        "100k": 21,
        "105k": 20,
        "110k": 8,
        "115k": 49,
        "120k": 9,
        "125k": 38,
        "130k": 5,
        "135k": 4,
        "140k": 6,
        "145k": 8,
        "150k": 34,
        "155k": 41,
        "160k": 10,
        "165k": 17,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 5,
        "200k": 12,
        "350k": "62"
      },
      nonRelativefm: {
        "5k": 29,
        "10k": 49,
        "15k": 32,
        "20k": 39,
        "25k": 23,
        "30k": 27,
        "35k": 11,
        "40k": 12,
        "45k": 20,
        "50k": 35,
        "55k": 45,
        "60k": 39,
        "65k": 36,
        "70k": 17,
        "75k": 8,
        "80k": 12,
        "85k": 45,
        "90k": 33,
        "95k": 4,
        "100k": 22,
        "105k": 18,
        "110k": 10,
        "115k": 20,
        "120k": 5,
        "125k": 21,
        "130k": 50,
        "135k": 5,
        "140k": 6,
        "145k": 3,
        "150k": 5,
        "155k": 25,
        "160k": 0,
        "165k": 8,
        "170k": 11,
        "175k": 6,
        "180k": 11,
        "185k": 6,
        "190k": 6,
        "195k": 0,
        "200k": 8,
        "350k": "41"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 451,
        "10k": 405,
        "15k": 554,
        "20k": 554,
        "25k": 471,
        "30k": 455,
        "35k": 395,
        "40k": 341,
        "45k": 451,
        "50k": 433,
        "55k": 466,
        "60k": 407,
        "65k": 423,
        "70k": 371,
        "75k": 512,
        "80k": 322,
        "85k": 432,
        "90k": 269,
        "95k": 274,
        "100k": 301,
        "105k": 249,
        "110k": 229,
        "115k": 193,
        "120k": 175,
        "125k": 177,
        "130k": 133,
        "135k": 195,
        "140k": 77,
        "145k": 136,
        "150k": 156,
        "155k": 180,
        "160k": 110,
        "165k": 103,
        "170k": 161,
        "175k": 68,
        "180k": 69,
        "185k": 117,
        "190k": 49,
        "195k": 54,
        "200k": 79,
        "350k": "1,074"
      },
      spousem: {
        "5k": 51,
        "10k": 26,
        "15k": 91,
        "20k": 110,
        "25k": 97,
        "30k": 163,
        "35k": 144,
        "40k": 156,
        "45k": 192,
        "50k": 145,
        "55k": 129,
        "60k": 169,
        "65k": 269,
        "70k": 171,
        "75k": 110,
        "80k": 172,
        "85k": 146,
        "90k": 176,
        "95k": 109,
        "100k": 152,
        "105k": 112,
        "110k": 65,
        "115k": 127,
        "120k": 129,
        "125k": 69,
        "130k": 75,
        "135k": 73,
        "140k": 129,
        "145k": 45,
        "150k": 83,
        "155k": 121,
        "160k": 79,
        "165k": 61,
        "170k": 34,
        "175k": 72,
        "180k": 64,
        "185k": 74,
        "190k": 63,
        "195k": 60,
        "200k": 21,
        "350k": "610"
      },
      childm: {
        "5k": 5,
        "10k": 16,
        "15k": 4,
        "20k": 42,
        "25k": 5,
        "30k": 9,
        "35k": 6,
        "40k": 11,
        "45k": 11,
        "50k": 5,
        "55k": 1,
        "60k": 8,
        "65k": 0,
        "70k": 7,
        "75k": 4,
        "80k": 1,
        "85k": 5,
        "90k": 5,
        "95k": 16,
        "100k": 0,
        "105k": 12,
        "110k": 11,
        "115k": 7,
        "120k": 10,
        "125k": 0,
        "130k": 0,
        "135k": 5,
        "140k": 2,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 3,
        "165k": 0,
        "170k": 0,
        "175k": 6,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 5,
        "350k": "19"
      },
      otherRelativem: {
        "5k": 0,
        "10k": 26,
        "15k": 12,
        "20k": 40,
        "25k": 31,
        "30k": 12,
        "35k": 53,
        "40k": 29,
        "45k": 37,
        "50k": 0,
        "55k": 19,
        "60k": 13,
        "65k": 24,
        "70k": 28,
        "75k": 47,
        "80k": 34,
        "85k": 8,
        "90k": 21,
        "95k": 13,
        "100k": 24,
        "105k": 2,
        "110k": 26,
        "115k": 25,
        "120k": 4,
        "125k": 29,
        "130k": 29,
        "135k": 20,
        "140k": 2,
        "145k": 6,
        "150k": 4,
        "155k": 4,
        "160k": 10,
        "165k": 10,
        "170k": 0,
        "175k": 0,
        "180k": 6,
        "185k": 2,
        "190k": 5,
        "195k": 5,
        "200k": 7,
        "350k": "59"
      },
      nonRelativem: {
        "5k": 6,
        "10k": 0,
        "15k": 32,
        "20k": 31,
        "25k": 22,
        "30k": 39,
        "35k": 15,
        "40k": 26,
        "45k": 18,
        "50k": 27,
        "55k": 13,
        "60k": 24,
        "65k": 8,
        "70k": 2,
        "75k": 5,
        "80k": 8,
        "85k": 42,
        "90k": 16,
        "95k": 25,
        "100k": 11,
        "105k": 11,
        "110k": 17,
        "115k": 2,
        "120k": 6,
        "125k": 1,
        "130k": 0,
        "135k": 3,
        "140k": 0,
        "145k": 22,
        "150k": 0,
        "155k": 10,
        "160k": 0,
        "165k": 1,
        "170k": 10,
        "175k": 7,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 7,
        "200k": 0,
        "350k": "55"
      },
      houseLoitererfm: {
        "5k": 435,
        "10k": 595,
        "15k": 738,
        "20k": 659,
        "25k": 583,
        "30k": 495,
        "35k": 524,
        "40k": 500,
        "45k": 530,
        "50k": 427,
        "55k": 364,
        "60k": 355,
        "65k": 500,
        "70k": 333,
        "75k": 231,
        "80k": 261,
        "85k": 289,
        "90k": 233,
        "95k": 192,
        "100k": 234,
        "105k": 193,
        "110k": 170,
        "115k": 142,
        "120k": 200,
        "125k": 96,
        "130k": 124,
        "135k": 82,
        "140k": 171,
        "145k": 88,
        "150k": 64,
        "155k": 148,
        "160k": 88,
        "165k": 47,
        "170k": 54,
        "175k": 90,
        "180k": 81,
        "185k": 79,
        "190k": 81,
        "195k": 64,
        "200k": 14,
        "350k": "771"
      },
      spousefm: {
        "5k": 73,
        "10k": 75,
        "15k": 111,
        "20k": 159,
        "25k": 218,
        "30k": 238,
        "35k": 195,
        "40k": 218,
        "45k": 204,
        "50k": 278,
        "55k": 308,
        "60k": 265,
        "65k": 264,
        "70k": 207,
        "75k": 337,
        "80k": 243,
        "85k": 268,
        "90k": 194,
        "95k": 256,
        "100k": 244,
        "105k": 167,
        "110k": 179,
        "115k": 150,
        "120k": 80,
        "125k": 124,
        "130k": 113,
        "135k": 107,
        "140k": 95,
        "145k": 91,
        "150k": 142,
        "155k": 103,
        "160k": 105,
        "165k": 65,
        "170k": 97,
        "175k": 23,
        "180k": 53,
        "185k": 130,
        "190k": 52,
        "195k": 46,
        "200k": 61,
        "350k": "884"
      },
      childfm: {
        "5k": 13,
        "10k": 6,
        "15k": 9,
        "20k": 5,
        "25k": 4,
        "30k": 21,
        "35k": 22,
        "40k": 25,
        "45k": 0,
        "50k": 20,
        "55k": 8,
        "60k": 9,
        "65k": 15,
        "70k": 16,
        "75k": 4,
        "80k": 0,
        "85k": 1,
        "90k": 21,
        "95k": 8,
        "100k": 0,
        "105k": 0,
        "110k": 10,
        "115k": 0,
        "120k": 10,
        "125k": 0,
        "130k": 5,
        "135k": 8,
        "140k": 0,
        "145k": 1,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 4,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 18,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      otherRelativefm: {
        "5k": 27,
        "10k": 25,
        "15k": 24,
        "20k": 48,
        "25k": 39,
        "30k": 70,
        "35k": 81,
        "40k": 45,
        "45k": 30,
        "50k": 41,
        "55k": 52,
        "60k": 33,
        "65k": 55,
        "70k": 38,
        "75k": 66,
        "80k": 42,
        "85k": 17,
        "90k": 47,
        "95k": 13,
        "100k": 44,
        "105k": 25,
        "110k": 30,
        "115k": 6,
        "120k": 8,
        "125k": 23,
        "130k": 32,
        "135k": 18,
        "140k": 7,
        "145k": 17,
        "150k": 15,
        "155k": 4,
        "160k": 7,
        "165k": 5,
        "170k": 0,
        "175k": 23,
        "180k": 4,
        "185k": 7,
        "190k": 0,
        "195k": 5,
        "200k": 7,
        "350k": "96"
      },
      nonRelativefm: {
        "5k": 11,
        "10k": 1,
        "15k": 20,
        "20k": 13,
        "25k": 33,
        "30k": 23,
        "35k": 29,
        "40k": 16,
        "45k": 32,
        "50k": 52,
        "55k": 14,
        "60k": 46,
        "65k": 21,
        "70k": 6,
        "75k": 25,
        "80k": 14,
        "85k": 8,
        "90k": 27,
        "95k": 40,
        "100k": 9,
        "105k": 15,
        "110k": 1,
        "115k": 1,
        "120k": 0,
        "125k": 15,
        "130k": 9,
        "135k": 15,
        "140k": 2,
        "145k": 17,
        "150k": 1,
        "155k": 0,
        "160k": 1,
        "165k": 10,
        "170k": 1,
        "175k": 1,
        "180k": 0,
        "185k": 7,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "15"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 141,
        "10k": 189,
        "15k": 478,
        "20k": 473,
        "25k": 383,
        "30k": 598,
        "35k": 312,
        "40k": 525,
        "45k": 433,
        "50k": 348,
        "55k": 382,
        "60k": 397,
        "65k": 329,
        "70k": 255,
        "75k": 336,
        "80k": 335,
        "85k": 292,
        "90k": 198,
        "95k": 179,
        "100k": 143,
        "105k": 156,
        "110k": 142,
        "115k": 137,
        "120k": 88,
        "125k": 79,
        "130k": 82,
        "135k": 92,
        "140k": 89,
        "145k": 77,
        "150k": 78,
        "155k": 46,
        "160k": 26,
        "165k": 49,
        "170k": 43,
        "175k": 31,
        "180k": 61,
        "185k": 51,
        "190k": 40,
        "195k": 40,
        "200k": 21,
        "350k": 503
      },
      spousem: {
        "5k": 68,
        "10k": 15,
        "15k": 94,
        "20k": 103,
        "25k": 136,
        "30k": 140,
        "35k": 160,
        "40k": 150,
        "45k": 146,
        "50k": 170,
        "55k": 262,
        "60k": 77,
        "65k": 127,
        "70k": 114,
        "75k": 125,
        "80k": 84,
        "85k": 65,
        "90k": 62,
        "95k": 36,
        "100k": 49,
        "105k": 69,
        "110k": 25,
        "115k": 118,
        "120k": 51,
        "125k": 40,
        "130k": 12,
        "135k": 42,
        "140k": 36,
        "145k": 29,
        "150k": 34,
        "155k": 34,
        "160k": 19,
        "165k": 31,
        "170k": 42,
        "175k": 51,
        "180k": 18,
        "185k": 6,
        "190k": 9,
        "195k": 4,
        "200k": 2,
        "350k": 200
      },
      childm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 1,
        "30k": 2,
        "35k": 9,
        "40k": 12,
        "45k": 0,
        "50k": 6,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 7,
        "10k": 0,
        "15k": 15,
        "20k": 12,
        "25k": 42,
        "30k": 2,
        "35k": 31,
        "40k": 43,
        "45k": 26,
        "50k": 20,
        "55k": 21,
        "60k": 14,
        "65k": 10,
        "70k": 31,
        "75k": 15,
        "80k": 30,
        "85k": 6,
        "90k": 17,
        "95k": 16,
        "100k": 24,
        "105k": 11,
        "110k": 17,
        "115k": 2,
        "120k": 7,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 13,
        "145k": 1,
        "150k": 0,
        "155k": 13,
        "160k": 0,
        "165k": 5,
        "170k": 10,
        "175k": 18,
        "180k": 0,
        "185k": 2,
        "190k": 3,
        "195k": 5,
        "200k": 0,
        "350k": 35
      },
      nonRelativem: {
        "5k": 7,
        "10k": 0,
        "15k": 4,
        "20k": 14,
        "25k": 36,
        "30k": 23,
        "35k": 43,
        "40k": 5,
        "45k": 17,
        "50k": 0,
        "55k": 5,
        "60k": 11,
        "65k": 11,
        "70k": 1,
        "75k": 0,
        "80k": 8,
        "85k": 0,
        "90k": 0,
        "95k": 10,
        "100k": 0,
        "105k": 8,
        "110k": 0,
        "115k": 0,
        "120k": 1,
        "125k": 6,
        "130k": 6,
        "135k": 3,
        "140k": 0,
        "145k": 0,
        "150k": 4,
        "155k": 1,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 10,
        "185k": 16,
        "190k": 0,
        "195k": 0,
        "200k": 8,
        "350k": 3
      },
      houseLoitererfm: {
        "5k": 203,
        "10k": 418,
        "15k": 693,
        "20k": 729,
        "25k": 556,
        "30k": 506,
        "35k": 438,
        "40k": 423,
        "45k": 400,
        "50k": 420,
        "55k": 409,
        "60k": 188,
        "65k": 252,
        "70k": 218,
        "75k": 165,
        "80k": 189,
        "85k": 117,
        "90k": 176,
        "95k": 64,
        "100k": 90,
        "105k": 105,
        "110k": 32,
        "115k": 134,
        "120k": 52,
        "125k": 36,
        "130k": 29,
        "135k": 8,
        "140k": 49,
        "145k": 29,
        "150k": 41,
        "155k": 12,
        "160k": 10,
        "165k": 26,
        "170k": 14,
        "175k": 24,
        "180k": 45,
        "185k": 34,
        "190k": 9,
        "195k": 4,
        "200k": 6,
        "350k": 165
      },
      spousefm: {
        "5k": 53,
        "10k": 27,
        "15k": 92,
        "20k": 191,
        "25k": 117,
        "30k": 376,
        "35k": 227,
        "40k": 317,
        "45k": 283,
        "50k": 216,
        "55k": 267,
        "60k": 258,
        "65k": 207,
        "70k": 215,
        "75k": 242,
        "80k": 244,
        "85k": 190,
        "90k": 126,
        "95k": 156,
        "100k": 127,
        "105k": 101,
        "110k": 70,
        "115k": 64,
        "120k": 49,
        "125k": 57,
        "130k": 51,
        "135k": 42,
        "140k": 39,
        "145k": 59,
        "150k": 27,
        "155k": 39,
        "160k": 24,
        "165k": 40,
        "170k": 34,
        "175k": 30,
        "180k": 29,
        "185k": 10,
        "190k": 7,
        "195k": 12,
        "200k": 9,
        "350k": 317
      },
      childfm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 5,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 8,
        "65k": 0,
        "70k": 7,
        "75k": 0,
        "80k": 0,
        "85k": 7,
        "90k": 0,
        "95k": 6,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 8,
        "15k": 0,
        "20k": 43,
        "25k": 38,
        "30k": 29,
        "35k": 36,
        "40k": 41,
        "45k": 39,
        "50k": 57,
        "55k": 74,
        "60k": 19,
        "65k": 43,
        "70k": 56,
        "75k": 36,
        "80k": 24,
        "85k": 27,
        "90k": 54,
        "95k": 31,
        "100k": 28,
        "105k": 32,
        "110k": 33,
        "115k": 21,
        "120k": 11,
        "125k": 1,
        "130k": 0,
        "135k": 14,
        "140k": 9,
        "145k": 2,
        "150k": 19,
        "155k": 7,
        "160k": 0,
        "165k": 15,
        "170k": 9,
        "175k": 4,
        "180k": 0,
        "185k": 11,
        "190k": 7,
        "195k": 0,
        "200k": 4,
        "350k": 76
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 0,
        "15k": 3,
        "20k": 0,
        "25k": 18,
        "30k": 10,
        "35k": 0,
        "40k": 13,
        "45k": 9,
        "50k": 1,
        "55k": 9,
        "60k": 11,
        "65k": 13,
        "70k": 3,
        "75k": 23,
        "80k": 0,
        "85k": 0,
        "90k": 10,
        "95k": 0,
        "100k": 6,
        "105k": 5,
        "110k": 0,
        "115k": 0,
        "120k": 17,
        "125k": 4,
        "130k": 0,
        "135k": 17,
        "140k": 1,
        "145k": 6,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 6,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 13
      }
    }
  },
  "2014": {
    "17": {
      houseLoitererm: {
        "5k": 5,
        "10k": 2,
        "15k": 5,
        "20k": 6,
        "25k": 13,
        "30k": 10,
        "35k": 6,
        "40k": 2,
        "45k": 3,
        "50k": 3,
        "55k": 6,
        "60k": 6,
        "65k": 10,
        "70k": 9,
        "75k": 4,
        "80k": 0,
        "85k": 0,
        "90k": 2,
        "95k": 2,
        "100k": 0,
        "105k": 1,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 2,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 0,
        "155k": 3,
        "160k": 0,
        "165k": 3,
        "170k": 0,
        "175k": 1,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "1"
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 2,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 2,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 2,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 1,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childm: {
        "5k": 291,
        "10k": 263,
        "15k": 282,
        "20k": 407,
        "25k": 427,
        "30k": 476,
        "35k": 517,
        "40k": 395,
        "45k": 487,
        "50k": 436,
        "55k": 396,
        "60k": 365,
        "65k": 383,
        "70k": 344,
        "75k": 414,
        "80k": 357,
        "85k": 328,
        "90k": 301,
        "95k": 295,
        "100k": 243,
        "105k": 352,
        "110k": 254,
        "115k": 220,
        "120k": 210,
        "125k": 214,
        "130k": 191,
        "135k": 209,
        "140k": 149,
        "145k": 169,
        "150k": 124,
        "155k": 166,
        "160k": 139,
        "165k": 104,
        "170k": 85,
        "175k": 82,
        "180k": 91,
        "185k": 106,
        "190k": 66,
        "195k": 51,
        "200k": 47,
        "350k": "1,000"
      },
      otherRelativem: {
        "5k": 27,
        "10k": 29,
        "15k": 67,
        "20k": 54,
        "25k": 50,
        "30k": 71,
        "35k": 75,
        "40k": 57,
        "45k": 41,
        "50k": 57,
        "55k": 57,
        "60k": 47,
        "65k": 46,
        "70k": 45,
        "75k": 47,
        "80k": 27,
        "85k": 35,
        "90k": 23,
        "95k": 28,
        "100k": 39,
        "105k": 29,
        "110k": 16,
        "115k": 28,
        "120k": 9,
        "125k": 14,
        "130k": 16,
        "135k": 8,
        "140k": 9,
        "145k": 7,
        "150k": 8,
        "155k": 4,
        "160k": 7,
        "165k": 12,
        "170k": 4,
        "175k": 6,
        "180k": 5,
        "185k": 2,
        "190k": 0,
        "195k": 5,
        "200k": 2,
        "350k": "43"
      },
      nonRelativem: {
        "5k": 5,
        "10k": 3,
        "15k": 6,
        "20k": 6,
        "25k": 5,
        "30k": 8,
        "35k": 7,
        "40k": 10,
        "45k": 7,
        "50k": 11,
        "55k": 17,
        "60k": 12,
        "65k": 12,
        "70k": 10,
        "75k": 5,
        "80k": 11,
        "85k": 5,
        "90k": 5,
        "95k": 12,
        "100k": 9,
        "105k": 4,
        "110k": 4,
        "115k": 6,
        "120k": 6,
        "125k": 6,
        "130k": 3,
        "135k": 1,
        "140k": 4,
        "145k": 3,
        "150k": 9,
        "155k": 1,
        "160k": 5,
        "165k": 1,
        "170k": 1,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": "9"
      },
      houseLoitererfm: {
        "5k": 0,
        "10k": 7,
        "15k": 7,
        "20k": 6,
        "25k": 9,
        "30k": 3,
        "35k": 5,
        "40k": 4,
        "45k": 4,
        "50k": 1,
        "55k": 13,
        "60k": 3,
        "65k": 5,
        "70k": 4,
        "75k": 1,
        "80k": 4,
        "85k": 8,
        "90k": 3,
        "95k": 0,
        "100k": 1,
        "105k": 4,
        "110k": 2,
        "115k": 0,
        "120k": 1,
        "125k": 0,
        "130k": 1,
        "135k": 0,
        "140k": 2,
        "145k": 5,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      spousefm: {
        "5k": 1,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 1,
        "90k": 0,
        "95k": 3,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childfm: {
        "5k": 264,
        "10k": 248,
        "15k": 282,
        "20k": 401,
        "25k": 427,
        "30k": 487,
        "35k": 435,
        "40k": 423,
        "45k": 414,
        "50k": 398,
        "55k": 412,
        "60k": 335,
        "65k": 373,
        "70k": 344,
        "75k": 354,
        "80k": 343,
        "85k": 316,
        "90k": 322,
        "95k": 298,
        "100k": 254,
        "105k": 323,
        "110k": 241,
        "115k": 248,
        "120k": 170,
        "125k": 249,
        "130k": 156,
        "135k": 184,
        "140k": 153,
        "145k": 154,
        "150k": 113,
        "155k": 123,
        "160k": 119,
        "165k": 110,
        "170k": 103,
        "175k": 98,
        "180k": 63,
        "185k": 83,
        "190k": 73,
        "195k": 61,
        "200k": 63,
        "350k": "978"
      },
      otherRelativefm: {
        "5k": 22,
        "10k": 33,
        "15k": 44,
        "20k": 37,
        "25k": 59,
        "30k": 67,
        "35k": 45,
        "40k": 50,
        "45k": 48,
        "50k": 58,
        "55k": 49,
        "60k": 35,
        "65k": 54,
        "70k": 49,
        "75k": 23,
        "80k": 38,
        "85k": 26,
        "90k": 29,
        "95k": 15,
        "100k": 22,
        "105k": 19,
        "110k": 33,
        "115k": 22,
        "120k": 15,
        "125k": 11,
        "130k": 19,
        "135k": 17,
        "140k": 6,
        "145k": 7,
        "150k": 1,
        "155k": 7,
        "160k": 6,
        "165k": 7,
        "170k": 5,
        "175k": 4,
        "180k": 3,
        "185k": 5,
        "190k": 5,
        "195k": 4,
        "200k": 1,
        "350k": "38"
      },
      nonRelativefm: {
        "5k": 3,
        "10k": 3,
        "15k": 4,
        "20k": 4,
        "25k": 7,
        "30k": 5,
        "35k": 4,
        "40k": 12,
        "45k": 23,
        "50k": 11,
        "55k": 21,
        "60k": 15,
        "65k": 2,
        "70k": 8,
        "75k": 14,
        "80k": 10,
        "85k": 1,
        "90k": 3,
        "95k": 10,
        "100k": 6,
        "105k": 10,
        "110k": 5,
        "115k": 9,
        "120k": 6,
        "125k": 7,
        "130k": 3,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 5,
        "160k": 4,
        "165k": 4,
        "170k": 0,
        "175k": 0,
        "180k": 3,
        "185k": 0,
        "190k": 0,
        "195k": 1,
        "200k": 0,
        "350k": "8"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 82,
        "10k": 33,
        "15k": 73,
        "20k": 61,
        "25k": 43,
        "30k": 65,
        "35k": 78,
        "40k": 55,
        "45k": 57,
        "50k": 42,
        "55k": 32,
        "60k": 36,
        "65k": 29,
        "70k": 24,
        "75k": 25,
        "80k": 23,
        "85k": 15,
        "90k": 27,
        "95k": 26,
        "100k": 9,
        "105k": 17,
        "110k": 7,
        "115k": 3,
        "120k": 12,
        "125k": 5,
        "130k": 2,
        "135k": 9,
        "140k": 7,
        "145k": 10,
        "150k": 3,
        "155k": 0,
        "160k": 0,
        "165k": 5,
        "170k": 2,
        "175k": 3,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 5,
        "200k": 0,
        "350k": 14
      },
      spousem: {
        "5k": 8,
        "10k": 3,
        "15k": 6,
        "20k": 1,
        "25k": 11,
        "30k": 4,
        "35k": 2,
        "40k": 4,
        "45k": 1,
        "50k": 0,
        "55k": 4,
        "60k": 8,
        "65k": 2,
        "70k": 0,
        "75k": 1,
        "80k": 0,
        "85k": 3,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 2,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 2,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 110,
        "10k": 129,
        "15k": 110,
        "20k": 146,
        "25k": 185,
        "30k": 174,
        "35k": 193,
        "40k": 196,
        "45k": 201,
        "50k": 247,
        "55k": 253,
        "60k": 168,
        "65k": 181,
        "70k": 154,
        "75k": 153,
        "80k": 178,
        "85k": 151,
        "90k": 178,
        "95k": 193,
        "100k": 138,
        "105k": 174,
        "110k": 146,
        "115k": 109,
        "120k": 126,
        "125k": 155,
        "130k": 100,
        "135k": 136,
        "140k": 103,
        "145k": 108,
        "150k": 77,
        "155k": 99,
        "160k": 44,
        "165k": 89,
        "170k": 62,
        "175k": 57,
        "180k": 51,
        "185k": 47,
        "190k": 60,
        "195k": 53,
        "200k": 34,
        "350k": 588
      },
      otherRelativem: {
        "5k": 34,
        "10k": 21,
        "15k": 16,
        "20k": 50,
        "25k": 47,
        "30k": 34,
        "35k": 33,
        "40k": 37,
        "45k": 37,
        "50k": 35,
        "55k": 53,
        "60k": 36,
        "65k": 33,
        "70k": 33,
        "75k": 37,
        "80k": 35,
        "85k": 14,
        "90k": 31,
        "95k": 19,
        "100k": 11,
        "105k": 30,
        "110k": 19,
        "115k": 20,
        "120k": 14,
        "125k": 10,
        "130k": 14,
        "135k": 8,
        "140k": 1,
        "145k": 7,
        "150k": 17,
        "155k": 4,
        "160k": 2,
        "165k": 2,
        "170k": 8,
        "175k": 3,
        "180k": 1,
        "185k": 2,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 28
      },
      nonRelativem: {
        "5k": 41,
        "10k": 23,
        "15k": 32,
        "20k": 34,
        "25k": 17,
        "30k": 45,
        "35k": 41,
        "40k": 50,
        "45k": 38,
        "50k": 18,
        "55k": 31,
        "60k": 31,
        "65k": 44,
        "70k": 22,
        "75k": 31,
        "80k": 12,
        "85k": 15,
        "90k": 25,
        "95k": 8,
        "100k": 8,
        "105k": 7,
        "110k": 3,
        "115k": 8,
        "120k": 4,
        "125k": 18,
        "130k": 2,
        "135k": 13,
        "140k": 3,
        "145k": 2,
        "150k": 8,
        "155k": 14,
        "160k": 2,
        "165k": 11,
        "170k": 3,
        "175k": 5,
        "180k": 0,
        "185k": 4,
        "190k": 0,
        "195k": 1,
        "200k": 2,
        "350k": 28
      },
      houseLoitererfm: {
        "5k": 162,
        "10k": 95,
        "15k": 125,
        "20k": 94,
        "25k": 95,
        "30k": 83,
        "35k": 66,
        "40k": 68,
        "45k": 77,
        "50k": 33,
        "55k": 42,
        "60k": 29,
        "65k": 39,
        "70k": 30,
        "75k": 22,
        "80k": 14,
        "85k": 18,
        "90k": 4,
        "95k": 15,
        "100k": 2,
        "105k": 4,
        "110k": 2,
        "115k": 6,
        "120k": 2,
        "125k": 8,
        "130k": 2,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 2,
        "155k": 3,
        "160k": 0,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 3,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 7
      },
      spousefm: {
        "5k": 8,
        "10k": 2,
        "15k": 3,
        "20k": 5,
        "25k": 19,
        "30k": 8,
        "35k": 13,
        "40k": 19,
        "45k": 10,
        "50k": 17,
        "55k": 7,
        "60k": 8,
        "65k": 17,
        "70k": 12,
        "75k": 11,
        "80k": 6,
        "85k": 0,
        "90k": 1,
        "95k": 2,
        "100k": 3,
        "105k": 1,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 4,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      childfm: {
        "5k": 88,
        "10k": 74,
        "15k": 105,
        "20k": 132,
        "25k": 142,
        "30k": 202,
        "35k": 190,
        "40k": 177,
        "45k": 189,
        "50k": 162,
        "55k": 201,
        "60k": 156,
        "65k": 158,
        "70k": 141,
        "75k": 195,
        "80k": 171,
        "85k": 163,
        "90k": 156,
        "95k": 148,
        "100k": 126,
        "105k": 183,
        "110k": 109,
        "115k": 122,
        "120k": 110,
        "125k": 126,
        "130k": 93,
        "135k": 113,
        "140k": 91,
        "145k": 81,
        "150k": 81,
        "155k": 79,
        "160k": 32,
        "165k": 57,
        "170k": 47,
        "175k": 54,
        "180k": 35,
        "185k": 43,
        "190k": 35,
        "195k": 51,
        "200k": 41,
        "350k": 564
      },
      otherRelativefm: {
        "5k": 23,
        "10k": 17,
        "15k": 34,
        "20k": 32,
        "25k": 31,
        "30k": 42,
        "35k": 37,
        "40k": 29,
        "45k": 21,
        "50k": 42,
        "55k": 30,
        "60k": 18,
        "65k": 45,
        "70k": 33,
        "75k": 28,
        "80k": 27,
        "85k": 11,
        "90k": 15,
        "95k": 13,
        "100k": 13,
        "105k": 19,
        "110k": 10,
        "115k": 13,
        "120k": 25,
        "125k": 17,
        "130k": 9,
        "135k": 6,
        "140k": 12,
        "145k": 0,
        "150k": 1,
        "155k": 4,
        "160k": 10,
        "165k": 5,
        "170k": 5,
        "175k": 6,
        "180k": 7,
        "185k": 0,
        "190k": 0,
        "195k": 1,
        "200k": 2,
        "350k": 29
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 26,
        "15k": 30,
        "20k": 40,
        "25k": 49,
        "30k": 50,
        "35k": 68,
        "40k": 46,
        "45k": 55,
        "50k": 42,
        "55k": 30,
        "60k": 23,
        "65k": 25,
        "70k": 24,
        "75k": 12,
        "80k": 2,
        "85k": 8,
        "90k": 19,
        "95k": 23,
        "100k": 12,
        "105k": 13,
        "110k": 0,
        "115k": 4,
        "120k": 14,
        "125k": 16,
        "130k": 5,
        "135k": 14,
        "140k": 3,
        "145k": 2,
        "150k": 8,
        "155k": 4,
        "160k": 3,
        "165k": 14,
        "170k": 14,
        "175k": 6,
        "180k": 4,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 36
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 115,
        "10k": 39,
        "15k": 114,
        "20k": 90,
        "25k": 132,
        "30k": 135,
        "35k": 147,
        "40k": 136,
        "45k": 119,
        "50k": 95,
        "55k": 92,
        "60k": 97,
        "65k": 62,
        "70k": 75,
        "75k": 69,
        "80k": 49,
        "85k": 44,
        "90k": 37,
        "95k": 22,
        "100k": 20,
        "105k": 24,
        "110k": 25,
        "115k": 8,
        "120k": 8,
        "125k": 36,
        "130k": 31,
        "135k": 11,
        "140k": 10,
        "145k": 20,
        "150k": 7,
        "155k": 3,
        "160k": 11,
        "165k": 9,
        "170k": 4,
        "175k": 7,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 4,
        "200k": 1,
        "350k": 23
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 7,
        "20k": 9,
        "25k": 22,
        "30k": 24,
        "35k": 14,
        "40k": 12,
        "45k": 29,
        "50k": 11,
        "55k": 10,
        "60k": 13,
        "65k": 7,
        "70k": 4,
        "75k": 0,
        "80k": 15,
        "85k": 2,
        "90k": 1,
        "95k": 6,
        "100k": 2,
        "105k": 4,
        "110k": 3,
        "115k": 1,
        "120k": 0,
        "125k": 5,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 0,
        "165k": 0,
        "170k": 3,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 32,
        "10k": 42,
        "15k": 70,
        "20k": 63,
        "25k": 76,
        "30k": 76,
        "35k": 83,
        "40k": 98,
        "45k": 78,
        "50k": 78,
        "55k": 101,
        "60k": 90,
        "65k": 133,
        "70k": 75,
        "75k": 97,
        "80k": 107,
        "85k": 88,
        "90k": 74,
        "95k": 82,
        "100k": 83,
        "105k": 100,
        "110k": 84,
        "115k": 69,
        "120k": 42,
        "125k": 85,
        "130k": 63,
        "135k": 52,
        "140k": 30,
        "145k": 49,
        "150k": 45,
        "155k": 50,
        "160k": 39,
        "165k": 39,
        "170k": 34,
        "175k": 25,
        "180k": 26,
        "185k": 38,
        "190k": 24,
        "195k": 41,
        "200k": 26,
        "350k": 370
      },
      otherRelativem: {
        "5k": 7,
        "10k": 5,
        "15k": 19,
        "20k": 24,
        "25k": 24,
        "30k": 20,
        "35k": 17,
        "40k": 27,
        "45k": 34,
        "50k": 30,
        "55k": 15,
        "60k": 28,
        "65k": 47,
        "70k": 18,
        "75k": 37,
        "80k": 17,
        "85k": 25,
        "90k": 18,
        "95k": 20,
        "100k": 8,
        "105k": 18,
        "110k": 17,
        "115k": 14,
        "120k": 10,
        "125k": 10,
        "130k": 16,
        "135k": 8,
        "140k": 7,
        "145k": 5,
        "150k": 5,
        "155k": 6,
        "160k": 2,
        "165k": 0,
        "170k": 9,
        "175k": 9,
        "180k": 3,
        "185k": 4,
        "190k": 1,
        "195k": 5,
        "200k": 1,
        "350k": 22
      },
      nonRelativem: {
        "5k": 48,
        "10k": 7,
        "15k": 40,
        "20k": 32,
        "25k": 34,
        "30k": 47,
        "35k": 66,
        "40k": 40,
        "45k": 45,
        "50k": 53,
        "55k": 37,
        "60k": 66,
        "65k": 44,
        "70k": 45,
        "75k": 60,
        "80k": 17,
        "85k": 13,
        "90k": 21,
        "95k": 17,
        "100k": 9,
        "105k": 15,
        "110k": 23,
        "115k": 18,
        "120k": 18,
        "125k": 35,
        "130k": 17,
        "135k": 40,
        "140k": 12,
        "145k": 4,
        "150k": 8,
        "155k": 1,
        "160k": 13,
        "165k": 13,
        "170k": 2,
        "175k": 6,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 1,
        "200k": 2,
        "350k": 58
      },
      houseLoitererfm: {
        "5k": 192,
        "10k": 134,
        "15k": 178,
        "20k": 166,
        "25k": 231,
        "30k": 147,
        "35k": 159,
        "40k": 108,
        "45k": 99,
        "50k": 105,
        "55k": 73,
        "60k": 73,
        "65k": 80,
        "70k": 51,
        "75k": 53,
        "80k": 38,
        "85k": 28,
        "90k": 19,
        "95k": 33,
        "100k": 18,
        "105k": 18,
        "110k": 16,
        "115k": 7,
        "120k": 10,
        "125k": 12,
        "130k": 6,
        "135k": 17,
        "140k": 7,
        "145k": 9,
        "150k": 1,
        "155k": 10,
        "160k": 8,
        "165k": 0,
        "170k": 3,
        "175k": 0,
        "180k": 6,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 15
      },
      spousefm: {
        "5k": 13,
        "10k": 4,
        "15k": 29,
        "20k": 27,
        "25k": 20,
        "30k": 45,
        "35k": 46,
        "40k": 47,
        "45k": 49,
        "50k": 54,
        "55k": 43,
        "60k": 19,
        "65k": 23,
        "70k": 29,
        "75k": 36,
        "80k": 10,
        "85k": 12,
        "90k": 13,
        "95k": 10,
        "100k": 3,
        "105k": 10,
        "110k": 4,
        "115k": 1,
        "120k": 2,
        "125k": 3,
        "130k": 2,
        "135k": 3,
        "140k": 6,
        "145k": 1,
        "150k": 2,
        "155k": 5,
        "160k": 1,
        "165k": 2,
        "170k": 1,
        "175k": 0,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      childfm: {
        "5k": 25,
        "10k": 31,
        "15k": 37,
        "20k": 48,
        "25k": 80,
        "30k": 62,
        "35k": 64,
        "40k": 68,
        "45k": 51,
        "50k": 73,
        "55k": 69,
        "60k": 96,
        "65k": 56,
        "70k": 70,
        "75k": 91,
        "80k": 73,
        "85k": 65,
        "90k": 92,
        "95k": 71,
        "100k": 66,
        "105k": 84,
        "110k": 91,
        "115k": 78,
        "120k": 48,
        "125k": 69,
        "130k": 50,
        "135k": 53,
        "140k": 56,
        "145k": 45,
        "150k": 34,
        "155k": 28,
        "160k": 34,
        "165k": 26,
        "170k": 30,
        "175k": 25,
        "180k": 44,
        "185k": 24,
        "190k": 28,
        "195k": 17,
        "200k": 28,
        "350k": 308
      },
      otherRelativefm: {
        "5k": 20,
        "10k": 20,
        "15k": 11,
        "20k": 36,
        "25k": 12,
        "30k": 20,
        "35k": 10,
        "40k": 18,
        "45k": 23,
        "50k": 34,
        "55k": 28,
        "60k": 19,
        "65k": 18,
        "70k": 10,
        "75k": 12,
        "80k": 22,
        "85k": 13,
        "90k": 16,
        "95k": 10,
        "100k": 15,
        "105k": 17,
        "110k": 7,
        "115k": 6,
        "120k": 10,
        "125k": 6,
        "130k": 13,
        "135k": 6,
        "140k": 6,
        "145k": 3,
        "150k": 10,
        "155k": 3,
        "160k": 1,
        "165k": 7,
        "170k": 2,
        "175k": 0,
        "180k": 6,
        "185k": 0,
        "190k": 6,
        "195k": 7,
        "200k": 2,
        "350k": 21
      },
      nonRelativefm: {
        "5k": 49,
        "10k": 27,
        "15k": 47,
        "20k": 73,
        "25k": 62,
        "30k": 45,
        "35k": 47,
        "40k": 58,
        "45k": 39,
        "50k": 73,
        "55k": 51,
        "60k": 50,
        "65k": 52,
        "70k": 44,
        "75k": 50,
        "80k": 46,
        "85k": 59,
        "90k": 19,
        "95k": 43,
        "100k": 17,
        "105k": 11,
        "110k": 20,
        "115k": 13,
        "120k": 11,
        "125k": 12,
        "130k": 7,
        "135k": 28,
        "140k": 10,
        "145k": 8,
        "150k": 5,
        "155k": 12,
        "160k": 4,
        "165k": 7,
        "170k": 6,
        "175k": 0,
        "180k": 0,
        "185k": 3,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 35
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 162,
        "10k": 73,
        "15k": 119,
        "20k": 177,
        "25k": 212,
        "30k": 232,
        "35k": 273,
        "40k": 231,
        "45k": 252,
        "50k": 268,
        "55k": 269,
        "60k": 212,
        "65k": 219,
        "70k": 172,
        "75k": 170,
        "80k": 134,
        "85k": 139,
        "90k": 121,
        "95k": 106,
        "100k": 99,
        "105k": 98,
        "110k": 108,
        "115k": 83,
        "120k": 55,
        "125k": 72,
        "130k": 44,
        "135k": 46,
        "140k": 26,
        "145k": 45,
        "150k": 39,
        "155k": 42,
        "160k": 28,
        "165k": 45,
        "170k": 20,
        "175k": 32,
        "180k": 11,
        "185k": 10,
        "190k": 11,
        "195k": 18,
        "200k": 11,
        "350k": 147
      },
      spousem: {
        "5k": 27,
        "10k": 9,
        "15k": 32,
        "20k": 25,
        "25k": 42,
        "30k": 54,
        "35k": 70,
        "40k": 65,
        "45k": 58,
        "50k": 61,
        "55k": 49,
        "60k": 55,
        "65k": 47,
        "70k": 55,
        "75k": 74,
        "80k": 40,
        "85k": 24,
        "90k": 22,
        "95k": 40,
        "100k": 22,
        "105k": 34,
        "110k": 18,
        "115k": 10,
        "120k": 17,
        "125k": 14,
        "130k": 21,
        "135k": 15,
        "140k": 11,
        "145k": 13,
        "150k": 13,
        "155k": 0,
        "160k": 3,
        "165k": 3,
        "170k": 4,
        "175k": 2,
        "180k": 4,
        "185k": 2,
        "190k": 2,
        "195k": 0,
        "200k": 6,
        "350k": 27
      },
      childm: {
        "5k": 33,
        "10k": 22,
        "15k": 39,
        "20k": 45,
        "25k": 62,
        "30k": 47,
        "35k": 79,
        "40k": 116,
        "45k": 58,
        "50k": 73,
        "55k": 89,
        "60k": 78,
        "65k": 80,
        "70k": 81,
        "75k": 81,
        "80k": 63,
        "85k": 82,
        "90k": 71,
        "95k": 91,
        "100k": 48,
        "105k": 70,
        "110k": 56,
        "115k": 130,
        "120k": 55,
        "125k": 71,
        "130k": 44,
        "135k": 62,
        "140k": 54,
        "145k": 47,
        "150k": 38,
        "155k": 32,
        "160k": 46,
        "165k": 42,
        "170k": 48,
        "175k": 26,
        "180k": 28,
        "185k": 26,
        "190k": 24,
        "195k": 19,
        "200k": 37,
        "350k": 370
      },
      otherRelativem: {
        "5k": 10,
        "10k": 7,
        "15k": 14,
        "20k": 24,
        "25k": 35,
        "30k": 22,
        "35k": 37,
        "40k": 51,
        "45k": 41,
        "50k": 60,
        "55k": 42,
        "60k": 26,
        "65k": 38,
        "70k": 18,
        "75k": 26,
        "80k": 29,
        "85k": 24,
        "90k": 33,
        "95k": 30,
        "100k": 7,
        "105k": 30,
        "110k": 17,
        "115k": 17,
        "120k": 16,
        "125k": 20,
        "130k": 14,
        "135k": 15,
        "140k": 13,
        "145k": 11,
        "150k": 9,
        "155k": 9,
        "160k": 6,
        "165k": 2,
        "170k": 6,
        "175k": 3,
        "180k": 3,
        "185k": 2,
        "190k": 0,
        "195k": 9,
        "200k": 7,
        "350k": 40
      },
      nonRelativem: {
        "5k": 43,
        "10k": 23,
        "15k": 17,
        "20k": 39,
        "25k": 53,
        "30k": 48,
        "35k": 49,
        "40k": 68,
        "45k": 94,
        "50k": 99,
        "55k": 87,
        "60k": 67,
        "65k": 92,
        "70k": 71,
        "75k": 50,
        "80k": 82,
        "85k": 77,
        "90k": 71,
        "95k": 48,
        "100k": 28,
        "105k": 30,
        "110k": 35,
        "115k": 33,
        "120k": 38,
        "125k": 30,
        "130k": 10,
        "135k": 38,
        "140k": 9,
        "145k": 27,
        "150k": 9,
        "155k": 38,
        "160k": 14,
        "165k": 19,
        "170k": 24,
        "175k": 19,
        "180k": 7,
        "185k": 12,
        "190k": 13,
        "195k": 10,
        "200k": 10,
        "350k": 131
      },
      houseLoitererfm: {
        "5k": 273,
        "10k": 232,
        "15k": 255,
        "20k": 289,
        "25k": 335,
        "30k": 296,
        "35k": 292,
        "40k": 278,
        "45k": 208,
        "50k": 232,
        "55k": 243,
        "60k": 191,
        "65k": 189,
        "70k": 173,
        "75k": 147,
        "80k": 130,
        "85k": 85,
        "90k": 78,
        "95k": 83,
        "100k": 66,
        "105k": 85,
        "110k": 70,
        "115k": 47,
        "120k": 39,
        "125k": 45,
        "130k": 34,
        "135k": 44,
        "140k": 29,
        "145k": 14,
        "150k": 27,
        "155k": 33,
        "160k": 15,
        "165k": 10,
        "170k": 19,
        "175k": 13,
        "180k": 11,
        "185k": 11,
        "190k": 3,
        "195k": 6,
        "200k": 5,
        "350k": 85
      },
      spousefm: {
        "5k": 40,
        "10k": 18,
        "15k": 44,
        "20k": 60,
        "25k": 81,
        "30k": 84,
        "35k": 77,
        "40k": 95,
        "45k": 106,
        "50k": 116,
        "55k": 127,
        "60k": 122,
        "65k": 123,
        "70k": 99,
        "75k": 89,
        "80k": 95,
        "85k": 108,
        "90k": 71,
        "95k": 66,
        "100k": 71,
        "105k": 69,
        "110k": 65,
        "115k": 67,
        "120k": 43,
        "125k": 69,
        "130k": 45,
        "135k": 31,
        "140k": 19,
        "145k": 35,
        "150k": 22,
        "155k": 28,
        "160k": 22,
        "165k": 23,
        "170k": 12,
        "175k": 30,
        "180k": 4,
        "185k": 12,
        "190k": 5,
        "195k": 7,
        "200k": 1,
        "350k": 63
      },
      childfm: {
        "5k": 10,
        "10k": 23,
        "15k": 26,
        "20k": 28,
        "25k": 47,
        "30k": 43,
        "35k": 47,
        "40k": 68,
        "45k": 38,
        "50k": 83,
        "55k": 68,
        "60k": 68,
        "65k": 75,
        "70k": 65,
        "75k": 55,
        "80k": 54,
        "85k": 33,
        "90k": 64,
        "95k": 44,
        "100k": 59,
        "105k": 39,
        "110k": 49,
        "115k": 40,
        "120k": 47,
        "125k": 22,
        "130k": 39,
        "135k": 46,
        "140k": 22,
        "145k": 33,
        "150k": 24,
        "155k": 30,
        "160k": 41,
        "165k": 19,
        "170k": 28,
        "175k": 24,
        "180k": 20,
        "185k": 12,
        "190k": 12,
        "195k": 17,
        "200k": 16,
        "350k": 187
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 6,
        "15k": 3,
        "20k": 17,
        "25k": 20,
        "30k": 21,
        "35k": 29,
        "40k": 18,
        "45k": 38,
        "50k": 27,
        "55k": 18,
        "60k": 15,
        "65k": 22,
        "70k": 31,
        "75k": 17,
        "80k": 15,
        "85k": 14,
        "90k": 26,
        "95k": 17,
        "100k": 22,
        "105k": 8,
        "110k": 10,
        "115k": 18,
        "120k": 10,
        "125k": 10,
        "130k": 12,
        "135k": 8,
        "140k": 9,
        "145k": 8,
        "150k": 9,
        "155k": 1,
        "160k": 0,
        "165k": 3,
        "170k": 3,
        "175k": 2,
        "180k": 10,
        "185k": 2,
        "190k": 7,
        "195k": 3,
        "200k": 4,
        "350k": 26
      },
      nonRelativefm: {
        "5k": 30,
        "10k": 23,
        "15k": 28,
        "20k": 25,
        "25k": 55,
        "30k": 42,
        "35k": 75,
        "40k": 58,
        "45k": 77,
        "50k": 63,
        "55k": 54,
        "60k": 65,
        "65k": 77,
        "70k": 67,
        "75k": 51,
        "80k": 48,
        "85k": 52,
        "90k": 47,
        "95k": 63,
        "100k": 26,
        "105k": 64,
        "110k": 37,
        "115k": 43,
        "120k": 27,
        "125k": 32,
        "130k": 24,
        "135k": 24,
        "140k": 12,
        "145k": 10,
        "150k": 10,
        "155k": 7,
        "160k": 14,
        "165k": 19,
        "170k": 6,
        "175k": 25,
        "180k": 1,
        "185k": 5,
        "190k": 14,
        "195k": 11,
        "200k": 24,
        "350k": 49
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 123,
        "10k": 99,
        "15k": 114,
        "20k": 130,
        "25k": 219,
        "30k": 201,
        "35k": 223,
        "40k": 224,
        "45k": 284,
        "50k": 259,
        "55k": 295,
        "60k": 220,
        "65k": 272,
        "70k": 203,
        "75k": 202,
        "80k": 227,
        "85k": 188,
        "90k": 187,
        "95k": 114,
        "100k": 145,
        "105k": 210,
        "110k": 92,
        "115k": 103,
        "120k": 92,
        "125k": 118,
        "130k": 80,
        "135k": 77,
        "140k": 49,
        "145k": 47,
        "150k": 61,
        "155k": 70,
        "160k": 43,
        "165k": 38,
        "170k": 43,
        "175k": 41,
        "180k": 28,
        "185k": 22,
        "190k": 31,
        "195k": 25,
        "200k": 9,
        "350k": 208
      },
      spousem: {
        "5k": 27,
        "10k": 31,
        "15k": 24,
        "20k": 60,
        "25k": 62,
        "30k": 54,
        "35k": 87,
        "40k": 93,
        "45k": 69,
        "50k": 80,
        "55k": 101,
        "60k": 87,
        "65k": 96,
        "70k": 89,
        "75k": 87,
        "80k": 84,
        "85k": 108,
        "90k": 72,
        "95k": 77,
        "100k": 69,
        "105k": 60,
        "110k": 52,
        "115k": 51,
        "120k": 45,
        "125k": 49,
        "130k": 38,
        "135k": 28,
        "140k": 38,
        "145k": 12,
        "150k": 34,
        "155k": 18,
        "160k": 17,
        "165k": 11,
        "170k": 21,
        "175k": 11,
        "180k": 10,
        "185k": 11,
        "190k": 13,
        "195k": 11,
        "200k": 6,
        "350k": 108
      },
      childm: {
        "5k": 14,
        "10k": 18,
        "15k": 12,
        "20k": 31,
        "25k": 49,
        "30k": 13,
        "35k": 55,
        "40k": 46,
        "45k": 35,
        "50k": 46,
        "55k": 57,
        "60k": 64,
        "65k": 39,
        "70k": 40,
        "75k": 38,
        "80k": 35,
        "85k": 45,
        "90k": 39,
        "95k": 32,
        "100k": 29,
        "105k": 19,
        "110k": 36,
        "115k": 24,
        "120k": 29,
        "125k": 33,
        "130k": 14,
        "135k": 42,
        "140k": 27,
        "145k": 19,
        "150k": 10,
        "155k": 17,
        "160k": 21,
        "165k": 13,
        "170k": 8,
        "175k": 27,
        "180k": 18,
        "185k": 10,
        "190k": 5,
        "195k": 11,
        "200k": 8,
        "350k": 103
      },
      otherRelativem: {
        "5k": 16,
        "10k": 5,
        "15k": 9,
        "20k": 8,
        "25k": 30,
        "30k": 30,
        "35k": 23,
        "40k": 25,
        "45k": 24,
        "50k": 23,
        "55k": 27,
        "60k": 7,
        "65k": 25,
        "70k": 17,
        "75k": 19,
        "80k": 12,
        "85k": 19,
        "90k": 23,
        "95k": 26,
        "100k": 13,
        "105k": 19,
        "110k": 20,
        "115k": 13,
        "120k": 19,
        "125k": 12,
        "130k": 6,
        "135k": 3,
        "140k": 4,
        "145k": 6,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 4,
        "175k": 7,
        "180k": 6,
        "185k": 0,
        "190k": 3,
        "195k": 1,
        "200k": 6,
        "350k": 28
      },
      nonRelativem: {
        "5k": 20,
        "10k": 28,
        "15k": 33,
        "20k": 54,
        "25k": 32,
        "30k": 46,
        "35k": 32,
        "40k": 44,
        "45k": 46,
        "50k": 49,
        "55k": 54,
        "60k": 45,
        "65k": 52,
        "70k": 37,
        "75k": 30,
        "80k": 37,
        "85k": 35,
        "90k": 38,
        "95k": 45,
        "100k": 25,
        "105k": 13,
        "110k": 46,
        "115k": 21,
        "120k": 28,
        "125k": 13,
        "130k": 15,
        "135k": 20,
        "140k": 18,
        "145k": 14,
        "150k": 13,
        "155k": 16,
        "160k": 9,
        "165k": 12,
        "170k": 10,
        "175k": 10,
        "180k": 0,
        "185k": 6,
        "190k": 7,
        "195k": 7,
        "200k": 3,
        "350k": 86
      },
      houseLoitererfm: {
        "5k": 259,
        "10k": 185,
        "15k": 250,
        "20k": 279,
        "25k": 319,
        "30k": 284,
        "35k": 294,
        "40k": 243,
        "45k": 189,
        "50k": 246,
        "55k": 211,
        "60k": 214,
        "65k": 195,
        "70k": 189,
        "75k": 156,
        "80k": 176,
        "85k": 168,
        "90k": 112,
        "95k": 126,
        "100k": 99,
        "105k": 84,
        "110k": 92,
        "115k": 76,
        "120k": 68,
        "125k": 78,
        "130k": 66,
        "135k": 42,
        "140k": 56,
        "145k": 26,
        "150k": 53,
        "155k": 36,
        "160k": 27,
        "165k": 26,
        "170k": 33,
        "175k": 36,
        "180k": 27,
        "185k": 24,
        "190k": 22,
        "195k": 19,
        "200k": 26,
        "350k": 192
      },
      spousefm: {
        "5k": 43,
        "10k": 20,
        "15k": 62,
        "20k": 40,
        "25k": 82,
        "30k": 110,
        "35k": 101,
        "40k": 98,
        "45k": 164,
        "50k": 116,
        "55k": 147,
        "60k": 137,
        "65k": 139,
        "70k": 123,
        "75k": 119,
        "80k": 154,
        "85k": 105,
        "90k": 130,
        "95k": 89,
        "100k": 107,
        "105k": 144,
        "110k": 63,
        "115k": 107,
        "120k": 63,
        "125k": 93,
        "130k": 67,
        "135k": 53,
        "140k": 55,
        "145k": 52,
        "150k": 48,
        "155k": 55,
        "160k": 37,
        "165k": 27,
        "170k": 35,
        "175k": 41,
        "180k": 33,
        "185k": 25,
        "190k": 28,
        "195k": 21,
        "200k": 8,
        "350k": 220
      },
      childfm: {
        "5k": 11,
        "10k": 15,
        "15k": 21,
        "20k": 30,
        "25k": 22,
        "30k": 22,
        "35k": 14,
        "40k": 22,
        "45k": 34,
        "50k": 38,
        "55k": 36,
        "60k": 26,
        "65k": 40,
        "70k": 26,
        "75k": 29,
        "80k": 23,
        "85k": 20,
        "90k": 22,
        "95k": 21,
        "100k": 31,
        "105k": 24,
        "110k": 15,
        "115k": 25,
        "120k": 34,
        "125k": 20,
        "130k": 20,
        "135k": 23,
        "140k": 10,
        "145k": 19,
        "150k": 6,
        "155k": 10,
        "160k": 15,
        "165k": 5,
        "170k": 5,
        "175k": 26,
        "180k": 9,
        "185k": 1,
        "190k": 4,
        "195k": 8,
        "200k": 3,
        "350k": 67
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 10,
        "15k": 10,
        "20k": 6,
        "25k": 14,
        "30k": 10,
        "35k": 9,
        "40k": 14,
        "45k": 23,
        "50k": 14,
        "55k": 17,
        "60k": 16,
        "65k": 24,
        "70k": 21,
        "75k": 15,
        "80k": 15,
        "85k": 6,
        "90k": 5,
        "95k": 9,
        "100k": 5,
        "105k": 6,
        "110k": 4,
        "115k": 7,
        "120k": 5,
        "125k": 9,
        "130k": 7,
        "135k": 2,
        "140k": 7,
        "145k": 3,
        "150k": 6,
        "155k": 3,
        "160k": 2,
        "165k": 7,
        "170k": 10,
        "175k": 11,
        "180k": 5,
        "185k": 2,
        "190k": 3,
        "195k": 0,
        "200k": 4,
        "350k": 26
      },
      nonRelativefm: {
        "5k": 8,
        "10k": 14,
        "15k": 13,
        "20k": 19,
        "25k": 12,
        "30k": 24,
        "35k": 43,
        "40k": 34,
        "45k": 39,
        "50k": 33,
        "55k": 28,
        "60k": 27,
        "65k": 46,
        "70k": 29,
        "75k": 51,
        "80k": 39,
        "85k": 13,
        "90k": 33,
        "95k": 19,
        "100k": 15,
        "105k": 23,
        "110k": 18,
        "115k": 18,
        "120k": 13,
        "125k": 10,
        "130k": 18,
        "135k": 19,
        "140k": 14,
        "145k": 3,
        "150k": 4,
        "155k": 3,
        "160k": 6,
        "165k": 1,
        "170k": 8,
        "175k": 6,
        "180k": 8,
        "185k": 7,
        "190k": 10,
        "195k": 2,
        "200k": 4,
        "350k": 54
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 222,
        "10k": 157,
        "15k": 256,
        "20k": 318,
        "25k": 370,
        "30k": 380,
        "35k": 404,
        "40k": 459,
        "45k": 485,
        "50k": 385,
        "55k": 426,
        "60k": 388,
        "65k": 436,
        "70k": 333,
        "75k": 403,
        "80k": 338,
        "85k": 335,
        "90k": 315,
        "95k": 350,
        "100k": 268,
        "105k": 331,
        "110k": 219,
        "115k": 254,
        "120k": 189,
        "125k": 232,
        "130k": 171,
        "135k": 217,
        "140k": 156,
        "145k": 163,
        "150k": 110,
        "155k": 143,
        "160k": 91,
        "165k": 120,
        "170k": 81,
        "175k": 75,
        "180k": 90,
        "185k": 79,
        "190k": 68,
        "195k": 40,
        "200k": 56,
        "350k": 874
      },
      spousem: {
        "5k": 52,
        "10k": 47,
        "15k": 46,
        "20k": 87,
        "25k": 133,
        "30k": 148,
        "35k": 159,
        "40k": 160,
        "45k": 165,
        "50k": 176,
        "55k": 209,
        "60k": 159,
        "65k": 195,
        "70k": 166,
        "75k": 174,
        "80k": 211,
        "85k": 216,
        "90k": 133,
        "95k": 164,
        "100k": 154,
        "105k": 158,
        "110k": 129,
        "115k": 149,
        "120k": 108,
        "125k": 122,
        "130k": 98,
        "135k": 102,
        "140k": 92,
        "145k": 83,
        "150k": 62,
        "155k": 87,
        "160k": 51,
        "165k": 39,
        "170k": 46,
        "175k": 44,
        "180k": 45,
        "185k": 52,
        "190k": 27,
        "195k": 38,
        "200k": 32,
        "350k": 491
      },
      childm: {
        "5k": 28,
        "10k": 25,
        "15k": 41,
        "20k": 50,
        "25k": 41,
        "30k": 64,
        "35k": 51,
        "40k": 96,
        "45k": 62,
        "50k": 77,
        "55k": 89,
        "60k": 50,
        "65k": 58,
        "70k": 66,
        "75k": 64,
        "80k": 57,
        "85k": 34,
        "90k": 48,
        "95k": 26,
        "100k": 38,
        "105k": 19,
        "110k": 23,
        "115k": 21,
        "120k": 36,
        "125k": 35,
        "130k": 7,
        "135k": 28,
        "140k": 25,
        "145k": 16,
        "150k": 22,
        "155k": 13,
        "160k": 2,
        "165k": 13,
        "170k": 9,
        "175k": 15,
        "180k": 6,
        "185k": 8,
        "190k": 11,
        "195k": 3,
        "200k": 9,
        "350k": 58
      },
      otherRelativem: {
        "5k": 4,
        "10k": 15,
        "15k": 14,
        "20k": 15,
        "25k": 23,
        "30k": 31,
        "35k": 53,
        "40k": 29,
        "45k": 65,
        "50k": 52,
        "55k": 23,
        "60k": 27,
        "65k": 44,
        "70k": 49,
        "75k": 25,
        "80k": 25,
        "85k": 24,
        "90k": 30,
        "95k": 24,
        "100k": 32,
        "105k": 29,
        "110k": 19,
        "115k": 23,
        "120k": 14,
        "125k": 13,
        "130k": 18,
        "135k": 18,
        "140k": 11,
        "145k": 10,
        "150k": 4,
        "155k": 5,
        "160k": 5,
        "165k": 6,
        "170k": 6,
        "175k": 2,
        "180k": 6,
        "185k": 4,
        "190k": 4,
        "195k": 3,
        "200k": 3,
        "350k": 48
      },
      nonRelativem: {
        "5k": 23,
        "10k": 28,
        "15k": 38,
        "20k": 74,
        "25k": 75,
        "30k": 40,
        "35k": 84,
        "40k": 87,
        "45k": 42,
        "50k": 75,
        "55k": 76,
        "60k": 82,
        "65k": 61,
        "70k": 67,
        "75k": 52,
        "80k": 38,
        "85k": 39,
        "90k": 31,
        "95k": 67,
        "100k": 37,
        "105k": 50,
        "110k": 42,
        "115k": 14,
        "120k": 23,
        "125k": 18,
        "130k": 21,
        "135k": 17,
        "140k": 6,
        "145k": 13,
        "150k": 25,
        "155k": 18,
        "160k": 12,
        "165k": 15,
        "170k": 11,
        "175k": 7,
        "180k": 4,
        "185k": 5,
        "190k": 5,
        "195k": 2,
        "200k": 5,
        "350k": 94
      },
      houseLoitererfm: {
        "5k": 400,
        "10k": 324,
        "15k": 356,
        "20k": 474,
        "25k": 496,
        "30k": 540,
        "35k": 496,
        "40k": 485,
        "45k": 419,
        "50k": 439,
        "55k": 429,
        "60k": 337,
        "65k": 409,
        "70k": 307,
        "75k": 347,
        "80k": 292,
        "85k": 310,
        "90k": 239,
        "95k": 265,
        "100k": 229,
        "105k": 256,
        "110k": 190,
        "115k": 181,
        "120k": 156,
        "125k": 150,
        "130k": 161,
        "135k": 119,
        "140k": 114,
        "145k": 99,
        "150k": 72,
        "155k": 89,
        "160k": 68,
        "165k": 74,
        "170k": 61,
        "175k": 57,
        "180k": 48,
        "185k": 74,
        "190k": 51,
        "195k": 52,
        "200k": 37,
        "350k": 633
      },
      spousefm: {
        "5k": 51,
        "10k": 38,
        "15k": 74,
        "20k": 105,
        "25k": 168,
        "30k": 151,
        "35k": 201,
        "40k": 183,
        "45k": 212,
        "50k": 199,
        "55k": 204,
        "60k": 170,
        "65k": 232,
        "70k": 240,
        "75k": 232,
        "80k": 225,
        "85k": 262,
        "90k": 216,
        "95k": 248,
        "100k": 217,
        "105k": 292,
        "110k": 220,
        "115k": 199,
        "120k": 180,
        "125k": 201,
        "130k": 157,
        "135k": 204,
        "140k": 125,
        "145k": 143,
        "150k": 97,
        "155k": 148,
        "160k": 97,
        "165k": 123,
        "170k": 76,
        "175k": 76,
        "180k": 68,
        "185k": 71,
        "190k": 65,
        "195k": 47,
        "200k": 57,
        "350k": 866
      },
      childfm: {
        "5k": 13,
        "10k": 9,
        "15k": 29,
        "20k": 21,
        "25k": 45,
        "30k": 45,
        "35k": 44,
        "40k": 55,
        "45k": 33,
        "50k": 53,
        "55k": 52,
        "60k": 29,
        "65k": 45,
        "70k": 28,
        "75k": 23,
        "80k": 44,
        "85k": 20,
        "90k": 32,
        "95k": 18,
        "100k": 25,
        "105k": 20,
        "110k": 28,
        "115k": 16,
        "120k": 16,
        "125k": 17,
        "130k": 11,
        "135k": 18,
        "140k": 5,
        "145k": 14,
        "150k": 6,
        "155k": 2,
        "160k": 13,
        "165k": 4,
        "170k": 13,
        "175k": 2,
        "180k": 5,
        "185k": 7,
        "190k": 2,
        "195k": 1,
        "200k": 2,
        "350k": 72
      },
      otherRelativefm: {
        "5k": 24,
        "10k": 22,
        "15k": 24,
        "20k": 9,
        "25k": 39,
        "30k": 26,
        "35k": 31,
        "40k": 29,
        "45k": 31,
        "50k": 53,
        "55k": 46,
        "60k": 22,
        "65k": 28,
        "70k": 32,
        "75k": 39,
        "80k": 31,
        "85k": 23,
        "90k": 30,
        "95k": 18,
        "100k": 28,
        "105k": 23,
        "110k": 10,
        "115k": 15,
        "120k": 13,
        "125k": 9,
        "130k": 11,
        "135k": 11,
        "140k": 15,
        "145k": 9,
        "150k": 3,
        "155k": 3,
        "160k": 3,
        "165k": 8,
        "170k": 4,
        "175k": 2,
        "180k": 4,
        "185k": 4,
        "190k": 2,
        "195k": 2,
        "200k": 4,
        "350k": 43
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 20,
        "15k": 23,
        "20k": 36,
        "25k": 44,
        "30k": 44,
        "35k": 24,
        "40k": 39,
        "45k": 53,
        "50k": 64,
        "55k": 47,
        "60k": 47,
        "65k": 28,
        "70k": 47,
        "75k": 40,
        "80k": 29,
        "85k": 30,
        "90k": 39,
        "95k": 40,
        "100k": 34,
        "105k": 28,
        "110k": 34,
        "115k": 18,
        "120k": 19,
        "125k": 17,
        "130k": 19,
        "135k": 12,
        "140k": 8,
        "145k": 12,
        "150k": 24,
        "155k": 8,
        "160k": 14,
        "165k": 6,
        "170k": 6,
        "175k": 13,
        "180k": 11,
        "185k": 12,
        "190k": 12,
        "195k": 5,
        "200k": 2,
        "350k": 86
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 323,
        "10k": 294,
        "15k": 322,
        "20k": 351,
        "25k": 419,
        "30k": 395,
        "35k": 450,
        "40k": 380,
        "45k": 427,
        "50k": 450,
        "55k": 440,
        "60k": 377,
        "65k": 450,
        "70k": 372,
        "75k": 348,
        "80k": 367,
        "85k": 370,
        "90k": 326,
        "95k": 331,
        "100k": 264,
        "105k": 433,
        "110k": 278,
        "115k": 273,
        "120k": 197,
        "125k": 253,
        "130k": 203,
        "135k": 224,
        "140k": 170,
        "145k": 215,
        "150k": 155,
        "155k": 188,
        "160k": 141,
        "165k": 145,
        "170k": 109,
        "175k": 100,
        "180k": 100,
        "185k": 123,
        "190k": 99,
        "195k": 72,
        "200k": 68,
        "350k": "1,291"
      },
      spousem: {
        "5k": 35,
        "10k": 42,
        "15k": 53,
        "20k": 89,
        "25k": 96,
        "30k": 139,
        "35k": 149,
        "40k": 107,
        "45k": 145,
        "50k": 163,
        "55k": 172,
        "60k": 159,
        "65k": 161,
        "70k": 181,
        "75k": 194,
        "80k": 173,
        "85k": 179,
        "90k": 164,
        "95k": 150,
        "100k": 150,
        "105k": 189,
        "110k": 125,
        "115k": 131,
        "120k": 127,
        "125k": 151,
        "130k": 115,
        "135k": 128,
        "140k": 98,
        "145k": 109,
        "150k": 87,
        "155k": 100,
        "160k": 102,
        "165k": 100,
        "170k": 61,
        "175k": 62,
        "180k": 53,
        "185k": 51,
        "190k": 44,
        "195k": 51,
        "200k": 49,
        "350k": "674"
      },
      childm: {
        "5k": 23,
        "10k": 26,
        "15k": 53,
        "20k": 78,
        "25k": 62,
        "30k": 66,
        "35k": 86,
        "40k": 49,
        "45k": 55,
        "50k": 96,
        "55k": 70,
        "60k": 44,
        "65k": 36,
        "70k": 18,
        "75k": 33,
        "80k": 40,
        "85k": 17,
        "90k": 37,
        "95k": 20,
        "100k": 23,
        "105k": 12,
        "110k": 14,
        "115k": 11,
        "120k": 11,
        "125k": 8,
        "130k": 21,
        "135k": 8,
        "140k": 18,
        "145k": 9,
        "150k": 4,
        "155k": 18,
        "160k": 5,
        "165k": 1,
        "170k": 14,
        "175k": 7,
        "180k": 4,
        "185k": 3,
        "190k": 3,
        "195k": 0,
        "200k": 0,
        "350k": "23"
      },
      otherRelativem: {
        "5k": 21,
        "10k": 18,
        "15k": 25,
        "20k": 29,
        "25k": 29,
        "30k": 49,
        "35k": 42,
        "40k": 58,
        "45k": 48,
        "50k": 58,
        "55k": 40,
        "60k": 35,
        "65k": 63,
        "70k": 46,
        "75k": 42,
        "80k": 30,
        "85k": 28,
        "90k": 40,
        "95k": 30,
        "100k": 21,
        "105k": 34,
        "110k": 26,
        "115k": 34,
        "120k": 40,
        "125k": 7,
        "130k": 6,
        "135k": 17,
        "140k": 16,
        "145k": 16,
        "150k": 12,
        "155k": 15,
        "160k": 4,
        "165k": 3,
        "170k": 5,
        "175k": 12,
        "180k": 9,
        "185k": 9,
        "190k": 3,
        "195k": 29,
        "200k": 7,
        "350k": "51"
      },
      nonRelativem: {
        "5k": 29,
        "10k": 18,
        "15k": 26,
        "20k": 30,
        "25k": 53,
        "30k": 59,
        "35k": 58,
        "40k": 30,
        "45k": 38,
        "50k": 49,
        "55k": 35,
        "60k": 58,
        "65k": 60,
        "70k": 54,
        "75k": 39,
        "80k": 32,
        "85k": 29,
        "90k": 25,
        "95k": 43,
        "100k": 30,
        "105k": 18,
        "110k": 23,
        "115k": 19,
        "120k": 12,
        "125k": 12,
        "130k": 26,
        "135k": 14,
        "140k": 16,
        "145k": 8,
        "150k": 3,
        "155k": 10,
        "160k": 13,
        "165k": 26,
        "170k": 8,
        "175k": 5,
        "180k": 14,
        "185k": 4,
        "190k": 4,
        "195k": 4,
        "200k": 3,
        "350k": "77"
      },
      houseLoitererfm: {
        "5k": 391,
        "10k": 457,
        "15k": 462,
        "20k": 476,
        "25k": 494,
        "30k": 482,
        "35k": 498,
        "40k": 444,
        "45k": 456,
        "50k": 467,
        "55k": 487,
        "60k": 373,
        "65k": 400,
        "70k": 316,
        "75k": 308,
        "80k": 343,
        "85k": 289,
        "90k": 255,
        "95k": 229,
        "100k": 252,
        "105k": 238,
        "110k": 200,
        "115k": 197,
        "120k": 188,
        "125k": 191,
        "130k": 162,
        "135k": 183,
        "140k": 133,
        "145k": 133,
        "150k": 100,
        "155k": 146,
        "160k": 120,
        "165k": 122,
        "170k": 90,
        "175k": 73,
        "180k": 69,
        "185k": 52,
        "190k": 64,
        "195k": 43,
        "200k": 52,
        "350k": "839"
      },
      spousefm: {
        "5k": 89,
        "10k": 56,
        "15k": 81,
        "20k": 133,
        "25k": 146,
        "30k": 167,
        "35k": 203,
        "40k": 156,
        "45k": 168,
        "50k": 204,
        "55k": 265,
        "60k": 206,
        "65k": 283,
        "70k": 208,
        "75k": 267,
        "80k": 255,
        "85k": 221,
        "90k": 220,
        "95k": 235,
        "100k": 233,
        "105k": 269,
        "110k": 206,
        "115k": 227,
        "120k": 165,
        "125k": 215,
        "130k": 180,
        "135k": 170,
        "140k": 159,
        "145k": 192,
        "150k": 112,
        "155k": 139,
        "160k": 146,
        "165k": 128,
        "170k": 126,
        "175k": 94,
        "180k": 87,
        "185k": 98,
        "190k": 95,
        "195k": 61,
        "200k": 69,
        "350k": "1,146"
      },
      childfm: {
        "5k": 5,
        "10k": 10,
        "15k": 19,
        "20k": 28,
        "25k": 17,
        "30k": 30,
        "35k": 22,
        "40k": 29,
        "45k": 30,
        "50k": 27,
        "55k": 27,
        "60k": 40,
        "65k": 31,
        "70k": 24,
        "75k": 11,
        "80k": 34,
        "85k": 15,
        "90k": 24,
        "95k": 9,
        "100k": 9,
        "105k": 7,
        "110k": 10,
        "115k": 25,
        "120k": 13,
        "125k": 2,
        "130k": 9,
        "135k": 6,
        "140k": 3,
        "145k": 7,
        "150k": 8,
        "155k": 5,
        "160k": 3,
        "165k": 2,
        "170k": 2,
        "175k": 1,
        "180k": 5,
        "185k": 0,
        "190k": 0,
        "195k": 2,
        "200k": 9,
        "350k": "17"
      },
      otherRelativefm: {
        "5k": 23,
        "10k": 23,
        "15k": 24,
        "20k": 60,
        "25k": 60,
        "30k": 61,
        "35k": 58,
        "40k": 61,
        "45k": 65,
        "50k": 45,
        "55k": 83,
        "60k": 66,
        "65k": 66,
        "70k": 42,
        "75k": 37,
        "80k": 27,
        "85k": 40,
        "90k": 41,
        "95k": 30,
        "100k": 19,
        "105k": 37,
        "110k": 20,
        "115k": 19,
        "120k": 13,
        "125k": 20,
        "130k": 14,
        "135k": 6,
        "140k": 16,
        "145k": 11,
        "150k": 17,
        "155k": 21,
        "160k": 11,
        "165k": 11,
        "170k": 4,
        "175k": 5,
        "180k": 9,
        "185k": 6,
        "190k": 4,
        "195k": 11,
        "200k": 3,
        "350k": "43"
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 11,
        "15k": 17,
        "20k": 39,
        "25k": 46,
        "30k": 34,
        "35k": 38,
        "40k": 45,
        "45k": 51,
        "50k": 31,
        "55k": 28,
        "60k": 21,
        "65k": 35,
        "70k": 23,
        "75k": 39,
        "80k": 44,
        "85k": 30,
        "90k": 35,
        "95k": 15,
        "100k": 19,
        "105k": 20,
        "110k": 28,
        "115k": 14,
        "120k": 15,
        "125k": 14,
        "130k": 8,
        "135k": 19,
        "140k": 2,
        "145k": 9,
        "150k": 17,
        "155k": 7,
        "160k": 12,
        "165k": 8,
        "170k": 6,
        "175k": 7,
        "180k": 4,
        "185k": 7,
        "190k": 5,
        "195k": 2,
        "200k": 0,
        "350k": "56"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 459,
        "10k": 428,
        "15k": 490,
        "20k": 536,
        "25k": 490,
        "30k": 425,
        "35k": 453,
        "40k": 537,
        "45k": 455,
        "50k": 383,
        "55k": 475,
        "60k": 372,
        "65k": 394,
        "70k": 370,
        "75k": 399,
        "80k": 445,
        "85k": 277,
        "90k": 283,
        "95k": 306,
        "100k": 284,
        "105k": 268,
        "110k": 257,
        "115k": 216,
        "120k": 189,
        "125k": 232,
        "130k": 155,
        "135k": 169,
        "140k": 155,
        "145k": 183,
        "150k": 104,
        "155k": 160,
        "160k": 143,
        "165k": 99,
        "170k": 137,
        "175k": 104,
        "180k": 59,
        "185k": 69,
        "190k": 76,
        "195k": 74,
        "200k": 84,
        "350k": "1,046"
      },
      spousem: {
        "5k": 86,
        "10k": 56,
        "15k": 91,
        "20k": 87,
        "25k": 121,
        "30k": 127,
        "35k": 152,
        "40k": 166,
        "45k": 152,
        "50k": 139,
        "55k": 199,
        "60k": 172,
        "65k": 217,
        "70k": 157,
        "75k": 207,
        "80k": 157,
        "85k": 175,
        "90k": 129,
        "95k": 144,
        "100k": 133,
        "105k": 159,
        "110k": 129,
        "115k": 124,
        "120k": 107,
        "125k": 128,
        "130k": 104,
        "135k": 113,
        "140k": 79,
        "145k": 76,
        "150k": 84,
        "155k": 73,
        "160k": 58,
        "165k": 81,
        "170k": 69,
        "175k": 68,
        "180k": 61,
        "185k": 50,
        "190k": 35,
        "195k": 47,
        "200k": 32,
        "350k": "635"
      },
      childm: {
        "5k": 9,
        "10k": 11,
        "15k": 25,
        "20k": 29,
        "25k": 34,
        "30k": 15,
        "35k": 19,
        "40k": 12,
        "45k": 29,
        "50k": 22,
        "55k": 17,
        "60k": 21,
        "65k": 11,
        "70k": 18,
        "75k": 7,
        "80k": 15,
        "85k": 11,
        "90k": 18,
        "95k": 17,
        "100k": 1,
        "105k": 12,
        "110k": 7,
        "115k": 3,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 3,
        "140k": 0,
        "145k": 4,
        "150k": 3,
        "155k": 6,
        "160k": 0,
        "165k": 5,
        "170k": 0,
        "175k": 0,
        "180k": 4,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "10"
      },
      otherRelativem: {
        "5k": 13,
        "10k": 8,
        "15k": 15,
        "20k": 29,
        "25k": 36,
        "30k": 26,
        "35k": 38,
        "40k": 45,
        "45k": 22,
        "50k": 57,
        "55k": 26,
        "60k": 51,
        "65k": 45,
        "70k": 17,
        "75k": 28,
        "80k": 32,
        "85k": 45,
        "90k": 21,
        "95k": 37,
        "100k": 13,
        "105k": 25,
        "110k": 16,
        "115k": 10,
        "120k": 17,
        "125k": 20,
        "130k": 22,
        "135k": 19,
        "140k": 14,
        "145k": 5,
        "150k": 22,
        "155k": 6,
        "160k": 6,
        "165k": 4,
        "170k": 11,
        "175k": 6,
        "180k": 4,
        "185k": 4,
        "190k": 1,
        "195k": 3,
        "200k": 2,
        "350k": "48"
      },
      nonRelativem: {
        "5k": 18,
        "10k": 10,
        "15k": 23,
        "20k": 29,
        "25k": 21,
        "30k": 27,
        "35k": 53,
        "40k": 31,
        "45k": 42,
        "50k": 47,
        "55k": 21,
        "60k": 26,
        "65k": 46,
        "70k": 32,
        "75k": 18,
        "80k": 22,
        "85k": 17,
        "90k": 20,
        "95k": 9,
        "100k": 13,
        "105k": 14,
        "110k": 16,
        "115k": 25,
        "120k": 9,
        "125k": 6,
        "130k": 4,
        "135k": 7,
        "140k": 8,
        "145k": 3,
        "150k": 12,
        "155k": 13,
        "160k": 7,
        "165k": 8,
        "170k": 4,
        "175k": 6,
        "180k": 5,
        "185k": 2,
        "190k": 2,
        "195k": 1,
        "200k": 3,
        "350k": "73"
      },
      houseLoitererfm: {
        "5k": 541,
        "10k": 471,
        "15k": 734,
        "20k": 521,
        "25k": 573,
        "30k": 509,
        "35k": 588,
        "40k": 524,
        "45k": 478,
        "50k": 393,
        "55k": 450,
        "60k": 361,
        "65k": 407,
        "70k": 310,
        "75k": 369,
        "80k": 262,
        "85k": 304,
        "90k": 224,
        "95k": 249,
        "100k": 204,
        "105k": 226,
        "110k": 202,
        "115k": 197,
        "120k": 156,
        "125k": 178,
        "130k": 109,
        "135k": 140,
        "140k": 104,
        "145k": 103,
        "150k": 102,
        "155k": 67,
        "160k": 53,
        "165k": 76,
        "170k": 74,
        "175k": 72,
        "180k": 68,
        "185k": 56,
        "190k": 47,
        "195k": 52,
        "200k": 39,
        "350k": "678"
      },
      spousefm: {
        "5k": 131,
        "10k": 96,
        "15k": 131,
        "20k": 170,
        "25k": 213,
        "30k": 172,
        "35k": 221,
        "40k": 287,
        "45k": 264,
        "50k": 268,
        "55k": 295,
        "60k": 254,
        "65k": 285,
        "70k": 238,
        "75k": 276,
        "80k": 286,
        "85k": 252,
        "90k": 205,
        "95k": 203,
        "100k": 229,
        "105k": 239,
        "110k": 205,
        "115k": 148,
        "120k": 159,
        "125k": 178,
        "130k": 124,
        "135k": 126,
        "140k": 118,
        "145k": 119,
        "150k": 88,
        "155k": 107,
        "160k": 58,
        "165k": 63,
        "170k": 91,
        "175k": 83,
        "180k": 60,
        "185k": 62,
        "190k": 68,
        "195k": 70,
        "200k": 64,
        "350k": "825"
      },
      childfm: {
        "5k": 8,
        "10k": 5,
        "15k": 6,
        "20k": 16,
        "25k": 29,
        "30k": 9,
        "35k": 16,
        "40k": 18,
        "45k": 13,
        "50k": 22,
        "55k": 20,
        "60k": 14,
        "65k": 15,
        "70k": 8,
        "75k": 5,
        "80k": 8,
        "85k": 9,
        "90k": 5,
        "95k": 11,
        "100k": 8,
        "105k": 6,
        "110k": 2,
        "115k": 3,
        "120k": 2,
        "125k": 8,
        "130k": 2,
        "135k": 0,
        "140k": 11,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 0,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 1,
        "350k": "6"
      },
      otherRelativefm: {
        "5k": 27,
        "10k": 21,
        "15k": 23,
        "20k": 44,
        "25k": 50,
        "30k": 47,
        "35k": 51,
        "40k": 74,
        "45k": 39,
        "50k": 73,
        "55k": 53,
        "60k": 51,
        "65k": 41,
        "70k": 40,
        "75k": 42,
        "80k": 38,
        "85k": 48,
        "90k": 37,
        "95k": 42,
        "100k": 35,
        "105k": 36,
        "110k": 22,
        "115k": 25,
        "120k": 24,
        "125k": 20,
        "130k": 32,
        "135k": 22,
        "140k": 24,
        "145k": 9,
        "150k": 17,
        "155k": 7,
        "160k": 8,
        "165k": 13,
        "170k": 19,
        "175k": 9,
        "180k": 8,
        "185k": 2,
        "190k": 7,
        "195k": 12,
        "200k": 5,
        "350k": "64"
      },
      nonRelativefm: {
        "5k": 15,
        "10k": 17,
        "15k": 22,
        "20k": 20,
        "25k": 41,
        "30k": 27,
        "35k": 37,
        "40k": 36,
        "45k": 26,
        "50k": 21,
        "55k": 29,
        "60k": 18,
        "65k": 20,
        "70k": 21,
        "75k": 31,
        "80k": 21,
        "85k": 5,
        "90k": 17,
        "95k": 20,
        "100k": 15,
        "105k": 7,
        "110k": 16,
        "115k": 26,
        "120k": 9,
        "125k": 8,
        "130k": 6,
        "135k": 3,
        "140k": 4,
        "145k": 11,
        "150k": 10,
        "155k": 10,
        "160k": 3,
        "165k": 6,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "25"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 198,
        "10k": 168,
        "15k": 448,
        "20k": 462,
        "25k": 476,
        "30k": 473,
        "35k": 489,
        "40k": 505,
        "45k": 428,
        "50k": 356,
        "55k": 416,
        "60k": 337,
        "65k": 294,
        "70k": 291,
        "75k": 275,
        "80k": 235,
        "85k": 238,
        "90k": 212,
        "95k": 176,
        "100k": 159,
        "105k": 152,
        "110k": 151,
        "115k": 112,
        "120k": 116,
        "125k": 108,
        "130k": 102,
        "135k": 106,
        "140k": 81,
        "145k": 99,
        "150k": 83,
        "155k": 70,
        "160k": 45,
        "165k": 51,
        "170k": 55,
        "175k": 54,
        "180k": 35,
        "185k": 45,
        "190k": 43,
        "195k": 36,
        "200k": 16,
        "350k": 502
      },
      spousem: {
        "5k": 50,
        "10k": 16,
        "15k": 65,
        "20k": 76,
        "25k": 116,
        "30k": 136,
        "35k": 132,
        "40k": 143,
        "45k": 154,
        "50k": 130,
        "55k": 139,
        "60k": 105,
        "65k": 153,
        "70k": 108,
        "75k": 109,
        "80k": 99,
        "85k": 77,
        "90k": 74,
        "95k": 79,
        "100k": 68,
        "105k": 92,
        "110k": 63,
        "115k": 63,
        "120k": 53,
        "125k": 40,
        "130k": 34,
        "135k": 28,
        "140k": 51,
        "145k": 40,
        "150k": 32,
        "155k": 23,
        "160k": 24,
        "165k": 24,
        "170k": 32,
        "175k": 21,
        "180k": 22,
        "185k": 16,
        "190k": 39,
        "195k": 14,
        "200k": 18,
        "350k": 261
      },
      childm: {
        "5k": 0,
        "10k": 1,
        "15k": 3,
        "20k": 2,
        "25k": 6,
        "30k": 7,
        "35k": 2,
        "40k": 1,
        "45k": 0,
        "50k": 2,
        "55k": 3,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 1,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 3,
        "10k": 6,
        "15k": 12,
        "20k": 18,
        "25k": 10,
        "30k": 37,
        "35k": 32,
        "40k": 28,
        "45k": 26,
        "50k": 8,
        "55k": 27,
        "60k": 28,
        "65k": 32,
        "70k": 11,
        "75k": 15,
        "80k": 23,
        "85k": 18,
        "90k": 14,
        "95k": 3,
        "100k": 11,
        "105k": 15,
        "110k": 8,
        "115k": 8,
        "120k": 23,
        "125k": 5,
        "130k": 9,
        "135k": 5,
        "140k": 3,
        "145k": 5,
        "150k": 5,
        "155k": 1,
        "160k": 11,
        "165k": 4,
        "170k": 3,
        "175k": 5,
        "180k": 5,
        "185k": 3,
        "190k": 4,
        "195k": 7,
        "200k": 3,
        "350k": 34
      },
      nonRelativem: {
        "5k": 2,
        "10k": 8,
        "15k": 10,
        "20k": 13,
        "25k": 19,
        "30k": 28,
        "35k": 20,
        "40k": 7,
        "45k": 14,
        "50k": 15,
        "55k": 12,
        "60k": 6,
        "65k": 4,
        "70k": 20,
        "75k": 13,
        "80k": 3,
        "85k": 1,
        "90k": 2,
        "95k": 2,
        "100k": 5,
        "105k": 0,
        "110k": 5,
        "115k": 1,
        "120k": 4,
        "125k": 3,
        "130k": 0,
        "135k": 0,
        "140k": 5,
        "145k": 6,
        "150k": 0,
        "155k": 3,
        "160k": 1,
        "165k": 3,
        "170k": 7,
        "175k": 2,
        "180k": 2,
        "185k": 6,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 25
      },
      houseLoitererfm: {
        "5k": 209,
        "10k": 407,
        "15k": 809,
        "20k": 752,
        "25k": 642,
        "30k": 600,
        "35k": 477,
        "40k": 448,
        "45k": 418,
        "50k": 354,
        "55k": 262,
        "60k": 240,
        "65k": 289,
        "70k": 210,
        "75k": 203,
        "80k": 174,
        "85k": 138,
        "90k": 145,
        "95k": 117,
        "100k": 101,
        "105k": 120,
        "110k": 81,
        "115k": 64,
        "120k": 64,
        "125k": 57,
        "130k": 65,
        "135k": 48,
        "140k": 61,
        "145k": 55,
        "150k": 62,
        "155k": 45,
        "160k": 37,
        "165k": 34,
        "170k": 31,
        "175k": 24,
        "180k": 20,
        "185k": 20,
        "190k": 22,
        "195k": 18,
        "200k": 20,
        "350k": 243
      },
      spousefm: {
        "5k": 81,
        "10k": 36,
        "15k": 86,
        "20k": 129,
        "25k": 245,
        "30k": 255,
        "35k": 282,
        "40k": 291,
        "45k": 255,
        "50k": 256,
        "55k": 245,
        "60k": 179,
        "65k": 191,
        "70k": 234,
        "75k": 163,
        "80k": 169,
        "85k": 164,
        "90k": 149,
        "95k": 132,
        "100k": 103,
        "105k": 68,
        "110k": 88,
        "115k": 66,
        "120k": 72,
        "125k": 79,
        "130k": 63,
        "135k": 80,
        "140k": 55,
        "145k": 70,
        "150k": 59,
        "155k": 57,
        "160k": 30,
        "165k": 41,
        "170k": 37,
        "175k": 31,
        "180k": 15,
        "185k": 30,
        "190k": 25,
        "195k": 21,
        "200k": 13,
        "350k": 310
      },
      childfm: {
        "5k": 3,
        "10k": 0,
        "15k": 0,
        "20k": 8,
        "25k": 0,
        "30k": 6,
        "35k": 2,
        "40k": 9,
        "45k": 3,
        "50k": 0,
        "55k": 2,
        "60k": 3,
        "65k": 1,
        "70k": 0,
        "75k": 0,
        "80k": 1,
        "85k": 1,
        "90k": 0,
        "95k": 3,
        "100k": 0,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 0,
        "140k": 0,
        "145k": 3,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      otherRelativefm: {
        "5k": 16,
        "10k": 16,
        "15k": 25,
        "20k": 36,
        "25k": 57,
        "30k": 43,
        "35k": 53,
        "40k": 29,
        "45k": 44,
        "50k": 46,
        "55k": 41,
        "60k": 45,
        "65k": 51,
        "70k": 31,
        "75k": 22,
        "80k": 40,
        "85k": 30,
        "90k": 23,
        "95k": 14,
        "100k": 16,
        "105k": 16,
        "110k": 17,
        "115k": 22,
        "120k": 8,
        "125k": 15,
        "130k": 13,
        "135k": 15,
        "140k": 5,
        "145k": 9,
        "150k": 9,
        "155k": 7,
        "160k": 9,
        "165k": 6,
        "170k": 3,
        "175k": 12,
        "180k": 6,
        "185k": 6,
        "190k": 5,
        "195k": 4,
        "200k": 2,
        "350k": 67
      },
      nonRelativefm: {
        "5k": 12,
        "10k": 3,
        "15k": 2,
        "20k": 5,
        "25k": 4,
        "30k": 17,
        "35k": 12,
        "40k": 13,
        "45k": 14,
        "50k": 17,
        "55k": 17,
        "60k": 13,
        "65k": 16,
        "70k": 10,
        "75k": 4,
        "80k": 11,
        "85k": 12,
        "90k": 3,
        "95k": 9,
        "100k": 0,
        "105k": 1,
        "110k": 6,
        "115k": 3,
        "120k": 1,
        "125k": 0,
        "130k": 9,
        "135k": 1,
        "140k": 0,
        "145k": 6,
        "150k": 3,
        "155k": 4,
        "160k": 0,
        "165k": 0,
        "170k": 5,
        "175k": 4,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 20
      }
    }
  },
  "2015": {
    "17": {
      houseLoitererm: {
        "5k": 14,
        "10k": 13,
        "15k": 4,
        "20k": 7,
        "25k": 14,
        "30k": 7,
        "35k": 8,
        "40k": 6,
        "45k": 5,
        "50k": 0,
        "55k": 4,
        "60k": 5,
        "65k": 12,
        "70k": 3,
        "75k": 8,
        "80k": 2,
        "85k": 0,
        "90k": 0,
        "95k": 4,
        "100k": 1,
        "105k": 0,
        "110k": 2,
        "115k": 2,
        "120k": 0,
        "125k": 0,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 1,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "1"
      },
      spousem: {
        "5k": 2,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 2,
        "45k": 0,
        "50k": 2,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 1,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childm: {
        "5k": 256,
        "10k": 235,
        "15k": 338,
        "20k": 438,
        "25k": 486,
        "30k": 420,
        "35k": 424,
        "40k": 449,
        "45k": 337,
        "50k": 412,
        "55k": 449,
        "60k": 362,
        "65k": 418,
        "70k": 349,
        "75k": 373,
        "80k": 357,
        "85k": 337,
        "90k": 266,
        "95k": 302,
        "100k": 253,
        "105k": 318,
        "110k": 247,
        "115k": 237,
        "120k": 177,
        "125k": 201,
        "130k": 246,
        "135k": 175,
        "140k": 148,
        "145k": 168,
        "150k": 133,
        "155k": 169,
        "160k": 168,
        "165k": 102,
        "170k": 86,
        "175k": 122,
        "180k": 79,
        "185k": 86,
        "190k": 55,
        "195k": 78,
        "200k": 49,
        "350k": "1,081"
      },
      otherRelativem: {
        "5k": 43,
        "10k": 40,
        "15k": 30,
        "20k": 76,
        "25k": 51,
        "30k": 60,
        "35k": 39,
        "40k": 63,
        "45k": 40,
        "50k": 53,
        "55k": 39,
        "60k": 45,
        "65k": 51,
        "70k": 35,
        "75k": 45,
        "80k": 36,
        "85k": 30,
        "90k": 27,
        "95k": 15,
        "100k": 25,
        "105k": 14,
        "110k": 15,
        "115k": 20,
        "120k": 10,
        "125k": 18,
        "130k": 13,
        "135k": 10,
        "140k": 13,
        "145k": 10,
        "150k": 12,
        "155k": 8,
        "160k": 18,
        "165k": 15,
        "170k": 7,
        "175k": 11,
        "180k": 5,
        "185k": 8,
        "190k": 5,
        "195k": 5,
        "200k": 0,
        "350k": "69"
      },
      nonRelativem: {
        "5k": 6,
        "10k": 1,
        "15k": 0,
        "20k": 1,
        "25k": 3,
        "30k": 15,
        "35k": 7,
        "40k": 9,
        "45k": 7,
        "50k": 12,
        "55k": 12,
        "60k": 14,
        "65k": 16,
        "70k": 11,
        "75k": 5,
        "80k": 10,
        "85k": 5,
        "90k": 9,
        "95k": 10,
        "100k": 6,
        "105k": 0,
        "110k": 6,
        "115k": 3,
        "120k": 3,
        "125k": 9,
        "130k": 7,
        "135k": 8,
        "140k": 1,
        "145k": 2,
        "150k": 0,
        "155k": 3,
        "160k": 4,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 5,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "13"
      },
      houseLoitererfm: {
        "5k": 0,
        "10k": 6,
        "15k": 8,
        "20k": 5,
        "25k": 6,
        "30k": 8,
        "35k": 6,
        "40k": 3,
        "45k": 2,
        "50k": 11,
        "55k": 0,
        "60k": 7,
        "65k": 5,
        "70k": 0,
        "75k": 3,
        "80k": 0,
        "85k": 1,
        "90k": 2,
        "95k": 0,
        "100k": 4,
        "105k": 2,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "1"
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 1,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childfm: {
        "5k": 263,
        "10k": 239,
        "15k": 279,
        "20k": 403,
        "25k": 437,
        "30k": 440,
        "35k": 456,
        "40k": 435,
        "45k": 430,
        "50k": 398,
        "55k": 415,
        "60k": 337,
        "65k": 432,
        "70k": 364,
        "75k": 303,
        "80k": 348,
        "85k": 357,
        "90k": 258,
        "95k": 221,
        "100k": 293,
        "105k": 280,
        "110k": 281,
        "115k": 225,
        "120k": 233,
        "125k": 230,
        "130k": 187,
        "135k": 178,
        "140k": 173,
        "145k": 159,
        "150k": 116,
        "155k": 168,
        "160k": 155,
        "165k": 122,
        "170k": 71,
        "175k": 74,
        "180k": 76,
        "185k": 81,
        "190k": 68,
        "195k": 73,
        "200k": 52,
        "350k": "1,051"
      },
      otherRelativefm: {
        "5k": 47,
        "10k": 34,
        "15k": 35,
        "20k": 47,
        "25k": 56,
        "30k": 46,
        "35k": 56,
        "40k": 56,
        "45k": 37,
        "50k": 41,
        "55k": 59,
        "60k": 39,
        "65k": 43,
        "70k": 21,
        "75k": 29,
        "80k": 14,
        "85k": 20,
        "90k": 23,
        "95k": 20,
        "100k": 16,
        "105k": 33,
        "110k": 20,
        "115k": 17,
        "120k": 20,
        "125k": 20,
        "130k": 13,
        "135k": 7,
        "140k": 9,
        "145k": 7,
        "150k": 10,
        "155k": 8,
        "160k": 4,
        "165k": 10,
        "170k": 1,
        "175k": 5,
        "180k": 10,
        "185k": 15,
        "190k": 7,
        "195k": 5,
        "200k": 1,
        "350k": "72"
      },
      nonRelativefm: {
        "5k": 1,
        "10k": 4,
        "15k": 2,
        "20k": 5,
        "25k": 8,
        "30k": 18,
        "35k": 4,
        "40k": 8,
        "45k": 13,
        "50k": 15,
        "55k": 15,
        "60k": 8,
        "65k": 4,
        "70k": 11,
        "75k": 16,
        "80k": 13,
        "85k": 9,
        "90k": 12,
        "95k": 6,
        "100k": 8,
        "105k": 4,
        "110k": 4,
        "115k": 5,
        "120k": 5,
        "125k": 5,
        "130k": 4,
        "135k": 4,
        "140k": 4,
        "145k": 1,
        "150k": 6,
        "155k": 6,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 1,
        "180k": 1,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "8"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 97,
        "10k": 63,
        "15k": 47,
        "20k": 70,
        "25k": 70,
        "30k": 108,
        "35k": 75,
        "40k": 53,
        "45k": 56,
        "50k": 52,
        "55k": 37,
        "60k": 26,
        "65k": 23,
        "70k": 22,
        "75k": 25,
        "80k": 24,
        "85k": 23,
        "90k": 20,
        "95k": 12,
        "100k": 20,
        "105k": 6,
        "110k": 8,
        "115k": 8,
        "120k": 5,
        "125k": 5,
        "130k": 7,
        "135k": 3,
        "140k": 5,
        "145k": 2,
        "150k": 0,
        "155k": 2,
        "160k": 9,
        "165k": 0,
        "170k": 0,
        "175k": 4,
        "180k": 3,
        "185k": 3,
        "190k": 0,
        "195k": 1,
        "200k": 2,
        "350k": 16
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 5,
        "20k": 4,
        "25k": 3,
        "30k": 1,
        "35k": 4,
        "40k": 5,
        "45k": 7,
        "50k": 0,
        "55k": 10,
        "60k": 6,
        "65k": 0,
        "70k": 1,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 2,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 1,
        "145k": 2,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 100,
        "10k": 75,
        "15k": 120,
        "20k": 155,
        "25k": 121,
        "30k": 150,
        "35k": 217,
        "40k": 225,
        "45k": 190,
        "50k": 173,
        "55k": 238,
        "60k": 205,
        "65k": 211,
        "70k": 203,
        "75k": 169,
        "80k": 197,
        "85k": 195,
        "90k": 202,
        "95k": 150,
        "100k": 145,
        "105k": 142,
        "110k": 166,
        "115k": 147,
        "120k": 97,
        "125k": 141,
        "130k": 137,
        "135k": 112,
        "140k": 107,
        "145k": 153,
        "150k": 96,
        "155k": 104,
        "160k": 94,
        "165k": 85,
        "170k": 84,
        "175k": 60,
        "180k": 51,
        "185k": 50,
        "190k": 59,
        "195k": 43,
        "200k": 43,
        "350k": 577
      },
      otherRelativem: {
        "5k": 18,
        "10k": 17,
        "15k": 52,
        "20k": 19,
        "25k": 35,
        "30k": 23,
        "35k": 36,
        "40k": 33,
        "45k": 36,
        "50k": 41,
        "55k": 35,
        "60k": 38,
        "65k": 54,
        "70k": 23,
        "75k": 24,
        "80k": 28,
        "85k": 18,
        "90k": 16,
        "95k": 21,
        "100k": 13,
        "105k": 14,
        "110k": 14,
        "115k": 9,
        "120k": 7,
        "125k": 5,
        "130k": 12,
        "135k": 3,
        "140k": 13,
        "145k": 7,
        "150k": 3,
        "155k": 10,
        "160k": 1,
        "165k": 2,
        "170k": 3,
        "175k": 8,
        "180k": 5,
        "185k": 4,
        "190k": 5,
        "195k": 0,
        "200k": 3,
        "350k": 45
      },
      nonRelativem: {
        "5k": 52,
        "10k": 31,
        "15k": 42,
        "20k": 22,
        "25k": 38,
        "30k": 29,
        "35k": 20,
        "40k": 39,
        "45k": 42,
        "50k": 36,
        "55k": 31,
        "60k": 52,
        "65k": 17,
        "70k": 26,
        "75k": 13,
        "80k": 29,
        "85k": 20,
        "90k": 14,
        "95k": 14,
        "100k": 14,
        "105k": 11,
        "110k": 18,
        "115k": 9,
        "120k": 9,
        "125k": 11,
        "130k": 2,
        "135k": 3,
        "140k": 6,
        "145k": 2,
        "150k": 0,
        "155k": 11,
        "160k": 15,
        "165k": 1,
        "170k": 8,
        "175k": 2,
        "180k": 6,
        "185k": 3,
        "190k": 2,
        "195k": 5,
        "200k": 0,
        "350k": 35
      },
      houseLoitererfm: {
        "5k": 103,
        "10k": 114,
        "15k": 84,
        "20k": 64,
        "25k": 102,
        "30k": 92,
        "35k": 63,
        "40k": 55,
        "45k": 48,
        "50k": 51,
        "55k": 58,
        "60k": 46,
        "65k": 27,
        "70k": 24,
        "75k": 12,
        "80k": 16,
        "85k": 16,
        "90k": 24,
        "95k": 14,
        "100k": 15,
        "105k": 12,
        "110k": 16,
        "115k": 3,
        "120k": 7,
        "125k": 5,
        "130k": 2,
        "135k": 5,
        "140k": 0,
        "145k": 4,
        "150k": 4,
        "155k": 0,
        "160k": 2,
        "165k": 2,
        "170k": 3,
        "175k": 0,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 26
      },
      spousefm: {
        "5k": 0,
        "10k": 2,
        "15k": 2,
        "20k": 3,
        "25k": 5,
        "30k": 10,
        "35k": 25,
        "40k": 10,
        "45k": 30,
        "50k": 5,
        "55k": 8,
        "60k": 5,
        "65k": 9,
        "70k": 2,
        "75k": 6,
        "80k": 5,
        "85k": 1,
        "90k": 2,
        "95k": 2,
        "100k": 7,
        "105k": 4,
        "110k": 1,
        "115k": 0,
        "120k": 4,
        "125k": 0,
        "130k": 0,
        "135k": 2,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 4,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 92,
        "10k": 89,
        "15k": 124,
        "20k": 144,
        "25k": 134,
        "30k": 143,
        "35k": 168,
        "40k": 168,
        "45k": 189,
        "50k": 163,
        "55k": 196,
        "60k": 159,
        "65k": 215,
        "70k": 155,
        "75k": 180,
        "80k": 177,
        "85k": 126,
        "90k": 154,
        "95k": 140,
        "100k": 136,
        "105k": 190,
        "110k": 144,
        "115k": 117,
        "120k": 102,
        "125k": 149,
        "130k": 102,
        "135k": 105,
        "140k": 101,
        "145k": 96,
        "150k": 58,
        "155k": 101,
        "160k": 81,
        "165k": 60,
        "170k": 37,
        "175k": 33,
        "180k": 47,
        "185k": 51,
        "190k": 40,
        "195k": 46,
        "200k": 19,
        "350k": 539
      },
      otherRelativefm: {
        "5k": 9,
        "10k": 16,
        "15k": 20,
        "20k": 33,
        "25k": 44,
        "30k": 33,
        "35k": 49,
        "40k": 25,
        "45k": 34,
        "50k": 32,
        "55k": 31,
        "60k": 31,
        "65k": 20,
        "70k": 25,
        "75k": 19,
        "80k": 18,
        "85k": 14,
        "90k": 10,
        "95k": 21,
        "100k": 20,
        "105k": 22,
        "110k": 22,
        "115k": 10,
        "120k": 10,
        "125k": 12,
        "130k": 8,
        "135k": 9,
        "140k": 4,
        "145k": 7,
        "150k": 3,
        "155k": 4,
        "160k": 2,
        "165k": 7,
        "170k": 13,
        "175k": 7,
        "180k": 1,
        "185k": 12,
        "190k": 0,
        "195k": 8,
        "200k": 0,
        "350k": 28
      },
      nonRelativefm: {
        "5k": 20,
        "10k": 28,
        "15k": 19,
        "20k": 45,
        "25k": 56,
        "30k": 45,
        "35k": 66,
        "40k": 58,
        "45k": 38,
        "50k": 47,
        "55k": 46,
        "60k": 30,
        "65k": 40,
        "70k": 20,
        "75k": 31,
        "80k": 17,
        "85k": 33,
        "90k": 27,
        "95k": 23,
        "100k": 13,
        "105k": 18,
        "110k": 14,
        "115k": 7,
        "120k": 12,
        "125k": 10,
        "130k": 8,
        "135k": 1,
        "140k": 2,
        "145k": 3,
        "150k": 2,
        "155k": 6,
        "160k": 2,
        "165k": 2,
        "170k": 15,
        "175k": 3,
        "180k": 3,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 13
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 100,
        "10k": 63,
        "15k": 89,
        "20k": 140,
        "25k": 125,
        "30k": 95,
        "35k": 132,
        "40k": 104,
        "45k": 132,
        "50k": 94,
        "55k": 78,
        "60k": 99,
        "65k": 67,
        "70k": 67,
        "75k": 52,
        "80k": 51,
        "85k": 40,
        "90k": 42,
        "95k": 33,
        "100k": 17,
        "105k": 26,
        "110k": 15,
        "115k": 13,
        "120k": 10,
        "125k": 18,
        "130k": 12,
        "135k": 11,
        "140k": 4,
        "145k": 11,
        "150k": 4,
        "155k": 6,
        "160k": 2,
        "165k": 8,
        "170k": 7,
        "175k": 8,
        "180k": 5,
        "185k": 4,
        "190k": 7,
        "195k": 2,
        "200k": 1,
        "350k": 35
      },
      spousem: {
        "5k": 9,
        "10k": 3,
        "15k": 5,
        "20k": 11,
        "25k": 12,
        "30k": 20,
        "35k": 28,
        "40k": 5,
        "45k": 10,
        "50k": 9,
        "55k": 16,
        "60k": 20,
        "65k": 15,
        "70k": 14,
        "75k": 11,
        "80k": 1,
        "85k": 4,
        "90k": 6,
        "95k": 1,
        "100k": 4,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 2,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 3,
        "145k": 4,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      childm: {
        "5k": 45,
        "10k": 23,
        "15k": 48,
        "20k": 72,
        "25k": 64,
        "30k": 82,
        "35k": 72,
        "40k": 92,
        "45k": 93,
        "50k": 98,
        "55k": 93,
        "60k": 91,
        "65k": 74,
        "70k": 82,
        "75k": 109,
        "80k": 83,
        "85k": 105,
        "90k": 84,
        "95k": 45,
        "100k": 81,
        "105k": 66,
        "110k": 104,
        "115k": 53,
        "120k": 68,
        "125k": 71,
        "130k": 57,
        "135k": 60,
        "140k": 48,
        "145k": 81,
        "150k": 55,
        "155k": 57,
        "160k": 46,
        "165k": 47,
        "170k": 43,
        "175k": 37,
        "180k": 47,
        "185k": 28,
        "190k": 21,
        "195k": 27,
        "200k": 13,
        "350k": 319
      },
      otherRelativem: {
        "5k": 17,
        "10k": 6,
        "15k": 13,
        "20k": 9,
        "25k": 9,
        "30k": 25,
        "35k": 31,
        "40k": 31,
        "45k": 32,
        "50k": 30,
        "55k": 38,
        "60k": 16,
        "65k": 21,
        "70k": 26,
        "75k": 41,
        "80k": 18,
        "85k": 29,
        "90k": 13,
        "95k": 20,
        "100k": 18,
        "105k": 14,
        "110k": 13,
        "115k": 2,
        "120k": 21,
        "125k": 18,
        "130k": 11,
        "135k": 1,
        "140k": 8,
        "145k": 3,
        "150k": 6,
        "155k": 4,
        "160k": 1,
        "165k": 4,
        "170k": 1,
        "175k": 10,
        "180k": 0,
        "185k": 3,
        "190k": 3,
        "195k": 2,
        "200k": 0,
        "350k": 24
      },
      nonRelativem: {
        "5k": 40,
        "10k": 28,
        "15k": 23,
        "20k": 19,
        "25k": 47,
        "30k": 34,
        "35k": 53,
        "40k": 58,
        "45k": 43,
        "50k": 35,
        "55k": 37,
        "60k": 54,
        "65k": 45,
        "70k": 20,
        "75k": 33,
        "80k": 30,
        "85k": 21,
        "90k": 31,
        "95k": 16,
        "100k": 35,
        "105k": 29,
        "110k": 17,
        "115k": 25,
        "120k": 15,
        "125k": 4,
        "130k": 15,
        "135k": 14,
        "140k": 12,
        "145k": 0,
        "150k": 5,
        "155k": 6,
        "160k": 20,
        "165k": 0,
        "170k": 4,
        "175k": 0,
        "180k": 13,
        "185k": 0,
        "190k": 1,
        "195k": 3,
        "200k": 6,
        "350k": 57
      },
      houseLoitererfm: {
        "5k": 221,
        "10k": 106,
        "15k": 164,
        "20k": 120,
        "25k": 183,
        "30k": 164,
        "35k": 131,
        "40k": 114,
        "45k": 88,
        "50k": 84,
        "55k": 85,
        "60k": 86,
        "65k": 95,
        "70k": 61,
        "75k": 75,
        "80k": 68,
        "85k": 20,
        "90k": 42,
        "95k": 42,
        "100k": 13,
        "105k": 31,
        "110k": 17,
        "115k": 4,
        "120k": 5,
        "125k": 10,
        "130k": 6,
        "135k": 18,
        "140k": 4,
        "145k": 6,
        "150k": 18,
        "155k": 12,
        "160k": 9,
        "165k": 8,
        "170k": 16,
        "175k": 6,
        "180k": 4,
        "185k": 4,
        "190k": 0,
        "195k": 0,
        "200k": 8,
        "350k": 30
      },
      spousefm: {
        "5k": 11,
        "10k": 14,
        "15k": 8,
        "20k": 20,
        "25k": 21,
        "30k": 27,
        "35k": 37,
        "40k": 36,
        "45k": 37,
        "50k": 32,
        "55k": 26,
        "60k": 27,
        "65k": 21,
        "70k": 39,
        "75k": 21,
        "80k": 23,
        "85k": 14,
        "90k": 17,
        "95k": 18,
        "100k": 3,
        "105k": 4,
        "110k": 2,
        "115k": 12,
        "120k": 14,
        "125k": 3,
        "130k": 3,
        "135k": 13,
        "140k": 0,
        "145k": 4,
        "150k": 2,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 4,
        "195k": 0,
        "200k": 1,
        "350k": 3
      },
      childfm: {
        "5k": 22,
        "10k": 38,
        "15k": 32,
        "20k": 45,
        "25k": 58,
        "30k": 48,
        "35k": 80,
        "40k": 69,
        "45k": 33,
        "50k": 78,
        "55k": 72,
        "60k": 63,
        "65k": 75,
        "70k": 72,
        "75k": 82,
        "80k": 62,
        "85k": 86,
        "90k": 93,
        "95k": 73,
        "100k": 66,
        "105k": 63,
        "110k": 49,
        "115k": 41,
        "120k": 76,
        "125k": 62,
        "130k": 73,
        "135k": 33,
        "140k": 60,
        "145k": 40,
        "150k": 41,
        "155k": 43,
        "160k": 49,
        "165k": 36,
        "170k": 24,
        "175k": 28,
        "180k": 35,
        "185k": 26,
        "190k": 36,
        "195k": 21,
        "200k": 26,
        "350k": 286
      },
      otherRelativefm: {
        "5k": 11,
        "10k": 6,
        "15k": 5,
        "20k": 10,
        "25k": 26,
        "30k": 14,
        "35k": 18,
        "40k": 15,
        "45k": 33,
        "50k": 9,
        "55k": 3,
        "60k": 24,
        "65k": 22,
        "70k": 25,
        "75k": 23,
        "80k": 17,
        "85k": 14,
        "90k": 20,
        "95k": 21,
        "100k": 10,
        "105k": 14,
        "110k": 13,
        "115k": 1,
        "120k": 15,
        "125k": 12,
        "130k": 15,
        "135k": 2,
        "140k": 1,
        "145k": 3,
        "150k": 2,
        "155k": 3,
        "160k": 7,
        "165k": 0,
        "170k": 0,
        "175k": 3,
        "180k": 7,
        "185k": 2,
        "190k": 5,
        "195k": 6,
        "200k": 0,
        "350k": 29
      },
      nonRelativefm: {
        "5k": 47,
        "10k": 28,
        "15k": 18,
        "20k": 45,
        "25k": 34,
        "30k": 38,
        "35k": 39,
        "40k": 63,
        "45k": 47,
        "50k": 49,
        "55k": 43,
        "60k": 51,
        "65k": 33,
        "70k": 43,
        "75k": 55,
        "80k": 58,
        "85k": 24,
        "90k": 43,
        "95k": 33,
        "100k": 25,
        "105k": 10,
        "110k": 10,
        "115k": 16,
        "120k": 16,
        "125k": 5,
        "130k": 9,
        "135k": 14,
        "140k": 3,
        "145k": 8,
        "150k": 24,
        "155k": 8,
        "160k": 15,
        "165k": 0,
        "170k": 7,
        "175k": 6,
        "180k": 3,
        "185k": 0,
        "190k": 5,
        "195k": 0,
        "200k": 2,
        "350k": 46
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 129,
        "10k": 91,
        "15k": 80,
        "20k": 135,
        "25k": 180,
        "30k": 198,
        "35k": 282,
        "40k": 283,
        "45k": 263,
        "50k": 237,
        "55k": 245,
        "60k": 216,
        "65k": 211,
        "70k": 183,
        "75k": 190,
        "80k": 143,
        "85k": 178,
        "90k": 150,
        "95k": 112,
        "100k": 113,
        "105k": 89,
        "110k": 86,
        "115k": 95,
        "120k": 75,
        "125k": 79,
        "130k": 54,
        "135k": 61,
        "140k": 38,
        "145k": 39,
        "150k": 44,
        "155k": 36,
        "160k": 29,
        "165k": 48,
        "170k": 18,
        "175k": 38,
        "180k": 31,
        "185k": 20,
        "190k": 13,
        "195k": 24,
        "200k": 7,
        "350k": 150
      },
      spousem: {
        "5k": 11,
        "10k": 12,
        "15k": 16,
        "20k": 14,
        "25k": 45,
        "30k": 71,
        "35k": 58,
        "40k": 58,
        "45k": 69,
        "50k": 48,
        "55k": 49,
        "60k": 49,
        "65k": 38,
        "70k": 40,
        "75k": 46,
        "80k": 48,
        "85k": 38,
        "90k": 59,
        "95k": 42,
        "100k": 29,
        "105k": 36,
        "110k": 16,
        "115k": 22,
        "120k": 11,
        "125k": 16,
        "130k": 16,
        "135k": 16,
        "140k": 17,
        "145k": 14,
        "150k": 5,
        "155k": 6,
        "160k": 3,
        "165k": 9,
        "170k": 7,
        "175k": 4,
        "180k": 5,
        "185k": 4,
        "190k": 0,
        "195k": 2,
        "200k": 2,
        "350k": 55
      },
      childm: {
        "5k": 14,
        "10k": 37,
        "15k": 44,
        "20k": 25,
        "25k": 55,
        "30k": 72,
        "35k": 39,
        "40k": 89,
        "45k": 59,
        "50k": 51,
        "55k": 68,
        "60k": 83,
        "65k": 91,
        "70k": 102,
        "75k": 77,
        "80k": 108,
        "85k": 114,
        "90k": 121,
        "95k": 79,
        "100k": 82,
        "105k": 79,
        "110k": 69,
        "115k": 67,
        "120k": 74,
        "125k": 70,
        "130k": 42,
        "135k": 34,
        "140k": 61,
        "145k": 56,
        "150k": 53,
        "155k": 76,
        "160k": 29,
        "165k": 49,
        "170k": 11,
        "175k": 45,
        "180k": 36,
        "185k": 35,
        "190k": 37,
        "195k": 23,
        "200k": 28,
        "350k": 387
      },
      otherRelativem: {
        "5k": 11,
        "10k": 10,
        "15k": 14,
        "20k": 10,
        "25k": 18,
        "30k": 28,
        "35k": 23,
        "40k": 46,
        "45k": 26,
        "50k": 39,
        "55k": 38,
        "60k": 36,
        "65k": 42,
        "70k": 43,
        "75k": 49,
        "80k": 32,
        "85k": 42,
        "90k": 42,
        "95k": 26,
        "100k": 43,
        "105k": 41,
        "110k": 14,
        "115k": 5,
        "120k": 18,
        "125k": 6,
        "130k": 17,
        "135k": 18,
        "140k": 10,
        "145k": 8,
        "150k": 12,
        "155k": 17,
        "160k": 2,
        "165k": 7,
        "170k": 0,
        "175k": 8,
        "180k": 0,
        "185k": 5,
        "190k": 3,
        "195k": 10,
        "200k": 2,
        "350k": 48
      },
      nonRelativem: {
        "5k": 47,
        "10k": 7,
        "15k": 30,
        "20k": 31,
        "25k": 53,
        "30k": 40,
        "35k": 54,
        "40k": 56,
        "45k": 46,
        "50k": 85,
        "55k": 83,
        "60k": 75,
        "65k": 68,
        "70k": 60,
        "75k": 110,
        "80k": 70,
        "85k": 43,
        "90k": 69,
        "95k": 51,
        "100k": 57,
        "105k": 42,
        "110k": 37,
        "115k": 24,
        "120k": 35,
        "125k": 34,
        "130k": 58,
        "135k": 42,
        "140k": 28,
        "145k": 29,
        "150k": 17,
        "155k": 10,
        "160k": 26,
        "165k": 25,
        "170k": 14,
        "175k": 16,
        "180k": 32,
        "185k": 18,
        "190k": 11,
        "195k": 15,
        "200k": 13,
        "350k": 186
      },
      houseLoitererfm: {
        "5k": 265,
        "10k": 144,
        "15k": 232,
        "20k": 255,
        "25k": 288,
        "30k": 314,
        "35k": 313,
        "40k": 264,
        "45k": 249,
        "50k": 212,
        "55k": 180,
        "60k": 171,
        "65k": 181,
        "70k": 140,
        "75k": 142,
        "80k": 133,
        "85k": 108,
        "90k": 131,
        "95k": 122,
        "100k": 87,
        "105k": 105,
        "110k": 50,
        "115k": 67,
        "120k": 40,
        "125k": 45,
        "130k": 55,
        "135k": 39,
        "140k": 32,
        "145k": 27,
        "150k": 16,
        "155k": 35,
        "160k": 27,
        "165k": 24,
        "170k": 34,
        "175k": 17,
        "180k": 8,
        "185k": 22,
        "190k": 16,
        "195k": 14,
        "200k": 13,
        "350k": 141
      },
      spousefm: {
        "5k": 46,
        "10k": 13,
        "15k": 11,
        "20k": 35,
        "25k": 64,
        "30k": 64,
        "35k": 96,
        "40k": 140,
        "45k": 126,
        "50k": 99,
        "55k": 114,
        "60k": 92,
        "65k": 86,
        "70k": 101,
        "75k": 115,
        "80k": 95,
        "85k": 95,
        "90k": 99,
        "95k": 90,
        "100k": 73,
        "105k": 71,
        "110k": 54,
        "115k": 46,
        "120k": 31,
        "125k": 40,
        "130k": 44,
        "135k": 42,
        "140k": 47,
        "145k": 33,
        "150k": 32,
        "155k": 38,
        "160k": 19,
        "165k": 15,
        "170k": 8,
        "175k": 18,
        "180k": 11,
        "185k": 12,
        "190k": 4,
        "195k": 20,
        "200k": 9,
        "350k": 85
      },
      childfm: {
        "5k": 15,
        "10k": 26,
        "15k": 33,
        "20k": 22,
        "25k": 50,
        "30k": 54,
        "35k": 35,
        "40k": 51,
        "45k": 43,
        "50k": 56,
        "55k": 38,
        "60k": 46,
        "65k": 50,
        "70k": 45,
        "75k": 87,
        "80k": 74,
        "85k": 53,
        "90k": 70,
        "95k": 66,
        "100k": 45,
        "105k": 55,
        "110k": 58,
        "115k": 26,
        "120k": 42,
        "125k": 34,
        "130k": 49,
        "135k": 43,
        "140k": 42,
        "145k": 24,
        "150k": 39,
        "155k": 17,
        "160k": 38,
        "165k": 30,
        "170k": 39,
        "175k": 16,
        "180k": 17,
        "185k": 23,
        "190k": 30,
        "195k": 14,
        "200k": 9,
        "350k": 232
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 7,
        "15k": 12,
        "20k": 11,
        "25k": 16,
        "30k": 19,
        "35k": 28,
        "40k": 26,
        "45k": 24,
        "50k": 10,
        "55k": 19,
        "60k": 37,
        "65k": 19,
        "70k": 24,
        "75k": 26,
        "80k": 34,
        "85k": 15,
        "90k": 33,
        "95k": 16,
        "100k": 14,
        "105k": 7,
        "110k": 23,
        "115k": 21,
        "120k": 18,
        "125k": 18,
        "130k": 18,
        "135k": 10,
        "140k": 14,
        "145k": 10,
        "150k": 10,
        "155k": 5,
        "160k": 0,
        "165k": 15,
        "170k": 7,
        "175k": 9,
        "180k": 4,
        "185k": 7,
        "190k": 10,
        "195k": 3,
        "200k": 3,
        "350k": 20
      },
      nonRelativefm: {
        "5k": 56,
        "10k": 14,
        "15k": 23,
        "20k": 45,
        "25k": 49,
        "30k": 37,
        "35k": 70,
        "40k": 66,
        "45k": 63,
        "50k": 83,
        "55k": 91,
        "60k": 65,
        "65k": 67,
        "70k": 68,
        "75k": 67,
        "80k": 36,
        "85k": 66,
        "90k": 37,
        "95k": 35,
        "100k": 31,
        "105k": 42,
        "110k": 27,
        "115k": 52,
        "120k": 33,
        "125k": 34,
        "130k": 14,
        "135k": 21,
        "140k": 9,
        "145k": 18,
        "150k": 7,
        "155k": 18,
        "160k": 26,
        "165k": 23,
        "170k": 9,
        "175k": 10,
        "180k": 12,
        "185k": 6,
        "190k": 15,
        "195k": 13,
        "200k": 26,
        "350k": 103
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 131,
        "10k": 66,
        "15k": 114,
        "20k": 158,
        "25k": 197,
        "30k": 172,
        "35k": 245,
        "40k": 224,
        "45k": 230,
        "50k": 202,
        "55k": 276,
        "60k": 199,
        "65k": 236,
        "70k": 228,
        "75k": 218,
        "80k": 178,
        "85k": 203,
        "90k": 165,
        "95k": 154,
        "100k": 130,
        "105k": 169,
        "110k": 107,
        "115k": 104,
        "120k": 100,
        "125k": 116,
        "130k": 114,
        "135k": 96,
        "140k": 70,
        "145k": 65,
        "150k": 61,
        "155k": 78,
        "160k": 44,
        "165k": 35,
        "170k": 35,
        "175k": 19,
        "180k": 28,
        "185k": 33,
        "190k": 35,
        "195k": 18,
        "200k": 11,
        "350k": 273
      },
      spousem: {
        "5k": 37,
        "10k": 8,
        "15k": 19,
        "20k": 47,
        "25k": 52,
        "30k": 63,
        "35k": 88,
        "40k": 74,
        "45k": 75,
        "50k": 71,
        "55k": 83,
        "60k": 67,
        "65k": 88,
        "70k": 81,
        "75k": 72,
        "80k": 89,
        "85k": 94,
        "90k": 50,
        "95k": 67,
        "100k": 65,
        "105k": 60,
        "110k": 62,
        "115k": 52,
        "120k": 28,
        "125k": 40,
        "130k": 26,
        "135k": 35,
        "140k": 38,
        "145k": 29,
        "150k": 18,
        "155k": 16,
        "160k": 13,
        "165k": 20,
        "170k": 12,
        "175k": 11,
        "180k": 13,
        "185k": 10,
        "190k": 14,
        "195k": 12,
        "200k": 9,
        "350k": 126
      },
      childm: {
        "5k": 25,
        "10k": 28,
        "15k": 40,
        "20k": 33,
        "25k": 32,
        "30k": 36,
        "35k": 60,
        "40k": 41,
        "45k": 32,
        "50k": 77,
        "55k": 41,
        "60k": 54,
        "65k": 71,
        "70k": 37,
        "75k": 44,
        "80k": 65,
        "85k": 60,
        "90k": 47,
        "95k": 47,
        "100k": 37,
        "105k": 49,
        "110k": 38,
        "115k": 29,
        "120k": 54,
        "125k": 41,
        "130k": 28,
        "135k": 37,
        "140k": 23,
        "145k": 19,
        "150k": 17,
        "155k": 2,
        "160k": 21,
        "165k": 22,
        "170k": 25,
        "175k": 26,
        "180k": 12,
        "185k": 4,
        "190k": 15,
        "195k": 16,
        "200k": 11,
        "350k": 122
      },
      otherRelativem: {
        "5k": 8,
        "10k": 3,
        "15k": 8,
        "20k": 17,
        "25k": 10,
        "30k": 25,
        "35k": 17,
        "40k": 18,
        "45k": 20,
        "50k": 12,
        "55k": 26,
        "60k": 22,
        "65k": 25,
        "70k": 21,
        "75k": 26,
        "80k": 31,
        "85k": 13,
        "90k": 22,
        "95k": 23,
        "100k": 21,
        "105k": 6,
        "110k": 30,
        "115k": 9,
        "120k": 19,
        "125k": 9,
        "130k": 24,
        "135k": 12,
        "140k": 17,
        "145k": 13,
        "150k": 4,
        "155k": 15,
        "160k": 15,
        "165k": 5,
        "170k": 0,
        "175k": 14,
        "180k": 15,
        "185k": 1,
        "190k": 7,
        "195k": 3,
        "200k": 3,
        "350k": 56
      },
      nonRelativem: {
        "5k": 37,
        "10k": 2,
        "15k": 26,
        "20k": 21,
        "25k": 39,
        "30k": 35,
        "35k": 27,
        "40k": 42,
        "45k": 31,
        "50k": 28,
        "55k": 44,
        "60k": 24,
        "65k": 44,
        "70k": 64,
        "75k": 65,
        "80k": 54,
        "85k": 31,
        "90k": 50,
        "95k": 41,
        "100k": 49,
        "105k": 35,
        "110k": 30,
        "115k": 28,
        "120k": 18,
        "125k": 8,
        "130k": 15,
        "135k": 15,
        "140k": 15,
        "145k": 16,
        "150k": 8,
        "155k": 6,
        "160k": 7,
        "165k": 6,
        "170k": 10,
        "175k": 16,
        "180k": 10,
        "185k": 4,
        "190k": 7,
        "195k": 10,
        "200k": 4,
        "350k": 103
      },
      houseLoitererfm: {
        "5k": 212,
        "10k": 176,
        "15k": 202,
        "20k": 228,
        "25k": 291,
        "30k": 308,
        "35k": 318,
        "40k": 268,
        "45k": 232,
        "50k": 206,
        "55k": 220,
        "60k": 241,
        "65k": 213,
        "70k": 170,
        "75k": 145,
        "80k": 172,
        "85k": 161,
        "90k": 110,
        "95k": 109,
        "100k": 118,
        "105k": 130,
        "110k": 101,
        "115k": 91,
        "120k": 56,
        "125k": 54,
        "130k": 55,
        "135k": 63,
        "140k": 68,
        "145k": 55,
        "150k": 31,
        "155k": 48,
        "160k": 25,
        "165k": 32,
        "170k": 21,
        "175k": 26,
        "180k": 22,
        "185k": 9,
        "190k": 26,
        "195k": 21,
        "200k": 12,
        "350k": 208
      },
      spousefm: {
        "5k": 42,
        "10k": 15,
        "15k": 42,
        "20k": 64,
        "25k": 86,
        "30k": 71,
        "35k": 123,
        "40k": 99,
        "45k": 87,
        "50k": 121,
        "55k": 124,
        "60k": 120,
        "65k": 111,
        "70k": 116,
        "75k": 140,
        "80k": 130,
        "85k": 139,
        "90k": 119,
        "95k": 107,
        "100k": 116,
        "105k": 118,
        "110k": 78,
        "115k": 93,
        "120k": 85,
        "125k": 89,
        "130k": 53,
        "135k": 49,
        "140k": 65,
        "145k": 55,
        "150k": 54,
        "155k": 60,
        "160k": 48,
        "165k": 55,
        "170k": 33,
        "175k": 24,
        "180k": 24,
        "185k": 29,
        "190k": 32,
        "195k": 25,
        "200k": 13,
        "350k": 236
      },
      childfm: {
        "5k": 3,
        "10k": 8,
        "15k": 20,
        "20k": 21,
        "25k": 15,
        "30k": 34,
        "35k": 20,
        "40k": 29,
        "45k": 25,
        "50k": 19,
        "55k": 30,
        "60k": 36,
        "65k": 33,
        "70k": 29,
        "75k": 38,
        "80k": 45,
        "85k": 23,
        "90k": 28,
        "95k": 31,
        "100k": 21,
        "105k": 31,
        "110k": 19,
        "115k": 10,
        "120k": 26,
        "125k": 30,
        "130k": 19,
        "135k": 20,
        "140k": 15,
        "145k": 13,
        "150k": 7,
        "155k": 16,
        "160k": 10,
        "165k": 6,
        "170k": 3,
        "175k": 25,
        "180k": 12,
        "185k": 7,
        "190k": 8,
        "195k": 14,
        "200k": 9,
        "350k": 92
      },
      otherRelativefm: {
        "5k": 14,
        "10k": 12,
        "15k": 5,
        "20k": 11,
        "25k": 12,
        "30k": 7,
        "35k": 16,
        "40k": 16,
        "45k": 18,
        "50k": 12,
        "55k": 10,
        "60k": 7,
        "65k": 17,
        "70k": 13,
        "75k": 12,
        "80k": 10,
        "85k": 9,
        "90k": 11,
        "95k": 13,
        "100k": 8,
        "105k": 9,
        "110k": 7,
        "115k": 12,
        "120k": 2,
        "125k": 11,
        "130k": 0,
        "135k": 6,
        "140k": 4,
        "145k": 6,
        "150k": 7,
        "155k": 5,
        "160k": 4,
        "165k": 6,
        "170k": 3,
        "175k": 2,
        "180k": 2,
        "185k": 0,
        "190k": 2,
        "195k": 4,
        "200k": 4,
        "350k": 23
      },
      nonRelativefm: {
        "5k": 15,
        "10k": 2,
        "15k": 22,
        "20k": 16,
        "25k": 15,
        "30k": 21,
        "35k": 45,
        "40k": 42,
        "45k": 39,
        "50k": 37,
        "55k": 43,
        "60k": 43,
        "65k": 60,
        "70k": 48,
        "75k": 34,
        "80k": 29,
        "85k": 52,
        "90k": 28,
        "95k": 34,
        "100k": 19,
        "105k": 33,
        "110k": 21,
        "115k": 18,
        "120k": 10,
        "125k": 34,
        "130k": 21,
        "135k": 7,
        "140k": 12,
        "145k": 15,
        "150k": 11,
        "155k": 7,
        "160k": 13,
        "165k": 10,
        "170k": 6,
        "175k": 2,
        "180k": 4,
        "185k": 0,
        "190k": 2,
        "195k": 5,
        "200k": 11,
        "350k": 81
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 233,
        "10k": 189,
        "15k": 164,
        "20k": 266,
        "25k": 293,
        "30k": 367,
        "35k": 448,
        "40k": 336,
        "45k": 348,
        "50k": 403,
        "55k": 457,
        "60k": 376,
        "65k": 376,
        "70k": 357,
        "75k": 395,
        "80k": 395,
        "85k": 349,
        "90k": 304,
        "95k": 282,
        "100k": 342,
        "105k": 337,
        "110k": 249,
        "115k": 251,
        "120k": 226,
        "125k": 263,
        "130k": 209,
        "135k": 175,
        "140k": 158,
        "145k": 174,
        "150k": 156,
        "155k": 176,
        "160k": 156,
        "165k": 146,
        "170k": 81,
        "175k": 100,
        "180k": 84,
        "185k": 83,
        "190k": 63,
        "195k": 83,
        "200k": 50,
        "350k": 942
      },
      spousem: {
        "5k": 49,
        "10k": 29,
        "15k": 64,
        "20k": 98,
        "25k": 82,
        "30k": 125,
        "35k": 180,
        "40k": 129,
        "45k": 158,
        "50k": 164,
        "55k": 155,
        "60k": 141,
        "65k": 199,
        "70k": 163,
        "75k": 152,
        "80k": 196,
        "85k": 180,
        "90k": 152,
        "95k": 146,
        "100k": 157,
        "105k": 186,
        "110k": 171,
        "115k": 178,
        "120k": 112,
        "125k": 138,
        "130k": 113,
        "135k": 86,
        "140k": 109,
        "145k": 70,
        "150k": 82,
        "155k": 93,
        "160k": 73,
        "165k": 72,
        "170k": 43,
        "175k": 47,
        "180k": 46,
        "185k": 40,
        "190k": 24,
        "195k": 55,
        "200k": 30,
        "350k": 554
      },
      childm: {
        "5k": 20,
        "10k": 32,
        "15k": 39,
        "20k": 94,
        "25k": 58,
        "30k": 59,
        "35k": 70,
        "40k": 90,
        "45k": 59,
        "50k": 65,
        "55k": 38,
        "60k": 65,
        "65k": 69,
        "70k": 60,
        "75k": 54,
        "80k": 57,
        "85k": 44,
        "90k": 30,
        "95k": 25,
        "100k": 34,
        "105k": 26,
        "110k": 28,
        "115k": 38,
        "120k": 27,
        "125k": 43,
        "130k": 25,
        "135k": 36,
        "140k": 22,
        "145k": 11,
        "150k": 9,
        "155k": 12,
        "160k": 14,
        "165k": 16,
        "170k": 6,
        "175k": 10,
        "180k": 9,
        "185k": 16,
        "190k": 1,
        "195k": 14,
        "200k": 6,
        "350k": 130
      },
      otherRelativem: {
        "5k": 15,
        "10k": 5,
        "15k": 16,
        "20k": 32,
        "25k": 20,
        "30k": 35,
        "35k": 28,
        "40k": 42,
        "45k": 24,
        "50k": 62,
        "55k": 42,
        "60k": 33,
        "65k": 45,
        "70k": 18,
        "75k": 38,
        "80k": 42,
        "85k": 29,
        "90k": 22,
        "95k": 31,
        "100k": 25,
        "105k": 17,
        "110k": 27,
        "115k": 25,
        "120k": 14,
        "125k": 4,
        "130k": 6,
        "135k": 12,
        "140k": 12,
        "145k": 13,
        "150k": 9,
        "155k": 17,
        "160k": 10,
        "165k": 8,
        "170k": 7,
        "175k": 5,
        "180k": 11,
        "185k": 7,
        "190k": 6,
        "195k": 6,
        "200k": 4,
        "350k": 65
      },
      nonRelativem: {
        "5k": 33,
        "10k": 12,
        "15k": 30,
        "20k": 41,
        "25k": 37,
        "30k": 40,
        "35k": 64,
        "40k": 66,
        "45k": 62,
        "50k": 55,
        "55k": 69,
        "60k": 52,
        "65k": 47,
        "70k": 56,
        "75k": 48,
        "80k": 61,
        "85k": 58,
        "90k": 31,
        "95k": 41,
        "100k": 37,
        "105k": 40,
        "110k": 23,
        "115k": 4,
        "120k": 31,
        "125k": 24,
        "130k": 22,
        "135k": 23,
        "140k": 17,
        "145k": 12,
        "150k": 16,
        "155k": 13,
        "160k": 5,
        "165k": 5,
        "170k": 9,
        "175k": 14,
        "180k": 2,
        "185k": 11,
        "190k": 14,
        "195k": 6,
        "200k": 3,
        "350k": 127
      },
      houseLoitererfm: {
        "5k": 348,
        "10k": 347,
        "15k": 413,
        "20k": 457,
        "25k": 534,
        "30k": 444,
        "35k": 491,
        "40k": 442,
        "45k": 451,
        "50k": 415,
        "55k": 385,
        "60k": 344,
        "65k": 372,
        "70k": 292,
        "75k": 328,
        "80k": 324,
        "85k": 285,
        "90k": 245,
        "95k": 225,
        "100k": 243,
        "105k": 294,
        "110k": 220,
        "115k": 186,
        "120k": 170,
        "125k": 211,
        "130k": 128,
        "135k": 121,
        "140k": 114,
        "145k": 93,
        "150k": 104,
        "155k": 119,
        "160k": 76,
        "165k": 85,
        "170k": 52,
        "175k": 60,
        "180k": 61,
        "185k": 40,
        "190k": 31,
        "195k": 76,
        "200k": 42,
        "350k": 711
      },
      spousefm: {
        "5k": 48,
        "10k": 51,
        "15k": 60,
        "20k": 111,
        "25k": 137,
        "30k": 157,
        "35k": 161,
        "40k": 169,
        "45k": 197,
        "50k": 178,
        "55k": 253,
        "60k": 199,
        "65k": 231,
        "70k": 226,
        "75k": 251,
        "80k": 263,
        "85k": 261,
        "90k": 199,
        "95k": 208,
        "100k": 213,
        "105k": 264,
        "110k": 227,
        "115k": 224,
        "120k": 177,
        "125k": 187,
        "130k": 162,
        "135k": 150,
        "140k": 144,
        "145k": 143,
        "150k": 118,
        "155k": 165,
        "160k": 135,
        "165k": 121,
        "170k": 81,
        "175k": 72,
        "180k": 79,
        "185k": 87,
        "190k": 51,
        "195k": 67,
        "200k": 56,
        "350k": 889
      },
      childfm: {
        "5k": 15,
        "10k": 12,
        "15k": 20,
        "20k": 19,
        "25k": 37,
        "30k": 19,
        "35k": 42,
        "40k": 36,
        "45k": 37,
        "50k": 34,
        "55k": 30,
        "60k": 37,
        "65k": 35,
        "70k": 23,
        "75k": 43,
        "80k": 21,
        "85k": 36,
        "90k": 29,
        "95k": 29,
        "100k": 7,
        "105k": 26,
        "110k": 20,
        "115k": 26,
        "120k": 17,
        "125k": 15,
        "130k": 27,
        "135k": 10,
        "140k": 11,
        "145k": 8,
        "150k": 7,
        "155k": 9,
        "160k": 15,
        "165k": 6,
        "170k": 3,
        "175k": 10,
        "180k": 6,
        "185k": 6,
        "190k": 3,
        "195k": 3,
        "200k": 3,
        "350k": 64
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 19,
        "15k": 13,
        "20k": 20,
        "25k": 28,
        "30k": 47,
        "35k": 44,
        "40k": 48,
        "45k": 22,
        "50k": 39,
        "55k": 34,
        "60k": 36,
        "65k": 34,
        "70k": 32,
        "75k": 24,
        "80k": 17,
        "85k": 21,
        "90k": 20,
        "95k": 23,
        "100k": 16,
        "105k": 21,
        "110k": 14,
        "115k": 7,
        "120k": 5,
        "125k": 16,
        "130k": 13,
        "135k": 11,
        "140k": 6,
        "145k": 13,
        "150k": 8,
        "155k": 9,
        "160k": 4,
        "165k": 5,
        "170k": 3,
        "175k": 3,
        "180k": 7,
        "185k": 4,
        "190k": 8,
        "195k": 6,
        "200k": 0,
        "350k": 44
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 14,
        "15k": 16,
        "20k": 38,
        "25k": 37,
        "30k": 41,
        "35k": 40,
        "40k": 52,
        "45k": 26,
        "50k": 44,
        "55k": 55,
        "60k": 53,
        "65k": 44,
        "70k": 56,
        "75k": 47,
        "80k": 33,
        "85k": 32,
        "90k": 54,
        "95k": 47,
        "100k": 36,
        "105k": 27,
        "110k": 7,
        "115k": 22,
        "120k": 19,
        "125k": 30,
        "130k": 20,
        "135k": 5,
        "140k": 27,
        "145k": 10,
        "150k": 20,
        "155k": 15,
        "160k": 20,
        "165k": 9,
        "170k": 6,
        "175k": 17,
        "180k": 2,
        "185k": 3,
        "190k": 11,
        "195k": 4,
        "200k": 5,
        "350k": 74
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 302,
        "10k": 244,
        "15k": 262,
        "20k": 347,
        "25k": 368,
        "30k": 340,
        "35k": 428,
        "40k": 402,
        "45k": 425,
        "50k": 375,
        "55k": 520,
        "60k": 360,
        "65k": 450,
        "70k": 407,
        "75k": 374,
        "80k": 384,
        "85k": 400,
        "90k": 318,
        "95k": 367,
        "100k": 255,
        "105k": 304,
        "110k": 305,
        "115k": 261,
        "120k": 211,
        "125k": 263,
        "130k": 230,
        "135k": 158,
        "140k": 234,
        "145k": 175,
        "150k": 140,
        "155k": 228,
        "160k": 174,
        "165k": 154,
        "170k": 132,
        "175k": 120,
        "180k": 111,
        "185k": 110,
        "190k": 86,
        "195k": 69,
        "200k": 65,
        "350k": "1,245"
      },
      spousem: {
        "5k": 41,
        "10k": 21,
        "15k": 63,
        "20k": 86,
        "25k": 74,
        "30k": 114,
        "35k": 147,
        "40k": 123,
        "45k": 148,
        "50k": 139,
        "55k": 165,
        "60k": 140,
        "65k": 145,
        "70k": 181,
        "75k": 181,
        "80k": 167,
        "85k": 178,
        "90k": 197,
        "95k": 150,
        "100k": 147,
        "105k": 188,
        "110k": 150,
        "115k": 120,
        "120k": 94,
        "125k": 133,
        "130k": 131,
        "135k": 132,
        "140k": 86,
        "145k": 102,
        "150k": 83,
        "155k": 106,
        "160k": 102,
        "165k": 110,
        "170k": 61,
        "175k": 92,
        "180k": 54,
        "185k": 55,
        "190k": 50,
        "195k": 55,
        "200k": 23,
        "350k": "723"
      },
      childm: {
        "5k": 16,
        "10k": 21,
        "15k": 30,
        "20k": 62,
        "25k": 57,
        "30k": 52,
        "35k": 71,
        "40k": 86,
        "45k": 69,
        "50k": 55,
        "55k": 34,
        "60k": 36,
        "65k": 57,
        "70k": 32,
        "75k": 35,
        "80k": 19,
        "85k": 43,
        "90k": 19,
        "95k": 42,
        "100k": 19,
        "105k": 15,
        "110k": 18,
        "115k": 21,
        "120k": 25,
        "125k": 29,
        "130k": 17,
        "135k": 17,
        "140k": 8,
        "145k": 3,
        "150k": 17,
        "155k": 1,
        "160k": 5,
        "165k": 4,
        "170k": 6,
        "175k": 1,
        "180k": 6,
        "185k": 7,
        "190k": 1,
        "195k": 8,
        "200k": 6,
        "350k": "73"
      },
      otherRelativem: {
        "5k": 25,
        "10k": 22,
        "15k": 9,
        "20k": 40,
        "25k": 41,
        "30k": 48,
        "35k": 35,
        "40k": 46,
        "45k": 48,
        "50k": 57,
        "55k": 35,
        "60k": 56,
        "65k": 72,
        "70k": 38,
        "75k": 64,
        "80k": 54,
        "85k": 45,
        "90k": 47,
        "95k": 45,
        "100k": 29,
        "105k": 36,
        "110k": 38,
        "115k": 13,
        "120k": 17,
        "125k": 36,
        "130k": 16,
        "135k": 4,
        "140k": 25,
        "145k": 6,
        "150k": 13,
        "155k": 13,
        "160k": 8,
        "165k": 34,
        "170k": 7,
        "175k": 14,
        "180k": 13,
        "185k": 8,
        "190k": 9,
        "195k": 4,
        "200k": 5,
        "350k": "72"
      },
      nonRelativem: {
        "5k": 29,
        "10k": 9,
        "15k": 29,
        "20k": 30,
        "25k": 19,
        "30k": 30,
        "35k": 27,
        "40k": 50,
        "45k": 36,
        "50k": 44,
        "55k": 23,
        "60k": 47,
        "65k": 61,
        "70k": 56,
        "75k": 38,
        "80k": 42,
        "85k": 44,
        "90k": 40,
        "95k": 32,
        "100k": 38,
        "105k": 23,
        "110k": 33,
        "115k": 28,
        "120k": 12,
        "125k": 20,
        "130k": 23,
        "135k": 8,
        "140k": 11,
        "145k": 14,
        "150k": 6,
        "155k": 4,
        "160k": 9,
        "165k": 16,
        "170k": 19,
        "175k": 20,
        "180k": 9,
        "185k": 5,
        "190k": 9,
        "195k": 4,
        "200k": 3,
        "350k": "83"
      },
      houseLoitererfm: {
        "5k": 370,
        "10k": 415,
        "15k": 446,
        "20k": 423,
        "25k": 439,
        "30k": 402,
        "35k": 472,
        "40k": 533,
        "45k": 442,
        "50k": 384,
        "55k": 444,
        "60k": 334,
        "65k": 384,
        "70k": 340,
        "75k": 343,
        "80k": 354,
        "85k": 333,
        "90k": 297,
        "95k": 247,
        "100k": 213,
        "105k": 282,
        "110k": 245,
        "115k": 210,
        "120k": 146,
        "125k": 195,
        "130k": 177,
        "135k": 167,
        "140k": 125,
        "145k": 135,
        "150k": 106,
        "155k": 151,
        "160k": 135,
        "165k": 130,
        "170k": 80,
        "175k": 117,
        "180k": 75,
        "185k": 90,
        "190k": 54,
        "195k": 66,
        "200k": 34,
        "350k": "855"
      },
      spousefm: {
        "5k": 63,
        "10k": 37,
        "15k": 60,
        "20k": 108,
        "25k": 123,
        "30k": 141,
        "35k": 163,
        "40k": 167,
        "45k": 192,
        "50k": 202,
        "55k": 245,
        "60k": 195,
        "65k": 226,
        "70k": 231,
        "75k": 264,
        "80k": 287,
        "85k": 240,
        "90k": 225,
        "95k": 269,
        "100k": 232,
        "105k": 220,
        "110k": 282,
        "115k": 199,
        "120k": 190,
        "125k": 250,
        "130k": 202,
        "135k": 163,
        "140k": 175,
        "145k": 156,
        "150k": 134,
        "155k": 170,
        "160k": 136,
        "165k": 113,
        "170k": 112,
        "175k": 96,
        "180k": 82,
        "185k": 107,
        "190k": 86,
        "195k": 59,
        "200k": 58,
        "350k": "1,142"
      },
      childfm: {
        "5k": 13,
        "10k": 1,
        "15k": 21,
        "20k": 25,
        "25k": 26,
        "30k": 32,
        "35k": 38,
        "40k": 39,
        "45k": 33,
        "50k": 32,
        "55k": 34,
        "60k": 26,
        "65k": 21,
        "70k": 19,
        "75k": 23,
        "80k": 11,
        "85k": 24,
        "90k": 17,
        "95k": 15,
        "100k": 11,
        "105k": 12,
        "110k": 6,
        "115k": 24,
        "120k": 5,
        "125k": 12,
        "130k": 15,
        "135k": 6,
        "140k": 12,
        "145k": 2,
        "150k": 2,
        "155k": 8,
        "160k": 4,
        "165k": 8,
        "170k": 2,
        "175k": 2,
        "180k": 4,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 4,
        "350k": "23"
      },
      otherRelativefm: {
        "5k": 17,
        "10k": 32,
        "15k": 26,
        "20k": 40,
        "25k": 59,
        "30k": 50,
        "35k": 53,
        "40k": 65,
        "45k": 65,
        "50k": 41,
        "55k": 53,
        "60k": 37,
        "65k": 60,
        "70k": 28,
        "75k": 50,
        "80k": 57,
        "85k": 51,
        "90k": 29,
        "95k": 33,
        "100k": 32,
        "105k": 28,
        "110k": 35,
        "115k": 24,
        "120k": 33,
        "125k": 14,
        "130k": 32,
        "135k": 4,
        "140k": 11,
        "145k": 11,
        "150k": 8,
        "155k": 15,
        "160k": 12,
        "165k": 9,
        "170k": 10,
        "175k": 17,
        "180k": 9,
        "185k": 11,
        "190k": 14,
        "195k": 4,
        "200k": 3,
        "350k": "80"
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 4,
        "15k": 14,
        "20k": 25,
        "25k": 28,
        "30k": 23,
        "35k": 37,
        "40k": 29,
        "45k": 47,
        "50k": 27,
        "55k": 32,
        "60k": 35,
        "65k": 43,
        "70k": 45,
        "75k": 39,
        "80k": 24,
        "85k": 17,
        "90k": 28,
        "95k": 16,
        "100k": 21,
        "105k": 20,
        "110k": 20,
        "115k": 25,
        "120k": 18,
        "125k": 19,
        "130k": 11,
        "135k": 11,
        "140k": 21,
        "145k": 10,
        "150k": 9,
        "155k": 13,
        "160k": 8,
        "165k": 3,
        "170k": 15,
        "175k": 6,
        "180k": 6,
        "185k": 5,
        "190k": 0,
        "195k": 2,
        "200k": 1,
        "350k": "72"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 449,
        "10k": 366,
        "15k": 499,
        "20k": 508,
        "25k": 478,
        "30k": 436,
        "35k": 463,
        "40k": 414,
        "45k": 448,
        "50k": 445,
        "55k": 486,
        "60k": 367,
        "65k": 381,
        "70k": 362,
        "75k": 378,
        "80k": 412,
        "85k": 322,
        "90k": 321,
        "95k": 359,
        "100k": 237,
        "105k": 330,
        "110k": 289,
        "115k": 284,
        "120k": 228,
        "125k": 233,
        "130k": 214,
        "135k": 199,
        "140k": 154,
        "145k": 153,
        "150k": 148,
        "155k": 159,
        "160k": 100,
        "165k": 109,
        "170k": 106,
        "175k": 102,
        "180k": 96,
        "185k": 116,
        "190k": 65,
        "195k": 65,
        "200k": 69,
        "350k": "1,183"
      },
      spousem: {
        "5k": 74,
        "10k": 50,
        "15k": 88,
        "20k": 93,
        "25k": 110,
        "30k": 117,
        "35k": 108,
        "40k": 128,
        "45k": 142,
        "50k": 134,
        "55k": 202,
        "60k": 197,
        "65k": 203,
        "70k": 157,
        "75k": 142,
        "80k": 192,
        "85k": 159,
        "90k": 157,
        "95k": 120,
        "100k": 125,
        "105k": 141,
        "110k": 134,
        "115k": 129,
        "120k": 121,
        "125k": 139,
        "130k": 107,
        "135k": 75,
        "140k": 133,
        "145k": 66,
        "150k": 73,
        "155k": 87,
        "160k": 64,
        "165k": 77,
        "170k": 66,
        "175k": 72,
        "180k": 53,
        "185k": 56,
        "190k": 56,
        "195k": 38,
        "200k": 39,
        "350k": "719"
      },
      childm: {
        "5k": 3,
        "10k": 14,
        "15k": 18,
        "20k": 32,
        "25k": 22,
        "30k": 27,
        "35k": 22,
        "40k": 27,
        "45k": 38,
        "50k": 23,
        "55k": 24,
        "60k": 14,
        "65k": 15,
        "70k": 8,
        "75k": 9,
        "80k": 2,
        "85k": 22,
        "90k": 18,
        "95k": 13,
        "100k": 3,
        "105k": 2,
        "110k": 3,
        "115k": 16,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 2,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 5,
        "160k": 3,
        "165k": 3,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "7"
      },
      otherRelativem: {
        "5k": 9,
        "10k": 19,
        "15k": 13,
        "20k": 37,
        "25k": 39,
        "30k": 32,
        "35k": 59,
        "40k": 35,
        "45k": 42,
        "50k": 39,
        "55k": 25,
        "60k": 33,
        "65k": 26,
        "70k": 25,
        "75k": 38,
        "80k": 25,
        "85k": 47,
        "90k": 30,
        "95k": 33,
        "100k": 25,
        "105k": 7,
        "110k": 23,
        "115k": 17,
        "120k": 12,
        "125k": 15,
        "130k": 14,
        "135k": 23,
        "140k": 9,
        "145k": 24,
        "150k": 16,
        "155k": 22,
        "160k": 9,
        "165k": 4,
        "170k": 20,
        "175k": 16,
        "180k": 10,
        "185k": 3,
        "190k": 9,
        "195k": 5,
        "200k": 7,
        "350k": "83"
      },
      nonRelativem: {
        "5k": 10,
        "10k": 7,
        "15k": 22,
        "20k": 32,
        "25k": 17,
        "30k": 20,
        "35k": 26,
        "40k": 43,
        "45k": 27,
        "50k": 18,
        "55k": 49,
        "60k": 37,
        "65k": 24,
        "70k": 27,
        "75k": 35,
        "80k": 23,
        "85k": 24,
        "90k": 18,
        "95k": 10,
        "100k": 12,
        "105k": 20,
        "110k": 15,
        "115k": 23,
        "120k": 21,
        "125k": 14,
        "130k": 7,
        "135k": 9,
        "140k": 13,
        "145k": 5,
        "150k": 15,
        "155k": 5,
        "160k": 4,
        "165k": 6,
        "170k": 16,
        "175k": 2,
        "180k": 4,
        "185k": 2,
        "190k": 0,
        "195k": 5,
        "200k": 3,
        "350k": "46"
      },
      houseLoitererfm: {
        "5k": 483,
        "10k": 519,
        "15k": 674,
        "20k": 523,
        "25k": 589,
        "30k": 573,
        "35k": 577,
        "40k": 458,
        "45k": 449,
        "50k": 455,
        "55k": 451,
        "60k": 352,
        "65k": 373,
        "70k": 305,
        "75k": 291,
        "80k": 311,
        "85k": 275,
        "90k": 254,
        "95k": 219,
        "100k": 224,
        "105k": 208,
        "110k": 187,
        "115k": 205,
        "120k": 160,
        "125k": 199,
        "130k": 140,
        "135k": 111,
        "140k": 164,
        "145k": 89,
        "150k": 97,
        "155k": 104,
        "160k": 90,
        "165k": 90,
        "170k": 80,
        "175k": 87,
        "180k": 61,
        "185k": 39,
        "190k": 66,
        "195k": 53,
        "200k": 46,
        "350k": "731"
      },
      spousefm: {
        "5k": 92,
        "10k": 56,
        "15k": 125,
        "20k": 147,
        "25k": 201,
        "30k": 224,
        "35k": 230,
        "40k": 252,
        "45k": 236,
        "50k": 248,
        "55k": 286,
        "60k": 266,
        "65k": 262,
        "70k": 258,
        "75k": 251,
        "80k": 295,
        "85k": 252,
        "90k": 232,
        "95k": 262,
        "100k": 185,
        "105k": 244,
        "110k": 173,
        "115k": 254,
        "120k": 163,
        "125k": 171,
        "130k": 139,
        "135k": 169,
        "140k": 113,
        "145k": 133,
        "150k": 109,
        "155k": 126,
        "160k": 79,
        "165k": 82,
        "170k": 76,
        "175k": 100,
        "180k": 119,
        "185k": 95,
        "190k": 40,
        "195k": 69,
        "200k": 57,
        "350k": "957"
      },
      childfm: {
        "5k": 2,
        "10k": 0,
        "15k": 9,
        "20k": 11,
        "25k": 5,
        "30k": 19,
        "35k": 21,
        "40k": 24,
        "45k": 16,
        "50k": 12,
        "55k": 18,
        "60k": 5,
        "65k": 14,
        "70k": 19,
        "75k": 10,
        "80k": 21,
        "85k": 11,
        "90k": 3,
        "95k": 11,
        "100k": 11,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 4,
        "125k": 2,
        "130k": 0,
        "135k": 9,
        "140k": 2,
        "145k": 0,
        "150k": 3,
        "155k": 9,
        "160k": 0,
        "165k": 2,
        "170k": 2,
        "175k": 0,
        "180k": 1,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 4,
        "350k": "18"
      },
      otherRelativefm: {
        "5k": 17,
        "10k": 16,
        "15k": 18,
        "20k": 34,
        "25k": 45,
        "30k": 54,
        "35k": 40,
        "40k": 44,
        "45k": 52,
        "50k": 64,
        "55k": 25,
        "60k": 46,
        "65k": 49,
        "70k": 39,
        "75k": 62,
        "80k": 34,
        "85k": 59,
        "90k": 48,
        "95k": 32,
        "100k": 34,
        "105k": 23,
        "110k": 38,
        "115k": 29,
        "120k": 20,
        "125k": 29,
        "130k": 31,
        "135k": 6,
        "140k": 11,
        "145k": 22,
        "150k": 14,
        "155k": 28,
        "160k": 5,
        "165k": 10,
        "170k": 20,
        "175k": 13,
        "180k": 12,
        "185k": 2,
        "190k": 13,
        "195k": 13,
        "200k": 10,
        "350k": "76"
      },
      nonRelativefm: {
        "5k": 9,
        "10k": 2,
        "15k": 14,
        "20k": 24,
        "25k": 35,
        "30k": 26,
        "35k": 34,
        "40k": 31,
        "45k": 30,
        "50k": 15,
        "55k": 38,
        "60k": 19,
        "65k": 30,
        "70k": 22,
        "75k": 23,
        "80k": 24,
        "85k": 17,
        "90k": 9,
        "95k": 11,
        "100k": 17,
        "105k": 21,
        "110k": 13,
        "115k": 5,
        "120k": 13,
        "125k": 3,
        "130k": 8,
        "135k": 4,
        "140k": 6,
        "145k": 15,
        "150k": 6,
        "155k": 4,
        "160k": 4,
        "165k": 6,
        "170k": 5,
        "175k": 5,
        "180k": 4,
        "185k": 7,
        "190k": 0,
        "195k": 0,
        "200k": 7,
        "350k": "51"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 163,
        "10k": 179,
        "15k": 482,
        "20k": 454,
        "25k": 521,
        "30k": 450,
        "35k": 449,
        "40k": 527,
        "45k": 314,
        "50k": 439,
        "55k": 375,
        "60k": 297,
        "65k": 331,
        "70k": 252,
        "75k": 253,
        "80k": 255,
        "85k": 243,
        "90k": 169,
        "95k": 228,
        "100k": 180,
        "105k": 205,
        "110k": 179,
        "115k": 154,
        "120k": 121,
        "125k": 97,
        "130k": 127,
        "135k": 110,
        "140k": 90,
        "145k": 100,
        "150k": 90,
        "155k": 96,
        "160k": 57,
        "165k": 41,
        "170k": 39,
        "175k": 83,
        "180k": 63,
        "185k": 48,
        "190k": 36,
        "195k": 39,
        "200k": 35,
        "350k": 558
      },
      spousem: {
        "5k": 62,
        "10k": 23,
        "15k": 56,
        "20k": 101,
        "25k": 154,
        "30k": 150,
        "35k": 175,
        "40k": 143,
        "45k": 127,
        "50k": 132,
        "55k": 124,
        "60k": 124,
        "65k": 107,
        "70k": 95,
        "75k": 125,
        "80k": 101,
        "85k": 102,
        "90k": 98,
        "95k": 106,
        "100k": 80,
        "105k": 64,
        "110k": 42,
        "115k": 74,
        "120k": 68,
        "125k": 68,
        "130k": 26,
        "135k": 47,
        "140k": 46,
        "145k": 44,
        "150k": 33,
        "155k": 32,
        "160k": 44,
        "165k": 30,
        "170k": 24,
        "175k": 22,
        "180k": 18,
        "185k": 21,
        "190k": 17,
        "195k": 26,
        "200k": 20,
        "350k": 269
      },
      childm: {
        "5k": 0,
        "10k": 2,
        "15k": 1,
        "20k": 1,
        "25k": 1,
        "30k": 2,
        "35k": 6,
        "40k": 0,
        "45k": 7,
        "50k": 5,
        "55k": 1,
        "60k": 0,
        "65k": 1,
        "70k": 1,
        "75k": 0,
        "80k": 3,
        "85k": 2,
        "90k": 0,
        "95k": 3,
        "100k": 3,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 2,
        "10k": 8,
        "15k": 17,
        "20k": 8,
        "25k": 23,
        "30k": 20,
        "35k": 25,
        "40k": 22,
        "45k": 19,
        "50k": 18,
        "55k": 21,
        "60k": 12,
        "65k": 29,
        "70k": 16,
        "75k": 9,
        "80k": 24,
        "85k": 12,
        "90k": 20,
        "95k": 13,
        "100k": 11,
        "105k": 8,
        "110k": 29,
        "115k": 8,
        "120k": 15,
        "125k": 16,
        "130k": 9,
        "135k": 3,
        "140k": 4,
        "145k": 10,
        "150k": 8,
        "155k": 11,
        "160k": 1,
        "165k": 6,
        "170k": 6,
        "175k": 4,
        "180k": 8,
        "185k": 2,
        "190k": 2,
        "195k": 2,
        "200k": 4,
        "350k": 43
      },
      nonRelativem: {
        "5k": 2,
        "10k": 5,
        "15k": 12,
        "20k": 8,
        "25k": 19,
        "30k": 17,
        "35k": 12,
        "40k": 8,
        "45k": 14,
        "50k": 8,
        "55k": 0,
        "60k": 8,
        "65k": 6,
        "70k": 4,
        "75k": 2,
        "80k": 16,
        "85k": 7,
        "90k": 3,
        "95k": 7,
        "100k": 2,
        "105k": 8,
        "110k": 5,
        "115k": 1,
        "120k": 0,
        "125k": 12,
        "130k": 8,
        "135k": 3,
        "140k": 1,
        "145k": 0,
        "150k": 0,
        "155k": 5,
        "160k": 0,
        "165k": 2,
        "170k": 7,
        "175k": 3,
        "180k": 0,
        "185k": 0,
        "190k": 6,
        "195k": 6,
        "200k": 0,
        "350k": 9
      },
      houseLoitererfm: {
        "5k": 266,
        "10k": 369,
        "15k": 797,
        "20k": 707,
        "25k": 679,
        "30k": 676,
        "35k": 474,
        "40k": 501,
        "45k": 387,
        "50k": 341,
        "55k": 284,
        "60k": 268,
        "65k": 253,
        "70k": 226,
        "75k": 200,
        "80k": 177,
        "85k": 202,
        "90k": 140,
        "95k": 138,
        "100k": 118,
        "105k": 153,
        "110k": 79,
        "115k": 108,
        "120k": 102,
        "125k": 118,
        "130k": 82,
        "135k": 56,
        "140k": 68,
        "145k": 46,
        "150k": 58,
        "155k": 32,
        "160k": 46,
        "165k": 43,
        "170k": 35,
        "175k": 15,
        "180k": 29,
        "185k": 31,
        "190k": 14,
        "195k": 15,
        "200k": 16,
        "350k": 271
      },
      spousefm: {
        "5k": 61,
        "10k": 27,
        "15k": 63,
        "20k": 147,
        "25k": 232,
        "30k": 298,
        "35k": 256,
        "40k": 307,
        "45k": 232,
        "50k": 303,
        "55k": 226,
        "60k": 201,
        "65k": 218,
        "70k": 157,
        "75k": 146,
        "80k": 183,
        "85k": 137,
        "90k": 137,
        "95k": 162,
        "100k": 101,
        "105k": 139,
        "110k": 123,
        "115k": 98,
        "120k": 82,
        "125k": 57,
        "130k": 80,
        "135k": 47,
        "140k": 74,
        "145k": 35,
        "150k": 49,
        "155k": 45,
        "160k": 29,
        "165k": 33,
        "170k": 29,
        "175k": 49,
        "180k": 27,
        "185k": 35,
        "190k": 28,
        "195k": 13,
        "200k": 20,
        "350k": 312
      },
      childfm: {
        "5k": 3,
        "10k": 0,
        "15k": 0,
        "20k": 6,
        "25k": 0,
        "30k": 7,
        "35k": 7,
        "40k": 9,
        "45k": 5,
        "50k": 6,
        "55k": 4,
        "60k": 0,
        "65k": 2,
        "70k": 1,
        "75k": 6,
        "80k": 0,
        "85k": 0,
        "90k": 3,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 2,
        "125k": 0,
        "130k": 0,
        "135k": 1,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 1,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 9,
        "15k": 21,
        "20k": 30,
        "25k": 42,
        "30k": 36,
        "35k": 36,
        "40k": 52,
        "45k": 47,
        "50k": 40,
        "55k": 57,
        "60k": 32,
        "65k": 32,
        "70k": 41,
        "75k": 22,
        "80k": 27,
        "85k": 24,
        "90k": 36,
        "95k": 18,
        "100k": 23,
        "105k": 25,
        "110k": 18,
        "115k": 14,
        "120k": 21,
        "125k": 16,
        "130k": 11,
        "135k": 8,
        "140k": 8,
        "145k": 24,
        "150k": 15,
        "155k": 11,
        "160k": 7,
        "165k": 22,
        "170k": 16,
        "175k": 11,
        "180k": 10,
        "185k": 4,
        "190k": 5,
        "195k": 11,
        "200k": 6,
        "350k": 89
      },
      nonRelativefm: {
        "5k": 7,
        "10k": 5,
        "15k": 2,
        "20k": 5,
        "25k": 19,
        "30k": 14,
        "35k": 20,
        "40k": 19,
        "45k": 15,
        "50k": 13,
        "55k": 17,
        "60k": 3,
        "65k": 8,
        "70k": 9,
        "75k": 2,
        "80k": 8,
        "85k": 15,
        "90k": 6,
        "95k": 3,
        "100k": 2,
        "105k": 10,
        "110k": 3,
        "115k": 4,
        "120k": 8,
        "125k": 14,
        "130k": 0,
        "135k": 8,
        "140k": 3,
        "145k": 4,
        "150k": 5,
        "155k": 3,
        "160k": 2,
        "165k": 2,
        "170k": 3,
        "175k": 0,
        "180k": 4,
        "185k": 1,
        "190k": 0,
        "195k": 2,
        "200k": 4,
        "350k": 19
      }
    }
  },
  "2016": {
    "17": {
      houseLoitererm: {
        "5k": 6,
        "10k": 2,
        "15k": 8,
        "20k": 3,
        "25k": 7,
        "30k": 3,
        "35k": 6,
        "40k": 3,
        "45k": 5,
        "50k": 5,
        "55k": 2,
        "60k": 0,
        "65k": 3,
        "70k": 7,
        "75k": 2,
        "80k": 2,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 2,
        "110k": 0,
        "115k": 0,
        "120k": 3,
        "125k": 0,
        "130k": 0,
        "135k": 2,
        "140k": 2,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 1,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": "5"
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childm: {
        "5k": 265,
        "10k": 204,
        "15k": 248,
        "20k": 293,
        "25k": 351,
        "30k": 388,
        "35k": 444,
        "40k": 457,
        "45k": 443,
        "50k": 416,
        "55k": 421,
        "60k": 363,
        "65k": 373,
        "70k": 369,
        "75k": 330,
        "80k": 376,
        "85k": 327,
        "90k": 285,
        "95k": 340,
        "100k": 292,
        "105k": 292,
        "110k": 190,
        "115k": 253,
        "120k": 209,
        "125k": 248,
        "130k": 219,
        "135k": 194,
        "140k": 156,
        "145k": 174,
        "150k": 144,
        "155k": 181,
        "160k": 124,
        "165k": 156,
        "170k": 115,
        "175k": 111,
        "180k": 97,
        "185k": 97,
        "190k": 82,
        "195k": 73,
        "200k": 55,
        "350k": "1,270"
      },
      otherRelativem: {
        "5k": 42,
        "10k": 25,
        "15k": 58,
        "20k": 47,
        "25k": 57,
        "30k": 54,
        "35k": 56,
        "40k": 58,
        "45k": 58,
        "50k": 57,
        "55k": 81,
        "60k": 42,
        "65k": 54,
        "70k": 39,
        "75k": 41,
        "80k": 26,
        "85k": 34,
        "90k": 30,
        "95k": 30,
        "100k": 33,
        "105k": 18,
        "110k": 11,
        "115k": 25,
        "120k": 20,
        "125k": 26,
        "130k": 10,
        "135k": 9,
        "140k": 17,
        "145k": 10,
        "150k": 11,
        "155k": 12,
        "160k": 8,
        "165k": 7,
        "170k": 7,
        "175k": 4,
        "180k": 8,
        "185k": 1,
        "190k": 11,
        "195k": 1,
        "200k": 6,
        "350k": "61"
      },
      nonRelativem: {
        "5k": 3,
        "10k": 10,
        "15k": 2,
        "20k": 8,
        "25k": 10,
        "30k": 7,
        "35k": 8,
        "40k": 8,
        "45k": 10,
        "50k": 10,
        "55k": 15,
        "60k": 7,
        "65k": 4,
        "70k": 8,
        "75k": 4,
        "80k": 7,
        "85k": 5,
        "90k": 3,
        "95k": 1,
        "100k": 14,
        "105k": 7,
        "110k": 8,
        "115k": 7,
        "120k": 1,
        "125k": 4,
        "130k": 4,
        "135k": 4,
        "140k": 0,
        "145k": 2,
        "150k": 9,
        "155k": 0,
        "160k": 3,
        "165k": 2,
        "170k": 0,
        "175k": 2,
        "180k": 3,
        "185k": 0,
        "190k": 5,
        "195k": 3,
        "200k": 0,
        "350k": "9"
      },
      houseLoitererfm: {
        "5k": 12,
        "10k": 6,
        "15k": 10,
        "20k": 4,
        "25k": 8,
        "30k": 3,
        "35k": 5,
        "40k": 4,
        "45k": 3,
        "50k": 5,
        "55k": 2,
        "60k": 1,
        "65k": 6,
        "70k": 3,
        "75k": 5,
        "80k": 4,
        "85k": 2,
        "90k": 0,
        "95k": 5,
        "100k": 5,
        "105k": 4,
        "110k": 0,
        "115k": 2,
        "120k": 1,
        "125k": 2,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "1"
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childfm: {
        "5k": 256,
        "10k": 215,
        "15k": 325,
        "20k": 338,
        "25k": 393,
        "30k": 350,
        "35k": 473,
        "40k": 401,
        "45k": 403,
        "50k": 376,
        "55k": 376,
        "60k": 339,
        "65k": 368,
        "70k": 313,
        "75k": 280,
        "80k": 386,
        "85k": 300,
        "90k": 301,
        "95k": 321,
        "100k": 282,
        "105k": 341,
        "110k": 228,
        "115k": 284,
        "120k": 203,
        "125k": 237,
        "130k": 188,
        "135k": 163,
        "140k": 190,
        "145k": 146,
        "150k": 128,
        "155k": 154,
        "160k": 139,
        "165k": 119,
        "170k": 118,
        "175k": 88,
        "180k": 94,
        "185k": 86,
        "190k": 83,
        "195k": 96,
        "200k": 56,
        "350k": "1,190"
      },
      otherRelativefm: {
        "5k": 37,
        "10k": 25,
        "15k": 39,
        "20k": 37,
        "25k": 44,
        "30k": 62,
        "35k": 63,
        "40k": 47,
        "45k": 43,
        "50k": 41,
        "55k": 55,
        "60k": 25,
        "65k": 46,
        "70k": 33,
        "75k": 32,
        "80k": 23,
        "85k": 44,
        "90k": 24,
        "95k": 27,
        "100k": 24,
        "105k": 31,
        "110k": 11,
        "115k": 28,
        "120k": 23,
        "125k": 9,
        "130k": 6,
        "135k": 14,
        "140k": 7,
        "145k": 4,
        "150k": 12,
        "155k": 9,
        "160k": 21,
        "165k": 4,
        "170k": 14,
        "175k": 6,
        "180k": 6,
        "185k": 4,
        "190k": 1,
        "195k": 2,
        "200k": 3,
        "350k": "68"
      },
      nonRelativefm: {
        "5k": 3,
        "10k": 6,
        "15k": 0,
        "20k": 5,
        "25k": 9,
        "30k": 7,
        "35k": 11,
        "40k": 13,
        "45k": 8,
        "50k": 3,
        "55k": 3,
        "60k": 7,
        "65k": 11,
        "70k": 13,
        "75k": 4,
        "80k": 8,
        "85k": 10,
        "90k": 8,
        "95k": 6,
        "100k": 4,
        "105k": 0,
        "110k": 3,
        "115k": 2,
        "120k": 5,
        "125k": 2,
        "130k": 2,
        "135k": 3,
        "140k": 0,
        "145k": 1,
        "150k": 6,
        "155k": 7,
        "160k": 0,
        "165k": 1,
        "170k": 1,
        "175k": 1,
        "180k": 4,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "22"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 93,
        "10k": 34,
        "15k": 48,
        "20k": 68,
        "25k": 57,
        "30k": 52,
        "35k": 58,
        "40k": 64,
        "45k": 77,
        "50k": 49,
        "55k": 39,
        "60k": 62,
        "65k": 23,
        "70k": 40,
        "75k": 17,
        "80k": 23,
        "85k": 18,
        "90k": 16,
        "95k": 12,
        "100k": 9,
        "105k": 13,
        "110k": 8,
        "115k": 14,
        "120k": 11,
        "125k": 5,
        "130k": 12,
        "135k": 6,
        "140k": 4,
        "145k": 8,
        "150k": 2,
        "155k": 4,
        "160k": 5,
        "165k": 5,
        "170k": 3,
        "175k": 1,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 28
      },
      spousem: {
        "5k": 2,
        "10k": 2,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 3,
        "35k": 5,
        "40k": 15,
        "45k": 0,
        "50k": 4,
        "55k": 16,
        "60k": 2,
        "65k": 0,
        "70k": 0,
        "75k": 2,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 2,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      childm: {
        "5k": 84,
        "10k": 61,
        "15k": 81,
        "20k": 123,
        "25k": 101,
        "30k": 135,
        "35k": 149,
        "40k": 177,
        "45k": 204,
        "50k": 193,
        "55k": 197,
        "60k": 195,
        "65k": 177,
        "70k": 178,
        "75k": 183,
        "80k": 191,
        "85k": 139,
        "90k": 191,
        "95k": 164,
        "100k": 137,
        "105k": 172,
        "110k": 130,
        "115k": 149,
        "120k": 136,
        "125k": 167,
        "130k": 98,
        "135k": 102,
        "140k": 111,
        "145k": 113,
        "150k": 72,
        "155k": 92,
        "160k": 88,
        "165k": 84,
        "170k": 86,
        "175k": 73,
        "180k": 62,
        "185k": 60,
        "190k": 58,
        "195k": 59,
        "200k": 31,
        "350k": 630
      },
      otherRelativem: {
        "5k": 4,
        "10k": 10,
        "15k": 35,
        "20k": 29,
        "25k": 49,
        "30k": 23,
        "35k": 43,
        "40k": 33,
        "45k": 36,
        "50k": 43,
        "55k": 19,
        "60k": 28,
        "65k": 33,
        "70k": 54,
        "75k": 25,
        "80k": 37,
        "85k": 28,
        "90k": 20,
        "95k": 31,
        "100k": 15,
        "105k": 20,
        "110k": 6,
        "115k": 17,
        "120k": 12,
        "125k": 12,
        "130k": 5,
        "135k": 14,
        "140k": 9,
        "145k": 1,
        "150k": 11,
        "155k": 14,
        "160k": 2,
        "165k": 7,
        "170k": 2,
        "175k": 5,
        "180k": 6,
        "185k": 10,
        "190k": 5,
        "195k": 6,
        "200k": 0,
        "350k": 42
      },
      nonRelativem: {
        "5k": 42,
        "10k": 26,
        "15k": 8,
        "20k": 34,
        "25k": 21,
        "30k": 16,
        "35k": 33,
        "40k": 50,
        "45k": 32,
        "50k": 27,
        "55k": 35,
        "60k": 44,
        "65k": 21,
        "70k": 23,
        "75k": 14,
        "80k": 13,
        "85k": 32,
        "90k": 8,
        "95k": 11,
        "100k": 7,
        "105k": 15,
        "110k": 17,
        "115k": 16,
        "120k": 8,
        "125k": 4,
        "130k": 6,
        "135k": 24,
        "140k": 4,
        "145k": 26,
        "150k": 2,
        "155k": 6,
        "160k": 16,
        "165k": 4,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 2,
        "200k": 2,
        "350k": 46
      },
      houseLoitererfm: {
        "5k": 94,
        "10k": 84,
        "15k": 80,
        "20k": 73,
        "25k": 70,
        "30k": 72,
        "35k": 65,
        "40k": 95,
        "45k": 44,
        "50k": 69,
        "55k": 51,
        "60k": 25,
        "65k": 14,
        "70k": 35,
        "75k": 32,
        "80k": 15,
        "85k": 23,
        "90k": 20,
        "95k": 14,
        "100k": 12,
        "105k": 1,
        "110k": 11,
        "115k": 5,
        "120k": 8,
        "125k": 7,
        "130k": 7,
        "135k": 13,
        "140k": 4,
        "145k": 0,
        "150k": 2,
        "155k": 6,
        "160k": 2,
        "165k": 2,
        "170k": 2,
        "175k": 0,
        "180k": 6,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 20
      },
      spousefm: {
        "5k": 5,
        "10k": 0,
        "15k": 10,
        "20k": 6,
        "25k": 18,
        "30k": 11,
        "35k": 11,
        "40k": 21,
        "45k": 7,
        "50k": 10,
        "55k": 10,
        "60k": 23,
        "65k": 3,
        "70k": 4,
        "75k": 9,
        "80k": 7,
        "85k": 5,
        "90k": 10,
        "95k": 3,
        "100k": 0,
        "105k": 2,
        "110k": 3,
        "115k": 0,
        "120k": 2,
        "125k": 1,
        "130k": 0,
        "135k": 0,
        "140k": 2,
        "145k": 4,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 5
      },
      childfm: {
        "5k": 79,
        "10k": 57,
        "15k": 107,
        "20k": 94,
        "25k": 142,
        "30k": 116,
        "35k": 127,
        "40k": 176,
        "45k": 171,
        "50k": 185,
        "55k": 152,
        "60k": 169,
        "65k": 183,
        "70k": 188,
        "75k": 163,
        "80k": 190,
        "85k": 118,
        "90k": 143,
        "95k": 184,
        "100k": 131,
        "105k": 147,
        "110k": 118,
        "115k": 130,
        "120k": 97,
        "125k": 149,
        "130k": 56,
        "135k": 100,
        "140k": 103,
        "145k": 104,
        "150k": 53,
        "155k": 99,
        "160k": 61,
        "165k": 67,
        "170k": 95,
        "175k": 50,
        "180k": 55,
        "185k": 62,
        "190k": 59,
        "195k": 49,
        "200k": 32,
        "350k": 585
      },
      otherRelativefm: {
        "5k": 14,
        "10k": 21,
        "15k": 15,
        "20k": 27,
        "25k": 45,
        "30k": 23,
        "35k": 15,
        "40k": 27,
        "45k": 34,
        "50k": 30,
        "55k": 26,
        "60k": 41,
        "65k": 27,
        "70k": 27,
        "75k": 15,
        "80k": 31,
        "85k": 42,
        "90k": 33,
        "95k": 11,
        "100k": 21,
        "105k": 13,
        "110k": 5,
        "115k": 29,
        "120k": 19,
        "125k": 11,
        "130k": 4,
        "135k": 11,
        "140k": 9,
        "145k": 5,
        "150k": 5,
        "155k": 8,
        "160k": 9,
        "165k": 0,
        "170k": 6,
        "175k": 1,
        "180k": 3,
        "185k": 5,
        "190k": 5,
        "195k": 2,
        "200k": 5,
        "350k": 41
      },
      nonRelativefm: {
        "5k": 46,
        "10k": 32,
        "15k": 24,
        "20k": 36,
        "25k": 33,
        "30k": 57,
        "35k": 48,
        "40k": 70,
        "45k": 50,
        "50k": 56,
        "55k": 34,
        "60k": 40,
        "65k": 25,
        "70k": 45,
        "75k": 21,
        "80k": 7,
        "85k": 39,
        "90k": 32,
        "95k": 10,
        "100k": 11,
        "105k": 10,
        "110k": 7,
        "115k": 12,
        "120k": 5,
        "125k": 12,
        "130k": 9,
        "135k": 8,
        "140k": 0,
        "145k": 0,
        "150k": 5,
        "155k": 0,
        "160k": 8,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 1,
        "185k": 2,
        "190k": 2,
        "195k": 2,
        "200k": 0,
        "350k": 18
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 47,
        "10k": 34,
        "15k": 87,
        "20k": 105,
        "25k": 105,
        "30k": 123,
        "35k": 118,
        "40k": 124,
        "45k": 140,
        "50k": 137,
        "55k": 146,
        "60k": 102,
        "65k": 82,
        "70k": 92,
        "75k": 65,
        "80k": 48,
        "85k": 50,
        "90k": 23,
        "95k": 33,
        "100k": 29,
        "105k": 31,
        "110k": 25,
        "115k": 26,
        "120k": 6,
        "125k": 6,
        "130k": 14,
        "135k": 7,
        "140k": 3,
        "145k": 14,
        "150k": 8,
        "155k": 6,
        "160k": 1,
        "165k": 5,
        "170k": 9,
        "175k": 9,
        "180k": 0,
        "185k": 7,
        "190k": 5,
        "195k": 7,
        "200k": 7,
        "350k": 58
      },
      spousem: {
        "5k": 1,
        "10k": 4,
        "15k": 8,
        "20k": 5,
        "25k": 16,
        "30k": 10,
        "35k": 10,
        "40k": 15,
        "45k": 14,
        "50k": 27,
        "55k": 14,
        "60k": 11,
        "65k": 11,
        "70k": 23,
        "75k": 14,
        "80k": 7,
        "85k": 8,
        "90k": 17,
        "95k": 9,
        "100k": 0,
        "105k": 0,
        "110k": 3,
        "115k": 4,
        "120k": 7,
        "125k": 2,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 4,
        "160k": 0,
        "165k": 6,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 48,
        "10k": 10,
        "15k": 47,
        "20k": 42,
        "25k": 44,
        "30k": 81,
        "35k": 74,
        "40k": 101,
        "45k": 69,
        "50k": 82,
        "55k": 75,
        "60k": 97,
        "65k": 101,
        "70k": 90,
        "75k": 73,
        "80k": 112,
        "85k": 89,
        "90k": 107,
        "95k": 105,
        "100k": 74,
        "105k": 107,
        "110k": 63,
        "115k": 53,
        "120k": 76,
        "125k": 58,
        "130k": 42,
        "135k": 51,
        "140k": 75,
        "145k": 81,
        "150k": 53,
        "155k": 62,
        "160k": 31,
        "165k": 34,
        "170k": 29,
        "175k": 19,
        "180k": 50,
        "185k": 45,
        "190k": 37,
        "195k": 42,
        "200k": 13,
        "350k": 347
      },
      otherRelativem: {
        "5k": 5,
        "10k": 2,
        "15k": 30,
        "20k": 8,
        "25k": 24,
        "30k": 24,
        "35k": 18,
        "40k": 37,
        "45k": 25,
        "50k": 15,
        "55k": 22,
        "60k": 16,
        "65k": 29,
        "70k": 26,
        "75k": 23,
        "80k": 25,
        "85k": 35,
        "90k": 18,
        "95k": 15,
        "100k": 8,
        "105k": 20,
        "110k": 13,
        "115k": 18,
        "120k": 10,
        "125k": 15,
        "130k": 7,
        "135k": 5,
        "140k": 5,
        "145k": 1,
        "150k": 0,
        "155k": 7,
        "160k": 8,
        "165k": 6,
        "170k": 7,
        "175k": 1,
        "180k": 4,
        "185k": 1,
        "190k": 5,
        "195k": 10,
        "200k": 6,
        "350k": 25
      },
      nonRelativem: {
        "5k": 51,
        "10k": 17,
        "15k": 28,
        "20k": 32,
        "25k": 31,
        "30k": 32,
        "35k": 20,
        "40k": 52,
        "45k": 42,
        "50k": 65,
        "55k": 50,
        "60k": 50,
        "65k": 58,
        "70k": 17,
        "75k": 32,
        "80k": 37,
        "85k": 29,
        "90k": 16,
        "95k": 32,
        "100k": 18,
        "105k": 33,
        "110k": 25,
        "115k": 15,
        "120k": 7,
        "125k": 4,
        "130k": 5,
        "135k": 21,
        "140k": 9,
        "145k": 8,
        "150k": 0,
        "155k": 4,
        "160k": 10,
        "165k": 3,
        "170k": 8,
        "175k": 4,
        "180k": 2,
        "185k": 3,
        "190k": 3,
        "195k": 2,
        "200k": 0,
        "350k": 65
      },
      houseLoitererfm: {
        "5k": 157,
        "10k": 91,
        "15k": 106,
        "20k": 120,
        "25k": 129,
        "30k": 168,
        "35k": 116,
        "40k": 134,
        "45k": 108,
        "50k": 128,
        "55k": 96,
        "60k": 89,
        "65k": 66,
        "70k": 60,
        "75k": 52,
        "80k": 39,
        "85k": 54,
        "90k": 55,
        "95k": 55,
        "100k": 12,
        "105k": 26,
        "110k": 15,
        "115k": 16,
        "120k": 14,
        "125k": 10,
        "130k": 13,
        "135k": 2,
        "140k": 8,
        "145k": 7,
        "150k": 10,
        "155k": 4,
        "160k": 5,
        "165k": 7,
        "170k": 8,
        "175k": 2,
        "180k": 5,
        "185k": 3,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 32
      },
      spousefm: {
        "5k": 10,
        "10k": 3,
        "15k": 5,
        "20k": 15,
        "25k": 27,
        "30k": 25,
        "35k": 31,
        "40k": 25,
        "45k": 47,
        "50k": 45,
        "55k": 31,
        "60k": 33,
        "65k": 35,
        "70k": 47,
        "75k": 23,
        "80k": 18,
        "85k": 5,
        "90k": 10,
        "95k": 10,
        "100k": 17,
        "105k": 15,
        "110k": 13,
        "115k": 11,
        "120k": 5,
        "125k": 4,
        "130k": 8,
        "135k": 0,
        "140k": 8,
        "145k": 1,
        "150k": 5,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 2,
        "185k": 6,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 17
      },
      childfm: {
        "5k": 32,
        "10k": 17,
        "15k": 30,
        "20k": 39,
        "25k": 50,
        "30k": 38,
        "35k": 61,
        "40k": 59,
        "45k": 62,
        "50k": 84,
        "55k": 89,
        "60k": 73,
        "65k": 82,
        "70k": 60,
        "75k": 73,
        "80k": 75,
        "85k": 75,
        "90k": 54,
        "95k": 62,
        "100k": 47,
        "105k": 55,
        "110k": 63,
        "115k": 64,
        "120k": 61,
        "125k": 36,
        "130k": 48,
        "135k": 71,
        "140k": 45,
        "145k": 54,
        "150k": 47,
        "155k": 38,
        "160k": 37,
        "165k": 45,
        "170k": 44,
        "175k": 53,
        "180k": 14,
        "185k": 16,
        "190k": 20,
        "195k": 13,
        "200k": 16,
        "350k": 369
      },
      otherRelativefm: {
        "5k": 7,
        "10k": 1,
        "15k": 9,
        "20k": 13,
        "25k": 7,
        "30k": 11,
        "35k": 19,
        "40k": 23,
        "45k": 30,
        "50k": 21,
        "55k": 14,
        "60k": 34,
        "65k": 20,
        "70k": 17,
        "75k": 15,
        "80k": 23,
        "85k": 16,
        "90k": 13,
        "95k": 12,
        "100k": 9,
        "105k": 15,
        "110k": 10,
        "115k": 6,
        "120k": 12,
        "125k": 0,
        "130k": 18,
        "135k": 6,
        "140k": 5,
        "145k": 8,
        "150k": 10,
        "155k": 12,
        "160k": 15,
        "165k": 1,
        "170k": 7,
        "175k": 2,
        "180k": 1,
        "185k": 8,
        "190k": 2,
        "195k": 3,
        "200k": 4,
        "350k": 43
      },
      nonRelativefm: {
        "5k": 24,
        "10k": 8,
        "15k": 28,
        "20k": 19,
        "25k": 47,
        "30k": 45,
        "35k": 20,
        "40k": 41,
        "45k": 39,
        "50k": 67,
        "55k": 72,
        "60k": 48,
        "65k": 31,
        "70k": 44,
        "75k": 46,
        "80k": 35,
        "85k": 43,
        "90k": 51,
        "95k": 47,
        "100k": 28,
        "105k": 31,
        "110k": 36,
        "115k": 40,
        "120k": 7,
        "125k": 12,
        "130k": 5,
        "135k": 12,
        "140k": 17,
        "145k": 5,
        "150k": 24,
        "155k": 8,
        "160k": 13,
        "165k": 4,
        "170k": 2,
        "175k": 5,
        "180k": 5,
        "185k": 9,
        "190k": 5,
        "195k": 4,
        "200k": 0,
        "350k": 39
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 132,
        "10k": 51,
        "15k": 107,
        "20k": 93,
        "25k": 196,
        "30k": 209,
        "35k": 222,
        "40k": 263,
        "45k": 272,
        "50k": 210,
        "55k": 204,
        "60k": 209,
        "65k": 218,
        "70k": 229,
        "75k": 170,
        "80k": 153,
        "85k": 194,
        "90k": 145,
        "95k": 171,
        "100k": 98,
        "105k": 130,
        "110k": 72,
        "115k": 98,
        "120k": 72,
        "125k": 75,
        "130k": 65,
        "135k": 48,
        "140k": 47,
        "145k": 62,
        "150k": 53,
        "155k": 48,
        "160k": 21,
        "165k": 20,
        "170k": 37,
        "175k": 18,
        "180k": 13,
        "185k": 24,
        "190k": 29,
        "195k": 9,
        "200k": 26,
        "350k": 179
      },
      spousem: {
        "5k": 18,
        "10k": 10,
        "15k": 7,
        "20k": 30,
        "25k": 36,
        "30k": 32,
        "35k": 38,
        "40k": 63,
        "45k": 62,
        "50k": 81,
        "55k": 48,
        "60k": 72,
        "65k": 60,
        "70k": 44,
        "75k": 58,
        "80k": 51,
        "85k": 68,
        "90k": 49,
        "95k": 28,
        "100k": 39,
        "105k": 30,
        "110k": 24,
        "115k": 27,
        "120k": 25,
        "125k": 16,
        "130k": 11,
        "135k": 16,
        "140k": 18,
        "145k": 1,
        "150k": 7,
        "155k": 17,
        "160k": 7,
        "165k": 0,
        "170k": 6,
        "175k": 0,
        "180k": 3,
        "185k": 4,
        "190k": 9,
        "195k": 1,
        "200k": 0,
        "350k": 31
      },
      childm: {
        "5k": 38,
        "10k": 26,
        "15k": 38,
        "20k": 53,
        "25k": 72,
        "30k": 52,
        "35k": 45,
        "40k": 37,
        "45k": 91,
        "50k": 101,
        "55k": 99,
        "60k": 79,
        "65k": 96,
        "70k": 75,
        "75k": 72,
        "80k": 114,
        "85k": 91,
        "90k": 84,
        "95k": 76,
        "100k": 99,
        "105k": 76,
        "110k": 93,
        "115k": 88,
        "120k": 99,
        "125k": 62,
        "130k": 42,
        "135k": 55,
        "140k": 60,
        "145k": 61,
        "150k": 52,
        "155k": 31,
        "160k": 39,
        "165k": 45,
        "170k": 39,
        "175k": 22,
        "180k": 52,
        "185k": 29,
        "190k": 34,
        "195k": 17,
        "200k": 25,
        "350k": 496
      },
      otherRelativem: {
        "5k": 11,
        "10k": 9,
        "15k": 10,
        "20k": 17,
        "25k": 29,
        "30k": 19,
        "35k": 26,
        "40k": 38,
        "45k": 27,
        "50k": 35,
        "55k": 43,
        "60k": 30,
        "65k": 38,
        "70k": 24,
        "75k": 47,
        "80k": 52,
        "85k": 18,
        "90k": 30,
        "95k": 24,
        "100k": 31,
        "105k": 21,
        "110k": 16,
        "115k": 17,
        "120k": 13,
        "125k": 17,
        "130k": 5,
        "135k": 17,
        "140k": 7,
        "145k": 12,
        "150k": 9,
        "155k": 16,
        "160k": 6,
        "165k": 13,
        "170k": 9,
        "175k": 12,
        "180k": 13,
        "185k": 11,
        "190k": 4,
        "195k": 5,
        "200k": 5,
        "350k": 72
      },
      nonRelativem: {
        "5k": 34,
        "10k": 14,
        "15k": 26,
        "20k": 11,
        "25k": 16,
        "30k": 46,
        "35k": 57,
        "40k": 55,
        "45k": 118,
        "50k": 65,
        "55k": 40,
        "60k": 66,
        "65k": 49,
        "70k": 70,
        "75k": 66,
        "80k": 59,
        "85k": 68,
        "90k": 53,
        "95k": 45,
        "100k": 54,
        "105k": 53,
        "110k": 43,
        "115k": 35,
        "120k": 30,
        "125k": 51,
        "130k": 41,
        "135k": 36,
        "140k": 21,
        "145k": 28,
        "150k": 26,
        "155k": 34,
        "160k": 15,
        "165k": 17,
        "170k": 32,
        "175k": 14,
        "180k": 14,
        "185k": 30,
        "190k": 13,
        "195k": 8,
        "200k": 29,
        "350k": 164
      },
      houseLoitererfm: {
        "5k": 231,
        "10k": 183,
        "15k": 233,
        "20k": 222,
        "25k": 254,
        "30k": 266,
        "35k": 303,
        "40k": 303,
        "45k": 228,
        "50k": 226,
        "55k": 228,
        "60k": 214,
        "65k": 205,
        "70k": 158,
        "75k": 163,
        "80k": 175,
        "85k": 149,
        "90k": 84,
        "95k": 102,
        "100k": 100,
        "105k": 83,
        "110k": 62,
        "115k": 79,
        "120k": 47,
        "125k": 71,
        "130k": 38,
        "135k": 52,
        "140k": 45,
        "145k": 18,
        "150k": 36,
        "155k": 40,
        "160k": 18,
        "165k": 13,
        "170k": 25,
        "175k": 7,
        "180k": 19,
        "185k": 23,
        "190k": 12,
        "195k": 17,
        "200k": 8,
        "350k": 120
      },
      spousefm: {
        "5k": 31,
        "10k": 15,
        "15k": 44,
        "20k": 31,
        "25k": 64,
        "30k": 81,
        "35k": 88,
        "40k": 77,
        "45k": 114,
        "50k": 98,
        "55k": 101,
        "60k": 90,
        "65k": 105,
        "70k": 120,
        "75k": 120,
        "80k": 101,
        "85k": 97,
        "90k": 83,
        "95k": 107,
        "100k": 69,
        "105k": 79,
        "110k": 49,
        "115k": 65,
        "120k": 67,
        "125k": 48,
        "130k": 34,
        "135k": 39,
        "140k": 15,
        "145k": 37,
        "150k": 29,
        "155k": 31,
        "160k": 16,
        "165k": 21,
        "170k": 14,
        "175k": 10,
        "180k": 15,
        "185k": 13,
        "190k": 10,
        "195k": 2,
        "200k": 18,
        "350k": 109
      },
      childfm: {
        "5k": 19,
        "10k": 29,
        "15k": 27,
        "20k": 43,
        "25k": 44,
        "30k": 25,
        "35k": 49,
        "40k": 61,
        "45k": 69,
        "50k": 52,
        "55k": 51,
        "60k": 66,
        "65k": 51,
        "70k": 58,
        "75k": 51,
        "80k": 57,
        "85k": 65,
        "90k": 55,
        "95k": 53,
        "100k": 58,
        "105k": 56,
        "110k": 67,
        "115k": 69,
        "120k": 51,
        "125k": 65,
        "130k": 36,
        "135k": 43,
        "140k": 29,
        "145k": 39,
        "150k": 35,
        "155k": 21,
        "160k": 10,
        "165k": 31,
        "170k": 9,
        "175k": 21,
        "180k": 27,
        "185k": 7,
        "190k": 23,
        "195k": 19,
        "200k": 19,
        "350k": 219
      },
      otherRelativefm: {
        "5k": 4,
        "10k": 6,
        "15k": 26,
        "20k": 1,
        "25k": 15,
        "30k": 8,
        "35k": 27,
        "40k": 17,
        "45k": 20,
        "50k": 12,
        "55k": 9,
        "60k": 22,
        "65k": 31,
        "70k": 17,
        "75k": 11,
        "80k": 39,
        "85k": 26,
        "90k": 21,
        "95k": 29,
        "100k": 17,
        "105k": 25,
        "110k": 8,
        "115k": 9,
        "120k": 12,
        "125k": 9,
        "130k": 16,
        "135k": 7,
        "140k": 10,
        "145k": 9,
        "150k": 12,
        "155k": 13,
        "160k": 5,
        "165k": 6,
        "170k": 13,
        "175k": 3,
        "180k": 9,
        "185k": 13,
        "190k": 5,
        "195k": 0,
        "200k": 4,
        "350k": 56
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 17,
        "15k": 7,
        "20k": 33,
        "25k": 27,
        "30k": 47,
        "35k": 67,
        "40k": 45,
        "45k": 72,
        "50k": 61,
        "55k": 70,
        "60k": 57,
        "65k": 67,
        "70k": 76,
        "75k": 72,
        "80k": 89,
        "85k": 56,
        "90k": 50,
        "95k": 63,
        "100k": 29,
        "105k": 48,
        "110k": 35,
        "115k": 73,
        "120k": 30,
        "125k": 41,
        "130k": 39,
        "135k": 18,
        "140k": 28,
        "145k": 15,
        "150k": 22,
        "155k": 13,
        "160k": 22,
        "165k": 21,
        "170k": 16,
        "175k": 29,
        "180k": 15,
        "185k": 36,
        "190k": 6,
        "195k": 9,
        "200k": 14,
        "350k": 88
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 134,
        "10k": 78,
        "15k": 98,
        "20k": 144,
        "25k": 160,
        "30k": 204,
        "35k": 181,
        "40k": 179,
        "45k": 272,
        "50k": 228,
        "55k": 193,
        "60k": 180,
        "65k": 210,
        "70k": 184,
        "75k": 227,
        "80k": 232,
        "85k": 223,
        "90k": 200,
        "95k": 138,
        "100k": 120,
        "105k": 195,
        "110k": 145,
        "115k": 139,
        "120k": 115,
        "125k": 99,
        "130k": 90,
        "135k": 108,
        "140k": 77,
        "145k": 59,
        "150k": 53,
        "155k": 53,
        "160k": 51,
        "165k": 45,
        "170k": 47,
        "175k": 37,
        "180k": 50,
        "185k": 49,
        "190k": 19,
        "195k": 25,
        "200k": 26,
        "350k": 346
      },
      spousem: {
        "5k": 23,
        "10k": 7,
        "15k": 30,
        "20k": 48,
        "25k": 47,
        "30k": 45,
        "35k": 79,
        "40k": 81,
        "45k": 73,
        "50k": 61,
        "55k": 106,
        "60k": 89,
        "65k": 78,
        "70k": 87,
        "75k": 67,
        "80k": 72,
        "85k": 67,
        "90k": 70,
        "95k": 74,
        "100k": 76,
        "105k": 47,
        "110k": 47,
        "115k": 61,
        "120k": 37,
        "125k": 60,
        "130k": 37,
        "135k": 33,
        "140k": 35,
        "145k": 38,
        "150k": 29,
        "155k": 29,
        "160k": 10,
        "165k": 29,
        "170k": 18,
        "175k": 11,
        "180k": 17,
        "185k": 13,
        "190k": 5,
        "195k": 11,
        "200k": 12,
        "350k": 136
      },
      childm: {
        "5k": 18,
        "10k": 24,
        "15k": 25,
        "20k": 41,
        "25k": 53,
        "30k": 27,
        "35k": 40,
        "40k": 56,
        "45k": 45,
        "50k": 28,
        "55k": 62,
        "60k": 44,
        "65k": 47,
        "70k": 54,
        "75k": 34,
        "80k": 43,
        "85k": 56,
        "90k": 34,
        "95k": 39,
        "100k": 33,
        "105k": 23,
        "110k": 32,
        "115k": 45,
        "120k": 37,
        "125k": 44,
        "130k": 27,
        "135k": 42,
        "140k": 23,
        "145k": 28,
        "150k": 12,
        "155k": 19,
        "160k": 23,
        "165k": 23,
        "170k": 22,
        "175k": 9,
        "180k": 0,
        "185k": 6,
        "190k": 11,
        "195k": 2,
        "200k": 9,
        "350k": 135
      },
      otherRelativem: {
        "5k": 10,
        "10k": 11,
        "15k": 9,
        "20k": 12,
        "25k": 13,
        "30k": 21,
        "35k": 19,
        "40k": 31,
        "45k": 22,
        "50k": 16,
        "55k": 33,
        "60k": 13,
        "65k": 24,
        "70k": 43,
        "75k": 23,
        "80k": 11,
        "85k": 23,
        "90k": 15,
        "95k": 27,
        "100k": 27,
        "105k": 29,
        "110k": 6,
        "115k": 38,
        "120k": 12,
        "125k": 7,
        "130k": 15,
        "135k": 6,
        "140k": 20,
        "145k": 10,
        "150k": 16,
        "155k": 10,
        "160k": 6,
        "165k": 13,
        "170k": 9,
        "175k": 7,
        "180k": 5,
        "185k": 7,
        "190k": 1,
        "195k": 6,
        "200k": 0,
        "350k": 60
      },
      nonRelativem: {
        "5k": 12,
        "10k": 18,
        "15k": 13,
        "20k": 34,
        "25k": 20,
        "30k": 35,
        "35k": 33,
        "40k": 37,
        "45k": 29,
        "50k": 59,
        "55k": 31,
        "60k": 47,
        "65k": 71,
        "70k": 67,
        "75k": 44,
        "80k": 67,
        "85k": 61,
        "90k": 39,
        "95k": 25,
        "100k": 31,
        "105k": 46,
        "110k": 18,
        "115k": 16,
        "120k": 41,
        "125k": 29,
        "130k": 16,
        "135k": 29,
        "140k": 18,
        "145k": 26,
        "150k": 19,
        "155k": 7,
        "160k": 13,
        "165k": 18,
        "170k": 11,
        "175k": 3,
        "180k": 2,
        "185k": 4,
        "190k": 12,
        "195k": 16,
        "200k": 2,
        "350k": 113
      },
      houseLoitererfm: {
        "5k": 216,
        "10k": 151,
        "15k": 175,
        "20k": 272,
        "25k": 278,
        "30k": 219,
        "35k": 282,
        "40k": 229,
        "45k": 254,
        "50k": 204,
        "55k": 269,
        "60k": 185,
        "65k": 219,
        "70k": 163,
        "75k": 153,
        "80k": 154,
        "85k": 147,
        "90k": 120,
        "95k": 124,
        "100k": 114,
        "105k": 101,
        "110k": 82,
        "115k": 95,
        "120k": 92,
        "125k": 129,
        "130k": 70,
        "135k": 60,
        "140k": 42,
        "145k": 63,
        "150k": 31,
        "155k": 42,
        "160k": 28,
        "165k": 41,
        "170k": 27,
        "175k": 34,
        "180k": 17,
        "185k": 12,
        "190k": 7,
        "195k": 20,
        "200k": 19,
        "350k": 206
      },
      spousefm: {
        "5k": 36,
        "10k": 18,
        "15k": 36,
        "20k": 61,
        "25k": 66,
        "30k": 84,
        "35k": 77,
        "40k": 78,
        "45k": 129,
        "50k": 85,
        "55k": 111,
        "60k": 107,
        "65k": 129,
        "70k": 84,
        "75k": 123,
        "80k": 151,
        "85k": 156,
        "90k": 133,
        "95k": 126,
        "100k": 117,
        "105k": 132,
        "110k": 112,
        "115k": 78,
        "120k": 86,
        "125k": 82,
        "130k": 82,
        "135k": 65,
        "140k": 77,
        "145k": 59,
        "150k": 42,
        "155k": 39,
        "160k": 43,
        "165k": 46,
        "170k": 42,
        "175k": 34,
        "180k": 42,
        "185k": 40,
        "190k": 22,
        "195k": 21,
        "200k": 16,
        "350k": 325
      },
      childfm: {
        "5k": 12,
        "10k": 12,
        "15k": 7,
        "20k": 17,
        "25k": 15,
        "30k": 35,
        "35k": 40,
        "40k": 28,
        "45k": 31,
        "50k": 20,
        "55k": 43,
        "60k": 15,
        "65k": 35,
        "70k": 33,
        "75k": 32,
        "80k": 32,
        "85k": 30,
        "90k": 35,
        "95k": 22,
        "100k": 21,
        "105k": 19,
        "110k": 9,
        "115k": 29,
        "120k": 19,
        "125k": 14,
        "130k": 27,
        "135k": 25,
        "140k": 11,
        "145k": 16,
        "150k": 24,
        "155k": 7,
        "160k": 1,
        "165k": 29,
        "170k": 10,
        "175k": 6,
        "180k": 8,
        "185k": 7,
        "190k": 4,
        "195k": 10,
        "200k": 4,
        "350k": 103
      },
      otherRelativefm: {
        "5k": 2,
        "10k": 2,
        "15k": 13,
        "20k": 18,
        "25k": 6,
        "30k": 21,
        "35k": 8,
        "40k": 1,
        "45k": 23,
        "50k": 19,
        "55k": 16,
        "60k": 9,
        "65k": 26,
        "70k": 23,
        "75k": 20,
        "80k": 6,
        "85k": 12,
        "90k": 10,
        "95k": 15,
        "100k": 9,
        "105k": 12,
        "110k": 10,
        "115k": 18,
        "120k": 4,
        "125k": 4,
        "130k": 12,
        "135k": 8,
        "140k": 5,
        "145k": 5,
        "150k": 3,
        "155k": 5,
        "160k": 5,
        "165k": 0,
        "170k": 5,
        "175k": 3,
        "180k": 4,
        "185k": 1,
        "190k": 3,
        "195k": 7,
        "200k": 2,
        "350k": 47
      },
      nonRelativefm: {
        "5k": 6,
        "10k": 21,
        "15k": 14,
        "20k": 22,
        "25k": 30,
        "30k": 42,
        "35k": 22,
        "40k": 39,
        "45k": 32,
        "50k": 32,
        "55k": 21,
        "60k": 24,
        "65k": 32,
        "70k": 28,
        "75k": 31,
        "80k": 57,
        "85k": 30,
        "90k": 32,
        "95k": 22,
        "100k": 37,
        "105k": 48,
        "110k": 10,
        "115k": 22,
        "120k": 31,
        "125k": 33,
        "130k": 24,
        "135k": 18,
        "140k": 17,
        "145k": 6,
        "150k": 8,
        "155k": 14,
        "160k": 10,
        "165k": 5,
        "170k": 11,
        "175k": 8,
        "180k": 9,
        "185k": 12,
        "190k": 2,
        "195k": 5,
        "200k": 5,
        "350k": 58
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 184,
        "10k": 134,
        "15k": 265,
        "20k": 214,
        "25k": 274,
        "30k": 340,
        "35k": 434,
        "40k": 354,
        "45k": 442,
        "50k": 409,
        "55k": 446,
        "60k": 367,
        "65k": 425,
        "70k": 376,
        "75k": 326,
        "80k": 332,
        "85k": 344,
        "90k": 306,
        "95k": 379,
        "100k": 317,
        "105k": 382,
        "110k": 234,
        "115k": 235,
        "120k": 215,
        "125k": 254,
        "130k": 227,
        "135k": 209,
        "140k": 190,
        "145k": 165,
        "150k": 130,
        "155k": 157,
        "160k": 112,
        "165k": 126,
        "170k": 141,
        "175k": 121,
        "180k": 107,
        "185k": 105,
        "190k": 79,
        "195k": 63,
        "200k": 43,
        "350k": "1,178"
      },
      spousem: {
        "5k": 58,
        "10k": 33,
        "15k": 43,
        "20k": 82,
        "25k": 91,
        "30k": 107,
        "35k": 139,
        "40k": 145,
        "45k": 122,
        "50k": 167,
        "55k": 148,
        "60k": 145,
        "65k": 169,
        "70k": 165,
        "75k": 151,
        "80k": 173,
        "85k": 177,
        "90k": 145,
        "95k": 152,
        "100k": 160,
        "105k": 158,
        "110k": 137,
        "115k": 121,
        "120k": 124,
        "125k": 150,
        "130k": 102,
        "135k": 101,
        "140k": 77,
        "145k": 100,
        "150k": 67,
        "155k": 96,
        "160k": 85,
        "165k": 72,
        "170k": 51,
        "175k": 58,
        "180k": 49,
        "185k": 39,
        "190k": 20,
        "195k": 41,
        "200k": 35,
        "350k": "611"
      },
      childm: {
        "5k": 36,
        "10k": 25,
        "15k": 30,
        "20k": 51,
        "25k": 49,
        "30k": 49,
        "35k": 63,
        "40k": 91,
        "45k": 67,
        "50k": 74,
        "55k": 52,
        "60k": 41,
        "65k": 37,
        "70k": 36,
        "75k": 45,
        "80k": 54,
        "85k": 45,
        "90k": 28,
        "95k": 44,
        "100k": 38,
        "105k": 49,
        "110k": 29,
        "115k": 35,
        "120k": 22,
        "125k": 27,
        "130k": 14,
        "135k": 18,
        "140k": 17,
        "145k": 16,
        "150k": 12,
        "155k": 12,
        "160k": 25,
        "165k": 16,
        "170k": 10,
        "175k": 14,
        "180k": 14,
        "185k": 8,
        "190k": 16,
        "195k": 6,
        "200k": 0,
        "350k": "83"
      },
      otherRelativem: {
        "5k": 9,
        "10k": 3,
        "15k": 17,
        "20k": 32,
        "25k": 32,
        "30k": 19,
        "35k": 24,
        "40k": 32,
        "45k": 58,
        "50k": 27,
        "55k": 50,
        "60k": 51,
        "65k": 33,
        "70k": 30,
        "75k": 32,
        "80k": 38,
        "85k": 38,
        "90k": 39,
        "95k": 21,
        "100k": 36,
        "105k": 25,
        "110k": 25,
        "115k": 22,
        "120k": 16,
        "125k": 16,
        "130k": 27,
        "135k": 7,
        "140k": 5,
        "145k": 7,
        "150k": 22,
        "155k": 12,
        "160k": 8,
        "165k": 10,
        "170k": 2,
        "175k": 8,
        "180k": 17,
        "185k": 8,
        "190k": 5,
        "195k": 9,
        "200k": 8,
        "350k": "88"
      },
      nonRelativem: {
        "5k": 24,
        "10k": 19,
        "15k": 35,
        "20k": 25,
        "25k": 50,
        "30k": 34,
        "35k": 48,
        "40k": 49,
        "45k": 51,
        "50k": 56,
        "55k": 49,
        "60k": 55,
        "65k": 57,
        "70k": 49,
        "75k": 45,
        "80k": 61,
        "85k": 54,
        "90k": 33,
        "95k": 29,
        "100k": 53,
        "105k": 35,
        "110k": 37,
        "115k": 19,
        "120k": 31,
        "125k": 24,
        "130k": 21,
        "135k": 22,
        "140k": 5,
        "145k": 14,
        "150k": 15,
        "155k": 8,
        "160k": 22,
        "165k": 6,
        "170k": 25,
        "175k": 22,
        "180k": 7,
        "185k": 7,
        "190k": 12,
        "195k": 7,
        "200k": 6,
        "350k": "127"
      },
      houseLoitererfm: {
        "5k": 374,
        "10k": 302,
        "15k": 370,
        "20k": 381,
        "25k": 438,
        "30k": 422,
        "35k": 437,
        "40k": 515,
        "45k": 402,
        "50k": 407,
        "55k": 358,
        "60k": 398,
        "65k": 351,
        "70k": 323,
        "75k": 326,
        "80k": 340,
        "85k": 307,
        "90k": 272,
        "95k": 270,
        "100k": 232,
        "105k": 273,
        "110k": 189,
        "115k": 214,
        "120k": 179,
        "125k": 191,
        "130k": 127,
        "135k": 126,
        "140k": 103,
        "145k": 115,
        "150k": 114,
        "155k": 141,
        "160k": 107,
        "165k": 96,
        "170k": 68,
        "175k": 67,
        "180k": 75,
        "185k": 55,
        "190k": 32,
        "195k": 49,
        "200k": 38,
        "350k": "777"
      },
      spousefm: {
        "5k": 52,
        "10k": 41,
        "15k": 71,
        "20k": 80,
        "25k": 118,
        "30k": 121,
        "35k": 174,
        "40k": 165,
        "45k": 202,
        "50k": 182,
        "55k": 193,
        "60k": 199,
        "65k": 212,
        "70k": 210,
        "75k": 196,
        "80k": 243,
        "85k": 236,
        "90k": 213,
        "95k": 275,
        "100k": 224,
        "105k": 294,
        "110k": 189,
        "115k": 187,
        "120k": 170,
        "125k": 207,
        "130k": 162,
        "135k": 160,
        "140k": 127,
        "145k": 140,
        "150k": 114,
        "155k": 134,
        "160k": 117,
        "165k": 120,
        "170k": 131,
        "175k": 102,
        "180k": 75,
        "185k": 94,
        "190k": 70,
        "195k": 63,
        "200k": 43,
        "350k": "1,065"
      },
      childfm: {
        "5k": 12,
        "10k": 10,
        "15k": 16,
        "20k": 29,
        "25k": 30,
        "30k": 35,
        "35k": 43,
        "40k": 36,
        "45k": 41,
        "50k": 46,
        "55k": 46,
        "60k": 34,
        "65k": 49,
        "70k": 17,
        "75k": 26,
        "80k": 26,
        "85k": 27,
        "90k": 16,
        "95k": 13,
        "100k": 25,
        "105k": 28,
        "110k": 20,
        "115k": 28,
        "120k": 16,
        "125k": 19,
        "130k": 27,
        "135k": 22,
        "140k": 15,
        "145k": 17,
        "150k": 12,
        "155k": 12,
        "160k": 9,
        "165k": 2,
        "170k": 5,
        "175k": 8,
        "180k": 8,
        "185k": 13,
        "190k": 3,
        "195k": 8,
        "200k": 5,
        "350k": "91"
      },
      otherRelativefm: {
        "5k": 17,
        "10k": 12,
        "15k": 23,
        "20k": 10,
        "25k": 32,
        "30k": 30,
        "35k": 49,
        "40k": 26,
        "45k": 38,
        "50k": 41,
        "55k": 31,
        "60k": 33,
        "65k": 31,
        "70k": 31,
        "75k": 27,
        "80k": 25,
        "85k": 25,
        "90k": 34,
        "95k": 21,
        "100k": 17,
        "105k": 18,
        "110k": 12,
        "115k": 24,
        "120k": 14,
        "125k": 11,
        "130k": 6,
        "135k": 9,
        "140k": 11,
        "145k": 10,
        "150k": 20,
        "155k": 8,
        "160k": 5,
        "165k": 3,
        "170k": 9,
        "175k": 5,
        "180k": 8,
        "185k": 6,
        "190k": 7,
        "195k": 8,
        "200k": 0,
        "350k": "46"
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 10,
        "15k": 40,
        "20k": 23,
        "25k": 28,
        "30k": 31,
        "35k": 35,
        "40k": 37,
        "45k": 27,
        "50k": 36,
        "55k": 35,
        "60k": 35,
        "65k": 37,
        "70k": 40,
        "75k": 39,
        "80k": 24,
        "85k": 46,
        "90k": 32,
        "95k": 30,
        "100k": 30,
        "105k": 37,
        "110k": 31,
        "115k": 21,
        "120k": 23,
        "125k": 15,
        "130k": 21,
        "135k": 25,
        "140k": 19,
        "145k": 10,
        "150k": 10,
        "155k": 18,
        "160k": 8,
        "165k": 10,
        "170k": 15,
        "175k": 11,
        "180k": 8,
        "185k": 10,
        "190k": 6,
        "195k": 9,
        "200k": 8,
        "350k": "77"
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 269,
        "10k": 209,
        "15k": 275,
        "20k": 226,
        "25k": 324,
        "30k": 316,
        "35k": 348,
        "40k": 398,
        "45k": 436,
        "50k": 415,
        "55k": 400,
        "60k": 302,
        "65k": 390,
        "70k": 315,
        "75k": 394,
        "80k": 374,
        "85k": 335,
        "90k": 287,
        "95k": 344,
        "100k": 308,
        "105k": 354,
        "110k": 237,
        "115k": 256,
        "120k": 260,
        "125k": 236,
        "130k": 220,
        "135k": 224,
        "140k": 151,
        "145k": 187,
        "150k": 154,
        "155k": 216,
        "160k": 148,
        "165k": 174,
        "170k": 128,
        "175k": 129,
        "180k": 92,
        "185k": 117,
        "190k": 84,
        "195k": 115,
        "200k": 56,
        "350k": "1,496"
      },
      spousem: {
        "5k": 49,
        "10k": 30,
        "15k": 39,
        "20k": 57,
        "25k": 73,
        "30k": 116,
        "35k": 117,
        "40k": 119,
        "45k": 163,
        "50k": 136,
        "55k": 174,
        "60k": 145,
        "65k": 180,
        "70k": 178,
        "75k": 163,
        "80k": 183,
        "85k": 205,
        "90k": 145,
        "95k": 170,
        "100k": 121,
        "105k": 177,
        "110k": 135,
        "115k": 182,
        "120k": 125,
        "125k": 133,
        "130k": 115,
        "135k": 95,
        "140k": 112,
        "145k": 106,
        "150k": 66,
        "155k": 113,
        "160k": 108,
        "165k": 86,
        "170k": 93,
        "175k": 68,
        "180k": 72,
        "185k": 63,
        "190k": 65,
        "195k": 64,
        "200k": 48,
        "350k": "764"
      },
      childm: {
        "5k": 26,
        "10k": 30,
        "15k": 52,
        "20k": 61,
        "25k": 109,
        "30k": 47,
        "35k": 42,
        "40k": 59,
        "45k": 37,
        "50k": 57,
        "55k": 73,
        "60k": 75,
        "65k": 53,
        "70k": 37,
        "75k": 22,
        "80k": 57,
        "85k": 37,
        "90k": 40,
        "95k": 39,
        "100k": 12,
        "105k": 17,
        "110k": 25,
        "115k": 19,
        "120k": 20,
        "125k": 10,
        "130k": 7,
        "135k": 22,
        "140k": 8,
        "145k": 12,
        "150k": 1,
        "155k": 13,
        "160k": 0,
        "165k": 0,
        "170k": 10,
        "175k": 4,
        "180k": 7,
        "185k": 11,
        "190k": 11,
        "195k": 0,
        "200k": 7,
        "350k": "32"
      },
      otherRelativem: {
        "5k": 19,
        "10k": 11,
        "15k": 15,
        "20k": 29,
        "25k": 56,
        "30k": 51,
        "35k": 35,
        "40k": 23,
        "45k": 40,
        "50k": 73,
        "55k": 44,
        "60k": 49,
        "65k": 49,
        "70k": 76,
        "75k": 40,
        "80k": 41,
        "85k": 49,
        "90k": 48,
        "95k": 38,
        "100k": 34,
        "105k": 28,
        "110k": 35,
        "115k": 21,
        "120k": 27,
        "125k": 8,
        "130k": 31,
        "135k": 30,
        "140k": 16,
        "145k": 18,
        "150k": 24,
        "155k": 11,
        "160k": 8,
        "165k": 4,
        "170k": 15,
        "175k": 6,
        "180k": 4,
        "185k": 6,
        "190k": 13,
        "195k": 12,
        "200k": 6,
        "350k": "101"
      },
      nonRelativem: {
        "5k": 9,
        "10k": 12,
        "15k": 33,
        "20k": 23,
        "25k": 22,
        "30k": 36,
        "35k": 33,
        "40k": 37,
        "45k": 27,
        "50k": 48,
        "55k": 44,
        "60k": 47,
        "65k": 52,
        "70k": 44,
        "75k": 42,
        "80k": 44,
        "85k": 36,
        "90k": 40,
        "95k": 43,
        "100k": 37,
        "105k": 36,
        "110k": 14,
        "115k": 28,
        "120k": 12,
        "125k": 19,
        "130k": 17,
        "135k": 19,
        "140k": 20,
        "145k": 10,
        "150k": 7,
        "155k": 8,
        "160k": 6,
        "165k": 11,
        "170k": 8,
        "175k": 3,
        "180k": 13,
        "185k": 8,
        "190k": 5,
        "195k": 4,
        "200k": 7,
        "350k": "95"
      },
      houseLoitererfm: {
        "5k": 396,
        "10k": 379,
        "15k": 469,
        "20k": 336,
        "25k": 382,
        "30k": 383,
        "35k": 440,
        "40k": 479,
        "45k": 470,
        "50k": 391,
        "55k": 406,
        "60k": 349,
        "65k": 411,
        "70k": 349,
        "75k": 344,
        "80k": 341,
        "85k": 299,
        "90k": 284,
        "95k": 251,
        "100k": 243,
        "105k": 292,
        "110k": 210,
        "115k": 243,
        "120k": 189,
        "125k": 196,
        "130k": 149,
        "135k": 130,
        "140k": 129,
        "145k": 133,
        "150k": 114,
        "155k": 163,
        "160k": 125,
        "165k": 94,
        "170k": 118,
        "175k": 96,
        "180k": 97,
        "185k": 70,
        "190k": 72,
        "195k": 84,
        "200k": 60,
        "350k": "944"
      },
      spousefm: {
        "5k": 75,
        "10k": 35,
        "15k": 60,
        "20k": 85,
        "25k": 115,
        "30k": 106,
        "35k": 140,
        "40k": 149,
        "45k": 198,
        "50k": 194,
        "55k": 197,
        "60k": 210,
        "65k": 215,
        "70k": 191,
        "75k": 254,
        "80k": 268,
        "85k": 214,
        "90k": 210,
        "95k": 262,
        "100k": 195,
        "105k": 232,
        "110k": 177,
        "115k": 244,
        "120k": 224,
        "125k": 203,
        "130k": 147,
        "135k": 189,
        "140k": 148,
        "145k": 187,
        "150k": 143,
        "155k": 163,
        "160k": 121,
        "165k": 125,
        "170k": 100,
        "175k": 111,
        "180k": 75,
        "185k": 86,
        "190k": 77,
        "195k": 96,
        "200k": 62,
        "350k": "1,308"
      },
      childfm: {
        "5k": 3,
        "10k": 11,
        "15k": 13,
        "20k": 22,
        "25k": 38,
        "30k": 29,
        "35k": 23,
        "40k": 40,
        "45k": 22,
        "50k": 45,
        "55k": 22,
        "60k": 30,
        "65k": 23,
        "70k": 23,
        "75k": 36,
        "80k": 16,
        "85k": 19,
        "90k": 18,
        "95k": 23,
        "100k": 16,
        "105k": 20,
        "110k": 14,
        "115k": 5,
        "120k": 6,
        "125k": 12,
        "130k": 10,
        "135k": 12,
        "140k": 10,
        "145k": 9,
        "150k": 13,
        "155k": 0,
        "160k": 6,
        "165k": 9,
        "170k": 2,
        "175k": 1,
        "180k": 3,
        "185k": 2,
        "190k": 6,
        "195k": 6,
        "200k": 1,
        "350k": "34"
      },
      otherRelativefm: {
        "5k": 33,
        "10k": 12,
        "15k": 20,
        "20k": 35,
        "25k": 48,
        "30k": 36,
        "35k": 42,
        "40k": 37,
        "45k": 48,
        "50k": 66,
        "55k": 37,
        "60k": 46,
        "65k": 51,
        "70k": 49,
        "75k": 47,
        "80k": 42,
        "85k": 61,
        "90k": 30,
        "95k": 39,
        "100k": 24,
        "105k": 41,
        "110k": 11,
        "115k": 36,
        "120k": 19,
        "125k": 15,
        "130k": 32,
        "135k": 17,
        "140k": 16,
        "145k": 10,
        "150k": 9,
        "155k": 12,
        "160k": 13,
        "165k": 7,
        "170k": 17,
        "175k": 3,
        "180k": 7,
        "185k": 17,
        "190k": 10,
        "195k": 11,
        "200k": 7,
        "350k": "97"
      },
      nonRelativefm: {
        "5k": 15,
        "10k": 19,
        "15k": 12,
        "20k": 19,
        "25k": 18,
        "30k": 24,
        "35k": 29,
        "40k": 39,
        "45k": 52,
        "50k": 31,
        "55k": 32,
        "60k": 37,
        "65k": 37,
        "70k": 36,
        "75k": 31,
        "80k": 33,
        "85k": 19,
        "90k": 34,
        "95k": 20,
        "100k": 31,
        "105k": 18,
        "110k": 30,
        "115k": 21,
        "120k": 32,
        "125k": 10,
        "130k": 15,
        "135k": 14,
        "140k": 5,
        "145k": 4,
        "150k": 10,
        "155k": 11,
        "160k": 9,
        "165k": 14,
        "170k": 5,
        "175k": 3,
        "180k": 5,
        "185k": 5,
        "190k": 10,
        "195k": 10,
        "200k": 2,
        "350k": "91"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 441,
        "10k": 337,
        "15k": 506,
        "20k": 389,
        "25k": 516,
        "30k": 406,
        "35k": 457,
        "40k": 411,
        "45k": 485,
        "50k": 426,
        "55k": 459,
        "60k": 379,
        "65k": 365,
        "70k": 415,
        "75k": 351,
        "80k": 361,
        "85k": 361,
        "90k": 292,
        "95k": 328,
        "100k": 280,
        "105k": 310,
        "110k": 218,
        "115k": 275,
        "120k": 183,
        "125k": 260,
        "130k": 199,
        "135k": 204,
        "140k": 140,
        "145k": 161,
        "150k": 126,
        "155k": 155,
        "160k": 117,
        "165k": 154,
        "170k": 124,
        "175k": 95,
        "180k": 98,
        "185k": 84,
        "190k": 94,
        "195k": 83,
        "200k": 81,
        "350k": "1,365"
      },
      spousem: {
        "5k": 100,
        "10k": 64,
        "15k": 79,
        "20k": 80,
        "25k": 98,
        "30k": 110,
        "35k": 128,
        "40k": 136,
        "45k": 149,
        "50k": 160,
        "55k": 140,
        "60k": 152,
        "65k": 192,
        "70k": 160,
        "75k": 189,
        "80k": 169,
        "85k": 165,
        "90k": 129,
        "95k": 152,
        "100k": 148,
        "105k": 141,
        "110k": 117,
        "115k": 131,
        "120k": 134,
        "125k": 112,
        "130k": 82,
        "135k": 91,
        "140k": 85,
        "145k": 96,
        "150k": 91,
        "155k": 80,
        "160k": 70,
        "165k": 79,
        "170k": 50,
        "175k": 59,
        "180k": 55,
        "185k": 37,
        "190k": 51,
        "195k": 35,
        "200k": 36,
        "350k": "696"
      },
      childm: {
        "5k": 5,
        "10k": 12,
        "15k": 20,
        "20k": 31,
        "25k": 33,
        "30k": 28,
        "35k": 15,
        "40k": 30,
        "45k": 29,
        "50k": 15,
        "55k": 25,
        "60k": 22,
        "65k": 17,
        "70k": 31,
        "75k": 18,
        "80k": 25,
        "85k": 4,
        "90k": 8,
        "95k": 9,
        "100k": 8,
        "105k": 0,
        "110k": 8,
        "115k": 9,
        "120k": 10,
        "125k": 3,
        "130k": 0,
        "135k": 3,
        "140k": 2,
        "145k": 3,
        "150k": 2,
        "155k": 5,
        "160k": 0,
        "165k": 0,
        "170k": 5,
        "175k": 0,
        "180k": 3,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "11"
      },
      otherRelativem: {
        "5k": 4,
        "10k": 12,
        "15k": 17,
        "20k": 14,
        "25k": 17,
        "30k": 28,
        "35k": 38,
        "40k": 18,
        "45k": 26,
        "50k": 38,
        "55k": 39,
        "60k": 42,
        "65k": 37,
        "70k": 38,
        "75k": 51,
        "80k": 35,
        "85k": 34,
        "90k": 48,
        "95k": 23,
        "100k": 35,
        "105k": 31,
        "110k": 38,
        "115k": 13,
        "120k": 18,
        "125k": 18,
        "130k": 12,
        "135k": 28,
        "140k": 21,
        "145k": 13,
        "150k": 19,
        "155k": 9,
        "160k": 13,
        "165k": 2,
        "170k": 12,
        "175k": 5,
        "180k": 8,
        "185k": 7,
        "190k": 9,
        "195k": 21,
        "200k": 9,
        "350k": "128"
      },
      nonRelativem: {
        "5k": 16,
        "10k": 15,
        "15k": 16,
        "20k": 33,
        "25k": 30,
        "30k": 15,
        "35k": 29,
        "40k": 28,
        "45k": 18,
        "50k": 25,
        "55k": 31,
        "60k": 29,
        "65k": 30,
        "70k": 30,
        "75k": 33,
        "80k": 55,
        "85k": 20,
        "90k": 18,
        "95k": 31,
        "100k": 14,
        "105k": 27,
        "110k": 7,
        "115k": 8,
        "120k": 25,
        "125k": 7,
        "130k": 26,
        "135k": 14,
        "140k": 11,
        "145k": 6,
        "150k": 11,
        "155k": 4,
        "160k": 0,
        "165k": 5,
        "170k": 1,
        "175k": 1,
        "180k": 1,
        "185k": 4,
        "190k": 3,
        "195k": 4,
        "200k": 0,
        "350k": "56"
      },
      houseLoitererfm: {
        "5k": 467,
        "10k": 510,
        "15k": 604,
        "20k": 541,
        "25k": 506,
        "30k": 512,
        "35k": 478,
        "40k": 473,
        "45k": 444,
        "50k": 477,
        "55k": 458,
        "60k": 368,
        "65k": 430,
        "70k": 312,
        "75k": 345,
        "80k": 354,
        "85k": 307,
        "90k": 234,
        "95k": 274,
        "100k": 232,
        "105k": 219,
        "110k": 181,
        "115k": 189,
        "120k": 165,
        "125k": 152,
        "130k": 92,
        "135k": 111,
        "140k": 121,
        "145k": 122,
        "150k": 91,
        "155k": 109,
        "160k": 84,
        "165k": 117,
        "170k": 66,
        "175k": 74,
        "180k": 74,
        "185k": 52,
        "190k": 57,
        "195k": 50,
        "200k": 45,
        "350k": "783"
      },
      spousefm: {
        "5k": 110,
        "10k": 79,
        "15k": 113,
        "20k": 123,
        "25k": 172,
        "30k": 154,
        "35k": 234,
        "40k": 191,
        "45k": 251,
        "50k": 250,
        "55k": 307,
        "60k": 221,
        "65k": 244,
        "70k": 289,
        "75k": 228,
        "80k": 273,
        "85k": 242,
        "90k": 216,
        "95k": 245,
        "100k": 243,
        "105k": 244,
        "110k": 192,
        "115k": 240,
        "120k": 145,
        "125k": 190,
        "130k": 158,
        "135k": 167,
        "140k": 114,
        "145k": 119,
        "150k": 100,
        "155k": 134,
        "160k": 105,
        "165k": 124,
        "170k": 121,
        "175k": 81,
        "180k": 93,
        "185k": 70,
        "190k": 79,
        "195k": 58,
        "200k": 56,
        "350k": "1,108"
      },
      childfm: {
        "5k": 6,
        "10k": 9,
        "15k": 10,
        "20k": 15,
        "25k": 18,
        "30k": 11,
        "35k": 19,
        "40k": 21,
        "45k": 23,
        "50k": 10,
        "55k": 11,
        "60k": 18,
        "65k": 13,
        "70k": 5,
        "75k": 15,
        "80k": 18,
        "85k": 9,
        "90k": 8,
        "95k": 9,
        "100k": 14,
        "105k": 9,
        "110k": 3,
        "115k": 8,
        "120k": 5,
        "125k": 9,
        "130k": 1,
        "135k": 2,
        "140k": 0,
        "145k": 2,
        "150k": 1,
        "155k": 4,
        "160k": 0,
        "165k": 5,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 1,
        "200k": 0,
        "350k": "16"
      },
      otherRelativefm: {
        "5k": 21,
        "10k": 24,
        "15k": 30,
        "20k": 34,
        "25k": 33,
        "30k": 43,
        "35k": 72,
        "40k": 55,
        "45k": 64,
        "50k": 72,
        "55k": 65,
        "60k": 58,
        "65k": 36,
        "70k": 65,
        "75k": 47,
        "80k": 31,
        "85k": 34,
        "90k": 38,
        "95k": 38,
        "100k": 35,
        "105k": 34,
        "110k": 27,
        "115k": 20,
        "120k": 25,
        "125k": 41,
        "130k": 19,
        "135k": 39,
        "140k": 17,
        "145k": 11,
        "150k": 29,
        "155k": 12,
        "160k": 10,
        "165k": 10,
        "170k": 8,
        "175k": 17,
        "180k": 15,
        "185k": 14,
        "190k": 9,
        "195k": 3,
        "200k": 11,
        "350k": "133"
      },
      nonRelativefm: {
        "5k": 7,
        "10k": 11,
        "15k": 22,
        "20k": 25,
        "25k": 29,
        "30k": 30,
        "35k": 31,
        "40k": 31,
        "45k": 24,
        "50k": 12,
        "55k": 29,
        "60k": 10,
        "65k": 39,
        "70k": 22,
        "75k": 27,
        "80k": 11,
        "85k": 12,
        "90k": 20,
        "95k": 25,
        "100k": 21,
        "105k": 25,
        "110k": 20,
        "115k": 11,
        "120k": 13,
        "125k": 13,
        "130k": 8,
        "135k": 7,
        "140k": 7,
        "145k": 5,
        "150k": 12,
        "155k": 2,
        "160k": 3,
        "165k": 5,
        "170k": 4,
        "175k": 8,
        "180k": 6,
        "185k": 0,
        "190k": 0,
        "195k": 7,
        "200k": 2,
        "350k": "84"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 209,
        "10k": 214,
        "15k": 522,
        "20k": 450,
        "25k": 513,
        "30k": 482,
        "35k": 424,
        "40k": 408,
        "45k": 416,
        "50k": 436,
        "55k": 428,
        "60k": 377,
        "65k": 285,
        "70k": 233,
        "75k": 216,
        "80k": 223,
        "85k": 199,
        "90k": 207,
        "95k": 218,
        "100k": 219,
        "105k": 221,
        "110k": 162,
        "115k": 209,
        "120k": 122,
        "125k": 144,
        "130k": 105,
        "135k": 116,
        "140k": 94,
        "145k": 92,
        "150k": 75,
        "155k": 74,
        "160k": 117,
        "165k": 66,
        "170k": 66,
        "175k": 49,
        "180k": 57,
        "185k": 50,
        "190k": 59,
        "195k": 47,
        "200k": 42,
        "350k": 658
      },
      spousem: {
        "5k": 68,
        "10k": 18,
        "15k": 66,
        "20k": 76,
        "25k": 119,
        "30k": 226,
        "35k": 185,
        "40k": 164,
        "45k": 168,
        "50k": 133,
        "55k": 170,
        "60k": 111,
        "65k": 127,
        "70k": 99,
        "75k": 156,
        "80k": 116,
        "85k": 95,
        "90k": 87,
        "95k": 72,
        "100k": 77,
        "105k": 62,
        "110k": 57,
        "115k": 64,
        "120k": 40,
        "125k": 55,
        "130k": 51,
        "135k": 55,
        "140k": 45,
        "145k": 68,
        "150k": 42,
        "155k": 58,
        "160k": 36,
        "165k": 41,
        "170k": 15,
        "175k": 35,
        "180k": 28,
        "185k": 29,
        "190k": 7,
        "195k": 30,
        "200k": 15,
        "350k": 313
      },
      childm: {
        "5k": 1,
        "10k": 3,
        "15k": 1,
        "20k": 6,
        "25k": 6,
        "30k": 3,
        "35k": 1,
        "40k": 0,
        "45k": 1,
        "50k": 0,
        "55k": 4,
        "60k": 3,
        "65k": 3,
        "70k": 0,
        "75k": 0,
        "80k": 3,
        "85k": 2,
        "90k": 1,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 1,
        "120k": 0,
        "125k": 3,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 6,
        "10k": 11,
        "15k": 12,
        "20k": 8,
        "25k": 23,
        "30k": 18,
        "35k": 10,
        "40k": 24,
        "45k": 19,
        "50k": 16,
        "55k": 15,
        "60k": 11,
        "65k": 16,
        "70k": 18,
        "75k": 15,
        "80k": 6,
        "85k": 13,
        "90k": 18,
        "95k": 22,
        "100k": 17,
        "105k": 13,
        "110k": 5,
        "115k": 6,
        "120k": 13,
        "125k": 4,
        "130k": 12,
        "135k": 17,
        "140k": 9,
        "145k": 8,
        "150k": 8,
        "155k": 3,
        "160k": 14,
        "165k": 5,
        "170k": 7,
        "175k": 8,
        "180k": 7,
        "185k": 9,
        "190k": 14,
        "195k": 6,
        "200k": 6,
        "350k": 59
      },
      nonRelativem: {
        "5k": 2,
        "10k": 3,
        "15k": 13,
        "20k": 4,
        "25k": 14,
        "30k": 16,
        "35k": 14,
        "40k": 15,
        "45k": 9,
        "50k": 16,
        "55k": 8,
        "60k": 8,
        "65k": 7,
        "70k": 15,
        "75k": 10,
        "80k": 17,
        "85k": 3,
        "90k": 9,
        "95k": 4,
        "100k": 3,
        "105k": 7,
        "110k": 7,
        "115k": 8,
        "120k": 8,
        "125k": 3,
        "130k": 0,
        "135k": 0,
        "140k": 1,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 2,
        "165k": 0,
        "170k": 3,
        "175k": 6,
        "180k": 1,
        "185k": 1,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 50
      },
      houseLoitererfm: {
        "5k": 250,
        "10k": 462,
        "15k": 749,
        "20k": 637,
        "25k": 668,
        "30k": 625,
        "35k": 560,
        "40k": 496,
        "45k": 396,
        "50k": 343,
        "55k": 342,
        "60k": 277,
        "65k": 315,
        "70k": 267,
        "75k": 274,
        "80k": 254,
        "85k": 166,
        "90k": 134,
        "95k": 138,
        "100k": 113,
        "105k": 121,
        "110k": 116,
        "115k": 77,
        "120k": 66,
        "125k": 50,
        "130k": 101,
        "135k": 72,
        "140k": 74,
        "145k": 81,
        "150k": 52,
        "155k": 52,
        "160k": 38,
        "165k": 42,
        "170k": 29,
        "175k": 37,
        "180k": 24,
        "185k": 43,
        "190k": 32,
        "195k": 31,
        "200k": 18,
        "350k": 323
      },
      spousefm: {
        "5k": 92,
        "10k": 27,
        "15k": 57,
        "20k": 120,
        "25k": 231,
        "30k": 266,
        "35k": 256,
        "40k": 259,
        "45k": 227,
        "50k": 253,
        "55k": 291,
        "60k": 255,
        "65k": 174,
        "70k": 150,
        "75k": 172,
        "80k": 136,
        "85k": 163,
        "90k": 131,
        "95k": 146,
        "100k": 148,
        "105k": 133,
        "110k": 82,
        "115k": 110,
        "120k": 64,
        "125k": 100,
        "130k": 81,
        "135k": 55,
        "140k": 68,
        "145k": 55,
        "150k": 32,
        "155k": 54,
        "160k": 64,
        "165k": 59,
        "170k": 44,
        "175k": 33,
        "180k": 44,
        "185k": 22,
        "190k": 29,
        "195k": 33,
        "200k": 26,
        "350k": 392
      },
      childfm: {
        "5k": 0,
        "10k": 0,
        "15k": 1,
        "20k": 7,
        "25k": 5,
        "30k": 4,
        "35k": 12,
        "40k": 3,
        "45k": 2,
        "50k": 1,
        "55k": 1,
        "60k": 5,
        "65k": 0,
        "70k": 4,
        "75k": 4,
        "80k": 3,
        "85k": 0,
        "90k": 0,
        "95k": 1,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 2,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 2
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 13,
        "15k": 22,
        "20k": 12,
        "25k": 45,
        "30k": 46,
        "35k": 31,
        "40k": 50,
        "45k": 51,
        "50k": 46,
        "55k": 32,
        "60k": 55,
        "65k": 30,
        "70k": 34,
        "75k": 50,
        "80k": 24,
        "85k": 27,
        "90k": 31,
        "95k": 35,
        "100k": 13,
        "105k": 26,
        "110k": 24,
        "115k": 32,
        "120k": 24,
        "125k": 21,
        "130k": 18,
        "135k": 20,
        "140k": 16,
        "145k": 12,
        "150k": 4,
        "155k": 14,
        "160k": 19,
        "165k": 12,
        "170k": 8,
        "175k": 9,
        "180k": 15,
        "185k": 7,
        "190k": 13,
        "195k": 8,
        "200k": 5,
        "350k": 96
      },
      nonRelativefm: {
        "5k": 2,
        "10k": 1,
        "15k": 27,
        "20k": 9,
        "25k": 12,
        "30k": 21,
        "35k": 17,
        "40k": 16,
        "45k": 28,
        "50k": 13,
        "55k": 19,
        "60k": 13,
        "65k": 13,
        "70k": 12,
        "75k": 1,
        "80k": 5,
        "85k": 5,
        "90k": 7,
        "95k": 6,
        "100k": 12,
        "105k": 2,
        "110k": 10,
        "115k": 3,
        "120k": 2,
        "125k": 6,
        "130k": 2,
        "135k": 12,
        "140k": 0,
        "145k": 6,
        "150k": 2,
        "155k": 0,
        "160k": 8,
        "165k": 5,
        "170k": 3,
        "175k": 2,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 20
      }
    }
  },
  "2017": {
    "17": {
      houseLoitererm: {
        "5k": 1,
        "10k": 1,
        "15k": 5,
        "20k": 3,
        "25k": 6,
        "30k": 6,
        "35k": 11,
        "40k": 0,
        "45k": 2,
        "50k": 4,
        "55k": 2,
        "60k": 3,
        "65k": 2,
        "70k": 0,
        "75k": 5,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 2,
        "105k": 0,
        "110k": 4,
        "115k": 3,
        "120k": 0,
        "125k": 2,
        "130k": 2,
        "135k": 0,
        "140k": 2,
        "145k": 2,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childm: {
        "5k": 288,
        "10k": 157,
        "15k": 282,
        "20k": 320,
        "25k": 345,
        "30k": 402,
        "35k": 391,
        "40k": 431,
        "45k": 435,
        "50k": 373,
        "55k": 436,
        "60k": 335,
        "65k": 365,
        "70k": 391,
        "75k": 384,
        "80k": 316,
        "85k": 346,
        "90k": 314,
        "95k": 292,
        "100k": 261,
        "105k": 331,
        "110k": 207,
        "115k": 238,
        "120k": 188,
        "125k": 222,
        "130k": 185,
        "135k": 197,
        "140k": 196,
        "145k": 174,
        "150k": 189,
        "155k": 189,
        "160k": 133,
        "165k": 126,
        "170k": 114,
        "175k": 99,
        "180k": 116,
        "185k": 108,
        "190k": 90,
        "195k": 95,
        "200k": 86,
        "350k": "1,417"
      },
      otherRelativem: {
        "5k": 27,
        "10k": 19,
        "15k": 34,
        "20k": 33,
        "25k": 49,
        "30k": 58,
        "35k": 63,
        "40k": 45,
        "45k": 52,
        "50k": 44,
        "55k": 60,
        "60k": 35,
        "65k": 40,
        "70k": 46,
        "75k": 44,
        "80k": 47,
        "85k": 28,
        "90k": 22,
        "95k": 29,
        "100k": 30,
        "105k": 20,
        "110k": 13,
        "115k": 13,
        "120k": 13,
        "125k": 16,
        "130k": 13,
        "135k": 13,
        "140k": 28,
        "145k": 11,
        "150k": 7,
        "155k": 6,
        "160k": 5,
        "165k": 10,
        "170k": 9,
        "175k": 15,
        "180k": 1,
        "185k": 2,
        "190k": 7,
        "195k": 2,
        "200k": 3,
        "350k": "48"
      },
      nonRelativem: {
        "5k": 4,
        "10k": 2,
        "15k": 2,
        "20k": 4,
        "25k": 2,
        "30k": 14,
        "35k": 8,
        "40k": 11,
        "45k": 17,
        "50k": 5,
        "55k": 9,
        "60k": 11,
        "65k": 11,
        "70k": 10,
        "75k": 2,
        "80k": 11,
        "85k": 10,
        "90k": 10,
        "95k": 3,
        "100k": 2,
        "105k": 2,
        "110k": 3,
        "115k": 3,
        "120k": 1,
        "125k": 7,
        "130k": 2,
        "135k": 4,
        "140k": 0,
        "145k": 2,
        "150k": 4,
        "155k": 0,
        "160k": 6,
        "165k": 6,
        "170k": 0,
        "175k": 0,
        "180k": 1,
        "185k": 9,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": "19"
      },
      houseLoitererfm: {
        "5k": 17,
        "10k": 2,
        "15k": 0,
        "20k": 2,
        "25k": 6,
        "30k": 9,
        "35k": 15,
        "40k": 4,
        "45k": 5,
        "50k": 1,
        "55k": 9,
        "60k": 1,
        "65k": 0,
        "70k": 4,
        "75k": 2,
        "80k": 4,
        "85k": 2,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 1,
        "110k": 3,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 2,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childfm: {
        "5k": 248,
        "10k": 171,
        "15k": 271,
        "20k": 341,
        "25k": 381,
        "30k": 374,
        "35k": 375,
        "40k": 433,
        "45k": 389,
        "50k": 411,
        "55k": 391,
        "60k": 322,
        "65k": 303,
        "70k": 352,
        "75k": 305,
        "80k": 366,
        "85k": 361,
        "90k": 297,
        "95k": 266,
        "100k": 268,
        "105k": 274,
        "110k": 231,
        "115k": 217,
        "120k": 199,
        "125k": 233,
        "130k": 197,
        "135k": 186,
        "140k": 164,
        "145k": 150,
        "150k": 167,
        "155k": 210,
        "160k": 93,
        "165k": 144,
        "170k": 108,
        "175k": 77,
        "180k": 100,
        "185k": 87,
        "190k": 91,
        "195k": 83,
        "200k": 69,
        "350k": "1,369"
      },
      otherRelativefm: {
        "5k": 30,
        "10k": 22,
        "15k": 36,
        "20k": 41,
        "25k": 50,
        "30k": 35,
        "35k": 62,
        "40k": 59,
        "45k": 40,
        "50k": 48,
        "55k": 43,
        "60k": 46,
        "65k": 22,
        "70k": 57,
        "75k": 57,
        "80k": 47,
        "85k": 35,
        "90k": 39,
        "95k": 30,
        "100k": 27,
        "105k": 28,
        "110k": 23,
        "115k": 15,
        "120k": 13,
        "125k": 8,
        "130k": 7,
        "135k": 5,
        "140k": 10,
        "145k": 7,
        "150k": 6,
        "155k": 8,
        "160k": 11,
        "165k": 12,
        "170k": 2,
        "175k": 7,
        "180k": 4,
        "185k": 8,
        "190k": 7,
        "195k": 2,
        "200k": 6,
        "350k": "68"
      },
      nonRelativefm: {
        "5k": 1,
        "10k": 3,
        "15k": 4,
        "20k": 5,
        "25k": 3,
        "30k": 14,
        "35k": 7,
        "40k": 11,
        "45k": 13,
        "50k": 13,
        "55k": 15,
        "60k": 14,
        "65k": 3,
        "70k": 11,
        "75k": 3,
        "80k": 5,
        "85k": 12,
        "90k": 18,
        "95k": 0,
        "100k": 2,
        "105k": 4,
        "110k": 5,
        "115k": 16,
        "120k": 5,
        "125k": 2,
        "130k": 1,
        "135k": 3,
        "140k": 1,
        "145k": 2,
        "150k": 4,
        "155k": 2,
        "160k": 2,
        "165k": 2,
        "170k": 2,
        "175k": 4,
        "180k": 1,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 5,
        "350k": "22"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 95,
        "10k": 34,
        "15k": 38,
        "20k": 57,
        "25k": 75,
        "30k": 49,
        "35k": 68,
        "40k": 68,
        "45k": 47,
        "50k": 45,
        "55k": 33,
        "60k": 32,
        "65k": 36,
        "70k": 21,
        "75k": 29,
        "80k": 26,
        "85k": 22,
        "90k": 14,
        "95k": 17,
        "100k": 8,
        "105k": 29,
        "110k": 8,
        "115k": 5,
        "120k": 14,
        "125k": 9,
        "130k": 11,
        "135k": 3,
        "140k": 4,
        "145k": 5,
        "150k": 4,
        "155k": 2,
        "160k": 9,
        "165k": 2,
        "170k": 3,
        "175k": 2,
        "180k": 1,
        "185k": 5,
        "190k": 1,
        "195k": 6,
        "200k": 2,
        "350k": 33
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 3,
        "30k": 3,
        "35k": 0,
        "40k": 17,
        "45k": 5,
        "50k": 6,
        "55k": 1,
        "60k": 1,
        "65k": 3,
        "70k": 4,
        "75k": 0,
        "80k": 2,
        "85k": 0,
        "90k": 4,
        "95k": 1,
        "100k": 0,
        "105k": 4,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 1,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 94,
        "10k": 64,
        "15k": 79,
        "20k": 145,
        "25k": 112,
        "30k": 123,
        "35k": 136,
        "40k": 190,
        "45k": 198,
        "50k": 183,
        "55k": 151,
        "60k": 195,
        "65k": 186,
        "70k": 156,
        "75k": 184,
        "80k": 170,
        "85k": 209,
        "90k": 146,
        "95k": 178,
        "100k": 162,
        "105k": 180,
        "110k": 128,
        "115k": 149,
        "120k": 123,
        "125k": 114,
        "130k": 112,
        "135k": 153,
        "140k": 128,
        "145k": 107,
        "150k": 109,
        "155k": 87,
        "160k": 85,
        "165k": 80,
        "170k": 87,
        "175k": 67,
        "180k": 53,
        "185k": 52,
        "190k": 69,
        "195k": 66,
        "200k": 45,
        "350k": 745
      },
      otherRelativem: {
        "5k": 18,
        "10k": 19,
        "15k": 21,
        "20k": 29,
        "25k": 26,
        "30k": 23,
        "35k": 36,
        "40k": 39,
        "45k": 21,
        "50k": 48,
        "55k": 53,
        "60k": 28,
        "65k": 39,
        "70k": 21,
        "75k": 10,
        "80k": 38,
        "85k": 31,
        "90k": 25,
        "95k": 20,
        "100k": 19,
        "105k": 8,
        "110k": 23,
        "115k": 4,
        "120k": 7,
        "125k": 11,
        "130k": 14,
        "135k": 17,
        "140k": 11,
        "145k": 12,
        "150k": 14,
        "155k": 12,
        "160k": 11,
        "165k": 3,
        "170k": 16,
        "175k": 8,
        "180k": 4,
        "185k": 5,
        "190k": 2,
        "195k": 0,
        "200k": 2,
        "350k": 43
      },
      nonRelativem: {
        "5k": 69,
        "10k": 25,
        "15k": 11,
        "20k": 25,
        "25k": 64,
        "30k": 59,
        "35k": 36,
        "40k": 45,
        "45k": 34,
        "50k": 15,
        "55k": 25,
        "60k": 32,
        "65k": 51,
        "70k": 15,
        "75k": 24,
        "80k": 15,
        "85k": 34,
        "90k": 16,
        "95k": 15,
        "100k": 25,
        "105k": 16,
        "110k": 2,
        "115k": 1,
        "120k": 21,
        "125k": 11,
        "130k": 4,
        "135k": 9,
        "140k": 5,
        "145k": 3,
        "150k": 5,
        "155k": 2,
        "160k": 6,
        "165k": 0,
        "170k": 4,
        "175k": 0,
        "180k": 0,
        "185k": 4,
        "190k": 10,
        "195k": 5,
        "200k": 3,
        "350k": 18
      },
      houseLoitererfm: {
        "5k": 96,
        "10k": 76,
        "15k": 89,
        "20k": 70,
        "25k": 105,
        "30k": 86,
        "35k": 76,
        "40k": 75,
        "45k": 52,
        "50k": 39,
        "55k": 54,
        "60k": 36,
        "65k": 25,
        "70k": 42,
        "75k": 24,
        "80k": 25,
        "85k": 17,
        "90k": 27,
        "95k": 15,
        "100k": 13,
        "105k": 17,
        "110k": 10,
        "115k": 11,
        "120k": 7,
        "125k": 6,
        "130k": 14,
        "135k": 3,
        "140k": 7,
        "145k": 3,
        "150k": 2,
        "155k": 3,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 6,
        "190k": 0,
        "195k": 1,
        "200k": 3,
        "350k": 28
      },
      spousefm: {
        "5k": 6,
        "10k": 0,
        "15k": 2,
        "20k": 8,
        "25k": 11,
        "30k": 15,
        "35k": 7,
        "40k": 9,
        "45k": 11,
        "50k": 15,
        "55k": 14,
        "60k": 11,
        "65k": 8,
        "70k": 4,
        "75k": 10,
        "80k": 10,
        "85k": 5,
        "90k": 11,
        "95k": 5,
        "100k": 0,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 1,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 4,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      childfm: {
        "5k": 86,
        "10k": 43,
        "15k": 85,
        "20k": 113,
        "25k": 135,
        "30k": 123,
        "35k": 118,
        "40k": 152,
        "45k": 172,
        "50k": 176,
        "55k": 187,
        "60k": 159,
        "65k": 163,
        "70k": 150,
        "75k": 138,
        "80k": 148,
        "85k": 152,
        "90k": 163,
        "95k": 138,
        "100k": 134,
        "105k": 119,
        "110k": 125,
        "115k": 141,
        "120k": 100,
        "125k": 113,
        "130k": 123,
        "135k": 101,
        "140k": 113,
        "145k": 62,
        "150k": 75,
        "155k": 121,
        "160k": 75,
        "165k": 60,
        "170k": 65,
        "175k": 61,
        "180k": 35,
        "185k": 74,
        "190k": 41,
        "195k": 65,
        "200k": 33,
        "350k": 756
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 22,
        "15k": 22,
        "20k": 18,
        "25k": 15,
        "30k": 22,
        "35k": 35,
        "40k": 40,
        "45k": 30,
        "50k": 39,
        "55k": 41,
        "60k": 19,
        "65k": 40,
        "70k": 19,
        "75k": 24,
        "80k": 45,
        "85k": 15,
        "90k": 20,
        "95k": 11,
        "100k": 10,
        "105k": 15,
        "110k": 26,
        "115k": 17,
        "120k": 11,
        "125k": 10,
        "130k": 8,
        "135k": 11,
        "140k": 8,
        "145k": 3,
        "150k": 8,
        "155k": 3,
        "160k": 3,
        "165k": 2,
        "170k": 13,
        "175k": 7,
        "180k": 9,
        "185k": 9,
        "190k": 4,
        "195k": 0,
        "200k": 0,
        "350k": 51
      },
      nonRelativefm: {
        "5k": 25,
        "10k": 16,
        "15k": 13,
        "20k": 21,
        "25k": 53,
        "30k": 36,
        "35k": 36,
        "40k": 65,
        "45k": 50,
        "50k": 41,
        "55k": 41,
        "60k": 47,
        "65k": 42,
        "70k": 28,
        "75k": 46,
        "80k": 8,
        "85k": 17,
        "90k": 19,
        "95k": 30,
        "100k": 7,
        "105k": 18,
        "110k": 9,
        "115k": 15,
        "120k": 11,
        "125k": 19,
        "130k": 12,
        "135k": 2,
        "140k": 3,
        "145k": 5,
        "150k": 13,
        "155k": 5,
        "160k": 6,
        "165k": 4,
        "170k": 8,
        "175k": 4,
        "180k": 2,
        "185k": 2,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 34
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 108,
        "10k": 59,
        "15k": 71,
        "20k": 91,
        "25k": 130,
        "30k": 114,
        "35k": 111,
        "40k": 122,
        "45k": 88,
        "50k": 96,
        "55k": 84,
        "60k": 56,
        "65k": 88,
        "70k": 64,
        "75k": 84,
        "80k": 71,
        "85k": 57,
        "90k": 43,
        "95k": 34,
        "100k": 39,
        "105k": 25,
        "110k": 19,
        "115k": 16,
        "120k": 20,
        "125k": 32,
        "130k": 11,
        "135k": 10,
        "140k": 9,
        "145k": 19,
        "150k": 0,
        "155k": 26,
        "160k": 8,
        "165k": 5,
        "170k": 9,
        "175k": 0,
        "180k": 3,
        "185k": 3,
        "190k": 7,
        "195k": 1,
        "200k": 4,
        "350k": 33
      },
      spousem: {
        "5k": 1,
        "10k": 6,
        "15k": 3,
        "20k": 4,
        "25k": 10,
        "30k": 17,
        "35k": 13,
        "40k": 22,
        "45k": 17,
        "50k": 14,
        "55k": 16,
        "60k": 10,
        "65k": 8,
        "70k": 17,
        "75k": 10,
        "80k": 18,
        "85k": 1,
        "90k": 5,
        "95k": 1,
        "100k": 1,
        "105k": 6,
        "110k": 1,
        "115k": 1,
        "120k": 5,
        "125k": 1,
        "130k": 1,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 1,
        "160k": 0,
        "165k": 5,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      childm: {
        "5k": 23,
        "10k": 28,
        "15k": 33,
        "20k": 33,
        "25k": 48,
        "30k": 36,
        "35k": 68,
        "40k": 87,
        "45k": 83,
        "50k": 96,
        "55k": 69,
        "60k": 81,
        "65k": 70,
        "70k": 68,
        "75k": 79,
        "80k": 94,
        "85k": 100,
        "90k": 69,
        "95k": 91,
        "100k": 68,
        "105k": 85,
        "110k": 56,
        "115k": 67,
        "120k": 36,
        "125k": 66,
        "130k": 58,
        "135k": 97,
        "140k": 40,
        "145k": 34,
        "150k": 53,
        "155k": 55,
        "160k": 57,
        "165k": 38,
        "170k": 49,
        "175k": 29,
        "180k": 34,
        "185k": 63,
        "190k": 62,
        "195k": 8,
        "200k": 9,
        "350k": 457
      },
      otherRelativem: {
        "5k": 7,
        "10k": 6,
        "15k": 17,
        "20k": 7,
        "25k": 31,
        "30k": 6,
        "35k": 33,
        "40k": 34,
        "45k": 25,
        "50k": 22,
        "55k": 28,
        "60k": 12,
        "65k": 12,
        "70k": 14,
        "75k": 22,
        "80k": 16,
        "85k": 32,
        "90k": 20,
        "95k": 15,
        "100k": 11,
        "105k": 9,
        "110k": 12,
        "115k": 10,
        "120k": 9,
        "125k": 8,
        "130k": 6,
        "135k": 9,
        "140k": 6,
        "145k": 7,
        "150k": 0,
        "155k": 5,
        "160k": 15,
        "165k": 2,
        "170k": 6,
        "175k": 7,
        "180k": 5,
        "185k": 12,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 41
      },
      nonRelativem: {
        "5k": 66,
        "10k": 20,
        "15k": 10,
        "20k": 12,
        "25k": 34,
        "30k": 53,
        "35k": 37,
        "40k": 27,
        "45k": 63,
        "50k": 65,
        "55k": 50,
        "60k": 44,
        "65k": 39,
        "70k": 28,
        "75k": 38,
        "80k": 15,
        "85k": 29,
        "90k": 36,
        "95k": 19,
        "100k": 36,
        "105k": 17,
        "110k": 18,
        "115k": 25,
        "120k": 14,
        "125k": 24,
        "130k": 40,
        "135k": 20,
        "140k": 13,
        "145k": 7,
        "150k": 0,
        "155k": 31,
        "160k": 8,
        "165k": 0,
        "170k": 6,
        "175k": 7,
        "180k": 1,
        "185k": 5,
        "190k": 18,
        "195k": 3,
        "200k": 5,
        "350k": 72
      },
      houseLoitererfm: {
        "5k": 128,
        "10k": 94,
        "15k": 120,
        "20k": 125,
        "25k": 153,
        "30k": 164,
        "35k": 123,
        "40k": 140,
        "45k": 127,
        "50k": 107,
        "55k": 92,
        "60k": 56,
        "65k": 95,
        "70k": 69,
        "75k": 52,
        "80k": 39,
        "85k": 21,
        "90k": 27,
        "95k": 32,
        "100k": 31,
        "105k": 48,
        "110k": 6,
        "115k": 18,
        "120k": 20,
        "125k": 17,
        "130k": 8,
        "135k": 8,
        "140k": 3,
        "145k": 8,
        "150k": 6,
        "155k": 10,
        "160k": 7,
        "165k": 30,
        "170k": 2,
        "175k": 4,
        "180k": 0,
        "185k": 0,
        "190k": 7,
        "195k": 2,
        "200k": 4,
        "350k": 40
      },
      spousefm: {
        "5k": 14,
        "10k": 3,
        "15k": 5,
        "20k": 23,
        "25k": 25,
        "30k": 19,
        "35k": 28,
        "40k": 36,
        "45k": 45,
        "50k": 32,
        "55k": 22,
        "60k": 31,
        "65k": 20,
        "70k": 24,
        "75k": 46,
        "80k": 23,
        "85k": 18,
        "90k": 15,
        "95k": 11,
        "100k": 4,
        "105k": 14,
        "110k": 10,
        "115k": 7,
        "120k": 10,
        "125k": 7,
        "130k": 4,
        "135k": 1,
        "140k": 8,
        "145k": 4,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 5,
        "170k": 0,
        "175k": 1,
        "180k": 1,
        "185k": 2,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 5
      },
      childfm: {
        "5k": 29,
        "10k": 32,
        "15k": 39,
        "20k": 51,
        "25k": 32,
        "30k": 48,
        "35k": 73,
        "40k": 73,
        "45k": 66,
        "50k": 61,
        "55k": 56,
        "60k": 52,
        "65k": 83,
        "70k": 62,
        "75k": 69,
        "80k": 64,
        "85k": 57,
        "90k": 59,
        "95k": 42,
        "100k": 45,
        "105k": 52,
        "110k": 61,
        "115k": 55,
        "120k": 63,
        "125k": 51,
        "130k": 78,
        "135k": 40,
        "140k": 42,
        "145k": 43,
        "150k": 47,
        "155k": 48,
        "160k": 42,
        "165k": 31,
        "170k": 33,
        "175k": 27,
        "180k": 32,
        "185k": 28,
        "190k": 26,
        "195k": 25,
        "200k": 21,
        "350k": 400
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 2,
        "15k": 17,
        "20k": 7,
        "25k": 4,
        "30k": 17,
        "35k": 21,
        "40k": 28,
        "45k": 17,
        "50k": 22,
        "55k": 26,
        "60k": 10,
        "65k": 18,
        "70k": 22,
        "75k": 19,
        "80k": 31,
        "85k": 10,
        "90k": 8,
        "95k": 12,
        "100k": 29,
        "105k": 7,
        "110k": 9,
        "115k": 11,
        "120k": 12,
        "125k": 10,
        "130k": 6,
        "135k": 5,
        "140k": 2,
        "145k": 15,
        "150k": 2,
        "155k": 4,
        "160k": 0,
        "165k": 2,
        "170k": 6,
        "175k": 3,
        "180k": 1,
        "185k": 10,
        "190k": 0,
        "195k": 0,
        "200k": 4,
        "350k": 44
      },
      nonRelativefm: {
        "5k": 43,
        "10k": 5,
        "15k": 16,
        "20k": 47,
        "25k": 46,
        "30k": 51,
        "35k": 56,
        "40k": 50,
        "45k": 46,
        "50k": 60,
        "55k": 55,
        "60k": 33,
        "65k": 56,
        "70k": 42,
        "75k": 30,
        "80k": 41,
        "85k": 25,
        "90k": 42,
        "95k": 18,
        "100k": 14,
        "105k": 26,
        "110k": 11,
        "115k": 16,
        "120k": 17,
        "125k": 21,
        "130k": 15,
        "135k": 11,
        "140k": 6,
        "145k": 11,
        "150k": 11,
        "155k": 10,
        "160k": 6,
        "165k": 25,
        "170k": 4,
        "175k": 3,
        "180k": 1,
        "185k": 19,
        "190k": 4,
        "195k": 3,
        "200k": 7,
        "350k": 41
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 142,
        "10k": 87,
        "15k": 75,
        "20k": 107,
        "25k": 179,
        "30k": 159,
        "35k": 238,
        "40k": 235,
        "45k": 246,
        "50k": 200,
        "55k": 246,
        "60k": 215,
        "65k": 212,
        "70k": 222,
        "75k": 174,
        "80k": 198,
        "85k": 134,
        "90k": 153,
        "95k": 152,
        "100k": 129,
        "105k": 125,
        "110k": 95,
        "115k": 87,
        "120k": 84,
        "125k": 106,
        "130k": 69,
        "135k": 74,
        "140k": 44,
        "145k": 38,
        "150k": 31,
        "155k": 46,
        "160k": 35,
        "165k": 49,
        "170k": 31,
        "175k": 15,
        "180k": 8,
        "185k": 23,
        "190k": 22,
        "195k": 29,
        "200k": 25,
        "350k": 244
      },
      spousem: {
        "5k": 12,
        "10k": 10,
        "15k": 15,
        "20k": 24,
        "25k": 49,
        "30k": 31,
        "35k": 52,
        "40k": 43,
        "45k": 49,
        "50k": 55,
        "55k": 45,
        "60k": 47,
        "65k": 64,
        "70k": 57,
        "75k": 56,
        "80k": 28,
        "85k": 31,
        "90k": 28,
        "95k": 31,
        "100k": 19,
        "105k": 45,
        "110k": 35,
        "115k": 36,
        "120k": 19,
        "125k": 2,
        "130k": 20,
        "135k": 15,
        "140k": 20,
        "145k": 8,
        "150k": 13,
        "155k": 12,
        "160k": 1,
        "165k": 17,
        "170k": 6,
        "175k": 2,
        "180k": 4,
        "185k": 5,
        "190k": 0,
        "195k": 0,
        "200k": 7,
        "350k": 28
      },
      childm: {
        "5k": 23,
        "10k": 34,
        "15k": 29,
        "20k": 58,
        "25k": 59,
        "30k": 39,
        "35k": 73,
        "40k": 32,
        "45k": 55,
        "50k": 112,
        "55k": 97,
        "60k": 82,
        "65k": 102,
        "70k": 112,
        "75k": 85,
        "80k": 108,
        "85k": 119,
        "90k": 116,
        "95k": 72,
        "100k": 74,
        "105k": 95,
        "110k": 92,
        "115k": 74,
        "120k": 93,
        "125k": 65,
        "130k": 109,
        "135k": 48,
        "140k": 37,
        "145k": 32,
        "150k": 52,
        "155k": 60,
        "160k": 53,
        "165k": 37,
        "170k": 37,
        "175k": 37,
        "180k": 49,
        "185k": 34,
        "190k": 56,
        "195k": 54,
        "200k": 32,
        "350k": 543
      },
      otherRelativem: {
        "5k": 19,
        "10k": 3,
        "15k": 8,
        "20k": 30,
        "25k": 24,
        "30k": 24,
        "35k": 23,
        "40k": 13,
        "45k": 27,
        "50k": 36,
        "55k": 28,
        "60k": 23,
        "65k": 39,
        "70k": 68,
        "75k": 30,
        "80k": 32,
        "85k": 29,
        "90k": 24,
        "95k": 33,
        "100k": 40,
        "105k": 14,
        "110k": 20,
        "115k": 15,
        "120k": 11,
        "125k": 12,
        "130k": 14,
        "135k": 20,
        "140k": 18,
        "145k": 18,
        "150k": 8,
        "155k": 14,
        "160k": 29,
        "165k": 11,
        "170k": 5,
        "175k": 1,
        "180k": 8,
        "185k": 0,
        "190k": 20,
        "195k": 7,
        "200k": 4,
        "350k": 73
      },
      nonRelativem: {
        "5k": 38,
        "10k": 11,
        "15k": 15,
        "20k": 39,
        "25k": 29,
        "30k": 30,
        "35k": 35,
        "40k": 46,
        "45k": 37,
        "50k": 62,
        "55k": 84,
        "60k": 82,
        "65k": 58,
        "70k": 88,
        "75k": 77,
        "80k": 93,
        "85k": 50,
        "90k": 77,
        "95k": 41,
        "100k": 40,
        "105k": 39,
        "110k": 42,
        "115k": 17,
        "120k": 27,
        "125k": 55,
        "130k": 44,
        "135k": 37,
        "140k": 29,
        "145k": 21,
        "150k": 19,
        "155k": 34,
        "160k": 41,
        "165k": 34,
        "170k": 19,
        "175k": 24,
        "180k": 17,
        "185k": 13,
        "190k": 17,
        "195k": 11,
        "200k": 16,
        "350k": 211
      },
      houseLoitererfm: {
        "5k": 233,
        "10k": 209,
        "15k": 204,
        "20k": 156,
        "25k": 252,
        "30k": 306,
        "35k": 337,
        "40k": 201,
        "45k": 254,
        "50k": 190,
        "55k": 211,
        "60k": 201,
        "65k": 238,
        "70k": 162,
        "75k": 168,
        "80k": 158,
        "85k": 113,
        "90k": 139,
        "95k": 126,
        "100k": 79,
        "105k": 97,
        "110k": 75,
        "115k": 55,
        "120k": 34,
        "125k": 43,
        "130k": 60,
        "135k": 43,
        "140k": 41,
        "145k": 50,
        "150k": 16,
        "155k": 34,
        "160k": 11,
        "165k": 18,
        "170k": 16,
        "175k": 22,
        "180k": 14,
        "185k": 27,
        "190k": 6,
        "195k": 5,
        "200k": 10,
        "350k": 151
      },
      spousefm: {
        "5k": 29,
        "10k": 11,
        "15k": 34,
        "20k": 32,
        "25k": 48,
        "30k": 60,
        "35k": 70,
        "40k": 71,
        "45k": 91,
        "50k": 77,
        "55k": 123,
        "60k": 116,
        "65k": 91,
        "70k": 111,
        "75k": 93,
        "80k": 120,
        "85k": 94,
        "90k": 94,
        "95k": 101,
        "100k": 101,
        "105k": 62,
        "110k": 59,
        "115k": 47,
        "120k": 49,
        "125k": 58,
        "130k": 27,
        "135k": 71,
        "140k": 31,
        "145k": 26,
        "150k": 38,
        "155k": 10,
        "160k": 28,
        "165k": 14,
        "170k": 27,
        "175k": 19,
        "180k": 15,
        "185k": 20,
        "190k": 14,
        "195k": 22,
        "200k": 11,
        "350k": 151
      },
      childfm: {
        "5k": 17,
        "10k": 18,
        "15k": 25,
        "20k": 31,
        "25k": 27,
        "30k": 35,
        "35k": 35,
        "40k": 49,
        "45k": 64,
        "50k": 57,
        "55k": 69,
        "60k": 66,
        "65k": 60,
        "70k": 68,
        "75k": 63,
        "80k": 66,
        "85k": 51,
        "90k": 59,
        "95k": 42,
        "100k": 44,
        "105k": 56,
        "110k": 51,
        "115k": 34,
        "120k": 42,
        "125k": 29,
        "130k": 55,
        "135k": 35,
        "140k": 47,
        "145k": 33,
        "150k": 25,
        "155k": 39,
        "160k": 30,
        "165k": 15,
        "170k": 38,
        "175k": 30,
        "180k": 18,
        "185k": 21,
        "190k": 18,
        "195k": 40,
        "200k": 22,
        "350k": 301
      },
      otherRelativefm: {
        "5k": 10,
        "10k": 6,
        "15k": 4,
        "20k": 24,
        "25k": 22,
        "30k": 14,
        "35k": 14,
        "40k": 34,
        "45k": 24,
        "50k": 18,
        "55k": 19,
        "60k": 15,
        "65k": 19,
        "70k": 31,
        "75k": 17,
        "80k": 33,
        "85k": 38,
        "90k": 17,
        "95k": 27,
        "100k": 18,
        "105k": 22,
        "110k": 11,
        "115k": 11,
        "120k": 7,
        "125k": 11,
        "130k": 19,
        "135k": 7,
        "140k": 13,
        "145k": 19,
        "150k": 14,
        "155k": 7,
        "160k": 8,
        "165k": 14,
        "170k": 8,
        "175k": 5,
        "180k": 9,
        "185k": 7,
        "190k": 0,
        "195k": 8,
        "200k": 0,
        "350k": 49
      },
      nonRelativefm: {
        "5k": 40,
        "10k": 23,
        "15k": 33,
        "20k": 19,
        "25k": 18,
        "30k": 28,
        "35k": 65,
        "40k": 51,
        "45k": 77,
        "50k": 55,
        "55k": 75,
        "60k": 67,
        "65k": 74,
        "70k": 69,
        "75k": 58,
        "80k": 70,
        "85k": 45,
        "90k": 75,
        "95k": 52,
        "100k": 55,
        "105k": 54,
        "110k": 53,
        "115k": 40,
        "120k": 46,
        "125k": 74,
        "130k": 46,
        "135k": 33,
        "140k": 29,
        "145k": 17,
        "150k": 15,
        "155k": 38,
        "160k": 37,
        "165k": 17,
        "170k": 12,
        "175k": 14,
        "180k": 6,
        "185k": 13,
        "190k": 3,
        "195k": 7,
        "200k": 8,
        "350k": 158
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 123,
        "10k": 85,
        "15k": 85,
        "20k": 123,
        "25k": 156,
        "30k": 188,
        "35k": 229,
        "40k": 209,
        "45k": 238,
        "50k": 225,
        "55k": 269,
        "60k": 215,
        "65k": 227,
        "70k": 237,
        "75k": 174,
        "80k": 239,
        "85k": 200,
        "90k": 165,
        "95k": 156,
        "100k": 137,
        "105k": 152,
        "110k": 118,
        "115k": 90,
        "120k": 81,
        "125k": 90,
        "130k": 86,
        "135k": 110,
        "140k": 87,
        "145k": 64,
        "150k": 77,
        "155k": 74,
        "160k": 76,
        "165k": 62,
        "170k": 44,
        "175k": 53,
        "180k": 61,
        "185k": 48,
        "190k": 23,
        "195k": 24,
        "200k": 24,
        "350k": 432
      },
      spousem: {
        "5k": 24,
        "10k": 17,
        "15k": 21,
        "20k": 23,
        "25k": 43,
        "30k": 61,
        "35k": 79,
        "40k": 53,
        "45k": 52,
        "50k": 97,
        "55k": 80,
        "60k": 78,
        "65k": 101,
        "70k": 91,
        "75k": 84,
        "80k": 81,
        "85k": 105,
        "90k": 98,
        "95k": 65,
        "100k": 62,
        "105k": 64,
        "110k": 40,
        "115k": 64,
        "120k": 59,
        "125k": 34,
        "130k": 55,
        "135k": 28,
        "140k": 34,
        "145k": 22,
        "150k": 23,
        "155k": 31,
        "160k": 18,
        "165k": 6,
        "170k": 26,
        "175k": 14,
        "180k": 9,
        "185k": 20,
        "190k": 6,
        "195k": 8,
        "200k": 11,
        "350k": 155
      },
      childm: {
        "5k": 13,
        "10k": 36,
        "15k": 12,
        "20k": 44,
        "25k": 54,
        "30k": 37,
        "35k": 48,
        "40k": 59,
        "45k": 59,
        "50k": 30,
        "55k": 62,
        "60k": 51,
        "65k": 73,
        "70k": 18,
        "75k": 49,
        "80k": 37,
        "85k": 67,
        "90k": 49,
        "95k": 27,
        "100k": 46,
        "105k": 43,
        "110k": 23,
        "115k": 47,
        "120k": 31,
        "125k": 23,
        "130k": 23,
        "135k": 20,
        "140k": 20,
        "145k": 40,
        "150k": 21,
        "155k": 30,
        "160k": 9,
        "165k": 23,
        "170k": 12,
        "175k": 28,
        "180k": 21,
        "185k": 15,
        "190k": 17,
        "195k": 14,
        "200k": 7,
        "350k": 163
      },
      otherRelativem: {
        "5k": 6,
        "10k": 10,
        "15k": 16,
        "20k": 19,
        "25k": 13,
        "30k": 18,
        "35k": 17,
        "40k": 9,
        "45k": 23,
        "50k": 16,
        "55k": 11,
        "60k": 24,
        "65k": 13,
        "70k": 21,
        "75k": 19,
        "80k": 24,
        "85k": 16,
        "90k": 24,
        "95k": 27,
        "100k": 40,
        "105k": 14,
        "110k": 5,
        "115k": 11,
        "120k": 10,
        "125k": 12,
        "130k": 4,
        "135k": 6,
        "140k": 9,
        "145k": 4,
        "150k": 2,
        "155k": 12,
        "160k": 6,
        "165k": 3,
        "170k": 13,
        "175k": 4,
        "180k": 3,
        "185k": 15,
        "190k": 10,
        "195k": 6,
        "200k": 0,
        "350k": 57
      },
      nonRelativem: {
        "5k": 18,
        "10k": 4,
        "15k": 10,
        "20k": 6,
        "25k": 31,
        "30k": 31,
        "35k": 30,
        "40k": 31,
        "45k": 52,
        "50k": 30,
        "55k": 54,
        "60k": 43,
        "65k": 61,
        "70k": 45,
        "75k": 32,
        "80k": 36,
        "85k": 67,
        "90k": 52,
        "95k": 32,
        "100k": 29,
        "105k": 19,
        "110k": 19,
        "115k": 13,
        "120k": 17,
        "125k": 29,
        "130k": 22,
        "135k": 14,
        "140k": 11,
        "145k": 23,
        "150k": 18,
        "155k": 11,
        "160k": 19,
        "165k": 15,
        "170k": 11,
        "175k": 11,
        "180k": 2,
        "185k": 12,
        "190k": 10,
        "195k": 6,
        "200k": 9,
        "350k": 147
      },
      houseLoitererfm: {
        "5k": 198,
        "10k": 135,
        "15k": 166,
        "20k": 213,
        "25k": 224,
        "30k": 246,
        "35k": 295,
        "40k": 262,
        "45k": 232,
        "50k": 212,
        "55k": 223,
        "60k": 160,
        "65k": 167,
        "70k": 211,
        "75k": 190,
        "80k": 132,
        "85k": 184,
        "90k": 143,
        "95k": 137,
        "100k": 95,
        "105k": 111,
        "110k": 75,
        "115k": 101,
        "120k": 89,
        "125k": 75,
        "130k": 108,
        "135k": 76,
        "140k": 52,
        "145k": 43,
        "150k": 50,
        "155k": 44,
        "160k": 57,
        "165k": 29,
        "170k": 25,
        "175k": 20,
        "180k": 28,
        "185k": 21,
        "190k": 19,
        "195k": 14,
        "200k": 23,
        "350k": 273
      },
      spousefm: {
        "5k": 48,
        "10k": 25,
        "15k": 37,
        "20k": 27,
        "25k": 70,
        "30k": 91,
        "35k": 89,
        "40k": 103,
        "45k": 92,
        "50k": 102,
        "55k": 114,
        "60k": 94,
        "65k": 121,
        "70k": 108,
        "75k": 131,
        "80k": 134,
        "85k": 133,
        "90k": 109,
        "95k": 118,
        "100k": 121,
        "105k": 134,
        "110k": 90,
        "115k": 88,
        "120k": 71,
        "125k": 75,
        "130k": 76,
        "135k": 70,
        "140k": 51,
        "145k": 71,
        "150k": 71,
        "155k": 78,
        "160k": 56,
        "165k": 39,
        "170k": 58,
        "175k": 36,
        "180k": 46,
        "185k": 33,
        "190k": 21,
        "195k": 27,
        "200k": 20,
        "350k": 403
      },
      childfm: {
        "5k": 9,
        "10k": 10,
        "15k": 21,
        "20k": 25,
        "25k": 22,
        "30k": 14,
        "35k": 22,
        "40k": 37,
        "45k": 37,
        "50k": 28,
        "55k": 22,
        "60k": 13,
        "65k": 33,
        "70k": 32,
        "75k": 28,
        "80k": 53,
        "85k": 23,
        "90k": 34,
        "95k": 55,
        "100k": 21,
        "105k": 20,
        "110k": 14,
        "115k": 28,
        "120k": 10,
        "125k": 26,
        "130k": 13,
        "135k": 15,
        "140k": 10,
        "145k": 14,
        "150k": 10,
        "155k": 13,
        "160k": 19,
        "165k": 13,
        "170k": 20,
        "175k": 21,
        "180k": 10,
        "185k": 13,
        "190k": 14,
        "195k": 14,
        "200k": 1,
        "350k": 105
      },
      otherRelativefm: {
        "5k": 4,
        "10k": 3,
        "15k": 3,
        "20k": 13,
        "25k": 6,
        "30k": 8,
        "35k": 11,
        "40k": 10,
        "45k": 13,
        "50k": 25,
        "55k": 14,
        "60k": 7,
        "65k": 13,
        "70k": 6,
        "75k": 18,
        "80k": 11,
        "85k": 13,
        "90k": 10,
        "95k": 5,
        "100k": 14,
        "105k": 8,
        "110k": 4,
        "115k": 10,
        "120k": 11,
        "125k": 4,
        "130k": 3,
        "135k": 6,
        "140k": 1,
        "145k": 2,
        "150k": 8,
        "155k": 12,
        "160k": 11,
        "165k": 6,
        "170k": 5,
        "175k": 4,
        "180k": 3,
        "185k": 6,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": 29
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 4,
        "15k": 14,
        "20k": 17,
        "25k": 29,
        "30k": 30,
        "35k": 19,
        "40k": 21,
        "45k": 28,
        "50k": 22,
        "55k": 33,
        "60k": 29,
        "65k": 35,
        "70k": 27,
        "75k": 37,
        "80k": 55,
        "85k": 37,
        "90k": 33,
        "95k": 34,
        "100k": 22,
        "105k": 47,
        "110k": 35,
        "115k": 19,
        "120k": 9,
        "125k": 28,
        "130k": 21,
        "135k": 19,
        "140k": 25,
        "145k": 12,
        "150k": 18,
        "155k": 5,
        "160k": 9,
        "165k": 13,
        "170k": 6,
        "175k": 12,
        "180k": 7,
        "185k": 8,
        "190k": 5,
        "195k": 7,
        "200k": 6,
        "350k": 103
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 230,
        "10k": 122,
        "15k": 217,
        "20k": 232,
        "25k": 287,
        "30k": 290,
        "35k": 349,
        "40k": 407,
        "45k": 365,
        "50k": 337,
        "55k": 403,
        "60k": 369,
        "65k": 348,
        "70k": 308,
        "75k": 368,
        "80k": 351,
        "85k": 419,
        "90k": 317,
        "95k": 299,
        "100k": 327,
        "105k": 400,
        "110k": 271,
        "115k": 269,
        "120k": 236,
        "125k": 266,
        "130k": 221,
        "135k": 208,
        "140k": 160,
        "145k": 172,
        "150k": 182,
        "155k": 240,
        "160k": 127,
        "165k": 121,
        "170k": 127,
        "175k": 87,
        "180k": 115,
        "185k": 90,
        "190k": 83,
        "195k": 103,
        "200k": 81,
        "350k": "1,287"
      },
      spousem: {
        "5k": 52,
        "10k": 20,
        "15k": 32,
        "20k": 71,
        "25k": 106,
        "30k": 121,
        "35k": 127,
        "40k": 148,
        "45k": 143,
        "50k": 146,
        "55k": 137,
        "60k": 134,
        "65k": 206,
        "70k": 175,
        "75k": 155,
        "80k": 169,
        "85k": 173,
        "90k": 148,
        "95k": 161,
        "100k": 141,
        "105k": 168,
        "110k": 125,
        "115k": 138,
        "120k": 125,
        "125k": 132,
        "130k": 93,
        "135k": 131,
        "140k": 94,
        "145k": 98,
        "150k": 92,
        "155k": 94,
        "160k": 52,
        "165k": 78,
        "170k": 42,
        "175k": 42,
        "180k": 51,
        "185k": 54,
        "190k": 28,
        "195k": 45,
        "200k": 48,
        "350k": "678"
      },
      childm: {
        "5k": 14,
        "10k": 17,
        "15k": 32,
        "20k": 27,
        "25k": 66,
        "30k": 40,
        "35k": 60,
        "40k": 65,
        "45k": 48,
        "50k": 62,
        "55k": 64,
        "60k": 74,
        "65k": 59,
        "70k": 75,
        "75k": 55,
        "80k": 55,
        "85k": 62,
        "90k": 46,
        "95k": 60,
        "100k": 37,
        "105k": 37,
        "110k": 13,
        "115k": 30,
        "120k": 16,
        "125k": 27,
        "130k": 33,
        "135k": 34,
        "140k": 6,
        "145k": 3,
        "150k": 17,
        "155k": 24,
        "160k": 10,
        "165k": 22,
        "170k": 3,
        "175k": 20,
        "180k": 17,
        "185k": 15,
        "190k": 7,
        "195k": 5,
        "200k": 11,
        "350k": "132"
      },
      otherRelativem: {
        "5k": 21,
        "10k": 2,
        "15k": 6,
        "20k": 12,
        "25k": 16,
        "30k": 18,
        "35k": 11,
        "40k": 20,
        "45k": 35,
        "50k": 48,
        "55k": 43,
        "60k": 30,
        "65k": 49,
        "70k": 44,
        "75k": 23,
        "80k": 33,
        "85k": 21,
        "90k": 14,
        "95k": 28,
        "100k": 29,
        "105k": 28,
        "110k": 32,
        "115k": 20,
        "120k": 36,
        "125k": 11,
        "130k": 15,
        "135k": 27,
        "140k": 19,
        "145k": 21,
        "150k": 9,
        "155k": 17,
        "160k": 12,
        "165k": 7,
        "170k": 19,
        "175k": 8,
        "180k": 8,
        "185k": 8,
        "190k": 7,
        "195k": 2,
        "200k": 1,
        "350k": "84"
      },
      nonRelativem: {
        "5k": 17,
        "10k": 13,
        "15k": 16,
        "20k": 37,
        "25k": 29,
        "30k": 65,
        "35k": 58,
        "40k": 51,
        "45k": 48,
        "50k": 60,
        "55k": 69,
        "60k": 56,
        "65k": 43,
        "70k": 58,
        "75k": 44,
        "80k": 39,
        "85k": 56,
        "90k": 34,
        "95k": 61,
        "100k": 40,
        "105k": 92,
        "110k": 28,
        "115k": 40,
        "120k": 24,
        "125k": 20,
        "130k": 13,
        "135k": 18,
        "140k": 36,
        "145k": 20,
        "150k": 15,
        "155k": 19,
        "160k": 19,
        "165k": 7,
        "170k": 6,
        "175k": 7,
        "180k": 13,
        "185k": 17,
        "190k": 29,
        "195k": 6,
        "200k": 8,
        "350k": "177"
      },
      houseLoitererfm: {
        "5k": 379,
        "10k": 199,
        "15k": 383,
        "20k": 422,
        "25k": 412,
        "30k": 419,
        "35k": 471,
        "40k": 446,
        "45k": 388,
        "50k": 429,
        "55k": 380,
        "60k": 325,
        "65k": 399,
        "70k": 316,
        "75k": 298,
        "80k": 316,
        "85k": 361,
        "90k": 270,
        "95k": 245,
        "100k": 223,
        "105k": 239,
        "110k": 177,
        "115k": 199,
        "120k": 172,
        "125k": 190,
        "130k": 136,
        "135k": 157,
        "140k": 136,
        "145k": 137,
        "150k": 111,
        "155k": 140,
        "160k": 84,
        "165k": 87,
        "170k": 82,
        "175k": 60,
        "180k": 59,
        "185k": 73,
        "190k": 42,
        "195k": 64,
        "200k": 65,
        "350k": "895"
      },
      spousefm: {
        "5k": 92,
        "10k": 45,
        "15k": 50,
        "20k": 76,
        "25k": 132,
        "30k": 104,
        "35k": 125,
        "40k": 177,
        "45k": 157,
        "50k": 195,
        "55k": 239,
        "60k": 164,
        "65k": 233,
        "70k": 207,
        "75k": 234,
        "80k": 194,
        "85k": 246,
        "90k": 219,
        "95k": 230,
        "100k": 242,
        "105k": 248,
        "110k": 187,
        "115k": 201,
        "120k": 181,
        "125k": 208,
        "130k": 190,
        "135k": 170,
        "140k": 127,
        "145k": 114,
        "150k": 124,
        "155k": 183,
        "160k": 128,
        "165k": 122,
        "170k": 110,
        "175k": 87,
        "180k": 111,
        "185k": 78,
        "190k": 68,
        "195k": 74,
        "200k": 70,
        "350k": "1,160"
      },
      childfm: {
        "5k": 19,
        "10k": 6,
        "15k": 15,
        "20k": 23,
        "25k": 28,
        "30k": 29,
        "35k": 47,
        "40k": 46,
        "45k": 24,
        "50k": 41,
        "55k": 52,
        "60k": 46,
        "65k": 31,
        "70k": 32,
        "75k": 29,
        "80k": 45,
        "85k": 27,
        "90k": 27,
        "95k": 47,
        "100k": 28,
        "105k": 20,
        "110k": 32,
        "115k": 19,
        "120k": 10,
        "125k": 17,
        "130k": 17,
        "135k": 15,
        "140k": 30,
        "145k": 13,
        "150k": 17,
        "155k": 19,
        "160k": 8,
        "165k": 19,
        "170k": 6,
        "175k": 11,
        "180k": 10,
        "185k": 5,
        "190k": 9,
        "195k": 2,
        "200k": 0,
        "350k": "80"
      },
      otherRelativefm: {
        "5k": 19,
        "10k": 6,
        "15k": 14,
        "20k": 8,
        "25k": 20,
        "30k": 22,
        "35k": 40,
        "40k": 22,
        "45k": 30,
        "50k": 33,
        "55k": 45,
        "60k": 19,
        "65k": 31,
        "70k": 30,
        "75k": 51,
        "80k": 33,
        "85k": 24,
        "90k": 13,
        "95k": 34,
        "100k": 22,
        "105k": 16,
        "110k": 13,
        "115k": 19,
        "120k": 17,
        "125k": 9,
        "130k": 15,
        "135k": 16,
        "140k": 18,
        "145k": 8,
        "150k": 14,
        "155k": 17,
        "160k": 10,
        "165k": 4,
        "170k": 11,
        "175k": 4,
        "180k": 10,
        "185k": 11,
        "190k": 5,
        "195k": 4,
        "200k": 2,
        "350k": "73"
      },
      nonRelativefm: {
        "5k": 8,
        "10k": 16,
        "15k": 9,
        "20k": 17,
        "25k": 29,
        "30k": 39,
        "35k": 33,
        "40k": 58,
        "45k": 47,
        "50k": 25,
        "55k": 39,
        "60k": 30,
        "65k": 25,
        "70k": 31,
        "75k": 24,
        "80k": 53,
        "85k": 40,
        "90k": 25,
        "95k": 41,
        "100k": 25,
        "105k": 37,
        "110k": 27,
        "115k": 29,
        "120k": 25,
        "125k": 23,
        "130k": 19,
        "135k": 51,
        "140k": 23,
        "145k": 22,
        "150k": 16,
        "155k": 13,
        "160k": 12,
        "165k": 11,
        "170k": 7,
        "175k": 14,
        "180k": 8,
        "185k": 16,
        "190k": 13,
        "195k": 9,
        "200k": 4,
        "350k": "94"
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 300,
        "10k": 142,
        "15k": 230,
        "20k": 252,
        "25k": 343,
        "30k": 310,
        "35k": 292,
        "40k": 361,
        "45k": 396,
        "50k": 373,
        "55k": 386,
        "60k": 332,
        "65k": 371,
        "70k": 331,
        "75k": 427,
        "80k": 312,
        "85k": 381,
        "90k": 303,
        "95k": 320,
        "100k": 243,
        "105k": 327,
        "110k": 235,
        "115k": 264,
        "120k": 238,
        "125k": 246,
        "130k": 223,
        "135k": 219,
        "140k": 203,
        "145k": 157,
        "150k": 173,
        "155k": 195,
        "160k": 155,
        "165k": 153,
        "170k": 141,
        "175k": 155,
        "180k": 119,
        "185k": 136,
        "190k": 98,
        "195k": 98,
        "200k": 75,
        "350k": "1,542"
      },
      spousem: {
        "5k": 45,
        "10k": 28,
        "15k": 43,
        "20k": 49,
        "25k": 74,
        "30k": 92,
        "35k": 123,
        "40k": 111,
        "45k": 131,
        "50k": 119,
        "55k": 140,
        "60k": 116,
        "65k": 122,
        "70k": 167,
        "75k": 184,
        "80k": 132,
        "85k": 173,
        "90k": 182,
        "95k": 162,
        "100k": 153,
        "105k": 142,
        "110k": 132,
        "115k": 140,
        "120k": 131,
        "125k": 167,
        "130k": 134,
        "135k": 143,
        "140k": 111,
        "145k": 124,
        "150k": 69,
        "155k": 147,
        "160k": 65,
        "165k": 85,
        "170k": 86,
        "175k": 53,
        "180k": 56,
        "185k": 61,
        "190k": 62,
        "195k": 70,
        "200k": 54,
        "350k": "890"
      },
      childm: {
        "5k": 30,
        "10k": 17,
        "15k": 58,
        "20k": 56,
        "25k": 70,
        "30k": 54,
        "35k": 73,
        "40k": 74,
        "45k": 43,
        "50k": 71,
        "55k": 40,
        "60k": 31,
        "65k": 41,
        "70k": 35,
        "75k": 34,
        "80k": 41,
        "85k": 44,
        "90k": 19,
        "95k": 41,
        "100k": 24,
        "105k": 27,
        "110k": 12,
        "115k": 20,
        "120k": 19,
        "125k": 9,
        "130k": 11,
        "135k": 10,
        "140k": 35,
        "145k": 7,
        "150k": 9,
        "155k": 9,
        "160k": 11,
        "165k": 11,
        "170k": 6,
        "175k": 7,
        "180k": 0,
        "185k": 0,
        "190k": 4,
        "195k": 17,
        "200k": 3,
        "350k": "48"
      },
      otherRelativem: {
        "5k": 10,
        "10k": 5,
        "15k": 32,
        "20k": 14,
        "25k": 19,
        "30k": 33,
        "35k": 30,
        "40k": 29,
        "45k": 27,
        "50k": 56,
        "55k": 33,
        "60k": 47,
        "65k": 10,
        "70k": 28,
        "75k": 46,
        "80k": 49,
        "85k": 70,
        "90k": 19,
        "95k": 58,
        "100k": 44,
        "105k": 28,
        "110k": 16,
        "115k": 25,
        "120k": 28,
        "125k": 12,
        "130k": 17,
        "135k": 7,
        "140k": 18,
        "145k": 22,
        "150k": 13,
        "155k": 8,
        "160k": 19,
        "165k": 4,
        "170k": 19,
        "175k": 7,
        "180k": 6,
        "185k": 12,
        "190k": 6,
        "195k": 4,
        "200k": 9,
        "350k": "120"
      },
      nonRelativem: {
        "5k": 34,
        "10k": 8,
        "15k": 22,
        "20k": 21,
        "25k": 43,
        "30k": 43,
        "35k": 57,
        "40k": 30,
        "45k": 68,
        "50k": 50,
        "55k": 49,
        "60k": 45,
        "65k": 50,
        "70k": 45,
        "75k": 27,
        "80k": 59,
        "85k": 44,
        "90k": 47,
        "95k": 37,
        "100k": 31,
        "105k": 43,
        "110k": 21,
        "115k": 18,
        "120k": 17,
        "125k": 23,
        "130k": 12,
        "135k": 27,
        "140k": 25,
        "145k": 15,
        "150k": 15,
        "155k": 12,
        "160k": 17,
        "165k": 7,
        "170k": 21,
        "175k": 5,
        "180k": 12,
        "185k": 4,
        "190k": 20,
        "195k": 6,
        "200k": 3,
        "350k": "145"
      },
      houseLoitererfm: {
        "5k": 332,
        "10k": 302,
        "15k": 388,
        "20k": 386,
        "25k": 362,
        "30k": 439,
        "35k": 438,
        "40k": 440,
        "45k": 396,
        "50k": 400,
        "55k": 386,
        "60k": 362,
        "65k": 341,
        "70k": 374,
        "75k": 283,
        "80k": 362,
        "85k": 337,
        "90k": 276,
        "95k": 260,
        "100k": 258,
        "105k": 266,
        "110k": 206,
        "115k": 226,
        "120k": 191,
        "125k": 212,
        "130k": 185,
        "135k": 160,
        "140k": 110,
        "145k": 161,
        "150k": 125,
        "155k": 176,
        "160k": 85,
        "165k": 114,
        "170k": 130,
        "175k": 84,
        "180k": 78,
        "185k": 78,
        "190k": 67,
        "195k": 71,
        "200k": 50,
        "350k": "1,087"
      },
      spousefm: {
        "5k": 60,
        "10k": 27,
        "15k": 71,
        "20k": 80,
        "25k": 117,
        "30k": 113,
        "35k": 138,
        "40k": 150,
        "45k": 177,
        "50k": 181,
        "55k": 206,
        "60k": 204,
        "65k": 218,
        "70k": 194,
        "75k": 204,
        "80k": 217,
        "85k": 238,
        "90k": 214,
        "95k": 212,
        "100k": 179,
        "105k": 207,
        "110k": 199,
        "115k": 203,
        "120k": 194,
        "125k": 201,
        "130k": 188,
        "135k": 159,
        "140k": 159,
        "145k": 148,
        "150k": 162,
        "155k": 154,
        "160k": 146,
        "165k": 101,
        "170k": 107,
        "175k": 135,
        "180k": 103,
        "185k": 105,
        "190k": 82,
        "195k": 92,
        "200k": 82,
        "350k": "1,372"
      },
      childfm: {
        "5k": 8,
        "10k": 10,
        "15k": 13,
        "20k": 20,
        "25k": 25,
        "30k": 38,
        "35k": 50,
        "40k": 32,
        "45k": 30,
        "50k": 30,
        "55k": 22,
        "60k": 20,
        "65k": 17,
        "70k": 44,
        "75k": 23,
        "80k": 11,
        "85k": 8,
        "90k": 19,
        "95k": 9,
        "100k": 16,
        "105k": 18,
        "110k": 12,
        "115k": 21,
        "120k": 7,
        "125k": 6,
        "130k": 8,
        "135k": 13,
        "140k": 11,
        "145k": 6,
        "150k": 0,
        "155k": 7,
        "160k": 7,
        "165k": 0,
        "170k": 0,
        "175k": 5,
        "180k": 0,
        "185k": 3,
        "190k": 1,
        "195k": 1,
        "200k": 6,
        "350k": "33"
      },
      otherRelativefm: {
        "5k": 9,
        "10k": 21,
        "15k": 32,
        "20k": 31,
        "25k": 39,
        "30k": 40,
        "35k": 67,
        "40k": 40,
        "45k": 34,
        "50k": 52,
        "55k": 37,
        "60k": 32,
        "65k": 44,
        "70k": 32,
        "75k": 45,
        "80k": 48,
        "85k": 56,
        "90k": 30,
        "95k": 35,
        "100k": 38,
        "105k": 47,
        "110k": 23,
        "115k": 24,
        "120k": 28,
        "125k": 17,
        "130k": 19,
        "135k": 12,
        "140k": 10,
        "145k": 27,
        "150k": 8,
        "155k": 12,
        "160k": 11,
        "165k": 13,
        "170k": 27,
        "175k": 4,
        "180k": 5,
        "185k": 12,
        "190k": 14,
        "195k": 10,
        "200k": 8,
        "350k": "97"
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 18,
        "15k": 26,
        "20k": 23,
        "25k": 34,
        "30k": 28,
        "35k": 22,
        "40k": 30,
        "45k": 23,
        "50k": 44,
        "55k": 40,
        "60k": 32,
        "65k": 22,
        "70k": 27,
        "75k": 37,
        "80k": 20,
        "85k": 21,
        "90k": 30,
        "95k": 25,
        "100k": 11,
        "105k": 34,
        "110k": 15,
        "115k": 29,
        "120k": 21,
        "125k": 20,
        "130k": 22,
        "135k": 12,
        "140k": 14,
        "145k": 8,
        "150k": 19,
        "155k": 13,
        "160k": 17,
        "165k": 6,
        "170k": 6,
        "175k": 5,
        "180k": 1,
        "185k": 2,
        "190k": 5,
        "195k": 5,
        "200k": 5,
        "350k": "121"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 431,
        "10k": 263,
        "15k": 474,
        "20k": 454,
        "25k": 429,
        "30k": 379,
        "35k": 386,
        "40k": 446,
        "45k": 398,
        "50k": 438,
        "55k": 454,
        "60k": 348,
        "65k": 375,
        "70k": 340,
        "75k": 387,
        "80k": 408,
        "85k": 358,
        "90k": 258,
        "95k": 261,
        "100k": 290,
        "105k": 344,
        "110k": 249,
        "115k": 222,
        "120k": 223,
        "125k": 185,
        "130k": 211,
        "135k": 184,
        "140k": 176,
        "145k": 129,
        "150k": 163,
        "155k": 156,
        "160k": 144,
        "165k": 141,
        "170k": 125,
        "175k": 132,
        "180k": 135,
        "185k": 69,
        "190k": 122,
        "195k": 91,
        "200k": 76,
        "350k": "1,602"
      },
      spousem: {
        "5k": 36,
        "10k": 29,
        "15k": 58,
        "20k": 68,
        "25k": 104,
        "30k": 124,
        "35k": 125,
        "40k": 117,
        "45k": 140,
        "50k": 146,
        "55k": 134,
        "60k": 148,
        "65k": 171,
        "70k": 173,
        "75k": 165,
        "80k": 176,
        "85k": 182,
        "90k": 126,
        "95k": 131,
        "100k": 144,
        "105k": 153,
        "110k": 118,
        "115k": 156,
        "120k": 137,
        "125k": 118,
        "130k": 104,
        "135k": 96,
        "140k": 106,
        "145k": 74,
        "150k": 83,
        "155k": 120,
        "160k": 64,
        "165k": 82,
        "170k": 58,
        "175k": 91,
        "180k": 51,
        "185k": 55,
        "190k": 89,
        "195k": 33,
        "200k": 22,
        "350k": "921"
      },
      childm: {
        "5k": 13,
        "10k": 2,
        "15k": 20,
        "20k": 26,
        "25k": 29,
        "30k": 32,
        "35k": 27,
        "40k": 34,
        "45k": 10,
        "50k": 29,
        "55k": 24,
        "60k": 17,
        "65k": 5,
        "70k": 17,
        "75k": 11,
        "80k": 17,
        "85k": 4,
        "90k": 9,
        "95k": 13,
        "100k": 6,
        "105k": 14,
        "110k": 4,
        "115k": 0,
        "120k": 5,
        "125k": 8,
        "130k": 7,
        "135k": 1,
        "140k": 4,
        "145k": 2,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "12"
      },
      otherRelativem: {
        "5k": 19,
        "10k": 10,
        "15k": 22,
        "20k": 14,
        "25k": 29,
        "30k": 24,
        "35k": 39,
        "40k": 40,
        "45k": 42,
        "50k": 46,
        "55k": 30,
        "60k": 44,
        "65k": 40,
        "70k": 34,
        "75k": 36,
        "80k": 29,
        "85k": 44,
        "90k": 30,
        "95k": 31,
        "100k": 26,
        "105k": 34,
        "110k": 23,
        "115k": 30,
        "120k": 28,
        "125k": 28,
        "130k": 17,
        "135k": 18,
        "140k": 17,
        "145k": 14,
        "150k": 7,
        "155k": 9,
        "160k": 6,
        "165k": 5,
        "170k": 11,
        "175k": 8,
        "180k": 11,
        "185k": 5,
        "190k": 8,
        "195k": 13,
        "200k": 5,
        "350k": "95"
      },
      nonRelativem: {
        "5k": 40,
        "10k": 15,
        "15k": 18,
        "20k": 22,
        "25k": 24,
        "30k": 35,
        "35k": 30,
        "40k": 32,
        "45k": 34,
        "50k": 33,
        "55k": 24,
        "60k": 8,
        "65k": 37,
        "70k": 34,
        "75k": 17,
        "80k": 29,
        "85k": 47,
        "90k": 23,
        "95k": 10,
        "100k": 13,
        "105k": 17,
        "110k": 13,
        "115k": 8,
        "120k": 21,
        "125k": 4,
        "130k": 14,
        "135k": 15,
        "140k": 11,
        "145k": 13,
        "150k": 9,
        "155k": 7,
        "160k": 2,
        "165k": 14,
        "170k": 11,
        "175k": 4,
        "180k": 1,
        "185k": 0,
        "190k": 9,
        "195k": 2,
        "200k": 1,
        "350k": "85"
      },
      houseLoitererfm: {
        "5k": 399,
        "10k": 484,
        "15k": 628,
        "20k": 571,
        "25k": 575,
        "30k": 430,
        "35k": 459,
        "40k": 494,
        "45k": 437,
        "50k": 467,
        "55k": 453,
        "60k": 373,
        "65k": 421,
        "70k": 361,
        "75k": 336,
        "80k": 377,
        "85k": 335,
        "90k": 254,
        "95k": 241,
        "100k": 199,
        "105k": 205,
        "110k": 141,
        "115k": 199,
        "120k": 158,
        "125k": 152,
        "130k": 161,
        "135k": 153,
        "140k": 117,
        "145k": 122,
        "150k": 136,
        "155k": 137,
        "160k": 75,
        "165k": 76,
        "170k": 73,
        "175k": 87,
        "180k": 58,
        "185k": 69,
        "190k": 95,
        "195k": 49,
        "200k": 19,
        "350k": "1,014"
      },
      spousefm: {
        "5k": 88,
        "10k": 43,
        "15k": 61,
        "20k": 137,
        "25k": 173,
        "30k": 166,
        "35k": 215,
        "40k": 230,
        "45k": 222,
        "50k": 211,
        "55k": 242,
        "60k": 215,
        "65k": 264,
        "70k": 204,
        "75k": 274,
        "80k": 326,
        "85k": 238,
        "90k": 232,
        "95k": 210,
        "100k": 209,
        "105k": 225,
        "110k": 193,
        "115k": 208,
        "120k": 174,
        "125k": 148,
        "130k": 145,
        "135k": 144,
        "140k": 147,
        "145k": 123,
        "150k": 116,
        "155k": 117,
        "160k": 117,
        "165k": 103,
        "170k": 97,
        "175k": 86,
        "180k": 93,
        "185k": 57,
        "190k": 84,
        "195k": 73,
        "200k": 50,
        "350k": "1,313"
      },
      childfm: {
        "5k": 4,
        "10k": 8,
        "15k": 8,
        "20k": 13,
        "25k": 14,
        "30k": 26,
        "35k": 22,
        "40k": 16,
        "45k": 27,
        "50k": 14,
        "55k": 20,
        "60k": 19,
        "65k": 26,
        "70k": 4,
        "75k": 22,
        "80k": 12,
        "85k": 4,
        "90k": 10,
        "95k": 9,
        "100k": 10,
        "105k": 2,
        "110k": 8,
        "115k": 4,
        "120k": 6,
        "125k": 1,
        "130k": 4,
        "135k": 4,
        "140k": 5,
        "145k": 3,
        "150k": 2,
        "155k": 2,
        "160k": 0,
        "165k": 2,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 2,
        "190k": 0,
        "195k": 2,
        "200k": 0,
        "350k": "12"
      },
      otherRelativefm: {
        "5k": 23,
        "10k": 16,
        "15k": 13,
        "20k": 39,
        "25k": 55,
        "30k": 52,
        "35k": 52,
        "40k": 64,
        "45k": 51,
        "50k": 51,
        "55k": 53,
        "60k": 54,
        "65k": 73,
        "70k": 53,
        "75k": 51,
        "80k": 49,
        "85k": 50,
        "90k": 38,
        "95k": 26,
        "100k": 45,
        "105k": 31,
        "110k": 20,
        "115k": 53,
        "120k": 27,
        "125k": 39,
        "130k": 34,
        "135k": 20,
        "140k": 27,
        "145k": 22,
        "150k": 9,
        "155k": 18,
        "160k": 5,
        "165k": 12,
        "170k": 6,
        "175k": 14,
        "180k": 15,
        "185k": 8,
        "190k": 14,
        "195k": 15,
        "200k": 5,
        "350k": "103"
      },
      nonRelativefm: {
        "5k": 25,
        "10k": 4,
        "15k": 28,
        "20k": 30,
        "25k": 37,
        "30k": 39,
        "35k": 35,
        "40k": 37,
        "45k": 17,
        "50k": 18,
        "55k": 15,
        "60k": 17,
        "65k": 29,
        "70k": 18,
        "75k": 31,
        "80k": 15,
        "85k": 28,
        "90k": 14,
        "95k": 14,
        "100k": 10,
        "105k": 15,
        "110k": 27,
        "115k": 13,
        "120k": 8,
        "125k": 21,
        "130k": 17,
        "135k": 1,
        "140k": 8,
        "145k": 8,
        "150k": 3,
        "155k": 3,
        "160k": 2,
        "165k": 5,
        "170k": 1,
        "175k": 8,
        "180k": 3,
        "185k": 1,
        "190k": 8,
        "195k": 3,
        "200k": 12,
        "350k": "63"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 266,
        "10k": 174,
        "15k": 453,
        "20k": 508,
        "25k": 444,
        "30k": 504,
        "35k": 535,
        "40k": 399,
        "45k": 411,
        "50k": 402,
        "55k": 383,
        "60k": 314,
        "65k": 344,
        "70k": 249,
        "75k": 269,
        "80k": 256,
        "85k": 262,
        "90k": 262,
        "95k": 217,
        "100k": 220,
        "105k": 165,
        "110k": 210,
        "115k": 182,
        "120k": 160,
        "125k": 140,
        "130k": 111,
        "135k": 81,
        "140k": 123,
        "145k": 90,
        "150k": 66,
        "155k": 107,
        "160k": 92,
        "165k": 80,
        "170k": 56,
        "175k": 50,
        "180k": 50,
        "185k": 58,
        "190k": 49,
        "195k": 57,
        "200k": 19,
        "350k": 841
      },
      spousem: {
        "5k": 72,
        "10k": 34,
        "15k": 55,
        "20k": 79,
        "25k": 118,
        "30k": 175,
        "35k": 189,
        "40k": 174,
        "45k": 192,
        "50k": 169,
        "55k": 165,
        "60k": 126,
        "65k": 149,
        "70k": 125,
        "75k": 101,
        "80k": 129,
        "85k": 147,
        "90k": 101,
        "95k": 104,
        "100k": 93,
        "105k": 100,
        "110k": 58,
        "115k": 76,
        "120k": 48,
        "125k": 57,
        "130k": 58,
        "135k": 51,
        "140k": 45,
        "145k": 38,
        "150k": 24,
        "155k": 47,
        "160k": 27,
        "165k": 44,
        "170k": 24,
        "175k": 25,
        "180k": 28,
        "185k": 23,
        "190k": 33,
        "195k": 11,
        "200k": 10,
        "350k": 436
      },
      childm: {
        "5k": 1,
        "10k": 0,
        "15k": 3,
        "20k": 13,
        "25k": 6,
        "30k": 6,
        "35k": 0,
        "40k": 0,
        "45k": 2,
        "50k": 9,
        "55k": 1,
        "60k": 0,
        "65k": 5,
        "70k": 0,
        "75k": 3,
        "80k": 1,
        "85k": 0,
        "90k": 1,
        "95k": 0,
        "100k": 1,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 2,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 1,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativem: {
        "5k": 8,
        "10k": 1,
        "15k": 7,
        "20k": 23,
        "25k": 20,
        "30k": 24,
        "35k": 22,
        "40k": 16,
        "45k": 8,
        "50k": 20,
        "55k": 14,
        "60k": 10,
        "65k": 14,
        "70k": 21,
        "75k": 16,
        "80k": 9,
        "85k": 18,
        "90k": 19,
        "95k": 18,
        "100k": 21,
        "105k": 16,
        "110k": 14,
        "115k": 15,
        "120k": 10,
        "125k": 9,
        "130k": 3,
        "135k": 7,
        "140k": 10,
        "145k": 11,
        "150k": 3,
        "155k": 18,
        "160k": 11,
        "165k": 5,
        "170k": 2,
        "175k": 7,
        "180k": 4,
        "185k": 4,
        "190k": 4,
        "195k": 5,
        "200k": 5,
        "350k": 67
      },
      nonRelativem: {
        "5k": 10,
        "10k": 3,
        "15k": 3,
        "20k": 19,
        "25k": 9,
        "30k": 13,
        "35k": 22,
        "40k": 9,
        "45k": 21,
        "50k": 7,
        "55k": 18,
        "60k": 9,
        "65k": 6,
        "70k": 3,
        "75k": 9,
        "80k": 15,
        "85k": 14,
        "90k": 5,
        "95k": 0,
        "100k": 5,
        "105k": 2,
        "110k": 12,
        "115k": 2,
        "120k": 0,
        "125k": 2,
        "130k": 4,
        "135k": 4,
        "140k": 0,
        "145k": 8,
        "150k": 2,
        "155k": 8,
        "160k": 2,
        "165k": 3,
        "170k": 8,
        "175k": 0,
        "180k": 0,
        "185k": 1,
        "190k": 4,
        "195k": 2,
        "200k": 6,
        "350k": 35
      },
      houseLoitererfm: {
        "5k": 350,
        "10k": 326,
        "15k": 734,
        "20k": 704,
        "25k": 642,
        "30k": 628,
        "35k": 566,
        "40k": 480,
        "45k": 498,
        "50k": 361,
        "55k": 369,
        "60k": 286,
        "65k": 288,
        "70k": 276,
        "75k": 229,
        "80k": 215,
        "85k": 213,
        "90k": 172,
        "95k": 150,
        "100k": 155,
        "105k": 119,
        "110k": 115,
        "115k": 103,
        "120k": 108,
        "125k": 84,
        "130k": 77,
        "135k": 73,
        "140k": 75,
        "145k": 51,
        "150k": 43,
        "155k": 63,
        "160k": 30,
        "165k": 48,
        "170k": 28,
        "175k": 35,
        "180k": 33,
        "185k": 37,
        "190k": 34,
        "195k": 35,
        "200k": 22,
        "350k": 439
      },
      spousefm: {
        "5k": 98,
        "10k": 38,
        "15k": 85,
        "20k": 99,
        "25k": 177,
        "30k": 268,
        "35k": 294,
        "40k": 259,
        "45k": 221,
        "50k": 227,
        "55k": 223,
        "60k": 186,
        "65k": 185,
        "70k": 168,
        "75k": 156,
        "80k": 173,
        "85k": 197,
        "90k": 172,
        "95k": 157,
        "100k": 175,
        "105k": 132,
        "110k": 118,
        "115k": 113,
        "120k": 73,
        "125k": 80,
        "130k": 64,
        "135k": 52,
        "140k": 65,
        "145k": 59,
        "150k": 31,
        "155k": 76,
        "160k": 73,
        "165k": 47,
        "170k": 37,
        "175k": 43,
        "180k": 35,
        "185k": 44,
        "190k": 33,
        "195k": 29,
        "200k": 17,
        "350k": 496
      },
      childfm: {
        "5k": 0,
        "10k": 2,
        "15k": 3,
        "20k": 8,
        "25k": 7,
        "30k": 7,
        "35k": 4,
        "40k": 6,
        "45k": 4,
        "50k": 5,
        "55k": 3,
        "60k": 6,
        "65k": 0,
        "70k": 2,
        "75k": 0,
        "80k": 3,
        "85k": 2,
        "90k": 2,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 3,
        "140k": 1,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 3
      },
      otherRelativefm: {
        "5k": 20,
        "10k": 8,
        "15k": 20,
        "20k": 23,
        "25k": 37,
        "30k": 45,
        "35k": 44,
        "40k": 37,
        "45k": 42,
        "50k": 49,
        "55k": 36,
        "60k": 30,
        "65k": 46,
        "70k": 44,
        "75k": 39,
        "80k": 25,
        "85k": 45,
        "90k": 35,
        "95k": 35,
        "100k": 36,
        "105k": 35,
        "110k": 23,
        "115k": 21,
        "120k": 8,
        "125k": 24,
        "130k": 16,
        "135k": 9,
        "140k": 15,
        "145k": 10,
        "150k": 8,
        "155k": 19,
        "160k": 12,
        "165k": 20,
        "170k": 6,
        "175k": 9,
        "180k": 8,
        "185k": 9,
        "190k": 9,
        "195k": 10,
        "200k": 5,
        "350k": 116
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 2,
        "15k": 7,
        "20k": 8,
        "25k": 15,
        "30k": 9,
        "35k": 11,
        "40k": 10,
        "45k": 9,
        "50k": 10,
        "55k": 21,
        "60k": 5,
        "65k": 12,
        "70k": 13,
        "75k": 8,
        "80k": 3,
        "85k": 10,
        "90k": 0,
        "95k": 5,
        "100k": 0,
        "105k": 0,
        "110k": 9,
        "115k": 7,
        "120k": 5,
        "125k": 10,
        "130k": 5,
        "135k": 0,
        "140k": 4,
        "145k": 4,
        "150k": 2,
        "155k": 4,
        "160k": 4,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 7,
        "185k": 0,
        "190k": 0,
        "195k": 4,
        "200k": 0,
        "350k": 27
      }
    }
  },
  "2018": {
    "17": {
      houseLoitererm: {
        "5k": 2,
        "10k": 4,
        "15k": 3,
        "20k": 12,
        "25k": 7,
        "30k": 8,
        "35k": 8,
        "40k": 4,
        "45k": 4,
        "50k": 0,
        "55k": 5,
        "60k": 0,
        "65k": 1,
        "70k": 0,
        "75k": 7,
        "80k": 2,
        "85k": 4,
        "90k": 0,
        "95k": 4,
        "100k": 1,
        "105k": 0,
        "110k": 2,
        "115k": 0,
        "120k": 0,
        "125k": 2,
        "130k": 1,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 4,
        "350k": "0"
      },
      spousem: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "0"
      },
      childm: {
        "5k": 192,
        "10k": 170,
        "15k": 268,
        "20k": 286,
        "25k": 374,
        "30k": 395,
        "35k": 407,
        "40k": 337,
        "45k": 341,
        "50k": 411,
        "55k": 478,
        "60k": 338,
        "65k": 410,
        "70k": 319,
        "75k": 360,
        "80k": 386,
        "85k": 327,
        "90k": 296,
        "95k": 263,
        "100k": 260,
        "105k": 312,
        "110k": 284,
        "115k": 281,
        "120k": 245,
        "125k": 248,
        "130k": 179,
        "135k": 190,
        "140k": 160,
        "145k": 210,
        "150k": 172,
        "155k": 187,
        "160k": 122,
        "165k": 158,
        "170k": 95,
        "175k": 134,
        "180k": 98,
        "185k": 92,
        "190k": 83,
        "195k": 79,
        "200k": 61,
        "350k": "1,596"
      },
      otherRelativem: {
        "5k": 21,
        "10k": 33,
        "15k": 34,
        "20k": 55,
        "25k": 46,
        "30k": 39,
        "35k": 42,
        "40k": 60,
        "45k": 62,
        "50k": 46,
        "55k": 42,
        "60k": 43,
        "65k": 39,
        "70k": 64,
        "75k": 29,
        "80k": 28,
        "85k": 30,
        "90k": 30,
        "95k": 40,
        "100k": 14,
        "105k": 36,
        "110k": 13,
        "115k": 14,
        "120k": 24,
        "125k": 20,
        "130k": 16,
        "135k": 15,
        "140k": 18,
        "145k": 19,
        "150k": 6,
        "155k": 9,
        "160k": 7,
        "165k": 19,
        "170k": 9,
        "175k": 8,
        "180k": 2,
        "185k": 10,
        "190k": 5,
        "195k": 14,
        "200k": 1,
        "350k": "65"
      },
      nonRelativem: {
        "5k": 5,
        "10k": 5,
        "15k": 7,
        "20k": 10,
        "25k": 2,
        "30k": 2,
        "35k": 11,
        "40k": 4,
        "45k": 3,
        "50k": 12,
        "55k": 7,
        "60k": 10,
        "65k": 8,
        "70k": 9,
        "75k": 7,
        "80k": 11,
        "85k": 15,
        "90k": 5,
        "95k": 5,
        "100k": 9,
        "105k": 2,
        "110k": 16,
        "115k": 9,
        "120k": 2,
        "125k": 9,
        "130k": 4,
        "135k": 4,
        "140k": 5,
        "145k": 2,
        "150k": 4,
        "155k": 0,
        "160k": 2,
        "165k": 9,
        "170k": 3,
        "175k": 7,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 3,
        "200k": 0,
        "350k": "18"
      },
      houseLoitererfm: {
        "5k": 3,
        "10k": 0,
        "15k": 0,
        "20k": 15,
        "25k": 4,
        "30k": 7,
        "35k": 0,
        "40k": 13,
        "45k": 4,
        "50k": 3,
        "55k": 8,
        "60k": 3,
        "65k": 2,
        "70k": 0,
        "75k": 7,
        "80k": 0,
        "85k": 4,
        "90k": 1,
        "95k": 2,
        "100k": 1,
        "105k": 2,
        "110k": 2,
        "115k": 2,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 2,
        "145k": 1,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "1"
      },
      spousefm: {
        "5k": 0,
        "10k": 0,
        "15k": 0,
        "20k": 0,
        "25k": 0,
        "30k": 0,
        "35k": 0,
        "40k": 0,
        "45k": 0,
        "50k": 0,
        "55k": 0,
        "60k": 0,
        "65k": 0,
        "70k": 0,
        "75k": 0,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 4,
        "350k": "0"
      },
      childfm: {
        "5k": 210,
        "10k": 195,
        "15k": 227,
        "20k": 289,
        "25k": 318,
        "30k": 372,
        "35k": 398,
        "40k": 386,
        "45k": 331,
        "50k": 363,
        "55k": 413,
        "60k": 383,
        "65k": 388,
        "70k": 319,
        "75k": 282,
        "80k": 339,
        "85k": 325,
        "90k": 301,
        "95k": 289,
        "100k": 271,
        "105k": 334,
        "110k": 276,
        "115k": 234,
        "120k": 197,
        "125k": 196,
        "130k": 178,
        "135k": 163,
        "140k": 140,
        "145k": 159,
        "150k": 205,
        "155k": 179,
        "160k": 125,
        "165k": 144,
        "170k": 99,
        "175k": 97,
        "180k": 129,
        "185k": 90,
        "190k": 94,
        "195k": 83,
        "200k": 78,
        "350k": "1,496"
      },
      otherRelativefm: {
        "5k": 15,
        "10k": 25,
        "15k": 47,
        "20k": 53,
        "25k": 44,
        "30k": 41,
        "35k": 33,
        "40k": 50,
        "45k": 60,
        "50k": 42,
        "55k": 32,
        "60k": 64,
        "65k": 37,
        "70k": 26,
        "75k": 43,
        "80k": 30,
        "85k": 49,
        "90k": 16,
        "95k": 26,
        "100k": 29,
        "105k": 17,
        "110k": 31,
        "115k": 16,
        "120k": 25,
        "125k": 11,
        "130k": 7,
        "135k": 13,
        "140k": 14,
        "145k": 15,
        "150k": 15,
        "155k": 17,
        "160k": 9,
        "165k": 15,
        "170k": 5,
        "175k": 12,
        "180k": 4,
        "185k": 9,
        "190k": 3,
        "195k": 20,
        "200k": 9,
        "350k": "46"
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 7,
        "15k": 5,
        "20k": 2,
        "25k": 4,
        "30k": 2,
        "35k": 1,
        "40k": 13,
        "45k": 0,
        "50k": 9,
        "55k": 4,
        "60k": 10,
        "65k": 12,
        "70k": 14,
        "75k": 11,
        "80k": 2,
        "85k": 13,
        "90k": 2,
        "95k": 9,
        "100k": 6,
        "105k": 10,
        "110k": 3,
        "115k": 3,
        "120k": 2,
        "125k": 7,
        "130k": 3,
        "135k": 4,
        "140k": 7,
        "145k": 2,
        "150k": 0,
        "155k": 2,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 3,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": "22"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": 77,
        "10k": 22,
        "15k": 37,
        "20k": 50,
        "25k": 64,
        "30k": 55,
        "35k": 80,
        "40k": 58,
        "45k": 64,
        "50k": 45,
        "55k": 54,
        "60k": 33,
        "65k": 41,
        "70k": 35,
        "75k": 28,
        "80k": 43,
        "85k": 17,
        "90k": 19,
        "95k": 20,
        "100k": 12,
        "105k": 3,
        "110k": 11,
        "115k": 13,
        "120k": 17,
        "125k": 13,
        "130k": 17,
        "135k": 13,
        "140k": 2,
        "145k": 0,
        "150k": 3,
        "155k": 1,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 5,
        "180k": 4,
        "185k": 0,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 27
      },
      spousem: {
        "5k": 2,
        "10k": 2,
        "15k": 0,
        "20k": 2,
        "25k": 1,
        "30k": 3,
        "35k": 8,
        "40k": 2,
        "45k": 1,
        "50k": 2,
        "55k": 5,
        "60k": 10,
        "65k": 0,
        "70k": 0,
        "75k": 5,
        "80k": 2,
        "85k": 0,
        "90k": 0,
        "95k": 4,
        "100k": 0,
        "105k": 0,
        "110k": 1,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childm: {
        "5k": 61,
        "10k": 40,
        "15k": 87,
        "20k": 65,
        "25k": 120,
        "30k": 132,
        "35k": 170,
        "40k": 176,
        "45k": 163,
        "50k": 103,
        "55k": 202,
        "60k": 188,
        "65k": 160,
        "70k": 199,
        "75k": 130,
        "80k": 184,
        "85k": 135,
        "90k": 137,
        "95k": 172,
        "100k": 154,
        "105k": 171,
        "110k": 143,
        "115k": 116,
        "120k": 112,
        "125k": 143,
        "130k": 132,
        "135k": 105,
        "140k": 99,
        "145k": 74,
        "150k": 126,
        "155k": 99,
        "160k": 74,
        "165k": 74,
        "170k": 64,
        "175k": 81,
        "180k": 72,
        "185k": 75,
        "190k": 64,
        "195k": 58,
        "200k": 42,
        "350k": 942
      },
      otherRelativem: {
        "5k": 24,
        "10k": 20,
        "15k": 21,
        "20k": 31,
        "25k": 28,
        "30k": 49,
        "35k": 36,
        "40k": 19,
        "45k": 7,
        "50k": 30,
        "55k": 23,
        "60k": 45,
        "65k": 43,
        "70k": 41,
        "75k": 17,
        "80k": 34,
        "85k": 40,
        "90k": 20,
        "95k": 26,
        "100k": 11,
        "105k": 13,
        "110k": 21,
        "115k": 12,
        "120k": 19,
        "125k": 13,
        "130k": 18,
        "135k": 15,
        "140k": 8,
        "145k": 9,
        "150k": 11,
        "155k": 19,
        "160k": 13,
        "165k": 10,
        "170k": 0,
        "175k": 5,
        "180k": 3,
        "185k": 7,
        "190k": 6,
        "195k": 6,
        "200k": 0,
        "350k": 52
      },
      nonRelativem: {
        "5k": 35,
        "10k": 22,
        "15k": 15,
        "20k": 7,
        "25k": 17,
        "30k": 23,
        "35k": 40,
        "40k": 13,
        "45k": 28,
        "50k": 29,
        "55k": 21,
        "60k": 20,
        "65k": 30,
        "70k": 28,
        "75k": 37,
        "80k": 25,
        "85k": 10,
        "90k": 10,
        "95k": 23,
        "100k": 14,
        "105k": 11,
        "110k": 12,
        "115k": 10,
        "120k": 10,
        "125k": 18,
        "130k": 16,
        "135k": 22,
        "140k": 3,
        "145k": 6,
        "150k": 2,
        "155k": 2,
        "160k": 6,
        "165k": 7,
        "170k": 2,
        "175k": 11,
        "180k": 2,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 30
      },
      houseLoitererfm: {
        "5k": 101,
        "10k": 56,
        "15k": 77,
        "20k": 66,
        "25k": 97,
        "30k": 53,
        "35k": 85,
        "40k": 71,
        "45k": 46,
        "50k": 46,
        "55k": 61,
        "60k": 35,
        "65k": 43,
        "70k": 25,
        "75k": 36,
        "80k": 22,
        "85k": 27,
        "90k": 9,
        "95k": 19,
        "100k": 5,
        "105k": 7,
        "110k": 9,
        "115k": 15,
        "120k": 11,
        "125k": 14,
        "130k": 18,
        "135k": 0,
        "140k": 6,
        "145k": 6,
        "150k": 4,
        "155k": 2,
        "160k": 7,
        "165k": 5,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 2,
        "195k": 0,
        "200k": 1,
        "350k": 11
      },
      spousefm: {
        "5k": 7,
        "10k": 3,
        "15k": 0,
        "20k": 7,
        "25k": 6,
        "30k": 4,
        "35k": 11,
        "40k": 8,
        "45k": 13,
        "50k": 8,
        "55k": 8,
        "60k": 18,
        "65k": 3,
        "70k": 6,
        "75k": 3,
        "80k": 18,
        "85k": 1,
        "90k": 4,
        "95k": 0,
        "100k": 2,
        "105k": 0,
        "110k": 3,
        "115k": 0,
        "120k": 0,
        "125k": 3,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 2,
        "155k": 0,
        "160k": 0,
        "165k": 4,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      childfm: {
        "5k": 85,
        "10k": 45,
        "15k": 97,
        "20k": 71,
        "25k": 135,
        "30k": 145,
        "35k": 148,
        "40k": 149,
        "45k": 145,
        "50k": 143,
        "55k": 170,
        "60k": 181,
        "65k": 135,
        "70k": 139,
        "75k": 152,
        "80k": 201,
        "85k": 127,
        "90k": 125,
        "95k": 171,
        "100k": 115,
        "105k": 184,
        "110k": 151,
        "115k": 109,
        "120k": 92,
        "125k": 106,
        "130k": 86,
        "135k": 155,
        "140k": 85,
        "145k": 88,
        "150k": 97,
        "155k": 117,
        "160k": 78,
        "165k": 83,
        "170k": 66,
        "175k": 84,
        "180k": 49,
        "185k": 62,
        "190k": 44,
        "195k": 59,
        "200k": 32,
        "350k": 828
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 16,
        "15k": 33,
        "20k": 13,
        "25k": 31,
        "30k": 33,
        "35k": 20,
        "40k": 26,
        "45k": 50,
        "50k": 29,
        "55k": 47,
        "60k": 29,
        "65k": 28,
        "70k": 19,
        "75k": 27,
        "80k": 25,
        "85k": 22,
        "90k": 30,
        "95k": 14,
        "100k": 20,
        "105k": 8,
        "110k": 14,
        "115k": 10,
        "120k": 23,
        "125k": 8,
        "130k": 10,
        "135k": 9,
        "140k": 6,
        "145k": 11,
        "150k": 12,
        "155k": 17,
        "160k": 7,
        "165k": 4,
        "170k": 4,
        "175k": 2,
        "180k": 11,
        "185k": 21,
        "190k": 0,
        "195k": 6,
        "200k": 3,
        "350k": 25
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 13,
        "15k": 16,
        "20k": 37,
        "25k": 20,
        "30k": 32,
        "35k": 68,
        "40k": 30,
        "45k": 48,
        "50k": 37,
        "55k": 22,
        "60k": 30,
        "65k": 41,
        "70k": 24,
        "75k": 24,
        "80k": 21,
        "85k": 21,
        "90k": 9,
        "95k": 10,
        "100k": 5,
        "105k": 10,
        "110k": 19,
        "115k": 10,
        "120k": 14,
        "125k": 4,
        "130k": 16,
        "135k": 3,
        "140k": 7,
        "145k": 6,
        "150k": 10,
        "155k": 5,
        "160k": 6,
        "165k": 25,
        "170k": 5,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 44
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 105,
        "10k": 61,
        "15k": 60,
        "20k": 91,
        "25k": 89,
        "30k": 100,
        "35k": 143,
        "40k": 91,
        "45k": 89,
        "50k": 136,
        "55k": 82,
        "60k": 99,
        "65k": 97,
        "70k": 61,
        "75k": 62,
        "80k": 78,
        "85k": 49,
        "90k": 49,
        "95k": 33,
        "100k": 23,
        "105k": 36,
        "110k": 12,
        "115k": 31,
        "120k": 23,
        "125k": 26,
        "130k": 9,
        "135k": 9,
        "140k": 14,
        "145k": 4,
        "150k": 13,
        "155k": 6,
        "160k": 24,
        "165k": 9,
        "170k": 9,
        "175k": 5,
        "180k": 9,
        "185k": 2,
        "190k": 11,
        "195k": 6,
        "200k": 1,
        "350k": 79
      },
      spousem: {
        "5k": 0,
        "10k": 2,
        "15k": 1,
        "20k": 7,
        "25k": 5,
        "30k": 17,
        "35k": 17,
        "40k": 17,
        "45k": 16,
        "50k": 4,
        "55k": 15,
        "60k": 11,
        "65k": 15,
        "70k": 13,
        "75k": 9,
        "80k": 14,
        "85k": 8,
        "90k": 1,
        "95k": 1,
        "100k": 1,
        "105k": 0,
        "110k": 5,
        "115k": 5,
        "120k": 6,
        "125k": 2,
        "130k": 8,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 2,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      childm: {
        "5k": 12,
        "10k": 14,
        "15k": 32,
        "20k": 28,
        "25k": 57,
        "30k": 45,
        "35k": 59,
        "40k": 71,
        "45k": 87,
        "50k": 48,
        "55k": 44,
        "60k": 78,
        "65k": 54,
        "70k": 101,
        "75k": 93,
        "80k": 80,
        "85k": 70,
        "90k": 68,
        "95k": 113,
        "100k": 48,
        "105k": 91,
        "110k": 67,
        "115k": 63,
        "120k": 67,
        "125k": 51,
        "130k": 53,
        "135k": 33,
        "140k": 33,
        "145k": 56,
        "150k": 48,
        "155k": 51,
        "160k": 56,
        "165k": 35,
        "170k": 59,
        "175k": 31,
        "180k": 41,
        "185k": 7,
        "190k": 15,
        "195k": 32,
        "200k": 24,
        "350k": 466
      },
      otherRelativem: {
        "5k": 5,
        "10k": 13,
        "15k": 8,
        "20k": 20,
        "25k": 17,
        "30k": 7,
        "35k": 23,
        "40k": 20,
        "45k": 44,
        "50k": 25,
        "55k": 22,
        "60k": 31,
        "65k": 28,
        "70k": 48,
        "75k": 23,
        "80k": 21,
        "85k": 15,
        "90k": 11,
        "95k": 15,
        "100k": 16,
        "105k": 17,
        "110k": 14,
        "115k": 15,
        "120k": 0,
        "125k": 15,
        "130k": 20,
        "135k": 12,
        "140k": 15,
        "145k": 4,
        "150k": 8,
        "155k": 16,
        "160k": 11,
        "165k": 8,
        "170k": 6,
        "175k": 10,
        "180k": 4,
        "185k": 0,
        "190k": 5,
        "195k": 0,
        "200k": 0,
        "350k": 35
      },
      nonRelativem: {
        "5k": 54,
        "10k": 21,
        "15k": 12,
        "20k": 12,
        "25k": 31,
        "30k": 46,
        "35k": 31,
        "40k": 43,
        "45k": 46,
        "50k": 37,
        "55k": 40,
        "60k": 35,
        "65k": 63,
        "70k": 52,
        "75k": 59,
        "80k": 43,
        "85k": 36,
        "90k": 53,
        "95k": 23,
        "100k": 43,
        "105k": 26,
        "110k": 36,
        "115k": 20,
        "120k": 14,
        "125k": 15,
        "130k": 17,
        "135k": 11,
        "140k": 1,
        "145k": 3,
        "150k": 11,
        "155k": 3,
        "160k": 6,
        "165k": 13,
        "170k": 4,
        "175k": 16,
        "180k": 7,
        "185k": 4,
        "190k": 10,
        "195k": 0,
        "200k": 5,
        "350k": 123
      },
      houseLoitererfm: {
        "5k": 150,
        "10k": 61,
        "15k": 59,
        "20k": 129,
        "25k": 158,
        "30k": 150,
        "35k": 135,
        "40k": 103,
        "45k": 122,
        "50k": 93,
        "55k": 92,
        "60k": 58,
        "65k": 75,
        "70k": 76,
        "75k": 74,
        "80k": 73,
        "85k": 39,
        "90k": 36,
        "95k": 36,
        "100k": 15,
        "105k": 27,
        "110k": 31,
        "115k": 22,
        "120k": 21,
        "125k": 8,
        "130k": 24,
        "135k": 9,
        "140k": 5,
        "145k": 9,
        "150k": 22,
        "155k": 2,
        "160k": 1,
        "165k": 12,
        "170k": 2,
        "175k": 6,
        "180k": 0,
        "185k": 3,
        "190k": 7,
        "195k": 0,
        "200k": 0,
        "350k": 53
      },
      spousefm: {
        "5k": 9,
        "10k": 11,
        "15k": 7,
        "20k": 5,
        "25k": 29,
        "30k": 25,
        "35k": 20,
        "40k": 33,
        "45k": 25,
        "50k": 22,
        "55k": 24,
        "60k": 27,
        "65k": 25,
        "70k": 21,
        "75k": 57,
        "80k": 33,
        "85k": 34,
        "90k": 16,
        "95k": 13,
        "100k": 14,
        "105k": 30,
        "110k": 12,
        "115k": 15,
        "120k": 9,
        "125k": 4,
        "130k": 3,
        "135k": 4,
        "140k": 7,
        "145k": 2,
        "150k": 1,
        "155k": 2,
        "160k": 0,
        "165k": 3,
        "170k": 9,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 3,
        "200k": 0,
        "350k": 8
      },
      childfm: {
        "5k": 15,
        "10k": 21,
        "15k": 30,
        "20k": 21,
        "25k": 55,
        "30k": 42,
        "35k": 44,
        "40k": 52,
        "45k": 66,
        "50k": 59,
        "55k": 55,
        "60k": 92,
        "65k": 69,
        "70k": 80,
        "75k": 66,
        "80k": 57,
        "85k": 80,
        "90k": 52,
        "95k": 89,
        "100k": 49,
        "105k": 69,
        "110k": 59,
        "115k": 34,
        "120k": 36,
        "125k": 40,
        "130k": 47,
        "135k": 50,
        "140k": 58,
        "145k": 28,
        "150k": 55,
        "155k": 70,
        "160k": 29,
        "165k": 33,
        "170k": 57,
        "175k": 18,
        "180k": 19,
        "185k": 19,
        "190k": 38,
        "195k": 25,
        "200k": 26,
        "350k": 409
      },
      otherRelativefm: {
        "5k": 8,
        "10k": 5,
        "15k": 18,
        "20k": 7,
        "25k": 22,
        "30k": 14,
        "35k": 15,
        "40k": 17,
        "45k": 13,
        "50k": 14,
        "55k": 19,
        "60k": 23,
        "65k": 20,
        "70k": 21,
        "75k": 31,
        "80k": 9,
        "85k": 19,
        "90k": 23,
        "95k": 21,
        "100k": 11,
        "105k": 10,
        "110k": 11,
        "115k": 22,
        "120k": 13,
        "125k": 5,
        "130k": 8,
        "135k": 13,
        "140k": 3,
        "145k": 4,
        "150k": 5,
        "155k": 1,
        "160k": 2,
        "165k": 5,
        "170k": 4,
        "175k": 3,
        "180k": 6,
        "185k": 5,
        "190k": 4,
        "195k": 0,
        "200k": 4,
        "350k": 18
      },
      nonRelativefm: {
        "5k": 45,
        "10k": 22,
        "15k": 18,
        "20k": 24,
        "25k": 23,
        "30k": 40,
        "35k": 54,
        "40k": 15,
        "45k": 53,
        "50k": 45,
        "55k": 60,
        "60k": 40,
        "65k": 57,
        "70k": 70,
        "75k": 37,
        "80k": 60,
        "85k": 41,
        "90k": 50,
        "95k": 37,
        "100k": 6,
        "105k": 23,
        "110k": 30,
        "115k": 13,
        "120k": 19,
        "125k": 30,
        "130k": 3,
        "135k": 12,
        "140k": 10,
        "145k": 11,
        "150k": 12,
        "155k": 13,
        "160k": 3,
        "165k": 9,
        "170k": 4,
        "175k": 4,
        "180k": 8,
        "185k": 2,
        "190k": 9,
        "195k": 0,
        "200k": 0,
        "350k": 94
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 134,
        "10k": 69,
        "15k": 116,
        "20k": 101,
        "25k": 169,
        "30k": 181,
        "35k": 207,
        "40k": 203,
        "45k": 252,
        "50k": 231,
        "55k": 237,
        "60k": 268,
        "65k": 279,
        "70k": 207,
        "75k": 228,
        "80k": 214,
        "85k": 191,
        "90k": 158,
        "95k": 159,
        "100k": 138,
        "105k": 131,
        "110k": 99,
        "115k": 104,
        "120k": 101,
        "125k": 96,
        "130k": 77,
        "135k": 51,
        "140k": 69,
        "145k": 47,
        "150k": 42,
        "155k": 43,
        "160k": 26,
        "165k": 38,
        "170k": 39,
        "175k": 31,
        "180k": 17,
        "185k": 19,
        "190k": 22,
        "195k": 27,
        "200k": 16,
        "350k": 238
      },
      spousem: {
        "5k": 7,
        "10k": 13,
        "15k": 12,
        "20k": 22,
        "25k": 29,
        "30k": 37,
        "35k": 45,
        "40k": 64,
        "45k": 49,
        "50k": 55,
        "55k": 69,
        "60k": 39,
        "65k": 51,
        "70k": 57,
        "75k": 37,
        "80k": 43,
        "85k": 52,
        "90k": 40,
        "95k": 35,
        "100k": 39,
        "105k": 31,
        "110k": 40,
        "115k": 23,
        "120k": 7,
        "125k": 17,
        "130k": 16,
        "135k": 17,
        "140k": 15,
        "145k": 5,
        "150k": 8,
        "155k": 6,
        "160k": 6,
        "165k": 9,
        "170k": 3,
        "175k": 0,
        "180k": 3,
        "185k": 2,
        "190k": 4,
        "195k": 0,
        "200k": 2,
        "350k": 57
      },
      childm: {
        "5k": 47,
        "10k": 19,
        "15k": 48,
        "20k": 45,
        "25k": 35,
        "30k": 36,
        "35k": 56,
        "40k": 82,
        "45k": 54,
        "50k": 78,
        "55k": 65,
        "60k": 96,
        "65k": 92,
        "70k": 99,
        "75k": 97,
        "80k": 111,
        "85k": 111,
        "90k": 102,
        "95k": 71,
        "100k": 77,
        "105k": 78,
        "110k": 85,
        "115k": 97,
        "120k": 78,
        "125k": 64,
        "130k": 64,
        "135k": 82,
        "140k": 83,
        "145k": 53,
        "150k": 67,
        "155k": 59,
        "160k": 51,
        "165k": 43,
        "170k": 62,
        "175k": 42,
        "180k": 40,
        "185k": 31,
        "190k": 29,
        "195k": 27,
        "200k": 34,
        "350k": 510
      },
      otherRelativem: {
        "5k": 11,
        "10k": 0,
        "15k": 9,
        "20k": 11,
        "25k": 15,
        "30k": 11,
        "35k": 32,
        "40k": 37,
        "45k": 28,
        "50k": 35,
        "55k": 35,
        "60k": 36,
        "65k": 32,
        "70k": 45,
        "75k": 40,
        "80k": 31,
        "85k": 30,
        "90k": 25,
        "95k": 38,
        "100k": 18,
        "105k": 14,
        "110k": 13,
        "115k": 23,
        "120k": 17,
        "125k": 17,
        "130k": 17,
        "135k": 9,
        "140k": 4,
        "145k": 9,
        "150k": 12,
        "155k": 9,
        "160k": 7,
        "165k": 5,
        "170k": 5,
        "175k": 7,
        "180k": 10,
        "185k": 14,
        "190k": 11,
        "195k": 5,
        "200k": 3,
        "350k": 90
      },
      nonRelativem: {
        "5k": 30,
        "10k": 11,
        "15k": 28,
        "20k": 33,
        "25k": 38,
        "30k": 42,
        "35k": 28,
        "40k": 43,
        "45k": 64,
        "50k": 54,
        "55k": 57,
        "60k": 42,
        "65k": 81,
        "70k": 67,
        "75k": 80,
        "80k": 75,
        "85k": 32,
        "90k": 36,
        "95k": 65,
        "100k": 42,
        "105k": 40,
        "110k": 39,
        "115k": 49,
        "120k": 32,
        "125k": 58,
        "130k": 40,
        "135k": 15,
        "140k": 22,
        "145k": 22,
        "150k": 46,
        "155k": 36,
        "160k": 22,
        "165k": 41,
        "170k": 31,
        "175k": 33,
        "180k": 11,
        "185k": 14,
        "190k": 7,
        "195k": 1,
        "200k": 10,
        "350k": 213
      },
      houseLoitererfm: {
        "5k": 269,
        "10k": 144,
        "15k": 188,
        "20k": 180,
        "25k": 189,
        "30k": 251,
        "35k": 224,
        "40k": 247,
        "45k": 244,
        "50k": 233,
        "55k": 235,
        "60k": 266,
        "65k": 221,
        "70k": 200,
        "75k": 147,
        "80k": 154,
        "85k": 152,
        "90k": 117,
        "95k": 141,
        "100k": 111,
        "105k": 85,
        "110k": 94,
        "115k": 66,
        "120k": 74,
        "125k": 78,
        "130k": 60,
        "135k": 70,
        "140k": 48,
        "145k": 33,
        "150k": 25,
        "155k": 33,
        "160k": 17,
        "165k": 36,
        "170k": 19,
        "175k": 10,
        "180k": 19,
        "185k": 15,
        "190k": 7,
        "195k": 3,
        "200k": 8,
        "350k": 187
      },
      spousefm: {
        "5k": 13,
        "10k": 20,
        "15k": 10,
        "20k": 19,
        "25k": 42,
        "30k": 39,
        "35k": 52,
        "40k": 87,
        "45k": 94,
        "50k": 109,
        "55k": 86,
        "60k": 86,
        "65k": 114,
        "70k": 111,
        "75k": 87,
        "80k": 79,
        "85k": 118,
        "90k": 103,
        "95k": 95,
        "100k": 103,
        "105k": 45,
        "110k": 63,
        "115k": 76,
        "120k": 71,
        "125k": 47,
        "130k": 40,
        "135k": 58,
        "140k": 42,
        "145k": 31,
        "150k": 11,
        "155k": 30,
        "160k": 25,
        "165k": 12,
        "170k": 22,
        "175k": 14,
        "180k": 18,
        "185k": 19,
        "190k": 14,
        "195k": 6,
        "200k": 5,
        "350k": 133
      },
      childfm: {
        "5k": 15,
        "10k": 8,
        "15k": 32,
        "20k": 26,
        "25k": 28,
        "30k": 43,
        "35k": 34,
        "40k": 41,
        "45k": 32,
        "50k": 49,
        "55k": 58,
        "60k": 44,
        "65k": 55,
        "70k": 64,
        "75k": 73,
        "80k": 49,
        "85k": 47,
        "90k": 72,
        "95k": 53,
        "100k": 44,
        "105k": 58,
        "110k": 56,
        "115k": 64,
        "120k": 24,
        "125k": 30,
        "130k": 52,
        "135k": 37,
        "140k": 22,
        "145k": 62,
        "150k": 54,
        "155k": 50,
        "160k": 16,
        "165k": 39,
        "170k": 21,
        "175k": 42,
        "180k": 30,
        "185k": 21,
        "190k": 23,
        "195k": 14,
        "200k": 25,
        "350k": 385
      },
      otherRelativefm: {
        "5k": 7,
        "10k": 6,
        "15k": 3,
        "20k": 7,
        "25k": 12,
        "30k": 14,
        "35k": 32,
        "40k": 14,
        "45k": 14,
        "50k": 22,
        "55k": 14,
        "60k": 12,
        "65k": 40,
        "70k": 24,
        "75k": 23,
        "80k": 11,
        "85k": 15,
        "90k": 21,
        "95k": 18,
        "100k": 19,
        "105k": 29,
        "110k": 22,
        "115k": 14,
        "120k": 12,
        "125k": 17,
        "130k": 17,
        "135k": 17,
        "140k": 17,
        "145k": 9,
        "150k": 14,
        "155k": 2,
        "160k": 16,
        "165k": 11,
        "170k": 15,
        "175k": 4,
        "180k": 6,
        "185k": 11,
        "190k": 1,
        "195k": 6,
        "200k": 2,
        "350k": 41
      },
      nonRelativefm: {
        "5k": 20,
        "10k": 16,
        "15k": 15,
        "20k": 33,
        "25k": 34,
        "30k": 43,
        "35k": 45,
        "40k": 49,
        "45k": 38,
        "50k": 84,
        "55k": 82,
        "60k": 77,
        "65k": 58,
        "70k": 57,
        "75k": 64,
        "80k": 89,
        "85k": 68,
        "90k": 53,
        "95k": 59,
        "100k": 59,
        "105k": 39,
        "110k": 40,
        "115k": 35,
        "120k": 28,
        "125k": 49,
        "130k": 44,
        "135k": 38,
        "140k": 41,
        "145k": 13,
        "150k": 11,
        "155k": 24,
        "160k": 27,
        "165k": 22,
        "170k": 23,
        "175k": 18,
        "180k": 17,
        "185k": 23,
        "190k": 6,
        "195k": 14,
        "200k": 9,
        "350k": 156
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 89,
        "10k": 77,
        "15k": 52,
        "20k": 102,
        "25k": 131,
        "30k": 156,
        "35k": 212,
        "40k": 220,
        "45k": 241,
        "50k": 223,
        "55k": 242,
        "60k": 194,
        "65k": 238,
        "70k": 211,
        "75k": 199,
        "80k": 244,
        "85k": 203,
        "90k": 145,
        "95k": 159,
        "100k": 200,
        "105k": 126,
        "110k": 151,
        "115k": 157,
        "120k": 119,
        "125k": 148,
        "130k": 110,
        "135k": 97,
        "140k": 79,
        "145k": 71,
        "150k": 53,
        "155k": 89,
        "160k": 54,
        "165k": 64,
        "170k": 30,
        "175k": 43,
        "180k": 38,
        "185k": 47,
        "190k": 36,
        "195k": 28,
        "200k": 31,
        "350k": 450
      },
      spousem: {
        "5k": 10,
        "10k": 10,
        "15k": 13,
        "20k": 27,
        "25k": 60,
        "30k": 55,
        "35k": 53,
        "40k": 64,
        "45k": 60,
        "50k": 111,
        "55k": 76,
        "60k": 95,
        "65k": 78,
        "70k": 90,
        "75k": 74,
        "80k": 76,
        "85k": 83,
        "90k": 82,
        "95k": 98,
        "100k": 64,
        "105k": 70,
        "110k": 56,
        "115k": 51,
        "120k": 67,
        "125k": 53,
        "130k": 48,
        "135k": 49,
        "140k": 14,
        "145k": 24,
        "150k": 11,
        "155k": 35,
        "160k": 19,
        "165k": 32,
        "170k": 14,
        "175k": 8,
        "180k": 15,
        "185k": 13,
        "190k": 18,
        "195k": 12,
        "200k": 4,
        "350k": 182
      },
      childm: {
        "5k": 15,
        "10k": 20,
        "15k": 19,
        "20k": 51,
        "25k": 20,
        "30k": 12,
        "35k": 73,
        "40k": 49,
        "45k": 51,
        "50k": 47,
        "55k": 37,
        "60k": 62,
        "65k": 43,
        "70k": 36,
        "75k": 18,
        "80k": 45,
        "85k": 46,
        "90k": 44,
        "95k": 21,
        "100k": 52,
        "105k": 23,
        "110k": 48,
        "115k": 37,
        "120k": 44,
        "125k": 28,
        "130k": 37,
        "135k": 52,
        "140k": 17,
        "145k": 28,
        "150k": 36,
        "155k": 21,
        "160k": 12,
        "165k": 23,
        "170k": 17,
        "175k": 14,
        "180k": 19,
        "185k": 31,
        "190k": 13,
        "195k": 9,
        "200k": 10,
        "350k": 252
      },
      otherRelativem: {
        "5k": 3,
        "10k": 0,
        "15k": 0,
        "20k": 16,
        "25k": 16,
        "30k": 17,
        "35k": 22,
        "40k": 17,
        "45k": 14,
        "50k": 19,
        "55k": 16,
        "60k": 25,
        "65k": 45,
        "70k": 31,
        "75k": 24,
        "80k": 8,
        "85k": 29,
        "90k": 26,
        "95k": 11,
        "100k": 2,
        "105k": 14,
        "110k": 11,
        "115k": 3,
        "120k": 26,
        "125k": 13,
        "130k": 15,
        "135k": 8,
        "140k": 18,
        "145k": 15,
        "150k": 6,
        "155k": 5,
        "160k": 13,
        "165k": 13,
        "170k": 3,
        "175k": 9,
        "180k": 15,
        "185k": 5,
        "190k": 6,
        "195k": 11,
        "200k": 7,
        "350k": 58
      },
      nonRelativem: {
        "5k": 13,
        "10k": 26,
        "15k": 12,
        "20k": 18,
        "25k": 30,
        "30k": 23,
        "35k": 49,
        "40k": 34,
        "45k": 42,
        "50k": 31,
        "55k": 39,
        "60k": 40,
        "65k": 33,
        "70k": 46,
        "75k": 70,
        "80k": 35,
        "85k": 55,
        "90k": 20,
        "95k": 27,
        "100k": 46,
        "105k": 15,
        "110k": 32,
        "115k": 16,
        "120k": 20,
        "125k": 23,
        "130k": 10,
        "135k": 20,
        "140k": 17,
        "145k": 20,
        "150k": 7,
        "155k": 16,
        "160k": 2,
        "165k": 30,
        "170k": 6,
        "175k": 12,
        "180k": 6,
        "185k": 4,
        "190k": 7,
        "195k": 0,
        "200k": 8,
        "350k": 184
      },
      houseLoitererfm: {
        "5k": 186,
        "10k": 142,
        "15k": 139,
        "20k": 195,
        "25k": 235,
        "30k": 176,
        "35k": 251,
        "40k": 236,
        "45k": 247,
        "50k": 262,
        "55k": 235,
        "60k": 160,
        "65k": 163,
        "70k": 150,
        "75k": 198,
        "80k": 178,
        "85k": 147,
        "90k": 144,
        "95k": 129,
        "100k": 137,
        "105k": 124,
        "110k": 95,
        "115k": 91,
        "120k": 68,
        "125k": 67,
        "130k": 74,
        "135k": 89,
        "140k": 50,
        "145k": 45,
        "150k": 40,
        "155k": 59,
        "160k": 35,
        "165k": 61,
        "170k": 33,
        "175k": 21,
        "180k": 30,
        "185k": 22,
        "190k": 36,
        "195k": 20,
        "200k": 7,
        "350k": 297
      },
      spousefm: {
        "5k": 16,
        "10k": 14,
        "15k": 23,
        "20k": 35,
        "25k": 41,
        "30k": 68,
        "35k": 77,
        "40k": 107,
        "45k": 91,
        "50k": 119,
        "55k": 119,
        "60k": 120,
        "65k": 142,
        "70k": 94,
        "75k": 137,
        "80k": 143,
        "85k": 117,
        "90k": 134,
        "95k": 100,
        "100k": 137,
        "105k": 114,
        "110k": 137,
        "115k": 103,
        "120k": 83,
        "125k": 103,
        "130k": 73,
        "135k": 96,
        "140k": 63,
        "145k": 40,
        "150k": 65,
        "155k": 58,
        "160k": 46,
        "165k": 63,
        "170k": 43,
        "175k": 36,
        "180k": 40,
        "185k": 24,
        "190k": 37,
        "195k": 40,
        "200k": 25,
        "350k": 397
      },
      childfm: {
        "5k": 9,
        "10k": 12,
        "15k": 16,
        "20k": 18,
        "25k": 24,
        "30k": 29,
        "35k": 21,
        "40k": 30,
        "45k": 33,
        "50k": 21,
        "55k": 36,
        "60k": 34,
        "65k": 36,
        "70k": 34,
        "75k": 18,
        "80k": 27,
        "85k": 37,
        "90k": 36,
        "95k": 26,
        "100k": 29,
        "105k": 35,
        "110k": 35,
        "115k": 20,
        "120k": 23,
        "125k": 20,
        "130k": 28,
        "135k": 9,
        "140k": 1,
        "145k": 24,
        "150k": 11,
        "155k": 16,
        "160k": 16,
        "165k": 9,
        "170k": 11,
        "175k": 11,
        "180k": 9,
        "185k": 6,
        "190k": 6,
        "195k": 13,
        "200k": 4,
        "350k": 121
      },
      otherRelativefm: {
        "5k": 4,
        "10k": 10,
        "15k": 10,
        "20k": 5,
        "25k": 14,
        "30k": 10,
        "35k": 5,
        "40k": 16,
        "45k": 15,
        "50k": 13,
        "55k": 12,
        "60k": 41,
        "65k": 11,
        "70k": 11,
        "75k": 9,
        "80k": 17,
        "85k": 11,
        "90k": 12,
        "95k": 14,
        "100k": 22,
        "105k": 3,
        "110k": 9,
        "115k": 6,
        "120k": 8,
        "125k": 9,
        "130k": 6,
        "135k": 14,
        "140k": 3,
        "145k": 2,
        "150k": 1,
        "155k": 0,
        "160k": 10,
        "165k": 12,
        "170k": 7,
        "175k": 8,
        "180k": 11,
        "185k": 7,
        "190k": 2,
        "195k": 1,
        "200k": 5,
        "350k": 43
      },
      nonRelativefm: {
        "5k": 12,
        "10k": 12,
        "15k": 10,
        "20k": 7,
        "25k": 19,
        "30k": 18,
        "35k": 24,
        "40k": 34,
        "45k": 36,
        "50k": 30,
        "55k": 56,
        "60k": 37,
        "65k": 59,
        "70k": 46,
        "75k": 32,
        "80k": 52,
        "85k": 43,
        "90k": 25,
        "95k": 36,
        "100k": 22,
        "105k": 22,
        "110k": 27,
        "115k": 18,
        "120k": 23,
        "125k": 24,
        "130k": 22,
        "135k": 12,
        "140k": 19,
        "145k": 10,
        "150k": 8,
        "155k": 21,
        "160k": 13,
        "165k": 10,
        "170k": 6,
        "175k": 6,
        "180k": 9,
        "185k": 14,
        "190k": 2,
        "195k": 5,
        "200k": 10,
        "350k": 114
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 163,
        "10k": 142,
        "15k": 239,
        "20k": 201,
        "25k": 231,
        "30k": 313,
        "35k": 310,
        "40k": 370,
        "45k": 360,
        "50k": 349,
        "55k": 428,
        "60k": 286,
        "65k": 410,
        "70k": 310,
        "75k": 347,
        "80k": 358,
        "85k": 352,
        "90k": 301,
        "95k": 321,
        "100k": 286,
        "105k": 316,
        "110k": 316,
        "115k": 258,
        "120k": 248,
        "125k": 251,
        "130k": 192,
        "135k": 206,
        "140k": 204,
        "145k": 162,
        "150k": 183,
        "155k": 198,
        "160k": 109,
        "165k": 184,
        "170k": 134,
        "175k": 116,
        "180k": 104,
        "185k": 113,
        "190k": 44,
        "195k": 86,
        "200k": 102,
        "350k": "1,377"
      },
      spousem: {
        "5k": 40,
        "10k": 15,
        "15k": 48,
        "20k": 66,
        "25k": 98,
        "30k": 102,
        "35k": 125,
        "40k": 120,
        "45k": 140,
        "50k": 158,
        "55k": 172,
        "60k": 128,
        "65k": 178,
        "70k": 127,
        "75k": 164,
        "80k": 159,
        "85k": 181,
        "90k": 163,
        "95k": 141,
        "100k": 185,
        "105k": 167,
        "110k": 151,
        "115k": 163,
        "120k": 122,
        "125k": 125,
        "130k": 128,
        "135k": 120,
        "140k": 102,
        "145k": 110,
        "150k": 97,
        "155k": 98,
        "160k": 62,
        "165k": 81,
        "170k": 62,
        "175k": 85,
        "180k": 45,
        "185k": 44,
        "190k": 51,
        "195k": 28,
        "200k": 49,
        "350k": "759"
      },
      childm: {
        "5k": 35,
        "10k": 52,
        "15k": 26,
        "20k": 51,
        "25k": 59,
        "30k": 59,
        "35k": 55,
        "40k": 83,
        "45k": 57,
        "50k": 72,
        "55k": 83,
        "60k": 70,
        "65k": 65,
        "70k": 39,
        "75k": 48,
        "80k": 46,
        "85k": 46,
        "90k": 79,
        "95k": 44,
        "100k": 56,
        "105k": 33,
        "110k": 34,
        "115k": 27,
        "120k": 45,
        "125k": 13,
        "130k": 30,
        "135k": 30,
        "140k": 26,
        "145k": 32,
        "150k": 16,
        "155k": 17,
        "160k": 12,
        "165k": 14,
        "170k": 21,
        "175k": 14,
        "180k": 7,
        "185k": 0,
        "190k": 9,
        "195k": 8,
        "200k": 16,
        "350k": "152"
      },
      otherRelativem: {
        "5k": 12,
        "10k": 9,
        "15k": 15,
        "20k": 21,
        "25k": 17,
        "30k": 28,
        "35k": 30,
        "40k": 37,
        "45k": 40,
        "50k": 32,
        "55k": 32,
        "60k": 24,
        "65k": 22,
        "70k": 32,
        "75k": 40,
        "80k": 34,
        "85k": 31,
        "90k": 25,
        "95k": 27,
        "100k": 19,
        "105k": 34,
        "110k": 18,
        "115k": 29,
        "120k": 22,
        "125k": 22,
        "130k": 8,
        "135k": 17,
        "140k": 13,
        "145k": 18,
        "150k": 23,
        "155k": 23,
        "160k": 12,
        "165k": 9,
        "170k": 11,
        "175k": 20,
        "180k": 7,
        "185k": 8,
        "190k": 4,
        "195k": 5,
        "200k": 0,
        "350k": "92"
      },
      nonRelativem: {
        "5k": 16,
        "10k": 5,
        "15k": 37,
        "20k": 23,
        "25k": 37,
        "30k": 42,
        "35k": 36,
        "40k": 50,
        "45k": 66,
        "50k": 64,
        "55k": 38,
        "60k": 43,
        "65k": 62,
        "70k": 52,
        "75k": 62,
        "80k": 57,
        "85k": 64,
        "90k": 50,
        "95k": 37,
        "100k": 38,
        "105k": 52,
        "110k": 80,
        "115k": 46,
        "120k": 44,
        "125k": 27,
        "130k": 28,
        "135k": 27,
        "140k": 28,
        "145k": 20,
        "150k": 17,
        "155k": 20,
        "160k": 20,
        "165k": 21,
        "170k": 14,
        "175k": 7,
        "180k": 15,
        "185k": 9,
        "190k": 14,
        "195k": 4,
        "200k": 6,
        "350k": "131"
      },
      houseLoitererfm: {
        "5k": 310,
        "10k": 237,
        "15k": 315,
        "20k": 383,
        "25k": 436,
        "30k": 413,
        "35k": 427,
        "40k": 376,
        "45k": 421,
        "50k": 414,
        "55k": 426,
        "60k": 325,
        "65k": 380,
        "70k": 294,
        "75k": 284,
        "80k": 309,
        "85k": 297,
        "90k": 308,
        "95k": 262,
        "100k": 238,
        "105k": 234,
        "110k": 203,
        "115k": 244,
        "120k": 180,
        "125k": 163,
        "130k": 158,
        "135k": 151,
        "140k": 121,
        "145k": 143,
        "150k": 144,
        "155k": 135,
        "160k": 112,
        "165k": 102,
        "170k": 90,
        "175k": 117,
        "180k": 65,
        "185k": 81,
        "190k": 65,
        "195k": 34,
        "200k": 53,
        "350k": "942"
      },
      spousefm: {
        "5k": 36,
        "10k": 24,
        "15k": 56,
        "20k": 66,
        "25k": 104,
        "30k": 142,
        "35k": 112,
        "40k": 167,
        "45k": 163,
        "50k": 154,
        "55k": 208,
        "60k": 180,
        "65k": 224,
        "70k": 175,
        "75k": 179,
        "80k": 237,
        "85k": 225,
        "90k": 154,
        "95k": 220,
        "100k": 222,
        "105k": 238,
        "110k": 209,
        "115k": 214,
        "120k": 202,
        "125k": 216,
        "130k": 168,
        "135k": 174,
        "140k": 142,
        "145k": 151,
        "150k": 155,
        "155k": 200,
        "160k": 100,
        "165k": 149,
        "170k": 105,
        "175k": 94,
        "180k": 99,
        "185k": 108,
        "190k": 66,
        "195k": 72,
        "200k": 97,
        "350k": "1,330"
      },
      childfm: {
        "5k": 23,
        "10k": 3,
        "15k": 10,
        "20k": 37,
        "25k": 44,
        "30k": 35,
        "35k": 51,
        "40k": 29,
        "45k": 40,
        "50k": 48,
        "55k": 43,
        "60k": 34,
        "65k": 59,
        "70k": 34,
        "75k": 25,
        "80k": 42,
        "85k": 33,
        "90k": 26,
        "95k": 28,
        "100k": 29,
        "105k": 35,
        "110k": 19,
        "115k": 5,
        "120k": 26,
        "125k": 17,
        "130k": 31,
        "135k": 16,
        "140k": 19,
        "145k": 2,
        "150k": 5,
        "155k": 15,
        "160k": 5,
        "165k": 14,
        "170k": 11,
        "175k": 6,
        "180k": 16,
        "185k": 9,
        "190k": 10,
        "195k": 9,
        "200k": 7,
        "350k": "106"
      },
      otherRelativefm: {
        "5k": 11,
        "10k": 6,
        "15k": 16,
        "20k": 25,
        "25k": 36,
        "30k": 23,
        "35k": 39,
        "40k": 55,
        "45k": 33,
        "50k": 39,
        "55k": 43,
        "60k": 42,
        "65k": 25,
        "70k": 27,
        "75k": 21,
        "80k": 37,
        "85k": 38,
        "90k": 21,
        "95k": 23,
        "100k": 20,
        "105k": 25,
        "110k": 23,
        "115k": 11,
        "120k": 20,
        "125k": 21,
        "130k": 14,
        "135k": 21,
        "140k": 14,
        "145k": 12,
        "150k": 15,
        "155k": 18,
        "160k": 3,
        "165k": 8,
        "170k": 13,
        "175k": 9,
        "180k": 6,
        "185k": 8,
        "190k": 9,
        "195k": 6,
        "200k": 1,
        "350k": "81"
      },
      nonRelativefm: {
        "5k": 11,
        "10k": 13,
        "15k": 20,
        "20k": 20,
        "25k": 19,
        "30k": 47,
        "35k": 22,
        "40k": 26,
        "45k": 39,
        "50k": 43,
        "55k": 30,
        "60k": 40,
        "65k": 43,
        "70k": 43,
        "75k": 42,
        "80k": 41,
        "85k": 34,
        "90k": 35,
        "95k": 30,
        "100k": 20,
        "105k": 39,
        "110k": 41,
        "115k": 33,
        "120k": 31,
        "125k": 19,
        "130k": 16,
        "135k": 19,
        "140k": 20,
        "145k": 17,
        "150k": 6,
        "155k": 4,
        "160k": 4,
        "165k": 15,
        "170k": 12,
        "175k": 6,
        "180k": 4,
        "185k": 6,
        "190k": 16,
        "195k": 12,
        "200k": 8,
        "350k": "114"
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 227,
        "10k": 143,
        "15k": 198,
        "20k": 240,
        "25k": 239,
        "30k": 271,
        "35k": 324,
        "40k": 335,
        "45k": 297,
        "50k": 318,
        "55k": 401,
        "60k": 393,
        "65k": 340,
        "70k": 382,
        "75k": 317,
        "80k": 396,
        "85k": 340,
        "90k": 273,
        "95k": 311,
        "100k": 218,
        "105k": 358,
        "110k": 250,
        "115k": 281,
        "120k": 211,
        "125k": 241,
        "130k": 231,
        "135k": 198,
        "140k": 154,
        "145k": 178,
        "150k": 143,
        "155k": 243,
        "160k": 124,
        "165k": 155,
        "170k": 154,
        "175k": 124,
        "180k": 145,
        "185k": 94,
        "190k": 132,
        "195k": 89,
        "200k": 58,
        "350k": "1,830"
      },
      spousem: {
        "5k": 44,
        "10k": 19,
        "15k": 59,
        "20k": 54,
        "25k": 80,
        "30k": 77,
        "35k": 75,
        "40k": 116,
        "45k": 113,
        "50k": 104,
        "55k": 157,
        "60k": 117,
        "65k": 107,
        "70k": 124,
        "75k": 138,
        "80k": 180,
        "85k": 151,
        "90k": 171,
        "95k": 178,
        "100k": 137,
        "105k": 141,
        "110k": 142,
        "115k": 160,
        "120k": 122,
        "125k": 138,
        "130k": 91,
        "135k": 110,
        "140k": 119,
        "145k": 96,
        "150k": 126,
        "155k": 131,
        "160k": 100,
        "165k": 75,
        "170k": 68,
        "175k": 71,
        "180k": 72,
        "185k": 64,
        "190k": 56,
        "195k": 52,
        "200k": 52,
        "350k": "965"
      },
      childm: {
        "5k": 41,
        "10k": 11,
        "15k": 43,
        "20k": 57,
        "25k": 70,
        "30k": 65,
        "35k": 60,
        "40k": 44,
        "45k": 47,
        "50k": 28,
        "55k": 53,
        "60k": 55,
        "65k": 52,
        "70k": 52,
        "75k": 25,
        "80k": 62,
        "85k": 31,
        "90k": 29,
        "95k": 14,
        "100k": 18,
        "105k": 25,
        "110k": 29,
        "115k": 8,
        "120k": 14,
        "125k": 7,
        "130k": 13,
        "135k": 21,
        "140k": 16,
        "145k": 6,
        "150k": 4,
        "155k": 15,
        "160k": 5,
        "165k": 4,
        "170k": 10,
        "175k": 5,
        "180k": 6,
        "185k": 3,
        "190k": 11,
        "195k": 3,
        "200k": 1,
        "350k": "69"
      },
      otherRelativem: {
        "5k": 23,
        "10k": 13,
        "15k": 14,
        "20k": 10,
        "25k": 54,
        "30k": 23,
        "35k": 34,
        "40k": 31,
        "45k": 27,
        "50k": 31,
        "55k": 63,
        "60k": 35,
        "65k": 59,
        "70k": 34,
        "75k": 31,
        "80k": 84,
        "85k": 41,
        "90k": 42,
        "95k": 48,
        "100k": 27,
        "105k": 26,
        "110k": 31,
        "115k": 16,
        "120k": 39,
        "125k": 25,
        "130k": 38,
        "135k": 7,
        "140k": 5,
        "145k": 24,
        "150k": 25,
        "155k": 10,
        "160k": 12,
        "165k": 11,
        "170k": 17,
        "175k": 19,
        "180k": 10,
        "185k": 13,
        "190k": 13,
        "195k": 9,
        "200k": 8,
        "350k": "117"
      },
      nonRelativem: {
        "5k": 19,
        "10k": 9,
        "15k": 18,
        "20k": 21,
        "25k": 26,
        "30k": 13,
        "35k": 30,
        "40k": 49,
        "45k": 43,
        "50k": 30,
        "55k": 37,
        "60k": 52,
        "65k": 30,
        "70k": 36,
        "75k": 30,
        "80k": 43,
        "85k": 30,
        "90k": 46,
        "95k": 28,
        "100k": 17,
        "105k": 35,
        "110k": 40,
        "115k": 22,
        "120k": 27,
        "125k": 18,
        "130k": 33,
        "135k": 16,
        "140k": 30,
        "145k": 18,
        "150k": 22,
        "155k": 17,
        "160k": 10,
        "165k": 17,
        "170k": 21,
        "175k": 11,
        "180k": 11,
        "185k": 5,
        "190k": 17,
        "195k": 6,
        "200k": 6,
        "350k": "92"
      },
      houseLoitererfm: {
        "5k": 372,
        "10k": 243,
        "15k": 414,
        "20k": 310,
        "25k": 418,
        "30k": 342,
        "35k": 439,
        "40k": 414,
        "45k": 338,
        "50k": 353,
        "55k": 420,
        "60k": 297,
        "65k": 308,
        "70k": 301,
        "75k": 337,
        "80k": 331,
        "85k": 289,
        "90k": 278,
        "95k": 266,
        "100k": 217,
        "105k": 285,
        "110k": 211,
        "115k": 233,
        "120k": 168,
        "125k": 189,
        "130k": 151,
        "135k": 177,
        "140k": 133,
        "145k": 164,
        "150k": 191,
        "155k": 159,
        "160k": 122,
        "165k": 103,
        "170k": 103,
        "175k": 82,
        "180k": 84,
        "185k": 70,
        "190k": 69,
        "195k": 66,
        "200k": 72,
        "350k": "1,196"
      },
      spousefm: {
        "5k": 72,
        "10k": 22,
        "15k": 35,
        "20k": 72,
        "25k": 99,
        "30k": 103,
        "35k": 144,
        "40k": 147,
        "45k": 149,
        "50k": 170,
        "55k": 185,
        "60k": 178,
        "65k": 158,
        "70k": 214,
        "75k": 165,
        "80k": 251,
        "85k": 214,
        "90k": 201,
        "95k": 260,
        "100k": 175,
        "105k": 278,
        "110k": 166,
        "115k": 216,
        "120k": 163,
        "125k": 170,
        "130k": 181,
        "135k": 136,
        "140k": 143,
        "145k": 129,
        "150k": 116,
        "155k": 180,
        "160k": 100,
        "165k": 122,
        "170k": 124,
        "175k": 111,
        "180k": 117,
        "185k": 74,
        "190k": 100,
        "195k": 86,
        "200k": 58,
        "350k": "1,577"
      },
      childfm: {
        "5k": 10,
        "10k": 8,
        "15k": 26,
        "20k": 17,
        "25k": 9,
        "30k": 24,
        "35k": 29,
        "40k": 32,
        "45k": 23,
        "50k": 26,
        "55k": 26,
        "60k": 32,
        "65k": 27,
        "70k": 17,
        "75k": 19,
        "80k": 20,
        "85k": 22,
        "90k": 32,
        "95k": 28,
        "100k": 13,
        "105k": 8,
        "110k": 7,
        "115k": 10,
        "120k": 9,
        "125k": 7,
        "130k": 13,
        "135k": 13,
        "140k": 0,
        "145k": 10,
        "150k": 4,
        "155k": 2,
        "160k": 0,
        "165k": 2,
        "170k": 8,
        "175k": 3,
        "180k": 3,
        "185k": 5,
        "190k": 0,
        "195k": 2,
        "200k": 9,
        "350k": "27"
      },
      otherRelativefm: {
        "5k": 29,
        "10k": 6,
        "15k": 20,
        "20k": 31,
        "25k": 19,
        "30k": 44,
        "35k": 41,
        "40k": 52,
        "45k": 57,
        "50k": 40,
        "55k": 55,
        "60k": 55,
        "65k": 48,
        "70k": 73,
        "75k": 54,
        "80k": 40,
        "85k": 36,
        "90k": 38,
        "95k": 40,
        "100k": 33,
        "105k": 25,
        "110k": 34,
        "115k": 23,
        "120k": 34,
        "125k": 14,
        "130k": 19,
        "135k": 22,
        "140k": 13,
        "145k": 15,
        "150k": 20,
        "155k": 31,
        "160k": 26,
        "165k": 13,
        "170k": 10,
        "175k": 10,
        "180k": 7,
        "185k": 4,
        "190k": 11,
        "195k": 5,
        "200k": 1,
        "350k": "98"
      },
      nonRelativefm: {
        "5k": 14,
        "10k": 22,
        "15k": 17,
        "20k": 16,
        "25k": 25,
        "30k": 29,
        "35k": 15,
        "40k": 41,
        "45k": 31,
        "50k": 15,
        "55k": 42,
        "60k": 21,
        "65k": 24,
        "70k": 45,
        "75k": 31,
        "80k": 19,
        "85k": 30,
        "90k": 26,
        "95k": 24,
        "100k": 25,
        "105k": 16,
        "110k": 17,
        "115k": 13,
        "120k": 10,
        "125k": 14,
        "130k": 4,
        "135k": 15,
        "140k": 6,
        "145k": 19,
        "150k": 11,
        "155k": 6,
        "160k": 17,
        "165k": 19,
        "170k": 11,
        "175k": 5,
        "180k": 7,
        "185k": 10,
        "190k": 8,
        "195k": 2,
        "200k": 2,
        "350k": "137"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 421,
        "10k": 274,
        "15k": 527,
        "20k": 417,
        "25k": 396,
        "30k": 423,
        "35k": 408,
        "40k": 415,
        "45k": 354,
        "50k": 400,
        "55k": 492,
        "60k": 431,
        "65k": 387,
        "70k": 382,
        "75k": 359,
        "80k": 395,
        "85k": 293,
        "90k": 315,
        "95k": 370,
        "100k": 306,
        "105k": 327,
        "110k": 279,
        "115k": 237,
        "120k": 201,
        "125k": 221,
        "130k": 208,
        "135k": 171,
        "140k": 166,
        "145k": 164,
        "150k": 157,
        "155k": 159,
        "160k": 131,
        "165k": 124,
        "170k": 126,
        "175k": 115,
        "180k": 114,
        "185k": 94,
        "190k": 89,
        "195k": 84,
        "200k": 79,
        "350k": "1,577"
      },
      spousem: {
        "5k": 81,
        "10k": 36,
        "15k": 59,
        "20k": 48,
        "25k": 90,
        "30k": 106,
        "35k": 123,
        "40k": 129,
        "45k": 98,
        "50k": 138,
        "55k": 180,
        "60k": 154,
        "65k": 177,
        "70k": 138,
        "75k": 150,
        "80k": 167,
        "85k": 168,
        "90k": 123,
        "95k": 155,
        "100k": 113,
        "105k": 185,
        "110k": 131,
        "115k": 118,
        "120k": 114,
        "125k": 119,
        "130k": 103,
        "135k": 115,
        "140k": 84,
        "145k": 126,
        "150k": 91,
        "155k": 88,
        "160k": 83,
        "165k": 97,
        "170k": 81,
        "175k": 68,
        "180k": 58,
        "185k": 77,
        "190k": 39,
        "195k": 45,
        "200k": 46,
        "350k": "938"
      },
      childm: {
        "5k": 9,
        "10k": 10,
        "15k": 3,
        "20k": 14,
        "25k": 10,
        "30k": 8,
        "35k": 19,
        "40k": 18,
        "45k": 16,
        "50k": 21,
        "55k": 25,
        "60k": 4,
        "65k": 23,
        "70k": 28,
        "75k": 11,
        "80k": 24,
        "85k": 6,
        "90k": 6,
        "95k": 13,
        "100k": 3,
        "105k": 2,
        "110k": 6,
        "115k": 3,
        "120k": 1,
        "125k": 8,
        "130k": 1,
        "135k": 1,
        "140k": 6,
        "145k": 4,
        "150k": 2,
        "155k": 5,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 5,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": "8"
      },
      otherRelativem: {
        "5k": 12,
        "10k": 16,
        "15k": 21,
        "20k": 25,
        "25k": 24,
        "30k": 25,
        "35k": 20,
        "40k": 38,
        "45k": 37,
        "50k": 20,
        "55k": 33,
        "60k": 35,
        "65k": 40,
        "70k": 31,
        "75k": 42,
        "80k": 36,
        "85k": 42,
        "90k": 42,
        "95k": 23,
        "100k": 19,
        "105k": 20,
        "110k": 14,
        "115k": 31,
        "120k": 44,
        "125k": 22,
        "130k": 27,
        "135k": 18,
        "140k": 16,
        "145k": 11,
        "150k": 18,
        "155k": 13,
        "160k": 6,
        "165k": 9,
        "170k": 7,
        "175k": 5,
        "180k": 10,
        "185k": 6,
        "190k": 8,
        "195k": 3,
        "200k": 11,
        "350k": "132"
      },
      nonRelativem: {
        "5k": 8,
        "10k": 7,
        "15k": 17,
        "20k": 13,
        "25k": 35,
        "30k": 26,
        "35k": 38,
        "40k": 16,
        "45k": 35,
        "50k": 29,
        "55k": 28,
        "60k": 32,
        "65k": 25,
        "70k": 22,
        "75k": 21,
        "80k": 18,
        "85k": 28,
        "90k": 29,
        "95k": 15,
        "100k": 10,
        "105k": 18,
        "110k": 11,
        "115k": 7,
        "120k": 10,
        "125k": 14,
        "130k": 6,
        "135k": 11,
        "140k": 10,
        "145k": 10,
        "150k": 16,
        "155k": 9,
        "160k": 7,
        "165k": 0,
        "170k": 8,
        "175k": 1,
        "180k": 3,
        "185k": 4,
        "190k": 3,
        "195k": 2,
        "200k": 2,
        "350k": "82"
      },
      houseLoitererfm: {
        "5k": 472,
        "10k": 452,
        "15k": 610,
        "20k": 571,
        "25k": 507,
        "30k": 506,
        "35k": 470,
        "40k": 473,
        "45k": 449,
        "50k": 382,
        "55k": 492,
        "60k": 391,
        "65k": 401,
        "70k": 324,
        "75k": 336,
        "80k": 295,
        "85k": 269,
        "90k": 249,
        "95k": 280,
        "100k": 196,
        "105k": 292,
        "110k": 207,
        "115k": 152,
        "120k": 176,
        "125k": 158,
        "130k": 159,
        "135k": 143,
        "140k": 133,
        "145k": 157,
        "150k": 97,
        "155k": 153,
        "160k": 85,
        "165k": 114,
        "170k": 95,
        "175k": 80,
        "180k": 56,
        "185k": 90,
        "190k": 54,
        "195k": 59,
        "200k": 44,
        "350k": "958"
      },
      spousefm: {
        "5k": 122,
        "10k": 61,
        "15k": 84,
        "20k": 110,
        "25k": 142,
        "30k": 186,
        "35k": 218,
        "40k": 207,
        "45k": 218,
        "50k": 194,
        "55k": 272,
        "60k": 271,
        "65k": 275,
        "70k": 263,
        "75k": 233,
        "80k": 247,
        "85k": 219,
        "90k": 225,
        "95k": 221,
        "100k": 225,
        "105k": 260,
        "110k": 209,
        "115k": 175,
        "120k": 172,
        "125k": 181,
        "130k": 155,
        "135k": 131,
        "140k": 134,
        "145k": 125,
        "150k": 113,
        "155k": 138,
        "160k": 87,
        "165k": 93,
        "170k": 97,
        "175k": 77,
        "180k": 89,
        "185k": 73,
        "190k": 78,
        "195k": 71,
        "200k": 76,
        "350k": "1,290"
      },
      childfm: {
        "5k": 9,
        "10k": 2,
        "15k": 13,
        "20k": 12,
        "25k": 17,
        "30k": 24,
        "35k": 21,
        "40k": 5,
        "45k": 20,
        "50k": 23,
        "55k": 8,
        "60k": 24,
        "65k": 13,
        "70k": 9,
        "75k": 2,
        "80k": 11,
        "85k": 24,
        "90k": 5,
        "95k": 8,
        "100k": 2,
        "105k": 9,
        "110k": 0,
        "115k": 2,
        "120k": 4,
        "125k": 2,
        "130k": 7,
        "135k": 2,
        "140k": 3,
        "145k": 0,
        "150k": 1,
        "155k": 2,
        "160k": 2,
        "165k": 1,
        "170k": 4,
        "175k": 0,
        "180k": 4,
        "185k": 0,
        "190k": 0,
        "195k": 3,
        "200k": 1,
        "350k": "11"
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 22,
        "15k": 37,
        "20k": 47,
        "25k": 39,
        "30k": 42,
        "35k": 45,
        "40k": 79,
        "45k": 67,
        "50k": 38,
        "55k": 68,
        "60k": 58,
        "65k": 45,
        "70k": 54,
        "75k": 53,
        "80k": 49,
        "85k": 42,
        "90k": 42,
        "95k": 34,
        "100k": 29,
        "105k": 38,
        "110k": 32,
        "115k": 42,
        "120k": 48,
        "125k": 36,
        "130k": 33,
        "135k": 21,
        "140k": 23,
        "145k": 30,
        "150k": 31,
        "155k": 17,
        "160k": 20,
        "165k": 15,
        "170k": 10,
        "175k": 21,
        "180k": 16,
        "185k": 7,
        "190k": 10,
        "195k": 1,
        "200k": 11,
        "350k": "117"
      },
      nonRelativefm: {
        "5k": 16,
        "10k": 4,
        "15k": 26,
        "20k": 28,
        "25k": 31,
        "30k": 38,
        "35k": 15,
        "40k": 25,
        "45k": 28,
        "50k": 17,
        "55k": 17,
        "60k": 25,
        "65k": 21,
        "70k": 28,
        "75k": 44,
        "80k": 29,
        "85k": 24,
        "90k": 20,
        "95k": 13,
        "100k": 16,
        "105k": 16,
        "110k": 20,
        "115k": 21,
        "120k": 16,
        "125k": 14,
        "130k": 12,
        "135k": 5,
        "140k": 7,
        "145k": 8,
        "150k": 6,
        "155k": 12,
        "160k": 0,
        "165k": 8,
        "170k": 6,
        "175k": 8,
        "180k": 10,
        "185k": 7,
        "190k": 1,
        "195k": 0,
        "200k": 0,
        "350k": "57"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": 265,
        "10k": 252,
        "15k": 425,
        "20k": 498,
        "25k": 533,
        "30k": 422,
        "35k": 531,
        "40k": 437,
        "45k": 510,
        "50k": 390,
        "55k": 431,
        "60k": 312,
        "65k": 432,
        "70k": 330,
        "75k": 307,
        "80k": 314,
        "85k": 222,
        "90k": 243,
        "95k": 256,
        "100k": 206,
        "105k": 247,
        "110k": 179,
        "115k": 155,
        "120k": 161,
        "125k": 130,
        "130k": 120,
        "135k": 123,
        "140k": 111,
        "145k": 110,
        "150k": 80,
        "155k": 84,
        "160k": 60,
        "165k": 83,
        "170k": 44,
        "175k": 73,
        "180k": 66,
        "185k": 47,
        "190k": 62,
        "195k": 36,
        "200k": 45,
        "350k": 770
      },
      spousem: {
        "5k": 61,
        "10k": 45,
        "15k": 81,
        "20k": 61,
        "25k": 107,
        "30k": 138,
        "35k": 178,
        "40k": 183,
        "45k": 170,
        "50k": 140,
        "55k": 157,
        "60k": 157,
        "65k": 190,
        "70k": 160,
        "75k": 137,
        "80k": 127,
        "85k": 99,
        "90k": 111,
        "95k": 106,
        "100k": 73,
        "105k": 93,
        "110k": 82,
        "115k": 76,
        "120k": 70,
        "125k": 51,
        "130k": 58,
        "135k": 63,
        "140k": 51,
        "145k": 53,
        "150k": 54,
        "155k": 66,
        "160k": 31,
        "165k": 34,
        "170k": 37,
        "175k": 28,
        "180k": 28,
        "185k": 18,
        "190k": 44,
        "195k": 9,
        "200k": 35,
        "350k": 424
      },
      childm: {
        "5k": 0,
        "10k": 0,
        "15k": 1,
        "20k": 2,
        "25k": 2,
        "30k": 6,
        "35k": 4,
        "40k": 0,
        "45k": 3,
        "50k": 4,
        "55k": 2,
        "60k": 2,
        "65k": 0,
        "70k": 0,
        "75k": 2,
        "80k": 0,
        "85k": 2,
        "90k": 0,
        "95k": 0,
        "100k": 0,
        "105k": 0,
        "110k": 0,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 1
      },
      otherRelativem: {
        "5k": 9,
        "10k": 4,
        "15k": 9,
        "20k": 12,
        "25k": 19,
        "30k": 16,
        "35k": 16,
        "40k": 35,
        "45k": 14,
        "50k": 7,
        "55k": 30,
        "60k": 20,
        "65k": 10,
        "70k": 20,
        "75k": 13,
        "80k": 17,
        "85k": 5,
        "90k": 1,
        "95k": 22,
        "100k": 17,
        "105k": 15,
        "110k": 9,
        "115k": 14,
        "120k": 9,
        "125k": 25,
        "130k": 5,
        "135k": 5,
        "140k": 3,
        "145k": 7,
        "150k": 6,
        "155k": 5,
        "160k": 11,
        "165k": 4,
        "170k": 3,
        "175k": 8,
        "180k": 6,
        "185k": 4,
        "190k": 4,
        "195k": 7,
        "200k": 5,
        "350k": 66
      },
      nonRelativem: {
        "5k": 3,
        "10k": 2,
        "15k": 11,
        "20k": 21,
        "25k": 13,
        "30k": 9,
        "35k": 6,
        "40k": 7,
        "45k": 18,
        "50k": 22,
        "55k": 20,
        "60k": 14,
        "65k": 3,
        "70k": 13,
        "75k": 15,
        "80k": 9,
        "85k": 12,
        "90k": 4,
        "95k": 14,
        "100k": 4,
        "105k": 8,
        "110k": 7,
        "115k": 6,
        "120k": 11,
        "125k": 10,
        "130k": 3,
        "135k": 3,
        "140k": 3,
        "145k": 3,
        "150k": 6,
        "155k": 3,
        "160k": 6,
        "165k": 0,
        "170k": 6,
        "175k": 5,
        "180k": 1,
        "185k": 6,
        "190k": 0,
        "195k": 4,
        "200k": 0,
        "350k": 25
      },
      houseLoitererfm: {
        "5k": 311,
        "10k": 381,
        "15k": 768,
        "20k": 699,
        "25k": 582,
        "30k": 600,
        "35k": 544,
        "40k": 521,
        "45k": 421,
        "50k": 343,
        "55k": 378,
        "60k": 384,
        "65k": 330,
        "70k": 318,
        "75k": 258,
        "80k": 252,
        "85k": 242,
        "90k": 208,
        "95k": 159,
        "100k": 110,
        "105k": 134,
        "110k": 131,
        "115k": 131,
        "120k": 89,
        "125k": 103,
        "130k": 72,
        "135k": 93,
        "140k": 72,
        "145k": 62,
        "150k": 58,
        "155k": 51,
        "160k": 35,
        "165k": 43,
        "170k": 49,
        "175k": 31,
        "180k": 46,
        "185k": 34,
        "190k": 31,
        "195k": 17,
        "200k": 26,
        "350k": 462
      },
      spousefm: {
        "5k": 80,
        "10k": 61,
        "15k": 73,
        "20k": 133,
        "25k": 197,
        "30k": 196,
        "35k": 265,
        "40k": 268,
        "45k": 250,
        "50k": 206,
        "55k": 225,
        "60k": 218,
        "65k": 225,
        "70k": 184,
        "75k": 178,
        "80k": 222,
        "85k": 135,
        "90k": 173,
        "95k": 167,
        "100k": 106,
        "105k": 129,
        "110k": 129,
        "115k": 102,
        "120k": 111,
        "125k": 87,
        "130k": 102,
        "135k": 75,
        "140k": 54,
        "145k": 70,
        "150k": 77,
        "155k": 41,
        "160k": 58,
        "165k": 54,
        "170k": 46,
        "175k": 57,
        "180k": 46,
        "185k": 23,
        "190k": 36,
        "195k": 27,
        "200k": 14,
        "350k": 537
      },
      childfm: {
        "5k": 1,
        "10k": 0,
        "15k": 2,
        "20k": 0,
        "25k": 2,
        "30k": 8,
        "35k": 4,
        "40k": 2,
        "45k": 0,
        "50k": 6,
        "55k": 0,
        "60k": 3,
        "65k": 3,
        "70k": 0,
        "75k": 6,
        "80k": 0,
        "85k": 0,
        "90k": 0,
        "95k": 1,
        "100k": 0,
        "105k": 0,
        "110k": 1,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 1,
        "135k": 4,
        "140k": 0,
        "145k": 1,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 2,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 0
      },
      otherRelativefm: {
        "5k": 10,
        "10k": 18,
        "15k": 34,
        "20k": 30,
        "25k": 15,
        "30k": 40,
        "35k": 27,
        "40k": 57,
        "45k": 40,
        "50k": 33,
        "55k": 51,
        "60k": 50,
        "65k": 54,
        "70k": 49,
        "75k": 42,
        "80k": 45,
        "85k": 39,
        "90k": 31,
        "95k": 32,
        "100k": 48,
        "105k": 35,
        "110k": 31,
        "115k": 36,
        "120k": 10,
        "125k": 15,
        "130k": 19,
        "135k": 20,
        "140k": 28,
        "145k": 16,
        "150k": 10,
        "155k": 15,
        "160k": 17,
        "165k": 6,
        "170k": 15,
        "175k": 9,
        "180k": 13,
        "185k": 13,
        "190k": 6,
        "195k": 11,
        "200k": 4,
        "350k": 117
      },
      nonRelativefm: {
        "5k": 1,
        "10k": 4,
        "15k": 10,
        "20k": 1,
        "25k": 15,
        "30k": 15,
        "35k": 14,
        "40k": 35,
        "45k": 5,
        "50k": 30,
        "55k": 18,
        "60k": 16,
        "65k": 8,
        "70k": 28,
        "75k": 14,
        "80k": 11,
        "85k": 5,
        "90k": 4,
        "95k": 10,
        "100k": 0,
        "105k": 10,
        "110k": 10,
        "115k": 4,
        "120k": 6,
        "125k": 6,
        "130k": 0,
        "135k": 3,
        "140k": 7,
        "145k": 8,
        "150k": 8,
        "155k": 0,
        "160k": 0,
        "165k": 2,
        "170k": 0,
        "175k": 3,
        "180k": 0,
        "185k": 2,
        "190k": 6,
        "195k": 0,
        "200k": 0,
        "350k": 16
      }
    }
  },
  "2019": {
    "17": {
      houseLoitererm: {
        "5k": "5",
        "10k": "4",
        "15k": "1",
        "20k": "0",
        "25k": "2",
        "30k": "7",
        "35k": "3",
        "40k": "5",
        "45k": "9",
        "50k": "2",
        "55k": "4",
        "60k": "2",
        "65k": "5",
        "70k": "4",
        "75k": "6",
        "80k": "2",
        "85k": "2",
        "90k": "2",
        "95k": "10",
        "100k": "9",
        "105k": "6",
        "110k": "0",
        "115k": "0",
        "120k": "0",
        "125k": "0",
        "130k": "0",
        "135k": "0",
        "140k": "0",
        "145k": "1",
        "150k": "2",
        "155k": "3",
        "160k": "0",
        "165k": "0",
        "170k": "2",
        "175k": "0",
        "180k": "0",
        "185k": "0",
        "190k": "0",
        "195k": "0",
        "200k": "0",
        "350k": "4"
      },
      spousem: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childm: {
        "5k": "218",
        "10k": "121",
        "15k": "189",
        "20k": "274",
        "25k": "278",
        "30k": "364",
        "35k": "330",
        "40k": "306",
        "45k": "392",
        "50k": "399",
        "55k": "376",
        "60k": "356",
        "65k": "368",
        "70k": "321",
        "75k": "350",
        "80k": "304",
        "85k": "299",
        "90k": "247",
        "95k": "257",
        "100k": "270",
        "105k": "280",
        "110k": "223",
        "115k": "256",
        "120k": "205",
        "125k": "226",
        "130k": "224",
        "135k": "216",
        "140k": "205",
        "145k": "162",
        "150k": "145",
        "155k": "151",
        "160k": "156",
        "165k": "162",
        "170k": "140",
        "175k": "160",
        "180k": "159",
        "185k": "146",
        "190k": "68",
        "195k": "118",
        "200k": "63",
        "350k": "1,828"
      },
      otherRelativem: {
        "5k": "41",
        "10k": "10",
        "15k": "41",
        "20k": "22",
        "25k": "32",
        "30k": "48",
        "35k": "58",
        "40k": "58",
        "45k": "47",
        "50k": "41",
        "55k": "38",
        "60k": "45",
        "65k": "56",
        "70k": "61",
        "75k": "33",
        "80k": "51",
        "85k": "26",
        "90k": "62",
        "95k": "48",
        "100k": "24",
        "105k": "38",
        "110k": "23",
        "115k": "29",
        "120k": "20",
        "125k": "24",
        "130k": "17",
        "135k": "8",
        "140k": "14",
        "145k": "8",
        "150k": "24",
        "155k": "25",
        "160k": "5",
        "165k": "17",
        "170k": "10",
        "175k": "21",
        "180k": "10",
        "185k": "3",
        "190k": "2",
        "195k": "9",
        "200k": "1",
        "350k": "55"
      },
      nonRelativem: {
        "5k": "5",
        "10k": "2",
        "15k": "2",
        "20k": "1",
        "25k": "5",
        "30k": "6",
        "35k": "6",
        "40k": "6",
        "45k": "12",
        "50k": "7",
        "55k": "13",
        "60k": "7",
        "65k": "10",
        "70k": "14",
        "75k": "4",
        "80k": "5",
        "85k": "13",
        "90k": "8",
        "95k": "8",
        "100k": "9",
        "105k": "7",
        "110k": "4",
        "115k": "5",
        "120k": "10",
        "125k": "8",
        "130k": "7",
        "135k": "3",
        "140k": "0",
        "145k": "8",
        "150k": "0",
        "155k": "0",
        "160k": "0",
        "165k": "3",
        "170k": "2",
        "175k": "0",
        "180k": "0",
        "185k": "2",
        "190k": "0",
        "195k": "8",
        "200k": "3",
        "350k": "31"
      },
      houseLoitererfm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      spousefm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childfm: {
        "5k": "211",
        "10k": "112",
        "15k": "159",
        "20k": "272",
        "25k": "264",
        "30k": "373",
        "35k": "321",
        "40k": "345",
        "45k": "379",
        "50k": "318",
        "55k": "356",
        "60k": "305",
        "65k": "338",
        "70k": "283",
        "75k": "311",
        "80k": "319",
        "85k": "276",
        "90k": "223",
        "95k": "235",
        "100k": "260",
        "105k": "321",
        "110k": "226",
        "115k": "282",
        "120k": "194",
        "125k": "235",
        "130k": "192",
        "135k": "228",
        "140k": "199",
        "145k": "165",
        "150k": "165",
        "155k": "198",
        "160k": "129",
        "165k": "127",
        "170k": "120",
        "175k": "157",
        "180k": "132",
        "185k": "117",
        "190k": "97",
        "195k": "107",
        "200k": "93",
        "350k": "1,888"
      },
      otherRelativefm: {
        "5k": "38",
        "10k": "22",
        "15k": "41",
        "20k": "30",
        "25k": "32",
        "30k": "57",
        "35k": "37",
        "40k": "39",
        "45k": "70",
        "50k": "49",
        "55k": "53",
        "60k": "42",
        "65k": "42",
        "70k": "28",
        "75k": "33",
        "80k": "28",
        "85k": "28",
        "90k": "32",
        "95k": "35",
        "100k": "51",
        "105k": "42",
        "110k": "24",
        "115k": "23",
        "120k": "14",
        "125k": "22",
        "130k": "3",
        "135k": "25",
        "140k": "20",
        "145k": "22",
        "150k": "15",
        "155k": "7",
        "160k": "7",
        "165k": "13",
        "170k": "6",
        "175k": "11",
        "180k": "9",
        "185k": "7",
        "190k": "6",
        "195k": "2",
        "200k": "12",
        "350k": "51"
      },
      nonRelativefm: {
        "5k": "6",
        "10k": "3",
        "15k": "3",
        "20k": "0",
        "25k": "2",
        "30k": "2",
        "35k": "9",
        "40k": "10",
        "45k": "9",
        "50k": "11",
        "55k": "7",
        "60k": "9",
        "65k": "6",
        "70k": "11",
        "75k": "6",
        "80k": "4",
        "85k": "8",
        "90k": "5",
        "95k": "12",
        "100k": "6",
        "105k": "8",
        "110k": "3",
        "115k": "5",
        "120k": "3",
        "125k": "0",
        "130k": "5",
        "135k": "9",
        "140k": "4",
        "145k": "0",
        "150k": "6",
        "155k": "4",
        "160k": "7",
        "165k": "5",
        "170k": "0",
        "175k": "2",
        "180k": "2",
        "185k": "0",
        "190k": "2",
        "195k": "5",
        "200k": "8",
        "350k": "24"
      }
    },
    "21": {
      houseLoitererm: {
        "5k": "77",
        "10k": "38",
        "15k": "44",
        "20k": "35",
        "25k": "55",
        "30k": "57",
        "35k": "56",
        "40k": "33",
        "45k": "47",
        "50k": "44",
        "55k": "21",
        "60k": "24",
        "65k": "27",
        "70k": "47",
        "75k": "21",
        "80k": "18",
        "85k": "24",
        "90k": "26",
        "95k": "22",
        "100k": "20",
        "105k": "16",
        "110k": "9",
        "115k": "3",
        "120k": "8",
        "125k": "16",
        "130k": "14",
        "135k": "6",
        "140k": "4",
        "145k": "5",
        "150k": "4",
        "155k": "2",
        "160k": "3",
        "165k": "1",
        "170k": "0",
        "175k": "18",
        "180k": "2",
        "185k": "1",
        "190k": "0",
        "195k": "3",
        "200k": "0",
        "350k": "15"
      },
      spousem: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childm: {
        "5k": "41",
        "10k": "36",
        "15k": "76",
        "20k": "63",
        "25k": "119",
        "30k": "131",
        "35k": "125",
        "40k": "166",
        "45k": "160",
        "50k": "186",
        "55k": "173",
        "60k": "161",
        "65k": "178",
        "70k": "173",
        "75k": "190",
        "80k": "140",
        "85k": "138",
        "90k": "180",
        "95k": "140",
        "100k": "118",
        "105k": "129",
        "110k": "119",
        "115k": "126",
        "120k": "120",
        "125k": "176",
        "130k": "115",
        "135k": "132",
        "140k": "96",
        "145k": "101",
        "150k": "97",
        "155k": "96",
        "160k": "95",
        "165k": "100",
        "170k": "82",
        "175k": "100",
        "180k": "86",
        "185k": "59",
        "190k": "77",
        "195k": "85",
        "200k": "88",
        "350k": "1,128"
      },
      otherRelativem: {
        "5k": "12",
        "10k": "16",
        "15k": "22",
        "20k": "20",
        "25k": "19",
        "30k": "37",
        "35k": "37",
        "40k": "35",
        "45k": "27",
        "50k": "36",
        "55k": "34",
        "60k": "47",
        "65k": "46",
        "70k": "32",
        "75k": "31",
        "80k": "18",
        "85k": "20",
        "90k": "17",
        "95k": "25",
        "100k": "25",
        "105k": "29",
        "110k": "6",
        "115k": "20",
        "120k": "18",
        "125k": "21",
        "130k": "15",
        "135k": "12",
        "140k": "10",
        "145k": "23",
        "150k": "5",
        "155k": "7",
        "160k": "4",
        "165k": "11",
        "170k": "13",
        "175k": "11",
        "180k": "15",
        "185k": "2",
        "190k": "8",
        "195k": "11",
        "200k": "3",
        "350k": "39"
      },
      nonRelativem: {
        "5k": "19",
        "10k": "33",
        "15k": "15",
        "20k": "16",
        "25k": "8",
        "30k": "24",
        "35k": "66",
        "40k": "17",
        "45k": "31",
        "50k": "33",
        "55k": "20",
        "60k": "24",
        "65k": "16",
        "70k": "36",
        "75k": "20",
        "80k": "14",
        "85k": "39",
        "90k": "8",
        "95k": "7",
        "100k": "10",
        "105k": "14",
        "110k": "9",
        "115k": "11",
        "120k": "18",
        "125k": "22",
        "130k": "6",
        "135k": "4",
        "140k": "3",
        "145k": "1",
        "150k": "14",
        "155k": "2",
        "160k": "0",
        "165k": "6",
        "170k": "6",
        "175k": "0",
        "180k": "5",
        "185k": "8",
        "190k": "7",
        "195k": "5",
        "200k": "2",
        "350k": "24"
      },
      houseLoitererfm: {
        "5k": "66",
        "10k": "59",
        "15k": "64",
        "20k": "56",
        "25k": "44",
        "30k": "57",
        "35k": "52",
        "40k": "43",
        "45k": "22",
        "50k": "32",
        "55k": "35",
        "60k": "38",
        "65k": "35",
        "70k": "33",
        "75k": "26",
        "80k": "13",
        "85k": "6",
        "90k": "24",
        "95k": "13",
        "100k": "15",
        "105k": "5",
        "110k": "10",
        "115k": "7",
        "120k": "3",
        "125k": "3",
        "130k": "0",
        "135k": "8",
        "140k": "0",
        "145k": "3",
        "150k": "0",
        "155k": "10",
        "160k": "1",
        "165k": "1",
        "170k": "1",
        "175k": "0",
        "180k": "2",
        "185k": "0",
        "190k": "0",
        "195k": "3",
        "200k": "0",
        "350k": "8"
      },
      spousefm: {
        "5k": "1",
        "10k": "0",
        "15k": "1",
        "20k": "20",
        "25k": "2",
        "30k": "3",
        "35k": "13",
        "40k": "3",
        "45k": "8",
        "50k": "12",
        "55k": "8",
        "60k": "8",
        "65k": "10",
        "70k": "0",
        "75k": "4",
        "80k": "6",
        "85k": "5",
        "90k": "0",
        "95k": "11",
        "100k": "3",
        "105k": "0",
        "110k": "1",
        "115k": "3",
        "120k": "5",
        "125k": "0",
        "130k": "0",
        "135k": "0",
        "140k": "0",
        "145k": "1",
        "150k": "0",
        "155k": "4",
        "160k": "0",
        "165k": "0",
        "170k": "0",
        "175k": "0",
        "180k": "0",
        "185k": "0",
        "190k": "0",
        "195k": "0",
        "200k": "0",
        "350k": "0"
      },
      childfm: {
        "5k": "32",
        "10k": "19",
        "15k": "51",
        "20k": "85",
        "25k": "76",
        "30k": "118",
        "35k": "157",
        "40k": "161",
        "45k": "110",
        "50k": "142",
        "55k": "170",
        "60k": "140",
        "65k": "158",
        "70k": "176",
        "75k": "153",
        "80k": "126",
        "85k": "135",
        "90k": "160",
        "95k": "142",
        "100k": "154",
        "105k": "143",
        "110k": "141",
        "115k": "99",
        "120k": "114",
        "125k": "157",
        "130k": "139",
        "135k": "101",
        "140k": "94",
        "145k": "83",
        "150k": "94",
        "155k": "104",
        "160k": "98",
        "165k": "118",
        "170k": "82",
        "175k": "77",
        "180k": "111",
        "185k": "88",
        "190k": "65",
        "195k": "47",
        "200k": "58",
        "350k": "1,033"
      },
      otherRelativefm: {
        "5k": "6",
        "10k": "11",
        "15k": "11",
        "20k": "14",
        "25k": "25",
        "30k": "44",
        "35k": "17",
        "40k": "14",
        "45k": "24",
        "50k": "53",
        "55k": "20",
        "60k": "43",
        "65k": "42",
        "70k": "39",
        "75k": "19",
        "80k": "22",
        "85k": "19",
        "90k": "25",
        "95k": "33",
        "100k": "29",
        "105k": "21",
        "110k": "19",
        "115k": "14",
        "120k": "23",
        "125k": "7",
        "130k": "5",
        "135k": "14",
        "140k": "13",
        "145k": "5",
        "150k": "8",
        "155k": "20",
        "160k": "7",
        "165k": "13",
        "170k": "13",
        "175k": "12",
        "180k": "6",
        "185k": "5",
        "190k": "3",
        "195k": "3",
        "200k": "4",
        "350k": "66"
      },
      nonRelativefm: {
        "5k": "47",
        "10k": "9",
        "15k": "30",
        "20k": "28",
        "25k": "38",
        "30k": "49",
        "35k": "37",
        "40k": "38",
        "45k": "33",
        "50k": "36",
        "55k": "35",
        "60k": "28",
        "65k": "17",
        "70k": "15",
        "75k": "16",
        "80k": "31",
        "85k": "18",
        "90k": "12",
        "95k": "14",
        "100k": "18",
        "105k": "9",
        "110k": "25",
        "115k": "2",
        "120k": "15",
        "125k": "7",
        "130k": "8",
        "135k": "3",
        "140k": "4",
        "145k": "1",
        "150k": "2",
        "155k": "1",
        "160k": "4",
        "165k": "2",
        "170k": "8",
        "175k": "9",
        "180k": "0",
        "185k": "4",
        "190k": "2",
        "195k": "6",
        "200k": "1",
        "350k": "33"
      }
    },
    "24": {
      houseLoitererm: {
        "5k": 67,
        "10k": 30,
        "15k": 63,
        "20k": 90,
        "25k": 78,
        "30k": 102,
        "35k": 74,
        "40k": 74,
        "45k": 114,
        "50k": 89,
        "55k": 79,
        "60k": 97,
        "65k": 113,
        "70k": 68,
        "75k": 59,
        "80k": 62,
        "85k": 50,
        "90k": 48,
        "95k": 59,
        "100k": 53,
        "105k": 21,
        "110k": 25,
        "115k": 17,
        "120k": 21,
        "125k": 19,
        "130k": 15,
        "135k": 25,
        "140k": 28,
        "145k": 6,
        "150k": 12,
        "155k": 20,
        "160k": 12,
        "165k": 0,
        "170k": 7,
        "175k": 3,
        "180k": 14,
        "185k": 3,
        "190k": 14,
        "195k": 12,
        "200k": 3,
        "350k": 44
      },
      spousem: {
        "5k": 1,
        "10k": 2,
        "15k": 1,
        "20k": 0,
        "25k": 10,
        "30k": 4,
        "35k": 12,
        "40k": 15,
        "45k": 11,
        "50k": 16,
        "55k": 10,
        "60k": 11,
        "65k": 11,
        "70k": 1,
        "75k": 6,
        "80k": 10,
        "85k": 5,
        "90k": 4,
        "95k": 0,
        "100k": 5,
        "105k": 7,
        "110k": 9,
        "115k": 1,
        "120k": 3,
        "125k": 5,
        "130k": 2,
        "135k": 0,
        "140k": 0,
        "145k": 4,
        "150k": 1,
        "155k": 0,
        "160k": 0,
        "165k": 0,
        "170k": 1,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 3,
        "350k": 4
      },
      childm: {
        "5k": 28,
        "10k": 16,
        "15k": 20,
        "20k": 38,
        "25k": 41,
        "30k": 52,
        "35k": 68,
        "40k": 53,
        "45k": 54,
        "50k": 99,
        "55k": 68,
        "60k": 68,
        "65k": 101,
        "70k": 86,
        "75k": 65,
        "80k": 58,
        "85k": 78,
        "90k": 95,
        "95k": 62,
        "100k": 57,
        "105k": 49,
        "110k": 99,
        "115k": 52,
        "120k": 79,
        "125k": 82,
        "130k": 55,
        "135k": 54,
        "140k": 54,
        "145k": 27,
        "150k": 47,
        "155k": 30,
        "160k": 29,
        "165k": 77,
        "170k": 43,
        "175k": 65,
        "180k": 42,
        "185k": 57,
        "190k": 33,
        "195k": 45,
        "200k": 33,
        "350k": 630
      },
      otherRelativem: {
        "5k": 15,
        "10k": 11,
        "15k": 2,
        "20k": 13,
        "25k": 10,
        "30k": 15,
        "35k": 31,
        "40k": 15,
        "45k": 24,
        "50k": 22,
        "55k": 20,
        "60k": 20,
        "65k": 29,
        "70k": 27,
        "75k": 23,
        "80k": 22,
        "85k": 24,
        "90k": 22,
        "95k": 9,
        "100k": 17,
        "105k": 28,
        "110k": 24,
        "115k": 18,
        "120k": 6,
        "125k": 9,
        "130k": 8,
        "135k": 2,
        "140k": 10,
        "145k": 12,
        "150k": 15,
        "155k": 14,
        "160k": 10,
        "165k": 16,
        "170k": 10,
        "175k": 15,
        "180k": 6,
        "185k": 6,
        "190k": 6,
        "195k": 3,
        "200k": 1,
        "350k": 38
      },
      nonRelativem: {
        "5k": 36,
        "10k": 12,
        "15k": 7,
        "20k": 0,
        "25k": 12,
        "30k": 17,
        "35k": 44,
        "40k": 27,
        "45k": 27,
        "50k": 28,
        "55k": 30,
        "60k": 51,
        "65k": 41,
        "70k": 31,
        "75k": 23,
        "80k": 26,
        "85k": 30,
        "90k": 29,
        "95k": 32,
        "100k": 26,
        "105k": 17,
        "110k": 24,
        "115k": 39,
        "120k": 4,
        "125k": 36,
        "130k": 0,
        "135k": 21,
        "140k": 21,
        "145k": 13,
        "150k": 16,
        "155k": 1,
        "160k": 15,
        "165k": 2,
        "170k": 11,
        "175k": 10,
        "180k": 20,
        "185k": 6,
        "190k": 6,
        "195k": 10,
        "200k": 2,
        "350k": 85
      },
      houseLoitererfm: {
        "5k": 107,
        "10k": 68,
        "15k": 85,
        "20k": 79,
        "25k": 95,
        "30k": 116,
        "35k": 141,
        "40k": 86,
        "45k": 92,
        "50k": 97,
        "55k": 126,
        "60k": 95,
        "65k": 72,
        "70k": 66,
        "75k": 31,
        "80k": 38,
        "85k": 51,
        "90k": 28,
        "95k": 26,
        "100k": 21,
        "105k": 31,
        "110k": 25,
        "115k": 16,
        "120k": 15,
        "125k": 20,
        "130k": 18,
        "135k": 12,
        "140k": 19,
        "145k": 7,
        "150k": 6,
        "155k": 6,
        "160k": 7,
        "165k": 9,
        "170k": 18,
        "175k": 6,
        "180k": 9,
        "185k": 0,
        "190k": 7,
        "195k": 0,
        "200k": 3,
        "350k": 40
      },
      spousefm: {
        "5k": 5,
        "10k": 1,
        "15k": 0,
        "20k": 16,
        "25k": 9,
        "30k": 11,
        "35k": 26,
        "40k": 30,
        "45k": 37,
        "50k": 27,
        "55k": 35,
        "60k": 29,
        "65k": 28,
        "70k": 27,
        "75k": 21,
        "80k": 27,
        "85k": 17,
        "90k": 33,
        "95k": 34,
        "100k": 8,
        "105k": 11,
        "110k": 8,
        "115k": 30,
        "120k": 2,
        "125k": 5,
        "130k": 2,
        "135k": 1,
        "140k": 0,
        "145k": 10,
        "150k": 8,
        "155k": 0,
        "160k": 0,
        "165k": 6,
        "170k": 3,
        "175k": 4,
        "180k": 0,
        "185k": 7,
        "190k": 3,
        "195k": 0,
        "200k": 0,
        "350k": 6
      },
      childfm: {
        "5k": 24,
        "10k": 10,
        "15k": 19,
        "20k": 29,
        "25k": 54,
        "30k": 65,
        "35k": 87,
        "40k": 63,
        "45k": 44,
        "50k": 80,
        "55k": 39,
        "60k": 52,
        "65k": 72,
        "70k": 67,
        "75k": 55,
        "80k": 38,
        "85k": 87,
        "90k": 76,
        "95k": 57,
        "100k": 62,
        "105k": 80,
        "110k": 53,
        "115k": 35,
        "120k": 40,
        "125k": 77,
        "130k": 75,
        "135k": 38,
        "140k": 64,
        "145k": 58,
        "150k": 27,
        "155k": 57,
        "160k": 60,
        "165k": 45,
        "170k": 45,
        "175k": 43,
        "180k": 31,
        "185k": 35,
        "190k": 17,
        "195k": 27,
        "200k": 30,
        "350k": 594
      },
      otherRelativefm: {
        "5k": 14,
        "10k": 0,
        "15k": 3,
        "20k": 10,
        "25k": 15,
        "30k": 12,
        "35k": 4,
        "40k": 10,
        "45k": 23,
        "50k": 21,
        "55k": 31,
        "60k": 30,
        "65k": 18,
        "70k": 19,
        "75k": 7,
        "80k": 13,
        "85k": 1,
        "90k": 18,
        "95k": 12,
        "100k": 18,
        "105k": 19,
        "110k": 6,
        "115k": 2,
        "120k": 19,
        "125k": 17,
        "130k": 8,
        "135k": 1,
        "140k": 7,
        "145k": 11,
        "150k": 13,
        "155k": 9,
        "160k": 4,
        "165k": 2,
        "170k": 3,
        "175k": 10,
        "180k": 11,
        "185k": 11,
        "190k": 3,
        "195k": 11,
        "200k": 4,
        "350k": 37
      },
      nonRelativefm: {
        "5k": 37,
        "10k": 16,
        "15k": 18,
        "20k": 32,
        "25k": 21,
        "30k": 35,
        "35k": 23,
        "40k": 53,
        "45k": 53,
        "50k": 30,
        "55k": 63,
        "60k": 50,
        "65k": 53,
        "70k": 36,
        "75k": 43,
        "80k": 54,
        "85k": 31,
        "90k": 22,
        "95k": 29,
        "100k": 27,
        "105k": 26,
        "110k": 23,
        "115k": 13,
        "120k": 25,
        "125k": 23,
        "130k": 21,
        "135k": 10,
        "140k": 21,
        "145k": 4,
        "150k": 7,
        "155k": 22,
        "160k": 7,
        "165k": 0,
        "170k": 2,
        "175k": 3,
        "180k": 6,
        "185k": 4,
        "190k": 1,
        "195k": 8,
        "200k": 3,
        "350k": 113
      }
    },
    "29": {
      houseLoitererm: {
        "5k": 100,
        "10k": 41,
        "15k": 87,
        "20k": 97,
        "25k": 131,
        "30k": 181,
        "35k": 243,
        "40k": 154,
        "45k": 215,
        "50k": 190,
        "55k": 239,
        "60k": 211,
        "65k": 247,
        "70k": 189,
        "75k": 183,
        "80k": 206,
        "85k": 167,
        "90k": 139,
        "95k": 144,
        "100k": 138,
        "105k": 163,
        "110k": 99,
        "115k": 121,
        "120k": 75,
        "125k": 101,
        "130k": 40,
        "135k": 81,
        "140k": 42,
        "145k": 43,
        "150k": 36,
        "155k": 67,
        "160k": 42,
        "165k": 48,
        "170k": 48,
        "175k": 25,
        "180k": 36,
        "185k": 32,
        "190k": 27,
        "195k": 19,
        "200k": 19,
        "350k": 280
      },
      spousem: {
        "5k": 11,
        "10k": 17,
        "15k": 23,
        "20k": 10,
        "25k": 20,
        "30k": 33,
        "35k": 34,
        "40k": 21,
        "45k": 45,
        "50k": 43,
        "55k": 50,
        "60k": 39,
        "65k": 43,
        "70k": 28,
        "75k": 39,
        "80k": 46,
        "85k": 56,
        "90k": 47,
        "95k": 30,
        "100k": 44,
        "105k": 43,
        "110k": 16,
        "115k": 16,
        "120k": 16,
        "125k": 23,
        "130k": 5,
        "135k": 20,
        "140k": 20,
        "145k": 19,
        "150k": 12,
        "155k": 12,
        "160k": 6,
        "165k": 10,
        "170k": 0,
        "175k": 0,
        "180k": 9,
        "185k": 5,
        "190k": 2,
        "195k": 2,
        "200k": 3,
        "350k": 76
      },
      childm: {
        "5k": 51,
        "10k": 12,
        "15k": 16,
        "20k": 23,
        "25k": 65,
        "30k": 73,
        "35k": 81,
        "40k": 56,
        "45k": 87,
        "50k": 67,
        "55k": 81,
        "60k": 90,
        "65k": 97,
        "70k": 123,
        "75k": 75,
        "80k": 77,
        "85k": 93,
        "90k": 139,
        "95k": 106,
        "100k": 73,
        "105k": 88,
        "110k": 86,
        "115k": 82,
        "120k": 103,
        "125k": 84,
        "130k": 49,
        "135k": 61,
        "140k": 49,
        "145k": 33,
        "150k": 63,
        "155k": 58,
        "160k": 62,
        "165k": 45,
        "170k": 70,
        "175k": 26,
        "180k": 55,
        "185k": 42,
        "190k": 46,
        "195k": 26,
        "200k": 33,
        "350k": 595
      },
      otherRelativem: {
        "5k": 10,
        "10k": 2,
        "15k": 7,
        "20k": 13,
        "25k": 21,
        "30k": 22,
        "35k": 14,
        "40k": 34,
        "45k": 43,
        "50k": 47,
        "55k": 23,
        "60k": 53,
        "65k": 50,
        "70k": 51,
        "75k": 44,
        "80k": 12,
        "85k": 21,
        "90k": 40,
        "95k": 34,
        "100k": 35,
        "105k": 32,
        "110k": 21,
        "115k": 19,
        "120k": 16,
        "125k": 27,
        "130k": 10,
        "135k": 31,
        "140k": 14,
        "145k": 1,
        "150k": 13,
        "155k": 14,
        "160k": 8,
        "165k": 20,
        "170k": 11,
        "175k": 20,
        "180k": 20,
        "185k": 0,
        "190k": 5,
        "195k": 8,
        "200k": 11,
        "350k": 122
      },
      nonRelativem: {
        "5k": 39,
        "10k": 8,
        "15k": 8,
        "20k": 20,
        "25k": 45,
        "30k": 15,
        "35k": 65,
        "40k": 64,
        "45k": 41,
        "50k": 51,
        "55k": 77,
        "60k": 48,
        "65k": 66,
        "70k": 40,
        "75k": 58,
        "80k": 55,
        "85k": 73,
        "90k": 37,
        "95k": 34,
        "100k": 59,
        "105k": 30,
        "110k": 33,
        "115k": 48,
        "120k": 52,
        "125k": 44,
        "130k": 29,
        "135k": 30,
        "140k": 23,
        "145k": 31,
        "150k": 20,
        "155k": 34,
        "160k": 22,
        "165k": 19,
        "170k": 30,
        "175k": 15,
        "180k": 17,
        "185k": 2,
        "190k": 25,
        "195k": 9,
        "200k": 16,
        "350k": 270
      },
      houseLoitererfm: {
        "5k": 216,
        "10k": 178,
        "15k": 126,
        "20k": 182,
        "25k": 244,
        "30k": 227,
        "35k": 229,
        "40k": 225,
        "45k": 244,
        "50k": 176,
        "55k": 259,
        "60k": 173,
        "65k": 213,
        "70k": 127,
        "75k": 189,
        "80k": 151,
        "85k": 178,
        "90k": 123,
        "95k": 100,
        "100k": 125,
        "105k": 99,
        "110k": 91,
        "115k": 71,
        "120k": 74,
        "125k": 65,
        "130k": 62,
        "135k": 41,
        "140k": 50,
        "145k": 47,
        "150k": 28,
        "155k": 44,
        "160k": 58,
        "165k": 33,
        "170k": 31,
        "175k": 27,
        "180k": 19,
        "185k": 7,
        "190k": 16,
        "195k": 16,
        "200k": 6,
        "350k": 211
      },
      spousefm: {
        "5k": 25,
        "10k": 12,
        "15k": 11,
        "20k": 11,
        "25k": 39,
        "30k": 44,
        "35k": 67,
        "40k": 57,
        "45k": 57,
        "50k": 51,
        "55k": 82,
        "60k": 111,
        "65k": 111,
        "70k": 88,
        "75k": 92,
        "80k": 87,
        "85k": 83,
        "90k": 59,
        "95k": 82,
        "100k": 61,
        "105k": 113,
        "110k": 47,
        "115k": 83,
        "120k": 57,
        "125k": 66,
        "130k": 43,
        "135k": 51,
        "140k": 43,
        "145k": 25,
        "150k": 19,
        "155k": 47,
        "160k": 17,
        "165k": 28,
        "170k": 31,
        "175k": 21,
        "180k": 12,
        "185k": 17,
        "190k": 5,
        "195k": 22,
        "200k": 7,
        "350k": 127
      },
      childfm: {
        "5k": 11,
        "10k": 14,
        "15k": 22,
        "20k": 16,
        "25k": 45,
        "30k": 42,
        "35k": 27,
        "40k": 44,
        "45k": 47,
        "50k": 54,
        "55k": 75,
        "60k": 46,
        "65k": 59,
        "70k": 75,
        "75k": 76,
        "80k": 26,
        "85k": 76,
        "90k": 44,
        "95k": 76,
        "100k": 55,
        "105k": 73,
        "110k": 67,
        "115k": 49,
        "120k": 47,
        "125k": 60,
        "130k": 46,
        "135k": 35,
        "140k": 35,
        "145k": 34,
        "150k": 19,
        "155k": 34,
        "160k": 39,
        "165k": 40,
        "170k": 7,
        "175k": 23,
        "180k": 31,
        "185k": 42,
        "190k": 26,
        "195k": 31,
        "200k": 25,
        "350k": 316
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 7,
        "15k": 2,
        "20k": 14,
        "25k": 11,
        "30k": 22,
        "35k": 14,
        "40k": 10,
        "45k": 31,
        "50k": 14,
        "55k": 27,
        "60k": 21,
        "65k": 27,
        "70k": 36,
        "75k": 20,
        "80k": 24,
        "85k": 18,
        "90k": 23,
        "95k": 17,
        "100k": 20,
        "105k": 17,
        "110k": 17,
        "115k": 19,
        "120k": 8,
        "125k": 17,
        "130k": 7,
        "135k": 20,
        "140k": 11,
        "145k": 3,
        "150k": 11,
        "155k": 5,
        "160k": 10,
        "165k": 11,
        "170k": 3,
        "175k": 10,
        "180k": 3,
        "185k": 2,
        "190k": 4,
        "195k": 2,
        "200k": 6,
        "350k": 52
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 4,
        "15k": 19,
        "20k": 9,
        "25k": 32,
        "30k": 29,
        "35k": 71,
        "40k": 45,
        "45k": 61,
        "50k": 28,
        "55k": 65,
        "60k": 42,
        "65k": 60,
        "70k": 66,
        "75k": 81,
        "80k": 62,
        "85k": 80,
        "90k": 59,
        "95k": 75,
        "100k": 63,
        "105k": 41,
        "110k": 69,
        "115k": 56,
        "120k": 45,
        "125k": 80,
        "130k": 38,
        "135k": 38,
        "140k": 44,
        "145k": 26,
        "150k": 20,
        "155k": 36,
        "160k": 27,
        "165k": 7,
        "170k": 13,
        "175k": 19,
        "180k": 9,
        "185k": 12,
        "190k": 40,
        "195k": 24,
        "200k": 8,
        "350k": 216
      }
    },
    "34": {
      houseLoitererm: {
        "5k": 87,
        "10k": 38,
        "15k": 72,
        "20k": 69,
        "25k": 157,
        "30k": 127,
        "35k": 199,
        "40k": 149,
        "45k": 279,
        "50k": 214,
        "55k": 212,
        "60k": 205,
        "65k": 200,
        "70k": 218,
        "75k": 180,
        "80k": 225,
        "85k": 224,
        "90k": 174,
        "95k": 154,
        "100k": 184,
        "105k": 196,
        "110k": 177,
        "115k": 151,
        "120k": 127,
        "125k": 166,
        "130k": 99,
        "135k": 87,
        "140k": 118,
        "145k": 89,
        "150k": 52,
        "155k": 120,
        "160k": 62,
        "165k": 59,
        "170k": 50,
        "175k": 67,
        "180k": 42,
        "185k": 52,
        "190k": 28,
        "195k": 32,
        "200k": 25,
        "350k": 557
      },
      spousem: {
        "5k": 8,
        "10k": 4,
        "15k": 19,
        "20k": 23,
        "25k": 15,
        "30k": 36,
        "35k": 59,
        "40k": 55,
        "45k": 71,
        "50k": 48,
        "55k": 87,
        "60k": 74,
        "65k": 77,
        "70k": 64,
        "75k": 85,
        "80k": 94,
        "85k": 92,
        "90k": 60,
        "95k": 62,
        "100k": 66,
        "105k": 61,
        "110k": 52,
        "115k": 41,
        "120k": 58,
        "125k": 37,
        "130k": 47,
        "135k": 26,
        "140k": 21,
        "145k": 20,
        "150k": 29,
        "155k": 35,
        "160k": 48,
        "165k": 14,
        "170k": 14,
        "175k": 26,
        "180k": 16,
        "185k": 19,
        "190k": 10,
        "195k": 24,
        "200k": 8,
        "350k": 208
      },
      childm: {
        "5k": 29,
        "10k": 14,
        "15k": 14,
        "20k": 10,
        "25k": 48,
        "30k": 59,
        "35k": 22,
        "40k": 56,
        "45k": 65,
        "50k": 52,
        "55k": 57,
        "60k": 61,
        "65k": 69,
        "70k": 46,
        "75k": 51,
        "80k": 55,
        "85k": 68,
        "90k": 55,
        "95k": 27,
        "100k": 43,
        "105k": 43,
        "110k": 49,
        "115k": 22,
        "120k": 70,
        "125k": 37,
        "130k": 29,
        "135k": 40,
        "140k": 14,
        "145k": 29,
        "150k": 40,
        "155k": 23,
        "160k": 21,
        "165k": 35,
        "170k": 14,
        "175k": 8,
        "180k": 16,
        "185k": 27,
        "190k": 13,
        "195k": 11,
        "200k": 17,
        "350k": 321
      },
      otherRelativem: {
        "5k": 10,
        "10k": 8,
        "15k": 13,
        "20k": 19,
        "25k": 18,
        "30k": 36,
        "35k": 31,
        "40k": 20,
        "45k": 33,
        "50k": 17,
        "55k": 26,
        "60k": 16,
        "65k": 16,
        "70k": 19,
        "75k": 12,
        "80k": 9,
        "85k": 28,
        "90k": 18,
        "95k": 29,
        "100k": 19,
        "105k": 18,
        "110k": 10,
        "115k": 4,
        "120k": 5,
        "125k": 9,
        "130k": 15,
        "135k": 9,
        "140k": 9,
        "145k": 14,
        "150k": 5,
        "155k": 12,
        "160k": 8,
        "165k": 8,
        "170k": 13,
        "175k": 11,
        "180k": 12,
        "185k": 3,
        "190k": 0,
        "195k": 2,
        "200k": 6,
        "350k": 72
      },
      nonRelativem: {
        "5k": 17,
        "10k": 5,
        "15k": 10,
        "20k": 10,
        "25k": 35,
        "30k": 19,
        "35k": 40,
        "40k": 38,
        "45k": 38,
        "50k": 37,
        "55k": 49,
        "60k": 18,
        "65k": 39,
        "70k": 30,
        "75k": 43,
        "80k": 52,
        "85k": 31,
        "90k": 34,
        "95k": 22,
        "100k": 59,
        "105k": 15,
        "110k": 22,
        "115k": 27,
        "120k": 54,
        "125k": 22,
        "130k": 14,
        "135k": 15,
        "140k": 22,
        "145k": 4,
        "150k": 5,
        "155k": 11,
        "160k": 18,
        "165k": 5,
        "170k": 5,
        "175k": 11,
        "180k": 7,
        "185k": 4,
        "190k": 20,
        "195k": 5,
        "200k": 1,
        "350k": 119
      },
      houseLoitererfm: {
        "5k": 162,
        "10k": 113,
        "15k": 163,
        "20k": 160,
        "25k": 189,
        "30k": 213,
        "35k": 250,
        "40k": 265,
        "45k": 269,
        "50k": 214,
        "55k": 269,
        "60k": 191,
        "65k": 174,
        "70k": 163,
        "75k": 136,
        "80k": 170,
        "85k": 133,
        "90k": 138,
        "95k": 134,
        "100k": 137,
        "105k": 104,
        "110k": 114,
        "115k": 104,
        "120k": 79,
        "125k": 71,
        "130k": 64,
        "135k": 61,
        "140k": 51,
        "145k": 57,
        "150k": 64,
        "155k": 75,
        "160k": 59,
        "165k": 38,
        "170k": 28,
        "175k": 45,
        "180k": 34,
        "185k": 38,
        "190k": 17,
        "195k": 23,
        "200k": 28,
        "350k": 373
      },
      spousefm: {
        "5k": 13,
        "10k": 16,
        "15k": 12,
        "20k": 13,
        "25k": 41,
        "30k": 45,
        "35k": 76,
        "40k": 45,
        "45k": 111,
        "50k": 80,
        "55k": 111,
        "60k": 99,
        "65k": 107,
        "70k": 115,
        "75k": 122,
        "80k": 169,
        "85k": 135,
        "90k": 77,
        "95k": 109,
        "100k": 136,
        "105k": 110,
        "110k": 113,
        "115k": 95,
        "120k": 75,
        "125k": 115,
        "130k": 64,
        "135k": 83,
        "140k": 61,
        "145k": 80,
        "150k": 48,
        "155k": 109,
        "160k": 61,
        "165k": 59,
        "170k": 54,
        "175k": 53,
        "180k": 57,
        "185k": 57,
        "190k": 32,
        "195k": 17,
        "200k": 27,
        "350k": 495
      },
      childfm: {
        "5k": 1,
        "10k": 10,
        "15k": 4,
        "20k": 20,
        "25k": 20,
        "30k": 17,
        "35k": 28,
        "40k": 12,
        "45k": 31,
        "50k": 40,
        "55k": 16,
        "60k": 15,
        "65k": 16,
        "70k": 65,
        "75k": 18,
        "80k": 50,
        "85k": 31,
        "90k": 33,
        "95k": 39,
        "100k": 30,
        "105k": 35,
        "110k": 21,
        "115k": 12,
        "120k": 23,
        "125k": 28,
        "130k": 23,
        "135k": 19,
        "140k": 10,
        "145k": 16,
        "150k": 12,
        "155k": 19,
        "160k": 20,
        "165k": 12,
        "170k": 18,
        "175k": 6,
        "180k": 12,
        "185k": 2,
        "190k": 19,
        "195k": 6,
        "200k": 8,
        "350k": 184
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 1,
        "15k": 14,
        "20k": 5,
        "25k": 20,
        "30k": 19,
        "35k": 19,
        "40k": 6,
        "45k": 14,
        "50k": 15,
        "55k": 10,
        "60k": 22,
        "65k": 26,
        "70k": 3,
        "75k": 13,
        "80k": 16,
        "85k": 33,
        "90k": 12,
        "95k": 21,
        "100k": 6,
        "105k": 15,
        "110k": 5,
        "115k": 13,
        "120k": 22,
        "125k": 17,
        "130k": 9,
        "135k": 10,
        "140k": 22,
        "145k": 9,
        "150k": 2,
        "155k": 8,
        "160k": 4,
        "165k": 3,
        "170k": 6,
        "175k": 8,
        "180k": 1,
        "185k": 0,
        "190k": 5,
        "195k": 5,
        "200k": 6,
        "350k": 44
      },
      nonRelativefm: {
        "5k": 5,
        "10k": 7,
        "15k": 9,
        "20k": 8,
        "25k": 12,
        "30k": 31,
        "35k": 23,
        "40k": 16,
        "45k": 21,
        "50k": 17,
        "55k": 33,
        "60k": 32,
        "65k": 34,
        "70k": 30,
        "75k": 35,
        "80k": 28,
        "85k": 36,
        "90k": 44,
        "95k": 28,
        "100k": 27,
        "105k": 17,
        "110k": 41,
        "115k": 29,
        "120k": 46,
        "125k": 26,
        "130k": 18,
        "135k": 32,
        "140k": 28,
        "145k": 5,
        "150k": 22,
        "155k": 20,
        "160k": 15,
        "165k": 14,
        "170k": 9,
        "175k": 16,
        "180k": 7,
        "185k": 11,
        "190k": 2,
        "195k": 6,
        "200k": 18,
        "350k": 97
      }
    },
    "44": {
      houseLoitererm: {
        "5k": 189,
        "10k": 131,
        "15k": 186,
        "20k": 157,
        "25k": 150,
        "30k": 242,
        "35k": 315,
        "40k": 259,
        "45k": 339,
        "50k": 328,
        "55k": 385,
        "60k": 292,
        "65k": 360,
        "70k": 279,
        "75k": 412,
        "80k": 344,
        "85k": 298,
        "90k": 260,
        "95k": 324,
        "100k": 328,
        "105k": 336,
        "110k": 282,
        "115k": 277,
        "120k": 239,
        "125k": 302,
        "130k": 199,
        "135k": 251,
        "140k": 186,
        "145k": 190,
        "150k": 160,
        "155k": 261,
        "160k": 146,
        "165k": 158,
        "170k": 114,
        "175k": 173,
        "180k": 129,
        "185k": 115,
        "190k": 73,
        "195k": 124,
        "200k": 82,
        "350k": "1,684"
      },
      spousem: {
        "5k": 23,
        "10k": 14,
        "15k": 25,
        "20k": 39,
        "25k": 64,
        "30k": 69,
        "35k": 113,
        "40k": 120,
        "45k": 135,
        "50k": 135,
        "55k": 182,
        "60k": 153,
        "65k": 137,
        "70k": 114,
        "75k": 148,
        "80k": 141,
        "85k": 113,
        "90k": 121,
        "95k": 127,
        "100k": 163,
        "105k": 169,
        "110k": 152,
        "115k": 169,
        "120k": 110,
        "125k": 123,
        "130k": 137,
        "135k": 137,
        "140k": 124,
        "145k": 104,
        "150k": 97,
        "155k": 103,
        "160k": 82,
        "165k": 79,
        "170k": 62,
        "175k": 91,
        "180k": 53,
        "185k": 77,
        "190k": 54,
        "195k": 75,
        "200k": 48,
        "350k": "877"
      },
      childm: {
        "5k": 22,
        "10k": 17,
        "15k": 46,
        "20k": 88,
        "25k": 50,
        "30k": 96,
        "35k": 46,
        "40k": 54,
        "45k": 74,
        "50k": 79,
        "55k": 81,
        "60k": 95,
        "65k": 69,
        "70k": 57,
        "75k": 49,
        "80k": 45,
        "85k": 32,
        "90k": 66,
        "95k": 50,
        "100k": 53,
        "105k": 66,
        "110k": 41,
        "115k": 40,
        "120k": 40,
        "125k": 41,
        "130k": 38,
        "135k": 38,
        "140k": 22,
        "145k": 16,
        "150k": 23,
        "155k": 32,
        "160k": 26,
        "165k": 38,
        "170k": 19,
        "175k": 17,
        "180k": 15,
        "185k": 11,
        "190k": 5,
        "195k": 15,
        "200k": 11,
        "350k": "210"
      },
      otherRelativem: {
        "5k": 13,
        "10k": 10,
        "15k": 9,
        "20k": 11,
        "25k": 39,
        "30k": 22,
        "35k": 15,
        "40k": 43,
        "45k": 42,
        "50k": 41,
        "55k": 31,
        "60k": 38,
        "65k": 38,
        "70k": 40,
        "75k": 56,
        "80k": 23,
        "85k": 31,
        "90k": 27,
        "95k": 7,
        "100k": 26,
        "105k": 25,
        "110k": 36,
        "115k": 19,
        "120k": 22,
        "125k": 22,
        "130k": 17,
        "135k": 15,
        "140k": 10,
        "145k": 6,
        "150k": 9,
        "155k": 32,
        "160k": 5,
        "165k": 6,
        "170k": 6,
        "175k": 10,
        "180k": 15,
        "185k": 11,
        "190k": 14,
        "195k": 8,
        "200k": 7,
        "350k": "121"
      },
      nonRelativem: {
        "5k": 26,
        "10k": 13,
        "15k": 19,
        "20k": 25,
        "25k": 22,
        "30k": 36,
        "35k": 47,
        "40k": 35,
        "45k": 34,
        "50k": 56,
        "55k": 73,
        "60k": 41,
        "65k": 41,
        "70k": 70,
        "75k": 63,
        "80k": 55,
        "85k": 56,
        "90k": 53,
        "95k": 29,
        "100k": 30,
        "105k": 47,
        "110k": 19,
        "115k": 59,
        "120k": 37,
        "125k": 28,
        "130k": 48,
        "135k": 42,
        "140k": 15,
        "145k": 16,
        "150k": 14,
        "155k": 15,
        "160k": 17,
        "165k": 20,
        "170k": 18,
        "175k": 26,
        "180k": 12,
        "185k": 9,
        "190k": 3,
        "195k": 11,
        "200k": 11,
        "350k": "136"
      },
      houseLoitererfm: {
        "5k": 257,
        "10k": 143,
        "15k": 222,
        "20k": 301,
        "25k": 329,
        "30k": 450,
        "35k": 384,
        "40k": 375,
        "45k": 435,
        "50k": 393,
        "55k": 409,
        "60k": 303,
        "65k": 394,
        "70k": 340,
        "75k": 346,
        "80k": 241,
        "85k": 303,
        "90k": 259,
        "95k": 263,
        "100k": 225,
        "105k": 276,
        "110k": 198,
        "115k": 248,
        "120k": 170,
        "125k": 186,
        "130k": 168,
        "135k": 197,
        "140k": 168,
        "145k": 135,
        "150k": 125,
        "155k": 135,
        "160k": 117,
        "165k": 101,
        "170k": 85,
        "175k": 138,
        "180k": 79,
        "185k": 85,
        "190k": 71,
        "195k": 103,
        "200k": 51,
        "350k": "1,166"
      },
      spousefm: {
        "5k": 44,
        "10k": 25,
        "15k": 61,
        "20k": 59,
        "25k": 63,
        "30k": 85,
        "35k": 106,
        "40k": 147,
        "45k": 164,
        "50k": 146,
        "55k": 169,
        "60k": 185,
        "65k": 184,
        "70k": 160,
        "75k": 239,
        "80k": 231,
        "85k": 207,
        "90k": 186,
        "95k": 219,
        "100k": 199,
        "105k": 220,
        "110k": 230,
        "115k": 207,
        "120k": 200,
        "125k": 201,
        "130k": 154,
        "135k": 159,
        "140k": 160,
        "145k": 161,
        "150k": 136,
        "155k": 213,
        "160k": 104,
        "165k": 146,
        "170k": 104,
        "175k": 142,
        "180k": 111,
        "185k": 90,
        "190k": 81,
        "195k": 89,
        "200k": 68,
        "350k": "1,640"
      },
      childfm: {
        "5k": 9,
        "10k": 12,
        "15k": 15,
        "20k": 20,
        "25k": 27,
        "30k": 35,
        "35k": 45,
        "40k": 32,
        "45k": 58,
        "50k": 59,
        "55k": 52,
        "60k": 60,
        "65k": 26,
        "70k": 27,
        "75k": 23,
        "80k": 53,
        "85k": 36,
        "90k": 23,
        "95k": 12,
        "100k": 48,
        "105k": 35,
        "110k": 30,
        "115k": 26,
        "120k": 31,
        "125k": 32,
        "130k": 16,
        "135k": 30,
        "140k": 16,
        "145k": 19,
        "150k": 5,
        "155k": 16,
        "160k": 8,
        "165k": 27,
        "170k": 9,
        "175k": 16,
        "180k": 12,
        "185k": 16,
        "190k": 15,
        "195k": 9,
        "200k": 6,
        "350k": "136"
      },
      otherRelativefm: {
        "5k": 6,
        "10k": 3,
        "15k": 21,
        "20k": 16,
        "25k": 17,
        "30k": 28,
        "35k": 33,
        "40k": 47,
        "45k": 34,
        "50k": 35,
        "55k": 28,
        "60k": 40,
        "65k": 39,
        "70k": 21,
        "75k": 28,
        "80k": 28,
        "85k": 27,
        "90k": 24,
        "95k": 27,
        "100k": 39,
        "105k": 30,
        "110k": 15,
        "115k": 10,
        "120k": 15,
        "125k": 24,
        "130k": 20,
        "135k": 18,
        "140k": 5,
        "145k": 16,
        "150k": 14,
        "155k": 15,
        "160k": 11,
        "165k": 13,
        "170k": 4,
        "175k": 14,
        "180k": 12,
        "185k": 4,
        "190k": 10,
        "195k": 3,
        "200k": 2,
        "350k": "66"
      },
      nonRelativefm: {
        "5k": 17,
        "10k": 17,
        "15k": 1,
        "20k": 21,
        "25k": 14,
        "30k": 24,
        "35k": 27,
        "40k": 30,
        "45k": 31,
        "50k": 29,
        "55k": 25,
        "60k": 40,
        "65k": 26,
        "70k": 43,
        "75k": 20,
        "80k": 36,
        "85k": 30,
        "90k": 20,
        "95k": 33,
        "100k": 42,
        "105k": 32,
        "110k": 21,
        "115k": 37,
        "120k": 16,
        "125k": 32,
        "130k": 22,
        "135k": 19,
        "140k": 17,
        "145k": 9,
        "150k": 19,
        "155k": 21,
        "160k": 29,
        "165k": 13,
        "170k": 17,
        "175k": 25,
        "180k": 11,
        "185k": 6,
        "190k": 1,
        "195k": 5,
        "200k": 11,
        "350k": "132"
      }
    },
    "54": {
      houseLoitererm: {
        "5k": 225,
        "10k": 118,
        "15k": 194,
        "20k": 204,
        "25k": 216,
        "30k": 261,
        "35k": 257,
        "40k": 273,
        "45k": 340,
        "50k": 325,
        "55k": 317,
        "60k": 293,
        "65k": 372,
        "70k": 254,
        "75k": 290,
        "80k": 305,
        "85k": 359,
        "90k": 268,
        "95k": 241,
        "100k": 270,
        "105k": 290,
        "110k": 252,
        "115k": 224,
        "120k": 218,
        "125k": 291,
        "130k": 259,
        "135k": 182,
        "140k": 148,
        "145k": 155,
        "150k": 151,
        "155k": 201,
        "160k": 145,
        "165k": 168,
        "170k": 154,
        "175k": 182,
        "180k": 141,
        "185k": 131,
        "190k": 122,
        "195k": 115,
        "200k": 104,
        "350k": "2,059"
      },
      spousem: {
        "5k": 41,
        "10k": 18,
        "15k": 36,
        "20k": 33,
        "25k": 54,
        "30k": 56,
        "35k": 89,
        "40k": 106,
        "45k": 104,
        "50k": 95,
        "55k": 162,
        "60k": 133,
        "65k": 116,
        "70k": 86,
        "75k": 153,
        "80k": 130,
        "85k": 166,
        "90k": 149,
        "95k": 132,
        "100k": 92,
        "105k": 146,
        "110k": 144,
        "115k": 127,
        "120k": 118,
        "125k": 150,
        "130k": 114,
        "135k": 130,
        "140k": 94,
        "145k": 110,
        "150k": 113,
        "155k": 104,
        "160k": 83,
        "165k": 129,
        "170k": 100,
        "175k": 108,
        "180k": 105,
        "185k": 71,
        "190k": 56,
        "195k": 89,
        "200k": 42,
        "350k": "1,140"
      },
      childm: {
        "5k": 14,
        "10k": 6,
        "15k": 19,
        "20k": 29,
        "25k": 24,
        "30k": 54,
        "35k": 34,
        "40k": 32,
        "45k": 25,
        "50k": 31,
        "55k": 33,
        "60k": 46,
        "65k": 34,
        "70k": 33,
        "75k": 34,
        "80k": 19,
        "85k": 18,
        "90k": 32,
        "95k": 20,
        "100k": 14,
        "105k": 15,
        "110k": 13,
        "115k": 8,
        "120k": 13,
        "125k": 8,
        "130k": 3,
        "135k": 1,
        "140k": 11,
        "145k": 11,
        "150k": 7,
        "155k": 4,
        "160k": 10,
        "165k": 3,
        "170k": 3,
        "175k": 8,
        "180k": 3,
        "185k": 7,
        "190k": 2,
        "195k": 0,
        "200k": 0,
        "350k": "34"
      },
      otherRelativem: {
        "5k": 6,
        "10k": 14,
        "15k": 7,
        "20k": 21,
        "25k": 39,
        "30k": 29,
        "35k": 46,
        "40k": 8,
        "45k": 43,
        "50k": 26,
        "55k": 29,
        "60k": 32,
        "65k": 53,
        "70k": 43,
        "75k": 24,
        "80k": 26,
        "85k": 52,
        "90k": 47,
        "95k": 33,
        "100k": 53,
        "105k": 27,
        "110k": 10,
        "115k": 21,
        "120k": 47,
        "125k": 33,
        "130k": 19,
        "135k": 6,
        "140k": 17,
        "145k": 26,
        "150k": 15,
        "155k": 27,
        "160k": 7,
        "165k": 24,
        "170k": 14,
        "175k": 19,
        "180k": 12,
        "185k": 1,
        "190k": 6,
        "195k": 7,
        "200k": 3,
        "350k": "79"
      },
      nonRelativem: {
        "5k": 10,
        "10k": 4,
        "15k": 9,
        "20k": 20,
        "25k": 29,
        "30k": 11,
        "35k": 15,
        "40k": 21,
        "45k": 22,
        "50k": 40,
        "55k": 15,
        "60k": 32,
        "65k": 51,
        "70k": 36,
        "75k": 36,
        "80k": 41,
        "85k": 28,
        "90k": 28,
        "95k": 31,
        "100k": 52,
        "105k": 33,
        "110k": 13,
        "115k": 50,
        "120k": 33,
        "125k": 39,
        "130k": 28,
        "135k": 27,
        "140k": 6,
        "145k": 6,
        "150k": 22,
        "155k": 11,
        "160k": 13,
        "165k": 5,
        "170k": 11,
        "175k": 7,
        "180k": 15,
        "185k": 29,
        "190k": 7,
        "195k": 13,
        "200k": 9,
        "350k": "144"
      },
      houseLoitererfm: {
        "5k": 282,
        "10k": 223,
        "15k": 290,
        "20k": 357,
        "25k": 335,
        "30k": 337,
        "35k": 366,
        "40k": 377,
        "45k": 323,
        "50k": 369,
        "55k": 363,
        "60k": 322,
        "65k": 374,
        "70k": 305,
        "75k": 274,
        "80k": 281,
        "85k": 256,
        "90k": 265,
        "95k": 279,
        "100k": 180,
        "105k": 265,
        "110k": 204,
        "115k": 230,
        "120k": 179,
        "125k": 243,
        "130k": 161,
        "135k": 189,
        "140k": 164,
        "145k": 143,
        "150k": 122,
        "155k": 178,
        "160k": 124,
        "165k": 158,
        "170k": 105,
        "175k": 120,
        "180k": 125,
        "185k": 107,
        "190k": 77,
        "195k": 123,
        "200k": 48,
        "350k": "1,363"
      },
      spousefm: {
        "5k": 46,
        "10k": 17,
        "15k": 35,
        "20k": 57,
        "25k": 60,
        "30k": 83,
        "35k": 100,
        "40k": 89,
        "45k": 137,
        "50k": 157,
        "55k": 129,
        "60k": 178,
        "65k": 171,
        "70k": 159,
        "75k": 135,
        "80k": 175,
        "85k": 180,
        "90k": 191,
        "95k": 184,
        "100k": 185,
        "105k": 190,
        "110k": 171,
        "115k": 179,
        "120k": 165,
        "125k": 217,
        "130k": 209,
        "135k": 189,
        "140k": 147,
        "145k": 124,
        "150k": 125,
        "155k": 164,
        "160k": 135,
        "165k": 178,
        "170k": 122,
        "175k": 172,
        "180k": 122,
        "185k": 105,
        "190k": 91,
        "195k": 94,
        "200k": 95,
        "350k": "1,864"
      },
      childfm: {
        "5k": 7,
        "10k": 4,
        "15k": 5,
        "20k": 17,
        "25k": 12,
        "30k": 15,
        "35k": 17,
        "40k": 16,
        "45k": 25,
        "50k": 28,
        "55k": 22,
        "60k": 21,
        "65k": 34,
        "70k": 26,
        "75k": 11,
        "80k": 22,
        "85k": 24,
        "90k": 34,
        "95k": 13,
        "100k": 18,
        "105k": 5,
        "110k": 6,
        "115k": 13,
        "120k": 10,
        "125k": 22,
        "130k": 7,
        "135k": 8,
        "140k": 18,
        "145k": 2,
        "150k": 14,
        "155k": 15,
        "160k": 3,
        "165k": 11,
        "170k": 5,
        "175k": 9,
        "180k": 0,
        "185k": 5,
        "190k": 4,
        "195k": 2,
        "200k": 8,
        "350k": "39"
      },
      otherRelativefm: {
        "5k": 22,
        "10k": 11,
        "15k": 22,
        "20k": 28,
        "25k": 30,
        "30k": 30,
        "35k": 40,
        "40k": 17,
        "45k": 27,
        "50k": 57,
        "55k": 19,
        "60k": 47,
        "65k": 65,
        "70k": 68,
        "75k": 37,
        "80k": 51,
        "85k": 31,
        "90k": 32,
        "95k": 39,
        "100k": 54,
        "105k": 31,
        "110k": 12,
        "115k": 19,
        "120k": 24,
        "125k": 24,
        "130k": 20,
        "135k": 21,
        "140k": 19,
        "145k": 20,
        "150k": 19,
        "155k": 17,
        "160k": 12,
        "165k": 10,
        "170k": 26,
        "175k": 17,
        "180k": 23,
        "185k": 2,
        "190k": 7,
        "195k": 10,
        "200k": 3,
        "350k": "56"
      },
      nonRelativefm: {
        "5k": 2,
        "10k": 10,
        "15k": 7,
        "20k": 23,
        "25k": 19,
        "30k": 24,
        "35k": 30,
        "40k": 17,
        "45k": 21,
        "50k": 34,
        "55k": 22,
        "60k": 31,
        "65k": 34,
        "70k": 41,
        "75k": 35,
        "80k": 28,
        "85k": 33,
        "90k": 13,
        "95k": 23,
        "100k": 29,
        "105k": 14,
        "110k": 24,
        "115k": 10,
        "120k": 15,
        "125k": 31,
        "130k": 10,
        "135k": 6,
        "140k": 14,
        "145k": 12,
        "150k": 15,
        "155k": 14,
        "160k": 6,
        "165k": 0,
        "170k": 9,
        "175k": 18,
        "180k": 8,
        "185k": 9,
        "190k": 4,
        "195k": 10,
        "200k": 4,
        "350k": "130"
      }
    },
    "64": {
      houseLoitererm: {
        "5k": 333,
        "10k": 243,
        "15k": 402,
        "20k": 415,
        "25k": 446,
        "30k": 361,
        "35k": 384,
        "40k": 348,
        "45k": 446,
        "50k": 482,
        "55k": 421,
        "60k": 378,
        "65k": 390,
        "70k": 381,
        "75k": 373,
        "80k": 374,
        "85k": 301,
        "90k": 295,
        "95k": 305,
        "100k": 275,
        "105k": 267,
        "110k": 243,
        "115k": 259,
        "120k": 206,
        "125k": 267,
        "130k": 213,
        "135k": 230,
        "140k": 174,
        "145k": 186,
        "150k": 163,
        "155k": 199,
        "160k": 131,
        "165k": 147,
        "170k": 139,
        "175k": 123,
        "180k": 127,
        "185k": 126,
        "190k": 140,
        "195k": 90,
        "200k": 96,
        "350k": "2,017"
      },
      spousem: {
        "5k": 62,
        "10k": 29,
        "15k": 40,
        "20k": 66,
        "25k": 94,
        "30k": 109,
        "35k": 108,
        "40k": 104,
        "45k": 153,
        "50k": 119,
        "55k": 98,
        "60k": 135,
        "65k": 122,
        "70k": 146,
        "75k": 158,
        "80k": 137,
        "85k": 152,
        "90k": 146,
        "95k": 166,
        "100k": 152,
        "105k": 122,
        "110k": 137,
        "115k": 179,
        "120k": 107,
        "125k": 141,
        "130k": 92,
        "135k": 83,
        "140k": 124,
        "145k": 75,
        "150k": 75,
        "155k": 122,
        "160k": 84,
        "165k": 73,
        "170k": 59,
        "175k": 66,
        "180k": 58,
        "185k": 82,
        "190k": 84,
        "195k": 65,
        "200k": 49,
        "350k": "1,077"
      },
      childm: {
        "5k": 10,
        "10k": 4,
        "15k": 7,
        "20k": 33,
        "25k": 15,
        "30k": 27,
        "35k": 17,
        "40k": 24,
        "45k": 13,
        "50k": 31,
        "55k": 10,
        "60k": 21,
        "65k": 20,
        "70k": 6,
        "75k": 7,
        "80k": 7,
        "85k": 7,
        "90k": 5,
        "95k": 9,
        "100k": 10,
        "105k": 6,
        "110k": 7,
        "115k": 3,
        "120k": 9,
        "125k": 7,
        "130k": 1,
        "135k": 3,
        "140k": 6,
        "145k": 5,
        "150k": 7,
        "155k": 0,
        "160k": 0,
        "165k": 1,
        "170k": 0,
        "175k": 0,
        "180k": 6,
        "185k": 0,
        "190k": 2,
        "195k": 4,
        "200k": 0,
        "350k": "23"
      },
      otherRelativem: {
        "5k": 12,
        "10k": 6,
        "15k": 20,
        "20k": 15,
        "25k": 24,
        "30k": 14,
        "35k": 38,
        "40k": 22,
        "45k": 29,
        "50k": 54,
        "55k": 41,
        "60k": 23,
        "65k": 25,
        "70k": 23,
        "75k": 27,
        "80k": 36,
        "85k": 21,
        "90k": 26,
        "95k": 27,
        "100k": 38,
        "105k": 25,
        "110k": 20,
        "115k": 10,
        "120k": 25,
        "125k": 18,
        "130k": 15,
        "135k": 27,
        "140k": 23,
        "145k": 8,
        "150k": 19,
        "155k": 33,
        "160k": 28,
        "165k": 14,
        "170k": 18,
        "175k": 17,
        "180k": 8,
        "185k": 10,
        "190k": 0,
        "195k": 9,
        "200k": 0,
        "350k": "120"
      },
      nonRelativem: {
        "5k": 6,
        "10k": 6,
        "15k": 11,
        "20k": 37,
        "25k": 41,
        "30k": 32,
        "35k": 33,
        "40k": 28,
        "45k": 35,
        "50k": 38,
        "55k": 40,
        "60k": 34,
        "65k": 16,
        "70k": 17,
        "75k": 21,
        "80k": 25,
        "85k": 24,
        "90k": 21,
        "95k": 19,
        "100k": 25,
        "105k": 14,
        "110k": 16,
        "115k": 17,
        "120k": 35,
        "125k": 3,
        "130k": 22,
        "135k": 12,
        "140k": 6,
        "145k": 6,
        "150k": 1,
        "155k": 4,
        "160k": 13,
        "165k": 11,
        "170k": 12,
        "175k": 5,
        "180k": 6,
        "185k": 8,
        "190k": 1,
        "195k": 0,
        "200k": 7,
        "350k": "57"
      },
      houseLoitererfm: {
        "5k": 454,
        "10k": 376,
        "15k": 620,
        "20k": 452,
        "25k": 486,
        "30k": 403,
        "35k": 510,
        "40k": 353,
        "45k": 508,
        "50k": 494,
        "55k": 398,
        "60k": 358,
        "65k": 387,
        "70k": 294,
        "75k": 296,
        "80k": 317,
        "85k": 321,
        "90k": 307,
        "95k": 301,
        "100k": 259,
        "105k": 252,
        "110k": 224,
        "115k": 257,
        "120k": 173,
        "125k": 191,
        "130k": 167,
        "135k": 114,
        "140k": 162,
        "145k": 96,
        "150k": 111,
        "155k": 145,
        "160k": 93,
        "165k": 113,
        "170k": 97,
        "175k": 89,
        "180k": 70,
        "185k": 84,
        "190k": 92,
        "195k": 77,
        "200k": 73,
        "350k": "1,132"
      },
      spousefm: {
        "5k": 83,
        "10k": 67,
        "15k": 62,
        "20k": 72,
        "25k": 154,
        "30k": 158,
        "35k": 185,
        "40k": 191,
        "45k": 252,
        "50k": 212,
        "55k": 226,
        "60k": 197,
        "65k": 240,
        "70k": 223,
        "75k": 257,
        "80k": 214,
        "85k": 221,
        "90k": 200,
        "95k": 217,
        "100k": 231,
        "105k": 210,
        "110k": 186,
        "115k": 190,
        "120k": 169,
        "125k": 192,
        "130k": 133,
        "135k": 164,
        "140k": 114,
        "145k": 140,
        "150k": 141,
        "155k": 147,
        "160k": 81,
        "165k": 101,
        "170k": 84,
        "175k": 111,
        "180k": 79,
        "185k": 67,
        "190k": 122,
        "195k": 64,
        "200k": 67,
        "350k": "1,574"
      },
      childfm: {
        "5k": 6,
        "10k": 6,
        "15k": 34,
        "20k": 15,
        "25k": 18,
        "30k": 14,
        "35k": 34,
        "40k": 34,
        "45k": 18,
        "50k": 15,
        "55k": 13,
        "60k": 11,
        "65k": 23,
        "70k": 9,
        "75k": 12,
        "80k": 10,
        "85k": 4,
        "90k": 14,
        "95k": 14,
        "100k": 10,
        "105k": 2,
        "110k": 10,
        "115k": 0,
        "120k": 4,
        "125k": 3,
        "130k": 7,
        "135k": 4,
        "140k": 3,
        "145k": 0,
        "150k": 1,
        "155k": 3,
        "160k": 3,
        "165k": 1,
        "170k": 0,
        "175k": 3,
        "180k": 0,
        "185k": 0,
        "190k": 4,
        "195k": 4,
        "200k": 4,
        "350k": "19"
      },
      otherRelativefm: {
        "5k": 23,
        "10k": 17,
        "15k": 29,
        "20k": 30,
        "25k": 38,
        "30k": 39,
        "35k": 32,
        "40k": 22,
        "45k": 48,
        "50k": 62,
        "55k": 38,
        "60k": 44,
        "65k": 57,
        "70k": 39,
        "75k": 42,
        "80k": 41,
        "85k": 38,
        "90k": 41,
        "95k": 44,
        "100k": 46,
        "105k": 37,
        "110k": 32,
        "115k": 23,
        "120k": 33,
        "125k": 30,
        "130k": 27,
        "135k": 26,
        "140k": 33,
        "145k": 18,
        "150k": 13,
        "155k": 29,
        "160k": 12,
        "165k": 14,
        "170k": 24,
        "175k": 25,
        "180k": 15,
        "185k": 12,
        "190k": 8,
        "195k": 3,
        "200k": 4,
        "350k": "163"
      },
      nonRelativefm: {
        "5k": 10,
        "10k": 5,
        "15k": 11,
        "20k": 10,
        "25k": 34,
        "30k": 21,
        "35k": 32,
        "40k": 24,
        "45k": 35,
        "50k": 25,
        "55k": 26,
        "60k": 19,
        "65k": 22,
        "70k": 13,
        "75k": 21,
        "80k": 23,
        "85k": 17,
        "90k": 23,
        "95k": 7,
        "100k": 8,
        "105k": 15,
        "110k": 26,
        "115k": 21,
        "120k": 7,
        "125k": 23,
        "130k": 5,
        "135k": 6,
        "140k": 2,
        "145k": 12,
        "150k": 9,
        "155k": 3,
        "160k": 5,
        "165k": 15,
        "170k": 4,
        "175k": 9,
        "180k": 11,
        "185k": 13,
        "190k": 7,
        "195k": 13,
        "200k": 2,
        "350k": "49"
      }
    },
    "74": {
      houseLoitererm: {
        "5k": "222",
        "10k": "175",
        "15k": "472",
        "20k": "452",
        "25k": "383",
        "30k": "423",
        "35k": "452",
        "40k": "427",
        "45k": "465",
        "50k": "424",
        "55k": "391",
        "60k": "389",
        "65k": "415",
        "70k": "298",
        "75k": "328",
        "80k": "290",
        "85k": "290",
        "90k": "246",
        "95k": "249",
        "100k": "190",
        "105k": "214",
        "110k": "181",
        "115k": "172",
        "120k": "138",
        "125k": "161",
        "130k": "129",
        "135k": "146",
        "140k": "103",
        "145k": "118",
        "150k": "117",
        "155k": "115",
        "160k": "86",
        "165k": "108",
        "170k": "52",
        "175k": "98",
        "180k": "81",
        "185k": "61",
        "190k": "71",
        "195k": "30",
        "200k": "53",
        "350k": "1,125"
      },
      spousem: {
        "5k": "43",
        "10k": "28",
        "15k": "42",
        "20k": "44",
        "25k": "84",
        "30k": "101",
        "35k": "142",
        "40k": "138",
        "45k": "175",
        "50k": "147",
        "55k": "136",
        "60k": "152",
        "65k": "165",
        "70k": "119",
        "75k": "124",
        "80k": "145",
        "85k": "137",
        "90k": "107",
        "95k": "81",
        "100k": "110",
        "105k": "145",
        "110k": "97",
        "115k": "106",
        "120k": "84",
        "125k": "80",
        "130k": "80",
        "135k": "37",
        "140k": "65",
        "145k": "33",
        "150k": "64",
        "155k": "54",
        "160k": "67",
        "165k": "36",
        "170k": "35",
        "175k": "47",
        "180k": "17",
        "185k": "29",
        "190k": "32",
        "195k": "32",
        "200k": "26",
        "350k": "478"
      },
      childm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      otherRelativem: {
        "5k": "3",
        "10k": "17",
        "15k": "6",
        "20k": "7",
        "25k": "15",
        "30k": "21",
        "35k": "14",
        "40k": "10",
        "45k": "17",
        "50k": "20",
        "55k": "13",
        "60k": "14",
        "65k": "20",
        "70k": "16",
        "75k": "29",
        "80k": "17",
        "85k": "8",
        "90k": "10",
        "95k": "18",
        "100k": "23",
        "105k": "15",
        "110k": "18",
        "115k": "18",
        "120k": "6",
        "125k": "24",
        "130k": "11",
        "135k": "28",
        "140k": "5",
        "145k": "12",
        "150k": "7",
        "155k": "8",
        "160k": "9",
        "165k": "11",
        "170k": "0",
        "175k": "5",
        "180k": "5",
        "185k": "6",
        "190k": "4",
        "195k": "5",
        "200k": "2",
        "350k": "85"
      },
      nonRelativem: {
        "5k": "6",
        "10k": "7",
        "15k": "15",
        "20k": "12",
        "25k": "16",
        "30k": "11",
        "35k": "25",
        "40k": "10",
        "45k": "18",
        "50k": "17",
        "55k": "17",
        "60k": "17",
        "65k": "14",
        "70k": "9",
        "75k": "5",
        "80k": "19",
        "85k": "9",
        "90k": "5",
        "95k": "3",
        "100k": "8",
        "105k": "0",
        "110k": "6",
        "115k": "4",
        "120k": "9",
        "125k": "10",
        "130k": "3",
        "135k": "3",
        "140k": "6",
        "145k": "2",
        "150k": "13",
        "155k": "0",
        "160k": "5",
        "165k": "4",
        "170k": "3",
        "175k": "2",
        "180k": "8",
        "185k": "0",
        "190k": "3",
        "195k": "4",
        "200k": "0",
        "350k": "27"
      },
      houseLoitererfm: {
        "5k": "321",
        "10k": "327",
        "15k": "689",
        "20k": "612",
        "25k": "571",
        "30k": "611",
        "35k": "537",
        "40k": "459",
        "45k": "524",
        "50k": "402",
        "55k": "393",
        "60k": "383",
        "65k": "335",
        "70k": "236",
        "75k": "292",
        "80k": "231",
        "85k": "205",
        "90k": "210",
        "95k": "168",
        "100k": "174",
        "105k": "157",
        "110k": "169",
        "115k": "125",
        "120k": "112",
        "125k": "127",
        "130k": "86",
        "135k": "58",
        "140k": "60",
        "145k": "51",
        "150k": "91",
        "155k": "61",
        "160k": "59",
        "165k": "63",
        "170k": "43",
        "175k": "77",
        "180k": "34",
        "185k": "39",
        "190k": "32",
        "195k": "37",
        "200k": "32",
        "350k": "499"
      },
      spousefm: {
        "5k": "83",
        "10k": "36",
        "15k": "63",
        "20k": "120",
        "25k": "132",
        "30k": "176",
        "35k": "250",
        "40k": "243",
        "45k": "227",
        "50k": "236",
        "55k": "217",
        "60k": "233",
        "65k": "253",
        "70k": "192",
        "75k": "214",
        "80k": "184",
        "85k": "194",
        "90k": "151",
        "95k": "188",
        "100k": "129",
        "105k": "150",
        "110k": "112",
        "115k": "94",
        "120k": "86",
        "125k": "112",
        "130k": "107",
        "135k": "87",
        "140k": "95",
        "145k": "60",
        "150k": "70",
        "155k": "71",
        "160k": "63",
        "165k": "67",
        "170k": "38",
        "175k": "49",
        "180k": "68",
        "185k": "59",
        "190k": "38",
        "195k": "27",
        "200k": "36",
        "350k": "774"
      },
      childfm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      otherRelativefm: {
        "5k": "11",
        "10k": "14",
        "15k": "26",
        "20k": "40",
        "25k": "20",
        "30k": "30",
        "35k": "49",
        "40k": "28",
        "45k": "53",
        "50k": "43",
        "55k": "33",
        "60k": "35",
        "65k": "27",
        "70k": "62",
        "75k": "39",
        "80k": "30",
        "85k": "30",
        "90k": "22",
        "95k": "45",
        "100k": "22",
        "105k": "42",
        "110k": "29",
        "115k": "35",
        "120k": "24",
        "125k": "35",
        "130k": "18",
        "135k": "26",
        "140k": "22",
        "145k": "12",
        "150k": "17",
        "155k": "16",
        "160k": "34",
        "165k": "25",
        "170k": "7",
        "175k": "25",
        "180k": "18",
        "185k": "8",
        "190k": "2",
        "195k": "12",
        "200k": "3",
        "350k": "141"
      },
      nonRelativefm: {
        "5k": "7",
        "10k": "4",
        "15k": "3",
        "20k": "9",
        "25k": "21",
        "30k": "16",
        "35k": "7",
        "40k": "21",
        "45k": "19",
        "50k": "25",
        "55k": "11",
        "60k": "11",
        "65k": "8",
        "70k": "21",
        "75k": "5",
        "80k": "8",
        "85k": "15",
        "90k": "2",
        "95k": "3",
        "100k": "3",
        "105k": "10",
        "110k": "18",
        "115k": "7",
        "120k": "4",
        "125k": "4",
        "130k": "11",
        "135k": "6",
        "140k": "3",
        "145k": "2",
        "150k": "6",
        "155k": "7",
        "160k": "2",
        "165k": "2",
        "170k": "4",
        "175k": "2",
        "180k": "5",
        "185k": "7",
        "190k": "2",
        "195k": "4",
        "200k": "0",
        "350k": "32"
      }
    }
  },
  "2020": {
    17: {
      houseLoitererm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      spousem: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childm: {
        "5k": "278",
        "10k": "148",
        "15k": "216",
        "20k": "218",
        "25k": "295",
        "30k": "312",
        "35k": "336",
        "40k": "430",
        "45k": "380",
        "50k": "293",
        "55k": "441",
        "60k": "299",
        "65k": "312",
        "70k": "312",
        "75k": "334",
        "80k": "361",
        "85k": "290",
        "90k": "279",
        "95k": "278",
        "100k": "296",
        "105k": "304",
        "110k": "220",
        "115k": "255",
        "120k": "185",
        "125k": "238",
        "130k": "228",
        "135k": "220",
        "140k": "182",
        "145k": "129",
        "150k": "175",
        "155k": "198",
        "160k": "132",
        "165k": "136",
        "170k": "125",
        "175k": "133",
        "180k": "103",
        "185k": "109",
        "190k": "92",
        "195k": "114",
        "200k": "91",
        "350k": "1930"
      },
      otherRelativem: {
        "5k": "46",
        "10k": "31",
        "15k": "47",
        "20k": "33",
        "25k": "46",
        "30k": "42",
        "35k": "40",
        "40k": "40",
        "45k": "57",
        "50k": "50",
        "55k": "45",
        "60k": "49",
        "65k": 44,
        "70k": "37",
        "75k": 34,
        "80k": "40",
        "85k": "45",
        "90k": "26",
        "95k": "22",
        "100k": "20",
        "105k": "38",
        "110k": "14",
        "115k": "36",
        "120k": "40",
        "125k": "14",
        "130k": "15",
        "135k": "11",
        "140k": "15",
        "145k": "6",
        "150k": "10",
        "155k": "8",
        "160k": "3",
        "165k": "16",
        "170k": "9",
        "175k": "12",
        "180k": "5",
        "185k": "2",
        "190k": "9",
        "195k": "9",
        "200k": "9",
        "350k": "75"
      },
      nonRelativem: {
        "5k": "0",
        "10k": "2",
        "15k": "4",
        "20k": "16",
        "25k": "0",
        "30k": "0",
        "35k": "3",
        "40k": "11",
        "45k": "8",
        "50k": "3",
        "55k": "3",
        "60k": "6",
        "65k": "7",
        "70k": "4",
        "75k": "12",
        "80k": "4",
        "85k": "9",
        "90k": "4",
        "95k": "15",
        "100k": "1",
        "105k": "5",
        "110k": "15",
        "115k": "10",
        "120k": "11",
        "125k": "6",
        "130k": "11",
        "135k": "9",
        "140k": "4",
        "145k": "2",
        "150k": "4",
        "155k": "0",
        "160k": "4",
        "165k": "0",
        "170k": "2",
        "175k": "0",
        "180k": "0",
        "185k": "6",
        "190k": "0",
        "195k": "2",
        "200k": "0",
        "350k": "26"
      },
      houseLoitererfm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      spousefm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childfm: {
        "5k": "255",
        "10k": "158",
        "15k": "253",
        "20k": "231",
        "25k": "294",
        "30k": "378",
        "35k": "386",
        "40k": "341",
        "45k": "371",
        "50k": "310",
        "55k": "372",
        "60k": "306",
        "65k": "386",
        "70k": "272",
        "75k": "319",
        "80k": "344",
        "85k": "320",
        "90k": "258",
        "95k": "257",
        "100k": "254",
        "105k": "302",
        "110k": "227",
        "115k": "222",
        "120k": "190",
        "125k": "222",
        "130k": "184",
        "135k": "191",
        "140k": "193",
        "145k": "157",
        "150k": "122",
        "155k": "191",
        "160k": "134",
        "165k": "135",
        "170k": "121",
        "175k": "137",
        "180k": "119",
        "185k": "132",
        "190k": "70",
        "195k": "91",
        "200k": "87",
        "350k": "1735"
      },
      otherRelativefm: {
        "5k": "41",
        "10k": "36",
        "15k": 44,
        "20k": 54,
        "25k": "45",
        "30k": "47",
        "35k": "48",
        "40k": 44,
        "45k": "58",
        "50k": "42",
        "55k": "39",
        "60k": "37",
        "65k": "39",
        "70k": "25",
        "75k": "50",
        "80k": 34,
        "85k": "20",
        "90k": 34,
        "95k": "35",
        "100k": "14",
        "105k": "33",
        "110k": "18",
        "115k": "40",
        "120k": "15",
        "125k": "5",
        "130k": 24,
        "135k": "7",
        "140k": "11",
        "145k": "15",
        "150k": "11",
        "155k": "16",
        "160k": "7",
        "165k": "5",
        "170k": "12",
        "175k": "7",
        "180k": 17,
        "185k": "14",
        "190k": "7",
        "195k": "8",
        "200k": "9",
        "350k": "109"
      },
      nonRelativefm: {
        "5k": "3",
        "10k": "4",
        "15k": "6",
        "20k": "5",
        "25k": "7",
        "30k": "4",
        "35k": "0",
        "40k": "4",
        "45k": "4",
        "50k": "6",
        "55k": "16",
        "60k": "6",
        "65k": "3",
        "70k": "4",
        "75k": "3",
        "80k": "7",
        "85k": "7",
        "90k": "6",
        "95k": "13",
        "100k": "1",
        "105k": "3",
        "110k": "15",
        "115k": "1",
        "120k": "7",
        "125k": "0",
        "130k": "4",
        "135k": "5",
        "140k": "2",
        "145k": "8",
        "150k": "0",
        "155k": "0",
        "160k": "3",
        "165k": "3",
        "170k": "1",
        "175k": "4",
        "180k": "2",
        "185k": "0",
        "190k": "2",
        "195k": "0",
        "200k": "0",
        "350k": "25"
      }
    },
    21: {
      houseLoitererm: {
        "5k": "51",
        "10k": "37",
        "15k": "37",
        "20k": "52",
        "25k": "49",
        "30k": "39",
        "35k": "43",
        "40k": "58",
        "45k": "38",
        "50k": "53",
        "55k": "59",
        "60k": "27",
        "65k": "49",
        "70k": "30",
        "75k": "35",
        "80k": "23",
        "85k": "27",
        "90k": "31",
        "95k": "25",
        "100k": "30",
        "105k": 21,
        "110k": "13",
        "115k": "20",
        "120k": "4",
        "125k": "5",
        "130k": "18",
        "135k": "7",
        "140k": "2",
        "145k": "0",
        "150k": "7",
        "155k": "13",
        "160k": "11",
        "165k": "9",
        "170k": "8",
        "175k": "1",
        "180k": "0",
        "185k": "0",
        "190k": "0",
        "195k": "3",
        "200k": "0",
        "350k": "11"
      },
      spousem: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      childm: {
        "5k": "72",
        "10k": 44,
        "15k": "60",
        "20k": "103",
        "25k": "114",
        "30k": "135",
        "35k": "142",
        "40k": "168",
        "45k": "147",
        "50k": "160",
        "55k": "147",
        "60k": "150",
        "65k": "180",
        "70k": "174",
        "75k": "143",
        "80k": "154",
        "85k": "169",
        "90k": "133",
        "95k": "134",
        "100k": "153",
        "105k": "161",
        "110k": "145",
        "115k": "154",
        "120k": "130",
        "125k": "146",
        "130k": "127",
        "135k": "127",
        "140k": "103",
        "145k": "100",
        "150k": "91",
        "155k": "82",
        "160k": "67",
        "165k": "80",
        "170k": "102",
        "175k": "87",
        "180k": "40",
        "185k": "66",
        "190k": "59",
        "195k": "51",
        "200k": "51",
        "350k": "1097"
      },
      otherRelativem: {
        "5k": "15",
        "10k": "11",
        "15k": "20",
        "20k": "18",
        "25k": "18",
        "30k": "19",
        "35k": "22",
        "40k": "42",
        "45k": 24,
        "50k": "41",
        "55k": "33",
        "60k": "49",
        "65k": "30",
        "70k": "19",
        "75k": "38",
        "80k": "39",
        "85k": "41",
        "90k": "19",
        "95k": "30",
        "100k": "28",
        "105k": "26",
        "110k": "9",
        "115k": "19",
        "120k": "25",
        "125k": "23",
        "130k": "12",
        "135k": "20",
        "140k": "12",
        "145k": "6",
        "150k": 17,
        "155k": "10",
        "160k": "2",
        "165k": "10",
        "170k": "1",
        "175k": "5",
        "180k": "2",
        "185k": "0",
        "190k": "0",
        "195k": "15",
        "200k": "3",
        "350k": "41"
      },
      nonRelativem: {
        "5k": "41",
        "10k": "13",
        "15k": "7",
        "20k": "13",
        "25k": "26",
        "30k": "31",
        "35k": "43",
        "40k": "14",
        "45k": 21,
        "50k": "65",
        "55k": "37",
        "60k": "9",
        "65k": "40",
        "70k": "20",
        "75k": "47",
        "80k": "41",
        "85k": "18",
        "90k": "16",
        "95k": "10",
        "100k": "37",
        "105k": "22",
        "110k": "14",
        "115k": "15",
        "120k": "2",
        "125k": "1",
        "130k": "13",
        "135k": "9",
        "140k": "5",
        "145k": "2",
        "150k": "5",
        "155k": "6",
        "160k": "4",
        "165k": "4",
        "170k": "4",
        "175k": "7",
        "180k": "2",
        "185k": "0",
        "190k": "1",
        "195k": "1",
        "200k": "5",
        "350k": "26"
      },
      houseLoitererfm: {
        "5k": "99",
        "10k": "45",
        "15k": 44,
        "20k": 64,
        "25k": "75",
        "30k": "62",
        "35k": 54,
        "40k": "81",
        "45k": "61",
        "50k": "49",
        "55k": "35",
        "60k": "45",
        "65k": "28",
        "70k": "32",
        "75k": "27",
        "80k": 24,
        "85k": "25",
        "90k": "4",
        "95k": "16",
        "100k": "6",
        "105k": "26",
        "110k": "5",
        "115k": "8",
        "120k": "3",
        "125k": "4",
        "130k": "6",
        "135k": "2",
        "140k": "9",
        "145k": "4",
        "150k": "2",
        "155k": "9",
        "160k": "1",
        "165k": "5",
        "170k": "1",
        "175k": "2",
        "180k": "0",
        "185k": "0",
        "190k": "0",
        "195k": "0",
        "200k": "2",
        "350k": "18"
      },
      spousefm: {
        "5k": "0",
        "10k": "0",
        "15k": "6",
        "20k": "5",
        "25k": "7",
        "30k": "2",
        "35k": "22",
        "40k": "1",
        "45k": "12",
        "50k": "13",
        "55k": "11",
        "60k": "5",
        "65k": "0",
        "70k": "0",
        "75k": "6",
        "80k": "5",
        "85k": "4",
        "90k": "0",
        "95k": "1",
        "100k": "1",
        "105k": "0",
        "110k": "0",
        "115k": "4",
        "120k": "0",
        "125k": "0",
        "130k": "0",
        "135k": "0",
        "140k": "3",
        "145k": "0",
        "150k": "3",
        "155k": "0",
        "160k": "0",
        "165k": "4",
        "170k": "0",
        "175k": "0",
        "180k": "0",
        "185k": "0",
        "190k": "0",
        "195k": "0",
        "200k": "0",
        "350k": "0"
      },
      childfm: {
        "5k": 44,
        "10k": "35",
        "15k": "60",
        "20k": "94",
        "25k": "105",
        "30k": "83",
        "35k": "129",
        "40k": "170",
        "45k": "114",
        "50k": "170",
        "55k": "166",
        "60k": "107",
        "65k": "172",
        "70k": "188",
        "75k": "173",
        "80k": "187",
        "85k": "156",
        "90k": "125",
        "95k": "123",
        "100k": "130",
        "105k": "143",
        "110k": "144",
        "115k": "132",
        "120k": "124",
        "125k": "93",
        "130k": "113",
        "135k": "101",
        "140k": "93",
        "145k": "71",
        "150k": "96",
        "155k": "77",
        "160k": "94",
        "165k": "85",
        "170k": "89",
        "175k": "94",
        "180k": "55",
        "185k": "59",
        "190k": "47",
        "195k": "53",
        "200k": "61",
        "350k": "1089"
      },
      otherRelativefm: {
        "5k": "25",
        "10k": "10",
        "15k": "20",
        "20k": "28",
        "25k": "31",
        "30k": "40",
        "35k": "30",
        "40k": "20",
        "45k": "33",
        "50k": "11",
        "55k": 21,
        "60k": "16",
        "65k": "39",
        "70k": "14",
        "75k": "26",
        "80k": "9",
        "85k": "35",
        "90k": "35",
        "95k": "30",
        "100k": "18",
        "105k": "28",
        "110k": 17,
        "115k": "20",
        "120k": "9",
        "125k": "14",
        "130k": "13",
        "135k": "16",
        "140k": "7",
        "145k": "0",
        "150k": "7",
        "155k": "3",
        "160k": "0",
        "165k": "0",
        "170k": "8",
        "175k": "22",
        "180k": "10",
        "185k": "2",
        "190k": "2",
        "195k": "7",
        "200k": "9",
        "350k": "39"
      },
      nonRelativefm: {
        "5k": "38",
        "10k": "8",
        "15k": "12",
        "20k": "40",
        "25k": "39",
        "30k": "45",
        "35k": 21,
        "40k": "69",
        "45k": "51",
        "50k": "31",
        "55k": 54,
        "60k": 34,
        "65k": "36",
        "70k": "30",
        "75k": "30",
        "80k": "15",
        "85k": "22",
        "90k": "16",
        "95k": "12",
        "100k": "8",
        "105k": "9",
        "110k": "14",
        "115k": "9",
        "120k": "10",
        "125k": "8",
        "130k": "1",
        "135k": "6",
        "140k": "5",
        "145k": "11",
        "150k": "2",
        "155k": "8",
        "160k": "5",
        "165k": "7",
        "170k": "1",
        "175k": "11",
        "180k": "5",
        "185k": "2",
        "190k": "3",
        "195k": "3",
        "200k": "0",
        "350k": "33"
      }
    },
    24: {
      houseLoitererm: {
        "5k": 73,
        "10k": 47,
        "15k": 38,
        "20k": 59,
        "25k": 67,
        "30k": 102,
        "35k": 123,
        "40k": 73,
        "45k": 98,
        "50k": 74,
        "55k": 98,
        "60k": 95,
        "65k": 67,
        "70k": 58,
        "75k": 58,
        "80k": 59,
        "85k": 53,
        "90k": 40,
        "95k": 19,
        "100k": 57,
        "105k": 18,
        "110k": 11,
        "115k": 25,
        "120k": 25,
        "125k": 38,
        "130k": 8,
        "135k": 9,
        "140k": 4,
        "145k": 2,
        "150k": 0,
        "155k": 2,
        "160k": 8,
        "165k": 16,
        "170k": 5,
        "175k": 6,
        "180k": 8,
        "185k": 12,
        "190k": 5,
        "195k": 1,
        "200k": 5,
        "350k": 53
      },
      spousem: {
        "5k": 4,
        "10k": 0,
        "15k": 9,
        "20k": 4,
        "25k": 3,
        "30k": 20,
        "35k": 12,
        "40k": 3,
        "45k": 17,
        "50k": 15,
        "55k": 13,
        "60k": 18,
        "65k": 6,
        "70k": 12,
        "75k": 3,
        "80k": 0,
        "85k": 14,
        "90k": 6,
        "95k": 3,
        "100k": 5,
        "105k": 1,
        "110k": 8,
        "115k": 0,
        "120k": 0,
        "125k": 0,
        "130k": 0,
        "135k": 0,
        "140k": 0,
        "145k": 0,
        "150k": 0,
        "155k": 0,
        "160k": 3,
        "165k": 0,
        "170k": 0,
        "175k": 0,
        "180k": 0,
        "185k": 0,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 4
      },
      childm: {
        "5k": 22,
        "10k": 10,
        "15k": 37,
        "20k": 47,
        "25k": 76,
        "30k": 58,
        "35k": 61,
        "40k": 70,
        "45k": 70,
        "50k": 63,
        "55k": 68,
        "60k": 69,
        "65k": 86,
        "70k": 100,
        "75k": 70,
        "80k": 86,
        "85k": 52,
        "90k": 90,
        "95k": 62,
        "100k": 76,
        "105k": 96,
        "110k": 91,
        "115k": 85,
        "120k": 63,
        "125k": 71,
        "130k": 68,
        "135k": 64,
        "140k": 59,
        "145k": 55,
        "150k": 49,
        "155k": 57,
        "160k": 50,
        "165k": 37,
        "170k": 49,
        "175k": 31,
        "180k": 36,
        "185k": 45,
        "190k": 21,
        "195k": 43,
        "200k": 30,
        "350k": 595
      },
      otherRelativem: {
        "5k": 6,
        "10k": 4,
        "15k": 5,
        "20k": 11,
        "25k": 10,
        "30k": 8,
        "35k": 17,
        "40k": 24,
        "45k": 12,
        "50k": 18,
        "55k": 39,
        "60k": 19,
        "65k": 19,
        "70k": 24,
        "75k": 26,
        "80k": 23,
        "85k": 13,
        "90k": 26,
        "95k": 33,
        "100k": 15,
        "105k": 29,
        "110k": 10,
        "115k": 6,
        "120k": 17,
        "125k": 3,
        "130k": 4,
        "135k": 16,
        "140k": 18,
        "145k": 15,
        "150k": 7,
        "155k": 14,
        "160k": 6,
        "165k": 3,
        "170k": 5,
        "175k": 8,
        "180k": 12,
        "185k": 1,
        "190k": 7,
        "195k": 0,
        "200k": 1,
        "350k": 51
      },
      nonRelativem: {
        "5k": 19,
        "10k": 3,
        "15k": 17,
        "20k": 26,
        "25k": 25,
        "30k": 20,
        "35k": 44,
        "40k": 29,
        "45k": 51,
        "50k": 18,
        "55k": 31,
        "60k": 31,
        "65k": 13,
        "70k": 44,
        "75k": 59,
        "80k": 22,
        "85k": 28,
        "90k": 26,
        "95k": 35,
        "100k": 27,
        "105k": 23,
        "110k": 29,
        "115k": 13,
        "120k": 33,
        "125k": 17,
        "130k": 14,
        "135k": 13,
        "140k": 0,
        "145k": 0,
        "150k": 11,
        "155k": 5,
        "160k": 6,
        "165k": 25,
        "170k": 12,
        "175k": 15,
        "180k": 6,
        "185k": 7,
        "190k": 4,
        "195k": 4,
        "200k": 7,
        "350k": 118
      },
      houseLoitererfm: {
        "5k": 113,
        "10k": 52,
        "15k": 79,
        "20k": 106,
        "25k": 89,
        "30k": 116,
        "35k": 128,
        "40k": 96,
        "45k": 103,
        "50k": 93,
        "55k": 102,
        "60k": 96,
        "65k": 53,
        "70k": 67,
        "75k": 63,
        "80k": 39,
        "85k": 50,
        "90k": 18,
        "95k": 62,
        "100k": 42,
        "105k": 24,
        "110k": 24,
        "115k": 24,
        "120k": 13,
        "125k": 12,
        "130k": 10,
        "135k": 1,
        "140k": 11,
        "145k": 25,
        "150k": 13,
        "155k": 2,
        "160k": 8,
        "165k": 19,
        "170k": 0,
        "175k": 2,
        "180k": 8,
        "185k": 6,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 25
      },
      spousefm: {
        "5k": 9,
        "10k": 5,
        "15k": 4,
        "20k": 10,
        "25k": 7,
        "30k": 14,
        "35k": 19,
        "40k": 23,
        "45k": 24,
        "50k": 20,
        "55k": 18,
        "60k": 23,
        "65k": 23,
        "70k": 15,
        "75k": 32,
        "80k": 27,
        "85k": 16,
        "90k": 11,
        "95k": 14,
        "100k": 18,
        "105k": 15,
        "110k": 12,
        "115k": 11,
        "120k": 7,
        "125k": 22,
        "130k": 4,
        "135k": 4,
        "140k": 5,
        "145k": 3,
        "150k": 0,
        "155k": 2,
        "160k": 2,
        "165k": 0,
        "170k": 6,
        "175k": 0,
        "180k": 1,
        "185k": 3,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 17
      },
      childfm: {
        "5k": 26,
        "10k": 12,
        "15k": 15,
        "20k": 42,
        "25k": 39,
        "30k": 30,
        "35k": 66,
        "40k": 65,
        "45k": 32,
        "50k": 64,
        "55k": 61,
        "60k": 82,
        "65k": 90,
        "70k": 79,
        "75k": 64,
        "80k": 48,
        "85k": 57,
        "90k": 54,
        "95k": 66,
        "100k": 64,
        "105k": 71,
        "110k": 55,
        "115k": 67,
        "120k": 55,
        "125k": 29,
        "130k": 49,
        "135k": 56,
        "140k": 32,
        "145k": 37,
        "150k": 48,
        "155k": 59,
        "160k": 29,
        "165k": 32,
        "170k": 30,
        "175k": 45,
        "180k": 27,
        "185k": 22,
        "190k": 17,
        "195k": 31,
        "200k": 20,
        "350k": 565
      },
      otherRelativefm: {
        "5k": 14,
        "10k": 7,
        "15k": 3,
        "20k": 14,
        "25k": 6,
        "30k": 13,
        "35k": 18,
        "40k": 27,
        "45k": 9,
        "50k": 13,
        "55k": 25,
        "60k": 14,
        "65k": 15,
        "70k": 14,
        "75k": 12,
        "80k": 13,
        "85k": 16,
        "90k": 25,
        "95k": 20,
        "100k": 18,
        "105k": 18,
        "110k": 6,
        "115k": 17,
        "120k": 24,
        "125k": 16,
        "130k": 8,
        "135k": 2,
        "140k": 18,
        "145k": 7,
        "150k": 5,
        "155k": 2,
        "160k": 4,
        "165k": 5,
        "170k": 1,
        "175k": 4,
        "180k": 0,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 2,
        "350k": 32
      },
      nonRelativefm: {
        "5k": 25,
        "10k": 25,
        "15k": 15,
        "20k": 27,
        "25k": 42,
        "30k": 27,
        "35k": 38,
        "40k": 33,
        "45k": 65,
        "50k": 48,
        "55k": 61,
        "60k": 73,
        "65k": 43,
        "70k": 38,
        "75k": 61,
        "80k": 46,
        "85k": 30,
        "90k": 37,
        "95k": 56,
        "100k": 21,
        "105k": 20,
        "110k": 16,
        "115k": 27,
        "120k": 23,
        "125k": 25,
        "130k": 19,
        "135k": 3,
        "140k": 11,
        "145k": 5,
        "150k": 12,
        "155k": 8,
        "160k": 4,
        "165k": 12,
        "170k": 13,
        "175k": 11,
        "180k": 2,
        "185k": 4,
        "190k": 7,
        "195k": 9,
        "200k": 5,
        "350k": 71
      }
    },
    29: {
      houseLoitererm: {
        "5k": 87,
        "10k": 47,
        "15k": 55,
        "20k": 89,
        "25k": 129,
        "30k": 128,
        "35k": 192,
        "40k": 233,
        "45k": 150,
        "50k": 212,
        "55k": 207,
        "60k": 208,
        "65k": 213,
        "70k": 195,
        "75k": 213,
        "80k": 165,
        "85k": 215,
        "90k": 130,
        "95k": 121,
        "100k": 119,
        "105k": 158,
        "110k": 112,
        "115k": 119,
        "120k": 72,
        "125k": 114,
        "130k": 74,
        "135k": 75,
        "140k": 54,
        "145k": 50,
        "150k": 63,
        "155k": 44,
        "160k": 29,
        "165k": 34,
        "170k": 30,
        "175k": 46,
        "180k": 52,
        "185k": 26,
        "190k": 29,
        "195k": 18,
        "200k": 15,
        "350k": 295
      },
      spousem: {
        "5k": 10,
        "10k": 0,
        "15k": 17,
        "20k": 12,
        "25k": 22,
        "30k": 29,
        "35k": 18,
        "40k": 42,
        "45k": 32,
        "50k": 36,
        "55k": 48,
        "60k": 36,
        "65k": 25,
        "70k": 47,
        "75k": 35,
        "80k": 33,
        "85k": 44,
        "90k": 34,
        "95k": 35,
        "100k": 34,
        "105k": 25,
        "110k": 40,
        "115k": 21,
        "120k": 18,
        "125k": 26,
        "130k": 16,
        "135k": 16,
        "140k": 25,
        "145k": 5,
        "150k": 12,
        "155k": 7,
        "160k": 10,
        "165k": 12,
        "170k": 5,
        "175k": 18,
        "180k": 12,
        "185k": 11,
        "190k": 3,
        "195k": 9,
        "200k": 0,
        "350k": 71
      },
      childm: {
        "5k": 28,
        "10k": 23,
        "15k": 24,
        "20k": 43,
        "25k": 83,
        "30k": 38,
        "35k": 62,
        "40k": 51,
        "45k": 93,
        "50k": 92,
        "55k": 87,
        "60k": 128,
        "65k": 84,
        "70k": 91,
        "75k": 79,
        "80k": 81,
        "85k": 111,
        "90k": 38,
        "95k": 61,
        "100k": 71,
        "105k": 80,
        "110k": 88,
        "115k": 77,
        "120k": 33,
        "125k": 58,
        "130k": 62,
        "135k": 53,
        "140k": 64,
        "145k": 61,
        "150k": 50,
        "155k": 61,
        "160k": 32,
        "165k": 75,
        "170k": 41,
        "175k": 78,
        "180k": 48,
        "185k": 28,
        "190k": 34,
        "195k": 42,
        "200k": 13,
        "350k": 588
      },
      otherRelativem: {
        "5k": 10,
        "10k": 15,
        "15k": 11,
        "20k": 19,
        "25k": 37,
        "30k": 19,
        "35k": 36,
        "40k": 31,
        "45k": 52,
        "50k": 40,
        "55k": 56,
        "60k": 43,
        "65k": 31,
        "70k": 20,
        "75k": 23,
        "80k": 54,
        "85k": 40,
        "90k": 39,
        "95k": 40,
        "100k": 27,
        "105k": 31,
        "110k": 49,
        "115k": 32,
        "120k": 18,
        "125k": 28,
        "130k": 13,
        "135k": 12,
        "140k": 19,
        "145k": 20,
        "150k": 12,
        "155k": 9,
        "160k": 9,
        "165k": 2,
        "170k": 13,
        "175k": 10,
        "180k": 11,
        "185k": 1,
        "190k": 7,
        "195k": 6,
        "200k": 5,
        "350k": 84
      },
      nonRelativem: {
        "5k": 21,
        "10k": 6,
        "15k": 7,
        "20k": 22,
        "25k": 29,
        "30k": 43,
        "35k": 38,
        "40k": 39,
        "45k": 43,
        "50k": 54,
        "55k": 60,
        "60k": 66,
        "65k": 68,
        "70k": 63,
        "75k": 61,
        "80k": 53,
        "85k": 81,
        "90k": 53,
        "95k": 58,
        "100k": 65,
        "105k": 72,
        "110k": 40,
        "115k": 51,
        "120k": 43,
        "125k": 35,
        "130k": 38,
        "135k": 30,
        "140k": 31,
        "145k": 23,
        "150k": 39,
        "155k": 36,
        "160k": 35,
        "165k": 17,
        "170k": 5,
        "175k": 23,
        "180k": 15,
        "185k": 13,
        "190k": 24,
        "195k": 9,
        "200k": 9,
        "350k": 332
      },
      houseLoitererfm: {
        "5k": 207,
        "10k": 128,
        "15k": 154,
        "20k": 166,
        "25k": 208,
        "30k": 211,
        "35k": 224,
        "40k": 234,
        "45k": 232,
        "50k": 194,
        "55k": 215,
        "60k": 188,
        "65k": 189,
        "70k": 194,
        "75k": 172,
        "80k": 171,
        "85k": 136,
        "90k": 106,
        "95k": 146,
        "100k": 93,
        "105k": 144,
        "110k": 130,
        "115k": 64,
        "120k": 79,
        "125k": 68,
        "130k": 62,
        "135k": 60,
        "140k": 57,
        "145k": 53,
        "150k": 32,
        "155k": 23,
        "160k": 36,
        "165k": 33,
        "170k": 27,
        "175k": 40,
        "180k": 25,
        "185k": 24,
        "190k": 28,
        "195k": 30,
        "200k": 6,
        "350k": 242
      },
      spousefm: {
        "5k": 15,
        "10k": 5,
        "15k": 12,
        "20k": 23,
        "25k": 28,
        "30k": 43,
        "35k": 46,
        "40k": 42,
        "45k": 62,
        "50k": 73,
        "55k": 73,
        "60k": 70,
        "65k": 70,
        "70k": 86,
        "75k": 98,
        "80k": 57,
        "85k": 88,
        "90k": 39,
        "95k": 57,
        "100k": 46,
        "105k": 70,
        "110k": 39,
        "115k": 66,
        "120k": 50,
        "125k": 41,
        "130k": 43,
        "135k": 45,
        "140k": 32,
        "145k": 23,
        "150k": 45,
        "155k": 13,
        "160k": 15,
        "165k": 37,
        "170k": 19,
        "175k": 32,
        "180k": 33,
        "185k": 27,
        "190k": 7,
        "195k": 22,
        "200k": 5,
        "350k": 159
      },
      childfm: {
        "5k": 24,
        "10k": 16,
        "15k": 22,
        "20k": 11,
        "25k": 27,
        "30k": 20,
        "35k": 44,
        "40k": 54,
        "45k": 37,
        "50k": 91,
        "55k": 79,
        "60k": 40,
        "65k": 45,
        "70k": 56,
        "75k": 49,
        "80k": 60,
        "85k": 64,
        "90k": 54,
        "95k": 50,
        "100k": 60,
        "105k": 35,
        "110k": 63,
        "115k": 60,
        "120k": 38,
        "125k": 62,
        "130k": 46,
        "135k": 14,
        "140k": 41,
        "145k": 25,
        "150k": 29,
        "155k": 34,
        "160k": 22,
        "165k": 51,
        "170k": 25,
        "175k": 26,
        "180k": 20,
        "185k": 26,
        "190k": 9,
        "195k": 11,
        "200k": 22,
        "350k": 375
      },
      otherRelativefm: {
        "5k": 3,
        "10k": 3,
        "15k": 3,
        "20k": 9,
        "25k": 15,
        "30k": 16,
        "35k": 22,
        "40k": 20,
        "45k": 15,
        "50k": 36,
        "55k": 21,
        "60k": 27,
        "65k": 49,
        "70k": 12,
        "75k": 20,
        "80k": 15,
        "85k": 22,
        "90k": 6,
        "95k": 24,
        "100k": 11,
        "105k": 21,
        "110k": 7,
        "115k": 22,
        "120k": 5,
        "125k": 16,
        "130k": 9,
        "135k": 9,
        "140k": 9,
        "145k": 21,
        "150k": 5,
        "155k": 2,
        "160k": 11,
        "165k": 10,
        "170k": 0,
        "175k": 7,
        "180k": 4,
        "185k": 5,
        "190k": 13,
        "195k": 3,
        "200k": 1,
        "350k": 73
      },
      nonRelativefm: {
        "5k": 19,
        "10k": 3,
        "15k": 15,
        "20k": 13,
        "25k": 23,
        "30k": 54,
        "35k": 46,
        "40k": 52,
        "45k": 52,
        "50k": 32,
        "55k": 70,
        "60k": 58,
        "65k": 63,
        "70k": 64,
        "75k": 76,
        "80k": 47,
        "85k": 58,
        "90k": 78,
        "95k": 77,
        "100k": 89,
        "105k": 59,
        "110k": 62,
        "115k": 88,
        "120k": 26,
        "125k": 43,
        "130k": 24,
        "135k": 24,
        "140k": 48,
        "145k": 34,
        "150k": 15,
        "155k": 26,
        "160k": 28,
        "165k": 20,
        "170k": 24,
        "175k": 27,
        "180k": 43,
        "185k": 29,
        "190k": 26,
        "195k": 10,
        "200k": 14,
        "350k": 223
      }
    },
    34: {
      houseLoitererm: {
        "5k": 94,
        "10k": 55,
        "15k": 63,
        "20k": 87,
        "25k": 192,
        "30k": 148,
        "35k": 167,
        "40k": 179,
        "45k": 260,
        "50k": 183,
        "55k": 300,
        "60k": 188,
        "65k": 194,
        "70k": 214,
        "75k": 205,
        "80k": 200,
        "85k": 227,
        "90k": 214,
        "95k": 214,
        "100k": 156,
        "105k": 170,
        "110k": 130,
        "115k": 122,
        "120k": 62,
        "125k": 132,
        "130k": 96,
        "135k": 108,
        "140k": 74,
        "145k": 86,
        "150k": 76,
        "155k": 91,
        "160k": 80,
        "165k": 62,
        "170k": 68,
        "175k": 54,
        "180k": 45,
        "185k": 65,
        "190k": 29,
        "195k": 46,
        "200k": 28,
        "350k": 596
      },
      spousem: {
        "5k": 29,
        "10k": 13,
        "15k": 13,
        "20k": 32,
        "25k": 23,
        "30k": 40,
        "35k": 64,
        "40k": 73,
        "45k": 66,
        "50k": 72,
        "55k": 54,
        "60k": 74,
        "65k": 46,
        "70k": 59,
        "75k": 83,
        "80k": 95,
        "85k": 61,
        "90k": 73,
        "95k": 53,
        "100k": 53,
        "105k": 111,
        "110k": 71,
        "115k": 55,
        "120k": 48,
        "125k": 43,
        "130k": 44,
        "135k": 39,
        "140k": 29,
        "145k": 27,
        "150k": 47,
        "155k": 44,
        "160k": 29,
        "165k": 29,
        "170k": 28,
        "175k": 28,
        "180k": 18,
        "185k": 33,
        "190k": 21,
        "195k": 23,
        "200k": 15,
        "350k": 229
      },
      childm: {
        "5k": 14,
        "10k": 20,
        "15k": 13,
        "20k": 60,
        "25k": 57,
        "30k": 40,
        "35k": 25,
        "40k": 11,
        "45k": 58,
        "50k": 27,
        "55k": 67,
        "60k": 70,
        "65k": 63,
        "70k": 73,
        "75k": 102,
        "80k": 49,
        "85k": 48,
        "90k": 26,
        "95k": 32,
        "100k": 29,
        "105k": 35,
        "110k": 46,
        "115k": 42,
        "120k": 11,
        "125k": 62,
        "130k": 44,
        "135k": 35,
        "140k": 20,
        "145k": 36,
        "150k": 21,
        "155k": 56,
        "160k": 25,
        "165k": 25,
        "170k": 33,
        "175k": 12,
        "180k": 26,
        "185k": 9,
        "190k": 19,
        "195k": 25,
        "200k": 17,
        "350k": 208
      },
      otherRelativem: {
        "5k": 5,
        "10k": 4,
        "15k": 9,
        "20k": 5,
        "25k": 27,
        "30k": 21,
        "35k": 10,
        "40k": 7,
        "45k": 17,
        "50k": 19,
        "55k": 26,
        "60k": 38,
        "65k": 23,
        "70k": 23,
        "75k": 17,
        "80k": 30,
        "85k": 19,
        "90k": 17,
        "95k": 4,
        "100k": 12,
        "105k": 25,
        "110k": 23,
        "115k": 23,
        "120k": 10,
        "125k": 21,
        "130k": 8,
        "135k": 10,
        "140k": 8,
        "145k": 26,
        "150k": 17,
        "155k": 9,
        "160k": 2,
        "165k": 6,
        "170k": 14,
        "175k": 3,
        "180k": 11,
        "185k": 7,
        "190k": 5,
        "195k": 2,
        "200k": 13,
        "350k": 99
      },
      nonRelativem: {
        "5k": 15,
        "10k": 5,
        "15k": 21,
        "20k": 13,
        "25k": 29,
        "30k": 23,
        "35k": 44,
        "40k": 40,
        "45k": 34,
        "50k": 47,
        "55k": 34,
        "60k": 34,
        "65k": 27,
        "70k": 44,
        "75k": 41,
        "80k": 46,
        "85k": 36,
        "90k": 32,
        "95k": 27,
        "100k": 32,
        "105k": 38,
        "110k": 34,
        "115k": 32,
        "120k": 24,
        "125k": 22,
        "130k": 16,
        "135k": 24,
        "140k": 25,
        "145k": 15,
        "150k": 11,
        "155k": 10,
        "160k": 24,
        "165k": 9,
        "170k": 19,
        "175k": 10,
        "180k": 12,
        "185k": 21,
        "190k": 6,
        "195k": 13,
        "200k": 4,
        "350k": 138
      },
      houseLoitererfm: {
        "5k": 231,
        "10k": 138,
        "15k": 168,
        "20k": 181,
        "25k": 226,
        "30k": 203,
        "35k": 211,
        "40k": 228,
        "45k": 241,
        "50k": 216,
        "55k": 205,
        "60k": 203,
        "65k": 207,
        "70k": 170,
        "75k": 202,
        "80k": 215,
        "85k": 134,
        "90k": 138,
        "95k": 123,
        "100k": 87,
        "105k": 140,
        "110k": 119,
        "115k": 105,
        "120k": 84,
        "125k": 78,
        "130k": 78,
        "135k": 80,
        "140k": 71,
        "145k": 48,
        "150k": 64,
        "155k": 75,
        "160k": 73,
        "165k": 38,
        "170k": 52,
        "175k": 46,
        "180k": 41,
        "185k": 41,
        "190k": 22,
        "195k": 34,
        "200k": 48,
        "350k": 383
      },
      spousefm: {
        "5k": 38,
        "10k": 17,
        "15k": 20,
        "20k": 32,
        "25k": 55,
        "30k": 47,
        "35k": 59,
        "40k": 52,
        "45k": 81,
        "50k": 93,
        "55k": 101,
        "60k": 97,
        "65k": 94,
        "70k": 105,
        "75k": 138,
        "80k": 114,
        "85k": 132,
        "90k": 146,
        "95k": 124,
        "100k": 119,
        "105k": 117,
        "110k": 81,
        "115k": 103,
        "120k": 49,
        "125k": 82,
        "130k": 75,
        "135k": 86,
        "140k": 59,
        "145k": 72,
        "150k": 64,
        "155k": 69,
        "160k": 45,
        "165k": 35,
        "170k": 53,
        "175k": 37,
        "180k": 43,
        "185k": 39,
        "190k": 38,
        "195k": 33,
        "200k": 25,
        "350k": 523
      },
      childfm: {
        "5k": 17,
        "10k": 14,
        "15k": 17,
        "20k": 16,
        "25k": 13,
        "30k": 40,
        "35k": 35,
        "40k": 25,
        "45k": 28,
        "50k": 21,
        "55k": 28,
        "60k": 46,
        "65k": 42,
        "70k": 42,
        "75k": 30,
        "80k": 28,
        "85k": 24,
        "90k": 22,
        "95k": 41,
        "100k": 33,
        "105k": 29,
        "110k": 14,
        "115k": 22,
        "120k": 33,
        "125k": 10,
        "130k": 10,
        "135k": 18,
        "140k": 26,
        "145k": 9,
        "150k": 11,
        "155k": 18,
        "160k": 2,
        "165k": 12,
        "170k": 8,
        "175k": 25,
        "180k": 2,
        "185k": 18,
        "190k": 4,
        "195k": 21,
        "200k": 18,
        "350k": 158
      },
      otherRelativefm: {
        "5k": 5,
        "10k": 4,
        "15k": 9,
        "20k": 2,
        "25k": 4,
        "30k": 10,
        "35k": 22,
        "40k": 7,
        "45k": 16,
        "50k": 10,
        "55k": 24,
        "60k": 17,
        "65k": 5,
        "70k": 6,
        "75k": 9,
        "80k": 15,
        "85k": 14,
        "90k": 15,
        "95k": 17,
        "100k": 5,
        "105k": 9,
        "110k": 7,
        "115k": 15,
        "120k": 11,
        "125k": 13,
        "130k": 6,
        "135k": 7,
        "140k": 5,
        "145k": 8,
        "150k": 6,
        "155k": 8,
        "160k": 7,
        "165k": 8,
        "170k": 9,
        "175k": 2,
        "180k": 10,
        "185k": 2,
        "190k": 5,
        "195k": 6,
        "200k": 0,
        "350k": 59
      },
      nonRelativefm: {
        "5k": 14,
        "10k": 6,
        "15k": 11,
        "20k": 16,
        "25k": 24,
        "30k": 27,
        "35k": 15,
        "40k": 30,
        "45k": 29,
        "50k": 43,
        "55k": 51,
        "60k": 38,
        "65k": 28,
        "70k": 35,
        "75k": 25,
        "80k": 40,
        "85k": 23,
        "90k": 34,
        "95k": 48,
        "100k": 20,
        "105k": 35,
        "110k": 42,
        "115k": 21,
        "120k": 16,
        "125k": 35,
        "130k": 16,
        "135k": 25,
        "140k": 23,
        "145k": 18,
        "150k": 11,
        "155k": 17,
        "160k": 21,
        "165k": 9,
        "170k": 4,
        "175k": 25,
        "180k": 3,
        "185k": 12,
        "190k": 5,
        "195k": 10,
        "200k": 5,
        "350k": 146
      }
    },
    44: {
      houseLoitererm: {
        "5k": 229,
        "10k": 173,
        "15k": 169,
        "20k": 171,
        "25k": 233,
        "30k": 232,
        "35k": 317,
        "40k": 346,
        "45k": 319,
        "50k": 327,
        "55k": 421,
        "60k": 303,
        "65k": 362,
        "70k": 273,
        "75k": 372,
        "80k": 353,
        "85k": 361,
        "90k": 272,
        "95k": 324,
        "100k": 292,
        "105k": 358,
        "110k": 239,
        "115k": 303,
        "120k": 180,
        "125k": 300,
        "130k": 194,
        "135k": 203,
        "140k": 203,
        "145k": 176,
        "150k": 169,
        "155k": 211,
        "160k": 137,
        "165k": 157,
        "170k": 108,
        "175k": 134,
        "180k": 127,
        "185k": 140,
        "190k": 95,
        "195k": 82,
        "200k": 56,
        "350k": 1855
      },
      spousem: {
        "5k": 41,
        "10k": 12,
        "15k": 55,
        "20k": 53,
        "25k": 89,
        "30k": 81,
        "35k": 114,
        "40k": 113,
        "45k": 151,
        "50k": 133,
        "55k": 142,
        "60k": 109,
        "65k": 191,
        "70k": 137,
        "75k": 165,
        "80k": 192,
        "85k": 170,
        "90k": 108,
        "95k": 156,
        "100k": 145,
        "105k": 157,
        "110k": 138,
        "115k": 149,
        "120k": 149,
        "125k": 124,
        "130k": 109,
        "135k": 123,
        "140k": 110,
        "145k": 130,
        "150k": 94,
        "155k": 117,
        "160k": 86,
        "165k": 72,
        "170k": 56,
        "175k": 94,
        "180k": 67,
        "185k": 66,
        "190k": 53,
        "195k": 49,
        "200k": 66,
        "350k": 858
      },
      childm: {
        "5k": 23,
        "10k": 8,
        "15k": 46,
        "20k": 71,
        "25k": 63,
        "30k": 63,
        "35k": 51,
        "40k": 47,
        "45k": 53,
        "50k": 76,
        "55k": 76,
        "60k": 36,
        "65k": 66,
        "70k": 50,
        "75k": 50,
        "80k": 84,
        "85k": 54,
        "90k": 15,
        "95k": 31,
        "100k": 50,
        "105k": 45,
        "110k": 70,
        "115k": 45,
        "120k": 44,
        "125k": 40,
        "130k": 34,
        "135k": 33,
        "140k": 33,
        "145k": 22,
        "150k": 43,
        "155k": 19,
        "160k": 29,
        "165k": 18,
        "170k": 19,
        "175k": 26,
        "180k": 18,
        "185k": 14,
        "190k": 14,
        "195k": 9,
        "200k": 15,
        "350k": 219
      },
      otherRelativem: {
        "5k": 11,
        "10k": 8,
        "15k": 10,
        "20k": 24,
        "25k": 22,
        "30k": 22,
        "35k": 63,
        "40k": 47,
        "45k": 36,
        "50k": 31,
        "55k": 40,
        "60k": 26,
        "65k": 31,
        "70k": 23,
        "75k": 62,
        "80k": 31,
        "85k": 43,
        "90k": 27,
        "95k": 50,
        "100k": 27,
        "105k": 22,
        "110k": 24,
        "115k": 37,
        "120k": 13,
        "125k": 14,
        "130k": 18,
        "135k": 15,
        "140k": 9,
        "145k": 8,
        "150k": 13,
        "155k": 10,
        "160k": 7,
        "165k": 15,
        "170k": 14,
        "175k": 10,
        "180k": 8,
        "185k": 3,
        "190k": 6,
        "195k": 11,
        "200k": 6,
        "350k": 104
      },
      nonRelativem: {
        "5k": 20,
        "10k": 22,
        "15k": 19,
        "20k": 24,
        "25k": 44,
        "30k": 21,
        "35k": 42,
        "40k": 59,
        "45k": 49,
        "50k": 49,
        "55k": 58,
        "60k": 66,
        "65k": 68,
        "70k": 49,
        "75k": 58,
        "80k": 75,
        "85k": 35,
        "90k": 49,
        "95k": 57,
        "100k": 43,
        "105k": 30,
        "110k": 29,
        "115k": 41,
        "120k": 34,
        "125k": 46,
        "130k": 20,
        "135k": 21,
        "140k": 16,
        "145k": 16,
        "150k": 14,
        "155k": 15,
        "160k": 27,
        "165k": 20,
        "170k": 11,
        "175k": 20,
        "180k": 21,
        "185k": 12,
        "190k": 6,
        "195k": 11,
        "200k": 17,
        "350k": 151
      },
      houseLoitererfm: {
        "5k": 369,
        "10k": 203,
        "15k": 302,
        "20k": 301,
        "25k": 339,
        "30k": 364,
        "35k": 446,
        "40k": 409,
        "45k": 427,
        "50k": 362,
        "55k": 406,
        "60k": 322,
        "65k": 407,
        "70k": 334,
        "75k": 344,
        "80k": 370,
        "85k": 291,
        "90k": 257,
        "95k": 259,
        "100k": 271,
        "105k": 251,
        "110k": 236,
        "115k": 205,
        "120k": 214,
        "125k": 230,
        "130k": 157,
        "135k": 156,
        "140k": 131,
        "145k": 162,
        "150k": 133,
        "155k": 160,
        "160k": 106,
        "165k": 122,
        "170k": 62,
        "175k": 118,
        "180k": 96,
        "185k": 93,
        "190k": 75,
        "195k": 88,
        "200k": 67,
        "350k": 1187
      },
      spousefm: {
        "5k": 48,
        "10k": 37,
        "15k": 47,
        "20k": 71,
        "25k": 101,
        "30k": 88,
        "35k": 130,
        "40k": 125,
        "45k": 139,
        "50k": 155,
        "55k": 174,
        "60k": 162,
        "65k": 184,
        "70k": 155,
        "75k": 216,
        "80k": 224,
        "85k": 220,
        "90k": 150,
        "95k": 193,
        "100k": 225,
        "105k": 266,
        "110k": 164,
        "115k": 208,
        "120k": 142,
        "125k": 218,
        "130k": 159,
        "135k": 151,
        "140k": 148,
        "145k": 105,
        "150k": 134,
        "155k": 163,
        "160k": 128,
        "165k": 117,
        "170k": 101,
        "175k": 130,
        "180k": 98,
        "185k": 111,
        "190k": 76,
        "195k": 72,
        "200k": 76,
        "350k": 1707
      },
      childfm: {
        "5k": 10,
        "10k": 14,
        "15k": 23,
        "20k": 17,
        "25k": 35,
        "30k": 32,
        "35k": 33,
        "40k": 33,
        "45k": 50,
        "50k": 61,
        "55k": 50,
        "60k": 33,
        "65k": 38,
        "70k": 38,
        "75k": 21,
        "80k": 42,
        "85k": 36,
        "90k": 34,
        "95k": 18,
        "100k": 31,
        "105k": 33,
        "110k": 19,
        "115k": 31,
        "120k": 27,
        "125k": 23,
        "130k": 21,
        "135k": 22,
        "140k": 10,
        "145k": 15,
        "150k": 28,
        "155k": 14,
        "160k": 9,
        "165k": 13,
        "170k": 13,
        "175k": 18,
        "180k": 11,
        "185k": 4,
        "190k": 13,
        "195k": 8,
        "200k": 9,
        "350k": 121
      },
      otherRelativefm: {
        "5k": 12,
        "10k": 24,
        "15k": 19,
        "20k": 16,
        "25k": 15,
        "30k": 22,
        "35k": 23,
        "40k": 36,
        "45k": 30,
        "50k": 50,
        "55k": 25,
        "60k": 34,
        "65k": 41,
        "70k": 26,
        "75k": 24,
        "80k": 26,
        "85k": 29,
        "90k": 20,
        "95k": 47,
        "100k": 16,
        "105k": 22,
        "110k": 21,
        "115k": 14,
        "120k": 13,
        "125k": 13,
        "130k": 21,
        "135k": 16,
        "140k": 18,
        "145k": 7,
        "150k": 7,
        "155k": 2,
        "160k": 11,
        "165k": 11,
        "170k": 9,
        "175k": 15,
        "180k": 9,
        "185k": 3,
        "190k": 1,
        "195k": 6,
        "200k": 7,
        "350k": 82
      },
      nonRelativefm: {
        "5k": 14,
        "10k": 8,
        "15k": 6,
        "20k": 25,
        "25k": 25,
        "30k": 46,
        "35k": 24,
        "40k": 38,
        "45k": 27,
        "50k": 29,
        "55k": 57,
        "60k": 33,
        "65k": 31,
        "70k": 36,
        "75k": 37,
        "80k": 28,
        "85k": 45,
        "90k": 28,
        "95k": 60,
        "100k": 9,
        "105k": 33,
        "110k": 23,
        "115k": 37,
        "120k": 29,
        "125k": 23,
        "130k": 16,
        "135k": 19,
        "140k": 15,
        "145k": 20,
        "150k": 21,
        "155k": 24,
        "160k": 12,
        "165k": 15,
        "170k": 8,
        "175k": 14,
        "180k": 20,
        "185k": 1,
        "190k": 2,
        "195k": 9,
        "200k": 9,
        "350k": 130
      }
    },
    54: {
      houseLoitererm: {
        "5k": 268,
        "10k": 170,
        "15k": 266,
        "20k": 272,
        "25k": 277,
        "30k": 234,
        "35k": 273,
        "40k": 273,
        "45k": 296,
        "50k": 270,
        "55k": 324,
        "60k": 288,
        "65k": 321,
        "70k": 323,
        "75k": 341,
        "80k": 289,
        "85k": 247,
        "90k": 246,
        "95k": 284,
        "100k": 212,
        "105k": 316,
        "110k": 192,
        "115k": 295,
        "120k": 232,
        "125k": 204,
        "130k": 214,
        "135k": 190,
        "140k": 200,
        "145k": 116,
        "150k": 149,
        "155k": 176,
        "160k": 157,
        "165k": 169,
        "170k": 147,
        "175k": 126,
        "180k": 119,
        "185k": 125,
        "190k": 114,
        "195k": 88,
        "200k": 126,
        "350k": 2093
      },
      spousem: {
        "5k": 45,
        "10k": 16,
        "15k": 49,
        "20k": 33,
        "25k": 82,
        "30k": 58,
        "35k": 62,
        "40k": 112,
        "45k": 119,
        "50k": 70,
        "55k": 136,
        "60k": 114,
        "65k": 124,
        "70k": 117,
        "75k": 133,
        "80k": 139,
        "85k": 145,
        "90k": 141,
        "95k": 134,
        "100k": 124,
        "105k": 166,
        "110k": 146,
        "115k": 104,
        "120k": 96,
        "125k": 118,
        "130k": 105,
        "135k": 106,
        "140k": 96,
        "145k": 105,
        "150k": 86,
        "155k": 129,
        "160k": 103,
        "165k": 102,
        "170k": 86,
        "175k": 73,
        "180k": 69,
        "185k": 63,
        "190k": 62,
        "195k": 67,
        "200k": 58,
        "350k": 1236
      },
      childm: {
        "5k": 3,
        "10k": 7,
        "15k": 15,
        "20k": 56,
        "25k": 57,
        "30k": 27,
        "35k": 47,
        "40k": 55,
        "45k": 25,
        "50k": 60,
        "55k": 38,
        "60k": 63,
        "65k": 28,
        "70k": 44,
        "75k": 25,
        "80k": 27,
        "85k": 36,
        "90k": 30,
        "95k": 28,
        "100k": 19,
        "105k": 30,
        "110k": 18,
        "115k": 6,
        "120k": 13,
        "125k": 12,
        "130k": 17,
        "135k": 16,
        "140k": 17,
        "145k": 10,
        "150k": 35,
        "155k": 20,
        "160k": 17,
        "165k": 4,
        "170k": 8,
        "175k": 10,
        "180k": 0,
        "185k": 5,
        "190k": 0,
        "195k": 15,
        "200k": 6,
        "350k": 54
      },
      otherRelativem: {
        "5k": 3,
        "10k": 20,
        "15k": 8,
        "20k": 27,
        "25k": 47,
        "30k": 23,
        "35k": 33,
        "40k": 32,
        "45k": 27,
        "50k": 42,
        "55k": 40,
        "60k": 50,
        "65k": 42,
        "70k": 26,
        "75k": 47,
        "80k": 70,
        "85k": 34,
        "90k": 35,
        "95k": 7,
        "100k": 32,
        "105k": 28,
        "110k": 30,
        "115k": 14,
        "120k": 14,
        "125k": 29,
        "130k": 31,
        "135k": 13,
        "140k": 24,
        "145k": 19,
        "150k": 27,
        "155k": 20,
        "160k": 6,
        "165k": 18,
        "170k": 20,
        "175k": 17,
        "180k": 10,
        "185k": 8,
        "190k": 6,
        "195k": 6,
        "200k": 2,
        "350k": 89
      },
      nonRelativem: {
        "5k": 22,
        "10k": 10,
        "15k": 20,
        "20k": 29,
        "25k": 47,
        "30k": 33,
        "35k": 34,
        "40k": 29,
        "45k": 45,
        "50k": 34,
        "55k": 43,
        "60k": 40,
        "65k": 45,
        "70k": 59,
        "75k": 40,
        "80k": 55,
        "85k": 11,
        "90k": 28,
        "95k": 24,
        "100k": 24,
        "105k": 39,
        "110k": 32,
        "115k": 15,
        "120k": 25,
        "125k": 18,
        "130k": 21,
        "135k": 25,
        "140k": 17,
        "145k": 26,
        "150k": 36,
        "155k": 17,
        "160k": 15,
        "165k": 26,
        "170k": 4,
        "175k": 5,
        "180k": 11,
        "185k": 9,
        "190k": 19,
        "195k": 6,
        "200k": 7,
        "350k": 152
      },
      houseLoitererfm: {
        "5k": 313,
        "10k": 208,
        "15k": 335,
        "20k": 300,
        "25k": 353,
        "30k": 311,
        "35k": 331,
        "40k": 379,
        "45k": 357,
        "50k": 335,
        "55k": 388,
        "60k": 306,
        "65k": 354,
        "70k": 353,
        "75k": 341,
        "80k": 322,
        "85k": 287,
        "90k": 234,
        "95k": 251,
        "100k": 261,
        "105k": 293,
        "110k": 237,
        "115k": 177,
        "120k": 143,
        "125k": 188,
        "130k": 163,
        "135k": 168,
        "140k": 141,
        "145k": 169,
        "150k": 125,
        "155k": 177,
        "160k": 130,
        "165k": 142,
        "170k": 94,
        "175k": 94,
        "180k": 82,
        "185k": 87,
        "190k": 87,
        "195k": 78,
        "200k": 70,
        "350k": 1477
      },
      spousefm: {
        "5k": 68,
        "10k": 17,
        "15k": 30,
        "20k": 71,
        "25k": 74,
        "30k": 70,
        "35k": 75,
        "40k": 104,
        "45k": 112,
        "50k": 160,
        "55k": 149,
        "60k": 147,
        "65k": 156,
        "70k": 148,
        "75k": 122,
        "80k": 174,
        "85k": 177,
        "90k": 169,
        "95k": 172,
        "100k": 169,
        "105k": 169,
        "110k": 181,
        "115k": 202,
        "120k": 182,
        "125k": 151,
        "130k": 138,
        "135k": 158,
        "140k": 183,
        "145k": 119,
        "150k": 120,
        "155k": 185,
        "160k": 119,
        "165k": 157,
        "170k": 105,
        "175k": 100,
        "180k": 111,
        "185k": 121,
        "190k": 100,
        "195k": 95,
        "200k": 96,
        "350k": 1934
      },
      childfm: {
        "5k": 0,
        "10k": 1,
        "15k": 15,
        "20k": 9,
        "25k": 15,
        "30k": 13,
        "35k": 6,
        "40k": 24,
        "45k": 23,
        "50k": 16,
        "55k": 35,
        "60k": 14,
        "65k": 22,
        "70k": 4,
        "75k": 12,
        "80k": 18,
        "85k": 22,
        "90k": 21,
        "95k": 8,
        "100k": 9,
        "105k": 11,
        "110k": 23,
        "115k": 23,
        "120k": 16,
        "125k": 13,
        "130k": 10,
        "135k": 7,
        "140k": 8,
        "145k": 8,
        "150k": 10,
        "155k": 6,
        "160k": 13,
        "165k": 5,
        "170k": 0,
        "175k": 5,
        "180k": 4,
        "185k": 1,
        "190k": 1,
        "195k": 2,
        "200k": 0,
        "350k": 41
      },
      otherRelativefm: {
        "5k": 19,
        "10k": 27,
        "15k": 17,
        "20k": 13,
        "25k": 35,
        "30k": 21,
        "35k": 36,
        "40k": 31,
        "45k": 60,
        "50k": 43,
        "55k": 53,
        "60k": 47,
        "65k": 42,
        "70k": 50,
        "75k": 69,
        "80k": 56,
        "85k": 37,
        "90k": 34,
        "95k": 17,
        "100k": 15,
        "105k": 46,
        "110k": 29,
        "115k": 38,
        "120k": 19,
        "125k": 22,
        "130k": 32,
        "135k": 17,
        "140k": 23,
        "145k": 22,
        "150k": 18,
        "155k": 29,
        "160k": 7,
        "165k": 14,
        "170k": 22,
        "175k": 10,
        "180k": 3,
        "185k": 10,
        "190k": 12,
        "195k": 5,
        "200k": 2,
        "350k": 96
      },
      nonRelativefm: {
        "5k": 23,
        "10k": 14,
        "15k": 35,
        "20k": 16,
        "25k": 11,
        "30k": 15,
        "35k": 16,
        "40k": 24,
        "45k": 18,
        "50k": 21,
        "55k": 31,
        "60k": 32,
        "65k": 29,
        "70k": 32,
        "75k": 25,
        "80k": 36,
        "85k": 27,
        "90k": 17,
        "95k": 31,
        "100k": 17,
        "105k": 45,
        "110k": 10,
        "115k": 19,
        "120k": 18,
        "125k": 9,
        "130k": 24,
        "135k": 9,
        "140k": 13,
        "145k": 29,
        "150k": 23,
        "155k": 13,
        "160k": 13,
        "165k": 7,
        "170k": 8,
        "175k": 7,
        "180k": 4,
        "185k": 9,
        "190k": 5,
        "195k": 4,
        "200k": 2,
        "350k": 81
      }
    },
    64: {
      houseLoitererm: {
        "5k": 418,
        "10k": 287,
        "15k": 455,
        "20k": 468,
        "25k": 436,
        "30k": 353,
        "35k": 440,
        "40k": 384,
        "45k": 392,
        "50k": 337,
        "55k": 424,
        "60k": 415,
        "65k": 379,
        "70k": 334,
        "75k": 304,
        "80k": 325,
        "85k": 290,
        "90k": 268,
        "95k": 340,
        "100k": 233,
        "105k": 266,
        "110k": 228,
        "115k": 279,
        "120k": 214,
        "125k": 218,
        "130k": 195,
        "135k": 191,
        "140k": 173,
        "145k": 157,
        "150k": 158,
        "155k": 215,
        "160k": 141,
        "165k": 140,
        "170k": 102,
        "175k": 147,
        "180k": 145,
        "185k": 127,
        "190k": 84,
        "195k": 131,
        "200k": 86,
        "350k": 1904
      },
      spousem: {
        "5k": 42,
        "10k": 22,
        "15k": 68,
        "20k": 61,
        "25k": 98,
        "30k": 90,
        "35k": 117,
        "40k": 100,
        "45k": 113,
        "50k": 98,
        "55k": 179,
        "60k": 144,
        "65k": 139,
        "70k": 131,
        "75k": 169,
        "80k": 142,
        "85k": 134,
        "90k": 148,
        "95k": 136,
        "100k": 174,
        "105k": 140,
        "110k": 115,
        "115k": 132,
        "120k": 101,
        "125k": 80,
        "130k": 96,
        "135k": 119,
        "140k": 91,
        "145k": 128,
        "150k": 83,
        "155k": 100,
        "160k": 73,
        "165k": 58,
        "170k": 67,
        "175k": 58,
        "180k": 51,
        "185k": 58,
        "190k": 57,
        "195k": 89,
        "200k": 48,
        "350k": 1144
      },
      childm: {
        "5k": 14,
        "10k": 3,
        "15k": 15,
        "20k": 18,
        "25k": 26,
        "30k": 18,
        "35k": 27,
        "40k": 18,
        "45k": 11,
        "50k": 37,
        "55k": 20,
        "60k": 22,
        "65k": 2,
        "70k": 24,
        "75k": 11,
        "80k": 10,
        "85k": 21,
        "90k": 9,
        "95k": 3,
        "100k": 4,
        "105k": 6,
        "110k": 5,
        "115k": 2,
        "120k": 6,
        "125k": 0,
        "130k": 1,
        "135k": 3,
        "140k": 3,
        "145k": 3,
        "150k": 0,
        "155k": 6,
        "160k": 0,
        "165k": 0,
        "170k": 4,
        "175k": 3,
        "180k": 4,
        "185k": 0,
        "190k": 2,
        "195k": 3,
        "200k": 1,
        "350k": 11
      },
      otherRelativem: {
        "5k": 14,
        "10k": 12,
        "15k": 10,
        "20k": 35,
        "25k": 25,
        "30k": 16,
        "35k": 32,
        "40k": 21,
        "45k": 34,
        "50k": 14,
        "55k": 32,
        "60k": 38,
        "65k": 39,
        "70k": 21,
        "75k": 43,
        "80k": 47,
        "85k": 37,
        "90k": 17,
        "95k": 27,
        "100k": 25,
        "105k": 33,
        "110k": 22,
        "115k": 42,
        "120k": 11,
        "125k": 7,
        "130k": 7,
        "135k": 5,
        "140k": 33,
        "145k": 6,
        "150k": 20,
        "155k": 31,
        "160k": 25,
        "165k": 14,
        "170k": 15,
        "175k": 29,
        "180k": 6,
        "185k": 10,
        "190k": 1,
        "195k": 6,
        "200k": 5,
        "350k": 150
      },
      nonRelativem: {
        "5k": 17,
        "10k": 8,
        "15k": 19,
        "20k": 19,
        "25k": 29,
        "30k": 28,
        "35k": 42,
        "40k": 38,
        "45k": 42,
        "50k": 29,
        "55k": 32,
        "60k": 27,
        "65k": 29,
        "70k": 43,
        "75k": 31,
        "80k": 35,
        "85k": 15,
        "90k": 21,
        "95k": 26,
        "100k": 15,
        "105k": 15,
        "110k": 19,
        "115k": 35,
        "120k": 10,
        "125k": 12,
        "130k": 9,
        "135k": 17,
        "140k": 17,
        "145k": 8,
        "150k": 8,
        "155k": 19,
        "160k": 8,
        "165k": 0,
        "170k": 5,
        "175k": 7,
        "180k": 15,
        "185k": 4,
        "190k": 1,
        "195k": 6,
        "200k": 6,
        "350k": 84
      },
      houseLoitererfm: {
        "5k": 388,
        "10k": 412,
        "15k": 582,
        "20k": 505,
        "25k": 470,
        "30k": 470,
        "35k": 462,
        "40k": 477,
        "45k": 460,
        "50k": 345,
        "55k": 444,
        "60k": 346,
        "65k": 372,
        "70k": 342,
        "75k": 351,
        "80k": 280,
        "85k": 282,
        "90k": 287,
        "95k": 262,
        "100k": 284,
        "105k": 316,
        "110k": 199,
        "115k": 260,
        "120k": 149,
        "125k": 186,
        "130k": 156,
        "135k": 144,
        "140k": 148,
        "145k": 139,
        "150k": 113,
        "155k": 140,
        "160k": 110,
        "165k": 76,
        "170k": 87,
        "175k": 105,
        "180k": 73,
        "185k": 75,
        "190k": 63,
        "195k": 93,
        "200k": 68,
        "350k": 1230
      },
      spousefm: {
        "5k": 113,
        "10k": 57,
        "15k": 89,
        "20k": 156,
        "25k": 133,
        "30k": 140,
        "35k": 213,
        "40k": 128,
        "45k": 183,
        "50k": 188,
        "55k": 243,
        "60k": 204,
        "65k": 213,
        "70k": 220,
        "75k": 198,
        "80k": 225,
        "85k": 200,
        "90k": 164,
        "95k": 200,
        "100k": 171,
        "105k": 244,
        "110k": 153,
        "115k": 224,
        "120k": 135,
        "125k": 204,
        "130k": 140,
        "135k": 159,
        "140k": 135,
        "145k": 131,
        "150k": 115,
        "155k": 106,
        "160k": 96,
        "165k": 124,
        "170k": 75,
        "175k": 114,
        "180k": 91,
        "185k": 101,
        "190k": 73,
        "195k": 100,
        "200k": 67,
        "350k": 1499
      },
      childfm: {
        "5k": 0,
        "10k": 6,
        "15k": 5,
        "20k": 16,
        "25k": 24,
        "30k": 12,
        "35k": 15,
        "40k": 17,
        "45k": 16,
        "50k": 18,
        "55k": 11,
        "60k": 19,
        "65k": 11,
        "70k": 17,
        "75k": 19,
        "80k": 14,
        "85k": 13,
        "90k": 10,
        "95k": 8,
        "100k": 14,
        "105k": 13,
        "110k": 9,
        "115k": 5,
        "120k": 2,
        "125k": 7,
        "130k": 6,
        "135k": 3,
        "140k": 5,
        "145k": 6,
        "150k": 0,
        "155k": 3,
        "160k": 0,
        "165k": 0,
        "170k": 4,
        "175k": 0,
        "180k": 7,
        "185k": 1,
        "190k": 0,
        "195k": 0,
        "200k": 0,
        "350k": 14
      },
      otherRelativefm: {
        "5k": 26,
        "10k": 8,
        "15k": 19,
        "20k": 42,
        "25k": 41,
        "30k": 37,
        "35k": 29,
        "40k": 27,
        "45k": 50,
        "50k": 43,
        "55k": 53,
        "60k": 70,
        "65k": 59,
        "70k": 48,
        "75k": 57,
        "80k": 34,
        "85k": 60,
        "90k": 48,
        "95k": 40,
        "100k": 46,
        "105k": 42,
        "110k": 31,
        "115k": 37,
        "120k": 21,
        "125k": 16,
        "130k": 18,
        "135k": 26,
        "140k": 25,
        "145k": 13,
        "150k": 35,
        "155k": 21,
        "160k": 23,
        "165k": 21,
        "170k": 21,
        "175k": 13,
        "180k": 12,
        "185k": 9,
        "190k": 8,
        "195k": 11,
        "200k": 12,
        "350k": 171
      },
      nonRelativefm: {
        "5k": 5,
        "10k": 9,
        "15k": 18,
        "20k": 33,
        "25k": 24,
        "30k": 17,
        "35k": 45,
        "40k": 25,
        "45k": 14,
        "50k": 33,
        "55k": 20,
        "60k": 15,
        "65k": 25,
        "70k": 21,
        "75k": 17,
        "80k": 22,
        "85k": 16,
        "90k": 17,
        "95k": 31,
        "100k": 17,
        "105k": 19,
        "110k": 17,
        "115k": 17,
        "120k": 9,
        "125k": 12,
        "130k": 16,
        "135k": 9,
        "140k": 15,
        "145k": 5,
        "150k": 12,
        "155k": 18,
        "160k": 5,
        "165k": 3,
        "170k": 4,
        "175k": 5,
        "180k": 4,
        "185k": 6,
        "190k": 0,
        "195k": 9,
        "200k": 5,
        "350k": 73
      }
    },
    "74": {
      houseLoitererm: {
        "5k": "271",
        "10k": "214",
        "15k": "447",
        "20k": "473",
        "25k": "470",
        "30k": "479",
        "35k": "483",
        "40k": "381",
        "45k": "407",
        "50k": "428",
        "55k": "409",
        "60k": "375",
        "65k": "337",
        "70k": "283",
        "75k": "289",
        "80k": "287",
        "85k": "249",
        "90k": "243",
        "95k": "210",
        "100k": "209",
        "105k": "228",
        "110k": "227",
        "115k": "190",
        "120k": "158",
        "125k": "152",
        "130k": "151",
        "135k": "123",
        "140k": "145",
        "145k": "109",
        "150k": "107",
        "155k": "88",
        "160k": "66",
        "165k": "108",
        "170k": "99",
        "175k": "94",
        "180k": "61",
        "185k": "44",
        "190k": "56",
        "195k": "56",
        "200k": "43",
        "350k": "913"
      },
      spousem: {
        "5k": "81",
        "10k": "29",
        "15k": "29",
        "20k": "81",
        "25k": "109",
        "30k": "168",
        "35k": "157",
        "40k": "154",
        "45k": "176",
        "50k": "198",
        "55k": "156",
        "60k": "152",
        "65k": "138",
        "70k": "125",
        "75k": "146",
        "80k": "130",
        "85k": "135",
        "90k": "93",
        "95k": "99",
        "100k": "74",
        "105k": "115",
        "110k": "115",
        "115k": "75",
        "120k": "55",
        "125k": "75",
        "130k": "88",
        "135k": "61",
        "140k": "59",
        "145k": "66",
        "150k": "52",
        "155k": "47",
        "160k": "63",
        "165k": "60",
        "170k": "35",
        "175k": "63",
        "180k": "29",
        "185k": "25",
        "190k": "35",
        "195k": "34",
        "200k": "46",
        "350k": "494"
      },
      childm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      otherRelativem: {
        "5k": "11",
        "10k": "2",
        "15k": "14",
        "20k": "16",
        "25k": "6",
        "30k": "9",
        "35k": "20",
        "40k": "18",
        "45k": "18",
        "50k": "13",
        "55k": "18",
        "60k": "21",
        "65k": "21",
        "70k": "17",
        "75k": "45",
        "80k": "23",
        "85k": "19",
        "90k": "23",
        "95k": "28",
        "100k": "16",
        "105k": "15",
        "110k": "21",
        "115k": "9",
        "120k": "7",
        "125k": "15",
        "130k": "8",
        "135k": "6",
        "140k": "19",
        "145k": "19",
        "150k": "14",
        "155k": "7",
        "160k": "6",
        "165k": "6",
        "170k": "8",
        "175k": "8",
        "180k": "4",
        "185k": "5",
        "190k": "11",
        "195k": "14",
        "200k": "8",
        "350k": "89"
      },
      nonRelativem: {
        "5k": "0",
        "10k": "7",
        "15k": "17",
        "20k": "14",
        "25k": "18",
        "30k": "15",
        "35k": "21",
        "40k": "26",
        "45k": "16",
        "50k": "5",
        "55k": "16",
        "60k": "18",
        "65k": "5",
        "70k": "6",
        "75k": "13",
        "80k": "12",
        "85k": "15",
        "90k": "4",
        "95k": "11",
        "100k": "0",
        "105k": "4",
        "110k": "2",
        "115k": "4",
        "120k": "8",
        "125k": "10",
        "130k": "0",
        "135k": "3",
        "140k": "3",
        "145k": "3",
        "150k": "6",
        "155k": "6",
        "160k": "4",
        "165k": "0",
        "170k": "2",
        "175k": "0",
        "180k": "2",
        "185k": "0",
        "190k": "0",
        "195k": "0",
        "200k": "1",
        "350k": "53"
      },
      houseLoitererfm: {
        "5k": "372",
        "10k": "251",
        "15k": "639",
        "20k": "775",
        "25k": "719",
        "30k": "612",
        "35k": "531",
        "40k": "563",
        "45k": "525",
        "50k": "523",
        "55k": "425",
        "60k": "336",
        "65k": "327",
        "70k": "271",
        "75k": "327",
        "80k": "283",
        "85k": "272",
        "90k": "167",
        "95k": "185",
        "100k": "133",
        "105k": "178",
        "110k": "137",
        "115k": "107",
        "120k": "99",
        "125k": "114",
        "130k": "90",
        "135k": "79",
        "140k": "88",
        "145k": "105",
        "150k": "96",
        "155k": "77",
        "160k": "59",
        "165k": "59",
        "170k": "48",
        "175k": "56",
        "180k": "43",
        "185k": "51",
        "190k": "25",
        "195k": "48",
        "200k": "34",
        "350k": "509"
      },
      spousefm: {
        "5k": "79",
        "10k": "59",
        "15k": "67",
        "20k": "121",
        "25k": "159",
        "30k": "233",
        "35k": "239",
        "40k": "211",
        "45k": "245",
        "50k": "225",
        "55k": "254",
        "60k": "234",
        "65k": "180",
        "70k": "183",
        "75k": "198",
        "80k": "191",
        "85k": "164",
        "90k": "138",
        "95k": "180",
        "100k": "146",
        "105k": "136",
        "110k": "149",
        "115k": "111",
        "120k": "125",
        "125k": "89",
        "130k": "104",
        "135k": "63",
        "140k": "93",
        "145k": "71",
        "150k": "73",
        "155k": "68",
        "160k": "52",
        "165k": "72",
        "170k": "86",
        "175k": "59",
        "180k": "50",
        "185k": "19",
        "190k": "45",
        "195k": "35",
        "200k": "28",
        "350k": "602"
      },
      childfm: {
        "5k": "(B)",
        "10k": "(B)",
        "15k": "(B)",
        "20k": "(B)",
        "25k": "(B)",
        "30k": "(B)",
        "35k": "(B)",
        "40k": "(B)",
        "45k": "(B)",
        "50k": "(B)",
        "55k": "(B)",
        "60k": "(B)",
        "65k": "(B)",
        "70k": "(B)",
        "75k": "(B)",
        "80k": "(B)",
        "85k": "(B)",
        "90k": "(B)",
        "95k": "(B)",
        "100k": "(B)",
        "105k": "(B)",
        "110k": "(B)",
        "115k": "(B)",
        "120k": "(B)",
        "125k": "(B)",
        "130k": "(B)",
        "135k": "(B)",
        "140k": "(B)",
        "145k": "(B)",
        "150k": "(B)",
        "155k": "(B)",
        "160k": "(B)",
        "165k": "(B)",
        "170k": "(B)",
        "175k": "(B)",
        "180k": "(B)",
        "185k": "(B)",
        "190k": "(B)",
        "195k": "(B)",
        "200k": "(B)",
        "350k": "(B)"
      },
      otherRelativefm: {
        "5k": "19",
        "10k": "9",
        "15k": "24",
        "20k": "22",
        "25k": "32",
        "30k": "51",
        "35k": "59",
        "40k": "58",
        "45k": "61",
        "50k": "29",
        "55k": "36",
        "60k": "49",
        "65k": "35",
        "70k": "62",
        "75k": "54",
        "80k": "29",
        "85k": "44",
        "90k": "32",
        "95k": "49",
        "100k": "44",
        "105k": "51",
        "110k": "32",
        "115k": "31",
        "120k": "19",
        "125k": "16",
        "130k": "33",
        "135k": "10",
        "140k": "32",
        "145k": "18",
        "150k": "9",
        "155k": "21",
        "160k": "17",
        "165k": "11",
        "170k": "12",
        "175k": "22",
        "180k": "6",
        "185k": "5",
        "190k": "11",
        "195k": "17",
        "200k": "10",
        "350k": "153"
      },
      nonRelativefm: {
        "5k": "10",
        "10k": "3",
        "15k": "11",
        "20k": "4",
        "25k": "16",
        "30k": "3",
        "35k": "21",
        "40k": "13",
        "45k": "21",
        "50k": "13",
        "55k": "33",
        "60k": "8",
        "65k": "13",
        "70k": "16",
        "75k": "18",
        "80k": "7",
        "85k": "17",
        "90k": "7",
        "95k": "4",
        "100k": "3",
        "105k": "2",
        "110k": "6",
        "115k": "8",
        "120k": "18",
        "125k": "1",
        "130k": "6",
        "135k": "3",
        "140k": "0",
        "145k": "6",
        "150k": "0",
        "155k": "0",
        "160k": "1",
        "165k": "4",
        "170k": "5",
        "175k": "0",
        "180k": "4",
        "185k": "1",
        "190k": "0",
        "195k": "0",
        "200k": "1",
        "350k": "21"
      }
    }
  }
};
export const yearlypop = {
  "1990": {
    /*"0": 3986488,
    "1": 3800632,
    "2": 3711376,
    "3": 3658876,
    "4": 3699075,
    "5": 3684102,
    "6": 3561588,
    "7": 3643051,
    "8": 3509403,
    "9": 3678602,
    "10": 3709456,
    "11": 3480601,
    "12": 3379084,
    "13": 3384708,
    "14": 3259038,
    "15": 3346796,
    "16": 3286594,
    "17": 3439042,
    "18": 3599611,
    "19": 4099550,*/
    "20": 4054116,
    "21": 3836827,
    "22": 3671346,
    "23": 3708282,
    "24": 3879815,
    "25": 4072660,
    "26": 4193967,
    "27": 4289896,
    "28": 4146848,
    "29": 4573676,
    "30": 4515448,
    "31": 4348350,
    "32": 4379099,
    "33": 4356081,
    "34": 4339795,
    "35": 4278653,
    "36": 4072625,
    "37": 3999937,
    "38": 3703701,
    "39": 3935379,
    "40": 3801229,
    "41": 3633450,
    "42": 3619863,
    "43": 3845526,
    "44": 2892874,
    "45": 2907092,
    "46": 2822206,
    "47": 3001489,
    "48": 2499420,
    "49": 2598809,
    "50": 2436832,
    "51": 2312474,
    "52": 2265069,
    "53": 2169906,
    "54": 2193481,
    "55": 2169429,
    "56": 2059482,
    "57": 2088237,
    "58": 1975268,
    "59": 2185743,
    "60": 2155202,
    "61": 2092965,
    "62": 2141981,
    "63": 2110263,
    "64": 2123071,
    "65": 2120737,
    "66": 2056074,
    "67": 2000161,
    "68": 1961341,
    "69": 1940610,
    "70": 1785637,
    "71": 1684943,
    "72": 1606680,
    "73": 1498595,
    "74": 1449208,
    "75": 1404203,
    "76": 1299109,
    "77": 1230704,
    "78": 1148210,
    "79": 1064770,
    "80": 953035,
    "81": 863829,
    "82": 788454,
    "83": 705351,
    "84": 626028
    //"85": 3059600
  },
  "1991": {
    /*"0": 4003780,
    "1": 3956187,
    "2": 3805832,
    "3": 3721868,
    "4": 3720379,
    "5": 3710209,
    "6": 3684704,
    "7": 3582318,
    "8": 3536785,
    "9": 3767114,
    "10": 3715571,
    "11": 3676577,
    "12": 3503980,
    "13": 3431857,
    "14": 3427970,
    "15": 3317193,
    "16": 3380995,
    "17": 3369699,
    "18": 3400389,
    "19": 3802004,*/
    "20": 4072063,
    "21": 3964964,
    "22": 3743834,
    "23": 3653983,
    "24": 3799581,
    "25": 3879903,
    "26": 4077195,
    "27": 4260580,
    "28": 4127345,
    "29": 4578114,
    "30": 4529278,
    "31": 4445245,
    "32": 4401664,
    "33": 4395231,
    "34": 4529447,
    "35": 4340039,
    "36": 4205271,
    "37": 4126598,
    "38": 3806340,
    "39": 4099100,
    "40": 3840792,
    "41": 3710894,
    "42": 3632474,
    "43": 3589949,
    "44": 3978009,
    "45": 2844550,
    "46": 2828029,
    "47": 2855102,
    "48": 2872716,
    "49": 2728695,
    "50": 2542744,
    "51": 2348686,
    "52": 2308790,
    "53": 2284342,
    "54": 2206498,
    "55": 2143337,
    "56": 2192820,
    "57": 2065187,
    "58": 1958273,
    "59": 2083172,
    "60": 2136556,
    "61": 2102042,
    "62": 2082428,
    "63": 2147688,
    "64": 2134358,
    "65": 2073172,
    "66": 2067018,
    "67": 2027159,
    "68": 1912345,
    "69": 1958672,
    "70": 1892156,
    "71": 1728116,
    "72": 1645240,
    "73": 1524970,
    "74": 1465056,
    "75": 1413664,
    "76": 1338326,
    "77": 1245951,
    "78": 1198775,
    "79": 1085352,
    "80": 978711,
    "81": 884929,
    "82": 802882,
    "83": 736789,
    "84": 643202
    //"85": 3189139
  },
  "1992": {
    /*"0": 3972775,
    "1": 3977686,
    "2": 3963688,
    "3": 3824569,
    "4": 3789323,
    "5": 3735861,
    "6": 3713080,
    "7": 3711736,
    "8": 3494131,
    "9": 3776296,
    "10": 3812500,
    "11": 3690646,
    "12": 3706910,
    "13": 3557411,
    "14": 3478296,
    "15": 3491225,
    "16": 3352809,
    "17": 3460235,
    "18": 3347464,
    "19": 3594507,*/
    "20": 3803224,
    "21": 4003995,
    "22": 3888090,
    "23": 3742455,
    "24": 3750238,
    "25": 3821532,
    "26": 3887651,
    "27": 4153627,
    "28": 4121313,
    "29": 4518494,
    "30": 4560699,
    "31": 4452937,
    "32": 4504133,
    "33": 4415197,
    "34": 4561387,
    "35": 4542352,
    "36": 4265487,
    "37": 4254660,
    "38": 3948302,
    "39": 4173598,
    "40": 4023999,
    "41": 3742574,
    "42": 3719172,
    "43": 3605570,
    "44": 3700620,
    "45": 3927797,
    "46": 2768334,
    "47": 2861431,
    "48": 2743321,
    "49": 3111815,
    "50": 2684545,
    "51": 2451865,
    "52": 2348662,
    "53": 2324729,
    "54": 2325219,
    "55": 2160410,
    "56": 2157802,
    "57": 2197520,
    "58": 1950852,
    "59": 2054316,
    "60": 2045600,
    "61": 2080951,
    "62": 2094208,
    "63": 2087253,
    "64": 2169146,
    "65": 2086548,
    "66": 2023843,
    "67": 2041446,
    "68": 1938566,
    "69": 1905169,
    "70": 1913049,
    "71": 1828861,
    "72": 1686834,
    "73": 1570301,
    "74": 1491402,
    "75": 1428664,
    "76": 1347350,
    "77": 1281680,
    "78": 1209805,
    "79": 1133146,
    "80": 1005000,
    "81": 905785,
    "82": 823425,
    "83": 748469,
    "84": 671991
    //"85": 3314660
  },
  "1993": {
    /*"0": 3894191,
    "1": 3955186,
    "2": 3989174,
    "3": 3991628,
    "4": 3899096,
    "5": 3809291,
    "6": 3741372,
    "7": 3746691,
    "8": 3636422,
    "9": 3712180,
    "10": 3830889,
    "11": 3794055,
    "12": 3727205,
    "13": 3761538,
    "14": 3607716,
    "15": 3544262,
    "16": 3527533,
    "17": 3426509,
    "18": 3447455,
    "19": 3528094,*/
    "20": 3603965,
    "21": 3741649,
    "22": 3925253,
    "23": 3885527,
    "24": 3833530,
    "25": 3783732,
    "26": 3820798,
    "27": 3963571,
    "28": 4034007,
    "29": 4466785,
    "30": 4522388,
    "31": 4471388,
    "32": 4511973,
    "33": 4509492,
    "34": 4568262,
    "35": 4585545,
    "36": 4459890,
    "37": 4306139,
    "38": 4089276,
    "39": 4285745,
    "40": 4119960,
    "41": 3911748,
    "42": 3757697,
    "43": 3693264,
    "44": 3702286,
    "45": 3675112,
    "46": 3823358,
    "47": 2802245,
    "48": 2760194,
    "49": 2952415,
    "50": 3077789,
    "51": 2589331,
    "52": 2455486,
    "53": 2360245,
    "54": 2367774,
    "55": 2281510,
    "56": 2167642,
    "57": 2162229,
    "58": 2090636,
    "59": 2034236,
    "60": 2025891,
    "61": 1988235,
    "62": 2076248,
    "63": 2097856,
    "64": 2103988,
    "65": 2122724,
    "66": 2039966,
    "67": 2001902,
    "68": 1952473,
    "69": 1926714,
    "70": 1864221,
    "71": 1845446,
    "72": 1785331,
    "73": 1617580,
    "74": 1536262,
    "75": 1452509,
    "76": 1361808,
    "77": 1288840,
    "78": 1240505,
    "79": 1143537,
    "80": 1055732,
    "81": 927460,
    "82": 844765,
    "83": 765197,
    "84": 683273
    //"85": 3445566
  },
  "1994": {
    /*"0": 3837113,
    "1": 3882988,
    "2": 3966826,
    "3": 4021040,
    "4": 4068895,
    "5": 3916329,
    "6": 3811882,
    "7": 3776454,
    "8": 3682214,
    "9": 3837800,
    "10": 3769302,
    "11": 3814271,
    "12": 3831807,
    "13": 3775834,
    "14": 3810072,
    "15": 3671558,
    "16": 3575264,
    "17": 3591286,
    "18": 3420476,
    "19": 3617225,*/
    "20": 3538504,
    "21": 3547069,
    "22": 3674415,
    "23": 3924167,
    "24": 3971909,
    "25": 3877530,
    "26": 3774994,
    "27": 3896371,
    "28": 3863783,
    "29": 4326887,
    "30": 4488415,
    "31": 4418642,
    "32": 4527461,
    "33": 4506418,
    "34": 4649053,
    "35": 4600981,
    "36": 4495789,
    "37": 4489009,
    "38": 4156409,
    "39": 4392419,
    "40": 4251275,
    "41": 3994145,
    "42": 3932797,
    "43": 3730693,
    "44": 3775349,
    "45": 3693541,
    "46": 3574810,
    "47": 3862399,
    "48": 2712014,
    "49": 2947806,
    "50": 2934447,
    "51": 2967730,
    "52": 2594697,
    "53": 2461316,
    "54": 2404001,
    "55": 2325621,
    "56": 2278864,
    "57": 2169418,
    "58": 2069581,
    "59": 2165482,
    "60": 2014172,
    "61": 1963894,
    "62": 1985014,
    "63": 2077702,
    "64": 2109389,
    "65": 2060387,
    "66": 2077858,
    "67": 2020305,
    "68": 1913812,
    "69": 1935364,
    "70": 1888121,
    "71": 1793292,
    "72": 1800022,
    "73": 1719000,
    "74": 1581630,
    "75": 1493140,
    "76": 1383961,
    "77": 1300381,
    "78": 1242310,
    "79": 1171770,
    "80": 1071912,
    "81": "970610",
    "82": 865753,
    "83": 781779,
    "84": 698364
    //"85": 3561032
  },
  "1995": {
    /*"0": 3791386,
    "1": 3834008,
    "2": 3895345,
    "3": 4004986,
    "4": 4100780,
    "5": 4084484,
    "6": 3917803,
    "7": 3850123,
    "8": 3723757,
    "9": 3862004,
    "10": 3900641,
    "11": 3755164,
    "12": 3853796,
    "13": 3875499,
    "14": 3821949,
    "15": 3872736,
    "16": 3698273,
    "17": 3630405,
    "18": 3592599,
    "19": 3579985,*/
    "20": 3630445,
    "21": 3486375,
    "22": 3492857,
    "23": 3682513,
    "24": 4008024,
    "25": 4032852,
    "26": 3865666,
    "27": 3855835,
    "28": 3817360,
    "29": 4108223,
    "30": 4372531,
    "31": 4375109,
    "32": 4476018,
    "33": 4515500,
    "34": 4633029,
    "35": 4695437,
    "36": 4507344,
    "37": 4515393,
    "38": 4353269,
    "39": 4420177,
    "40": 4381995,
    "41": 4111159,
    "42": 4022674,
    "43": 3904445,
    "44": 3798875,
    "45": 3785616,
    "46": 3592314,
    "47": 3613678,
    "48": 3755164,
    "49": 2876969,
    "50": 2946456,
    "51": 2828827,
    "52": 2977455,
    "53": 2595882,
    "54": 2507878,
    "55": 2365518,
    "56": 2314396,
    "57": 2279463,
    "58": 2091303,
    "59": 2131618,
    "60": 2153524,
    "61": 1949659,
    "62": 1963465,
    "63": 1985401,
    "64": 2085675,
    "65": 2068210,
    "66": 2019570,
    "67": 2062202,
    "68": 1933312,
    "69": 1893191,
    "70": 1900607,
    "71": 1813884,
    "72": 1750051,
    "73": 1742939,
    "74": 1682122,
    "75": 1536466,
    "76": 1424088,
    "77": 1321478,
    "78": 1250734,
    "79": 1175116,
    "80": 1106839,
    "81": 983987,
    "82": 909049,
    "83": 799663,
    "84": 715074
    //"85": 3680722
  },
  "1996": {
    /*"0": 3744999,
    "1": 3794741,
    "2": 3845840,
    "3": 3937003,
    "4": 4085689,
    "5": 4115730,
    "6": 4086392,
    "7": 3961488,
    "8": 3812082,
    "9": 3884838,
    "10": 3931789,
    "11": 3889245,
    "12": 3797740,
    "13": 3893842,
    "14": 3922493,
    "15": 3884361,
    "16": 3898198,
    "17": 3747042,
    "18": 3643452,
    "19": 3747107,
    "20": 3597090,*/
    "21": 3576997,
    "22": 3437702,
    "23": 3505978,
    "24": 3758878,
    "25": 4084507,
    "26": 4013523,
    "27": 3952011,
    "28": 3794394,
    "29": 4019705,
    "30": 4175189,
    "31": 4251100,
    "32": 4432969,
    "33": 4456520,
    "34": 4629387,
    "35": 4692240,
    "36": 4594923,
    "37": 4517088,
    "38": 4399058,
    "39": 4582442,
    "40": 4435161,
    "41": 4227082,
    "42": 4147836,
    "43": 3994702,
    "44": 3960731,
    "45": 3828110,
    "46": 3679581,
    "47": 3629275,
    "48": 3524316,
    "49": 3949244,
    "50": 2891366,
    "51": 2839579,
    "52": 2841209,
    "53": 2971683,
    "54": 2645602,
    "55": 2470980,
    "56": 2344046,
    "57": 2312452,
    "58": 2211949,
    "59": 2141273,
    "60": 2129047,
    "61": 2079936,
    "62": 1950949,
    "63": 1961449,
    "64": 1987905,
    "65": 2046644,
    "66": 2029293,
    "67": 2007390,
    "68": 1972804,
    "69": 1907956,
    "70": 1863251,
    "71": 1821327,
    "72": 1768882,
    "73": 1701901,
    "74": 1705040,
    "75": 1631594,
    "76": 1465185,
    "77": 1357586,
    "78": 1266429,
    "79": 1182894,
    "80": 1116525,
    "81": 1012769,
    "82": 922919,
    "83": 836505,
    "84": 731263
    //"85": 3794892
  },
  "1997": {
    /*"0": 3751141,
    "1": 3754617,
    "2": 3810061,
    "3": 3894969,
    "4": 4021883,
    "5": 4105686,
    "6": 4116997,
    "7": 4136708,
    "8": 3938810,
    "9": 3955831,
    "10": 3961198,
    "11": 3925877,
    "12": 3937360,
    "13": 3834967,
    "14": 3941295,
    "15": 3984667,
    "16": 3907945,
    "17": 3940725,
    "18": 3773223,
    "19": 3791131,*/
    "20": 3765281,
    "21": 3554549,
    "22": 3540701,
    "23": 3463110,
    "24": 3586435,
    "25": 3856528,
    "26": 4063569,
    "27": 4110734,
    "28": 3908129,
    "29": 3960080,
    "30": 4112244,
    "31": 4052248,
    "32": 4312131,
    "33": 4409408,
    "34": 4559892,
    "35": 4706298,
    "36": 4590690,
    "37": 4597017,
    "38": 4423735,
    "39": 4585881,
    "40": 4625228,
    "41": 4268525,
    "42": 4273481,
    "43": 4120119,
    "44": 4037441,
    "45": 4010954,
    "46": 3721193,
    "47": 3717966,
    "48": 3554154,
    "49": 3682373,
    "50": 3984667,
    "51": 2787021,
    "52": 2856744,
    "53": 2831212,
    "54": 3031224,
    "55": 2611145,
    "56": 2439854,
    "57": 2341620,
    "58": 2260218,
    "59": 2252250,
    "60": 2148377,
    "61": 2052756,
    "62": 2084414,
    "63": 1948570,
    "64": 1960107,
    "65": 1953482,
    "66": 2010708,
    "67": 2020810,
    "68": 1920710,
    "69": 1944120,
    "70": 1882546,
    "71": 1781503,
    "72": 1776000,
    "73": 1728567,
    "74": 1665428,
    "75": 1652142,
    "76": 1556046,
    "77": 1395475,
    "78": 1296971,
    "79": 1198196,
    "80": 1130912,
    "81": 1018872,
    "82": 951866,
    "83": 846282,
    "84": 765449
    //"85": 3905476
  },
  "1998": {
    /*"0": 3762809,
    "1": 3768436,
    "2": 3770264,
    "3": 3863033,
    "4": 3980381,
    "5": 4042435,
    "6": 4107812,
    "7": 4169579,
    "8": 4127042,
    "9": 4063460,
    "10": 4038441,
    "11": 3958076,
    "12": 3977099,
    "13": 3970327,
    "14": 3881238,
    "15": 4002537,
    "16": 4005760,
    "17": 3942677,
    "18": 3976876,
    "19": 3911882,*/
    "20": 3807500,
    "21": 3719968,
    "22": 3527162,
    "23": 3571955,
    "24": 3540518,
    "25": 3694746,
    "26": 3831757,
    "27": 4162091,
    "28": 4078505,
    "29": 4036545,
    "30": 4075280,
    "31": 3981240,
    "32": 4113184,
    "33": 4282820,
    "34": 4500344,
    "35": 4651269,
    "36": 4601444,
    "37": 4583073,
    "38": 4523808,
    "39": 4566603,
    "40": 4658667,
    "41": 4442033,
    "42": 4324516,
    "43": 4247297,
    "44": 4149644,
    "45": 4110945,
    "46": 3899413,
    "47": 3759596,
    "48": 3655985,
    "49": 3688224,
    "50": 3737099,
    "51": 3842825,
    "52": 2807858,
    "53": 2840167,
    "54": 2889745,
    "55": 2994615,
    "56": 2568414,
    "57": 2435237,
    "58": 2303724,
    "59": 2287457,
    "60": 2269149,
    "61": 2067462,
    "62": 2059970,
    "63": 2081451,
    "64": 1943476,
    "65": 1928407,
    "66": 1920478,
    "67": 2005513,
    "68": 1933965,
    "69": 1888902,
    "70": 1922504,
    "71": 1796192,
    "72": 1737458,
    "73": 1744445,
    "74": 1692383,
    "75": 1613030,
    "76": 1576460,
    "77": 1481217,
    "78": 1329494,
    "79": 1227917,
    "80": 1153176,
    "81": 1029595,
    "82": 959834,
    "83": "870521",
    "84": 775133
    //"85": 4032535
  },
  "1999": {
    /*"0": 3795762,
    "1": 3785088,
    "2": 3782635,
    "3": 3824806,
    "4": 3947253,
    "5": 3999762,
    "6": 4042620,
    "7": 4163303,
    "8": 4170706,
    "9": 4230069,
    "10": 4148653,
    "11": 4036182,
    "12": 4009910,
    "13": 4003607,
    "14": 4015012,
    "15": 3939152,
    "16": 4020305,
    "17": 4031224,
    "18": 3989843,
    "19": 4104078,*/
    "20": 3925495,
    "21": 3763401,
    "22": 3694190,
    "23": 3562469,
    "24": 3645874,
    "25": 3657407,
    "26": 3666228,
    "27": 3935064,
    "28": 4146484,
    "29": 4169874,
    "30": 4181212,
    "31": 3936187,
    "32": 4043957,
    "33": 4079717,
    "34": 4362276,
    "35": 4605618,
    "36": 4543561,
    "37": 4582792,
    "38": 4530009,
    "39": 4620569,
    "40": 4667525,
    "41": 4461898,
    "42": 4506931,
    "43": 4297852,
    "44": 4259922,
    "45": 4245882,
    "46": 3994950,
    "47": 3937147,
    "48": 3710656,
    "49": 3765572,
    "50": 3762700,
    "51": 3601854,
    "52": 3873166,
    "53": 2785325,
    "54": 2900567,
    "55": 2859176,
    "56": 2934509,
    "57": 2561216,
    "58": 2412512,
    "59": 2317408,
    "60": 2313430,
    "61": 2178551,
    "62": 2076327,
    "63": 2054965,
    "64": 2069932,
    "65": 1913454,
    "66": 1896361,
    "67": 1916683,
    "68": 1917811,
    "69": 1897189,
    "70": 1872535,
    "71": 1830815,
    "72": 1751382,
    "73": 1714390,
    "74": 1708289,
    "75": 1637734,
    "76": 1539287,
    "77": 1499362,
    "78": 1407240,
    "79": 1258983,
    "80": 1189382,
    "81": 1047265,
    "82": 972176,
    "83": 875092,
    "84": 798393
    //"85": 4154018
  },
  "2000": {
    /*"0": 3855956,
    "1": 3798691,
    "2": 3800144,
    "3": 3821118,
    "4": 3902384,
    "5": 3967834,
    "6": 4004335,
    "7": 4091467,
    "8": 4164871,
    "9": 4235345,
    "10": 4318540,
    "11": 4151953,
    "12": 4084732,
    "13": 4034618,
    "14": 4047853,
    "15": 4064678,
    "16": 3983291,
    "17": 4048379,
    "18": 4079606,
    "19": 4119001,*/
    "20": 4101286,
    "21": 3893853,
    "22": 3771190,
    "23": 3722452,
    "24": 3627886,
    "25": 3747738,
    "26": 3644037,
    "27": 3747633,
    "28": 3947343,
    "29": 4193512,
    "30": 4297742,
    "31": 4062757,
    "32": 3999268,
    "33": 4019117,
    "34": 4145350,
    "35": 4446557,
    "36": 4528984,
    "37": 4523611,
    "38": 4547235,
    "39": 4604465,
    "40": 4696305,
    "41": 4513298,
    "42": 4522455,
    "43": 4476856,
    "44": 4309077,
    "45": 4335271,
    "46": 4155176,
    "47": 4025331,
    "48": 3898395,
    "49": 3805354,
    "50": 3800059,
    "51": 3663455,
    "52": 3637919,
    "53": 3816136,
    "54": 2861878,
    "55": 2854393,
    "56": 2817330,
    "57": 2913690,
    "58": 2580483,
    "59": 2400041,
    "60": 2337902,
    "61": 2230218,
    "62": 2190359,
    "63": 2069449,
    "64": 2035201,
    "65": 2039337,
    "66": 1876784,
    "67": 1882892,
    "68": 1867764,
    "69": 1857132,
    "70": 1884298,
    "71": 1788317,
    "72": 1781935,
    "73": 1735378,
    "74": 1670100,
    "75": 1649707,
    "76": 1568457,
    "77": 1472736,
    "78": 1417670,
    "79": 1330049,
    "80": 1229253,
    "81": 1066141,
    "82": 1002304,
    "83": 884002,
    "84": 802840
    //"85": 4262472
  },
  "2001": {
    /*0": 4012658,
    "1": 3855407,
    "2": 3800096,
    "3": 3802710,
    "4": 3827346,
    "5": 3910033,
    "6": 3972508,
    "7": 4012049,
    "8": 4099542,
    "9": 4179230,
    "10": 4277249,
    "11": 4344913,
    "12": 4183955,
    "13": 4113191,
    "14": 4059370,
    "15": 4083878,
    "16": 4108304,
    "17": 4028736,
    "18": 4102851,
    "19": 4132515,*/
    "20": 4156725,
    "21": 4133311,
    "22": 3919316,
    "23": 3798554,
    "24": 3749380,
    "25": 3659719,
    "26": 3768152,
    "27": 3668700,
    "28": 3766129,
    "29": 3956647,
    "30": 4218260,
    "31": 4302689,
    "32": 4082902,
    "33": 4014912,
    "34": 4033412,
    "35": 4161140,
    "36": 4457678,
    "37": 4534648,
    "38": 4529329,
    "39": 4553123,
    "40": 4606601,
    "41": 4700471,
    "42": 4514435,
    "43": 4523433,
    "44": 4470894,
    "45": 4301955,
    "46": 4331582,
    "47": 4152347,
    "48": 4018921,
    "49": 3894178,
    "50": 3796146,
    "51": 3796980,
    "52": 3654405,
    "53": 3631014,
    "54": 3808740,
    "55": 2851092,
    "56": 2846838,
    "57": 2805407,
    "58": 2900132,
    "59": 2565506,
    "60": 2380300,
    "61": 2323707,
    "62": 2213270,
    "63": 2172024,
    "64": 2047019,
    "65": 2012270,
    "66": 2013441,
    "67": 1850485,
    "68": 1852792,
    "69": 1833245,
    "70": 1817859,
    "71": 1844676,
    "72": 1743767,
    "73": 1733630,
    "74": 1682014,
    "75": 1610892,
    "76": 1589103,
    "77": 1503097,
    "78": 1405933,
    "79": 1346388,
    "80": 1254832,
    "81": 1154138,
    "82": 993071,
    "83": 927192,
    "84": 808972
    //"85": 4312494
  },

  "2002": {
    /*"0": 3951461,
    "1": 4004674,
    "2": 3856114,
    "3": 3804336,
    "4": 3812607,
    "5": 3837187,
    "6": 3913054,
    "7": 3982360,
    "8": 4020970,
    "9": 4118846,
    "10": 4243816,
    "11": 4295720,
    "12": 4380351,
    "13": 4209025,
    "14": 4132509,
    "15": 4097876,
    "16": 4126961,
    "17": 4148590,
    "18": 4084316,
    "19": 4152627,*/
    "20": 4158896,
    "21": 4182678,
    "22": 4145736,
    "23": 3936927,
    "24": 3819528,
    "25": 3779169,
    "26": 3669915,
    "27": 3791713,
    "28": 3682001,
    "29": 3768542,
    "30": 3994164,
    "31": 4210913,
    "32": 4330977,
    "33": 4095531,
    "34": 4026721,
    "35": 4050190,
    "36": 4168398,
    "37": 4459389,
    "38": 4538848,
    "39": 4534393,
    "40": 4551912,
    "41": 4610846,
    "42": 4699196,
    "43": 4515522,
    "44": 4511955,
    "45": 4461379,
    "46": 4301101,
    "47": 4330110,
    "48": 4143330,
    "49": 4016368,
    "50": 3881009,
    "51": 3797199,
    "52": 3785188,
    "53": 3650409,
    "54": 3626101,
    "55": 3788111,
    "56": 2846712,
    "57": 2833628,
    "58": 2792832,
    "59": 2881784,
    "60": 2540735,
    "61": 2370358,
    "62": 2306156,
    "63": 2195850,
    "64": 2147166,
    "65": 2025379,
    "66": 1986541,
    "67": 1986526,
    "68": 1821142,
    "69": 1818893,
    "70": 1792760,
    "71": 1782107,
    "72": 1797946,
    "73": 1697450,
    "74": 1679791,
    "75": 1620812,
    "76": 1554475,
    "77": 1522890,
    "78": 1436185,
    "79": 1336099,
    "80": 1269310,
    "81": 1180698,
    "82": 1075053,
    "83": 919945,
    "84": 849397
    //"85": 4368808
  },
  "2003": {
    /*"0": 3975871,
    "1": 3936139,
    "2": 4002836,
    "3": 3860727,
    "4": 3816873,
    "5": 3823568,
    "6": 3837761,
    "7": 3924182,
    "8": 3991075,
    "9": 4044265,
    "10": 4205042,
    "11": 4254047,
    "12": 4332987,
    "13": 4401393,
    "14": 4221884,
    "15": 4172654,
    "16": 4139141,
    "17": 4160313,
    "18": 4201835,
    "19": 4123223,*/
    "20": 4156877,
    "21": 4167554,
    "22": 4175294,
    "23": 4147777,
    "24": 3944186,
    "25": 3842468,
    "26": 3771710,
    "27": 3688646,
    "28": 3794838,
    "29": 3673923,
    "30": 3813536,
    "31": 3973726,
    "32": 4245067,
    "33": 4337299,
    "34": 4102582,
    "35": 4042650,
    "36": 4051962,
    "37": 4165007,
    "38": 4462051,
    "39": 4542489,
    "40": 4529389,
    "41": 4556844,
    "42": 4606465,
    "43": 4699388,
    "44": 4497961,
    "45": 4499971,
    "46": 4462427,
    "47": 4300483,
    "48": 4317939,
    "49": 4141856,
    "50": 3998812,
    "51": 3886144,
    "52": 3782009,
    "53": 3783390,
    "54": 3646923,
    "55": 3601691,
    "56": 3785668,
    "57": 2831596,
    "58": 2821608,
    "59": 2773994,
    "60": 2849408,
    "61": 2534959,
    "62": 2352131,
    "63": 2289143,
    "64": 2168747,
    "65": 2125962,
    "66": 1999916,
    "67": 1961151,
    "68": 1955990,
    "69": 1787777,
    "70": 1777336,
    "71": 1760292,
    "72": 1736351,
    "73": 1751589,
    "74": 1644551,
    "75": 1617308,
    "76": 1567629,
    "77": 1489790,
    "78": 1456995,
    "79": 1366120,
    "80": 1259724,
    "81": 1196760,
    "82": 1100969,
    "83": 997727,
    "84": 843416
    //"85": 4466176
  },
  "2004": {
    /*"0": 4014258,
    "1": 3953063,
    "2": 3933735,
    "3": 4008220,
    "4": 3876609,
    "5": 3829607,
    "6": 3822089,
    "7": 3850646,
    "8": 3933161,
    "9": 4018734,
    "10": 4151951,
    "11": 4207721,
    "12": 4293550,
    "13": 4350199,
    "14": 4408259,
    "15": 4264863,
    "16": 4213762,
    "17": 4167308,
    "18": 4214061,
    "19": 4242558,*/
    "20": 4131771,
    "21": 4180191,
    "22": 4173077,
    "23": 4192674,
    "24": 4167847,
    "25": 3981519,
    "26": 3838225,
    "27": 3801676,
    "28": 3697495,
    "29": 3788140,
    "30": 3737940,
    "31": 3790984,
    "32": 4023788,
    "33": 4257394,
    "34": 4349395,
    "35": 4127056,
    "36": 4048580,
    "37": 4051890,
    "38": 4174027,
    "39": 4473096,
    "40": 4540929,
    "41": 4540376,
    "42": 4555019,
    "43": 4610120,
    "44": 4679182,
    "45": 4486566,
    "46": 4506067,
    "47": 4465196,
    "48": 4287486,
    "49": 4319947,
    "50": 4121104,
    "51": 4010062,
    "52": 3868188,
    "53": 3782872,
    "54": 3782442,
    "55": 3618001,
    "56": 3603856,
    "57": 3763702,
    "58": 2819754,
    "59": 2801863,
    "60": 2739059,
    "61": 2848602,
    "62": 2515357,
    "63": 2335553,
    "64": 2259557,
    "65": 2148431,
    "66": 2098650,
    "67": 1975087,
    "68": 1930847,
    "69": 1920414,
    "70": 1744903,
    "71": 1747347,
    "72": 1713793,
    "73": 1691931,
    "74": 1696130,
    "75": 1581121,
    "76": 1566753,
    "77": 1501866,
    "78": 1425682,
    "79": 1385977,
    "80": 1286733,
    "81": 1189534,
    "82": 1115106,
    "83": 1023151,
    "84": 913980
    //"85": 4545883
  },
  "2005": {
    /*"0": 4004393,
    "1": 3987032,
    "2": 3952632,
    "3": 3943215,
    "4": 4030128,
    "5": 3893128,
    "6": 3828153,
    "7": 3838840,
    "8": 3861877,
    "9": 3967069,
    "10": 4150122,
    "11": 4149228,
    "12": 4251162,
    "13": 4308851,
    "14": 4353216,
    "15": 4456969,
    "16": 4308145,
    "17": 4239509,
    "18": 4225126,
    "19": 4256465,*/
    "20": 4241197,
    "21": 4150658,
    "22": 4176096,
    "23": 4183656,
    "24": 4208348,
    "25": 4206096,
    "26": 3966722,
    "27": 3870040,
    "28": 3805526,
    "29": 3686326,
    "30": 3866581,
    "31": 3705594,
    "32": 3848038,
    "33": 4035045,
    "34": 4268679,
    "35": 4377057,
    "36": 4130501,
    "37": 4044933,
    "38": 4060608,
    "39": 4185554,
    "40": 4470383,
    "41": 4553968,
    "42": 4537278,
    "43": 4559728,
    "44": 4585528,
    "45": 4666734,
    "46": 4496805,
    "47": 4511695,
    "48": 4449860,
    "49": 4292077,
    "50": 4295348,
    "51": 4138594,
    "52": 3989065,
    "53": 3871906,
    "54": 3783820,
    "55": 3748247,
    "56": 3625240,
    "57": 3582008,
    "58": 3748661,
    "59": 2799064,
    "60": 2763609,
    "61": 2745139,
    "62": 2827064,
    "63": 2498812,
    "64": 2303653,
    "65": 2240858,
    "66": 2121740,
    "67": 2074635,
    "68": 1945279,
    "69": 1896115,
    "70": 1873726,
    "71": 1718367,
    "72": 1700960,
    "73": 1671512,
    "74": 1638505,
    "75": 1629763,
    "76": 1535359,
    "77": 1501643,
    "78": 1439475,
    "79": 1357369,
    "80": 1305661,
    "81": 1218283,
    "82": 1109756,
    "83": 1038431,
    "84": 939062
    //"85": 4693299
  },
  "2006": {
    /*"0": 4041738,
    "1": 3972124,
    "2": 3988119,
    "3": 3966022,
    "4": 3970880,
    "5": 4050582,
    "6": 3891329,
    "7": 3848758,
    "8": 3852265,
    "9": 3901754,
    "10": 4121105,
    "11": 4141970,
    "12": 4196880,
    "13": 4264733,
    "14": 4308450,
    "15": 4406515,
    "16": 4502997,
    "17": 4331493,
    "18": 4301050,
    "19": 4265654,*/
    "20": 4248095,
    "21": 4258790,
    "22": 4141532,
    "23": 4186427,
    "24": 4201291,
    "25": 4254643,
    "26": 4186325,
    "27": 4004498,
    "28": 3874154,
    "29": 3790391,
    "30": 3779051,
    "31": 3824265,
    "32": 3772538,
    "33": 3860165,
    "34": 4049149,
    "35": 4302604,
    "36": 4380502,
    "37": 4126573,
    "38": 4056686,
    "39": 4074868,
    "40": 4184594,
    "41": 4488189,
    "42": 4552280,
    "43": 4544742,
    "44": 4532763,
    "45": 4573546,
    "46": 4682144,
    "47": 4506142,
    "48": 4495342,
    "49": 4458112,
    "50": 4264904,
    "51": 4320192,
    "52": 4114856,
    "53": 3996095,
    "54": 3874826,
    "55": 3745012,
    "56": 3760899,
    "57": 3601973,
    "58": 3568659,
    "59": 3720317,
    "60": 2757092,
    "61": 2776869,
    "62": 2725651,
    "63": 2810202,
    "64": 2463439,
    "65": 2286008,
    "66": 2213751,
    "67": 2098690,
    "68": 2044245,
    "69": 1910507,
    "70": 1848556,
    "71": 1848947,
    "72": 1672443,
    "73": 1660842,
    "74": 1619038,
    "75": 1573506,
    "76": 1587027,
    "77": 1472328,
    "78": 1441641,
    "79": 1372532,
    "80": 1278890,
    "81": 1239927,
    "82": 1138135,
    "83": 1036784,
    "84": 954381
    //"85": 4865929
  },
  "2007": {
    /*"0": 4147997,
    "1": 4002215,
    "2": 3973479,
    "3": 4004011,
    "4": 3998260,
    "5": 3993489,
    "6": 4046402,
    "7": 3914743,
    "8": 3863089,
    "9": 3896888,
    "10": 4075718,
    "11": 4106492,
    "12": 4192002,
    "13": 4207326,
    "14": 4259504,
    "15": 4364495,
    "16": 4452250,
    "17": 4521045,
    "18": 4394638,
    "19": 4335388,*/
    "20": 4242404,
    "21": 4257371,
    "22": 4236043,
    "23": 4143940,
    "24": 4198241,
    "25": 4245572,
    "26": 4222850,
    "27": 4224395,
    "28": 3999799,
    "29": 3850082,
    "30": 3894527,
    "31": 3724612,
    "32": 3899463,
    "33": 3780927,
    "34": 3871236,
    "35": 4083733,
    "36": 4302578,
    "37": 4370533,
    "38": 4136991,
    "39": 4070056,
    "40": 4071341,
    "41": 4202260,
    "42": 4485432,
    "43": 4560505,
    "44": 4512801,
    "45": 4519792,
    "46": 4591932,
    "47": 4694196,
    "48": 4487653,
    "49": 4506188,
    "50": 4426479,
    "51": 4294933,
    "52": 4292620,
    "53": 4124978,
    "54": 4001168,
    "55": 3829701,
    "56": 3761384,
    "57": 3734883,
    "58": 3588953,
    "59": 3539851,
    "60": 3659454,
    "61": 2775802,
    "62": 2757918,
    "63": 2711325,
    "64": 2769163,
    "65": 2445756,
    "66": 2257671,
    "67": 2191327,
    "68": 2068095,
    "69": 2007386,
    "70": 1860716,
    "71": 1826784,
    "72": 1798955,
    "73": 1633722,
    "74": 1608315,
    "75": 1553097,
    "76": 1535224,
    "77": 1521903,
    "78": 1414814,
    "79": 1375325,
    "80": 1292775,
    "81": 1216593,
    "82": 1158822,
    "83": 1065220,
    "84": 953666
    //"85": 5039545
  },
  "2008": {
    /*"0": 4132735,
    "1": 4100756,
    "2": 4004146,
    "3": 3992320,
    "4": 4041170,
    "5": 4024297,
    "6": 3988117,
    "7": 4073291,
    "8": 3930544,
    "9": 3913353,
    "10": 4093680,
    "11": 4055388,
    "12": 4159477,
    "13": 4200016,
    "14": 4198094,
    "15": 4318982,
    "16": 4411176,
    "17": 4467060,
    "18": 4588123,
    "19": 4425539,*/
    "20": 4302988,
    "21": 4251950,
    "22": 4232792,
    "23": 4237432,
    "24": 4155450,
    "25": 4246210,
    "26": 4206583,
    "27": 4265165,
    "28": 4215620,
    "29": 3969090,
    "30": 3970191,
    "31": 3828094,
    "32": 3807761,
    "33": 3907253,
    "34": 3791506,
    "35": 3908041,
    "36": 4083819,
    "37": 4291673,
    "38": 4382157,
    "39": 4151773,
    "40": 4065710,
    "41": 4090091,
    "42": 4199463,
    "43": 4495218,
    "44": 4524541,
    "45": 4499251,
    "46": 4541484,
    "47": 4606123,
    "48": 4672955,
    "49": 4501160,
    "50": 4471128,
    "51": 4464006,
    "52": 4264599,
    "53": 4306809,
    "54": 4132932,
    "55": 3949524,
    "56": 3850704,
    "57": 3733201,
    "58": 3722427,
    "59": 3558712,
    "60": 3476545,
    "61": 3694987,
    "62": 2757325,
    "63": 2744863,
    "64": 2668775,
    "65": 2751913,
    "66": 2416007,
    "67": 2235669,
    "68": 2160024,
    "69": 2030734,
    "70": 1953328,
    "71": 1841560,
    "72": 1776463,
    "73": 1758650,
    "74": 1581331,
    "75": 1541027,
    "76": 1518327,
    "77": 1472006,
    "78": 1464084,
    "79": 1350299,
    "80": 1294601,
    "81": 1232307,
    "82": 1136851,
    "83": 1086404,
    "84": 980196
    //"85": 5195840
  },
  "2009": {
    /*"0": 4003587,
    "1": 4078797,
    "2": 4103002,
    "3": 4025675,
    "4": 4033457,
    "5": 4070265,
    "6": 4018200,
    "7": 4017024,
    "8": 4089946,
    "9": 3987064,
    "10": 4133090,
    "11": 4066605,
    "12": 4110576,
    "13": 4164413,
    "14": 4185880,
    "15": 4259907,
    "16": 4365338,
    "17": 4421341,
    "18": 4534634,
    "19": 4611590,*/
    "20": 4383628,
    "21": 4307414,
    "22": 4217667,
    "23": 4230115,
    "24": 4245298,
    "25": 4204602,
    "26": 4197922,
    "27": 4250014,
    "28": 4250613,
    "29": 4175077,
    "30": 4104133,
    "31": 3890200,
    "32": 3921281,
    "33": 3813001,
    "34": 3916499,
    "35": 3829432,
    "36": 3905615,
    "37": 4071181,
    "38": 4304006,
    "39": 4397562,
    "40": 4145183,
    "41": 4085265,
    "42": 4085849,
    "43": 4209233,
    "44": 4454485,
    "45": 4509265,
    "46": 4523596,
    "47": 4557336,
    "48": 4582655,
    "49": 4689393,
    "50": 4462192,
    "51": 4514833,
    "52": 4429228,
    "53": 4281465,
    "54": 4317125,
    "55": 4074019,
    "56": 3975863,
    "57": 3819855,
    "58": 3721219,
    "59": 3689647,
    "60": 3489302,
    "61": 3517588,
    "62": 3673134,
    "63": 2745716,
    "64": 2699257,
    "65": 2653345,
    "66": 2719454,
    "67": 2394376,
    "68": 2204229,
    "69": 2120973,
    "70": 1974076,
    "71": 1936308,
    "72": 1790136,
    "73": 1737897,
    "74": 1702305,
    "75": 1513301,
    "76": 1510000,
    "77": 1455744,
    "78": 1417539,
    "79": 1398480,
    "80": 1270444,
    "81": 1236769,
    "82": 1152248,
    "83": 1067638,
    "84": 1000612
    //"85": 5367301
  },
  "2010": {
    "20": 4567951,
    "21": 4387413,
    "22": 4286330,
    "23": 4216524,
    "24": 4243080,
    "25": 4288870,
    "26": 4160319,
    "27": 4236631,
    "28": 4247132,
    "29": 4209925,
    "30": 4303827,
    "31": 4042153,
    "32": 3967147,
    "33": 3933076,
    "34": 3821734,
    "35": 3947854,
    "36": 3829703,
    "37": 3896217,
    "38": 4079655,
    "39": 4323834,
    "40": 4386825,
    "41": 4162937,
    "42": 4082210,
    "43": 4093285,
    "44": 4177952,
    "45": 4438000,
    "46": 4529194,
    "47": 4534994,
    "48": 4534165,
    "49": 4598583,
    "50": 4645689,
    "51": 4498526,
    "52": 4480160,
    "53": 4439014,
    "54": 4288067,
    "55": 4258545,
    "56": 4092793,
    "57": 3946199,
    "58": 3802133,
    "59": 3693990,
    "60": 3616436,
    "61": 3519888,
    "62": 3494867,
    "63": 3651970,
    "64": 2705897,
    "65": 2678332,
    "66": 2621200,
    "67": 2693584,
    "68": 2359713,
    "69": 2167750,
    "70": 2062466,
    "71": 1953486,
    "72": 1883725,
    "73": 1750237,
    "74": 1685936,
    "75": 1631850,
    "76": 1481687,
    "77": 1449156,
    "78": 1402199,
    "79": 1354926,
    "80": 1319723,
    "81": 1212519,
    "82": 1158188,
    "83": 1081226,
    "84": 986814,
    "85": 914842,
    "86": 821428,
    "87": 721149,
    "88": 636650,
    "89": 546248,
    "90": 448417,
    "91": 344555,
    "92": 288965,
    "93": 219192,
    "94": 170904,
    "95": 131195,
    "96": 97259,
    "97": 68966,
    "98": 47086,
    "99": 32214,
    "100": 54437
  },
  "2011": {
    "20": 4598087,
    "21": 4594046,
    "22": 4412989,
    "23": 4312071,
    "24": 4242005,
    "25": 4267715,
    "26": 4311460,
    "27": 4181235,
    "28": 4255887,
    "29": 4264231,
    "30": 4224904,
    "31": 4316834,
    "32": 4053130,
    "33": 3977087,
    "34": 3941571,
    "35": 3829296,
    "36": 3953574,
    "37": 3834160,
    "38": 3899275,
    "39": 4081727,
    "40": 4323492,
    "41": 4384667,
    "42": 4160003,
    "43": 4078457,
    "44": 4088648,
    "45": 4171392,
    "46": 4429559,
    "47": 4518765,
    "48": 4522525,
    "49": 4519850,
    "50": 4582117,
    "51": 4627614,
    "52": 4478988,
    "53": 4458856,
    "54": 4416050,
    "55": 4263777,
    "56": 4233221,
    "57": 4066454,
    "58": 3918867,
    "59": 3773861,
    "60": 3664938,
    "61": 3586380,
    "62": 3487750,
    "63": 3459686,
    "64": 3612778,
    "65": 2674388,
    "66": 2644949,
    "67": 2585082,
    "68": 2652967,
    "69": 2320909,
    "70": 2128978,
    "71": 2022302,
    "72": 1911768,
    "73": 1839617,
    "74": 1705176,
    "75": 1638392,
    "76": 1581264,
    "77": 1430846,
    "78": 1394624,
    "79": 1343872,
    "80": 1292555,
    "81": 1252415,
    "82": 1143567,
    "83": 1084658,
    "84": 1004493,
    "85": 908494,
    "86": 834114,
    "87": 740788,
    "88": 642674,
    "89": 559656,
    "90": 473596,
    "91": 382497,
    "92": 289188,
    "93": 238035,
    "94": 176717,
    "95": 135101,
    "96": 101295,
    "97": 73267,
    "98": 50654,
    "99": 33604,
    "100": 57513
  },
  "2012": {
    "20": 4546283,
    "21": 4629444,
    "22": 4625206,
    "23": 4443353,
    "24": 4341674,
    "25": 4271101,
    "26": 4295141,
    "27": 4336875,
    "28": 4204349,
    "29": 4276982,
    "30": 4282588,
    "31": 4240764,
    "32": 4330633,
    "33": 4064859,
    "34": 3987707,
    "35": 3950829,
    "36": 3836855,
    "37": 3959826,
    "38": 3839208,
    "39": 3902771,
    "40": 4083481,
    "41": 4323211,
    "42": 4382863,
    "43": 4157098,
    "44": 4074786,
    "45": 4083445,
    "46": 4164199,
    "47": 4420397,
    "48": 4507426,
    "49": 4509218,
    "50": 4504724,
    "51": 4564517,
    "52": 4607874,
    "53": 4457963,
    "54": 4436389,
    "55": 4391667,
    "56": 4238325,
    "57": 4206336,
    "58": 4038875,
    "59": 3890472,
    "60": 3744191,
    "61": 3634139,
    "62": 3554183,
    "63": 3453473,
    "64": 3422763,
    "65": 3571867,
    "66": 2641359,
    "67": 2609571,
    "68": 2546536,
    "69": 2610071,
    "70": 2280469,
    "71": 2089040,
    "72": 1980767,
    "73": 1868543,
    "74": 1793992,
    "75": 1658787,
    "76": 1589317,
    "77": 1529111,
    "78": 1378596,
    "79": 1338588,
    "80": 1283870,
    "81": 1228453,
    "82": 1183563,
    "83": 1073199,
    "84": 1010027,
    "85": 927337,
    "86": 830572,
    "87": 754700,
    "88": 662480,
    "89": 567505,
    "90": 486948,
    "91": 406038,
    "92": 322223,
    "93": 239592,
    "94": 193338,
    "95": 140263,
    "96": 105060,
    "97": 76840,
    "98": 54192,
    "99": 36514,
    "100": 61035
  },
  "2013": {
    "20": 4452516,
    "21": 4573290,
    "22": 4659674,
    "23": 4655026,
    "24": 4471728,
    "25": 4368721,
    "26": 4296990,
    "27": 4319792,
    "28": 4358696,
    "29": 4224848,
    "30": 4295820,
    "31": 4298346,
    "32": 4254484,
    "33": 4342195,
    "34": 4074558,
    "35": 3996281,
    "36": 3957490,
    "37": 3842637,
    "38": 3964275,
    "39": 3842610,
    "40": 3904444,
    "41": 4083858,
    "42": 4321505,
    "43": 4379162,
    "44": 4152699,
    "45": 4068840,
    "46": 4076067,
    "47": 4155470,
    "48": 4409255,
    "49": 4494101,
    "50": 4493641,
    "51": 4486720,
    "52": 4544358,
    "53": 4586306,
    "54": 4435170,
    "55": 4411473,
    "56": 4364534,
    "57": 4210457,
    "58": 4176885,
    "59": 4008522,
    "60": 3859173,
    "61": 3711821,
    "62": 3600431,
    "63": 3518772,
    "64": 3415735,
    "65": 3382530,
    "66": 3527156,
    "67": 2605415,
    "68": 2571021,
    "69": 2504738,
    "70": 2563695,
    "71": 2236694,
    "72": 2045286,
    "73": 1935198,
    "74": 1821131,
    "75": 1744077,
    "76": 1607991,
    "77": 1535682,
    "78": 1472207,
    "79": 1321640,
    "80": 1277561,
    "81": 1218793,
    "82": 1159110,
    "83": 1109098,
    "84": 997485,
    "85": 930550,
    "86": 846127,
    "87": 749538,
    "88": 673270,
    "89": 583346,
    "90": 492709,
    "91": 415817,
    "92": 341169,
    "93": 265470,
    "94": 193974,
    "95": 153120,
    "96": 108285,
    "97": 79369,
    "98": 56508,
    "99": 38797,
    "100": 64898
  },
  "2014": {
    "20": 4410910,
    "21": 4480266,
    "22": 4604115,
    "23": 4692295,
    "24": 4686353,
    "25": 4501246,
    "26": 4397165,
    "27": 4324796,
    "28": 4345247,
    "29": 4382190,
    "30": 4246947,
    "31": 4314451,
    "32": 4314207,
    "33": 4268221,
    "34": 4353984,
    "35": 4084165,
    "36": 4004418,
    "37": 3964439,
    "38": 3848856,
    "39": 3969337,
    "40": 3845825,
    "41": 3905797,
    "42": 4083824,
    "43": 4319265,
    "44": 4375450,
    "45": 4147532,
    "46": 4062255,
    "47": 4068585,
    "48": 4145797,
    "49": 4397069,
    "50": 4479191,
    "51": 4476281,
    "52": 4467724,
    "53": 4523245,
    "54": 4563253,
    "55": 4410443,
    "56": 4384452,
    "57": 4335908,
    "58": 4180851,
    "59": 4145881,
    "60": 3976405,
    "61": 3825701,
    "62": 3677408,
    "63": 3564597,
    "64": 3481789,
    "65": 3376670,
    "66": 3340649,
    "67": 3480104,
    "68": 2567511,
    "69": 2530460,
    "70": 2461426,
    "71": 2516392,
    "72": 2191642,
    "73": 2000102,
    "74": 1888141,
    "75": 1772432,
    "76": 1692960,
    "77": 1555971,
    "78": 1480735,
    "79": 1414010,
    "80": 1263540,
    "81": 1215634,
    "82": 1152926,
    "83": 1089054,
    "84": 1034418,
    "85": 922398,
    "86": 852675,
    "87": 767609,
    "88": 672433,
    "89": 596951,
    "90": 510281,
    "91": 424834,
    "92": 352528,
    "93": 284642,
    "94": 217013,
    "95": 155992,
    "96": 120427,
    "97": 82948,
    "98": 59546,
    "99": 41277,
    "100": 70685
  },
  "2015": {
    "20": 4349271,
    "21": 4440639,
    "22": 4513472,
    "23": 4638825,
    "24": 4726443,
    "25": 4719543,
    "26": 4533612,
    "27": 4428666,
    "28": 4354559,
    "29": 4372818,
    "30": 4407648,
    "31": 4270096,
    "32": 4334801,
    "33": 4332152,
    "34": 4284303,
    "35": 4367425,
    "36": 4095075,
    "37": 4013981,
    "38": 3972535,
    "39": 3855684,
    "40": 3974173,
    "41": 3849567,
    "42": 3907838,
    "43": 4083850,
    "44": 4317474,
    "45": 4371112,
    "46": 4141749,
    "47": 4055387,
    "48": 4059893,
    "49": 4135111,
    "50": 4383161,
    "51": 4462685,
    "52": 4458166,
    "53": 4447639,
    "54": 4500628,
    "55": 4538441,
    "56": 4383779,
    "57": 4355834,
    "58": 4305438,
    "59": 4149449,
    "60": 4112746,
    "61": 3941754,
    "62": 3790011,
    "63": 3640427,
    "64": 3526289,
    "65": 3441926,
    "66": 3334811,
    "67": 3295666,
    "68": 3429944,
    "69": 2526801,
    "70": 2486399,
    "71": 2414320,
    "72": 2463978,
    "73": 2141464,
    "74": 1949879,
    "75": 1836120,
    "76": 1718677,
    "77": 1636506,
    "78": 1498661,
    "79": 1420466,
    "80": 1350324,
    "81": 1200209,
    "82": 1148098,
    "83": 1081183,
    "84": 1013458,
    "85": 954664,
    "86": 843182,
    "87": 771610,
    "88": 687017,
    "89": 594305,
    "90": 520698,
    "91": 438523,
    "92": 359346,
    "93": 292623,
    "94": 232203,
    "95": 173006,
    "96": 122315,
    "97": 92078,
    "98": 61585,
    "99": 43276,
    "100": 75449
  },
  "2016": {
    "20": 4278076,
    "21": 4376754,
    "22": 4472022,
    "23": 4546725,
    "24": 4671030,
    "25": 4759050,
    "26": 4750806,
    "27": 4564270,
    "28": 4457571,
    "29": 4381396,
    "30": 4397578,
    "31": 4429613,
    "32": 4290114,
    "33": 4353183,
    "34": 4348424,
    "35": 4297636,
    "36": 4377814,
    "37": 4103497,
    "38": 4021576,
    "39": 3978876,
    "40": 3860351,
    "41": 3977416,
    "42": 3851777,
    "43": 3907854,
    "44": 4082421,
    "45": 4313188,
    "46": 4365052,
    "47": 4134775,
    "48": 4046714,
    "49": 4049463,
    "50": 4122087,
    "51": 4367488,
    "52": 4444936,
    "53": 4438043,
    "54": 4425283,
    "55": 4475490,
    "56": 4511556,
    "57": 4355282,
    "58": 4325310,
    "59": 4272724,
    "60": 4115514,
    "61": 4076904,
    "62": 3904795,
    "63": 3751734,
    "64": 3600490,
    "65": 3485374,
    "66": 3399710,
    "67": 3290367,
    "68": 3248029,
    "69": 3376173,
    "70": 2483369,
    "71": 2440774,
    "72": 2365831,
    "73": 2409821,
    "74": 2089859,
    "75": 1898266,
    "76": 1782609,
    "77": 1663500,
    "78": 1578695,
    "79": 1440053,
    "80": 1358823,
    "81": 1285298,
    "82": 1135564,
    "83": 1079467,
    "84": 1008948,
    "85": 937939,
    "86": 875790,
    "87": 765842,
    "88": 693336,
    "89": 610099,
    "90": 520782,
    "91": 450056,
    "92": 373146,
    "93": 300773,
    "94": 240128,
    "95": 187214,
    "96": 136011,
    "97": 94732,
    "98": 69464,
    "99": 45030,
    "100": 81199
  },
  "2017": {
    "20": 4262267,
    "21": 4301347,
    "22": 4403799,
    "23": 4500396,
    "24": 4574512,
    "25": 4698330,
    "26": 4785819,
    "27": 4776789,
    "28": 4588278,
    "29": 4479720,
    "30": 4401684,
    "31": 4415784,
    "32": 4445710,
    "33": 4304958,
    "34": 4366340,
    "35": 4358474,
    "36": 4304733,
    "37": 4383439,
    "38": 4108234,
    "39": 4025425,
    "40": 3980852,
    "41": 3861830,
    "42": 3977681,
    "43": 3850531,
    "44": 3905385,
    "45": 4077414,
    "46": 4305935,
    "47": 4356263,
    "48": 4124824,
    "49": 4035771,
    "50": 4036095,
    "51": 4106675,
    "52": 4349788,
    "53": 4424299,
    "54": 4415276,
    "55": 4399955,
    "56": 4448203,
    "57": 4482211,
    "58": 4324223,
    "59": 4291724,
    "60": 4236920,
    "61": 4078580,
    "62": 4038225,
    "63": 3864653,
    "64": 3710075,
    "65": 3557852,
    "66": 3441715,
    "67": 3354577,
    "68": 3242657,
    "69": 3196430,
    "70": 3318392,
    "71": 2436899,
    "72": 2391538,
    "73": 2313358,
    "74": 2351361,
    "75": 2033951,
    "76": 1842454,
    "77": 1725054,
    "78": 1604180,
    "79": 1516532,
    "80": 1377005,
    "81": 1292742,
    "82": 1215783,
    "83": 1066792,
    "84": 1006959,
    "85": 933197,
    "86": 859615,
    "87": 795017,
    "88": 687486,
    "89": 614987,
    "90": 534178,
    "91": 449297,
    "92": 382476,
    "93": 311689,
    "94": 246766,
    "95": 192745,
    "96": 147449,
    "97": 104068,
    "98": 71571,
    "99": 50969,
    "100": 85663
  },
  "2018": {
    "20": 4262078,
    "21": 4279527,
    "22": 4321222,
    "23": 4424783,
    "24": 4520476,
    "25": 4593783,
    "26": 4717156,
    "27": 4803933,
    "28": 4793010,
    "29": 4603079,
    "30": 4492860,
    "31": 4413112,
    "32": 4425955,
    "33": 4454613,
    "34": 4312609,
    "35": 4371450,
    "36": 4361404,
    "37": 4306366,
    "38": 4384202,
    "39": 4108600,
    "40": 4024106,
    "41": 3978956,
    "42": 3859261,
    "43": 3973483,
    "44": 3845646,
    "45": 3898596,
    "46": 4068551,
    "47": 4295362,
    "48": 4344044,
    "49": 4111972,
    "50": 4021129,
    "51": 4019585,
    "52": 4088748,
    "53": 4328970,
    "54": 4400749,
    "55": 4389077,
    "56": 4371950,
    "57": 4417813,
    "58": 4449262,
    "59": 4289580,
    "60": 4254388,
    "61": 4197441,
    "62": 4038371,
    "63": 3995629,
    "64": 3820738,
    "65": 3664838,
    "66": 3511843,
    "67": 3394794,
    "68": 3305114,
    "69": 3190630,
    "70": 3141469,
    "71": 3256743,
    "72": 2387218,
    "73": 2338843,
    "74": 2257691,
    "75": 2288885,
    "76": 1974261,
    "77": 1783292,
    "78": 1664122,
    "79": 1541515,
    "80": 1450820,
    "81": 1310691,
    "82": 1223611,
    "83": 1143041,
    "84": 995618,
    "85": 932507,
    "86": 856172,
    "87": 781145,
    "88": 714808,
    "89": 610666,
    "90": 539372,
    "91": 461887,
    "92": 382414,
    "93": 320359,
    "94": 256353,
    "95": 198913,
    "96": 151823,
    "97": 113716,
    "98": 77943,
    "99": 53184,
    "100": 93038
  },
  "2019": {
    "20": 4269683,
    "21": 4278323,
    "22": 4298772,
    "23": 4341644,
    "24": 4444518,
    "25": 4539058,
    "26": 4611220,
    "27": 4733869,
    "28": 4818725,
    "29": 4806144,
    "30": 4614384,
    "31": 4502311,
    "32": 4421505,
    "33": 4432973,
    "34": 4460132,
    "35": 4315866,
    "36": 4372444,
    "37": 4361286,
    "38": 4305576,
    "39": 4382349,
    "40": 4105313,
    "41": 4020254,
    "42": 3974741,
    "43": 3854040,
    "44": 3967275,
    "45": 3837909,
    "46": 3889372,
    "47": 4058038,
    "48": 4282657,
    "49": 4329775,
    "50": 4096572,
    "51": 4004343,
    "52": 4001782,
    "53": 4068851,
    "54": 4305603,
    "55": 4374565,
    "56": 4361016,
    "57": 4342385,
    "58": 4385570,
    "59": 4413855,
    "60": 4252663,
    "61": 4215172,
    "62": 4156645,
    "63": 3996088,
    "64": 3950578,
    "65": 3774597,
    "66": 3618069,
    "67": 3464437,
    "68": 3345475,
    "69": 3252423,
    "70": 3136704,
    "71": 3083083,
    "72": 3191048,
    "73": 2334433,
    "74": 2283164,
    "75": 2198286,
    "76": 2222392,
    "77": 1911261,
    "78": 1720817,
    "79": 1599909,
    "80": 1475278,
    "81": 1381641,
    "82": 1241341,
    "83": 1151190,
    "84": 1067757,
    "85": 922467,
    "86": 856646,
    "87": 778923,
    "88": 703078,
    "89": 635982,
    "90": 536447,
    "91": 467172,
    "92": 394067,
    "93": 320785,
    "94": 264277,
    "95": 207086,
    "96": 157463,
    "97": 116969,
    "98": 86150,
    "99": 57124,
    "100": 100322
  },
  "2020": {
    /*"0": 3735010,
    "1": 3773884,
    "2": 3853025,
    "3": 3921526,
    "4": 4017847,
    "5": 4054336,
    "6": 4040169,
    "7": 4029753,
    "8": 4034785,
    "9": 4078668,
    "10": 4074173,
    "11": 4072994,
    "12": 4200977,
    "13": 4219921,
    "14": 4186358,
    "15": 4175920,
    "16": 4187874,
    "17": 4164893,
    "18": 4159857,
    "19": 4272385,*/
    "20": 4344572,
    "21": 4283150,
    "22": 4293020,
    "23": 4315220,
    "24": 4358793,
    "25": 4461006,
    "26": 4555511,
    "27": 4628618,
    "28": 4751001,
    "29": 4835107,
    "30": 4821081,
    "31": 4627340,
    "32": 4514736,
    "33": 4432699,
    "34": 4442547,
    "35": 4466747,
    "36": 4319361,
    "37": 4374441,
    "38": 4362662,
    "39": 4305093,
    "40": 4378957,
    "41": 4100150,
    "42": 4014287,
    "43": 3967810,
    "44": 3846684,
    "45": 3957972,
    "46": 3826694,
    "47": 3876923,
    "48": 4043448,
    "49": 4265569,
    "50": 4310670,
    "51": 4076434,
    "52": 3983727,
    "53": 3979962,
    "54": 4044734,
    "55": 4277856,
    "56": 4343881,
    "57": 4327862,
    "58": 4306615,
    "59": 4346885,
    "60": 4372095,
    "61": 4208976,
    "62": 4168433,
    "63": 4106492,
    "64": 3944582,
    "65": 3897420,
    "66": 3720410,
    "67": 3562236,
    "68": 3407082,
    "69": 3286519,
    "70": 3192029,
    "71": 3074732,
    "72": 3017173,
    "73": 3117385,
    "74": 2274412,
    "75": 2219565,
    "76": 2130427,
    "77": 2147097,
    "78": 1839828,
    "79": 1649916,
    "80": 1526957,
    "81": 1400392,
    "82": 1303809,
    "83": 1163249,
    "84": 1070307,
    "85": 984343,
    "86": 842226,
    "87": 774636,
    "88": 696138,
    "89": 620583,
    "90": 553813,
    "91": 460157,
    "92": 394211,
    "93": 326753,
    "94": 260690,
    "95": 210633,
    "96": 161205,
    "97": 119744,
    "98": 86169,
    "99": 62300,
    "100": 104819
  }
};